import { colors } from '@apps/shared/src/style';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NumberFormatCustom, NumberFormatFixed, NumberFormatMax } from './TextInputNumberFormats';

const styles = theme => ({
  formControl: {
    marginBottom: 15,
  },
  inputInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '14px',
  },
  inputUnderline: {
    '&$warning:after': {
      borderBottomColor: colors.yellow,
    },
  },
  warning: {},
});

export class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedValue: props.value,
      allowedCharacterRegex: this.getCharacterRegex(props),
    };
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.max) {
      if (+nextProps.value <= +this.props.max) this.setState({ formattedValue: nextProps.value });
    } else this.setState({ formattedValue: nextProps.value });
  }

  handleChange = e => {
    if (this.props.max) {
      if (+e.target.value <= +this.props.max) this.setState({ formattedValue: e.target.value });
    } else this.setState({ formattedValue: e.target.value });
    if (this.props.onChange) this.props.onChange(e);
  };

  handleFocus = e => e.target.select();

  shouldAllowedRegexBlockInput = e => {
    const key = e.key || String.fromCharCode(e.which || e.keyCode);
    return (
      this.state.allowedCharacterRegex !== undefined && !this.state.allowedCharacterRegex.test(key)
    );
  };

  handleKeyPress = e => {
    if (this.shouldAllowedRegexBlockInput(e)) e.preventDefault();
    if (this.props.onKeyPress) this.props.onKeyPress(e);
  };

  handleBlur = () => {
    if (this.props.onBlur) this.props.onBlur(this.state.formattedValue);
  };

  getCharacterRegex = props => {
    let { allowedCharRegex } = props;
    if (
      (allowedCharRegex === undefined || allowedCharRegex === '') &&
      (props.isMoney || props.isMoneyFixed || props.isPercent || props.isFloat)
    ) {
      allowedCharRegex = '\\d.';
    }
    if (allowedCharRegex !== undefined && allowedCharRegex !== '') {
      return new RegExp(`[${allowedCharRegex}]`);
    }
    return undefined;
  };

  render() {
    const {
      classes,
      label,
      isMoney,
      isMoneyFixed,
      isPercent,
      isFloat,
      isInteger,
      id,
      errorLabel,
      disabled,
      maxLength,
      margin,
      fullWidth,
      placeholder,
      onKeyDown,
      onPaste,
      className,
      ...other
    } = this.props;
    const { formattedValue } = this.state;

    delete other.allowedCharRegex;

    let inputComponent;
    if (isMoney || isFloat || isInteger) {
      inputComponent = NumberFormatCustom;
    } else if (isMoneyFixed) {
      inputComponent = NumberFormatFixed;
    } else if (isPercent) {
      inputComponent = NumberFormatMax;
    }

    return (
      <FormControl
        error={!!errorLabel}
        className={classes.formControl}
        disabled={disabled}
        margin={margin}
        fullWidth={fullWidth}
      >
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Input
          {...other}
          id={id}
          label={label}
          placeholder={placeholder}
          value={formattedValue}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyPress={this.handleKeyPress}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          type={isMoney || isMoneyFixed || isPercent || isFloat || isInteger ? 'tel' : undefined}
          className={className}
          classes={{
            input: classes.inputInput,
            underline: classes.inputUnderline,
          }}
          inputProps={{
            maxLength,
          }}
          inputComponent={inputComponent}
          startAdornment={
            isMoney || isMoneyFixed ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : undefined
          }
          endAdornment={isPercent ? <InputAdornment position="end">%</InputAdornment> : undefined}
        />
        {!!errorLabel && <FormHelperText id={`${id}-error-text`}>{errorLabel}</FormHelperText>}
      </FormControl>
    );
  }
}

TextInput.defaultProps = {
  value: undefined,
  onChange: undefined,
  onBlur: undefined,
  label: undefined,
  isMoney: false,
  isMoneyFixed: false,
  isPercent: false,
  isFloat: false,
  isInteger: false,
  id: undefined,
  errorLabel: undefined,
  type: undefined,
  disabled: false,
  maxLength: undefined,
  margin: undefined,
  fullWidth: false,
  multiline: false,
  allowedCharRegex: undefined,
  autoFocus: false,
  placeholder: undefined,
  onKeyPress: undefined,
  onKeyDown: undefined,
  onPaste: undefined,
  className: undefined,
  max: undefined,
};

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  isMoney: PropTypes.bool,
  isMoneyFixed: PropTypes.bool,
  isPercent: PropTypes.bool,
  isFloat: PropTypes.bool,
  isInteger: PropTypes.bool,
  id: PropTypes.string,
  errorLabel: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  allowedCharRegex: PropTypes.string,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  className: PropTypes.string,
  max: PropTypes.number,
};

export default withStyles(styles)(TextInput);
