import React, { useState, useCallback, Component } from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from '@material-ui/core/styles/styled';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { colors } from '@apps/shared/src/style';
import * as types from '../../shared/types/propTypes';
import { claimStatusCodes } from '../../shared/status';
import ClaimMessage from '../../shared/components/ClaimMessage';
import { getErrorText } from '../../shared/validators';
import { addSnackbar } from '../../shared/components/snackbar/snackbarReducer';

import { parseTypeOfBill } from '../claimsReducer';
import DateInput from '../DateInput';
import Provider from '../Provider';
import ServiceItems from '../ServiceItems';
import IndividualOrOrganization from '../IndividualOrOrganization';
import TextInput from '../../shared/components/TextInput';

import Policy from '../Policy';
import { ManualClaimFormFooter } from './ManualClaimFormFooter/ManualClaimFormFooter';
import { newSelectedClaim } from '../rootReducer';
import { newServiceLocationProvider } from '../serviceLocationReducer';
import FileUploadModal from './ClaimFileUploadModal';
import SelectedClaimFileDetailModal from './SelectedClaimFileDetailsModal';

// Used for claim info sections
const Column = styled('div')(({ theme }) => ({
  display: 'inline-block',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
  verticalAlign: 'top',
}));

// Used for service item headers
const TableCellHeaderTop = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  padding: theme.spacing(2),
  color: colors.black,
  backgroundColor: colors.grey05,
}));

const SSelect = styled(Select)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const styles = theme => ({
  root: {
    margin: '1em 2em',
    maxWidth: 'fit-content',
  },

  container: {
    flexGrow: 1,
  },

  item: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },

  rightDivider: {
    borderRight: `1px solid ${colors.grey17}`,
  },

  // For the 4 claim info sections
  claimInfoCell: {
    padding: 10,
    width: 200,
    minHeight: 510,
  },

  // For the service items section
  serviceItemsCell: {
    padding: 10,
    overflowX: 'auto',
  },

  serviceItemsTable: {
    width: '100%',
  },

  // For save buttons
  button: {
    margin: theme.spacing(1),
  },

  uploadButton: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingRight: 0,
    justifyContent: 'flex-end',
  },

  // For plan name
  msg: {
    padding: '6px 0px',
    fontSize: '0.75em',
    color: colors.greyDark,
  },
  err: {
    padding: '6px 0px',
    fontSize: '0.75em',
    color: colors.redRose,
  },
});

function getManualClaimPageTitle(isEdit, isValidate) {
  if (isEdit) {
    if (isValidate) return 'Validate Claim';
    return 'Edit Claim';
  }
  return 'New Claim';
}
class ManualClaimForm extends Component {
  handleValidationButton = () => this.props.validateClaim(this.props.selectedClaim.id);
  handleEntryErrorButton = () => this.props.invalidateClaim(this.props.selectedClaim.id);
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isSelectedFileModalOpen: false,
      selectedFile: null,
      claimType: 'HCFA',
    };
  }

  componentDidMount() {
    this.updateClaimType();
  }

  componentDidUpdate(prevProps) {
    const claimType = this.props.selectedClaim.claims[0].formType === 'A' ? 'UBO4' : 'HCFA';
    if (
      prevProps.selectedClaim?.claims[0] !== this.props.selectedClaim?.claims[0] &&
      this.state.claimType !== claimType
    ) {
      this.updateClaimType();
    }
  }

  updateClaimType() {
    const { selectedClaim } = this.props;
    if (selectedClaim && selectedClaim.claims[0].formType) {
      this.setState({
        claimType: selectedClaim.claims[0].formType === 'A' ? 'UBO4' : 'HCFA',
      });
    }
  }

  toggleFileUpload = open => {
    this.setState({ isModalOpen: open }, () => {
      // open SelectedClaimFileDetailModal
      if (!open && this.state.selectedFile) {
        this.toggleSelectedFileDetail(true);
      }
    });
  };

  toggleSelectedFileDetail = open => {
    this.setState({ isSelectedFileModalOpen: open });
  };

  handleUploadClick = () => {
    this.toggleFileUpload(true);
  };

  handleFileUploadModal = (claimType, selectedFile) => {
    if (selectedFile) {
      this.setSelectedFile(selectedFile);
    }
    this.setClaimType(claimType);
  };

  handleSaveClaim = async () => {
    const { selectedClaim } = this.props;
    let invalidClaim = false;
    if (this.state.selectedFile) {
      try {
        const uploadedClaimFile = await this.props.handleFileUpload();

        if (uploadedClaimFile?.StorageFilename) {
          selectedClaim.claims[0].claimFile = uploadedClaimFile.StorageFilename;
        } else {
          invalidClaim = uploadedClaimFile;
        }
      } catch (error) {
        console.log('save claim upload file', error);
      }
    }
    if (!invalidClaim) {
      this.props.handleSaveClick();
    }
  };

  handleSaveAndRepriceClick = async () => {
    const { selectedClaim } = this.props;
    let invalidClaim = false;
    if (this.state.selectedFile) {
      try {
        const uploadedClaimFile = await this.props.handleFileUpload();
        if (uploadedClaimFile?.StorageFilename) {
          selectedClaim.claims[0].claimFile = uploadedClaimFile.StorageFilename;
        } else {
          invalidClaim = uploadedClaimFile;
        }
      } catch (error) {
        console.log('Error uploading the file', error);
      }
    }
    if (!invalidClaim) {
      this.props.handleSaveAndRepriceClick();
    }
  };

  handleCompleteAndSubmitClick = async () => {
    const { selectedClaim } = this.props;
    let invalidClaim = false;
    if (this.state.selectedFile) {
      try {
        const uploadedClaimFile = await this.props.handleFileUpload();
        if (uploadedClaimFile?.StorageFilename) {
          selectedClaim.claims[0].claimFile = uploadedClaimFile.StorageFilename;
        } else {
          invalidClaim = uploadedClaimFile;
        }
      } catch (error) {
        console.log('Error uploading the file');
      }
    }
    if (!invalidClaim) {
      this.props.handleCompleteAndSubmitClick();
    }
  };

  setSelectedFile = file => {
    this.setState({ selectedFile: file }, () => {
      this.props.onFileAndClaimTypeChange(this.state.selectedFile, this.state.claimType);
    });
  };

  setClaimType = claimType => {
    this.setState({ claimType }, () => {
      // Notify NewClaim of the updated claim type
      this.props.onFileAndClaimTypeChange(this.state.selectedFile, this.state.claimType);
    });
  };

  handleMenuChange = e => {
    const value = e.target.value.toUpperCase();

    // Update formType using the passed down handleTextChange prop
    this.props.handleTextChange('formType')(e);

    // Update claimType based on formType
    const claimType = value === 'A' ? 'UBO4' : 'HCFA';
    this.setClaimType(claimType);
  };

  render() {
    const { isModalOpen, selectedFile, claimType, isSelectedFileModalOpen } = this.state;
    const {
      selectedClaim,
      classes,
      isEdit,
      handleTextChange,
      formatTextChange,
      handleDateChange,
      invalidClaimId,
      updateProviderValue,
      updateNPI,
      updateLocationProviderValue,
      updateLocationNPI,
      toggleFileUpload,
      toggleAddressVerified,
      toggleAddressIsEditable,
      toggleServiceLocationAddressIsEditable,
      toggleServiceLocationAddressVerified,
      updateSubscriberValue,
      updatePatientValue,
      updateClaimValue,
      removeServiceItem,
      updateServiceValue,
      addServiceItem,
      handleSaveClick,
      handleFileUpload,
      handleCompleteAndSubmitClick,
      handleNewClaimClick,
      handleDeleteClick,
      isDataEnterer,
      handleSaveAndRepriceClick,
      handlePolicyNumChange,
    } = this.props;
    const firstClaim = selectedClaim.claims[0];
    // this.setState({
    //   claimType: firstClaim.formType === 'A' ? 'UBO4' : 'HCFA',
    // });
    const isProfessional = firstClaim.formType === 'B';
    const isInpatient = parseTypeOfBill(firstClaim.typeOfBill, firstClaim.formType) === 'I';
    const isValidate =
      selectedClaim.status === claimStatusCodes.repriceNew &&
      selectedClaim.receiveConfig === 'manual entry';
    const isReadOnly = isValidate;

    if (invalidClaimId) {
      return <ClaimMessage text="Invalid claim ID" />;
    }

    return (
      <div className={classes.root}>
        {/* Title section */}
        <Grid container className={classes.container} spacing={2} justify="flex-start">
          <Grid item>
            <Typography variant="h3" color="secondary">
              {getManualClaimPageTitle(isEdit, isValidate)}
            </Typography>
          </Grid>
          <Grid item className={classes.uploadButton}>
            <Tooltip title="Upload Claim File">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleUploadClick}
                id="save-claim-button"
              >
                Upload File
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        {/* modal */}
        <FileUploadModal
          open={isModalOpen}
          selectedFile={selectedFile}
          setSelectedFile={this.setSelectedFile}
          claimType={claimType}
          setClaimType={this.setClaimType}
          toggleFileUpload={this.toggleFileUpload}
          handleFileUpload={this.handleFileUploadModal}
        />
        <SelectedClaimFileDetailModal
          open={isSelectedFileModalOpen}
          selectedFile={selectedFile}
          setSelectedFile={this.setSelectedFile}
          toggleSelectedFileDetail={this.toggleSelectedFileDetail}
        />
        {/* Claim information */}
        <Grid container className={classes.container} spacing={2}>
          <Grid item>
            <Paper className={classes.claimInfoCell}>
              <TableCellHeaderTop>Provider</TableCellHeaderTop>
              <Column>
                <Provider
                  prov={selectedClaim.billProv}
                  updateProviderValue={updateProviderValue}
                  updateNPI={updateNPI}
                  toggleAddressVerified={toggleAddressVerified}
                  toggleAddressIsEditable={toggleAddressIsEditable}
                  isReadOnly={isReadOnly}
                />
              </Column>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.claimInfoCell}>
              <TableCellHeaderTop>Service Provider Location</TableCellHeaderTop>
              <Column>
                <Provider
                  prov={
                    firstClaim?.serviceLocation?.id
                      ? firstClaim.serviceLocation
                      : newServiceLocationProvider()
                  }
                  updateProviderValue={updateLocationProviderValue}
                  updateNPI={updateLocationNPI}
                  toggleAddressVerified={toggleServiceLocationAddressVerified}
                  toggleAddressIsEditable={toggleServiceLocationAddressIsEditable}
                  isReadOnly={isReadOnly}
                  serviceLocation
                />
              </Column>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.claimInfoCell}>
              <TableCellHeaderTop>Claim</TableCellHeaderTop>
              <Column>
                <SSelect
                  readOnly={isReadOnly}
                  label="Form Type"
                  onChange={this.handleMenuChange}
                  value={firstClaim.formType}
                  autoFocus
                  id="claim-type"
                >
                  <MenuItem id="facility" key={0} value="A">
                    UB-O4 (Facility)
                  </MenuItem>
                  <MenuItem id="professional" key={1} value="B">
                    HCFA (Professional)
                  </MenuItem>
                </SSelect>
                {!isProfessional && (
                  <TextInput
                    readOnly={isReadOnly}
                    label="Type of Bill"
                    allowedCharRegex="\d"
                    maxLength={3}
                    onChange={handleTextChange('typeOfBill')}
                    value={firstClaim.typeOfBill}
                    errorLabel={getErrorText(firstClaim, 'typeOfBill')}
                    id="type-of-bill"
                  />
                )}
                {isProfessional && (
                  <TextInput
                    readOnly={isReadOnly}
                    label="Resubmission Code"
                    allowedCharRegex="1-9"
                    maxLength={1}
                    onChange={handleTextChange('resubmissionCode')}
                    value={firstClaim.resubmissionCode}
                    errorLabel={getErrorText(firstClaim, 'resubmissionCode')}
                    id="resubmission-code"
                  />
                )}
                <TextInput
                  readOnly={isReadOnly}
                  label="Bill Reference Number"
                  onChange={handleTextChange('refNum')}
                  value={firstClaim.refNum}
                  errorLabel={getErrorText(firstClaim, 'refNum')}
                  id="bill-ref-number"
                />
                <Policy
                  planName={firstClaim.planName}
                  policyNum={firstClaim.policyNum}
                  save={handlePolicyNumChange}
                  showLabel
                  isEditable
                  errorLabel={getErrorText(firstClaim, 'policyNum')}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="Admitting Diag Codes"
                  onChange={handleTextChange('admittingDiagnoses')}
                  onBlur={formatTextChange('admittingDiagnoses')}
                  multiline
                  allowedCharRegex="\w "
                  rowsMax={10}
                  value={firstClaim.admittingDiagnoses}
                  title="Enter admitting diagnosis codes separated by space"
                  errorLabel={getErrorText(firstClaim, 'admittingDiagnoses')}
                  id="admitting-diagnosis-codes"
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="Principal Diag Codes"
                  onChange={handleTextChange('principalDiagnoses')}
                  onBlur={formatTextChange('principalDiagnoses')}
                  multiline
                  allowedCharRegex="\w "
                  rowsMax={10}
                  value={firstClaim.principalDiagnoses}
                  title="Enter principal diagnosis codes separated by space"
                  errorLabel={getErrorText(firstClaim, 'principalDiagnoses')}
                  id="principal-diagnosis-codes"
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="Diagnosis Codes"
                  onChange={handleTextChange('diagnoses')}
                  onBlur={formatTextChange('diagnoses')}
                  multiline
                  allowedCharRegex="\w "
                  rowsMax={10}
                  value={firstClaim.diagnoses}
                  title="Enter diagnosis codes separated by space"
                  errorLabel={getErrorText(firstClaim, 'diagnoses')}
                  id="diagnosis-codes"
                />
                {isInpatient && (
                  <TextInput
                    readOnly={isReadOnly}
                    label="DRG"
                    onChange={handleTextChange('drg')}
                    allowedCharRegex="\d"
                    maxLength={3}
                    value={firstClaim.drg}
                    errorLabel={getErrorText(firstClaim, 'drg')}
                    id="drg"
                  />
                )}
                {!isProfessional && (
                  <DateInput
                    readOnly={isReadOnly}
                    label="Statement Start"
                    onValueChange={handleDateChange('statementFromDate')}
                    value={firstClaim.statementFromDate}
                    errorLabel={getErrorText(firstClaim, 'statementFromDate')}
                    id="statement-start"
                  />
                )}
                {!isProfessional && (
                  <DateInput
                    readOnly={isReadOnly}
                    label="Statement End"
                    onValueChange={handleDateChange('statementToDate')}
                    value={firstClaim.statementToDate}
                    errorLabel={getErrorText(firstClaim, 'statementToDate')}
                    id="statement-end"
                  />
                )}
              </Column>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.claimInfoCell}>
              <TableCellHeaderTop>Subscriber</TableCellHeaderTop>
              <Column>
                <IndividualOrOrganization
                  data={firstClaim.subscriber}
                  updateValue={updateSubscriberValue}
                  isReadOnly={isReadOnly}
                />
              </Column>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.claimInfoCell}>
              <TableCellHeaderTop>Patient</TableCellHeaderTop>
              <Column>
                <IndividualOrOrganization
                  data={firstClaim.patient}
                  updateValue={updatePatientValue}
                  isReadOnly={isReadOnly}
                  patient
                />
              </Column>
            </Paper>
          </Grid>
        </Grid>

        {/* Service items section */}
        <Grid container className={classes.container} spacing={2}>
          <Grid item className={classes.serviceItemsTable}>
            <Paper className={classes.serviceItemsCell}>
              <ServiceItems
                services={firstClaim.services}
                isProfessional={isProfessional}
                removeServiceItem={removeServiceItem}
                updateServiceValue={updateServiceValue}
                addServiceItem={addServiceItem}
                isReadOnly={isReadOnly}
                forMemberReimbursement={firstClaim.forMemberReimbursement}
                updateClaimValue={updateClaimValue}
              />
            </Paper>
          </Grid>
        </Grid>

        <ManualClaimFormFooter
          selectedClaim={selectedClaim}
          isEdit={isEdit}
          isDataEnterer={isDataEnterer}
          handleSaveClick={this.handleSaveClaim}
          handleCompleteAndSubmitClick={this.handleCompleteAndSubmitClick}
          handleNewClaimClick={handleNewClaimClick}
          handleDeleteClick={handleDeleteClick}
          validateClaim={this.handleValidationButton}
          invalidateClaim={this.handleEntryErrorButton}
          handleSaveAndRepriceClick={this.handleSaveAndRepriceClick}
        />
      </div>
    );
  }
}

// Incomplete prop types
ManualClaimForm.defaultProps = {
  selectedClaim: newSelectedClaim(),
  isDataEnterer: false,
  newOpen: false,
};

ManualClaimForm.propTypes = {
  selectedClaim: types.claim,
  selectedFile: types.File,
  newOpen: PropTypes.bool,
  validateClaim: PropTypes.func.isRequired,
  invalidateClaim: PropTypes.func.isRequired,
  handleSaveAndRepriceClick: PropTypes.func.isRequired,
  handlePolicyNumChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  formatTextChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  updateProviderValue: PropTypes.func.isRequired,
  updateNPI: PropTypes.func.isRequired,
  updateLocationNPI: PropTypes.func.isRequired,
  updateLocationProviderValue: PropTypes.func.isRequired,
  toggleAddressVerified: PropTypes.func.isRequired,
  toggleAddressIsEditable: PropTypes.func.isRequired,
  toggleServiceLocationAddressVerified: PropTypes.func.isRequired,
  toggleServiceLocationAddressIsEditable: PropTypes.func.isRequired,
  updateSubscriberValue: PropTypes.func.isRequired,
  updatePatientValue: PropTypes.func.isRequired,
  updateClaimValue: PropTypes.func.isRequired,
  removeServiceItem: PropTypes.func.isRequired,
  updateServiceValue: PropTypes.func.isRequired,
  addServiceItem: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  handleCompleteAndSubmitClick: PropTypes.func.isRequired,
  handleNewClaimClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isDataEnterer: PropTypes.bool,
  invalidClaimId: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleFileUpload: PropTypes.func.isRequired,
};

export default withStyles(styles)(ManualClaimForm);
