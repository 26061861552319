import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import HoverableText from './HoverableText';

function createHoverCodes(id, value) {
  return (
    <span key={id} style={{ whiteSpace: 'pre-line' }}>
      <HoverableText optIn type="optIn" value={id}>
        {value}
      </HoverableText>
    </span>
  );
}

function createMetaData(id, value) {
  const metaText = createHoverCodes(id, value);
  return (
    <div key={value}>
      <div>{metaText}</div>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    padding: '1rem',
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
  },
});

export default function OptInHoverMeta({ value, id }) {
  const classes = useStyles();

  return <div className={classes.root}>{createMetaData(id, value)}</div>;
}

OptInHoverMeta.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};
