import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Redirect, RouteComponentProps } from 'react-router-dom';
import { verifyEmail, clearRedirect } from './userActions';

import { RootState } from './types';

type StateProps = {
  redirectTo: string;
};

type DispatchProps = {
  verifyEmail: typeof verifyEmail;
  clearRedirect: typeof clearRedirect;
};

type RouteProps = {
  code: string;
};

const mapStateToProps = ({ user }: RootState): StateProps => {
  return {
    redirectTo: user.redirectTo,
  };
};

const mapDispatchToProps = {
  verifyEmail,
  clearRedirect,
};

type Props = StateProps & DispatchProps & RouteComponentProps<RouteProps>;

function VerifyEmail({ redirectTo, verifyEmail, clearRedirect, match }: Props): JSX.Element {
  useEffect(() => {
    verifyEmail(match.params.code, false);
    return (): void => {
      clearRedirect();
    };
  }, [verifyEmail, clearRedirect, match.params.code]);

  return redirectTo === '' ? <div>Verifying Email, please wait</div> : <Redirect to={redirectTo} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
