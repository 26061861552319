import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from './userActions';

type DispatchProps = {
  logout: typeof logout;
};

const mapDispatchToProps = {
  logout,
};

type Props = DispatchProps;

function Logout({ logout }: Props): JSX.Element {
  useEffect(() => {
    logout('');
  }, [logout]);

  return <Redirect to="/login" />;
}

export default connect(undefined, mapDispatchToProps)(Logout);
