import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import ExcludedIcon from '@material-ui/icons/VisibilityOff';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { colors } from '@apps/shared/src/style';

import { pluralize } from '../../contracts/contractUtilities';
import { ExclusionToolTip } from '../types/types';

function qty(num: number): string {
  return num <= 1 ? '' : `${num} `;
}

function hasOrHave(num: number): string {
  return num <= 1 ? 'has' : 'have';
}

const exclusionDescriptions: Record<string, ExclusionToolTip> = {
  lowData: {
    toolTip:
      'Providers with limited reported claim data are excluded to ensure the anonymity of patients',
    titlePrefix: 'To protect personal health information,',
  },
  noData: {
    toolTip:
      'A Provider with few Medicare reimbursable claims is not reportable, and thereby excluded',
    titlePrefix: 'Due to lack of data,',
  },
  excluded: {
    toolTip:
      'Providers whose financial model is substantially different from traditional providers are excluded as they make side-by-side analysis difficult. Such facilities can include HMOs, Acute Care Centers, Native American facilities, etc.',
    titlePrefix: 'Due to facility type,',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  popover: {
    padding: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '8px',
  },
  list: {
    listStyle: 'none',
    margin: '0',
    padding: '0 1em',
    color: colors.greyDark,
  },
}));

type ParentProps = {
  excludedProviders: Record<string, string[]>;
};

type Props = ParentProps;

export default function ExcludedProviders({ excludedProviders }: Props): JSX.Element | null {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  let excludedProviderCount = 0;
  Object.values(excludedProviders).forEach(value => {
    excludedProviderCount += value.length;
  });

  const title = `Excluded ${pluralize(
    'Provider',
    excludedProviderCount
  )} (${excludedProviderCount})`;

  if (excludedProviderCount === 0) return null;

  return (
    <div className={classes.root}>
      <Button startIcon={<ExcludedIcon />} onClick={handleClick}>
        {title}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className={classes.popover}>
          <div className={classes.header}>
            <ExcludedIcon className={classes.icon} />
            <Typography variant="h5">{title}</Typography>
          </div>

          {Object.keys(excludedProviders).map(exclusionType => {
            const providerCount = excludedProviders[exclusionType].length;
            const toolTip = exclusionDescriptions[exclusionType]
              ? exclusionDescriptions[exclusionType].toolTip
              : '';
            const titlePrefix = exclusionDescriptions[exclusionType]
              ? exclusionDescriptions[exclusionType].titlePrefix
              : '';
            return (
              <div key={exclusionType}>
                <Tooltip title={toolTip} placement="bottom-end">
                  <p>
                    {titlePrefix} the following {qty(providerCount)}{' '}
                    {pluralize('provider', providerCount)} {hasOrHave(providerCount)} been excluded:
                  </p>
                </Tooltip>
                <ul className={classes.list}>
                  {excludedProviders[exclusionType].map(provider => (
                    <li key={provider}>{provider}</li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
}
