import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import DailyClaimsHeader from './DailyClaimsHeader';
import DailyClaimsBody from './DailyClaimsBody';
import { getDailyClaims } from '../reportsActions';
import { dailyClaimsProp } from '../../shared/types/propTypes';

const useStyles = makeStyles({
  reportContainer: { paddingTop: '1rem' },
  reportHeader: { display: 'flex', alignItems: 'center' },
  controls: { width: '175px', margin: '0 1rem' },
  menuItem: {
    padding: '0 1rem',
    fontSize: '0.75rem',
    minHeight: '1.25rem',
  },
  table: {
    fontSize: '0.8rem',
    textAlign: 'left',
    borderSpacing: 0,
    marginTop: '1rem',
    position: 'relative',
    minWidth: 'max-content',
  },
});

const allHandlers = 'All';

export function DailyClaims({ dailyClaims, loading, getDailyClaims }) {
  const classes = useStyles();
  const [selectedHandler, setSelectedHandler] = useState('');

  useEffect(() => {
    getDailyClaims();
  }, [getDailyClaims]);

  if (dailyClaims && !selectedHandler) setSelectedHandler(allHandlers);

  const handleChange = e => setSelectedHandler(e.target.value);

  const handlers = dailyClaims ? Object.keys(dailyClaims).sort() : [];

  const isIndividual = selectedHandler !== allHandlers;

  return (
    <div className={classes.reportContainer}>
      <div className={classes.reportHeader}>
        <span>Daily Claim Count:</span>
        <FormControl disabled={loading || !selectedHandler} className={classes.controls}>
          <InputLabel htmlFor="daily-claim-select">Select User</InputLabel>
          <Select value={selectedHandler} onChange={handleChange} id="daily-claim-select">
            {handlers.map(h => (
              <MenuItem key={h} value={h} className={classes.menuItem}>
                {h}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <LoadingSpinner isLoading={loading} />
      {!loading && (
        <table className={classes.table}>
          <DailyClaimsHeader isIndividual={isIndividual} />
          <DailyClaimsBody
            dailyClaims={dailyClaims}
            handlers={handlers}
            selectedHandler={selectedHandler}
            isIndividual={isIndividual}
          />
        </table>
      )}
    </div>
  );
}

DailyClaims.defaultProps = {
  dailyClaims: undefined,
};

DailyClaims.propTypes = {
  dailyClaims: dailyClaimsProp,
  loading: PropTypes.bool.isRequired,
  getDailyClaims: PropTypes.func.isRequired,
};

function mapStateToProps({ reports }) {
  return {
    dailyClaims: reports.dailyClaims,
    loading: reports.loading,
  };
}

const mapDispatchToProps = {
  getDailyClaims,
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyClaims);
