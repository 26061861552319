const beige = '#FBEED8';
const black = '#000000';
const blue = '#2B629B';
const blueDark = '#16324F';
const blueLight = '#16BBD2';
const blueMuted = '#45748C';
const green = '#109651';
const greenMuted = '#758E4F';
const grey05 = '#F1F0F0';
const grey07 = '#EAE9E8';
const grey12 = '#E0DEDD';
const grey17 = '#D5D2D1';
const grey20 = '#C6C2C1';
const grey37 = '#A29F9E';
const grey50 = '#848181';
const grey60 = '#666363';
const grey76 = '#3E3C3C';
const grey82 = '#2D2A2B';
const offwhite = '#FCF9F2';
const orange = '#EF7125';
const orangeDark = '#DA6722';
const purple = '#843AC4';
const red = '#D90027';
const redRose = '#DD5555';
const redScarlet = '#9B1E20';
const white = '#FFFFFF';
const yellow = '#EEAA22';
const yellowBright = '#ECC532';

export const colors = {
  beige,
  black,
  blue,
  blueDark,
  blueLight,
  blueMuted,
  green,
  greenMuted,
  grey05,
  grey07,
  grey12,
  grey17,
  grey20,
  grey37,
  grey50,
  grey60,
  grey76,
  grey82,

  grey: grey20,
  greyText: grey50,
  greyDark: grey60,
  greyDarker: grey76,
  greyDarkest: grey82,

  offwhite,
  orange,
  orangeDark,
  purple,
  red,
  redRose,
  redScarlet,
  white,
  yellow,
  yellowBright,

  success: green,
  failure: red,
  warning: redRose,
  selected: grey12,
  hovered: grey07,
  focused: grey20,

  primary: orange,
  secondary: blue,
};

export const claimColors = {
  professional: colors.orange,
  inpatient: colors.blue,
  outpatient: colors.green,
  batchIn: colors.blue,
  batchOut: colors.blue,
};

export const chartColors = {
  bar: [colors.orange, colors.blue, colors.greenMuted],
  line: [colors.yellow],
};
