import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { colors } from '@apps/shared/src/style';
import * as types from '../types/propTypes';
import { Column } from '../grid';

const styles = {
  paper: {
    margin: '15em auto',
    width: '20em',
  },
  title: {
    fontWeight: 'bold',
    padding: '1em',
    color: colors.black,
    backgroundColor: colors.grey05,
    borderTopLeftRadius: '.5em',
    borderTopRightRadius: '.5em',
  },
  content: {
    padding: '1.5em 1em',
  },
  unassign: {
    marginTop: '2em',
    backgroundColor: colors.redRose,
    color: '#fff',
  },
  close: {
    marginTop: '2em',
  },
  navigate: {
    marginTop: '1em',
  },
};

class ClaimOpenError extends PureComponent {
  render() {
    const {
      selectedClaim,
      currentUser,
      isFirstClaim,
      isLastClaim,
      claimInList,
      closeOwnershipError,
      unassignOwner,
      classes,
      handleNavigatePrev,
      handleNavigateNext,
    } = this.props;
    const { simpleClaim = {}, owner = {} } = selectedClaim;
    const ownershipErrorOpen = owner.id != null && owner.id !== currentUser.userID;
    const isAdmin = Array.isArray(currentUser.roles) && currentUser.roles.includes('claims-admin');

    return (
      <Modal open={ownershipErrorOpen} onClose={closeOwnershipError}>
        <Paper className={classes.paper}>
          <div className={classes.title}>Claim Access Error</div>
          <Column className={classes.content}>
            Claim <strong>{simpleClaim.claimNum || simpleClaim.refNum}</strong> is currently owned
            by <strong>{owner.fullName || 'Unnamed'}</strong>
            {isAdmin && (
              <Button
                variant="contained"
                fullWidth
                className={classes.unassign}
                onClick={unassignOwner}
              >
                Unassign Owner
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              className={classes.close}
              onClick={closeOwnershipError}
            >
              Close This Claim
            </Button>
            {claimInList && !isFirstClaim && (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                className={classes.navigate}
                onClick={handleNavigatePrev}
              >
                Previous Claim
              </Button>
            )}
            {claimInList && !isLastClaim && (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                className={classes.navigate}
                onClick={handleNavigateNext}
              >
                Next Claim
              </Button>
            )}
          </Column>
        </Paper>
      </Modal>
    );
  }
}

ClaimOpenError.defaultProps = {
  handleNavigatePrev: null,
  handleNavigateNext: null,
  selectedClaim: {},
  currentUser: {},
  isFirstClaim: false,
  isLastClaim: false,
  claimInList: false,
  unassignOwner: null,
};
ClaimOpenError.propTypes = {
  handleNavigatePrev: PropTypes.func,
  handleNavigateNext: PropTypes.func,
  selectedClaim: types.claim,
  currentUser: types.simpleUser,
  isFirstClaim: PropTypes.bool,
  isLastClaim: PropTypes.bool,
  claimInList: PropTypes.bool,
  closeOwnershipError: PropTypes.func.isRequired,
  unassignOwner: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ClaimOpenError);
