import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '@apps/shared/src/style';
import * as types from '../../shared/types/propTypes';
import { isValidArray, isArrayFilled } from '../../shared/typeChecks';

const useStyles = makeStyles({
  configList: {
    listStyle: 'none',
    height: 'calc(100vh - 165px)',
    overflowY: 'scroll',
  },
  configListItem: {
    fontSize: '16px',
    padding: '5px',
    '&:hover': {
      backgroundColor: `${colors.grey}`,
    },
  },
  selectedListItem: {
    fontSize: '16px',
    padding: '5px',
    backgroundColor: `${colors.grey}`,
  },
  configListLink: {
    textDecoration: 'none !important',
    color: 'black',
  },
  selectedLink: {
    backgroundColor: `${colors.grey}`,
    textDecoration: 'none !important',
    color: 'black',
  },
  newConfigButton: {
    margin: '10px',
    marginLeft: '50px',
    width: '75%',
    color: 'white',
    backgroundColor: `${colors.green}`,
    '&:hover': {
      backgroundColor: `${colors.green}`,
    },
  },
  newTPAButton: {
    margin: '10px',
    marginLeft: '50px',
    width: '75%',
    color: 'white',
    backgroundColor: `${colors.orange}`,
    '&:hover': {
      backgroundColor: `${colors.orange}`,
    },
  },
});

export default function ConfigList({
  allClientConfigs,
  selectedID,
  toggleNewConfig,
  toggleNewTPA,
}) {
  const classes = useStyles();

  if (!(isValidArray(allClientConfigs) && isArrayFilled(allClientConfigs))) return null;

  return (
    <>
      <div>
        <Button onClick={toggleNewConfig} className={classes.newConfigButton}>
          New Config
        </Button>
        <Button onClick={toggleNewTPA} className={classes.newTPAButton}>
          Add TPA
        </Button>
      </div>
      <ul className={classes.configList}>
        {allClientConfigs.map(config => {
          return (
            <Link
              key={config.id}
              className={classes.configListLink}
              to={`/edi/configEditor/${config.id}`}
            >
              <li
                className={
                  config.id === selectedID ? classes.selectedListItem : classes.configListItem
                }
              >
                {config.name}
              </li>
            </Link>
          );
        })}
      </ul>
    </>
  );
}

ConfigList.defaultProps = {
  selectedID: undefined,
};

ConfigList.propTypes = {
  allClientConfigs: PropTypes.arrayOf(types.clientConfig).isRequired,
  selectedID: PropTypes.string,
  toggleNewConfig: PropTypes.func.isRequired,
  toggleNewTPA: PropTypes.func.isRequired,
};
