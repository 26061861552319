import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import LinkIcon from '@material-ui/icons/Link';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { inflateString } from '@apps/shared/src/inflators';
import DupeTableAction from './DupeTableAction';

import * as types from '../../types/propTypes';

export function getDupeDisplayDate(inputDate) {
  const validInputDateString = inflateString(inputDate);
  if (validInputDateString && !validInputDateString.startsWith('0001-01-01')) {
    return new Date(validInputDateString).toLocaleDateString('en-US', {
      timeZone: 'UTC',
    });
  }
  return 'not available';
}

export function getDupeMatchPercent(matchFraction) {
  if (typeof matchFraction === 'number' && matchFraction >= 0 && matchFraction <= 1) {
    return `${Math.round(matchFraction * 100)}%`;
  }
  return '';
}

const useStyles = makeStyles(theme => ({
  tableRow: theme.flagTable.tableRow,
  link: theme.flagTable.link,
  linkIcon: theme.flagTable.linkIcon,
}));

export default function DupeTableRow(props) {
  const { dupe = {}, isEven, color } = props;

  const {
    matchID,
    matchDate,
    matchReferenceNumber,
    matchClaimNumber,
    matchFraction,
    matchIsCashPrePay,
  } = dupe;
  const classes = useStyles({ isEven, color, isHighlighted: matchIsCashPrePay });

  return (
    <TableRow className={classes.tableRow} data-testid="dupe-table-row">
      <TableCell>
        <Link title="Link to Dupe" to={`/repricing/${matchID}`} className={classes.link}>
          <LinkIcon className={classes.linkIcon} />
        </Link>
      </TableCell>
      <TableCell>{getDupeDisplayDate(matchDate)}</TableCell>
      <TableCell>{matchReferenceNumber}</TableCell>
      <TableCell>{matchClaimNumber}</TableCell>
      <TableCell>{getDupeMatchPercent(matchFraction)}</TableCell>
      <TableCell>{matchIsCashPrePay ? 'Yes' : 'No'}</TableCell>
    </TableRow>
  );
}

DupeTableRow.propTypes = {
  dupe: types.dupe.isRequired,
  isEven: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
};
