import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TocIcon from '@material-ui/icons/Toc';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import * as types from '../../shared/types/propTypes';
import TextInput from '../../shared/components/TextInput';
import {
  TableCellHeaderTop,
  Column,
  configSectionStyle,
  addButtonStyle,
  deleteButtonStyle,
  configIconStyle,
} from './sharedConfig';
import ColArray from './ColArray';

const useStyles = makeStyles({
  configSection: configSectionStyle,
  configIcon: configIconStyle,
  marginButton: {
    margin: '20px',
  },
  claimInfoCell: {
    margin: '10px',
  },
  dropDown: {
    marginBottom: '20px',
  },
  dropDownHeader: {
    marginTop: '10px',
  },
  addButton: addButtonStyle,
  deleteButton: deleteButtonStyle,
});

export default function PatientCSVConfig({
  patientCSVConfig,
  isReadOnly,
  handleIntegerChange,
  handleTextChange,
  handleBoolChange,
  handlePushToArray,
  handleRemoveFromArray,
  handleUnSetObject,
}) {
  const classes = useStyles();

  if (!patientCSVConfig) {
    return null;
  }

  return (
    <Paper className={classes.claimInfoCell}>
      <TableCellHeaderTop>
        <Typography>
          <TocIcon className={classes.configIcon} /> &nbsp; Patient CSV Config
        </Typography>
        {!isReadOnly && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.deleteButton}
            onClick={handleUnSetObject('patientCSVConfig')}
          >
            <DeleteForeverIcon /> &nbsp; CSV Config
          </Button>
        )}
      </TableCellHeaderTop>
      <Column>
        <div className={classes.configSection}>
          <TextInput
            readOnly={isReadOnly}
            label="Delimiter"
            aria-label="Delimiter"
            fullWidth
            onChange={handleTextChange('patientCSVConfig.delimiter')}
            value={patientCSVConfig.delimiter}
          />
          <Typography className={classes.dropDownHeader}>Header:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              readOnly={isReadOnly}
              label="Header"
              aria-label="Header"
              onChange={handleBoolChange('patientCSVConfig.header')}
              value={patientCSVConfig.header}
            >
              <MenuItem key={0} value="true">
                True
              </MenuItem>
              <MenuItem key={1} value="false">
                False
              </MenuItem>
            </Select>
          </FormControl>
          <TextInput
            readOnly={isReadOnly}
            label="HeaderValues"
            aria-label="HeaderValues"
            fullWidth
            onChange={handleTextChange('patientCSVConfig.headerValues')}
            value={patientCSVConfig.headerValues}
          />
          <TextInput
            readOnly={isReadOnly}
            label="DateFormat"
            aria-label="DateFormat"
            fullWidth
            onChange={handleTextChange('patientCSVConfig.dateFormat')}
            value={patientCSVConfig.dateFormat}
          />
          <TextInput
            readOnly={isReadOnly}
            label="MaxDateFormat"
            aria-label="MaxDateFormat"
            fullWidth
            onChange={handleTextChange('patientCSVConfig.maxDateFormat')}
            value={patientCSVConfig.maxDateFormat}
          />
          <TextInput
            readOnly={isReadOnly}
            label="groupNumCol"
            aria-label="groupNumCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.groupNumCol')}
            value={patientCSVConfig.groupNumCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="firstNameCol"
            aria-label="firstNameCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.firstNameCol')}
            value={patientCSVConfig.firstNameCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="lastNameCol"
            aria-label="lastNameCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.lastNameCol')}
            value={patientCSVConfig.lastNameCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="DateOfBirthCol"
            aria-label="DateOfBirthCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.dateOfBirthCol')}
            value={patientCSVConfig.dateOfBirthCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="GenderCol"
            aria-label="GenderCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.genderCol')}
            value={patientCSVConfig.genderCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="patientSSNCol"
            aria-label="patientSSNCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.patientSSNCol')}
            value={patientCSVConfig.patientSSNCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="patientIDCol"
            aria-label="patientIDCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.patientIDCol')}
            value={patientCSVConfig.patientIDCol}
          />
          <Typography className={classes.dropDownHeader}>PatientIDFormat:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              readOnly={isReadOnly}
              label="patientIDFormat"
              aria-label="patientIDFormat"
              onChange={handleBoolChange('patientCSVConfig.patientIDFormat')}
              value={patientCSVConfig.patientIDFormat}
            >
              <MenuItem key={0} value="true">
                True
              </MenuItem>
              <MenuItem key={1} value="false">
                False
              </MenuItem>
            </Select>
          </FormControl>
          <TextInput
            readOnly={isReadOnly}
            label="subscriberSSNCol"
            aria-label="subscriberSSNCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.subscriberSSNCol')}
            value={patientCSVConfig.subscriberSSNCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="subscriberIDCol"
            aria-label="subscriberIDCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.subscriberIDCol')}
            value={patientCSVConfig.subscriberIDCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="subPlanIDCol"
            aria-label="subPlanIDCol"
            allowedCharRegex="\d"
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.subPlanIDCol')}
            value={patientCSVConfig.subPlanIDCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="subscriberIDFormat"
            aria-label="subscriberIDFormat"
            fullWidth
            onChange={handleTextChange('patientCSVConfig.subscriberIDFormat')}
            value={patientCSVConfig.subscriberIDFormat}
          />
          {patientCSVConfig.addressCols && (
            <ColArray
              parent="patientCSVConfig"
              name="addressCols"
              colArray={patientCSVConfig.addressCols}
              isReadOnly={isReadOnly}
              handleIntegerChange={handleIntegerChange}
              handlePushToArray={handlePushToArray}
              handleRemoveFromArray={handleRemoveFromArray}
            />
          )}
          <TextInput
            readOnly={isReadOnly}
            label="cityCol"
            aria-label="cityCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.cityCol')}
            value={patientCSVConfig.cityCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="stateCol"
            aria-label="stateCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.stateCol')}
            value={patientCSVConfig.stateCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="zipcodeCol"
            aria-label="zipcodeCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.zipcodeCol')}
            value={patientCSVConfig.zipcodeCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="countyCol"
            aria-label="countyCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.countyCol')}
            value={patientCSVConfig.countyCol}
          />
          {patientCSVConfig.phoneCol && (
            <ColArray
              parent="patientCSVConfig"
              name="phoneCol"
              colArray={patientCSVConfig.phoneCol}
              isReadOnly={isReadOnly}
              handleIntegerChange={handleIntegerChange}
              handlePushToArray={handlePushToArray}
              handleRemoveFromArray={handleRemoveFromArray}
            />
          )}
          {patientCSVConfig.emailCol && (
            <ColArray
              parent="patientCSVConfig"
              name="emailCol"
              colArray={patientCSVConfig.emailCol}
              isReadOnly={isReadOnly}
              handleIntegerChange={handleIntegerChange}
              handlePushToArray={handlePushToArray}
              handleRemoveFromArray={handleRemoveFromArray}
            />
          )}
          <TextInput
            readOnly={isReadOnly}
            label="startDateCol"
            aria-label="startDateCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.startDateCol')}
            value={patientCSVConfig.startDateCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="StartDateFormat"
            aria-label="StartDateFormat"
            fullWidth
            onChange={handleTextChange('patientCSVConfig.startDateFormat')}
            value={patientCSVConfig.startDateFormat}
          />
          <TextInput
            readOnly={isReadOnly}
            label="terminationDateCol"
            aria-label="terminationDateCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.terminationDateCol')}
            value={patientCSVConfig.terminationDateCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="TerminationDateFormat"
            aria-label="TerminationDateFormat"
            fullWidth
            onChange={handleTextChange('patientCSVConfig.terminationDateFormat')}
            value={patientCSVConfig.terminationDateFormat}
          />
          <TextInput
            readOnly={isReadOnly}
            label="eligibleCol"
            aria-label="eligibleCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.eligibleCol')}
            value={patientCSVConfig.eligibleCol}
          />
          <TextInput
            readOnly={isReadOnly}
            label="tpaRoutingCodeCol"
            aria-label="tpaRoutingCodeCol"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('patientCSVConfig.tpaRoutingCodeCol')}
            value={patientCSVConfig.tpaRoutingCodeCol}
          />
        </div>
      </Column>
    </Paper>
  );
}

PatientCSVConfig.defaultProps = {
  patientCSVConfig: null,
};

PatientCSVConfig.propTypes = {
  patientCSVConfig: types.patientCSVConfig,
  isReadOnly: PropTypes.bool.isRequired,
  handleIntegerChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleBoolChange: PropTypes.func.isRequired,
  handlePushToArray: PropTypes.func.isRequired,
  handleRemoveFromArray: PropTypes.func.isRequired,
  handleUnSetObject: PropTypes.func.isRequired,
};
