import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import * as types from '../../shared/types/propTypes';
import { TableCellHeaderTop, Column, configSectionStyle, configIconStyle } from './sharedConfig';
import TextInput from '../../shared/components/TextInput';

const useStyles = makeStyles({
  configSection: configSectionStyle,
  configIcon: configIconStyle,
  claimInfoCell: {
    margin: '10px',
  },
  dropDown: {
    marginBottom: '20px',
  },
  dropDownHeader: {
    marginTop: '10px',
  },
  select: {
    marginBottom: '20px',
  },
});

export default function MainConfig({
  selectedConfig,
  isReadOnly,
  handleTextChange,
  handleIntegerChange,
  handleBoolChange,
  allClientConfigs,
  handleParentConfigChange,
  handleMRFFileTypeChange,
}) {
  const classes = useStyles();

  const mrfFileTypes = [
    { key: 'CSV', value: 'CSV', label: 'CSV' },
    { key: 'JSON', value: 'JSON', label: 'JSON' },
    { key: 'Both', value: 'Both', label: 'Both' },
  ];

  if (!selectedConfig) {
    return null;
  }

  return (
    <Paper className={classes.claimInfoCell}>
      <TableCellHeaderTop>
        <Typography>
          <AssignmentIcon className={classes.configIcon} /> &nbsp; Main Config
        </Typography>
      </TableCellHeaderTop>
      <Column>
        <div className={classes.configSection}>
          <Typography className={classes.dropDownHeader}>Parent Config:</Typography>
          <Select
            fullWidth
            className={classes.select}
            label="Parent Config"
            InputLabel="Parent Config"
            aria-label="parentConfig"
            onChange={handleParentConfigChange}
            value={selectedConfig.parentID || ''}
            variant="outlined"
            readOnly={isReadOnly}
          >
            <MenuItem value="" selected>
              Select a Parent Config
            </MenuItem>
            <MenuItem value="None">None</MenuItem>
            {allClientConfigs &&
              allClientConfigs
                .filter(option => option.id !== selectedConfig.id)
                .map(option => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
          </Select>
          <TextInput
            readOnly={isReadOnly}
            label="SixDegTPAID"
            aria-label="SixDegTPAID"
            allowedCharRegex="\d"
            maxLength={3}
            fullWidth
            onChange={handleIntegerChange('sixDegTPAID')}
            value={selectedConfig.sixDegTPAID}
          />
          <TextInput
            readOnly={isReadOnly}
            label="LocalIncomingFolder"
            aria-label="LocalIncomingFolder"
            fullWidth
            onChange={handleTextChange('localIncomingFolder')}
            value={selectedConfig.localIncomingFolder}
          />
          <TextInput
            readOnly={isReadOnly}
            label="LocalOutgoingFolder"
            aria-label="LocalOutgoingFolder"
            fullWidth
            onChange={handleTextChange('localOutgoingFolder')}
            value={selectedConfig.localOutgoingFolder}
          />
          <TextInput
            readOnly={isReadOnly}
            label="ClaimsBridgeRouteFolder"
            aria-label="ClaimsBridgeRouteFolder"
            fullWidth
            onChange={handleTextChange('claimsBridgeRouteFolder')}
            value={selectedConfig.claimsBridgeRouteFolder}
          />
          <TextInput
            readOnly={isReadOnly}
            label="LocalCashPrePayFolder"
            aria-label="LocalCashPrePayFolder"
            fullWidth
            onChange={handleTextChange('localCashPrePayFolder')}
            value={selectedConfig.localCashPrePayFolder}
          />
          <TextInput
            readOnly={isReadOnly}
            label="LocalReportsFolder"
            aria-label="LocalReportsFolder"
            fullWidth
            onChange={handleTextChange('localReportsFolder')}
            value={selectedConfig.localReportsFolder}
          />
          <TextInput
            readOnly={isReadOnly}
            label="LocalMRFFolder"
            aria-label="Local-MRF-Folder"
            fullWidth
            onChange={handleTextChange('localMRFFolder')}
            value={selectedConfig.localMRFFolder}
          />
          <Typography className={classes.dropDownHeader}>MRFFileType:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              className={classes.select}
              readOnly={isReadOnly}
              label="MRFFileType"
              aria-label="MRFFileType"
              onChange={handleMRFFileTypeChange}
              value={selectedConfig.mrfFileType || 'CSV'}
            >
              {mrfFileTypes.map(item => (
                <MenuItem key={item.key} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextInput
            readOnly={isReadOnly}
            label="ReportsEmails"
            aria-label="ReportsEmails"
            fullWidth
            onChange={handleTextChange('reportsEmails')}
            value={selectedConfig.reportsEmails}
          />
          <TextInput
            readOnly={isReadOnly}
            label="Subfolder999"
            aria-label="Subfolder999"
            fullWidth
            onChange={handleTextChange('subfolder999')}
            value={selectedConfig.subfolder999}
          />
          <Typography className={classes.dropDownHeader}>Suppress999:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              readOnly={isReadOnly}
              label="Suppress999"
              aria-label="Suppress999"
              onChange={handleBoolChange('suppress999')}
              value={selectedConfig.suppress999}
            >
              <MenuItem key={0} value="true">
                True
              </MenuItem>
              <MenuItem key={1} value="false">
                False
              </MenuItem>
            </Select>
          </FormControl>
          <Typography className={classes.dropDownHeader}>Send837Invoice:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              readOnly={isReadOnly}
              label="Send837Invoice"
              aria-label="Send837Invoice"
              onChange={handleBoolChange('send837Invoice')}
              value={selectedConfig.send837Invoice}
            >
              <MenuItem key={0} value="true">
                True
              </MenuItem>
              <MenuItem key={1} value="false">
                False
              </MenuItem>
            </Select>
          </FormControl>
          {selectedConfig.send837Invoice && (
            <>
              <Typography className={classes.dropDownHeader}>Send837InvoiceHCP:</Typography>
              <FormControl className={classes.dropDown}>
                <Select
                  autoWidth
                  readOnly={isReadOnly}
                  label="Send837InvoiceHCP"
                  aria-label="Send837InvoiceHCP"
                  onChange={handleBoolChange('send837InvoiceHCP')}
                  value={selectedConfig.send837InvoiceHCP}
                >
                  <MenuItem key={0} value="true">
                    True
                  </MenuItem>
                  <MenuItem key={1} value="false">
                    False
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          <TextInput
            readOnly={isReadOnly}
            label="RepriceSegmentName"
            aria-label="RepriceSegmentName"
            fullWidth
            onChange={handleTextChange('repriceSegmentName')}
            value={selectedConfig.repriceSegmentName}
          />
          <Typography className={classes.dropDownHeader}>UseHCP04Codes:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              readOnly={isReadOnly}
              label="UseHCP04Codes"
              aria-label="UseHCP04Codes"
              onChange={handleBoolChange('useHCP04Codes')}
              value={selectedConfig.useHCP04Codes}
            >
              <MenuItem key={0} value="true">
                True
              </MenuItem>
              <MenuItem key={1} value="false">
                False
              </MenuItem>
            </Select>
          </FormControl>
          <Typography className={classes.dropDownHeader}>SendClaimHCP:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              readOnly={isReadOnly}
              label="SendClaimHCP"
              aria-label="SendClaimHCP"
              onChange={handleBoolChange('sendClaimHCP')}
              value={selectedConfig.sendClaimHCP}
            >
              <MenuItem key={0} value="true">
                True
              </MenuItem>
              <MenuItem key={1} value="false">
                False
              </MenuItem>
            </Select>
          </FormControl>
          <TextInput
            readOnly={isReadOnly}
            label="NTECodeOverride"
            aria-label="NTECodeOverride"
            fullWidth
            onChange={handleTextChange('nteCodeOverride')}
            value={selectedConfig.nteCodeOverride}
          />
          <Typography className={classes.dropDownHeader}>RepriceZeroOutOfNetwork:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              readOnly={isReadOnly}
              label="RepriceZeroOutOfNetwork"
              aria-label="RepriceZeroOutOfNetwork"
              onChange={handleBoolChange('repriceZeroOutOfNetwork')}
              value={selectedConfig.repriceZeroOutOfNetwork}
            >
              <MenuItem key={0} value="true">
                True
              </MenuItem>
              <MenuItem key={1} value="false">
                False
              </MenuItem>
            </Select>
          </FormControl>
          <Typography className={classes.dropDownHeader}>CPPShowValuesInReprice:</Typography>
          <FormControl className={classes.dropDown}>
            <Select
              autoWidth
              readOnly={isReadOnly}
              label="CPPShowValuesInReprice"
              aria-label="CPPShowValuesInReprice"
              onChange={handleBoolChange('cppShowValuesInReprice')}
              value={selectedConfig.cppShowValuesInReprice}
            >
              <MenuItem key={0} value="true">
                True
              </MenuItem>
              <MenuItem key={1} value="false">
                False
              </MenuItem>
            </Select>
          </FormControl>
          <TextInput
            readOnly={isReadOnly}
            label="ProfessionalPrefix"
            aria-label="ProfessionalPrefix"
            fullWidth
            onChange={handleTextChange('professionalPrefix')}
            value={selectedConfig.professionalPrefix}
          />
          <TextInput
            readOnly={isReadOnly}
            label="InstitutionalPrefix"
            aria-label="InstitutionalPrefix"
            fullWidth
            onChange={handleTextChange('institutionalPrefix')}
            value={selectedConfig.institutionalPrefix}
          />
          <TextInput
            readOnly={isReadOnly}
            label="Extension837"
            aria-label="Extension837"
            fullWidth
            onChange={handleTextChange('extension837')}
            value={selectedConfig.extension837}
          />
          <TextInput
            readOnly={isReadOnly}
            label="Extension999"
            aria-label="Extension999"
            fullWidth
            onChange={handleTextChange('extension999')}
            value={selectedConfig.extension999}
          />
          <TextInput
            readOnly={isReadOnly}
            label="Extension277"
            aria-label="Extension277"
            fullWidth
            onChange={handleTextChange('extension277')}
            value={selectedConfig.extension277}
          />
          <TextInput
            readOnly={isReadOnly}
            label="IDQualifier"
            aria-label="IDQualifier"
            fullWidth
            onChange={handleTextChange('idQualifier')}
            value={selectedConfig.idQualifier}
          />

          <TextInput
            readOnly={isReadOnly}
            label="InterchangeID"
            aria-label="InterchangeID"
            fullWidth
            onChange={handleTextChange('interchangeID')}
            value={selectedConfig.interchangeID}
          />
          <TextInput
            readOnly={isReadOnly}
            label="PGPKeyPath"
            aria-label="PGPKeyPath"
            fullWidth
            onChange={handleTextChange('pgpKeyPath')}
            value={selectedConfig.pgpKeyPath}
          />
        </div>
      </Column>
    </Paper>
  );
}

MainConfig.propTypes = {
  selectedConfig: types.clientConfig.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleIntegerChange: PropTypes.func.isRequired,
  handleBoolChange: PropTypes.func.isRequired,
};
