import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import Chip from '@material-ui/core/Chip';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import TextInput from '../../../shared/components/TextInput';
import IconButtonCompact from '../../../shared/components/IconButtonCompact';

import { termItems } from '../termElements';
import { validArray } from '../../../shared/typeChecks';
import { getCodeConfig } from '../../contractUtilities';
import { TermInputProps, HandleTermValueChange, TermItem } from '../../types/contracts';

const useStyles = makeStyles({
  termInput: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '1em',
  },
  textInput: {
    minWidth: '9em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chip: {
    margin: '0.1em',
  },
  chipBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

const handleDelete = (
  handleChange: HandleTermValueChange,
  id: TermItem,
  arr: string[],
  el: string
): void => {
  handleChange({ id, value: arr.filter(v => v !== el) });
};

const revCodeRegex = RegExp('^[0-9]{3,4}$');
const hcpcsRegex = RegExp('^[a-zA-Z0-9]{5}$');

const validateRevCode = (code: string): boolean => revCodeRegex.test(code);
const validateHCPCSorCPT = (code: string): boolean => hcpcsRegex.test(code);

type HandleAddProps = {
  handleChange: HandleTermValueChange;
  setCode: (code: string) => void;
  type: TermItem;
  existingCodes: string[];
  code: string;
  setError: (error: string) => void;
};
const handleAdd = ({
  handleChange,
  setCode,
  type,
  existingCodes,
  code,
  setError,
}: HandleAddProps): void => {
  if (code !== '') {
    if (existingCodes.includes(code)) {
      setError('duplicate code');
    } else if (type === termItems.revCode && !validateRevCode(code)) {
      setError('must be 3-4 digits and contain only numeric characters');
    } else if (type === termItems.hcpcsOrCPT && !validateHCPCSorCPT(code)) {
      setError('must be 5 digits and contain only alphanumeric characters');
    } else {
      handleChange({ id: type, value: [...existingCodes, code] });
      setCode('');
      setError('');
    }
  } else {
    setError('');
  }
};

type HandleAddMultipleProps = {
  handleChange: HandleTermValueChange;
  type: TermItem;
  existingCodes: string[];
  newCodes: string[];
  setError: (error: string) => void;
};
const handleAddMultiple = ({
  handleChange,
  type,
  existingCodes,
  newCodes,
  setError,
}: HandleAddMultipleProps): void => {
  if (newCodes.length === 0) return;
  const codesToAdd: string[] = [];
  const errors: string[] = [];

  newCodes.forEach(newCode => {
    if (existingCodes.includes(newCode) || codesToAdd.includes(newCode)) {
      errors.push(`Invalid Code: ${newCode} (duplicate code)`);
    } else if (type === termItems.revCode && !validateRevCode(newCode)) {
      errors.push(
        `Invalid Code: ${newCode} (must be 3-4 digits and contain only numeric characters)`
      );
    } else if (type === termItems.hcpcsOrCPT && !validateHCPCSorCPT(newCode)) {
      errors.push(
        `Invalid Code: ${newCode} (must be 5 digits and contain only alphanumeric characters)`
      );
    } else {
      codesToAdd.push(newCode);
    }
  });

  handleChange({ id: type, value: [...existingCodes, ...codesToAdd] });
  setError(errors.join('; '));
};

const matchCodeDelimiters = /[,\s]+/; // Regex matching any combination of commas and spaces

export default function ListInput({ value, handleChange, id: type }: TermInputProps): JSX.Element {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const classes = useStyles();
  const existingCodes = validArray(value);

  const { allowedCharRegex, maxLength } = getCodeConfig(type);

  const onDelete = (v: string) => (): void => handleDelete(handleChange, type, existingCodes, v);
  const addCode = (): void =>
    handleAdd({ handleChange, setCode, type, existingCodes, code, setError });

  const handleKeyPress = (e: KeyboardEvent): void => {
    if (e.key === 'Enter' || e.key === ' ') addCode();
  };

  const handlePaste = (e: React.ClipboardEvent): void => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData('Text');
    const newCodes = clipboardData.trim().split(matchCodeDelimiters);
    handleAddMultiple({ handleChange, type, existingCodes, newCodes, setError });
    setCode('');
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>): void =>
    setCode(e.currentTarget.value.toUpperCase());

  return (
    <div className={classes.termInput}>
      <div className={classes.textInput}>
        <TextInput
          key={existingCodes.length + 1}
          value={code}
          onBlur={addCode}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          allowedCharRegex={allowedCharRegex}
          maxLength={maxLength}
          errorLabel={error}
          onPaste={handlePaste}
          autoFocus
        />

        <IconButtonCompact onClick={addCode}>
          <AddCircleIcon />
        </IconButtonCompact>
      </div>
      <div className={classes.chipBox}>
        {validArray(existingCodes).map(v => (
          <div className={classes.chip} key={v}>
            <Chip
              size="small"
              label={v}
              onDelete={onDelete(v)}
              clickable={false}
              variant="outlined"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
