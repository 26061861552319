import React from 'react';

import Typography from '@material-ui/core/Typography';

import ClaimFooterItem from '../../repricing/ClaimFooterItem';
import { SimpleUser } from '../../shared/types/types';

type Props = {
  owner?: SimpleUser;
};
export default function ContractFooterOwner({ owner }: Props): JSX.Element | null {
  if (!owner) return null;

  return (
    <>
      <ClaimFooterItem>
        <Typography variant="subtitle1">
          <strong>Owner: </strong>
          {owner.fullName}
        </Typography>
      </ClaimFooterItem>
    </>
  );
}
