import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import UserCard from '@apps/shared/src/auth/UserCard';

export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ redirect: true, enable: true }), 2000);
  }

  render() {
    return this.state.redirect ? (
      <Redirect to="/" />
    ) : (
      <UserCard title="Welcome!">
        <h2>
          Thank you for your patience, your user is being created. You will be redirected soon.
        </h2>
        {this.state.enable && <Link to="/">Claims Home</Link>}
      </UserCard>
    );
  }
}
