import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';

import { colors } from '@apps/shared/src/style';

import TextInput from '../../shared/components/TextInput';
import { InputChangeHandler } from '../../shared/types/types';

import { MasterPlanErrors, TPA, MasterPlan } from './types/plans';

const useStyles = makeStyles({
  planNameForm: {
    marginBottom: '25px',
  },
  input: {
    display: 'inline-block',
    marginRight: '20px',
  },
  mediviPlanAccessWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  smallLabel: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  alertText: {
    color: colors.red,
    fontSize: '12px',
  },
  select: {
    minWidth: '120px',
  },
  planNameField: {
    minWidth: '300px',
  },
  resize: {
    fontSize: '.75em',
    marginLeft: '3%',
    marginRight: '3%',
  },
  checkbox: {
    marginRight: '3%',
  },
});

type ParentProps = {
  localMasterPlan: MasterPlan;
  updateMasterPlan: (updatedFields: Partial<MasterPlan>) => void;
  allTPAs: Record<number, TPA>;
  masterPlanErrors: MasterPlanErrors;
  setPlanErrors: React.Dispatch<React.SetStateAction<MasterPlanErrors>>;
  handleSnackBar: (message: string) => void;
};

export function PlanNamesForm({
  localMasterPlan,
  updateMasterPlan,
  allTPAs,
  masterPlanErrors,
  setPlanErrors,
  handleSnackBar,
}: ParentProps): JSX.Element {
  const classes = useStyles();
  const [localPlanSelection, setLocalPlanSelection] = useState(
    localMasterPlan.planSelection || false
  );

  const handleSFAccountIDChange: InputChangeHandler = e => {
    updateMasterPlan({ sfAccountID: e.target.value });
    if (e.target.value && e.target.value !== '')
      setPlanErrors({ ...masterPlanErrors, sfAccountID: '' });
  };

  const handlePlanNameChange: InputChangeHandler = e => {
    updateMasterPlan({ masterPlanName: e.target.value });
    if (e.target.value && e.target.value !== '')
      setPlanErrors({ ...masterPlanErrors, masterPlanName: '' });
  };

  const handleStatusChange = (e: ChangeEvent<{ value: unknown }>) => {
    updateMasterPlan({ status: e.target.value });
  };

  const handlePlanSelectionChange = (e: ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value === 'true';
    setLocalPlanSelection(value);
    updateMasterPlan({ planSelection: value });
  };

  const handleTPAIDChange = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    if (typeof e.target.value === 'string') {
      updateMasterPlan({ sixDegTPAID: parseInt(e.target.value, 10) });
    }
  };

  return (
    <div className={classes.planNameForm}>
      <div className={classes.input}>
        <FormControl>
          <InputLabel>TPA</InputLabel>
          <Select
            className={classes.select}
            autoWidth
            onChange={handleTPAIDChange}
            value={allTPAs[localMasterPlan.sixDegTPAID] ? localMasterPlan.sixDegTPAID : ''}
          >
            {Object.entries(allTPAs).map(([id, tpa]) => (
              <MenuItem key={id} value={id}>
                {tpa.tpaName}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="body1" className={classes.alertText}>
            {masterPlanErrors.tpa}
          </Typography>
        </FormControl>
      </div>

      <div className={classes.input}>
        <TextInput
          label="SF Account ID"
          aria-label="SF Account ID"
          value={localMasterPlan.sfAccountID}
          maxLength={18}
          allowedCharRegex="a-zA-Z0-9"
          onChange={handleSFAccountIDChange}
          errorLabel={masterPlanErrors.sfAccountID}
        />
      </div>

      <FormControl>
        <InputLabel>Status</InputLabel>
        <Select
          className={classes.select}
          autoWidth
          onChange={handleStatusChange}
          value={localMasterPlan.status}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </Select>
        <Typography variant="body1" className={classes.alertText}>
          {masterPlanErrors.status}
        </Typography>
      </FormControl>

      <div>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div className={classes.input}>
            <TextInput
              label="Master Plan Name"
              aria-label="Master Plan Name"
              value={localMasterPlan.masterPlanName}
              onChange={handlePlanNameChange}
              errorLabel={masterPlanErrors.masterPlanName}
              className={classes.planNameField}
            />
          </div>
          <div>
            <Typography variant="body2" color="textSecondary">
              Plan Selection
            </Typography>
            <RadioGroup
              aria-label="plan-selection"
              name="plan-selection"
              value={String(localPlanSelection)}
              onChange={handlePlanSelectionChange}
            >
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="Census Names"
              />
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Sub Plan ID"
              />
            </RadioGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanNamesForm;
