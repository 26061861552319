import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FlagIcon from '@material-ui/icons/Flag';
import PanToolIcon from '@material-ui/icons/PanTool';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '@apps/shared/src/style';
import IconButtonCompact from './IconButtonCompact';
import { claimStatusCodes as statusCodes } from '../status';

const useStyles = makeStyles({
  alignCenter: {
    textAlign: 'center',
  },
  tooltipText: {
    fontSize: '1.2em',
  },
  smallIcon: {
    fontSize: '20px',
  },
  blue: {
    color: colors.blue,
  },
  purple: {
    color: colors.purple,
  },
});

export default function FlaggedOnHold({
  status,
  isStatusUpdatePending,
  handleFlaggedClick,
  handleOnHoldClick,
}) {
  const classes = useStyles();
  const flagged = status === statusCodes.flagged || status === statusCodes.adjudicateFlagged;
  const onHold = status === statusCodes.onHold || status === statusCodes.adjudicateHold;

  return (
    <>
      <IconButtonCompact
        onClick={handleFlaggedClick}
        id={`${flagged ? 'flagged' : 'unflagged'}-claim`}
        tooltip={
          !isStatusUpdatePending && (
            <div className={classes.alignCenter}>
              <div className={classes.tooltipText}>Set to Flagged</div>Used for internal questions,
              information or issues
            </div>
          )
        }
        isDisabled={isStatusUpdatePending}
      >
        <FlagIcon className={flagged ? classes.purple : null} />
      </IconButtonCompact>
      <IconButtonCompact
        onClick={handleOnHoldClick}
        id={`${onHold ? 'held' : 'unheld'}-claim`}
        tooltip={
          !isStatusUpdatePending && (
            <div className={classes.alignCenter}>
              <div className={classes.tooltipText}>Set to OnHold</div>Used while awaiting
              contracting, external data or other information
            </div>
          )
        }
        isDisabled={isStatusUpdatePending}
      >
        <PanToolIcon className={classNames(classes.smallIcon, onHold ? classes.blue : null)} />
      </IconButtonCompact>
    </>
  );
}

FlaggedOnHold.defaultProps = {
  status: '',
};
FlaggedOnHold.propTypes = {
  status: PropTypes.string,
  isStatusUpdatePending: PropTypes.bool.isRequired,
  handleFlaggedClick: PropTypes.func.isRequired,
  handleOnHoldClick: PropTypes.func.isRequired,
};
