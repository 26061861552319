import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import HoverableText from '../repricing/HoverableText';
import * as types from '../shared/types/propTypes';
import { validArray } from '../shared/typeChecks';
import { formatDate, getStatementDateString } from '../shared/globals';

const dataTypes = [
  {
    fields: ['statementFromDate', 'statementToDate'],
    description: 'Statement Date',
    formatFxn: getStatementDateString,
  },
  { fields: ['admitDate'], description: 'Admission Date', formatFxn: formatDate },
  { fields: ['dischargeDate'], description: 'Discharge Date', formatFxn: formatDate },
  { fields: ['principalDiagnoses'], description: 'Principal Diagnosis', codeType: 'diagnosis' },
  { fields: ['diagnoses'], description: 'Diagnoses', codeType: 'diagnosis' },
  { fields: ['admittingDiagnoses'], description: 'Admitting Diagnosis', codeType: 'diagnosis' },
  {
    fields: ['externalCauseOfInjury'],
    description: 'External Cause of Injury',
    codeType: 'diagnosis',
  },
  { fields: ['principalProcedureCodes'], description: 'Principal Procedure Codes' },
  { fields: ['otherProcedureCodes'], description: 'Other Procedure Codes' },
  { fields: ['drg'], description: 'Diagnosis Related Group (DRG)', codeType: 'drg' },
  { fields: ['patientHeight'], description: 'Patient Height' },
  { fields: ['patientWeight'], description: 'Patient Weight' },
  {
    fields: ['dischargeStatus'],
    description: 'Patient Discharge Status Code',
    codeType: 'discharge',
  },
];

export function createHoverCodes(codes, type, statementFromDate) {
  const codesArray = typeof codes === 'string' ? [codes] : validArray(codes);
  return codesArray.map((value, i) => (
    <HoverableText key={value.id} type={type} value={value} date={statementFromDate}>
      {i > 0 && ', '} {value}
    </HoverableText>
  ));
}

export function createClaimMetaData(dataTypes, simpleClaim) {
  return dataTypes
    .filter(({ fields }) => validArray(fields).some(field => simpleClaim[field]))
    .map(({ fields, description, formatFxn, codeType }) => {
      let data;
      if (codeType && fields.length > 0) {
        data = createHoverCodes(simpleClaim[fields[0]], codeType, simpleClaim.statementFromDate);
      } else {
        const values = fields.map(field => simpleClaim[field]);
        data =
          typeof formatFxn === 'function' ? formatFxn(...values) : [].concat(...values).join(', ');
      }

      return (
        <div key={description}>
          <strong variant="h6">{inflateString(description)}</strong>
          <div>{data}</div>
        </div>
      );
    });
}

const useStyles = makeStyles({
  root: {
    padding: '1rem',
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    borderTop: `1px solid ${colors.grey05}`,
  },
});

export default function ClaimMetadata({ simpleClaim }) {
  const classes = useStyles();
  if (!Array.isArray(dataTypes) || !simpleClaim) return null;

  return <div className={classes.root}>{createClaimMetaData(dataTypes, simpleClaim)}</div>;
}

ClaimMetadata.propTypes = {
  simpleClaim: types.simpleClaim.isRequired,
};
