/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContractTypes, ContractOwnerTypes } from './contracts';

export function isContractType(t: any): t is ContractTypes {
  return t && typeof t === 'string' && t in ContractTypes;
}

export function isContractOwnerTypes(t: any): t is ContractOwnerTypes {
  return t === 'internal' || t === 'external' || t === '';
}
