import { inflateString, inflateNumber } from '@apps/shared/src/inflators';
import s, { SettlementsActionTypes } from './types/actionTypes';
import {
  SettlementsState,
  SFCase,
  SettlementsClaimSummary,
  ImpactAmounts,
  PlanImpact,
  ImpactChartData,
  SettlementMethodology,
  MediVIProvider,
  SettlementsQueryValues,
} from './types/types';
import { validArray, validObject, isArrayFilled } from '../shared/typeChecks';

export function emptySFCase(): SFCase {
  return {
    caseNumber: '',
    planName: '',
    groupNumber: '',
    tpa: '',
    typeOfCase: '',
    status: '',
    billedAmountTotal: 0,
    repriceTotal: 0,
    balanceBillTotal: 0,
  };
}

export function emptyImpactAmounts(): ImpactAmounts {
  return {
    billedAmount: 0,
    repricedAmount: 0,
    medicareAllowed: 0,
    additionalPayment: 0,
    medicareMultiple: 0,
    proposedSettlementAmount: 0,
  };
}

export function emptyPlanImpact(): PlanImpact {
  return {
    currentFacility: emptyImpactAmounts(),
    currentProfessional: emptyImpactAmounts(),
    proposedProfessionalSettlement: emptyImpactAmounts(),
    proposedFacilitySettlement: emptyImpactAmounts(),
    finalProfessional: emptyImpactAmounts(),
    finalFacility: emptyImpactAmounts(),
  };
}

export const defaultState: SettlementsState = {
  claims: [],
  savedManualSettlementAmounts: {},
  loadingCases: 0,
  isReportLoading: false,
  sfCases: [],
  messages: {},
  caseInputError: '',
  settlementMethodology: 'manual',
  settlementPercent: 0,
  isInitialURLSynced: false,
  displayReportOptions: {
    showCostMultiple: true,
    showChart: true,
    showPlanImpact: true,
  },
  mediVIChartOptions: {
    selectedProvider: {
      name: '',
      ccn: '',
      city: '',
      state: '',
      zip: '',
    },
    providerAutocompleteOptions: [],
    selectedRadius: 100,
    providerLimit: 30,
    showInpatient: true,
    showOutpatient: true,
  },
};

export default (
  state: SettlementsState = defaultState,
  action: SettlementsActionTypes
): SettlementsState => {
  switch (action.type) {
    case s.GET_CASE_SUMMARY_PENDING: {
      return {
        ...state,
        loadingCases: state.loadingCases + 1,
        caseInputError: '',
      };
    }
    case s.GET_CASE_SUMMARY_REJECTED: {
      return {
        ...state,
        loadingCases: state.loadingCases - 1,
        caseInputError: 'There was an error with your submitted case',
      };
    }
    case s.GET_CASE_SUMMARY_FULFILLED: {
      const {
        settlementMethodology,
        settlementPercent,
        savedManualSettlementAmounts,
        loadingCases,
        sfCases,
      } = state;
      const {
        meta: { caseNumber },
        payload: {
          data: { claims, sfCase },
        },
      } = action;

      if (!sfCase)
        return {
          ...state,
          loadingCases: loadingCases - 1,
          caseInputError: `Unknown case number: ${caseNumber}`,
        };

      return {
        ...state,
        claims: state.claims.concat(validArray(claims).map(inflateClaimSummary)).map(claim => {
          const newClaim = { ...claim };
          const newProposedSettlementAmount = savedManualSettlementAmounts[newClaim.id]
            ? savedManualSettlementAmounts[newClaim.id]
            : 0;
          newClaim.proposedSettlementAmount = newProposedSettlementAmount;
          return updateClaimSettlementAmount(newClaim, settlementMethodology, settlementPercent);
        }),
        sfCases: [...sfCases, inflateSFCase(sfCase)],
        loadingCases: state.loadingCases - 1,
      };
    }

    case s.RESET_SETTLEMENTS: {
      return {
        ...defaultState,
        isInitialURLSynced: true,
      };
    }

    case s.REMOVE_CASE: {
      const { caseNumber } = action.meta;
      const { sfCases, claims } = state;
      return {
        ...state,
        sfCases: sfCases.filter(sfCase => sfCase.caseNumber !== caseNumber),
        claims: claims.filter(claim => claim.sfCaseNumber !== caseNumber),
        isReportLoading: false,
        messages: {},
      };
    }

    case s.GET_PROVIDER_REPORT_PENDING:
    case s.GET_IMPACT_REPORT_PENDING: {
      return { ...state, isReportLoading: true };
    }

    case s.GET_PROVIDER_REPORT_REJECTED:
    case s.GET_IMPACT_REPORT_REJECTED: {
      return { ...state, isReportLoading: false };
    }

    case s.GET_PROVIDER_REPORT_FULFILLED:
    case s.GET_IMPACT_REPORT_FULFILLED: {
      const {
        payload: {
          data: { claims, planImpact, messages },
        },
      } = action;

      const inflatedMessages = inflateMessages(messages);

      return anyMessages(inflatedMessages)
        ? {
            ...state,
            messages: inflatedMessages,
            isReportLoading: false,
          }
        : {
            ...state,
            claims: validArray(claims).map(claim => inflateClaimSummary(claim)),
            planImpact: inflatePlanImpact(planImpact),
            isReportLoading: false,
          };
    }

    case s.UPDATE_SETTLEMENT_AMOUNT: {
      const { claimID, amount } = action.meta;

      const allClaims = [...state.claims];
      const claimIndex = allClaims.findIndex(claim => claim.id === claimID);
      if (claimIndex >= 0) {
        allClaims[claimIndex].proposedSettlementAmount = amount;
      }

      return {
        ...state,
        claims: allClaims,
      };
    }

    case s.UPDATE_ALL_SETTLEMENT_AMOUNTS: {
      const { settlementMethodology, settlementPercent } = state;

      const updatedClaims = state.claims.map(claim => {
        return updateClaimSettlementAmount(claim, settlementMethodology, settlementPercent);
      });

      return {
        ...state,
        claims: updatedClaims,
      };
    }

    case s.UPDATE_SETTLEMENT_METHODOLOGY: {
      const { methodology } = action.meta;
      return {
        ...state,
        settlementMethodology: methodology,
      };
    }

    case s.UPDATE_SETTLEMENT_PERCENT: {
      const { settlementPercent } = action.meta;
      return {
        ...state,
        settlementPercent,
      };
    }

    case s.UPDATE_REPORT_DISPLAY_OPTIONS: {
      const { field } = action.meta;
      const newDisplayReportOptions = { ...state.displayReportOptions };
      newDisplayReportOptions[field] = !newDisplayReportOptions[field];

      return {
        ...state,
        displayReportOptions: newDisplayReportOptions,
      };
    }

    case s.UPDATE_MEDIVI_CHART_OPTIONS: {
      const { field, value } = action.meta;

      const newMediVIChartOptions = { ...state.mediVIChartOptions };

      const newFieldType = typeof newMediVIChartOptions[field];
      const valueType = typeof value;
      if (newFieldType !== valueType) return state;

      if (typeof value === 'boolean') (newMediVIChartOptions[field] as boolean) = value;
      else if (typeof value === 'number') (newMediVIChartOptions[field] as number) = value;
      else if (typeof value === 'object') (newMediVIChartOptions[field] as MediVIProvider) = value;

      return {
        ...state,
        mediVIChartOptions: newMediVIChartOptions,
      };
    }

    case s.GET_PROVIDER_AUTOCOMPLETE_FULFILLED: {
      return {
        ...state,
        mediVIChartOptions: {
          ...state.mediVIChartOptions,
          providerAutocompleteOptions: inflateMediVIProviders(action.payload.data),
        },
      };
    }

    case s.GET_MEDIVI_CHART_FULFILLED: {
      return {
        ...state,
        chartData: inflateChartData(action.payload.data),
      };
    }

    case s.TOGGLE_CHART_PRINT_VIEW: {
      return {
        ...state,
        mediVIChartOptions: {
          ...state.mediVIChartOptions,
          printView: !state.mediVIChartOptions.printView,
        },
      };
    }

    case s.SET_SAVED_MANUAL_SETTLEMENT_AMOUNTS: {
      const { amounts } = action.meta;
      const newSavedManualSettlementAmounts = { ...state.savedManualSettlementAmounts };
      Object.keys(amounts).forEach(key => {
        newSavedManualSettlementAmounts[key] = amounts[key];
      });
      return {
        ...state,
        savedManualSettlementAmounts: newSavedManualSettlementAmounts,
      };
    }

    case s.SET_URL_SYNCED: {
      return {
        ...state,
        isInitialURLSynced: action.meta.isURLSynced,
      };
    }

    default:
      return state;
  }
};

export function inflateSFCase(rawCase: unknown): SFCase {
  const raw = validObject(rawCase);
  return {
    caseNumber: inflateString(raw.caseNumber),
    planName: inflateString(raw.planName),
    groupNumber: inflateString(raw.groupNumber),
    tpa: inflateString(raw.tpa),
    typeOfCase: inflateString(raw.typeOfCase),
    status: inflateString(raw.status),
    billedAmountTotal: inflateNumber(raw.billedAmountTotal),
    repriceTotal: inflateNumber(raw.repriceTotal),
    balanceBillTotal: inflateNumber(raw.balanceBillTotal),
  };
}

export function validateAndInflateSFCase(wantedCaseNumber: string, sfCase: unknown): SFCase {
  if (!sfCase) return { ...emptySFCase(), caseNumber: wantedCaseNumber };
  return inflateSFCase(sfCase);
}

export function inflateMessages(rawMessages: unknown): Record<string, string[]> {
  const raw = validObject(rawMessages);
  const messages: Record<string, string[]> = {};
  Object.keys(raw).forEach(msgGroup => {
    messages[inflateString(msgGroup)] = inflateStringArray(raw[msgGroup]);
  });
  return messages;
}

export function inflateStringArray(msgArray: unknown): string[] {
  return validArray(msgArray).map(inflateString);
}

export function inflateClaimSummary(rawSummary: unknown): SettlementsClaimSummary {
  const raw = validObject(rawSummary);
  const inflatedClaimSummary: SettlementsClaimSummary = {
    id: inflateString(raw.id),
    sfCaseNumber: inflateString(raw.sfCaseNumber),
    claimStatus: inflateString(raw.claimStatus),
    policyNumber: inflateString(raw.policyNumber),
    claimNumber: inflateString(raw.claimNumber),
    refNumber: inflateString(raw.refNumber),
    dateOfService: inflateString(raw.dateOfService),
    patientName: inflateString(raw.patientName),
    serviceLocation: inflateString(raw.serviceLocation),
    billingProvider: inflateString(raw.billingProvider),
    billedAmount: inflateNumber(raw.billedAmount),
    medicareAllowed: inflateNumber(raw.medicareAllowed),
    repriceTotal: inflateNumber(raw.repriceTotal),
    additionalPayment: inflateNumber(raw.additionalPayment),
    resultingMedicareMultiple: inflateNumber(raw.resultingMedicareMultiple),
    proposedSettlementAmount: inflateNumber(raw.proposedSettlementAmount),
  };

  if (raw.chargeToCostRatio !== undefined) {
    inflatedClaimSummary.chargeToCostRatio = inflateNumber(raw.chargeToCostRatio);
  }

  return inflatedClaimSummary;
}

export function inflatePlanImpact(rawImpact: unknown): PlanImpact {
  const raw = validObject(rawImpact);
  return {
    currentFacility: inflateImpactAmounts(raw.currentFacility),
    currentProfessional: inflateImpactAmounts(raw.currentProfessional),
    proposedProfessionalSettlement: inflateImpactAmounts(raw.proposedProfessionalSettlement),
    proposedFacilitySettlement: inflateImpactAmounts(raw.proposedFacilitySettlement),
    finalFacility: inflateImpactAmounts(raw.finalFacility),
    finalProfessional: inflateImpactAmounts(raw.finalProfessional),
  };
}

export function inflateChartData(rawChartData: unknown): ImpactChartData {
  const raw = validObject(rawChartData);
  return {
    labels: validArray(raw.labels).map(inflateString),
    inpatientRatios: validArray(raw.inpatientRatios).map(inflateNumber),
    outpatientRatios: validArray(raw.outpatientRatios).map(inflateNumber),
    excludedProviders: validObject(raw.excludedProviders),
  };
}

export function inflateImpactAmounts(rawAmounts: unknown): ImpactAmounts {
  const raw = validObject(rawAmounts);
  const inflatedImpactAmounts: ImpactAmounts = {
    billedAmount: inflateNumber(raw.billedAmount),
    repricedAmount: inflateNumber(raw.repricedAmount),
    medicareAllowed: inflateNumber(raw.medicareAllowed),
    additionalPayment: inflateNumber(raw.additionalPayment),
    medicareMultiple: inflateNumber(raw.medicareMultiple),
    proposedSettlementAmount: inflateNumber(raw.proposedSettlementAmount),
  };

  if (raw.overTarget !== undefined) {
    inflatedImpactAmounts.overTarget = Boolean(raw.overTarget);
  }

  return inflatedImpactAmounts;
}

export function anyMessages(messages: Record<string, string[]>): boolean {
  return Object.values(messages).some(isArrayFilled);
}

export function updateClaimSettlementAmount(
  claim: SettlementsClaimSummary,
  settlementMethodology: SettlementMethodology,
  settlementPercent: number
): SettlementsClaimSummary {
  if (settlementMethodology === 'manual') return claim;
  const updatedClaim = { ...claim };
  updatedClaim.proposedSettlementAmount = parseFloat(
    (claim[settlementMethodology] * (settlementPercent / 100)).toFixed(2)
  );
  return updatedClaim;
}

export function inflateMediVIProvider(rawProvider: unknown): MediVIProvider {
  const raw = validObject(rawProvider);
  return {
    name: inflateString(raw.name),
    ccn: inflateString(raw.ccn),
    city: inflateString(raw.city),
    state: inflateString(raw.state),
    zip: inflateString(raw.zip),
  };
}

export function inflateMediVIProviders(rawProviders: unknown): MediVIProvider[] {
  return validArray(rawProviders).map(inflateMediVIProvider);
}

export function inflateURL(rawQueryValues: unknown): SettlementsQueryValues {
  const raw = validObject(rawQueryValues);

  if (raw.selectedRadius === undefined)
    raw.selectedRadius = defaultState.mediVIChartOptions.selectedRadius;
  if (raw.providerLimit === undefined)
    raw.providerLimit = defaultState.mediVIChartOptions.providerLimit;
  if (raw.showInpatient === undefined)
    raw.showInpatient = defaultState.mediVIChartOptions.showInpatient;
  if (raw.showOutpatient === undefined)
    raw.showOutpatient = defaultState.mediVIChartOptions.showOutpatient;
  if (raw.showChart === undefined) raw.showChart = defaultState.displayReportOptions.showChart;
  if (raw.showCostMultiple === undefined)
    raw.showCostMultiple = defaultState.displayReportOptions.showCostMultiple;
  if (raw.showPlanImpact === undefined)
    raw.showPlanImpact = defaultState.displayReportOptions.showPlanImpact;

  const savedManualSettlementAmounts: Record<string, number> = {};

  Object.keys(raw).forEach(key => {
    const basicMongoIDFilter = /^[0-9a-fA-F]{24}$/;
    if (key.match(basicMongoIDFilter)) savedManualSettlementAmounts[key] = inflateNumber(raw[key]);
  });

  return {
    caseNumber: inflateCaseNumberArray(raw.caseNumber),
    providerLimit: inflateNumber(raw.providerLimit),
    selectedRadius: inflateNumber(raw.selectedRadius),
    settlementPercent: inflateNumber(raw.settlementPercent),
    showChart: Boolean(raw.showChart),
    showCostMultiple: Boolean(raw.showCostMultiple),
    showPlanImpact: Boolean(raw.showPlanImpact),
    showInpatient: Boolean(raw.showInpatient),
    showOutpatient: Boolean(raw.showOutpatient),
    selectedCCN: inflateString(raw.selectedCCN),
    selectedProviderName: inflateString(raw.selectedProviderName),
    settlementMethodology: validSettlementMethodology(raw.settlementMethodology),
    savedManualSettlementAmounts,
  };
}

function validSettlementMethodology(rawString: unknown): SettlementMethodology {
  const stringMethodology = inflateString(rawString);

  if (
    stringMethodology === 'medicareAllowed' ||
    stringMethodology === 'billedAmount' ||
    stringMethodology === 'manual'
  ) {
    return stringMethodology;
  }
  return defaultState.settlementMethodology;
}

function inflateCaseNumberArray(rawCaseNumbers: unknown): string[] {
  if (typeof rawCaseNumbers === 'string') {
    return [rawCaseNumbers];
  }
  return validArray(rawCaseNumbers).map(inflateString);
}
