import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import { Column } from '../../shared/grid';

import TextInput from '../../shared/components/TextInput';
import { defaultMainClientConfig } from './defaultTypes';
import { set } from './mutationFunctions';

const useStyles = makeStyles({
  paper: {
    margin: '15em auto',
    width: '20em',
  },
  title: {
    fontWeight: 'bold',
    padding: '1em',
    color: colors.black,
    backgroundColor: colors.grey05,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },
  column: {
    padding: '1em',
  },
  overwrite: {
    color: colors.white,
    backgroundColor: colors.orange,
    marginTop: '1em',
  },
  cancel: {
    marginTop: '1em',
  },
});

export default function NewConfig({ open, toggleNewConfig, handleNewConfig }) {
  const classes = useStyles();

  const [newConfig, setNewConfig] = useState(defaultMainClientConfig());

  const handleTextChange = field => e => {
    const { value } = e.target;
    const newConfigCopy = { ...newConfig };
    set(newConfigCopy, field, value);
    setNewConfig(newConfigCopy);
  };

  const handleSubmit = newConfig => {
    handleNewConfig(newConfig);
    setNewConfig('');
  };

  const disabled = !(inflateString(newConfig.name).length > 2);

  return (
    <Modal open={open}>
      <Paper className={classes.paper}>
        <div className={classes.title}>New Client Loader Config</div>
        <Column className={classes.column}>
          Enter name for new config:
          <br />
          <TextInput
            label="Config Name"
            fullWidth
            onChange={handleTextChange('name')}
            value={newConfig.name}
          />
          <Button
            className={classes.overwrite}
            variant="contained"
            fullWidth
            disabled={disabled}
            onClick={() => handleSubmit(newConfig)}
          >
            Create
          </Button>
          <Button
            className={classes.cancel}
            variant="contained"
            fullWidth
            onClick={toggleNewConfig}
          >
            Cancel
          </Button>
        </Column>
      </Paper>
    </Modal>
  );
}

NewConfig.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleNewConfig: PropTypes.func.isRequired,
  handleNewConfig: PropTypes.func.isRequired,
};
