import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '@apps/shared/src/style';
import { splitCamelCase } from '@apps/shared/src/utilities';
import { formatDate } from '../../globals';
import * as types from '../../types/propTypes';

const useStyles = makeStyles({
  root: {
    color: colors.white,
    fontSize: '1.3em',
    minWidth: '120px',
  },
  listItemNoBullet: {
    listStyleType: 'none',
    margin: 0,
    padding: '5% 0 5% 10%',
    // width: '100%',
  },
});

export default function HandlersTooltip({ handlers }) {
  const classes = useStyles();

  if (!handlers) return null;
  return Object.keys(handlers).map(h =>
    handlers[h] && handlers[h].fullName ? (
      <p key={h} className={classes.root}>
        <strong>{splitCamelCase(h)}: </strong>
        {`${handlers[h].fullName} ${handlers[h].date ? formatDate(handlers[h].date) : ''}`}
      </p>
    ) : (
      <p key={h} className={classes.root}>
        <strong>{splitCamelCase(h)}(s): </strong>
        {Array.isArray(handlers.dataEnterer) ? (
          handlers.dataEnterer?.map(c => (
            <li className={classes.listItemNoBullet} key={c}>
              {c}
            </li>
          ))
        ) : (
          <li className={classes.listItemNoBullet} key={handlers.dataEnterer}>
            {handlers.dataEnterer}
          </li>
        )}
      </p>
    )
  );
}

HandlersTooltip.defaultProps = {
  handlers: {},
};
HandlersTooltip.propTypes = {
  handlers: types.handlers,
};
