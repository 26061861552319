export const externalNoteTypes = [
  { code: 'DEG', type: 'both', hasFreeText: false, comment: '' },
  { code: 'DUP', type: 'claim', hasFreeText: false, comment: 'Duplicate claim' },
  {
    code: 'CON',
    type: 'both',
    hasFreeText: true,
    comment: 'Repriced according to Direct Contract',
  },
  { code: 'SCA', type: 'both', hasFreeText: true, comment: 'Repriced according to SCA or LOA' },
  { code: 'NTA', type: 'line', hasFreeText: true, comment: 'Not allowed by CMS' },
  { code: 'AAH', type: 'both', hasFreeText: true, comment: 'Allowed amount higher than billed' },
  { code: 'PKG', type: 'line', hasFreeText: true, comment: 'Packaged or Bundled' },
  {
    code: 'CAP',
    type: 'both',
    hasFreeText: false,
    comment: 'Reimbursement capped at allowed amount',
  },
  { code: 'IFO', type: 'both', hasFreeText: true, comment: 'Need more info' },
  { code: 'NRP', type: 'both', hasFreeText: true, comment: 'Not repriced per TPA request' },
  { code: 'STA', type: 'line', hasFreeText: true, comment: 'Status Indicator' },
  { code: 'MEA', type: 'line', hasFreeText: false, comment: 'Measurement code not priced' },
  {
    code: 'OTH',
    type: 'line',
    hasFreeText: false,
    comment: 'CMS uses other code for this service',
  },
  { code: 'COV', type: 'line', hasFreeText: true, comment: 'Non-covered service' },
  { code: 'EXC', type: 'line', hasFreeText: true, comment: 'Excluded code' },
  { code: 'PRO', type: 'line', hasFreeText: false, comment: 'Professional component only code' },
  { code: 'TEC', type: 'line', hasFreeText: false, comment: 'Technical component only code' },
  {
    code: 'PTC',
    type: 'line',
    hasFreeText: false,
    comment: 'Professional/Technical component does not apply',
  },
  {
    code: 'CNR',
    type: 'line',
    hasFreeText: true,
    comment: 'Code not recognized when submitted on this bill type',
  },
  { code: 'INO', type: 'line', hasFreeText: false, comment: 'Inpatient only procedure' },
  { code: 'APC', type: 'line', hasFreeText: true, comment: 'Repriced using composite APC' },
  {
    code: 'MPR',
    type: 'line',
    hasFreeText: true,
    comment: 'Multiple procedure reduction applies',
  },
  {
    code: 'BPR',
    type: 'line',
    hasFreeText: false,
    comment: 'Bilateral procedure reduction applies',
  },
  { code: 'CPF', type: 'line', hasFreeText: true, comment: 'Cash PrePay Fee' },
  { code: 'CPP', type: 'line', hasFreeText: true, comment: 'Cash PrePay Service Item' },
  { code: 'QPA', type: 'line', hasFreeText: false, comment: 'Qualifying Payment Amount' },
];

export const externalStatusNoteTypes = [
  { code: 'DEG', hasFreeText: false, comment: '' },
  { code: 'NEG', hasFreeText: true, comment: 'Contract negotiation in progress' },
  { code: 'IFO', hasFreeText: true, comment: 'Need more info from TPA or Provider' },
  { code: 'INA', hasFreeText: true, comment: 'Internal Audit' },
  { code: 'OTH', hasFreeText: true, comment: 'Other' },
];
