import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    margin: '0',
    paddingLeft: '60px',
  },
});

type ParentProps = {
  content: string[][];
};

export default function FlagText({ content }: ParentProps): JSX.Element | null {
  const classes = useStyles();

  if (content.length === 0) return null;

  return (
    <ul className={classes.root}>
      {content.map(listItems =>
        listItems.map(listItem => (
          <li key={listItem}>
            <Typography variant="body2">{listItem}</Typography>
          </li>
        ))
      )}
    </ul>
  );
}
