import React, { useState, Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import Button from '@material-ui/core/Button';

import { inflateNumber } from '@apps/shared/src/inflators';
import { splitCamelCase } from '@apps/shared/src/utilities';
import { updateMediVIChartOptions } from '../settlementsActions';
import { MediVIChartOptions, MediVIProvider } from '../types/types';
import { RootState, InputChangeHandler } from '../../shared/types/types';
import TextInput from '../../shared/components/TextInput';

const maxValues: Partial<MediVIChartOptions> = {
  providerLimit: 100,
  selectedRadius: 5000,
};

export function limitValue(
  field: keyof MediVIChartOptions,
  submittedValue: number,
  errors: {
    providerLimit: string;
    selectedRadius: string;
  },
  setErrors: Dispatch<SetStateAction<typeof errors>>
): number {
  if (submittedValue < 1) return 1;
  const valueLimit = inflateNumber(maxValues[field]);
  if (submittedValue > valueLimit) {
    setErrors({ ...errors, [field]: `Maximum ${splitCamelCase(field)} is ${maxValues[field]}` });
    return valueLimit;
  }
  setErrors({ ...errors, [field]: '' });
  return submittedValue;
}

const useStyles = makeStyles({
  buttonGroup: {
    width: '100%',
    display: 'flex',
  },
  leftButton: {
    width: '50%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  rightButton: {
    width: '50%',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  providerOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '4.5em',
  },
});

type StateProps = {
  mediVIChartOptions: MediVIChartOptions;
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    mediVIChartOptions: settlements.mediVIChartOptions,
  };
};

const mapDispatchToProps = {
  updateMediVIChartOptions,
};

type DispatchProps = {
  updateMediVIChartOptions: (
    field: keyof MediVIChartOptions,
    value: MediVIProvider | number | boolean
  ) => void;
};

type Props = StateProps & DispatchProps;

export function ProviderOptions({
  updateMediVIChartOptions,
  mediVIChartOptions,
}: Props): JSX.Element | null {
  const classes = useStyles();

  const { providerLimit, selectedRadius, selectedProvider, showInpatient, showOutpatient } =
    mediVIChartOptions;

  const [errors, setErrors] = useState({ providerLimit: '', selectedRadius: '' });
  const [options, setOptions] = useState({ providerLimit, selectedRadius });
  type IntegerField = keyof typeof options;

  const hasProvider = Boolean(selectedProvider.ccn);

  if (!hasProvider) return null;

  const makeIntegerChangeHandler =
    (field: IntegerField): InputChangeHandler =>
    (e): void => {
      const value = limitValue(field, inflateNumber(e.target.value), errors, setErrors);
      setOptions({ ...options, [field]: value });
    };

  const makeKeyDownHandler =
    (field: IntegerField) =>
    (e: KeyboardEvent): void => {
      if (e.keyCode === 38)
        // key up
        setOptions({
          ...options,
          [field]: limitValue(field, inflateNumber(options[field]) + 1, errors, setErrors),
        });
      if (e.keyCode === 40)
        // keydown
        setOptions({
          ...options,
          [field]: limitValue(field, inflateNumber(options[field]) - 1, errors, setErrors),
        });
    };

  const makeClickHandler = (field: keyof MediVIChartOptions) => (): void => {
    const numSelected = [showInpatient, showOutpatient].filter(show => show).length;

    if (numSelected !== 1 || !mediVIChartOptions[field]) {
      updateMediVIChartOptions(field, !mediVIChartOptions[field]);
    }
  };

  const makeBlurHandler = (field: IntegerField) => (): void => {
    setErrors({ ...errors, [field]: '' });
    updateMediVIChartOptions(field, options[field]);
  };

  return (
    <>
      <div className={classes.providerOptions}>
        <TextInput
          label="Provider Limit"
          onChange={makeIntegerChangeHandler('providerLimit')}
          onKeyDown={makeKeyDownHandler('providerLimit')}
          value={options.providerLimit}
          allowedCharRegex="0-9"
          errorLabel={errors.providerLimit}
          onBlur={makeBlurHandler('providerLimit')}
        />
        <TextInput
          label="Search Radius (mi)"
          onChange={makeIntegerChangeHandler('selectedRadius')}
          onKeyDown={makeKeyDownHandler('selectedRadius')}
          value={options.selectedRadius}
          allowedCharRegex="0-9"
          errorLabel={errors.selectedRadius}
          onBlur={makeBlurHandler('selectedRadius')}
        />
      </div>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.leftButton}
          variant={showInpatient ? 'contained' : 'outlined'}
          onClick={makeClickHandler('showInpatient')}
          disableElevation
          color="secondary"
        >
          Inpatient
        </Button>
        <Button
          className={classes.rightButton}
          variant={showOutpatient ? 'contained' : 'outlined'}
          onClick={makeClickHandler('showOutpatient')}
          disableElevation
          color="secondary"
          value="showInpatient"
        >
          Outpatient
        </Button>
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderOptions);
