import React from 'react';
import { inflateString, inflateNumber } from '@apps/shared/src/inflators';
import { ContractPatient } from '../types/contracts';
import PatientTooltipTitle from './PatientTooltipTitle';
import TooltippedChip from './TooltippedChip';

function getChipText(patient: ContractPatient): string {
  if (inflateNumber(patient.id)) return inflateString(patient.name);
  return `* ${inflateString(patient.name)}`;
}

type Props = {
  isEdit: boolean;
  handleRemove: (id: number | string) => void;
  item: ContractPatient;
};
export default function PatientChip({ isEdit, handleRemove, item: patient }: Props): JSX.Element {
  const handleDelete = (): void => {
    const element = patient.id ? patient.id : patient.name;
    handleRemove(element);
  };
  const chipText = getChipText(patient);

  return (
    <TooltippedChip
      chipKey={`${patient.id}${patient.name}`}
      tooltip={PatientTooltipTitle(patient)}
      chipText={chipText}
      onDelete={isEdit ? handleDelete : undefined}
    />
  );
}
