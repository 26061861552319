import React, { ChangeEventHandler } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';

type Props = {
  searchText: string;
  onSearch: (value: string) => void;
  onClearSearch: () => void;
  label?: string;
  tooltipTitle?: string;
};

export default function SearchInput({
  searchText,
  onSearch,
  onClearSearch,
  label = '',
  tooltipTitle = '',
}: Props): JSX.Element {
  const onChange: ChangeEventHandler<HTMLInputElement> = event =>
    onSearch(event.currentTarget.value);

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    endAdornment: searchText && (
      <InputAdornment position="end">
        <IconButton onClick={onClearSearch}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Tooltip title={tooltipTitle}>
      <TextField
        value={searchText}
        label={label}
        onChange={onChange}
        InputProps={inputProps}
        fullWidth
      />
    </Tooltip>
  );
}
