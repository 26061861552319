import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';

import { SettlementsClaimSummary } from '../types/types';
import { RootState } from '../../shared/types/types';
import ProviderSelect from './ProviderSelect';
import ProviderOptions from './ProviderOptions';

const useStyles = makeStyles({
  container: {
    width: '350px',
  },
});

type StateProps = {
  showChart: boolean;
  hasCases: boolean;
  claims: SettlementsClaimSummary[];
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    showChart: settlements.displayReportOptions.showChart,
    hasCases: settlements.sfCases.length > 0,
    claims: settlements.claims,
  };
};

type Props = StateProps & StateProps;

export function MediVIOptions({ showChart, hasCases, claims }: Props): JSX.Element | null {
  const classes = useStyles();

  if (claims.length === 0) return null;

  const showOptions = showChart && hasCases;

  return (
    <div className={classes.container}>
      {showOptions && (
        <>
          <Typography variant="h5">MediVI Options:</Typography>
          <FormGroup>
            <ProviderSelect />
            <ProviderOptions />
          </FormGroup>
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps)(MediVIOptions);
