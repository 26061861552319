import React from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';

export default function ReportSearchInput(props) {
  const {
    searchText,
    handleSearch,
    handleClearSearch,
    handleKeyDown,
    label,
    style,
    id,
    isDate,
    tooltip,
  } = props;

  function formatDateOfBirth(searchText) {
    // Assuming the searchText is in the format "MM/DD/YYYY"
    const [month, day, year] = searchText.split('/');
    if (year && month && day) {
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    return searchText;
  }

  const txtField = (
    <TextField
      style={style}
      value={isDate ? formatDateOfBirth(searchText) : searchText}
      label={label}
      onChange={handleSearch}
      onKeyDown={handleKeyDown}
      id={id}
      type={isDate ? 'date' : 'text'}
      variant="standard"
      helperText={isDate ? 'Date of birth' : ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchText && (
              <ClearIcon id={id} style={{ cursor: 'pointer' }} onClick={handleClearSearch} />
            )}
          </InputAdornment>
        ),
      }}
    />
  );

  return tooltip !== '' ? <Tooltip title={tooltip}>{txtField}</Tooltip> : txtField;
}

ReportSearchInput.defaultProps = {
  tooltip: '',
  style: {},
  handleKeyDown: undefined,
  isDate: false,
};

ReportSearchInput.propTypes = {
  tooltip: PropTypes.string,
  searchText: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func,
  label: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.string),
  isDate: PropTypes.bool,
};
