import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { ClickHandler } from '../../shared/types/types';
import { TopLevelCell } from '../chips/ChipBox';
import {
  insertItemIntoList,
  removeItemAtIndex,
  removeMatchingItem,
  splitSelectedTermItems,
} from '../contractUtilities';
import {
  ContractTerm,
  HandleTermValueChange,
  SetIsElementOpen,
  TermItem,
} from '../types/contracts';
import EditTermButtons from './EditTermButtons';
import { rangeElements, ruleTypeList, termItems } from './termElements';
import TermRulesOrConditions from './TermRulesOrConditions';

const useStyles = makeStyles({
  termBox: {
    marginTop: '1em',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
  },
  rulesAndConditions: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.35em',
  },
});

type Props = {
  currentValues: ContractTerm;
  isTermOpen: boolean;
  selectedTermItems: TermItem[];
  handleChange: HandleTermValueChange;
  handleSubmitNewTerm: VoidFunction;
  setIsElementOpen: SetIsElementOpen;
  handleSetSelectedTermItems: (validTermItems: TermItem[]) => void;
  cancelTerm: VoidFunction;
};

export default function AddTerm({
  currentValues,
  isTermOpen,
  selectedTermItems,
  handleChange,
  handleSubmitNewTerm,
  setIsElementOpen,
  handleSetSelectedTermItems,
  cancelTerm,
}: Props): JSX.Element {
  const classes = useStyles();

  const addTerm: VoidFunction = () => {
    setIsElementOpen('term', true);
  };

  const addTermItem = (newItem: TermItem): void => {
    if (newItem === termItems.rangeType) addRangeElements();
    else {
      const newSelectedTermItems = insertItemIntoList(selectedTermItems, newItem);
      handleSetSelectedTermItems(newSelectedTermItems);
    }
  };

  const removeRule = (index: number): ClickHandler => {
    return (): void => {
      handleSetSelectedTermItems(removeItemAtIndex(selectedTermItems, index));
    };
  };

  const removeCondition = (index: number): ClickHandler => {
    const conditionsOffest = selectedTermItems.filter(i => ruleTypeList.includes(i)).length;
    return (): void => {
      handleSetSelectedTermItems(removeItemAtIndex(selectedTermItems, conditionsOffest + index));
      if (selectedTermItems[conditionsOffest + index] === termItems.rangeType) {
        removeRangeElements();
      }
    };
  };

  const addRangeElements = (): void => {
    handleSetSelectedTermItems([
      ...selectedTermItems,
      termItems.rangeType,
      termItems.startRange,
      termItems.endRange,
      termItems.rangeInclusive,
    ]);
    handleChange({ id: termItems.rangeInclusive, value: true });
    handleChange({ id: termItems.rangeType, value: 'days' });
    handleChange({ id: termItems.startRange, value: 0 });
    handleChange({ id: termItems.endRange, value: 999 });
  };

  const removeRangeElements = (): void => {
    const newTermItems = [...selectedTermItems];
    rangeElements.forEach(el => removeMatchingItem(newTermItems, el));
    handleSetSelectedTermItems(newTermItems);
    handleChange({ id: termItems.rangeInclusive, value: false });
  };

  if (!isTermOpen)
    return (
      <div>
        <Button variant="contained" color="primary" onClick={addTerm} className={classes.termBox}>
          Add Term
        </Button>
      </div>
    );

  const isUpdateTerm = Boolean(currentValues.id);
  const TermIcon = isUpdateTerm ? EditIcon : AddCircleIcon;
  const termHeaderText = isUpdateTerm ? 'Edit Term' : 'New Term';

  const { selectedRules, selectedConditions } = splitSelectedTermItems(selectedTermItems);

  return (
    <Card className={classes.termBox}>
      <TopLevelCell className={classes.top}>
        <TermIcon className={classes.icon} />
        {termHeaderText}
      </TopLevelCell>
      <div className={classes.rulesAndConditions}>
        <TermRulesOrConditions
          itemType="rule"
          selectedTermItems={selectedRules}
          currentValues={currentValues}
          handleChange={handleChange}
          addTermItem={addTermItem}
          removeTermItem={removeRule}
          cancelTerm={cancelTerm}
        />
        <Divider orientation="vertical" flexItem />
        <TermRulesOrConditions
          itemType="condition"
          selectedTermItems={selectedConditions}
          currentValues={currentValues}
          handleChange={handleChange}
          addTermItem={addTermItem}
          removeTermItem={removeCondition}
          cancelTerm={cancelTerm}
        />
      </div>
      <EditTermButtons handleSubmitNewTerm={handleSubmitNewTerm} cancelTerm={cancelTerm} />
    </Card>
  );
}
