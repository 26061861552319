import React from 'react';

import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { splitCamelCase } from '../../../shared/globals';
import Handlers from '../../../shared/components/handlers/Handlers';
import { UIClaimTransaction } from '../../types/manualClaimTypes';

const useStyles = makeStyles(theme => ({
  status: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
  },

  rightDivider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

type Props = {
  ownerFullName?: string;
  handlers?: UIClaimTransaction['handlers'];
  status?: string;
};

export default function OwnerHandlersStatus({
  ownerFullName,
  handlers,
  status,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {ownerFullName && (
        <Typography variant="subtitle1">
          <strong>Owner:</strong> {ownerFullName}
        </Typography>
      )}
      {handlers && <Handlers handlers={handlers} />}
      <div className={classes.status}>
        {status && (
          <Typography variant="subtitle1">
            <strong>Status:</strong> {splitCamelCase(status)}
          </Typography>
        )}
      </div>
    </>
  );
}
