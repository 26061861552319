import axios from 'axios';
import { getAxiosRequestConfig } from '../../utilities';
import u, {
  AddRole,
  ClearInviteStatus,
  GetUsers,
  InviteUser,
  RemoveRole,
  RoleArg,
} from './adminActionTypes';

export function getUsers(app: string): GetUsers {
  return {
    type: u.GET_USERS,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/app-users/${app !== '' ? app : 'undefined'}`,
      getAxiosRequestConfig()
    ),
  };
}

export function invite(email: string, userRoles: string, app: string): InviteUser {
  // eslint-disable-next-line no-nested-ternary
  const tempAppString = app === 'medivi' ? 'MediVI' : app === 'claims' ? 'Claims' : '';
  return {
    type: u.INVITE_USER,
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/invite`,
      {
        application: tempAppString,
        email,
        userRoles,
        destinationURL: `${process.env.REACT_APP_API_BASE_URL || ''}/welcome`,
      },
      getAxiosRequestConfig()
    ),
  };
}

export function clearInviteStatus(): ClearInviteStatus {
  return {
    type: u.CLEAR_INVITE_STATUS,
  };
}

export function addRole({ userID, role }: RoleArg): AddRole {
  return {
    type: u.ADD_ROLE,
    meta: { userID, role },
    payload: axios.put(`/api/users/${userID}/add-role`, { role }, getAxiosRequestConfig()),
  };
}

export function removeRole({ userID, role }: RoleArg): RemoveRole {
  return {
    type: u.REMOVE_ROLE,
    meta: { userID, role },
    payload: axios.put(`/api/users/${userID}/remove-role`, { role }, getAxiosRequestConfig()),
  };
}
