/* eslint-disable @typescript-eslint/no-explicit-any */
import { SimpleUser } from './types';
import { isSimpleUser } from './typeGuards';

export function inflateSimpleUser(raw: any): SimpleUser | undefined {
  if (isSimpleUser(raw)) return raw;
  return undefined;
}

export function inflateSimpleUserFromIDAndName(userID: any, fullName: any): SimpleUser | undefined {
  if (typeof userID === 'string' && typeof fullName === 'string') {
    const simpleUser: SimpleUser = {
      userID,
      fullName,
      roles: [],
    };
    return simpleUser;
  }
  return undefined;
}
