import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import HandlersIcon from '@material-ui/icons/People';
import { colors } from '@apps/shared/src/style';
import IconButtonCompact from '../IconButtonCompact';

import HandlersTooltip from './HandlersTooltip';
import * as types from '../../types/propTypes';

const useStyles = makeStyles(theme => ({
  root: {
    padding: `0 ${theme.spacing(1.5)}px`,
    borderRight: `1px solid ${colors.grey17}`,
  },
}));

export default function Handlers({ handlers }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <IconButtonCompact tooltip={<HandlersTooltip handlers={handlers} />}>
        <HandlersIcon data-testid="HandlersIcon" />
      </IconButtonCompact>
    </div>
  );
}

Handlers.defaultProps = {
  handlers: {},
};
Handlers.propTypes = {
  handlers: types.handlers,
};
