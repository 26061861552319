import { EventSourcePolyfill as EventSource } from 'event-source-polyfill';
import { updateClaimOnEventSource } from './repricing/claimsActions';
import { updateContractOnEventSource } from './contracts/contractsActions';

const appListeners = [
  { type: 'claimUpdate', action: updateClaimOnEventSource },
  { type: 'contractUpdate', action: updateContractOnEventSource },
];

function addListener(eventSource, dispatch) {
  return ({ type, action }) =>
    eventSource.addEventListener(type, e => dispatch(action(JSON.parse(e.data))));
}

function initEventSource(dispatch, initialRetryTimeout) {
  let retryTimeout = initialRetryTimeout;
  const csrfToken = localStorage.getItem('csrfToken');
  const eventSource = new EventSource('/api/events', {
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  });

  appListeners.forEach(addListener(eventSource, dispatch));

  eventSource.onopen = () => {
    retryTimeout = 500;
  };
  eventSource.onerror = e => {
    if (e.target.readyState === EventSource.CLOSED) {
      const nextTimeout = Math.min(retryTimeout * 2, 300000);
      setTimeout(() => initEventSource(dispatch, nextTimeout), retryTimeout);
    }
  };
}

export default function (dispatch) {
  initEventSource(dispatch, 500);
}
