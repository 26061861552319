export function set(obj, path, value) {
  const { schema, pList, len } = setup(obj, path);
  schema[pList[len - 1]] = value;
}

export function unSet(obj, path) {
  const { schema, pList, len } = setup(obj, path);
  delete schema[pList[len - 1]];
}

export function pushTo(obj, path, value) {
  const { schema, pList, len } = setup(obj, path);
  schema[pList[len - 1]].unshift(value);
}

export function removeFrom(obj, path, indexToRemove) {
  const { schema, pList, len } = setup(obj, path);
  schema[pList[len - 1]].splice(indexToRemove, 1);
}

function setup(obj, path) {
  let schema = obj;
  const pList = path.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  return { schema, pList, len };
}
