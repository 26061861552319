import React, { useState, useEffect, ReactNode } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { hexToRgba } from '@apps/shared/src/style';
import flagTypes from './flagTypes';

type makeStylesProps = {
  color: string;
};
const useStyles = makeStyles<null, makeStylesProps>({
  root: {
    marginBottom: '1.5rem',
    backgroundColor: ({ color }): string => hexToRgba(color, 0.2),
  },
  cardContent: {
    paddingTop: '0',
    '&:last-child': {
      paddingBottom: '1rem',
    },
  },
  avatar: {
    background: 'rgba(0, 0, 0, 0)',
  },
  icon: {
    fontSize: '2rem',
    color: ({ color }): string => color,
  },
});

type ParentProps = {
  type: string;
  children?: ReactNode;
  titleOverride?: string;
};

export default function Flag({ type, children, titleOverride }: ParentProps): JSX.Element {
  const { color, warning, label, title, id } = flagTypes[type] || flagTypes.default;
  const classes = useStyles({ color });
  const [flagOpen, setFlagOpen] = useState(true);

  useEffect(() => {
    if (type === 'duplicate') {
      setFlagOpen(false);
    } else {
      setFlagOpen(Boolean(children));
    }
  }, [children]);

  const FlagIcon = warning ? WarningIcon : InfoIcon;
  const ToggleIcon = flagOpen ? ExpandLessIcon : ExpandMoreIcon;

  const handleToggleOpen: VoidFunction = () => setFlagOpen(!flagOpen);

  const flagTitle = (
    <Typography variant="subtitle2">
      {titleOverride || title}
      {children && (
        <IconButton size="small" onClick={handleToggleOpen}>
          <ToggleIcon />
        </IconButton>
      )}
    </Typography>
  );

  return (
    <Card className={classes.root} id={id}>
      <CardHeader
        avatar={
          <Avatar aria-label={label} className={classes.avatar}>
            <FlagIcon className={classes.icon} />
          </Avatar>
        }
        title={flagTitle}
      />
      {flagOpen && <CardContent className={classes.cardContent}>{children}</CardContent>}
    </Card>
  );
}
