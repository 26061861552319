import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { GridColumn } from '../shared/grid';
import { SaveMetadata } from './types/repricingTypes';
import { updateRepricing } from './claimsActions';
import TextInput from './TextInput';

const useStyles = makeStyles({
  root: {
    gridColumn: 'reference / span 1',
  },
});

type DispatchProps = {
  updateRepricing: (updateInfo: SaveMetadata) => void;
};
const mapDispatchToProps = {
  updateRepricing,
};

type ParentProps = {
  refAdj: number;
  reference: number;
  isRefAdjDisabled?: boolean;
  saveMetadata: SaveMetadata;
};

type Props = ParentProps & DispatchProps;

export function ServiceReferences({
  refAdj,
  reference,
  isRefAdjDisabled = false,
  saveMetadata,
  updateRepricing,
}: Props): JSX.Element {
  const styles = useStyles();

  return (
    <>
      <GridColumn className={styles.root}>
        <TextInput
          id={`s${saveMetadata.serviceIndex}-RefAdj`}
          value={refAdj}
          label="Ref Adj"
          labelHover="Reference Adjustment"
          isPercent
          fullWidth
          saveMetadata={{ ...saveMetadata, fieldName: 'repricing.refAdj' }}
          save={updateRepricing}
          disabled={isRefAdjDisabled}
        />
      </GridColumn>
      <GridColumn>
        <TextInput
          id={`s${saveMetadata.serviceIndex}-Reference`}
          value={reference}
          label="Ref"
          labelHover="Reference"
          fullWidth
          isMoney
          saveMetadata={{ ...saveMetadata, fieldName: 'repricing.reference' }}
          save={updateRepricing}
        />
      </GridColumn>
    </>
  );
}

export default connect(null, mapDispatchToProps)(ServiceReferences);
