import React from 'react';

import DownloadIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LoadingIcon from '@apps/shared/src/components/LoadingIcon';

type Props = {
  isDisabled: boolean;
  isDownloading: boolean;
  onClick: () => void;
  tooltipTitle?: string;
};

export default function DownloadToExcelButton({
  isDisabled,
  isDownloading,
  onClick,
  tooltipTitle = 'Download to Excel',
}: Props): JSX.Element {
  return (
    <Tooltip title={tooltipTitle} placement="top">
      <span>
        <IconButton color="primary" disabled={isDisabled || isDownloading} onClick={onClick}>
          <LoadingIcon isLoading={isDownloading}>
            <DownloadIcon />
          </LoadingIcon>
        </IconButton>
      </span>
    </Tooltip>
  );
}
