import React, { useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, Box, LinearProgress, IconButton } from '@mui/material';
import { RadioButtonUncheckedOutlined, CheckCircle, Delete } from '@material-ui/icons';
import { closeModal } from '../fileUploadModalReducer';

const useStyles = makeStyles(() => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: 24,
    padding: '24px',
  },
  headerText: {
    marginBottom: 8,
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start', // Align items from the top
    marginTop: 8,
  },
  iconContainer: {
    marginRight: 8,
    marginTop: '1.5vw', // Space between the circle and the file details
  },
  fileDetailsContainer: {
    display: 'flex',
    flexDirection: 'column', // Stack file name and progress bar vertically
    flexGrow: 1, // Take up available space
  },
  fileNameRow: {
    display: 'flex',
    justifyContent: 'space-between', // File name on the left, delete icon on the right
    alignItems: 'center', // Align vertically center
    height: '3vw',
  },
  fileName: {
    color: props => (props.progress === 100 ? 'green' : 'inherit'),
    textDecoration: 'underline',
  },
  deleteButton: {
    marginLeft: 8,
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '85%',
  },
  progressBar: {
    flexGrow: 1,
    height: '1vh',
    borderRadius: 5, // Make the progress bar rounded
    backgroundColor: '#e0e0e0', // Background of the progress bar when empty
    '& .MuiLinearProgress-bar': {
      height: '1vh',
      borderRadius: 5, // Make the filled portion of the bar rounded
      backgroundColor: props => (props.progress === 100 ? 'green' : 'primary'),
    },
    '& .MuiLinearProgress-root': {
      height: '1vh',
    },
  },
  progressText: {
    marginLeft: 8, // Space between progress bar and percentage
  },
  checkCircle: {
    color: props => (props.progress === 100 ? 'green' : 'action'),
  },
}));

export default function SelectedClaimFileDetailModal({
  open,
  toggleSelectedFileDetail,
  selectedFile,
  setSelectedFile,
}) {
  const [progress, setProgress] = useState(0);
  const classes = useStyles({ progress });

  const handleClose = () => {
    dispatch(closeModal());
    toggleSelectedFileDetail(false);
    return undefined;
  };

  const onDelete = () => {
    setSelectedFile(null);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      let interval;

      /* replace with API progress when claim ingestion is integrated */

      if (progress < 100) {
        const totalTime = 6000; // Total time for progress (6 seconds)
        const increment = 100 / (totalTime / 100); // Increment per 100ms

        interval = setInterval(() => {
          setProgress(prevProgress => {
            const newProgress = Math.min(prevProgress + increment, 100);

            if (newProgress === 100) {
              clearInterval(interval);

              // Set a timeout to close the modal after 2 seconds
              setTimeout(() => {
                handleClose();
              }, 2000);
            }

            return newProgress;
          });
        }, 100);
      }

      return () => clearInterval(interval);
    }
    return undefined;
  }, [open, progress]);

  useEffect(() => {
    if (selectedFile) {
      setProgress(0);
    }
  }, [selectedFile]);
  const dispatch = useDispatch();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modalBox}>
        <Typography id="file-upload-modal" variant="h6" className={classes.headerText}>
          Uploading File
        </Typography>

        {/* Main content container with the circle icon, filename, and progress */}
        <Box className={classes.contentContainer}>
          {/* Circle icon aligned on the left */}
          <Box className={classes.iconContainer}>
            {progress === 100 ? (
              <CheckCircle className={classes.checkCircle} />
            ) : (
              <RadioButtonUncheckedOutlined className={classes.checkCircle} />
            )}
          </Box>

          {/* File details (filename, progress bar, and percentage) */}
          <Box className={classes.fileDetailsContainer}>
            {/* Filename and delete icon on the same row */}
            <Box className={classes.fileNameRow}>
              <Typography variant="body1" className={classes.fileName}>
                {selectedFile?.name}
              </Typography>
              <IconButton className={classes.deleteButton} color="error" onClick={onDelete}>
                <Delete />
              </IconButton>
            </Box>

            {/* Progress bar and percentage in the same row, stacked vertically under the filename */}
            <Box className={classes.progressBarContainer}>
              <LinearProgress
                variant="determinate"
                value={progress}
                className={classes.progressBar}
              />
              <Typography variant="body2" className={classes.progressText}>
                {Math.round(progress)}%
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

SelectedClaimFileDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleSelectedFileDetail: PropTypes.func.isRequired,
  selectedFile: PropTypes.any,
  setSelectedFile: PropTypes.func.isRequired,
};
