import React, { useState, ChangeEvent } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import { getPastMonths } from '../shared/globals';

const useStyles = makeStyles({
  monthSelectWrapper: {
    width: '175px',
  },
  selectLabel: {
    padding: '0 0.25rem',
  },
  monthItem: {
    padding: '0 1rem',
    fontSize: '0.75rem',
    minHeight: '1.5rem',
  },
});

type Props = {
  isDisabled: boolean;
  onSelect: (month: string) => void;
  numMonths?: number;
  label?: string;
};

export default function MonthSelect({
  isDisabled,
  onSelect,
  numMonths = 24,
  label = 'Select a month',
}: Props): JSX.Element {
  const classes = useStyles();
  const [month, setMonth] = useState('');

  const monthOptions = React.useMemo(() => getPastMonths(numMonths), [numMonths]);

  const handleMonthSelect = (e: ChangeEvent<{ value: unknown }>): void => {
    setMonth(e.target.value as string);
    onSelect(e.target.value as string);
  };

  return (
    <FormControl disabled={isDisabled} className={classes.monthSelectWrapper}>
      <InputLabel id="month-select-label" className={classes.selectLabel}>
        {label}
      </InputLabel>
      <Select
        id="month-select"
        labelId="month-select-label"
        value={month}
        onChange={handleMonthSelect}
      >
        {monthOptions.map(month => (
          <MenuItem key={month} value={month} className={classes.monthItem}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
