import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import DownloadToExcelButton from '../DownloadToExcelButton';
import ReportSearchInput from '../ReportSearchInput';
import RefreshButton from './RefreshButton';
import ViewSelectedClaimsSwitch from './ViewSelectedClaimsSwitch';
import FilterByTPAButton from './FilterByTPAButton';

import * as types from '../../shared/types/propTypes';

const useStyles = makeStyles({
  filterHeader: {
    padding: '0.5em 0',
    top: '0',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default function ClaimsAgingHeader(props) {
  const classes = useStyles();

  const {
    dataFetchedDate,
    allTPAs,
    selectedTPAs,
    selectedIDs,
    handleViewSelectedClaims,
    viewSelectedClaims,
    filteredAging,
    updateSelectedTPAs,
    handleExportClick,
    loadReportData,
    formatStartDate,
    formatEndDate,
    startDate,
    endDate,
    startDateErr,
    endDateErr,
    handleSearch,
    handleClearSearch,
    searchText,
    showOnlyAging,
    toggleShowOnlyAging,
    loading,
    isDownloading,
  } = props;

  return (
    <div className={classes.filterHeader}>
      <div>
        <FilterByTPAButton
          allTPAs={allTPAs}
          selectedTPAs={selectedTPAs}
          updateSelectedTPAs={updateSelectedTPAs}
          formatStartDate={formatStartDate}
          formatEndDate={formatEndDate}
          startDate={startDate}
          endDate={endDate}
          startDateErr={startDateErr}
          endDateErr={endDateErr}
          showOnlyAging={showOnlyAging}
          toggleShowOnlyAging={toggleShowOnlyAging}
        />
        <ReportSearchInput
          style={{ width: '12em', margin: '0 1em' }}
          searchText={searchText}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
          label="Search Filtered Claims"
          tooltip="Searches will NOT be reflected in Report Download"
        />
        <ViewSelectedClaimsSwitch
          filteredAging={filteredAging}
          selectedIDs={selectedIDs}
          onChange={handleViewSelectedClaims}
          viewSelectedClaims={viewSelectedClaims}
        />
        {searchText ? (
          <Tooltip title="Export is not allowed while searching. Please clear your search to proceed">
            <span>
              <IconButton disabled>
                <DownloadIcon color="error" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <DownloadToExcelButton
            onClick={handleExportClick}
            isDisabled={loading || (filteredAging && filteredAging.length === 0)}
            isDownloading={isDownloading}
          />
        )}
      </div>
      <div>
        <span>Data Last Fetched: {dataFetchedDate}</span>
        <RefreshButton tooltip="Refresh Data" onClick={loadReportData} />
      </div>
    </div>
  );
}

ClaimsAgingHeader.propTypes = {
  dataFetchedDate: PropTypes.string.isRequired,
  allTPAs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTPAs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleViewSelectedClaims: PropTypes.func.isRequired,
  viewSelectedClaims: PropTypes.bool.isRequired,
  filteredAging: PropTypes.arrayOf(types.reportRow).isRequired,
  updateSelectedTPAs: PropTypes.func.isRequired,
  handleExportClick: PropTypes.func.isRequired,
  loadReportData: PropTypes.func.isRequired,
  formatStartDate: PropTypes.func.isRequired,
  formatEndDate: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  startDateErr: PropTypes.string.isRequired,
  endDateErr: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  showOnlyAging: PropTypes.bool.isRequired,
  toggleShowOnlyAging: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
};
