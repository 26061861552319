import u, { UserActionTypes } from '../auth/userActionTypes';
import r, { RegisterActionTypes } from './types/registerActions';
import { RegisterState } from './types/register';
import { isCredentialsInUseResponse } from '../addPlan/types/addPlanActions';

export const makeDefaultRegisterState = (): RegisterState => ({
  isVerifying: false,
  userInfo: {
    email: '',
    password: '',
    passwordConfirm: '',
  },
  loginInfo: {
    email: '',
    password: '',
  },
  errorMessage: '',
  hasAccount: false,
  pageCount: 1,
  currentPage: 1,
});

export default function registerReducer(
  state: RegisterState = makeDefaultRegisterState(),
  action: RegisterActionTypes | UserActionTypes
): RegisterState {
  switch (action.type) {
    case r.VERIFY_USER_CREDENTIALS_PENDING:
    case r.VERIFY_USER_EMAIL_PENDING:
    case r.ADD_USER_PENDING:
      return { ...state, isVerifying: true };

    case r.VERIFY_USER_CREDENTIALS_REJECTED:
    case r.VERIFY_USER_EMAIL_REJECTED:
    case r.ADD_USER_REJECTED: {
      return {
        ...state,
        isVerifying: false,
      };
    }

    case r.VERIFY_USER_CREDENTIALS_FULFILLED: {
      if (isCredentialsInUseResponse(action.payload.data)) return { ...state, isVerifying: false };

      return {
        ...state,
        isVerifying: false,
        currentPage: Math.min(state.currentPage + 1, state.pageCount),
      };
    }
    case r.VERIFY_USER_EMAIL_FULFILLED: {
      return {
        ...state,
        isVerifying: false,
        hasAccount: action.payload.data.hasAccount,
        currentPage: Math.min(state.currentPage + 1, state.pageCount),
      };
    }
    case r.ADD_USER_FULFILLED: {
      if (action.meta.redirectTo === '/provider-search') {
        return {
          ...state,
          isVerifying: false,
          userInfo: {
            ...state.userInfo,
            password: '',
            passwordConfirm: '',
          },
          loginInfo: {
            ...state.loginInfo,
            password: '',
          },
        };
      }
      return {
        ...state,
        isVerifying: false,
      };
    }

    case r.SET_LOGIN_INFO: {
      const { key, value } = action.meta;
      return { ...state, loginInfo: { ...state.loginInfo, [key]: value } };
    }
    case r.SET_USER_INFO: {
      const { key, value } = action.meta;
      return { ...state, userInfo: { ...state.userInfo, [key]: value } };
    }
    case r.RESET_REGISTER_PAGES:
      return { ...state, ...makeDefaultRegisterState() };
    case r.PREVIOUS_PAGE:
      return {
        ...state,
        currentPage: Math.max(state.currentPage - 1, 1),
        userInfo: {
          email: state.userInfo.email,
          password: '',
          passwordConfirm: '',
        },
      };
    case r.NEXT_PAGE:
      return { ...state, currentPage: Math.min(state.currentPage + 1, state.pageCount) };
    case r.SET_PAGE_COUNT:
      return { ...state, pageCount: action.meta };

    case u.LOGIN_FULFILLED:
    case u.LOGOUT_FULFILLED:
    case u.REQUEST_PASSWORD_RESET_FULFILLED:
      return {
        ...state,
        userInfo: { ...state.userInfo, password: '', passwordConfirm: '' },
        loginInfo: { ...state.loginInfo, password: '' },
      };

    default:
      return state;
  }
}
