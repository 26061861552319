import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const styles = {
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    marginLeft: '6.7rem',
  },
  button: {
    marginRight: '5px',
    marginLeft: '5px',
    minWidth: '1.2rem !important',
    height: '1.2rem',
  },
  icon: {
    height: '.5em !important',
  },
  select: {
    marginLeft: '10px',
    marginRight: '10px',
  },
};

const Pagination = ({
  currentPage,
  pageSize,
  totalPages,
  onPageChange,
  onPageSizeChange,
  classes,
}) => {
  const handlePreviousPage = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  return (
    <div className={classes.pagination}>
      <Select
        value={pageSize}
        onChange={e => onPageSizeChange(e.target.value)}
        className={classes.select}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
      <Button
        variant="contained"
        color="primary"
        onClick={handlePreviousPage}
        disabled={currentPage === 1} // Disable if on first page
        className={classes.button}
      >
        <ArrowBackIosIcon className={classes.icon} />
      </Button>
      <span>
        {currentPage}&nbsp;/{totalPages}&nbsp;
      </span>
      <Button
        variant="contained"
        color="primary"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        className={classes.button}
      >
        <ArrowForwardIosIcon className={classes.icon} />
      </Button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Pagination);
