import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';

import { colors } from '@apps/shared/src/style';
import { anyMessages } from './settlementsReducer';

const useStyles = makeStyles({
  caseWarnings: {
    padding: '5px',
    margin: '10px',
  },
  caseNumber: {
    fontSize: '1.1em',
  },
  warning: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  warningIcon: {
    color: colors.red,
    fontSize: '1.15em',
    paddingRight: '5px',
  },
  noWarning: {
    color: colors.grey50,
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkIcon: {
    fontSize: '1.15em',
    paddingRight: '5px',
  },
});

type ParentProps = {
  warnings: Record<string, string[]>;
};

export default function Warnings({ warnings }: ParentProps): JSX.Element | null {
  const classes = useStyles();

  if (!anyMessages(warnings)) return null;

  const reportWarnings = warnings.Other;
  const caseWarnings = { ...warnings };
  delete caseWarnings.Other;
  const caseWarningsExist = Object.values(caseWarnings).some(sfCase => sfCase.length > 0);

  return (
    <>
      <Typography variant="h5">Problems with Requested Report:</Typography>
      {reportWarnings.map(warning => (
        <ListItem key={warning} className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          {warning}
        </ListItem>
      ))}
      {caseWarningsExist &&
        Object.keys(caseWarnings).map(key => {
          const caseWarnings = warnings[key];
          return (
            <div className={classes.caseWarnings} key={key}>
              <Typography className={classes.caseNumber}>Salesforce Case: {key}</Typography>
              <List>
                {caseWarnings.length > 0 ? (
                  caseWarnings.map(item => (
                    <ListItem key={item} className={classes.warning}>
                      <WarningIcon className={classes.warningIcon} />
                      {item}
                    </ListItem>
                  ))
                ) : (
                  <ListItem className={classes.noWarning}>
                    <CheckIcon className={classes.checkIcon} />
                    No issues with this case
                  </ListItem>
                )}
              </List>
            </div>
          );
        })}
    </>
  );
}
