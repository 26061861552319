import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';

import * as typesProps from '../shared/types/propTypes';
import FinalReportItems from './FinalReportItems';
import PDFPrintClassWrapper from './PDFPrintClassWrapper';
import { toggleChartPrintView } from './settlementsActions';

const mapStateToProps = ({ settlements }) => {
  return {
    planImpact: settlements.planImpact,
  };
};

const mapDispatchToProps = {
  toggleChartPrintView,
};

const useStyles = makeStyles({
  printContainer: {
    position: 'relative',
  },
  printButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
});

async function wait(timeout) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
}

// Left as javascript. Ref objects disagreed with being typed
function PrintableReports({ planImpact, toggleChartPrintView }) {
  const classes = useStyles();
  const componentRef = useRef();

  const hasReport = Boolean(planImpact);

  const resizeChartAndWait = async () => {
    toggleChartPrintView();
    await wait(1000);
  };

  return (
    <div className={classes.printContainer}>
      <ReactToPrint
        trigger={() => (
          <Button
            display={hasReport ? 'block' : 'hidden'}
            variant="contained"
            color="secondary"
            disabled={!hasReport}
            className={classes.printButton}
          >
            Print Report to PDF
          </Button>
        )}
        content={() => componentRef.current}
        onBeforeGetContent={resizeChartAndWait}
        onAfterPrint={toggleChartPrintView}
      />
      <PDFPrintClassWrapper ref={componentRef}>
        <FinalReportItems />
      </PDFPrintClassWrapper>
    </div>
  );
}

PrintableReports.defaultProps = {
  toggleChartPrintView: undefined,
  planImpact: {},
};

PrintableReports.propTypes = {
  toggleChartPrintView: PropTypes.func,
  planImpact: typesProps.PlanImpact,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintableReports);
