import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import FormHelperText from '@material-ui/core/FormHelperText';
import Downshift, { StateChangeOptions } from 'downshift';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import AutocompleteSelect, { PlanOption } from '../shared/components/AutocompleteSelect';
import useUpdatableState from '../shared/hooks/useUpdatableState';

import { planErrorMessages } from '../shared/globals';
import { validArray } from '../shared/typeChecks';
import { RootState } from '../shared/types/types';
import { Plan } from '../repricing/types/repricingTypes';

function plansByTPA(plans: Plan[], sixDegTPAID?: number, senderName?: string): Plan[] {
  if (senderName === 'ClaimsBridge' || sixDegTPAID === undefined) return validArray(plans);
  return validArray(plans).filter(plan => plan.sixDegTPAID === sixDegTPAID);
}

function filterPlans(plans: Plan[], policyInput: string): Plan[] {
  return plans.filter(p => inflateString(p.policyNum).startsWith(policyInput));
}

const useStyles = makeStyles({
  policyNum: {
    color: colors.black,
    textTransform: 'uppercase',
  },
  msg: {
    paddingTop: '5px',
    color: colors.greyDark,
  },
  err: {
    color: colors.red,
  },
});

type StateProps = {
  plans: Plan[];
};
const mapStateToProps = ({ claims }: RootState): StateProps => {
  return {
    plans: claims.plans,
  };
};

type ParentProps = {
  planName: string;
  policyNum: string;
  save: (
    policyNum: string,
    sixDegTPAID?: number,
    sixDegPlanID?: number,
    shouldSave?: boolean
  ) => void;
  isEditable: boolean;
  showLabel: boolean;
  errorLabel: string;
  showTitle: boolean;
  sixDegTPAID?: number;
  sixDegPlanID?: number;
  senderName?: string;
};

type Props = StateProps & ParentProps;

function Policy({
  plans,
  planName,
  showTitle,
  save,
  policyNum,
  isEditable,
  showLabel,
  errorLabel,
  sixDegTPAID,
  senderName,
}: Props): JSX.Element {
  const tpaPlans = plansByTPA(plans, sixDegTPAID, senderName);
  const [filteredPlans, setFilteredPlans] = useState(filterPlans(tpaPlans, policyNum));

  const [open, setOpen] = useState(false);
  const [input, setInput] = useUpdatableState(policyNum);
  const [tpa, setTPA] = useUpdatableState(sixDegTPAID);

  const classes = useStyles();

  useEffect(() => {
    const tpaPlans = plansByTPA(plans, sixDegTPAID, senderName);
    setFilteredPlans(filterPlans(tpaPlans, policyNum));
  }, [plans, policyNum, sixDegTPAID, senderName]);

  const errStyle =
    Boolean(errorLabel) || Object.values(planErrorMessages).includes(planName)
      ? classes.err
      : undefined;
  const helperText = errorLabel || planName;

  const handleSearch = (e: React.FormEvent<HTMLInputElement>): void => {
    const newInput = e.currentTarget.value.toUpperCase();
    setInput(newInput);
    setFilteredPlans(filterPlans(tpaPlans, newInput));
    if (newInput.length > 1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleBlur = (): void => {
    if (input !== policyNum) {
      const matchingPlans = plans.filter(p => p.policyNum === input);
      const tpaID = matchingPlans.length === 1 ? matchingPlans[0].sixDegTPAID : tpa;
      savePlan(input, tpaID);
    }
    setOpen(false);
  };

  const handleSelect = (plan: Plan): void => {
    if (plan?.policyNum && plan?.sixDegTPAID && plan?.sixDegPlanID) {
      setTPA(plan.sixDegTPAID);
      setInput(plan.policyNum);
      savePlan(plan.policyNum, plan.sixDegTPAID, plan.sixDegPlanID);
    }
    setOpen(false);
  };

  const savePlan = (policyNum: string, sixDegTPAID?: number, sixDegPlanID?: number): void => {
    save(policyNum, sixDegTPAID, sixDegPlanID, true);
    setFilteredPlans(filterPlans(tpaPlans, policyNum));
  };

  const handleFocus = (): void => {
    if (input && input.length > 1 && filteredPlans.length > 1) setOpen(true);
  };

  const handleStateChange = (changes: StateChangeOptions<Plan>): void => {
    if (
      (changes.type === Downshift.stateChangeTypes.keyDownArrowDown ||
        changes.type === Downshift.stateChangeTypes.keyDownArrowUp) &&
      changes.highlightedIndex !== null &&
      changes.highlightedIndex !== undefined
    ) {
      const { policyNum, sixDegTPAID } = filteredPlans[changes.highlightedIndex];
      setInput(policyNum);
      setTPA(sixDegTPAID);
    }
  };

  return (
    <>
      {showTitle && (
        <div>
          <strong>Policy</strong>
        </div>
      )}
      {isEditable ? (
        <div>
          <AutocompleteSelect
            RenderComponent={PlanOption}
            inputValue={input}
            isOpen={open}
            onChange={handleSearch}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onStateChange={handleStateChange}
            placeholder="Policy/Group Number"
            options={filteredPlans}
            onSelect={handleSelect}
            label={showLabel ? 'Policy/Group Number' : ''}
            compact
          />
          <FormHelperText>
            <span className={errStyle || classes.msg}>{helperText}</span>
          </FormHelperText>
        </div>
      ) : (
        <div>
          <div>{policyNum}</div>
          <div className={errStyle || classes.policyNum}>{planName}</div>
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps)(Policy);
