import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { InputChangeHandler, FocusHandler } from '../../shared/types/types';
import { UpdateContract } from '../types/contracts';

type Props = {
  providerGroup: string;
  isEdit: boolean;
  updateContract: UpdateContract;
};
export default function ContractProviderGroup({
  providerGroup,
  isEdit,
  updateContract,
}: Props): JSX.Element {
  const [value, setValue] = useState(providerGroup);

  if (!isEdit) return <Typography>{providerGroup}</Typography>;

  const handleChange: InputChangeHandler = e => {
    setValue(e.target.value);
  };

  const handleBlur: FocusHandler = () => {
    updateContract('providerGroup', value);
  };

  return (
    <TextField
      label="Provider Group"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
    />
  );
}
