import React from 'react';

import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import { colors } from '@apps/shared/src/style';
import DisplayDateRange from './DisplayDateRange';
import ContractIcon from './ContractIcon';

import { ContractSummary, HandleListClick, ContractTypesDisplay } from '../types/contracts';
import { KeyHandler } from '../../shared/types/types';

const useStyles = makeStyles({
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '4em',
    width: '100%',
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: colors.hovered,
    },
    boxSizing: 'border-box',
    padding: '0 0.25rem',
  },
  selected: {
    backgroundColor: `${colors.selected} !important`,
  },
  item: {
    width: '85%',
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    color: colors.grey50,
    fontSize: '.85em',
  },
  patientProvider: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

type Props = {
  contract: ContractSummary;
  handleListClick: HandleListClick;
  isSelected: boolean;
};

export default function ContractListItem({
  contract,
  handleListClick,
  isSelected,
}: Props): JSX.Element {
  const classes = useStyles();
  const { id, status, patientSummary, startDate, endDate, providerGroup, contractType } = contract;

  const handleItemClick: VoidFunction = () => handleListClick(id);
  const onKeyDown: KeyHandler = e => {
    if (e.key === 'Enter') handleListClick(id);
  };

  return (
    <div
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
      className={classNames(classes.itemWrapper, isSelected && classes.selected)}
      key={id}
      onClick={handleItemClick}
    >
      <ContractIcon status={status} type={contractType} />
      <div className={classes.item}>
        <div className={classes.itemHeader}>
          <div>{ContractTypesDisplay[contractType]}</div>
          <DisplayDateRange start={startDate} end={endDate} />
        </div>
        <div className={classes.patientProvider}>
          <strong>{providerGroup}</strong>
        </div>
        <div className={classes.patientProvider}>
          <strong>{patientSummary}</strong>
        </div>
      </div>
    </div>
  );
}
