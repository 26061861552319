export function inflateString(str: unknown): string {
  switch (typeof str) {
    case 'string':
      return str;
    case 'number':
      return str.toString();
    default:
      return '';
  }
}

export function inflateNumber(num: unknown): number {
  return Number.isNaN(Number(num)) ? 0 : Number(num);
}
