import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getErrorText } from '../shared/validators';
import TextInput from '../shared/components/TextInput';
import DateInput from './DateInput';

export default class IndividualOrOrganization extends Component {
  handleTextChange = name => e => this.props.updateValue(name, e.target.value.toUpperCase());
  handleDateChange = name => value => this.props.updateValue(name, value);

  render() {
    const { data, isReadOnly, patient } = this.props;
    return (
      <>
        <TextInput
          readOnly={isReadOnly}
          label="Last Name"
          value={data.lastNameOrOrg}
          onChange={this.handleTextChange('lastNameOrOrg')}
          errorLabel={getErrorText(data, 'lastNameOrOrg')}
          id={`${patient ? 'patient' : 'subscriber'}-last-name`}
        />
        <TextInput
          readOnly={isReadOnly}
          label="First Name"
          value={data.firstName}
          onChange={this.handleTextChange('firstName')}
          errorLabel={getErrorText(data, 'firstName')}
          id={`${patient ? 'patient' : 'subscriber'}-first-name`}
        />
        <TextInput
          readOnly={isReadOnly}
          label="ID Number"
          value={data.id}
          onChange={this.handleTextChange('id')}
          errorLabel={getErrorText(data, 'id')}
          id={`${patient ? 'patient' : 'subscriber'}-id`}
        />
        <DateInput
          readOnly={isReadOnly}
          label="Date of Birth"
          value={data.dateOfBirth}
          onValueChange={this.handleDateChange('dateOfBirth')}
          errorLabel={getErrorText(data, 'dateOfBirth')}
          id={`${patient ? 'patient' : 'subscriber'}-dob`}
        />
        <TextInput
          readOnly={isReadOnly}
          label="Zip"
          allowedCharRegex="\d"
          maxLength={5}
          value={data.zip}
          onChange={this.handleTextChange('zip')}
          errorLabel={getErrorText(data, 'zip')}
          id={`${patient ? 'patient' : 'subscriber'}-zip`}
        />
      </>
    );
  }
}

IndividualOrOrganization.defaultProps = {
  patient: false,
};

IndividualOrOrganization.propTypes = {
  data: PropTypes.shape({
    // Replace shape with types.person when manual claim is converted to use simple claim
    lastNameOrOrg: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    dateOfBirth: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  updateValue: PropTypes.func.isRequired,
  patient: PropTypes.bool,
};
