import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  underline: {
    textDecoration: 'underline dotted',
  },
});

type Props = {
  stringArr: string[];
};

export default function TooltipStringArray({ stringArr }: Props): JSX.Element | null {
  const classes = useStyles();

  if (stringArr.length === 0) return null;
  if (stringArr.length === 1) return <span>{stringArr[0]}</span>;

  return (
    <Tooltip title={stringArr.join(', ')}>
      <span className={classes.underline}>Multiple</span>
    </Tooltip>
  );
}
