import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { claimStatusCodes } from '../../../shared/status';
import { ClickHandler, RootState } from '../../../shared/types/types';
import { UIClaimTransaction } from '../../types/manualClaimTypes';
import OwnerHandlersStatus from './OwnerHandlersStatus';
import NewManualClaimButtons from './NewManualClaimButtons';
import EditManualClaimButtons from './EditManualClaimButtons';
import ValidateManualClaimButtons from './ValidateManualClaimButtons';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingRight: 0,
    justifyContent: 'flex-end',
  },
}));

type ParentProps = {
  isDataEnterer: boolean;
  handleSaveClick: ClickHandler;
  handleCompleteAndSubmitClick: ClickHandler;
  handleNewClaimClick: ClickHandler;
  validateClaim: (id: string) => void;
  invalidateClaim: (id: string) => void;
  handleSaveAndRepriceClick: ClickHandler;
  handleDeleteClick: ClickHandler;
};

type StateProps = {
  selectedClaim: UIClaimTransaction;
  isEdit: boolean;
};

type Props = ParentProps & StateProps;

export function ManualClaimFormFooter({
  selectedClaim,
  isEdit,
  isDataEnterer,
  handleSaveClick,
  handleCompleteAndSubmitClick,
  handleNewClaimClick,
  handleDeleteClick,
  validateClaim,
  invalidateClaim,
  handleSaveAndRepriceClick,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleValidateClick: ClickHandler = () => validateClaim(selectedClaim?.id || '');
  const handleEntryErrorClick: ClickHandler = () => invalidateClaim(selectedClaim?.id || '');

  const isValidate =
    selectedClaim.status === claimStatusCodes.repriceNew &&
    selectedClaim?.receiveConfig === 'manual entry';
  const isNew = selectedClaim?.status === undefined;

  return (
    <Grid container className={classes.root} spacing={2}>
      <OwnerHandlersStatus
        ownerFullName={selectedClaim.owner?.fullName}
        handlers={selectedClaim.handlers}
        status={selectedClaim.status}
      />

      {isNew && (
        <NewManualClaimButtons
          handleSaveClick={handleSaveClick}
          handleCompleteAndSubmitClick={handleCompleteAndSubmitClick}
          handleNewClaimClick={handleNewClaimClick}
        />
      )}

      {isValidate && (
        // <ValidateManualClaimButtons
        //   // isDataEnterer={isDataEnterer}
        //   handleValidateClick={handleValidateClick}
        //   handleEntryErrorClick={handleEntryErrorClick}
        // />
        <EditManualClaimButtons
          handleSaveClick={handleSaveClick}
          handleDeleteClick={handleDeleteClick}
          handleSaveAndRepriceClick={handleSaveAndRepriceClick}
          handleCompleteAndSubmitClick={handleCompleteAndSubmitClick}
          selectedClaim={selectedClaim}
        />
      )}

      {!isValidate && isEdit && (
        <EditManualClaimButtons
          handleSaveClick={handleSaveClick}
          handleDeleteClick={handleDeleteClick}
          handleSaveAndRepriceClick={handleSaveAndRepriceClick}
          handleCompleteAndSubmitClick={handleCompleteAndSubmitClick}
          selectedClaim={selectedClaim}
        />
      )}
    </Grid>
  );
}

const mapStateToProps = ({ manualClaim }: RootState): StateProps => ({
  selectedClaim: manualClaim.selectedClaim,
  isEdit: manualClaim.isEdit,
});

export default connect(mapStateToProps)(ManualClaimFormFooter);
