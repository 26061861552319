import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer, List as VirtualList } from 'react-virtualized';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { colors } from '@apps/shared/src/style';

import {
  getAgingReport,
  updateExtStatusNote,
  exportAgingReport,
  updateSelectedTPAs,
  updateSelectedIDs,
  toggleViewSelectedClaims,
  clearSelectedIDs,
  updateAndFilterAging,
  updateFilteredAging,
  formatReportDate,
  showOnlyAgingClaims,
  updateAgingSearchText,
  selectAllIDs,
} from '../reportsActions';

import * as types from '../../shared/types/propTypes';
import ExternalStatusNote from '../../repricing/ExternalStatusNote';
import ClaimsAgingHeader from './ClaimsAgingHeader';

const claimHeight = 25;
const aboveTheTable = '168px';
const leftOfTheTable = '230px';
const styles = {
  progress: {
    marginTop: '30vh',
    width: `calc(100vw - ${leftOfTheTable})`,
    textAlign: 'center',
    outline: 'none',
  },
  checkboxStyle: { height: `${claimHeight}px`, padding: '0' },
  greyText: { color: colors.grey67 },
  leftAlign: { textAlign: 'left' },
  agingReport: {
    maxWidth: `calc(100vw - ${leftOfTheTable})`,
    minWidth: '1275px',
    height: 'calc(100vh - 65px)',
  },
  gridDefinition: {
    display: 'grid',
    gridTemplateAreas:
      '" checkbox    date amount provider patient tpa filename planID claimNum refNum type status comment extNote"',
    gridTemplateColumns:
      'min-content 6em   4em     12em     12em   6em   8em    4em     8em     8em   1em   6em     14em    50em',
    gridGap: '0.5em',
    padding: '0.5em 0',
    fontSize: '0.75em',
    zIndex: '1',
    height: `calc(${claimHeight}px - 2em)`,
  },
  gridHeader: {
    fontWeight: '500',
    backgroundColor: colors.greyDark,
    color: colors.white,
    height: '1.5em',
  },
  crop: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  selectClaim: {
    gridArea: 'checkbox',
    marginTop: '-0.3em',
  },
  provider: { gridArea: 'provider' },
  tpa: { gridArea: 'tpa' },
  planID: { gridArea: 'planID' },
  status: { gridArea: 'status' },
  date: { gridArea: 'date' },
  type: { gridArea: 'type' },
  patient: { gridArea: 'patient' },
  filename: { gridArea: 'filename' },
  amount: {
    gridArea: 'amount',
    fontWeight: '500',
  },
  claimNum: { gridArea: 'claimNum' },
  refNum: { gridArea: 'refNum' },
  comment: { gridArea: 'comment' },
  extNote: { gridArea: 'extNote' },
  modal: {
    top: aboveTheTable,
    left: `calc(${leftOfTheTable} - 2em)`,
    zIndex: '2',
  },
};

function AgingReportTableHeader({
  selectedIDs,
  filteredAging,
  clearSelectedIDs,
  selectAllClaims,
  classes,
  loading,
}) {
  if (loading) return null;

  const hasSelected = filteredAging.filter(c => selectedIDs.includes(c.id)).length > 0;
  return (
    <div className={classNames(classes.gridDefinition, classes.gridHeader)}>
      <span className={classes.selectClaim}>
        <Tooltip title={hasSelected ? 'Clear Selected Claims' : 'Select All Claims'}>
          <Checkbox
            checked={selectedIDs && !hasSelected}
            className={classes.leftAlign}
            style={styles.checkboxStyle}
            onChange={hasSelected ? clearSelectedIDs : selectAllClaims}
            indeterminateIcon={<IndeterminateCheckBox color="secondary" />}
            indeterminate={hasSelected}
          />
        </Tooltip>
      </span>
      <span title="Date Received" className={classes.date}>
        Date
      </span>
      <span title="TPA" className={classes.tpa}>
        TPA
      </span>
      <span title="Plan ID" className={classes.planID}>
        Plan ID
      </span>
      <span title="Claim Number" className={classes.claimNum}>
        Claim Number
      </span>
      <span title="Internal Comment" className={classes.comment}>
        Internal Comment
      </span>
      <span title="Billed Amount" className={classes.amount}>
        Billed
      </span>
      <span title="Patient" className={classes.patient}>
        Patient
      </span>
      <span title="Reference Number" className={classes.refNum}>
        Ref Number
      </span>
      <span title="Claim Type" className={classes.type}>
        Type/Status
      </span>
      <span title="External Status Note" className={classes.extNote}>
        External Status Note
      </span>
    </div>
  );
}

AgingReportTableHeader.propTypes = {
  selectedIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  filteredAging: PropTypes.arrayOf(types.reportRow).isRequired,
  clearSelectedIDs: PropTypes.func.isRequired,
  selectAllClaims: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
};

const AgingReportTableHeaderStyled = withStyles(styles)(AgingReportTableHeader);

function AgingReportTableData({
  filteredAging,
  selectedIDs,
  classes,
  handleSelectIDChange,
  handleExtStatusNoteSave,
  loading,
}) {
  if (loading) return null;

  const renderRow = ({ index, key, style }) => {
    const r = filteredAging[index];
    const idSelected = Array.isArray(selectedIDs) && selectedIDs.includes(r.id);
    const claimNum = r.claimNums == null && r.refNums == null ? r.id : r.claimNums;

    return (
      <div key={key} style={style}>
        <div key={key} style={{ ...styles.gridDefinition }}>
          <span title="Click to Select" className={classes.selectClaim}>
            <Checkbox
              checked={idSelected}
              value={r.id}
              onChange={handleSelectIDChange}
              className={classes.leftAlign}
              style={styles.checkboxStyle}
            />
          </span>
          <span title={`Date Received: ${r.dateReceived}`} className={classes.date}>
            {r.dateReceived.split(' ')[0]}
          </span>
          <span title={`TPA: ${r.tpa}`} className={classNames(classes.crop, classes.tpa)}>
            {r.tpa}
          </span>
          <span
            title={`Plan ID: ${r.policyNum}`}
            className={classNames(classes.crop, classes.planID)}
          >
            {r.policyNum}
          </span>
          <span
            title={`Claim Number: ${claimNum}`}
            className={classNames(classes.crop, classes.claimNum)}
          >
            <Link to={`/repricing/${r.id}`}>{claimNum}</Link>
          </span>
          <span title={`Status: ${r.status}`} className={classNames(classes.crop, classes.status)}>
            {r.status}
          </span>
          <span
            title={`Internal Comment: ${r.intComment}`}
            className={classNames(classes.comment, classes.greyText, classes.crop)}
          >
            {r.intComment}
          </span>
          <span title={`Billed Amount: ${r.billedAmt.toFixed(2)}`} className={classes.amount}>
            ${r.billedAmt.toFixed(0)}
          </span>
          <span
            title={`Patient: ${r.patients || r.subscribers}`}
            className={classNames(classes.patient, classes.greyText, classes.crop)}
          >
            {r.patients || r.subscribers}
          </span>
          <span
            title={`Reference Number: ${r.refNums}`}
            className={classNames(classes.crop, classes.refNum)}
          >
            <Link to={`/repricing/${r.id}`}>{r.refNums}</Link>
          </span>
          <span title="Claim Type" className={classes.type}>
            {r.claimTypes}
          </span>
          <span title="External Status Note" className={classes.extNote}>
            <ExternalStatusNote
              type="aging"
              value={r.extStatusNote}
              save={handleExtStatusNoteSave}
              saveMetadata={{ claimID: r.id }}
            />
          </span>
        </div>
      </div>
    );
  };

  renderRow.propTypes = types.RenderRow;

  return (
    <AutoSizer>
      {({ width, height }) => (
        <VirtualList
          id="aging-list"
          style={{ height: `calc(100vh - ${aboveTheTable})` }}
          width={width}
          height={height}
          rowHeight={claimHeight}
          rowRenderer={renderRow}
          rowCount={filteredAging.length}
        />
      )}
    </AutoSizer>
  );
}

AgingReportTableData.propTypes = {
  filteredAging: PropTypes.arrayOf(types.reportRow).isRequired,
  selectedIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSelectIDChange: PropTypes.func.isRequired,
  handleExtStatusNoteSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const AgingReportTableDataStyled = withStyles(styles)(AgingReportTableData);

class ClaimsAging extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataFetchedDate: new Date().toLocaleString(),
    };
  }

  componentDidMount() {
    this.loadReportData();
  }

  loadReportData = () => {
    this.props.dispatch(getAgingReport()).then(() => {
      this.props.dispatch(updateFilteredAging());
      this.setState({ dataFetchedDate: new Date().toLocaleString() });
    });
  };

  selectAllClaims = () => this.props.dispatch(selectAllIDs());

  handleSelectIDChange = e => {
    const { viewSelectedClaims, dispatch } = this.props;
    if (viewSelectedClaims) {
      dispatch(updateAndFilterAging(updateSelectedIDs(e.currentTarget.value)));
    } else {
      dispatch(updateSelectedIDs(e.currentTarget.value));
    }
  };

  handleViewSelectedClaims = () =>
    this.props.dispatch(updateAndFilterAging(toggleViewSelectedClaims()));

  handleClearSelectedIDs = () => {
    const { selectedIDs, filteredAging, viewSelectedClaims, dispatch } = this.props;
    const ids = selectedIDs.filter(id => filteredAging.findIndex(c => c.id === id) !== -1);
    if (viewSelectedClaims) {
      dispatch(updateAndFilterAging(clearSelectedIDs(ids)));
    } else {
      dispatch(clearSelectedIDs(ids));
    }
  };

  handleUpdateSelectedTPAs = e =>
    this.props.dispatch(updateAndFilterAging(updateSelectedTPAs(e.currentTarget.value)));

  handleExtStatusNoteSave = v => {
    this.props.dispatch(updateExtStatusNote(v));
  };

  handleExportClick = () => this.props.dispatch(exportAgingReport());

  formatStartDate = formattedDate =>
    this.props.dispatch(formatReportDate(formattedDate, 'startDate'));

  formatEndDate = formattedDate => this.props.dispatch(formatReportDate(formattedDate, 'endDate'));

  toggleShowOnlyAging = () => {
    this.props.dispatch(updateAndFilterAging(showOnlyAgingClaims())).then(() => {
      if (this.props.showOnlyAging) {
        this.props
          .dispatch(getAgingReport())
          .then(() => this.props.dispatch(updateFilteredAging()));
      }
    });
  };

  handleSearch = e =>
    this.props.dispatch(updateAndFilterAging(updateAgingSearchText(e.currentTarget.value)));

  handleClearSearch = () => this.props.dispatch(updateAndFilterAging(updateAgingSearchText('')));

  render() {
    const {
      loading,
      isDownloading,
      filteredAging,
      allTPAs,
      selectedTPAs,
      selectedIDs,
      viewSelectedClaims,
      startDate,
      endDate,
      startDateErr,
      endDateErr,
      showOnlyAging,
      classes,
      searchText,
    } = this.props;
    const { dataFetchedDate } = this.state;

    return (
      <div className={classes.agingReport}>
        <ClaimsAgingHeader
          dataFetchedDate={dataFetchedDate}
          allTPAs={allTPAs}
          selectedTPAs={selectedTPAs}
          selectedIDs={selectedIDs}
          viewSelectedClaims={viewSelectedClaims}
          handleViewSelectedClaims={this.handleViewSelectedClaims}
          filteredAging={filteredAging}
          handleExportClick={this.handleExportClick}
          loadReportData={this.loadReportData}
          updateSelectedTPAs={this.handleUpdateSelectedTPAs}
          formatStartDate={this.formatStartDate}
          formatEndDate={this.formatEndDate}
          startDate={startDate}
          endDate={endDate}
          startDateErr={startDateErr}
          endDateErr={endDateErr}
          toggleShowOnlyAging={this.toggleShowOnlyAging}
          showOnlyAging={showOnlyAging}
          handleSearch={this.handleSearch}
          handleClearSearch={this.handleClearSearch}
          searchText={searchText}
          loading={loading}
          isDownloading={isDownloading}
        />
        <LoadingSpinner containerClassName={classes.progress} isLoading={loading} />
        {!loading && filteredAging && filteredAging.length === 0 ? (
          <Typography variant="h5">No Claims to Display</Typography>
        ) : (
          <>
            <AgingReportTableHeaderStyled
              selectedIDs={selectedIDs}
              filteredAging={filteredAging}
              clearSelectedIDs={this.handleClearSelectedIDs}
              selectAllClaims={this.selectAllClaims}
              loading={loading}
            />
            <AgingReportTableDataStyled
              filteredAging={filteredAging}
              selectedIDs={selectedIDs}
              handleSelectIDChange={this.handleSelectIDChange}
              handleExtStatusNoteSave={this.handleExtStatusNoteSave}
              loading={loading}
            />
          </>
        )}
      </div>
    );
  }
}

ClaimsAging.propTypes = {
  loading: PropTypes.bool.isRequired,
  filteredAging: PropTypes.arrayOf(types.reportRow).isRequired,
  allTPAs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTPAs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  viewSelectedClaims: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  startDateErr: PropTypes.string.isRequired,
  endDateErr: PropTypes.string.isRequired,
  showOnlyAging: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  searchText: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool.isRequired,
};

function mapStateToProps({ reports }) {
  return {
    loading: reports.loading,
    isDownloading: reports.isDownloading,
    filteredAging: reports.filteredAging,
    allTPAs: reports.allTPAsInReport,
    selectedTPAs: reports.selectedTPAs,
    startDate: reports.startDateFilter,
    endDate: reports.endDateFilter,
    startDateErr: reports.startDateError,
    endDateErr: reports.endDateError,
    selectedIDs: reports.selectedIDs,
    showOnlyAging: reports.showOnlyAging,
    viewSelectedClaims: reports.viewSelectedClaims,
    searchText: reports.agingSearchText,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ClaimsAging));
