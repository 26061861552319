import React from 'react';
import PropTypes from 'prop-types';
import { FlexColumn } from '../shared/grid';

export default function LabeledFlexColumn({ children, label }) {
  return (
    <FlexColumn>
      <strong>{label}</strong>
      {children}
    </FlexColumn>
  );
}

LabeledFlexColumn.defaultProps = {
  children: undefined,
};

LabeledFlexColumn.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
};
