import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import TextInput from '../../../shared/components/TextInput';
import { TermInputProps } from '../../types/contracts';

const useStyles = makeStyles({
  termInput: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '7em',
    marginLeft: '1em',
  },
});

export default function PercentInput({ value, handleChange, id }: TermInputProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.termInput}>
      <TextInput
        max={1000}
        id={id}
        isPercent
        value={+value || ''}
        onChange={handleChange}
        autoFocus
      />
    </div>
  );
}
