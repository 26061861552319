import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import { colors } from '@apps/shared/src/style';
import { SettlementsClaimSummary } from '../types/types';
import { RootState } from '../../shared/types/types';

import ImpactClaimsHeaderRow from './ImpactClaimsHeaderRow';
import ImpactClaimsTotalsRow from './ImpactClaimsTotalsRow';
import ImpactClaimsTableRow from './ImpactClaimsTableRow';

const useStyles = makeStyles({
  root: {
    pageBreakBefore: 'always',
  },
  title: {
    marginRight: '2em',
  },
  card: {
    marginBottom: '1em',
    width: 'fit-content',
  },
  greyText: {
    color: colors.greyDark,
  },
  tableTitleContent: {
    marginTop: '2em',
    display: 'flex',
    alignItems: 'baseline',
  },
});

type StateProps = {
  claims: SettlementsClaimSummary[];
  showCostMultiple: boolean;
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    claims: settlements.claims,
    showCostMultiple: settlements.displayReportOptions.showCostMultiple,
  };
};

type Props = StateProps;

export function ImpactClaims({ claims, showCostMultiple }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tableTitleContent}>
        <Typography variant="h5" className={classes.title}>
          Claim Details
        </Typography>
      </div>
      <Card className={classes.card}>
        <Table size="small">
          <TableHead>
            <ImpactClaimsHeaderRow showCostMultiple={showCostMultiple} />
            <ImpactClaimsTotalsRow showCostMultiple={showCostMultiple} claims={claims} />
          </TableHead>
          <TableBody>
            {claims.map(claim => (
              <ImpactClaimsTableRow
                key={claim.id}
                claim={claim}
                showCostMultiple={showCostMultiple}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default connect(mapStateToProps)(ImpactClaims);
