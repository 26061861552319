import {
  required,
  updateAndValidate,
  validateAll,
  isBeforeNow,
  checkDate,
  validate,
  checkUnits,
  checkPlaceOfSvc,
  checkDiagCodePtr,
} from '../shared/validators';
import { UPDATE_VALUE, VALIDATE } from './rootReducer';

export const ADD_SERVICE_ITEM = 'ADD_SERVICE_ITEM';
export const REMOVE_SERVICE_ITEM = 'REMOVE_SERVICE_ITEM';
export const VALIDATE_PROFESSIONAL = 'VALIDATE_PROFESSIONAL';

export function addServiceItem(path, index) {
  return { type: `${path}/${ADD_SERVICE_ITEM}`, payload: { index } };
}

export function removeServiceItem(path, index) {
  return { type: `${path}/${REMOVE_SERVICE_ITEM}`, payload: { index } };
}

export function updateServiceValue(path, index, name, value) {
  return { type: `${path}/${UPDATE_VALUE}`, payload: { index, name, value } };
}

const errorTextGetter = {
  procModString: state => {
    const procModString = state.procModString.replace(/^[\s,:]+|[\s,:]+$/g, ''); // remove leading and trailing delimiters
    const modifiers = procModString.split(/[\s,:]+/);
    return modifiers.length > 4 ||
      (procModString !== '' && !/^(?:\w{1,2}(?:[\s,:]+|$))+$/i.test(procModString))
      ? '0-4 modifiers'
      : '';
  },
  units: state => checkUnits(state.units),
  state: state => required(state.billed),
  serviceDate: state => {
    const [isValid, , error] = checkDate(state.serviceDate);
    return isValid ? isBeforeNow(state.serviceDate) : error;
  },
  placeOfSvc: state => checkPlaceOfSvc(state.placeOfSvc),
  diagnosisCodePointer: state => checkDiagCodePtr(state.diagnosisCodePointers),
};

export const newUIService = () => {
  return {
    revCode: '',
    procCode: '',
    procModString: '',
    serviceDate: '',
    placeOfSvc: '',
    diagnosisCodePointers: [1],
    units: 1,
    billedCharge: 0,
  };
};

export const serviceItemReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_VALUE:
      return updateAndValidate(
        state,
        action.payload.name,
        action.payload.value,
        errorTextGetter[action.payload.name],
        true
      );

    case VALIDATE_PROFESSIONAL:
      return state.errors.placeOfSvc === ''
        ? {
            ...state,
            errors: {
              ...state.errors,
              placeOfSvc: validate(required, state.placeOfSvc),
              serviceDate: validate(required, state.serviceDate),
            },
          }
        : state;

    default:
      return state;
  }
};

export const servicesReducer = (state = [newUIService()], action) => {
  switch (action.type) {
    case VALIDATE:
      return state.map(i => validateAll(i, errorTextGetter));

    case ADD_SERVICE_ITEM: {
      const index = action.payload.index !== undefined ? action.payload.index : state.length;
      state.splice(index, 0, newUIService());
      return [...state];
    }

    case REMOVE_SERVICE_ITEM:
      state.splice(action.payload.index, 1);
      return [...state];

    case UPDATE_VALUE:
      return [
        ...state.slice(0, action.payload.index),
        serviceItemReducer(state[action.payload.index], action),
        ...state.slice(action.payload.index + 1),
      ];

    default:
      return state;
  }
};

export default servicesReducer;
