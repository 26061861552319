import { colors } from '@apps/shared/src/style';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  NumberFormatCustom,
  NumberFormatFixed,
  NumberFormatMax,
} from '../shared/components/TextInputNumberFormats';
import { isNumeric } from '../shared/globals';
import * as types from '../shared/types/propTypes';

const styles = {
  inputInput: {
    fontSize: '14px',
    textAlign: 'center',
    padding: '0 0 4px',
  },
  inputUnderline: {
    '&$warning:after': {
      borderBottomColor: colors.yellow,
    },
  },
  formHelperTextRoot: {
    marginTop: '2px',
    fontSize: '13px',
    textAlign: 'center',
    color: colors.blueLight,
    userSelect: 'none',
    cursor: 'text',
    '&$warning': {
      color: colors.yellow,
    },
  },
  warning: {},
};

function setCaretPosition(elem, caretPos) {
  if (elem.createTextRange) {
    const range = elem.createTextRange();
    range.move('character', caretPos);
    range.select();
  } else if (elem.setSelectionRange) {
    elem.setSelectionRange(caretPos, caretPos);
  }
}

function resizeMultiline(elem) {
  // eslint-disable-next-line no-param-reassign
  elem.style.height = '1px';
  // eslint-disable-next-line no-param-reassign
  elem.style.height = `${elem.scrollHeight - 4}px`;
  // testing default size without math.min
  // elem.style.height = `${Math.min(elem.scrollHeight - 4, 75)}px`;
}
export function parseValue(v, isMoney, isPercent) {
  if (typeof v === 'string') {
    if (v === '') {
      return {
        formattedValue: v,
        isNumeric: false,
      };
    }
    const lastChar = v[v.length - 1];
    let trimmed = v[0] === '$' && isMoney ? v.slice(1) : v; // Remove $ prefix
    trimmed = lastChar === '%' && isPercent ? trimmed.slice(0, -1) : trimmed; // Remove % suffix
    // Ends with . or decimal ending with zero
    if (v.indexOf('.') !== -1 && ((lastChar === '0' && !isMoney) || lastChar === '.')) {
      return {
        formattedValue: trimmed,
        isNumeric: isNumeric(trimmed),
      };
    }
    return getValue(trimmed, isMoney, isPercent);
  }
  if (Number.isNaN(v)) {
    return {
      formattedValue: '',
      isNumeric: false,
    };
  }
  return {
    formattedValue: formatNumber(v, isMoney, isPercent),
    isNumeric: true,
  };
}
export function formatNumber(v, isMoney, isPercent) {
  if (isMoney === true) {
    return `${(Math.round(v * 100) / 100).toFixed(2)}`; // Always 2 decimal places
  }
  if (isPercent === true) {
    return `${Math.round(v * 100) / 100}`; // 2 decimal places
  }
  return `${Math.round(v * 10000) / 10000}`; // 4 decimal places
}
export function getValue(v, isMoney, isPercent) {
  const numeric = isNumeric(v);
  return {
    formattedValue: numeric ? formatNumber(v, isMoney, isPercent) : v,
    isNumeric: numeric,
  };
}
export function getState(value, isText, isMoney, isPercent) {
  let v = value != null ? value : '';
  v = typeof v === 'number' ? v.toString() : v;
  if (isText === true) {
    return {
      savedValue: v,
      currentValue: v,
    };
  }
  const n = parseValue(v, isMoney, isPercent);
  return {
    savedValue: n.formattedValue,
    currentValue: n.formattedValue,
    cursorPosition: 0,
    isNumeric: n.isNumeric,
  };
}
class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = getState(props.value, props.isText, props.isMoney, props.isPercent, props.onClick);
    if (props.multiline === true) {
      this.multilineRef = React.createRef();
    }
  }

  componentDidMount() {
    if (this.props.multiline === true) {
      resizeMultiline(this.multilineRef.current);
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      getState(nextProps.value, nextProps.isText, nextProps.isMoney, nextProps.isPercent)
    );
  }

  handleChange = e => {
    const { isMoney, isPercent } = this.props;
    const elem = e.target;
    const v = parseValue(e.target.value, isMoney, isPercent);
    const prefixAdder = isMoney === true && v.isNumeric ? 1 : 0;
    const suffixPosition =
      isPercent && this.state.cursorPosition >= v.formattedValue.length
        ? v.formattedValue.length
        : 0;
    this.setState({ currentValue: v.formattedValue, isNumeric: v.isNumeric }, () => {
      const caretPos =
        suffixPosition > 0 ? suffixPosition : this.state.cursorPosition + prefixAdder;
      setCaretPosition(elem, caretPos);
    });
  };

  handleKeyDown = e => {
    if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
      const { savedValue } = this.state;
      this.setState({
        currentValue: savedValue,
        isNumeric: isNumeric(savedValue),
        cursorPosition: 0,
      });
      return;
    }
    if (
      e.key === '.' &&
      e.target.selectionStart === e.target.selectionEnd &&
      e.target.value[e.target.selectionStart] === '.'
    ) {
      setCaretPosition(e.target, e.target.selectionStart + 1);
      e.preventDefault();
      return;
    }
    // Figure out cursor position (excluding the prefix and suffix)
    const isVisibleKey = e.key.length === 1;
    let newPosition = e.target.selectionStart + (isVisibleKey ? 1 : 0);
    if (newPosition !== 0 && (e.key === 'Backspace' || e.keyCode === 8)) {
      newPosition--;
    }
    if (e.target.value === '.' && e.key >= '0' && e.key <= '9') {
      newPosition++; // This means we'll end up with 0.x. Adding in the leading zero requires us to move one more space
    }
    if (
      e.target.value[0] === '$' &&
      this.props.isMoney === true &&
      e.target.selectionStart !== 0 &&
      newPosition > 0
    ) {
      // Back out the prefix addition
      newPosition--;
    }
    this.setState({ cursorPosition: newPosition });
  };

  handleFocus = e => {
    e.target.select();
  };

  handleBlur = () => {
    const { save, isText, saveMetadata } = this.props;
    const { currentValue, savedValue } = this.state;
    if (save && currentValue !== savedValue) {
      if (isText === true) {
        save({ ...saveMetadata, stringVal: currentValue });
      } else {
        save({ ...saveMetadata, numberVal: Number(currentValue) });
      }
      this.setState({ savedValue: currentValue });
    }
  };

  updateTextValue = e => {
    if (this.props.multiline === true) {
      resizeMultiline(e.target);
    }
    this.setState({ currentValue: e.target.value });
  };

  handleTextAreaNewLineInput = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      // Reset field height
      e.target.style.height = 'inherit';
      // Get the computed styles for the element
      const computed = window.getComputedStyle(e.target);
      // Calculate the height
      const height =
        parseInt(computed.getPropertyValue('border-top-width'), 10) +
        parseInt(computed.getPropertyValue('padding-top'), 10) +
        e.target.scrollHeight +
        parseInt(computed.getPropertyValue('padding-bottom'), 10) +
        parseInt(computed.getPropertyValue('border-bottom-width'), 10);
      e.target.style.height = `${height}px`;
    }
  };

  render() {
    const {
      multiline,
      disabled,
      style,
      maxLength,
      isText,
      classes,
      label,
      labelHover,
      validation = {},
      isMoney,
      isPercent,
      isMoneyFixed,
      margin,
      fullWidth,
      id,
      isEditable,
      isReadOnly,
    } = this.props;
    const { currentValue, isNumeric } = this.state;
    if (multiline) {
      return (
        <textarea
          disabled={disabled || isReadOnly}
          onKeyUp={this.handleTextAreaNewLineInput}
          id={id}
          readOnly={isReadOnly}
          value={this.state.currentValue}
          onBlur={this.handleBlur}
          onChange={this.updateTextValue}
          style={{ resize: 'vertical', ...style }}
          maxLength={maxLength}
          ref={this.multilineRef}
        />
      );
    }
    if (isText) {
      return (
        <input
          disabled={disabled || isReadOnly}
          readOnly={isReadOnly}
          id={id}
          value={this.state.currentValue}
          onBlur={this.handleBlur}
          onChange={this.updateTextValue}
          style={style}
          maxLength={maxLength}
        />
      );
    }
    const prefix = isMoney === true && isNumeric ? '$' : '';
    const suffix = isPercent === true && isNumeric ? '%' : '';
    const warningClassName = validation.warning && !validation.error ? classes.warning : null;
    let errorLabel = validation.showValidation && (validation.error || validation.warning);
    let errorLabelShort =
      validation.showValidation && (validation.errorShort || validation.warningShort);
    if (!isNumeric && currentValue !== '') {
      errorLabel = 'Value must be numeric';
      errorLabelShort = 'Non-numeric';
    }
    let inputComponent;
    if (isMoney) {
      inputComponent = NumberFormatCustom;
    } else if (isMoneyFixed) {
      inputComponent = NumberFormatFixed;
    } else if (isPercent) {
      inputComponent = NumberFormatMax;
    }
    return (
      <FormControl
        error={Boolean(errorLabel)}
        disabled={disabled || isReadOnly}
        margin={margin}
        fullWidth={fullWidth}
      >
        <Input
          readOnly={isEditable}
          id={id}
          value={prefix + currentValue + suffix}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onClick={this.props.onClick}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          className={warningClassName}
          classes={{
            input: classes.inputInput,
            underline: classes.inputUnderline,
          }}
          inputComponent={inputComponent}
          startAdornment={
            isMoney || isMoneyFixed ? (
              <InputAdornment style={{ marginRight: '0.11em' }} position="start">
                $
              </InputAdornment>
            ) : undefined
          }
          endAdornment={isPercent ? <InputAdornment position="end">%</InputAdornment> : undefined}
        />
        <label htmlFor={id} title={errorLabel || labelHover}>
          <FormHelperText
            disabled={false}
            className={warningClassName}
            classes={{
              root: classes.formHelperTextRoot,
            }}
          >
            {errorLabelShort || label}
          </FormHelperText>
        </label>
      </FormControl>
    );
  }
}
TextInput.defaultProps = {
  disabled: false,
  style: undefined,
  maxLength: undefined,
  label: undefined,
  labelHover: undefined,
  validation: {},
  margin: undefined,
  fullWidth: false,
  id: undefined,
  value: '',
  isText: false,
  isMoney: false,
  isMoneyFixed: false,
  isPercent: false,
  multiline: false,
  saveMetadata: {},
  save: null,
  isEditable: false,
};
TextInput.propTypes = {
  disabled: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  label: PropTypes.string,
  labelHover: PropTypes.string,
  validation: types.validation,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isText: PropTypes.bool,
  isMoney: PropTypes.bool,
  isMoneyFixed: PropTypes.bool,
  isPercent: PropTypes.bool,
  multiline: PropTypes.bool,
  saveMetadata: types.saveMetadata,
  save: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isEditable: PropTypes.bool,
};
export default withStyles(styles, { name: 'TextInput' })(TextInput);
