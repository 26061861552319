import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { formatCurrency } from '@apps/shared/src/utilities';

import { SettlementsClaimSummary } from '../types/types';
import { formatDate, formatFloat } from '../../shared/globals';

const useStyles = makeStyles({
  patientName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '10px',
  },
});

type ParentProps = {
  claim: SettlementsClaimSummary;
  showCostMultiple: boolean;
};

type Props = ParentProps;

export default function ImpactClaimsTableRow({ claim, showCostMultiple }: Props): JSX.Element {
  const classes = useStyles();
  const ccrDisplay = claim.chargeToCostRatio ? formatFloat(claim.chargeToCostRatio) : '-';

  return (
    <TableRow key={claim.id}>
      <TableCell className={classes.patientName}>{claim.patientName}</TableCell>
      <TableCell>{formatDate(claim.dateOfService)}</TableCell>
      <TableCell align="right">{formatCurrency(claim.billedAmount)}</TableCell>
      <TableCell align="right">{formatCurrency(claim.medicareAllowed)}</TableCell>
      <TableCell align="right">{formatCurrency(claim.repriceTotal)}</TableCell>
      <TableCell align="right">{formatCurrency(claim.additionalPayment)}</TableCell>
      <TableCell align="right">{formatCurrency(claim.proposedSettlementAmount)}</TableCell>
      <TableCell align="right">{formatFloat(claim.resultingMedicareMultiple)}</TableCell>
      {showCostMultiple && <TableCell align="right">{ccrDisplay}</TableCell>}
    </TableRow>
  );
}
