import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import TermInputSelection from './TermInputSelection';
import { TermItem, ContractTerm, HandleTermValueChange } from '../../types/contracts';
import { ruleNameLookup, conditionNameLookup } from '../termElements';
import { ClickHandler } from '../../../shared/types/types';

const useStyles = makeStyles({
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

type Props = {
  itemType: 'rule' | 'condition';
  selectedTermItems: TermItem[];
  currentValues: ContractTerm;
  handleChange: HandleTermValueChange;
  removeTermItem: (index: number) => ClickHandler;
  cancelTerm: VoidFunction;
};

export default function TermItemInputs({
  itemType,
  selectedTermItems,
  currentValues,
  handleChange,
  removeTermItem,
  cancelTerm,
}: Props): JSX.Element {
  const classes = useStyles();

  const isRulesType = itemType === 'rule';
  const nameLookup = isRulesType ? ruleNameLookup : conditionNameLookup;

  const handleDeleteInput = (index: number): void => {
    removeTermItem(index);
    cancelTerm();
  };

  return (
    <>
      {selectedTermItems.map((item, index) => {
        const displayName = nameLookup[item];
        return (
          <div className={classes.inputWrapper} key={item}>
            <span>{displayName}</span>
            <TermInputSelection
              termItem={item}
              currentValues={currentValues}
              handleChange={handleChange}
            />
            <IconButton
              onClick={(): void => {
                handleDeleteInput(index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      })}
    </>
  );
}
