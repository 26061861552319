import { Dispatch } from 'redux';

import axios from 'axios';
import * as p from './types/actions';
import { RootState } from '../../shared/types/types';
import { MasterPlan, PlanElection } from './types/plans';
import { validArray } from '../../shared/typeChecks';

type PlansDispatch = Dispatch<p.PlanActionTypes>;

export function updateMasterPlan(
  field: keyof MasterPlan,
  value: number | string | boolean,
  masterPlanID: number
): p.PlanActionTypes {
  const updateValue = value;
  return {
    type: p.UPDATE_PLAN_INFO,
    meta: {
      field,
      value: updateValue,
      masterPlanID,
    },
  };
}

export function updateMasterCensusName(
  masterCensus: string[],
  masterPlanID: number
): p.PlanActionTypes {
  return {
    type: p.UPDATE_MASTER_CENSUS_NAME,
    meta: {
      masterCensus,
      masterPlanID,
    },
  };
}

export function updatePlanElections(
  planElections: PlanElection[],
  masterPlanID: number
): p.PlanActionTypes {
  return {
    type: p.UPDATE_PLAN_ELECTIONS,
    meta: {
      planElections,
      masterPlanID,
    },
  };
}

export function addPlanElection(planElection: PlanElection, FEUniqueId: number): p.PlanActionTypes {
  return {
    type: p.ADD_PLAN_ELECTION,
    meta: {
      planElection,
      FEUniqueId,
    },
  };
}

export function updatePlanElection(
  planElection: PlanElection,
  sixDegPlanID: number
): p.PlanActionTypes {
  return {
    type: p.UPDATE_PLAN_ELECTION,
    meta: {
      planElection,
      sixDegPlanID,
    },
  };
}

export function setSelectedElection(selectedID: string, masterPlanID: number): p.PlanActionTypes {
  return {
    type: p.SET_SELECTED_ELECTION,
    meta: {
      selectedID,
      masterPlanID,
    },
  };
}

export function removeSelectedElection(): p.PlanActionTypes {
  return {
    type: p.REMOVE_SELECTED_ELECTION,
  };
}

export function deletePlanElection(selectedID: string, masterPlanID: number): p.PlanActionTypes {
  return {
    type: p.DELETE_PLAN_ELECTION,
    meta: {
      selectedID,
      masterPlanID,
    },
  };
}

export function getPlans(): p.PlanActionTypes {
  return {
    type: p.GET_ALL_PLANS,
    payload: axios.get('/api/planEditor', {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getPlan(masterPlanID: number): p.PlanActionTypes {
  return {
    type: p.GET_PLAN,
    meta: {
      masterPlanID,
    },
    payload: axios.get(`/api/masterPlan/${masterPlanID}`, {
      headers: {
        'X-CSRF-Token': localStorage.getItem('csrfToken'),
      },
    }),
  };
}
/* eslint-disable no-param-reassign, prefer-const */
export function savePlanAndElectionUpdates(
  masterPlan: MasterPlan,
  planElections: Record<number | string, PlanElection>,
  isNewPlan: boolean
) {
  return async (dispatch: PlansDispatch): Promise<void> => {
    try {
      let updatedMasterPlanID = masterPlan.masterPlanID;

      // Function to handle Plan Election Upserts
      const savePlanElections = async () => {
        Object.values(planElections).forEach(election => {
          election.masterplanid = updatedMasterPlanID;
          election.proplus = election.proplus === '' ? false : election.proplus;
          delete election.FEUniqueId;
          if (masterPlan.planSelection) {
            election.censusnames = [];
            election.bypasseligibility = false;
          } else {
            election.subPlanID = [];
          }
        });

        const safePlanElections = Object.values(planElections).filter(
          election => election.masterplanid === updatedMasterPlanID
        );

        const electionPromises = safePlanElections.map(async election => {
          let send837invoice = election.invoicingtype !== 'none';

          const updatedElection = {
            ...election,
            send837invoice,
          };

          try {
            const electionResponse = await axios.post('/api/planElectionUpsert', updatedElection, {
              headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
              withCredentials: true,
            });

            dispatch({
              type: p.SAVE_PLAN_ELECTION_FULFILLED,
              payload: electionResponse.data,
            });
          } catch (error) {
            console.error('Error saving Plan Election:', error);
            throw error; // Rethrow the error to be caught by the outer try-catch
          }
        });

        // Await all Plan Election API calls to complete
        await Promise.all(electionPromises);
      };

      if (!isNewPlan && masterPlan.planSelection) {
        // If planSelection is true, save Plan Elections first
        await savePlanElections(); // Ensure this completes before continuing

        // Step 2: Save or update the Master Plan
        const sanitizedMasterPlan = { ...masterPlan };

        // Remove unnecessary properties
        if (sanitizedMasterPlan.sixDegTPAID) {
          delete sanitizedMasterPlan.sixDegTPAID;
        }

        if (sanitizedMasterPlan.planSelection === false) {
          delete sanitizedMasterPlan.masterCensus;
        }

        try {
          const masterPlanResponse = await axios.post(
            '/api/masterPlanUpsert',
            sanitizedMasterPlan,
            {
              headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
              withCredentials: true,
            }
          );

          dispatch({
            type: p.SAVE_PLAN_FULFILLED,
            payload: {
              masterPlan: masterPlanResponse.data,
            },
          });

          updatedMasterPlanID = masterPlanResponse.data.masterPlanID;
        } catch (error) {
          console.error('Error saving master plan:', error);
        }
      } else {
        // If planSelection is false, save Master Plan first
        const sanitizedMasterPlan = { ...masterPlan };

        // Remove unnecessary properties
        if (sanitizedMasterPlan.sixDegTPAID) {
          delete sanitizedMasterPlan.sixDegTPAID;
        }

        if (sanitizedMasterPlan.planSelection === false) {
          delete sanitizedMasterPlan.masterCensus;
        }

        try {
          const masterPlanResponse = await axios.post(
            '/api/masterPlanUpsert',
            sanitizedMasterPlan,
            {
              headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
              withCredentials: true,
            }
          );

          dispatch({
            type: p.SAVE_PLAN_FULFILLED,
            payload: {
              masterPlan: masterPlanResponse.data,
            },
          });

          updatedMasterPlanID = masterPlanResponse.data.masterPlanID;

          // Step 2: Save Plan Elections
          await savePlanElections();
        } catch (error) {
          console.error('Error saving master plan:', error);
        }
      }
    } catch (error) {
      dispatch({
        type: p.SAVE_PLAN_REJECTED,
        payload: error,
      });
    }
  };
}
/* eslint-disable no-param-reassign, prefer-const */

export function setFilteredTPAs(tpaIDs: number[]): p.PlanActionTypes {
  return {
    type: p.SET_FILTERED_TPAS,
    meta: {
      filteredTPAs: tpaIDs,
    },
  };
}

export function addNewPlanAndTPA(): p.PlanActionTypes {
  return {
    type: p.ADD_NEW_PLAN_TPA,
  };
}

export function removeNewPlanAndTPA(): p.PlanActionTypes {
  return {
    type: p.REMOVE_NEW_PLAN_TPA,
  };
}
