import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useTheme } from '@material-ui/core/styles';

import { isValidNumber } from '@apps/shared/src/guards';
import { emptyProviderValues } from '../contractsReducer';
import {
  removeProvider,
  addProvider,
  setSelectedProvider,
  saveSelectedProviderToRoster,
  setNPIForSelectedProvider,
  setTaxIDForSelectedProvider,
} from '../contractsActions';
import { addSnackbar as addMessage } from '../../shared/components/snackbar/snackbarReducer';
import { ContractProvider, ContractRosterTheme, ContractRoster } from '../types/contracts';
import {
  RootState,
  ClickHandler,
  FocusHandler,
  InputChangeHandler,
  KeyHandler,
} from '../../shared/types/types';
import { isNonEmptyProvider } from '../contractUtilities';

import ReadOnlyProviderRow from './ReadOnlyProviderRow';
import EditableProviderRow from './EditableProviderRow';
import ProviderTableHeader from './ProviderTableHeader';

function scrollProviderIntoView(
  tableWrapperRef: React.RefObject<HTMLDivElement>,
  headerRowHeight: number,
  bodyRowHeight: number,
  tableWrapperHeight: number,
  rowIndex?: number
): void {
  if (!rowIndex || !isValidNumber(rowIndex) || tableWrapperRef.current == null) return;
  tableWrapperRef.current.scrollTo({
    behavior: 'smooth',
    top: calculateScroll(rowIndex, headerRowHeight, bodyRowHeight, tableWrapperHeight),
  });
}

function calculateScroll(
  row: number,
  headerHeight: number,
  rowHeight: number,
  tableWrapperHeight: number
): number {
  return headerHeight + 0.5 * (rowHeight * (2 * row + 1) - tableWrapperHeight);
}

const useStyles = makeStyles<ContractRosterTheme, Partial<Props>>(theme => ({
  providersContainer: {
    position: 'relative',
  },
  providers: {
    overflow: 'auto',
    maxHeight: theme.contractRoster.table.maxHeight,
  },
  table: {
    '& .MuiTableCell-root:last-child': {
      paddingRight: ({ isEdit }): string => (isEdit ? '3rem' : '1rem'),
    },
  },
  headerRow: {
    height: theme.contractRoster.table.headerRowHeight,
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '1.5rem',
  },
}));

type ParentProps = {
  isEdit: boolean;
};

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    isRosterLoading: contracts.isRosterLoading,
    providers: contracts.selectedRoster.providers,
    selectedProvider: contracts.selectedProvider,
    selectedRoster: contracts.selectedRoster,
  };
};
type StateProps = {
  isRosterLoading: boolean;
  providers: ContractProvider[];
  selectedProvider: ContractProvider;
  selectedRoster: ContractRoster;
};

const mapDispatchToProps = {
  removeProvider,
  addProvider,
  setSelectedProvider,
  saveSelectedProviderToRoster,
  addMessage,
  setNPIForSelectedProvider,
  setTaxIDForSelectedProvider,
};
type DispatchProps = {
  addProvider: VoidFunction;
  removeProvider: (index: number) => void;
  setSelectedProvider: (p: ContractProvider) => void;
  saveSelectedProviderToRoster: (row: number) => void;
  addMessage: (message: string) => void;
  setNPIForSelectedProvider: (npi: string) => void;
  setTaxIDForSelectedProvider: (taxID: string) => void;
};
type Props = ParentProps & StateProps & DispatchProps;

export function Providers({
  isRosterLoading,
  isEdit,
  providers,
  selectedProvider,
  selectedRoster,
  addProvider,
  removeProvider,
  setSelectedProvider,
  saveSelectedProviderToRoster,
  addMessage,
  setNPIForSelectedProvider,
  setTaxIDForSelectedProvider,
}: Props): JSX.Element {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | undefined>();

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ isEdit });
  const {
    contractRoster: {
      table: { headerRowHeight, bodyRowHeight, maxHeight },
    },
  } = useTheme<ContractRosterTheme>();

  const { npiColumnName, taxIDColumnName } = selectedRoster;

  const numProviders = providers.length;

  useEffect(() => {
    scrollProviderIntoView(
      tableWrapperRef,
      headerRowHeight,
      bodyRowHeight,
      maxHeight,
      selectedRowIndex
    );
  }, [numProviders, selectedRowIndex, headerRowHeight, bodyRowHeight, maxHeight]);

  useEffect(() => {
    if (isEdit && numProviders === 0) addProvider();
    if (!isEdit) setSelectedRowIndex(undefined);
  }, [numProviders, addProvider, isEdit]);

  useEffect(() => {
    setSelectedRowIndex(undefined);
  }, [isRosterLoading]);

  const handleAddRow: VoidFunction = () => {
    const lastRowSelected = selectedRowIndex === numProviders - 1;
    const lastProvider = lastRowSelected ? selectedProvider : providers[numProviders - 1];
    if (numProviders && isNonEmptyProvider(lastProvider)) {
      setSelectedRowIndex(numProviders);
      setSelectedProvider(emptyProviderValues());
      addProvider();
    } else addMessage('Add NPI or Tax ID before adding more providers');
  };

  const createSelectRowHandler = (
    newRowIndex: number,
    provider: ContractProvider
  ): FocusHandler => {
    return (): void => {
      setSelectedProvider(provider);
      setSelectedRowIndex(newRowIndex);
    };
  };

  const handleRowBlur: FocusHandler = e => {
    const blurSourceRow = e.target ? e.target.getAttribute('data-row-index') : null;
    const relatedTarget = e.relatedTarget as Element;
    const blurDestinationRow = relatedTarget ? relatedTarget.getAttribute('data-row-index') : null;

    if (
      selectedRowIndex !== undefined &&
      isValidNumber(selectedRowIndex) &&
      blurSourceRow !== blurDestinationRow
    )
      saveSelectedProviderToRoster(selectedRowIndex);
  };

  const createDeleteRowHandler = (i: number): ClickHandler | undefined => {
    if (numProviders <= 1) return undefined;
    return (): void => {
      setSelectedRowIndex(undefined);
      removeProvider(i);
    };
  };

  const handleKeyDownFromLastCell: KeyHandler = e => {
    if (e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();
      if (selectedRowIndex !== undefined) saveSelectedProviderToRoster(selectedRowIndex);
      handleAddRow();
    }
  };

  const handleNPIChange: InputChangeHandler = e => setNPIForSelectedProvider(e.target.value);
  const handleTaxIDChange: InputChangeHandler = e => setTaxIDForSelectedProvider(e.target.value);

  return (
    <div className={classes.providersContainer}>
      <div className={classes.providers} ref={tableWrapperRef}>
        <Table stickyHeader className={classes.table}>
          <ProviderTableHeader
            isEdit={isEdit}
            npiColumnName={npiColumnName}
            taxIDColumnName={taxIDColumnName}
          />
          <TableBody>
            {providers.map((provider, i) => {
              // eslint-disable-next-line react/no-array-index-key
              if (!isEdit) return <ReadOnlyProviderRow key={i} provider={provider} />;

              const isSelected = i === selectedRowIndex;
              const isLastRow = i === numProviders - 1;
              const lastCellHandler = isLastRow ? handleKeyDownFromLastCell : undefined;
              const selectRowHandler = isSelected ? undefined : createSelectRowHandler(i, provider);

              return (
                <EditableProviderRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  rowIndex={i}
                  provider={isSelected ? selectedProvider : provider}
                  isNewRow={isSelected && isLastRow && !isNonEmptyProvider(provider)}
                  isSelected={isSelected}
                  handleSelectRow={selectRowHandler}
                  handleBlur={handleRowBlur}
                  handleDelete={createDeleteRowHandler(i)}
                  handleNPIChange={handleNPIChange}
                  handleTaxIDChange={handleTaxIDChange}
                  handleKeyDownFromLastCell={lastCellHandler}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
      {isEdit && (
        <Fab
          size="medium"
          color="secondary"
          className={classes.fab}
          onClick={handleAddRow}
          aria-label="Add Provider"
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
