import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { debounce } from '@apps/shared/src/utilities';
import { colors } from '@apps/shared/src/style';

import { deleteFile, getFiles, getFilesV2 } from '../reportsActions';

const styles = {
  container: {
    padding: '5px 16px',
    borderBottom: `1px solid ${colors.grey12}`,
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    gap: '1rem',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  fileFilter: {
    display: 'flex',
    alignItems: 'center',
    gap: '.3rem',
  },
  selectAll: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  containerWrapper: {
    marginLeft: '-1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '8rem',
  },
  deleteBtn: {
    color: 'white',
  },
  deleteBtnDisabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    '&.MuiButton-contained.Mui-disabled': {
      color: 'white',
    },
  },
};

class BatchSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      errFiles: false,
      eligibilityFiles: false,
      duplicateFiles: false,
    };
    this.searchInput = React.createRef();
    this.debouncedGetFilesV2 = debounce(this.props.getFilesV2, 400);
    this.debouncedGetFiles = debounce(this.props.getFiles, 400);
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchFilter !== prevProps.searchFilter) {
      // Update the state or any other logic if needed when searchFilter prop changes
    }

    if (this.searchInput && this.searchInput.current) {
      this.searchInput.current.focus();
    }
  }

  handleChange = e => {
    const newSearchTerm = e.target.value;
    this.setState({ searchTerm: newSearchTerm });
    const updatedFilter = { ...this.props.searchFilter, filter: newSearchTerm };
    if (this.state.errFiles || this.state.eligibilityFiles || this.state.duplicateFiles) {
      this.debouncedGetFilesV2(updatedFilter);
    } else {
      this.debouncedGetFiles(newSearchTerm);
    }
  };

  handleShowErroredFiles = e => {
    const isChecked = e.target.checked;
    this.setState({ errFiles: isChecked });
    const updatedFilter = {
      ...this.props.searchFilter,
      errFiles: isChecked,
      filter: this.state.searchTerm,
    };
    this.props.setSearchFilter(updatedFilter);
    if (isChecked || this.state.eligibilityFiles || this.state.duplicateFiles) {
      this.props.getFilesV2(updatedFilter);
    } else {
      this.debouncedGetFiles(this.state.searchTerm);
    }
  };

  handleExcludeDuplicatesFiles = e => {
    const isChecked = e.target.checked;
    this.setState({ duplicateFiles: isChecked });
    const updatedFilter = {
      ...this.props.searchFilter,
      duplicateFiles: isChecked,
      filter: this.state.searchTerm,
    };
    this.props.setSearchFilter(updatedFilter);
    if (this.state.errFiles || this.state.eligibilityFiles || isChecked) {
      this.props.getFilesV2(updatedFilter);
    } else {
      this.debouncedGetFiles(this.state.searchTerm);
    }
  };

  handleShowEligibilityFiles = e => {
    const isChecked = e.target.checked;
    this.setState({ eligibilityFiles: isChecked });
    const updatedFilter = {
      ...this.props.searchFilter,
      eligibilityFiles: isChecked,
      filter: this.state.searchTerm,
    };
    this.props.setSearchFilter(updatedFilter);
    if (this.state.errFiles || isChecked || this.state.duplicateFiles) {
      this.props.getFilesV2(updatedFilter);
    } else {
      this.debouncedGetFiles(this.state.searchTerm);
    }
  };

  render() {
    const { classes, roles, searchFilter, checkedItems, handleSelectAll, handleDelete, selectAll } =
      this.props;

    const isEDIAdmin = (roles || []).includes('claims-edi-admin');

    const isDeleteDisabled = Object.values(checkedItems).every(value => !value);

    return (
      <div className={classes.container}>
        <FormControl fullWidth>
          <Input
            inputRef={this.searchInput}
            onChange={this.handleChange}
            placeholder="Search all files"
            value={this.state.searchTerm}
          />
        </FormControl>
        {isEDIAdmin && (
          <>
            <div className={classes.flexContainer}>
              <div className={classes.fileFilter}>
                <input
                  type="checkbox"
                  checked={searchFilter.errFiles || false}
                  onChange={this.handleShowErroredFiles}
                />
                <Typography>Show only errored files</Typography>
              </div>
              <div className={classes.fileFilter}>
                <input
                  type="checkbox"
                  checked={searchFilter.duplicateFiles || false}
                  onChange={this.handleExcludeDuplicatesFiles}
                />
                <Typography>Exclude duplicates</Typography>
              </div>
              <div className={classes.fileFilter}>
                <input
                  type="checkbox"
                  checked={searchFilter.eligibilityFiles || false}
                  onChange={this.handleShowEligibilityFiles}
                />
                <Typography>Show only eligibility files</Typography>
              </div>
            </div>
            {searchFilter.eligibilityFiles === true && (
              <div className={classes.containerWrapper}>
                <div className={classes.selectAll}>
                  <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                  <Typography>Select All</Typography>
                </div>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#D90027' }}
                  className={`${classes.deleteBtn} ${
                    isDeleteDisabled ? classes.deleteBtnDisabled : ''
                  }`}
                  onClick={handleDelete}
                  disabled={isDeleteDisabled}
                >
                  Delete
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

BatchSearch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  getFiles: PropTypes.func.isRequired,
  getFilesV2: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchFilter: PropTypes.object.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  checkedItems: PropTypes.object.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ reports, user }) => ({
  files: reports.files,
  roles: user.currentUser.roles,
});

const mapDispatchToProps = {
  getFiles,
  getFilesV2,
  deleteFile,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(BatchSearch);
