import React, { ReactNode } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { AppTheme } from '../../types/themeType';
import Header from '../header/Header';
import Sidebar from './Sidebar';

const useStyles = makeStyles<AppTheme, Partial<Props>>(theme => ({
  app: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: theme.headerHeight.full,
  },
  main: {
    height: ({ showHeader }): string =>
      showHeader ? `calc(100vh - ${theme.headerHeight.full}px)` : '100vh',
    position: 'relative',
    display: 'flex',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
  },
  children: {
    overflow: 'auto',
    flex: 1,
  },
  footer: {
    flex: '0 0',
  },
}));

type Props = {
  children: ReactNode;
  sidebar?: ReactNode;
  contentFooter?: ReactNode;
  showHeader?: boolean;
};
export default function AppLayout({
  children,
  sidebar,
  contentFooter,
  showHeader = true,
}: Props): JSX.Element {
  const classes = useStyles({ showHeader });

  return (
    <div className={classes.app}>
      {showHeader && (
        <header className={classes.header}>
          <Header />
        </header>
      )}
      <main className={classes.main}>
        <Sidebar>{sidebar}</Sidebar>
        <section className={classes.content}>
          <div className={classes.children}>{children}</div>
          {contentFooter && <footer className={classes.footer}>{contentFooter}</footer>}
        </section>
      </main>
    </div>
  );
}
