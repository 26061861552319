import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import { debounce } from '@apps/shared/src/utilities';
import { InputChangeHandler } from '../../shared/types/types';
import { searchContracts } from '../contractsActions';

const useStyles = makeStyles({
  searchContainer: {
    margin: '0.5rem 2rem 0',
  },
});

type ParentProps = {
  searchText: string;
};

type Props = typeof mapDispatchToProps & ParentProps;

export function ContractSearch({ searchContracts, searchText }: Props): JSX.Element {
  const classes = useStyles();

  const debouncedSearch = useCallback(debounce(searchContracts, 600), [searchContracts]);
  const handleChange: InputChangeHandler = e =>
    e.target.value.length > 0 && debouncedSearch(e.target.value);

  return (
    <div className={classes.searchContainer}>
      <TextField
        defaultValue={searchText}
        label="Search Contracts"
        onChange={handleChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

const mapDispatchToProps = {
  searchContracts,
};

export default connect(null, mapDispatchToProps)(ContractSearch);
