export const ADD_SNACKBAR = 'ADD_SNACKBAR';
const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
const NEXT_SNACKBAR = 'NEXT_SNACKBAR';

const initialState = {
  current: '',
  messages: [],
  open: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const { messages } = state;

  switch (type) {
    case ADD_SNACKBAR:
      return addMessage(state, payload);

    case CLOSE_SNACKBAR:
      return { ...state, open: false };

    case NEXT_SNACKBAR:
      return messages.length
        ? { ...state, current: messages[0], messages: messages.slice(1), open: true }
        : { ...state, current: '' };

    default:
      if (type.includes('REJECTED') && payload && payload.response && payload.response.data) {
        if (payload.request?.responseType === 'blob' && payload.message) {
          return addMessage(state, payload.message);
        }
        return addMessage(state, payload.response.data);
      }
      return state;
  }
};

function addMessage(state, message) {
  const { current, messages } = state;
  if (!message) {
    return state;
  }
  return current
    ? { ...state, messages: [...messages, message] }
    : { ...state, current: message, open: true };
}

export function addSnackbar(message) {
  return { type: ADD_SNACKBAR, payload: message };
}

export function closeSnackbar() {
  return { type: CLOSE_SNACKBAR };
}

export function nextSnackbar() {
  return { type: NEXT_SNACKBAR };
}
