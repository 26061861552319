import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ContractTypes } from './types/contracts';
import { createContract } from './contractsActions';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15vh',
  },
  newContractTypes: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: '400px',
    width: '100%',
    paddingTop: '1em',
  },
  createNew: {
    marginTop: '1.5rem',
  },
  newContractButton: {
    marginLeft: '5px',
    marginRight: '5px',
  },
});

const mapDispatchToProps = {
  createContract,
};

type Props = typeof mapDispatchToProps;

const buttonTypes = [
  { type: ContractTypes.SingleCase, text: 'Single Case Agreement' },
  { type: ContractTypes.Direct, text: 'Direct Contract' },
  { type: ContractTypes.LOA, text: 'Letter of Agreement' },
];

export function NewContract({ createContract }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h6">Select a contract from the list or</Typography>
      <Typography className={classes.createNew} variant="h5">
        Create New Contract
      </Typography>
      <div className={classes.newContractTypes}>
        {buttonTypes.map(({ type, text }) => {
          return (
            <Button
              className={classes.newContractButton}
              variant="contained"
              color="primary"
              onClick={(): void => {
                createContract(ContractTypes[type]);
              }}
              key={type}
            >
              {text}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(NewContract);
