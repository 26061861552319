import {
  validYear,
  validMonth,
  validDay,
  formatBlock,
  validYearPartial,
  validMonthPartial,
  validDayPartial,
} from './monatUtilities';

export default class MonatDate {
  constructor(format, blocks, year, month, day) {
    this.format = format;
    this.blocks = blocks;
    this.year = year;
    this.month = month;
    this.day = day;
    if (!year && !month && !day && blocks) {
      blocks.forEach((block, i) => {
        switch (format.blocks[i]) {
          case 'yyyy':
            this.year = block;
            break;
          case 'mm':
            this.month = block;
            break;
          case 'dd':
            this.day = block;
            break;
          default:
        }
      });
    }
  }

  toFormat(format) {
    const blocks = format.blocks.map(block => {
      switch (block) {
        case 'yyyy':
          return this.year;
        case 'mm':
          return this.month;
        case 'dd':
          return this.day;
        default:
          return '';
      }
    });
    return new MonatDate(format, blocks, this.year, this.month, this.day);
  }

  toComplete() {
    const blocks = this.blocks.map((block, i) =>
      formatBlock(block, this.format.blocks[i], this.format.flexibleYear)
    );
    return new MonatDate(this.format, blocks);
  }

  toString() {
    let formatted = this.blocks[0];
    for (let i = 1; i < this.blocks.length; i++) {
      if (this.blocks[i] !== '' || this.blocks[i - 1].length === this.format.blocks[i - 1].length) {
        formatted += this.format.delim + this.blocks[i];
      }
    }
    return formatted;
  }

  isValidComplete() {
    return (
      validYear(this.year) && validMonth(this.month) && validDay(this.day, this.month, this.year)
    );
  }

  isValidPartial() {
    return (
      validYearPartial(this.year, this.format.flexibleYear) &&
      validMonthPartial(this.month) &&
      validDayPartial(this.day, this.month, this.year)
    );
  }
}
