import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer, Column, Table as VirtualTable } from 'react-virtualized';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import 'react-virtualized/styles.css';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { colors } from '@apps/shared/src/style';
import {
  getPatientReportAndFilter,
  filterPatient,
  sortPatient,
  getPatientReport,
} from './reportsActions';
import * as types from '../shared/types/propTypes';
import { SortDirection } from '../shared/globals';
import ReportSearchInput from './ReportSearchInput';

const styles = {
  filterHeader: {
    padding: '0.5em 0 1em',
    top: '0',
    zIndex: '1',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
  },
  tableWrapper: {
    height: `calc(100vh - 175px)`,
  },
  table: {
    fontSize: '13px',
  },
  tableHeader: {
    fontWeight: '500',
    backgroundColor: colors.greyDark,
    color: colors.white,
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    '&:focus': {
      outlineColor: colors.orange,
      outlineOffset: '-3px',
    },
  },
  grid: {
    '&:focus': {
      outlineColor: colors.orange,
      outlineOffset: '-3px',
    },
  },
  column: {
    margin: 0,
    padding: '3px',
    whiteSpace: 'normal',
  },
  greyText: { color: colors.greyDark },
  searchBtn: { marginRight: '1rem' },
  PageSizeContainer: {
    display: 'flex',
    gap: '0.5rem',
    marginLeft: '0.5rem',
    alignItems: 'center',
  },
  rightdivider: {
    borderRight: '1px solid #2B629B',
    paddingRight: '0.2rem',
  },
  active: {
    color: 'black',
    cursor: 'pointer',
  },
  link: {
    color: '#2B629B',
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  pageButton: {
    margin: '0 0.5rem',
    padding: '0.4rem 1rem',
    border: '1px solid #2B629B',
    borderRadius: '5px',
    cursor: 'pointer',
    color: '#2B629B',
  },
  activePageButton: {
    margin: '0 0.5rem',
    padding: '0.5rem 1rem',
    border: '1px solid #2B629B',
    borderRadius: '5px',
    backgroundColor: '#2B629B',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
};

const cellWidths = {
  small: 50,
  medium: 100,
  large: 150,
  veryLarge: 250,
};

const everyOtherRow = {
  background: colors.grey12,
};

function cleanDate(date) {
  return date.slice(0, 10);
}

function emptyIfInfDate(date) {
  const cleaned = cleanDate(date);
  return cleaned === '9999-01-01' ? '' : cleaned;
}

function formatCleanDate({ cellData, columnIndex }) {
  // End date should appear empty if value == infinity date
  return (columnIndex === 9 ? emptyIfInfDate : cleanDate)(cellData);
}

class Patients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'LastName',
      sortDirection: SortDirection.ASC,
      searchSubscriberID: '',
      searchFirstName: '',
      searchLastName: '',
      searchDateofBirth: '',
      searchPatientID: '',
      searchCensusName: '',
      activePageSize: 100,
      activePage: 1,
    };
  }

  componentDidMount() {
    const filter = {
      patientSearchFirstName: this.state.searchFirstName,
      patientSearchLastName: this.state.searchLastName,
      searchDateofBirth: this.state.searchDateofBirth,
      searchPatientID: this.state.searchPatientID,
      searchSubscriberID: this.state.searchSubscriberID,
      searchCensusName: this.state.searchCensusName,
    };

    this.props.dispatch(
      getPatientReportAndFilter(filter, this.state.activePage, this.state.activePageSize)
    );
  }

  handleSearch = e => {
    const searchField = e.currentTarget.id;
    this.setState({ [searchField]: e.currentTarget.value });
  };

  handleClearSearch = e => {
    this.setState(
      {
        [e.currentTarget.id]: '',
      },
      () => {
        const filter = {
          patientSearchFirstName: this.state.searchFirstName,
          patientSearchLastName: this.state.searchLastName,
          searchDateofBirth: this.state.searchDateofBirth,
          searchPatientID: this.state.searchPatientID,
          searchSubscriberID: this.state.searchSubscriberID,
          searchCensusName: this.state.searchCensusName,
        };
        this.props.dispatch(
          getPatientReportAndFilter(filter, this.state.activePage, this.state.activePageSize)
        );
      }
    );
  };

  handleSearchClick = () => {
    const filter = {
      patientSearchFirstName: this.state.searchFirstName,
      patientSearchLastName: this.state.searchLastName,
      searchDateofBirth: this.state.searchDateofBirth,
      searchPatientID: this.state.searchPatientID,
      searchSubscriberID: this.state.searchSubscriberID,
      searchCensusName: this.state.searchCensusName,
    };

    // Update the state and call getPatientReportAndFilter in the callback function
    this.setState({ activePage: 1 }, () => {
      this.props.dispatch(
        getPatientReportAndFilter(filter, this.state.activePage, this.state.activePageSize)
      );
    });
  };

  handleEnterKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleSearchClick();
    }
  };

  sortList = ({ sortBy, sortDirection }) => {
    this.setState({ sortBy, sortDirection });
    this.props.dispatch(sortPatient(sortBy, sortDirection));
  };

  handlePageClick = (e, page) => {
    this.setState({ activePageSize: page }, this.fetchPatientReportAndFilter);
  };

  handlePrevPageClick = () => {
    const prevPage = this.state.activePage - 1;
    if (prevPage > 0) {
      this.setState({ activePage: prevPage }, this.fetchPatientReportAndFilter);
    }
  };

  handleNextPageClick = () => {
    this.setState(
      prevState => ({ activePage: prevState.activePage + 1 }),
      this.fetchPatientReportAndFilter
    );
  };

  fetchPatientReportAndFilter = () => {
    const filter = {
      patientSearchFirstName: this.state.searchFirstName,
      patientSearchLastName: this.state.searchLastName,
      searchDateofBirth: this.state.searchDateofBirth,
      searchPatientID: this.state.searchPatientID,
      searchSubscriberID: this.state.searchSubscriberID,
      searchCensusName: this.state.searchCensusName,
    };
    this.props.dispatch(
      getPatientReportAndFilter(filter, this.state.activePage, this.state.activePageSize)
    );
  };

  render() {
    const { patients, loading, classes } = this.props;
    const { sortBy, sortDirection } = this.state;
    const numOfPatients = Array.isArray(patients) ? patients.length : 0;
    return (
      <div style={{ width: '1720px' }}>
        <div className={classes.filterHeader}>
          <ReportSearchInput
            searchText={this.state.searchFirstName}
            handleSearch={this.handleSearch}
            handleClearSearch={this.handleClearSearch}
            handleKeyDown={this.handleEnterKeyDown}
            label="First Name"
            id="searchFirstName"
            style={{ width: '12em', marginRight: '1em' }}
          />
          <ReportSearchInput
            searchText={this.state.searchLastName}
            handleSearch={this.handleSearch}
            handleClearSearch={this.handleClearSearch}
            handleKeyDown={this.handleEnterKeyDown}
            label="Last Name"
            id="searchLastName"
            style={{ width: '12em', marginRight: '1em' }}
          />
          <ReportSearchInput
            searchText={this.state.searchDateofBirth}
            handleSearch={this.handleSearch}
            handleClearSearch={this.handleClearSearch}
            handleKeyDown={this.handleEnterKeyDown}
            id="searchDateofBirth"
            style={{ width: '12em', marginRight: '1em' }}
            isDate
          />
          <ReportSearchInput
            searchText={this.state.searchPatientID}
            handleSearch={this.handleSearch}
            handleClearSearch={this.handleClearSearch}
            handleKeyDown={this.handleEnterKeyDown}
            label="Patient ID"
            id="searchPatientID"
            style={{ width: '12em', marginRight: '1em' }}
          />
          <ReportSearchInput
            searchText={this.state.searchSubscriberID}
            handleSearch={this.handleSearch}
            handleClearSearch={this.handleClearSearch}
            handleKeyDown={this.handleEnterKeyDown}
            label="Subscriber ID"
            id="searchSubscriberID"
            style={{ width: '12em', marginRight: '1em' }}
          />
          <ReportSearchInput
            searchText={this.state.searchCensusName}
            handleSearch={this.handleSearch}
            handleClearSearch={this.handleClearSearch}
            handleKeyDown={this.handleEnterKeyDown}
            label="Policy Number"
            id="searchCensusName"
            style={{ width: '12em', marginRight: '1em' }}
          />

          <Button
            id="searchBtn"
            className={classes.searchBtn}
            variant="contained"
            color="primary"
            onClick={this.handleSearchClick}
          >
            Search
          </Button>
          <span className={classes.greyText}>
            {numOfPatients} patient{numOfPatients !== 1 ? 's' : ''}
          </span>

          <div className={classes.pagination}>
            <Button className={classes.pageButton} onClick={this.handlePrevPageClick}>
              Previous
            </Button>
            <span className={classes.pageButton}>{this.state.activePage}</span>
            <Button
              className={classes.pageButton}
              onClick={this.handleNextPageClick}
              disabled={numOfPatients < this.state.activePageSize}
            >
              Next
            </Button>
          </div>
          <div className={classes.PageSizeContainer}>
            <span>Results per page </span>
            <span
              className={`${classes.rightdivider} ${
                this.state.activePageSize === 100 ? classes.active : classes.link
              }`}
              onClick={e => this.handlePageClick(e, 100)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handlePageClick(e, 100);
                }
              }}
              role="button"
              tabIndex={0}
            >
              100
            </span>
            <span
              className={`${classes.rightdivider} ${
                this.state.activePageSize === 200 ? classes.active : classes.link
              }`}
              onClick={e => this.handlePageClick(e, 200)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handlePageClick(e, 200);
                }
              }}
              role="button"
              tabIndex={0}
            >
              200
            </span>
            <span
              className={`${this.state.activePageSize === 300 ? classes.active : classes.link}`}
              onClick={e => this.handlePageClick(e, 300)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handlePageClick(e, 300);
                }
              }}
              role="button"
              tabIndex={0}
            >
              300
            </span>
          </div>
        </div>
        <LoadingSpinner isLoading={loading} />
        {!loading && (
          <div className={classes.tableWrapper}>
            <AutoSizer disableWidth>
              {({ height }) => (
                <VirtualTable
                  className={classes.table}
                  width={1700}
                  height={height}
                  headerHeight={35}
                  rowHeight={45}
                  rowGetter={({ index }) => patients[index]}
                  rowCount={numOfPatients}
                  headerClassName={classes.tableHeader}
                  headerStyle={{ margin: 0 }}
                  gridClassName={classes.grid}
                  sort={this.sortList}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  rowStyle={({ index }) => (index % 2 === 0 ? everyOtherRow : null)}
                >
                  <Column
                    label="First Name"
                    dataKey="FirstName"
                    width={cellWidths.large}
                    style={{ margin: 0 }}
                    className={classes.column}
                  />
                  <Column
                    label="Last Name"
                    dataKey="LastName"
                    width={cellWidths.large}
                    className={classes.column}
                  />
                  <Column
                    label="DOB"
                    dataKey="DateOfBirth"
                    cellRenderer={formatCleanDate}
                    width={cellWidths.medium}
                    className={classes.column}
                  />
                  <Column
                    label="Plan"
                    dataKey="PlanName"
                    width={cellWidths.veryLarge}
                    className={classes.column}
                  />
                  <Column
                    label="Plan ID"
                    dataKey="PlanID"
                    width={cellWidths.medium}
                    className={classes.column}
                  />
                  <Column
                    label="TPA Name"
                    dataKey="TPAName"
                    width={cellWidths.veryLarge}
                    className={classes.column}
                  />
                  <Column
                    label="Patient ID"
                    dataKey="PatientID"
                    width={cellWidths.large}
                    className={classes.column}
                  />
                  <Column
                    label="Subscriber ID"
                    dataKey="SubscriberID"
                    width={cellWidths.large}
                    className={classes.column}
                  />
                  <Column
                    label="Start Elig."
                    dataKey="StartDate"
                    cellRenderer={formatCleanDate}
                    width={cellWidths.medium}
                    className={classes.column}
                  />
                  <Column
                    label="End Elig."
                    dataKey="EndDate"
                    cellRenderer={formatCleanDate}
                    width={cellWidths.medium}
                    className={classes.column}
                  />
                  <Column
                    label="Last Updated"
                    dataKey="CensusDate"
                    cellRenderer={formatCleanDate}
                    width={cellWidths.medium}
                    className={classes.column}
                  />
                  <Column
                    label="Address"
                    dataKey="Address"
                    width={cellWidths.veryLarge}
                    className={classes.column}
                  />
                  <Column
                    label="City"
                    dataKey="City"
                    width={cellWidths.large}
                    className={classes.column}
                  />
                  <Column
                    label="St"
                    dataKey="StateOrProvince"
                    width={cellWidths.small}
                    className={classes.column}
                  />
                  <Column
                    label="ZIP"
                    dataKey="ZipOrPostalCode"
                    width={cellWidths.medium}
                    className={classes.column}
                  />
                  <Column
                    label="Phone"
                    dataKey="Phone"
                    width={cellWidths.large}
                    className={classes.column}
                  />
                </VirtualTable>
              )}
            </AutoSizer>
          </div>
        )}
      </div>
    );
  }
}

Patients.defaultProps = {
  patients: undefined,
  searchText: '',
  loading: false,
};
Patients.propTypes = {
  patients: PropTypes.arrayOf(types.patientEligibility),
  searchText: PropTypes.string,
  loading: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps({ reports }) {
  return {
    patients: reports.filteredPatients,
    loading: reports.loading,
    searchText: reports.patientSearchText,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Patients));
