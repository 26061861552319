import React from 'react';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { claimStatusCodes, filterGroups } from '../../../shared/status';
import { ClickHandler } from '../../../shared/types/types';
import { UIClaimTransaction } from '../../types/manualClaimTypes';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  handleSaveClick: ClickHandler;
  handleSaveAndRepriceClick: ClickHandler;
  handleCompleteAndSubmitClick: ClickHandler;
  handleDeleteClick: ClickHandler;
  selectedClaim: UIClaimTransaction;
};

export default function EditManualClaimButtons({
  selectedClaim,
  handleSaveClick,
  handleDeleteClick,
  handleSaveAndRepriceClick,
  handleCompleteAndSubmitClick,
}: Props): JSX.Element {
  const classes = useStyles();

  const isSubmitForValidation = selectedClaim?.status === claimStatusCodes.dataEntry;
  const isResubmitForValidation = selectedClaim?.status === claimStatusCodes.dataEntryError;
  const isReturnToRepricing =
    selectedClaim?.status && !filterGroups.manualClaims.includes(selectedClaim?.status);

  return (
    <>
      <Tooltip title="Save Changes to Claim">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleSaveClick}
          id="save-claim-button"
        >
          Save
        </Button>
      </Tooltip>

      {isReturnToRepricing && (
        <Tooltip title="Save Claim and Return to Repricing">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSaveAndRepriceClick}
            id="save-and-reprice-claim-button"
          >
            Save and Reprice
          </Button>
        </Tooltip>
      )}

      {isSubmitForValidation && (
        <Tooltip title="Save Claim and Submit for Repricing">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleCompleteAndSubmitClick}
            id="save-and-submit-claim-button"
          >
            Save and Submit
          </Button>
        </Tooltip>
      )}

      {isResubmitForValidation && (
        <Tooltip title="Save Claim and Resubmit for Validation Checking">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleCompleteAndSubmitClick}
            id="save-and-resubmit-claim-button"
          >
            Save and Resubmit
          </Button>
        </Tooltip>
      )}

      <Button
        type="submit"
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={handleDeleteClick}
        id="delete-claim-button"
      >
        Delete
      </Button>
    </>
  );
}
