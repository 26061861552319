import { AxiosPromise } from 'axios';
import {
  SettlementsClaimSummary,
  SFCase,
  ImpactDetails,
  SettlementMethodology,
  DisplayReportOptions,
  MediVIChartOptions,
  MediVIProvider,
  ImpactChartData,
} from './types';

enum s {
  GET_CASE_SUMMARY = 'GET_CASE_SUMMARY',
  GET_CASE_SUMMARY_PENDING = 'GET_CASE_SUMMARY_PENDING',
  GET_CASE_SUMMARY_FULFILLED = 'GET_CASE_SUMMARY_FULFILLED',
  GET_CASE_SUMMARY_REJECTED = 'GET_CASE_SUMMARY_REJECTED',

  GET_IMPACT_REPORT = 'GET_IMPACT_REPORT',
  GET_IMPACT_REPORT_PENDING = 'GET_IMPACT_REPORT_PENDING',
  GET_IMPACT_REPORT_FULFILLED = 'GET_IMPACT_REPORT_FULFILLED',
  GET_IMPACT_REPORT_REJECTED = 'GET_IMPACT_REPORT_REJECTED',

  GET_PROVIDER_REPORT = 'GET_PROVIDER_REPORT',
  GET_PROVIDER_REPORT_PENDING = 'GET_PROVIDER_REPORT_PENDING',
  GET_PROVIDER_REPORT_FULFILLED = 'GET_PROVIDER_REPORT_FULFILLED',
  GET_PROVIDER_REPORT_REJECTED = 'GET_PROVIDER_REPORT_REJECTED',

  RESET_SETTLEMENTS = 'RESET_SETTLEMENTS',

  REMOVE_CASE = 'REMOVE_CASE',

  UPDATE_SETTLEMENT_AMOUNT = 'UPDATE_SETTLEMENT_AMOUNT',
  UPDATE_ALL_SETTLEMENT_AMOUNTS = 'UPDATE_ALL_SETTLEMENT_AMOUNTS',
  UPDATE_SETTLEMENT_METHODOLOGY = 'UPDATE_SETTLEMENT_METHODOLOGY',
  UPDATE_SETTLEMENT_PERCENT = 'UPDATE_SETTLEMENT_PERCENT',

  UPDATE_REPORT_DISPLAY_OPTIONS = 'UPDATE_REPORT_DISPLAY_OPTIONS',
  UPDATE_MEDIVI_CHART_OPTIONS = 'UPDATE_MEDIVI_CHART_OPTIONS',
  TOGGLE_CHART_PRINT_VIEW = 'TOGGLE_CHART_PRINT_VIEW',

  GET_PROVIDER_AUTOCOMPLETE = 'GET_PROVIDER_AUTOCOMPLETE',
  GET_PROVIDER_AUTOCOMPLETE_PENDING = 'GET_PROVIDER_AUTOCOMPLETE_PENDING',
  GET_PROVIDER_AUTOCOMPLETE_REJECTED = 'GET_PROVIDER_AUTOCOMPLETE_REJECTED',
  GET_PROVIDER_AUTOCOMPLETE_FULFILLED = 'GET_PROVIDER_AUTOCOMPLETE_FULFILLED',

  GET_MEDIVI_CHART = 'GET_MEDIVI_CHART',
  GET_MEDIVI_CHART_PENDING = 'GET_MEDIVI_CHART_PENDING',
  GET_MEDIVI_CHART_REJECTED = 'GET_MEDIVI_CHART_REJECTED',
  GET_MEDIVI_CHART_FULFILLED = 'GET_MEDIVI_CHART_FULFILLED',

  SET_SAVED_MANUAL_SETTLEMENT_AMOUNTS = 'SET_SAVED_MANUAL_SETTLEMENT_AMOUNTS',
  SET_URL_SYNCED = 'SET_URL_SYNCED',
}

interface GetClaimSummaries {
  type: typeof s.GET_CASE_SUMMARY;
  payload: AxiosPromise;
  meta: {
    caseNumber: string;
  };
}

interface GetClaimSummariesPending {
  type: typeof s.GET_CASE_SUMMARY_PENDING;
}
interface GetClaimSummariesRejected {
  type: typeof s.GET_CASE_SUMMARY_REJECTED;
}
interface GetClaimSummariesFulfilled {
  type: typeof s.GET_CASE_SUMMARY_FULFILLED;
  payload: {
    data: {
      claims: SettlementsClaimSummary[];
      sfCase: SFCase;
    };
  };
  meta: {
    caseNumber: string;
  };
}

type GetClaimSummariesActions =
  | GetClaimSummaries
  | GetClaimSummariesPending
  | GetClaimSummariesRejected
  | GetClaimSummariesFulfilled;

interface GetImpactReport {
  type: typeof s.GET_IMPACT_REPORT;
  payload: AxiosPromise;
}

interface GetImpactReportPending {
  type: typeof s.GET_IMPACT_REPORT_PENDING;
}
interface GetImpactReportRejected {
  type: typeof s.GET_IMPACT_REPORT_REJECTED;
}
interface GetImpactReportFulfilled {
  type: typeof s.GET_IMPACT_REPORT_FULFILLED;
  payload: {
    data: ImpactDetails;
  };
}

type GetImpactReportActions =
  | GetImpactReport
  | GetImpactReportPending
  | GetImpactReportRejected
  | GetImpactReportFulfilled;

interface GetProviderReport {
  type: typeof s.GET_PROVIDER_REPORT;
  payload: AxiosPromise;
}

interface GetProviderReportPending {
  type: typeof s.GET_PROVIDER_REPORT_PENDING;
}
interface GetProviderReportRejected {
  type: typeof s.GET_PROVIDER_REPORT_REJECTED;
}
interface GetProviderReportFulfilled {
  type: typeof s.GET_PROVIDER_REPORT_FULFILLED;
  payload: {
    data: ImpactDetails;
  };
}

type GetProviderReportActions =
  | GetProviderReport
  | GetProviderReportPending
  | GetProviderReportRejected
  | GetProviderReportFulfilled;

interface ResetSettlements {
  type: typeof s.RESET_SETTLEMENTS;
}

interface RemoveCase {
  type: typeof s.REMOVE_CASE;
  meta: {
    caseNumber: string;
  };
}

interface UpdateSettlementAmount {
  type: typeof s.UPDATE_SETTLEMENT_AMOUNT;
  meta: {
    claimID: string;
    amount: number;
  };
}

interface UpdateAllSettlementAmounts {
  type: typeof s.UPDATE_ALL_SETTLEMENT_AMOUNTS;
}

interface UpdateSettlementMethodology {
  type: typeof s.UPDATE_SETTLEMENT_METHODOLOGY;
  meta: {
    methodology: SettlementMethodology;
  };
}

interface UpdateSettlementPercent {
  type: typeof s.UPDATE_SETTLEMENT_PERCENT;
  meta: {
    settlementPercent: number;
  };
}

interface UpdateReportDisplayOptions {
  type: typeof s.UPDATE_REPORT_DISPLAY_OPTIONS;
  meta: {
    field: keyof DisplayReportOptions;
  };
}

interface UpdateMediVIChartOptions {
  type: typeof s.UPDATE_MEDIVI_CHART_OPTIONS;
  meta: {
    field: keyof MediVIChartOptions;
    value: MediVIProvider | number | boolean;
  };
}

interface GetProviderAutocomplete {
  type: typeof s.GET_PROVIDER_AUTOCOMPLETE;
  payload: AxiosPromise;
}

interface GetProviderAutocompletePending {
  type: typeof s.GET_PROVIDER_AUTOCOMPLETE_PENDING;
}
interface GetProviderAutocompleteRejected {
  type: typeof s.GET_PROVIDER_AUTOCOMPLETE_REJECTED;
}
interface GetProviderAutocompleteFulfilled {
  type: typeof s.GET_PROVIDER_AUTOCOMPLETE_FULFILLED;
  payload: {
    data: MediVIProvider[];
  };
}

type GetProviderAutocompleteActions =
  | GetProviderAutocomplete
  | GetProviderAutocompletePending
  | GetProviderAutocompleteRejected
  | GetProviderAutocompleteFulfilled;

interface GetMediVIChart {
  type: typeof s.GET_MEDIVI_CHART;
  payload: AxiosPromise;
}

interface GetMediVIChartPending {
  type: typeof s.GET_MEDIVI_CHART_PENDING;
}
interface GetMediVIChartRejected {
  type: typeof s.GET_MEDIVI_CHART_REJECTED;
}
interface GetMediVIChartFulfilled {
  type: typeof s.GET_MEDIVI_CHART_FULFILLED;
  payload: {
    data: ImpactChartData;
  };
}

interface ToggleChartPrintView {
  type: typeof s.TOGGLE_CHART_PRINT_VIEW;
}

interface SetSavedManualSettlementAmounts {
  type: typeof s.SET_SAVED_MANUAL_SETTLEMENT_AMOUNTS;
  meta: { amounts: Record<string, number> };
}

interface SetURLSynced {
  type: typeof s.SET_URL_SYNCED;
  meta: { isURLSynced: boolean };
}

type GetMediVIChartActions =
  | GetMediVIChart
  | GetMediVIChartPending
  | GetMediVIChartRejected
  | GetMediVIChartFulfilled;

export type SettlementsActionTypes =
  | GetClaimSummariesActions
  | ResetSettlements
  | RemoveCase
  | UpdateSettlementAmount
  | GetImpactReportActions
  | UpdateAllSettlementAmounts
  | UpdateSettlementMethodology
  | UpdateSettlementPercent
  | GetProviderReportActions
  | UpdateReportDisplayOptions
  | UpdateMediVIChartOptions
  | GetProviderAutocompleteActions
  | GetMediVIChartActions
  | ToggleChartPrintView
  | SetSavedManualSettlementAmounts
  | SetURLSynced;

export default s;
