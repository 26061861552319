import styled from '@material-ui/core/styles/styled';
import { colors } from '@apps/shared/src/style';

export const TableCellHeaderTop = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  padding: theme.spacing(2),
  color: colors.black,
  backgroundColor: colors.grey05,
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Column = styled('div')(({ theme }) => ({
  display: 'inline-block',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
  verticalAlign: 'top',
}));

export const addButtonStyle = {
  margin: '0px',
  backgroundColor: colors.green,
  '&:hover': {
    backgroundColor: '#1f8463',
  },
};

export const deleteButtonStyle = {
  marginLeft: '25px',
  backgroundColor: colors.red,
  '&:hover': {
    backgroundColor: colors.redScarlet,
  },
};

export const configSectionStyle = {
  width: '400px',
};

export const configIconStyle = {
  position: 'relative',
  top: '6px',
};
