import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { colors } from '@apps/shared/src/style';

const useStyles = makeStyles({
  header1: {
    backgroundColor: colors.grey07,
  },
  tableCell: {
    width: '10%',
    textAlign: 'center',
    verticalAlign: 'bottom',
  },
});

const headerRows = [
  'Patient',
  'Date of Service',
  'Billed Amount',
  'Medicare Allowed',
  'Repriced Amount',
  'Proposed Additional Payment', // more to be paid
  'Proposed Settlement Total', // total paid when all is done
  'Final Payable as Medicare Multiple', // how many times the total paid is of the medicare allowed
  // 'Final Payable as Cost Multiple', // how many times the total paid is of the cost
];

type ParentProps = {
  showCostMultiple: boolean;
};

type Props = ParentProps;

export default function ImpactClaimsHeaderRow({ showCostMultiple }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableRow className={classes.header1}>
      {headerRows.map(v => (
        <TableCell key={v} className={classes.tableCell}>
          {v}
        </TableCell>
      ))}

      {showCostMultiple && (
        <TableCell className={classes.tableCell}>Final Payable as Cost Multiple</TableCell>
      )}
    </TableRow>
  );
}
