import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import PasswordResetForm from './PasswordResetForm';

const useStyles = makeStyles({
  card: {
    margin: '2rem auto',
    padding: '2rem 2rem 0.5rem 2rem',
  },
});

export default function PasswordReset(): JSX.Element {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Card className={classes.card}>
        <PasswordResetForm />
      </Card>
    </Container>
  );
}
