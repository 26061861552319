import * as p from './types/actions';
import { PlansState, TPA, MasterPlan, PlanElection } from './types/plans';

export const zeroDate = '01/01/0001';
export const infinityDate = '01/01/9999';

export const defaultPlansState: PlansState = {
  areAllPlansLoading: false,
  masterPlans: {},
  allTPAs: {},
  filteredTPAs: [],
  allPlanElections: {},
  selectedElectionID: '',
  isLoading: false,
  isError: false,
};

const zeroMasterPlan: MasterPlan = {
  sfAccountID: '',
  masterPlanID: 0,
  masterPlanName: '',
  status: 'Active',
  planSelection: false,
  sixDegTPAID: 0,
};

export const zeroPlanElection = (masterPlanID: number): PlanElection => {
  return {
    FEUniqueId: '',
    sixDegPlanID: 0,
    planname: '',
    planid: '',
    sixdegtpaid: 0,
    censusnames: [],
    subPlanID: [],
    stagename: 'Live Plan',
    startdate: zeroDate,
    termdate: infinityDate,
    percentsavings: 0,
    invoicingtype: 'none',
    bypasseligibility: false,
    displaymedivimessage: false,
    medivimessage: '',
    facilityinvoicing: false,
    professionalinvoicing: false,
    groupidoncard: '',
    runoutenddate: '',
    indefiniterunout: false,
    repriceforrunoutperiod: false,
    runin: false,
    threshold: 0,
    tbcthreshold: false,
    mediviplanaccess: 'Full Access',
    masterplanid: masterPlanID,
    facilitymultiplier: 0,
    professionalmultiplier: 0,
    drugmultiplier: 0,
    anesthesiamultiplier: 0,
    proplus: '',
  };
};

const serializePlanElection = (planElection: PlanElection): PlanElection => {
  // Define the default values from zeroPlanElection
  const serializedPlanElection: PlanElection = {
    ...zeroPlanElection(planElection.masterplanid), // Start with default structure
    ...planElection, // Override with any provided fields (values from the current planElection)
  };

  // Handle any null or undefined values - only non-null values should persist
  const filteredPlanElection = Object.fromEntries(
    Object.entries(serializedPlanElection).filter(
      ([_, value]) => value !== null && value !== undefined
    )
  );

  return filteredPlanElection as PlanElection;
};

export default (state: PlansState = defaultPlansState, action: p.PlanActionTypes): PlansState => {
  switch (action.type) {
    case p.UPDATE_PLAN_INFO: {
      const { field, value, masterPlanID } = action.meta;
      const currentMasterPlan = state.masterPlans[masterPlanID];

      if (currentMasterPlan) {
        const updatedPlan = {
          ...currentMasterPlan,
          [field]: value,
        };

        return {
          ...state,
          masterPlans: {
            ...state.masterPlans,
            [masterPlanID]: updatedPlan,
          },
        };
      }

      return state;
    }

    case p.GET_ALL_PLANS_PENDING: {
      return {
        ...state,
        areAllPlansLoading: true,
      };
    }

    case p.GET_ALL_PLANS_REJECTED: {
      return {
        ...state,
        areAllPlansLoading: false,
      };
    }

    case p.GET_ALL_PLANS_FULFILLED: {
      const { masterPlans, planElections, tpas } = action.payload.data;

      return {
        ...state,
        areAllPlansLoading: false,
        masterPlans: { ...masterPlans, 0: zeroMasterPlan },
        allPlanElections: { ...planElections },
        allTPAs: { ...tpas },
        filteredTPAs: [],
      };
    }

    case p.ADD_PLAN_ELECTION: {
      const { planElection, FEUniqueId } = action.meta;
      const { allPlanElections } = state;

      return {
        ...state,
        allPlanElections: { ...allPlanElections, [FEUniqueId]: planElection },
      };
    }

    case p.UPDATE_PLAN_ELECTION: {
      const { planElection, sixDegPlanID } = action.meta;
      const { allPlanElections } = state;

      // Update the specific plan election using the provided sixDegPlanID
      const updatedPlanElection = {
        ...allPlanElections[sixDegPlanID], // Preserve existing data
        ...planElection, // Override with new data
      };

      return {
        ...state,
        allPlanElections: {
          ...allPlanElections,
          [sixDegPlanID]: updatedPlanElection,
        },
      };
    }

    case p.SET_SELECTED_ELECTION: {
      const { selectedID, masterPlanID } = action.meta;
      const { allPlanElections } = state;

      const selectedPlanElection = allPlanElections[selectedID];

      const isMatch = selectedPlanElection && selectedPlanElection.masterplanid === masterPlanID;

      if (isMatch) {
        return {
          ...state,
          selectedElectionID: selectedID,
        };
      }

      return state;
    }

    case p.REMOVE_SELECTED_ELECTION: {
      return {
        ...state,
        selectedElectionID: '',
      };
    }

    case p.DELETE_PLAN_ELECTION: {
      const { selectedID, masterPlanID } = action.meta;
      const { allPlanElections } = state;

      // Get the selected plan election by its selectedID
      const selectedPlanElection = allPlanElections[selectedID];

      // Check if the selected plan election exists and matches the masterPlanID
      const isMatch = selectedPlanElection && selectedPlanElection.masterplanid === masterPlanID;

      if (isMatch) {
        const updatedPlanElections = { ...allPlanElections };
        delete updatedPlanElections[selectedID];

        return {
          ...state,
          allPlanElections: updatedPlanElections,
        };
      }

      // Return the current state if no match is found
      return state;
    }

    case p.GET_PLAN_PENDING:
    case p.SAVE_PLAN_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case p.GET_PLAN_REJECTED:
    case p.SAVE_PLAN_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }

    case p.GET_PLAN_FULFILLED:
    case p.SAVE_PLAN_FULFILLED: {
      // eslint-disable-next-line
      const masterPlan = action.payload.masterPlan;

      const { allPlanElections } = state;

      masterPlan.sixDegTPAID = undefined;

      // Check for "Live Plan" election first
      const livePlanElection = Object.values(allPlanElections).find(
        election =>
          election.stagename === 'Live Plan' && election.masterplanid === masterPlan.masterPlanID
      );

      if (livePlanElection) {
        masterPlan.sixDegTPAID = livePlanElection.sixdegtpaid;
      } else {
        // If no "Live Plan", check for "Run Out"
        const runOutPlanElection = Object.values(allPlanElections).find(
          election =>
            election.stagename === 'Run Out' && election.masterplanid === masterPlan.masterPlanID
        );

        if (runOutPlanElection) {
          masterPlan.sixDegTPAID = runOutPlanElection.sixdegtpaid;
        } else {
          // If no "Run Out", check for "Termed"
          const termedPlanElection = Object.values(allPlanElections).find(
            election =>
              election.stagename === 'Termed' && election.masterplanid === masterPlan.masterPlanID
          );

          if (termedPlanElection) {
            masterPlan.sixDegTPAID = termedPlanElection.sixdegtpaid;
          }
        }
      }

      return {
        ...state,
        isLoading: false,
        masterPlans: {
          ...state.masterPlans,
          [masterPlan.masterPlanID]: masterPlan,
        },
      };
    }

    case p.SAVE_PLAN_ELECTION_PENDING: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }

    case p.SAVE_PLAN_ELECTION_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }

    case p.SAVE_PLAN_ELECTION_FULFILLED: {
      const updatedElection = action.payload;
      const serializedElection = serializePlanElection(updatedElection);
      const { masterplanid, sixDegPlanID, sixdegtpaid } = updatedElection;

      return {
        ...state,
        isLoading: false,
        allPlanElections: {
          ...state.allPlanElections,
          [sixDegPlanID]: {
            ...state.allPlanElections[sixDegPlanID],
            ...serializedElection,
          },
        },
      };
    }

    case p.UPDATE_MASTER_CENSUS_NAME: {
      const { masterCensus, masterPlanID } = action.meta;

      const currentMasterPlan = state.masterPlans[masterPlanID];

      if (currentMasterPlan) {
        const updatedPlan = {
          ...currentMasterPlan,
          masterCensus,
        };

        return {
          ...state,
          masterPlans: {
            ...state.masterPlans,
            [masterPlanID]: updatedPlan,
          },
        };
      }

      return state;
    }

    case p.SET_FILTERED_TPAS: {
      const { filteredTPAs } = action.meta;
      return {
        ...state,
        filteredTPAs,
      };
    }

    case p.REMOVE_NEW_PLAN_TPA: {
      return {
        ...state,
        masterPlans: { ...state.masterPlans, 0: zeroMasterPlan },
        allPlanElections: { ...state.allPlanElections },
      };
    }

    default:
      return state;
  }
};
