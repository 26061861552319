import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

function createOnValueChangeHandler(onChange, id, max) {
  return values => {
    onChange({
      target: {
        value: values.value,
        type: 'tel',
        id,
        max,
      },
    });
  };
}

export function NumberFormatMax(props) {
  const { inputRef, onChange, id, max, ...other } = props;
  return (
    <NumberFormat
      id={id}
      {...other}
      getInputRef={inputRef}
      onValueChange={createOnValueChangeHandler(onChange, id, max)}
      thousandSeparator
      decimalScale={2}
    />
  );
}

NumberFormatMax.defaultProps = {
  id: undefined,
  max: 1000,
};
NumberFormatMax.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  max: PropTypes.number,
};

export function NumberFormatFixed(props) {
  // couldn't figure out how to pass in isMoneyFixed to the NumberFormatCustom. With that, this component wouldn't be needed
  const { inputRef, onChange, id, ...other } = props;
  return (
    <NumberFormat
      {...other}
      id={id}
      getInputRef={inputRef}
      onValueChange={createOnValueChangeHandler(onChange, id)}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

NumberFormatFixed.defaultProps = {
  id: undefined,
};
NumberFormatFixed.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export function NumberFormatCustom(props) {
  const { inputRef, onChange, isMoneyFixed, isFloat, id, ...other } = props;
  return (
    <NumberFormat
      {...other}
      id={id}
      getInputRef={inputRef}
      onValueChange={createOnValueChangeHandler(onChange, id)}
      thousandSeparator
      decimalScale={isMoneyFixed || isFloat ? 2 : undefined}
      fixedDecimalScale={isMoneyFixed || isFloat}
    />
  );
}

NumberFormatCustom.defaultProps = {
  isMoneyFixed: false,
  isFloat: false,
  id: undefined,
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isMoneyFixed: PropTypes.bool,
  isFloat: PropTypes.bool,
  id: PropTypes.string,
};
