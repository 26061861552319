import React from 'react';
import { ContractPlan } from '../types/contracts';

export default function PlanTooltipTitle(plan: ContractPlan): JSX.Element {
  const { id, planName, policyNum } = plan;

  return (
    <div key={id}>
      {planName && <div>{planName}</div>}
      {policyNum && <div>Plan ID: {policyNum}</div>}
    </div>
  );
}
