import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  button: {
    margin: '1.5rem 0',
  },
});

type Props = {
  getMonthlyReportingPreflight: VoidFunction;
  isLoading: boolean;
};

export default function StartOrContinue({
  getMonthlyReportingPreflight,
  isLoading,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" color="secondary" gutterBottom>
        Claims Monthly Reporting
      </Typography>
      <Typography variant="body2">
        To create or continue a new report, <br />
        please click the button below.
      </Typography>

      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={getMonthlyReportingPreflight}
        disabled={isLoading}
      >
        Start/Continue Report
      </Button>
    </>
  );
}
