import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import DailyClaimsDateGroup from './DailyClaimsDateGroup';
import DailyClaimsRow from './DailyClaimsRow';

import { dailyClaimsProp } from '../../shared/types/propTypes';

const useStyles = makeStyles({
  tbody: {
    display: 'block',
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
  },
});

export default function DailyClaimsBody({ dailyClaims, handlers, selectedHandler, isIndividual }) {
  const classes = useStyles();

  if (!dailyClaims) return null;

  const filteredClaims = dailyClaims[selectedHandler] || [];
  const sortedDates = Object.keys(filteredClaims).sort().reverse();

  return (
    <tbody className={classes.tbody}>
      {sortedDates.map((date, index) =>
        isIndividual ? (
          <DailyClaimsRow
            key={date}
            row={dailyClaims[selectedHandler][date]}
            handler={selectedHandler}
            date={date}
            index={index}
            isBreakout={false}
            isIndividual
          />
        ) : (
          <DailyClaimsDateGroup
            key={date}
            date={date}
            index={index}
            handlerTotals={handlers
              .filter(handler => handler !== 'All' && dailyClaims[handler][date])
              .map(handler => ({
                handler,
                totals: dailyClaims[handler][date],
              }))}
            totalRow={dailyClaims.All[date]}
          />
        )
      )}
    </tbody>
  );
}

DailyClaimsBody.defaultProps = {
  dailyClaims: undefined,
};

DailyClaimsBody.propTypes = {
  dailyClaims: dailyClaimsProp,
  handlers: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedHandler: PropTypes.string.isRequired,
  isIndividual: PropTypes.bool.isRequired,
};
