import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { colors } from '@apps/shared/src/style';
import { formatCurrency } from '@apps/shared/src/utilities';

import { SettlementsClaimSummary } from '../types/types';
import { formatFloat } from '../../shared/globals';

export function sumClaimsField(
  claims: SettlementsClaimSummary[],
  field: keyof SettlementsClaimSummary
): number {
  return claims.reduce((acc, obj) => acc + Number(obj[field]), 0);
}

export function getTotalsMedicareMultiple(
  totalProposedSettlementAmount: number,
  totalMedicareAllowed: number
): number {
  return totalProposedSettlementAmount / totalMedicareAllowed;
}

export function getTotalsRow(claims: SettlementsClaimSummary[]): string[] {
  const totalProposedSettlementAmount = sumClaimsField(claims, 'proposedSettlementAmount');
  const totalMedicareAllowed = sumClaimsField(claims, 'medicareAllowed');
  return [
    formatCurrency(sumClaimsField(claims, 'billedAmount')),
    formatCurrency(totalMedicareAllowed),
    formatCurrency(sumClaimsField(claims, 'repriceTotal')),
    formatCurrency(sumClaimsField(claims, 'additionalPayment')),
    formatCurrency(totalProposedSettlementAmount),
    formatFloat(getTotalsMedicareMultiple(totalProposedSettlementAmount, totalMedicareAllowed)),
  ];
}

const useStyles = makeStyles({
  header2: {
    backgroundColor: colors.grey05,
  },
  totals: {
    fontWeight: 'bold',
    color: colors.black,
  },
});

type ParentProps = {
  claims: SettlementsClaimSummary[];
  showCostMultiple: boolean;
};

type Props = ParentProps;

export default function ImpactClaimsTotalsRow({ claims, showCostMultiple }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableRow className={classes.header2}>
      <TableCell colSpan={2} className={classes.totals} align="left">
        TOTALS
      </TableCell>
      {getTotalsRow(claims).map((v, i) => (
        // eslint-disable-next-line
        <TableCell key={`${v}${i}`} align="right" className={classes.totals}>
          {v}
        </TableCell>
      ))}

      {showCostMultiple && <TableCell className={classes.totals} />}
    </TableRow>
  );
}
