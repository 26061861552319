import { User } from '@apps/shared/src/auth/types';
import UserCard from '@apps/shared/src/auth/UserCard';
import {
  clearInviteStatus,
  getUsers,
  invite,
} from '@apps/shared/src/components/AdminUserList/adminActions';
import VirtualizedUserList from '@apps/shared/src/components/AdminUserList/VirtualizedUserList';
import LoadingIndicator from '@apps/shared/src/components/LoadingIndicator';
import { colors } from '@apps/shared/src/style/colors';
import { Button, Dialog, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../shared/components/header/Header';
import { RootState, InputChangeHandler } from '../shared/types/types';

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '93vh',
    overflowY: 'hidden',
  },
  invitationBox: {
    margin: '1em auto 0',
  },
  invitationLink: {
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
}));

// Component

type StateProps = {
  superAdmin: boolean;
  userList: User[];
  app: string;
  appRoles: string[];
  fetching: boolean;
  userInvited?: boolean;
};

const Users: React.FC<Props> = (props): JSX.Element => {
  const {
    superAdmin,
    userList,
    app,
    appRoles,
    fetching,
    userInvited,
    getUsers,
    invite,
    clearInviteStatus,
  } = props;
  const classes = useStyles();
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!userList || userList.length < 1) getUsers(app || '');
  }, [app, getUsers, userList]);

  const handleSubmit: FormEventHandler<Element> = e => {
    e.preventDefault();
    invite(email, '', app);
    setEmail('');
  };

  const handleChange: InputChangeHandler = e => {
    setEmail(e.currentTarget.value);
  };

  return (
    <>
      {fetching && (!userList || userList.length < 1) ? (
        <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
          <LoadingIndicator isLoading={fetching} size={75} color={colors.orange} />
        </div>
      ) : (
        <>
          <Header />
          <section className={classes.content}>
            <div className={classes.invitationBox}>
              <Typography variant="h6">
                <Button
                  onClick={(): void => setOpenEmailModal(true)}
                  title="Invite user to claims"
                  aria-label="Invite user to claims"
                  className={classes.invitationLink}
                >
                  Invite New User
                </Button>
              </Typography>
            </div>
            <VirtualizedUserList
              users={userList}
              app={app}
              appRoles={appRoles}
              superAdmin={superAdmin}
            />
          </section>
        </>
      )}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        title="Invite new user by email"
        open={openEmailModal}
        onClose={(): void => setOpenEmailModal(false)}
      >
        <div style={{ margin: '5% auto', width: '450px' }}>
          <UserCard showLogo>
            <form style={{ width: '100%', margin: 'auto' }} onSubmit={handleSubmit}>
              <div style={{ maxWidth: '450px', margin: 'auto', textAlign: 'left' }}>
                <TextField
                  label="Email"
                  value={email}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="normal"
                />
              </div>
              <div style={{ margin: '40px auto 0' }}>
                {!fetching && userInvited && <span>Email sent, invite another user?</span>}
                {!fetching && userInvited === false && (
                  <span>Something went wrong, email may be invalid.</span>
                )}
                <Button
                  style={{ marginTop: '2%', maxWidth: '350px' }}
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  {fetching ? (
                    <LoadingIndicator isLoading={fetching} size={15} color={colors.orange} />
                  ) : (
                    'SEND INVITE'
                  )}
                </Button>
                <Button
                  style={{ margin: 'auto', maxWidth: '350px' }}
                  variant="text"
                  onClick={(): void => {
                    setEmail('');
                    clearInviteStatus();
                    setOpenEmailModal(false);
                  }}
                  color="secondary"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </UserCard>
        </div>
      </Dialog>
    </>
  );
};

// Container

type DispatchProps = {
  getUsers: typeof getUsers;
  invite: typeof invite;
  clearInviteStatus: typeof clearInviteStatus;
};

const mapStateToProps = ({ admin, user }: RootState): StateProps => ({
  superAdmin:
    (user.currentUser &&
      user.currentUser?.roles?.filter(role => role === 'users-admin').length > 0) ||
    false,
  userList: admin.users || [],
  appRoles: admin.appRoles || [],
  app: admin.app || '',
  fetching: admin.fetching,
  userInvited: admin.userInvited,
});

const mapDispatchToProps = {
  getUsers,
  invite,
  clearInviteStatus,
};

type Props = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Users);
