import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';

import Header from './header/Header';
import SettlementMethodForm from './settlementMethod/SettlementMethodForm';
import AllCases from './cases/AllCases';
import ClaimsTable from './ClaimsTable';
import ReportOptions from './ReportOptions';
import MediVIOptions from './mediviOptions/MediVIOptions';
import Reports from './Reports';
import URLSyncer from './urlSync/URLSyncer';

const useStyles = makeStyles({
  settlements: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
    maxWidth: '1400px',
  },
  options: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    minHeight: '220px',
    marginTop: '30px',
    marginBottom: '50px',
  },
});

export default function Settlements(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.settlements}>
      <Header />
      <URLSyncer />
      <AllCases />
      <ClaimsTable />
      <div className={classes.options}>
        <SettlementMethodForm />
        <ReportOptions />
        <MediVIOptions />
      </div>
      <Reports />
    </div>
  );
}
