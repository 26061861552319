import { inflateString } from '../inflators';

export function isValidNumber(num: unknown): num is number {
  return typeof num === 'number' && !Number.isNaN(num);
}

export function isValidInteger(num: unknown): num is number {
  return isValidNumber(num) && Number.isInteger(Number(num));
}

export function isNonEmptyString(str: unknown): str is string {
  return typeof str === 'string' && str.length > 0;
}

export function isValidString(str: unknown): str is string {
  return typeof str === 'string';
}

export function isValidFunction(fxn: unknown): boolean {
  return typeof fxn === 'function';
}

export function isValidColor(color: unknown): boolean {
  const s = new Option().style;
  s.color = inflateString(color);
  return s.color !== '';
}
