import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import RosterUploadButton from './RosterUploadButton';

const rowHeight = 36;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '1rem 0',
    '& > *': {
      height: rowHeight,
    },
  },
  loadingSpinnerContainer: {
    width: rowHeight,
  },
});

type Props = {
  isRosterLoading: boolean;
  isAddButtonDisabled: boolean;
  handleAddManualRoster: VoidFunction;
};

export default function AddRoster({
  isRosterLoading,
  isAddButtonDisabled,
  handleAddManualRoster,
}: Props): JSX.Element {
  const classes = useStyles(rowHeight);
  return (
    <div className={classes.container}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleAddManualRoster}
        disabled={isAddButtonDisabled}
      >
        Add Providers Manually
      </Button>

      <div className={classes.loadingSpinnerContainer}>
        <LoadingSpinner isLoading={isRosterLoading} size={rowHeight} unstyledContainer />
      </div>

      <RosterUploadButton />
    </div>
  );
}
