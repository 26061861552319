import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
  chip: {
    margin: '0.25em',
  },
  selectedChip: {
    margin: '0.25em',
    backgroundColor: 'inherit',
    border: '1px solid lightgrey',
  },
});

type Props = {
  chipKey: string | number;
  tooltip: React.ReactElement | string;
  chipText: string;
  onDelete?: () => void;
  onClick?: () => void;
  isSelected?: boolean;
};

export default function TooltippedChip({
  chipKey,
  tooltip,
  chipText,
  onDelete,
  onClick,
  isSelected,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Tooltip key={chipKey} placement="bottom-end" title={tooltip}>
      <Chip
        className={isSelected ? classes.selectedChip : classes.chip}
        key={chipKey}
        label={chipText}
        onDelete={onDelete}
        onClick={onClick}
      />
    </Tooltip>
  );
}
