import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  spinner: {
    position: 'absolute',
    color: theme.palette.text.disabled,
  },
}));

type ParentProps = CircularProgressProps & {
  isLoading?: boolean;
  className?: string;
  children: React.ReactChild;
};

type Props = ParentProps;

export default function LoadingIcon({
  isLoading = true,
  children,
  className,
  size = '1.75em',
  ...rest
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {children}
      <Fade in={isLoading} style={{ transitionDelay: isLoading ? '400ms' : '0ms' }}>
        <CircularProgress
          className={classNames(classes.spinner, className)}
          size={size}
          {...rest}
        />
      </Fade>
    </>
  );
}
