import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import styled from '@material-ui/core/styles/styled';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { colors } from '@apps/shared/src/style';
import * as types from '../shared/types/propTypes';
import DateInput from './DateInput';
import TextInput from '../shared/components/TextInput';
import { getErrorText } from '../shared/validators';
import { isNumeric } from '../shared/globals';

// Column widths
const colWidths = {
  revCode: 70,
  serviceDate: 100,
  procCode: 70,
  procModString: 75,
  billedCharge: 100,
  units: 75,
  diagnosisCodePointers: 75,
  delete: 40,
  placeOfSvc: 95,
  add: 40,
};

// Used for headers of 4 sections of claim info
const ColumnHeader = styled('td')(({ theme }) => ({
  background: colors.grey05,
  padding: theme.spacing(1),
  fontWeight: 'bold',
  fontSize: 16,
  color: colors.black,
}));

// Used all over as wrapper for TD
const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const styles = {
  // Keeps service item table from wrapping
  table: {
    tableLayout: 'auto',
    whiteSpace: 'nowrap',
  },
  iconButton: {
    width: '40px',
    height: '40px',
    padding: '0',
    fontSize: '24px',
    color: colors.grey25,
    '&:hover': {
      color: colors.greyDark,
    },
  },
  claimTotal: {
    fontSize: '14px',
    color: colors.grey37,
    fontWeight: 'bold',
    textAlign: 'right',
  },
};

export class ServiceItems extends Component {
  handleRemoveServiceItem = index => () => this.props.removeServiceItem(index);
  handleInsertServiceItem = index => () => this.props.addServiceItem(index + 1);
  handleIntChange = (index, name) => e =>
    this.props.updateServiceValue(index, name, Number(e.target.value));

  handleFloatChange = (index, name) => e =>
    this.props.updateServiceValue(index, name, parseFloat(e.target.value));

  handleTextChange = (index, name) => e =>
    this.props.updateServiceValue(index, name, e.target.value.toUpperCase());

  handleDateChange = (index, name) => value => this.props.updateServiceValue(index, name, value);
  handleArrayChange = (index, name) => e =>
    this.props.updateServiceValue(
      index,
      name,
      e.target.value.split(/[\s,:]+/).map(i => (isNumeric(i) ? Number(i) : ''))
    );

  handleArrayBlur = (index, name) => value =>
    this.props.updateServiceValue(
      index,
      name,
      value
        .trim()
        .split(/[\s,:]+/)
        .map(i => (isNumeric(i) ? Number(i) : ''))
    );

  handleKeyDown = e => {
    if (e.keyCode === 9) this.props.addServiceItem();
  };

  handleClaimValueChange = (name, value) => () => this.props.updateClaimValue(name, value);

  handleInput = event => {
    const value = event.target.value.replace(/\W/g, '');
    /* eslint-disable no-param-reassign */
    event.target.value = value;
  };

  render() {
    const { services, isProfessional, classes, isReadOnly, forMemberReimbursement } = this.props;
    const claimTotal = services.reduce((acc, cur) => acc + cur.billedCharge, 0);

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {isProfessional && (
              <ColumnHeader style={{ width: colWidths.serviceDate }}>Service Date</ColumnHeader>
            )}
            {isProfessional && (
              <ColumnHeader style={{ width: colWidths.placeOfSvc }}>
                Place Of
                <br />
                Service
              </ColumnHeader>
            )}
            {!isProfessional && (
              <ColumnHeader style={{ width: colWidths.revCode }}>Rev Code</ColumnHeader>
            )}
            <ColumnHeader style={{ width: colWidths.procCode }}>HCPCS</ColumnHeader>
            <ColumnHeader style={{ width: colWidths.procModString }}>Mod</ColumnHeader>
            {isProfessional && (
              <ColumnHeader style={{ width: colWidths.diagnosisCodePointers }}>
                Diagnosis
                <br />
                Pointers
              </ColumnHeader>
            )}
            <ColumnHeader style={{ width: colWidths.units }}>Units</ColumnHeader>
            <ColumnHeader style={{ width: colWidths.billedCharge }}>
              Billed
              <br />
              Charge
            </ColumnHeader>
            <ColumnHeader style={{ width: colWidths.delete }}> &nbsp; </ColumnHeader>
            <ColumnHeader style={{ width: colWidths.add }}> &nbsp; </ColumnHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((si, index) => (
            /* eslint-disable react/no-array-index-key */
            <TableRow key={index} style={{ padding: '4px 0', borderBottom: '1px solid lightgrey' }}>
              {isProfessional && (
                <TableCell>
                  <DateInput
                    readOnly={isReadOnly}
                    name="serviceData"
                    label="Service Date"
                    onValueChange={this.handleDateChange(index, 'serviceDate')}
                    style={{ width: colWidths.serviceDate }}
                    value={si.serviceDate}
                    errorLabel={getErrorText(services[index], 'serviceDate')}
                    id={`row-${index + 1}-service-date`}
                  />
                </TableCell>
              )}
              {isProfessional && (
                <TableCell>
                  <TextInput
                    readOnly={isReadOnly}
                    label="Place of Svc"
                    allowedCharRegex="\d"
                    maxLength={2}
                    onChange={this.handleTextChange(index, 'placeOfSvc')}
                    style={{ width: colWidths.placeOfSvc }}
                    value={si.placeOfSvc}
                    errorLabel={getErrorText(services[index], 'placeOfSvc')}
                    id={`row-${index + 1}-place-of-service`}
                  />
                </TableCell>
              )}
              {!isProfessional && (
                <TableCell>
                  <TextInput
                    readOnly={isReadOnly}
                    label="RevCode"
                    allowedCharRegex="\d"
                    onChange={this.handleTextChange(index, 'revCode')}
                    style={{ width: colWidths.revCode }}
                    value={si.revCode}
                    errorLabel={getErrorText(services[index], 'revCode')}
                    id={`row-${index + 1}-rev-code`}
                  />
                </TableCell>
              )}
              <TableCell>
                <TextInput
                  readOnly={isReadOnly}
                  label="HCPCS"
                  maxLength={7}
                  allowedCharRegex="\w"
                  onInput={this.handleInput}
                  onChange={this.handleTextChange(index, 'procCode')}
                  style={{ width: colWidths.procCode }}
                  value={si.procCode}
                  errorLabel={getErrorText(services[index], 'procCode')}
                  id={`row-${index + 1}-hcpcs`}
                />
              </TableCell>
              <TableCell>
                <TextInput
                  readOnly={isReadOnly}
                  label="Mod"
                  allowedCharRegex="\w "
                  onChange={this.handleTextChange(index, 'procModString')}
                  style={{ width: colWidths.procModString }}
                  value={si.procModString}
                  errorLabel={getErrorText(services[index], 'procModString')}
                  id={`row-${index + 1}-mod`}
                />
              </TableCell>
              {isProfessional && (
                <TableCell>
                  <TextInput
                    readOnly={isReadOnly}
                    label="Diag Ptr"
                    allowedCharRegex="\d "
                    onChange={this.handleArrayChange(index, 'diagnosisCodePointers')}
                    onBlur={this.handleArrayBlur(index, 'diagnosisCodePointers')}
                    style={{ width: colWidths.diagnosisCodePointers }}
                    value={si.diagnosisCodePointers ? si.diagnosisCodePointers.join(' ') : ''}
                    errorLabel={getErrorText(services[index], 'diagnosisCodePointers')}
                  />
                </TableCell>
              )}
              <TableCell>
                <TextInput
                  readOnly={isReadOnly}
                  label="Units"
                  isFloat
                  onChange={this.handleFloatChange(index, 'units')}
                  style={{ width: colWidths.units }}
                  value={si.units}
                  errorLabel={getErrorText(services[index], 'units')}
                  id={`row-${index + 1}-units`}
                />
              </TableCell>
              <TableCell>
                <TextInput
                  readOnly={isReadOnly}
                  label="Charge"
                  isMoneyFixed
                  onChange={this.handleFloatChange(index, 'billedCharge')}
                  style={{ width: colWidths.billedCharge }}
                  value={si.billedCharge}
                  errorLabel={getErrorText(services[index], 'billedCharge')}
                  onKeyDown={index + 1 === services.length ? this.handleKeyDown : undefined}
                  id={`row-${index + 1}-billed-charge`}
                />
              </TableCell>
              <TableCell>
                {services.length > 1 ? (
                  <IconButton
                    className={classes.iconButton}
                    disabled={isReadOnly}
                    onClick={this.handleRemoveServiceItem(index)}
                    tabIndex={-1}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </TableCell>
              <TableCell>
                <IconButton
                  className={classes.iconButton}
                  disabled={isReadOnly}
                  onClick={this.handleInsertServiceItem(index)}
                  tabIndex={-1}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow style={{ background: colors.grey05 }}>
            <TableCell colSpan={1} />
            <TableCell colSpan={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!forMemberReimbursement}
                    id="for-member-reimbursement"
                    onChange={this.handleClaimValueChange(
                      'forMemberReimbursement',
                      !forMemberReimbursement
                    )}
                    disabled={isReadOnly}
                  />
                }
                label="For Member Reimbursement"
              />
            </TableCell>
            <TableCell
              colSpan={isProfessional ? 3 : 1}
              className={classes.claimTotal}
              style={{ width: colWidths.units }}
            >
              Claim Total
            </TableCell>
            <TableCell>
              <TextInput
                disabled
                id="claim-total"
                isMoneyFixed
                style={{ width: colWidths.billedCharge }}
                value={claimTotal}
              />
            </TableCell>
            <TableCell colSpan={2} />
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

ServiceItems.defaultProps = {
  forMemberReimbursement: false,
};

ServiceItems.propTypes = {
  removeServiceItem: PropTypes.func.isRequired,
  addServiceItem: PropTypes.func.isRequired,
  updateClaimValue: PropTypes.func.isRequired,
  updateServiceValue: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(types.serviceItem).isRequired,
  isProfessional: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  forMemberReimbursement: PropTypes.bool,
};

export default withStyles(styles)(ServiceItems);
