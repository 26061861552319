import { RootState } from '@apps/shared/src/auth/types';
import {
  requestPasswordReset,
  setIsPasswordResendSuccess,
} from '@apps/shared/src/auth/userActions';
import { PasswordResetArg } from '@apps/shared/src/auth/userActionTypes';
import { Button, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  message: {
    fontSize: '16px',
    overflowWrap: 'break-word',
  },
  button: {
    margin: '2rem 0 1rem',
    textTransform: 'none',
    backgroundColor: '#EF7125',
  },
  resendText: {
    fontSize: '14px',
    textTransform: 'none',
  },
  resendButton: {
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: 600,
  },
  resendResetDialog: {
    height: '15%',
  },
  dialogButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  dialogText: {
    maxWidth: '75%',
    margin: 'auto',
    padding: '7% 0 15%',
  },
});

type UserState = RootState['user'];
type StateProps = {
  isFetching: UserState['isFetching'];
  email: string;
  isPasswordResendSuccess: boolean;
};

const mapStateToProps = ({ user }: RootState): StateProps => ({
  isFetching: user.isFetching,
  email: user.currentUser?.email || '',
  isPasswordResendSuccess: user.isPasswordResendSuccess,
});

type DispatchProps = {
  requestPasswordReset: ({ email, destinationURL, isResend }: PasswordResetArg) => void;
  setIsPasswordResendSuccess: (isPasswordResendSuccess: boolean) => void;
};

const mapDispatchToProps = {
  requestPasswordReset,
  setIsPasswordResendSuccess,
};

type Props = StateProps & DispatchProps;

export function PasswordResetSuccess({
  isFetching,
  email,
  isPasswordResendSuccess,
  requestPasswordReset,
  setIsPasswordResendSuccess,
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  if (!email) return <Redirect to="/password-reset" />;

  const handleResendClick = (): void => {
    requestPasswordReset({
      email,
      destinationURL: '',
      isResend: true,
      application: 'Claims',
    });
  };

  const handleCloseDialog = (): void => {
    setIsPasswordResendSuccess(false);
  };

  const handleBackToLoginClick: VoidFunction = () => {
    history.push('/login');
  };

  return (
    <>
      <div style={{ width: '45%', margin: '15% auto auto', maxWidth: '500px' }}>
        <Typography color="textPrimary" className={classes.message}>
          {`An email has been sent to ${email} with instructions on how to reset the password.`}
        </Typography>
        <Button
          onClick={handleBackToLoginClick}
          color="primary"
          variant="contained"
          fullWidth
          disabled={isFetching}
          className={classes.button}
        >
          Back to login
        </Button>
        <div style={{ width: '25%', margin: 'auto' }}>
          <Typography color="textPrimary" className={classes.resendText}>
            Didn&apos;t get an email?{' '}
          </Typography>
          <Button
            style={{ marginLeft: '21%' }}
            onClick={handleResendClick}
            disabled={isFetching}
            className={classes.resendButton}
          >
            Resend
          </Button>
        </div>
      </div>
      <Dialog title="Email Resent" open={isPasswordResendSuccess} onClose={handleCloseDialog}>
        <p className={classes.dialogText}>{`Another email has been sent to ${email}`}</p>
        <Button className={classes.dialogButton} onClick={handleCloseDialog}>
          OK
        </Button>
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetSuccess);
