import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import TextInput from './TextInput';
import * as types from '../shared/types/propTypes';
import { externalNoteTypes, externalStatusNoteTypes } from './externalNoteTypes';

function ExternalRepricingNote({
  setAll,
  save,
  saveMetadata,
  fieldPrefix,
  type,
  value,
  simpleClaim,
}) {
  const handleSetAll = e => setAll({ ...saveMetadata, stringVal: e.target.value });

  const handleTypeChange = e => {
    save({
      ...saveMetadata,
      stringVal: e.target.value,
      fieldName: `${fieldPrefix}.code`,
    });
    if (!shouldShowInput(e.target.value)) {
      // don't show text value, so blank value out
      save({
        ...saveMetadata,
        stringVal: '',
        fieldName: `${fieldPrefix}.comment`,
      });
    }
  };

  const shouldShowInput = (code, type) => {
    const note = type !== 'claim' && externalNoteTypes.find(i => i.code === code);
    return note ? note.hasFreeText === true : false;
  };

  let e;

  if (value && value.code) {
    e = value;
  } else if (simpleClaim.extCmnt && simpleClaim.extCmnt.code) {
    e = simpleClaim.extCmnt;
    if (value.comment !== '') {
      e.comment = value.comment;
    }
  } else {
    e = externalNoteTypes[0];
  }
  const showInput = shouldShowInput(e.code, type);

  return (
    <>
      <FormControl
        style={{
          gridColumn: 'external',
          width: type !== 'aging' ? '100%' : '18em',
          alignSelf: 'start',
        }}
      >
        <Select
          value={type === 'claim' ? 'setall' : e.code}
          onChange={type === 'claim' ? handleSetAll : handleTypeChange}
          style={{ fontSize: '1em' }}
          id={`${saveMetadata.serviceIndex}-external-notes-select`}
        >
          {type === 'claim' && (
            <MenuItem
              key="setall"
              disabled
              value="setall"
              style={{ padding: '0 1rem', fontSize: '0.75rem', minHeight: '1.5rem' }}
            >
              Set All
            </MenuItem>
          )}
          {externalNoteTypes.map((option, index) => {
            if (option.type === 'both' || option.type === type || e.code === 'DUP') {
              return (
                <MenuItem
                  id={`${saveMetadata.serviceIndex}-external-note-select-option${index}`}
                  key={option.code}
                  value={option.code}
                  style={{ padding: '0 1rem', fontSize: '0.75rem', minHeight: '1.5rem' }}
                >
                  {option.comment}
                </MenuItem>
              );
            }
            return null;
          })}
        </Select>

        {showInput && (
          <TextInput
            value={e.comment}
            style={{ fontSize: '1em', marginTop: '1%', maxHeight: '25px' }}
            isText
            maxLength={74}
            saveMetadata={{ ...saveMetadata, fieldName: `${fieldPrefix}.comment` }}
            save={save}
          />
        )}
      </FormControl>
    </>
  );
}

ExternalRepricingNote.defaultProps = {
  value: undefined,
};

ExternalRepricingNote.propTypes = {
  setAll: PropTypes.func.isRequired,
  saveMetadata: types.saveMetadata.isRequired,
  save: PropTypes.func.isRequired,
  fieldPrefix: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: types.externalComment,
};
const mapStateToProps = ({ claims }) => ({
  simpleClaim: claims.selectedClaim.simpleClaim,
});

export default connect(mapStateToProps)(ExternalRepricingNote);
