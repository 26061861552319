import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@apps/shared/src/style';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { inflateString } from '@apps/shared/src/inflators';

const useStyles = makeStyles({
  card: {
    margin: '5em auto 0',
    width: '20em',
  },
  title: {
    fontWeight: 'bold',
    padding: '1em',
    color: colors.black,
    backgroundColor: colors.grey12,
    display: 'flex',
  },
  body: {
    padding: '1em',
  },
});

type Props = {
  title: string;
  message: string;
  visible: boolean;
};

export default function ContractMessage({
  message,
  title,
  visible = true,
}: Props): JSX.Element | null {
  const classes = useStyles();
  if (!visible) return null;

  return (
    <Card className={classes.card}>
      <div className={classes.title}>{inflateString(title)}</div>
      <Typography variant="body1" className={classes.body}>
        {inflateString(message)}
      </Typography>
    </Card>
  );
}
