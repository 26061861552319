import { AxiosPromise } from 'axios';
import {
  EventsourceUpdate,
  TermItem,
  TermValue,
  ContractRoster,
  ContractUpdate,
  AutocompleteOption,
  ContractSummary,
  ContractDetail,
  AutocompleteOptions,
  ContractTerm,
  ContractProvider,
  AutocompleteType,
} from './contracts';

export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_CONTRACTS_PENDING = 'GET_CONTRACTS_PENDING';
export const GET_CONTRACTS_FULFILLED = 'GET_CONTRACTS_FULFILLED';
export const GET_CONTRACTS_REJECTED = 'GET_CONTRACTS_REJECTED';

export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_CONTRACT_PENDING = 'GET_CONTRACT_PENDING';
export const GET_CONTRACT_FULFILLED = 'GET_CONTRACT_FULFILLED';
export const GET_CONTRACT_REJECTED = 'GET_CONTRACT_REJECTED';

export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const CREATE_CONTRACT_PENDING = 'CREATE_CONTRACT_PENDING';
export const CREATE_CONTRACT_FULFILLED = 'CREATE_CONTRACT_FULFILLED';
export const CREATE_CONTRACT_REJECTED = 'CREATE_CONTRACT_REJECTED';

export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const UPDATE_CONTRACT_PENDING = 'UPDATE_CONTRACT_PENDING';
export const UPDATE_CONTRACT_FULFILLED = 'UPDATE_CONTRACT_FULFILLED';
export const UPDATE_CONTRACT_REJECTED = 'UPDATE_CONTRACT_REJECTED';

export const DELETE_CONTRACT = 'DELETE_CONTRACT';
export const DELETE_CONTRACT_PENDING = 'DELETE_CONTRACT_PENDING';
export const DELETE_CONTRACT_FULFILLED = 'DELETE_CONTRACT_FULFILLED';
export const DELETE_CONTRACT_REJECTED = 'DELETE_CONTRACT_REJECTED';

export const SET_AUTOCOMPLETE_TYPE = 'SET_AUTOCOMPLETE_TYPE';
export const GET_AUTOCOMPLETE = 'GET_AUTOCOMPLETE';
export const GET_AUTOCOMPLETE_PENDING = 'GET_AUTOCOMPLETE_PENDING';
export const GET_AUTOCOMPLETE_FULFILLED = 'GET_AUTOCOMPLETE_FULFILLED';
export const GET_AUTOCOMPLETE_REJECTED = 'GET_AUTOCOMPLETE_REJECTED';

export const UPDATE_FILTERED_CONTRACTS = 'UPDATE_FILTERED_CONTRACTS';
export const SEARCH_CONTRACTS = 'SEARCH_CONTRACTS';
export const UPDATE_CONTRACTS_ON_EVENTSOURCE = 'UPDATE_CONTRACTS_ON_EVENTSOURCE';
export const CLEAR_SELECTED_CONTRACT = 'CLEAR_SELECTED_CONTRACT';
export const CLEAR_NEW_CONTRACT_ID = 'CLEAR_NEW_CONTRACT_ID';

export const UPDATE_TERM_VALUE = 'UPDATE_TERM_VALUE';
export const CLEAR_TERM_VALUES = 'CLEAR_TERM_VALUES';
export const SET_SELECTED_TERM = 'SET_SELECTED_TERM';
export const SET_SELECTED_TERM_ITEMS = 'SET_SELECTED_TERM_ITEMS';

export const SUBMIT_NEW_TERM = 'SUBMIT_NEW_TERM';
export const SUBMIT_NEW_TERM_PENDING = 'SUBMIT_NEW_TERM_PENDING';
export const SUBMIT_NEW_TERM_FULFILLED = 'SUBMIT_NEW_TERM_FULFILLED';
export const SUBMIT_NEW_TERM_REJECTED = 'SUBMIT_NEW_TERM_REJECTED';

export const ADD_OR_UPDATE_ROSTER = 'ADD_OR_UPDATE_ROSTER';
export const ADD_OR_UPDATE_ROSTER_PENDING = 'ADD_OR_UPDATE_ROSTER_PENDING';
export const ADD_OR_UPDATE_ROSTER_FULFILLED = 'ADD_OR_UPDATE_ROSTER_FULFILLED';
export const ADD_OR_UPDATE_ROSTER_REJECTED = 'ADD_OR_UPDATE_ROSTER_REJECTED';

export const REMOVE_ROSTER = 'REMOVE_ROSTER';
export const REMOVE_ROSTER_PENDING = 'REMOVE_ROSTER_PENDING';
export const REMOVE_ROSTER_FULFILLED = 'REMOVE_ROSTER_FULFILLED';
export const REMOVE_ROSTER_REJECTED = 'REMOVE_ROSTER_REJECTED';

export const LOOKUP_NPI = 'LOOKUP_NPI';
export const LOOKUP_NPI_PENDING = 'LOOKUP_NPI_PENDING';
export const LOOKUP_NPI_FULFILLED = 'LOOKUP_NPI_FULFILLED';
export const LOOKUP_NPI_REJECTED = 'LOOKUP_NPI_REJECTED';

export const GET_ROSTER = 'GET_ROSTER';
export const GET_ROSTER_PENDING = 'GET_ROSTER_PENDING';
export const GET_ROSTER_FULFILLED = 'GET_ROSTER_FULFILLED';
export const GET_ROSTER_REJECTED = 'GET_ROSTER_REJECTED';

export const SET_SELECTED_PROVIDER = 'SET_SELECTED_PROVIDER';

export const UPDATE_SELECTED_ROSTER = 'UPDATE_SELECTED_ROSTER';

export const SET_BOOLEAN_ELEMENT = 'SET_BOOLEAN_ELEMENT';

export const PARSE_ROSTER_FILE = 'PARSE_ROSTER_FILE';
export const PARSE_ROSTER_FILE_PENDING = 'PARSE_ROSTER_FILE_PENDING';
export const PARSE_ROSTER_FILE_FULFILLED = 'PARSE_ROSTER_FILE_FULFILLED';
export const PARSE_ROSTER_FILE_REJECTED = 'PARSE_ROSTER_FILE_REJECTED';

interface GetContractsAction {
  type: typeof GET_CONTRACTS;
  payload: AxiosPromise;
}

interface GetContractsPendingAction {
  type: typeof GET_CONTRACTS_PENDING;
}
interface GetContractsRejectedAction {
  type: typeof GET_CONTRACTS_REJECTED;
}
interface GetContractsFulfilledAction {
  type: typeof GET_CONTRACTS_FULFILLED;
  payload: ContractSummary[];
}
type GetContractsActions =
  | GetContractsAction
  | GetContractsPendingAction
  | GetContractsRejectedAction
  | GetContractsFulfilledAction;

interface GetContractAction {
  type: typeof GET_CONTRACT;
  meta: {
    id: number;
  };
  payload: AxiosPromise;
}
interface GetContractPendingAction {
  type: typeof GET_CONTRACT_PENDING;
  meta: {
    id: number;
  };
}
interface GetContractRejectedAction {
  type: typeof GET_CONTRACT_REJECTED;
  meta: {
    id: number;
  };
}
interface GetContractFulfilledAction {
  type: typeof GET_CONTRACT_FULFILLED;
  payload: {
    data: ContractDetail;
  };
}
type GetContractActions =
  | GetContractAction
  | GetContractPendingAction
  | GetContractRejectedAction
  | GetContractFulfilledAction;

interface SetAutoCompleteTypeAction {
  type: typeof SET_AUTOCOMPLETE_TYPE;
  payload: AutocompleteType;
}

interface GetAutocompleteAction {
  type: typeof GET_AUTOCOMPLETE;
  payload: AxiosPromise;
}
interface GetAutocompleteFulfilledAction {
  type: typeof GET_AUTOCOMPLETE_FULFILLED;
  payload: {
    data: AutocompleteOptions;
  };
}
type GetAutocompleteActions = GetAutocompleteAction | GetAutocompleteFulfilledAction;

interface ClearContractAction {
  type: typeof CLEAR_SELECTED_CONTRACT;
}

interface SearchContractsAction {
  type: typeof SEARCH_CONTRACTS;
  meta: string;
}

interface UpdateContractOnEventSourceAction {
  type: typeof UPDATE_CONTRACTS_ON_EVENTSOURCE;
  payload: EventsourceUpdate;
}

interface ClearTermValuesAction {
  type: typeof CLEAR_TERM_VALUES;
}

interface UpdateTermValueAction {
  type: typeof UPDATE_TERM_VALUE;
  payload: {
    termType: TermItem;
    value: TermValue;
  };
}

interface SetSelectedAction {
  type: typeof SET_SELECTED_TERM_ITEMS;
  payload: {
    selections: TermItem[];
  };
}

interface LookupNPIAction {
  type: typeof LOOKUP_NPI;
  meta: {
    npi: string;
  };
  payload: AxiosPromise;
}
interface LookupNPIPendingAction {
  type: typeof LOOKUP_NPI_PENDING;
}
interface LookupNPIRejectedAction {
  type: typeof LOOKUP_NPI_REJECTED;
  meta: {
    npi: string;
  };
  payload: {
    response: {
      data: string;
    };
  };
}
interface LookupNPIFulfilledAction {
  type: typeof LOOKUP_NPI_FULFILLED;
  meta: {
    npi: string;
  };
  payload: {
    data: {
      firstName: string;
      lastNameOrOrg: string;
    };
  };
}
type LookupNPIActions =
  | LookupNPIAction
  | LookupNPIPendingAction
  | LookupNPIRejectedAction
  | LookupNPIFulfilledAction;

interface UpdateContractAction {
  type: typeof UPDATE_CONTRACT;
  payload: AxiosPromise;
  meta: { id: number; updateObject: ContractUpdate; elementToAttach?: AutocompleteOption };
}
interface UpdateContractFulfilledAction {
  type: typeof UPDATE_CONTRACT_FULFILLED;
  payload: {
    data: ContractSummary;
  };
  meta: { id: number; updateObject: ContractUpdate; elementToAttach: AutocompleteOption };
}
type UpdateContractActions = UpdateContractAction | UpdateContractFulfilledAction;

interface DeleteContractAction {
  type: typeof DELETE_CONTRACT;
  payload: AxiosPromise;
  meta: { id: number };
}
interface DeleteContractPendingAction {
  type: typeof DELETE_CONTRACT_PENDING;
}
interface DeleteContractFulfilledAction {
  type: typeof DELETE_CONTRACT_FULFILLED;
  meta: { id: number };
}
interface DeleteContractRejectedAction {
  type: typeof DELETE_CONTRACT_REJECTED;
}
type DeleteContractActions =
  | DeleteContractAction
  | DeleteContractPendingAction
  | DeleteContractFulfilledAction
  | DeleteContractRejectedAction;

interface CreateContractAction {
  type: typeof CREATE_CONTRACT;
  payload: AxiosPromise;
}
interface CreateContractPendingAction {
  type: typeof CREATE_CONTRACT_PENDING;
}

interface CreateContractRejectedAction {
  type: typeof CREATE_CONTRACT_REJECTED;
}

interface CreateContractFulfilledAction {
  type: typeof CREATE_CONTRACT_FULFILLED;
  payload: {
    data: {
      summary: ContractSummary;
      newContract: ContractDetail;
    };
  };
}
type CreateContractActions =
  | CreateContractAction
  | CreateContractPendingAction
  | CreateContractRejectedAction
  | CreateContractFulfilledAction;

interface SubmitNewTermAction {
  type: typeof SUBMIT_NEW_TERM;
  payload: AxiosPromise;
  meta: ContractTerm;
}
interface SubmitNewTermFulfilledAction {
  type: typeof SUBMIT_NEW_TERM_FULFILLED;
  payload: {
    data: number;
  };
  meta: ContractTerm;
}
type SubmitNewTermActions = SubmitNewTermAction | SubmitNewTermFulfilledAction;

interface GetRosterAction {
  type: typeof GET_ROSTER;
  payload: AxiosPromise;
}
interface GetRosterPendingAction {
  type: typeof GET_ROSTER_PENDING;
}
interface GetRosterRejectedAction {
  type: typeof GET_ROSTER_REJECTED;
}
interface GetRosterFulfilledAction {
  type: typeof GET_ROSTER_FULFILLED;
  meta: {
    selectedContractID: number;
  };
  payload: {
    data: ContractRoster;
  };
}
type GetRosterActions =
  | GetRosterAction
  | GetRosterPendingAction
  | GetRosterRejectedAction
  | GetRosterFulfilledAction;

interface UpdateSelectedRosterAction {
  type: typeof UPDATE_SELECTED_ROSTER;
  payload: ContractRoster;
}

interface AddOrUpdateRosterAction {
  type: typeof ADD_OR_UPDATE_ROSTER;
  payload: AxiosPromise;
  meta: {
    submittedRoster: ContractRoster;
  };
}
interface AddOrUpdateRosterPendingAction {
  type: typeof ADD_OR_UPDATE_ROSTER_PENDING;
}
interface AddOrUpdateRosterRejectedAction {
  type: typeof ADD_OR_UPDATE_ROSTER_REJECTED;
}
interface AddOrUpdateRosterFulfilledAction {
  type: typeof ADD_OR_UPDATE_ROSTER_FULFILLED;
  payload: {
    data: {
      rosterID: number;
      contractID: number;
    };
  };
  meta: {
    submittedRoster: ContractRoster;
  };
}
type AddOrUpdateRosterActions =
  | AddOrUpdateRosterAction
  | AddOrUpdateRosterPendingAction
  | AddOrUpdateRosterRejectedAction
  | AddOrUpdateRosterFulfilledAction;

interface RemoveRosterAction {
  type: typeof REMOVE_ROSTER;
  payload: AxiosPromise;
}
interface RemoveRosterPendingAction {
  type: typeof REMOVE_ROSTER_PENDING;
}
interface RemoveRosterRejectedAction {
  type: typeof REMOVE_ROSTER_REJECTED;
}
interface RemoveRosterFulfilledAction {
  type: typeof REMOVE_ROSTER_FULFILLED;
  payload: {
    data: {
      rosterID: number;
    };
  };
}
type RemoveRosterActions =
  | RemoveRosterAction
  | RemoveRosterPendingAction
  | RemoveRosterRejectedAction
  | RemoveRosterFulfilledAction;

interface SetSelectedProviderAction {
  type: typeof SET_SELECTED_PROVIDER;
  payload: ContractProvider;
}

interface SetSelectedTermAction {
  type: typeof SET_SELECTED_TERM;
  payload: { term: ContractTerm };
}

interface SetBooleanElementAction {
  type: typeof SET_BOOLEAN_ELEMENT;
  payload: { fieldName: string; bool: boolean };
}

interface ParseRosterAction {
  type: typeof PARSE_ROSTER_FILE;
  meta: {
    selectedContractID: number;
  };
  payload: AxiosPromise;
}
interface ParseRosterPendingAction {
  type: typeof PARSE_ROSTER_FILE_PENDING;
}
interface ParseRosterFulfilledAction {
  type: typeof PARSE_ROSTER_FILE_FULFILLED;
  meta: {
    selectedContractID: number;
  };
  payload: {
    data: ContractRoster;
  };
}
interface ParseRosterRejectedAction {
  type: typeof PARSE_ROSTER_FILE_REJECTED;
}
type ParseRosterFileActions =
  | ParseRosterAction
  | ParseRosterPendingAction
  | ParseRosterFulfilledAction
  | ParseRosterRejectedAction;

export type ContractActionTypes =
  | GetContractsActions
  | GetContractActions
  | SetAutoCompleteTypeAction
  | GetAutocompleteActions
  | ClearContractAction
  | SearchContractsAction
  | UpdateContractOnEventSourceAction
  | ClearTermValuesAction
  | UpdateTermValueAction
  | SetSelectedAction
  | LookupNPIActions
  | UpdateContractActions
  | DeleteContractActions
  | CreateContractActions
  | SubmitNewTermActions
  | GetRosterActions
  | UpdateSelectedRosterAction
  | AddOrUpdateRosterActions
  | RemoveRosterActions
  | SetSelectedProviderAction
  | SetSelectedTermAction
  | SetBooleanElementAction
  | ParseRosterFileActions;
