import { splitCamelCase } from '@apps/shared/src/utilities';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import LinkIcon from '@material-ui/icons/Link';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import getTermText from '../contracts/terms/getTermText';
import { ContractTypes } from '../contracts/types/contracts';
import TooltipStringArray from '../shared/components/TooltipStringArray';
import { AppTheme } from '../shared/types/themeType';
import { PartialContract } from './types/repricingTypes';
import OptInHoverMeta from './OptInHoverMeta';

type makeStylesProps = Pick<Props, 'isEven' | 'color'> & { isHighlighted?: boolean };

const useStyles = makeStyles<AppTheme, makeStylesProps>(theme => ({
  tableRow: theme.flagTable.tableRow,
  selectedRow: {
    outline: `2px solid black`,
    outlineOffset: '-2px',
    '& td': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
  link: theme.flagTable.link,
  linkIcon: theme.flagTable.linkIcon,
  button: theme.flagTable.actionButton,
  deselectIcon: {
    paddingRight: '0.25em',
  },
  termList: {
    '& > ul': {
      textAlign: 'left',
      margin: 0,
    },
  },
}));

export type Props = {
  isEven: boolean;
  contract: PartialContract;
  color: string;
  showAllMatches: boolean;
  isSelectable: boolean;
  contractID: number | null;
  handleSelectContract: (
    contractID: number | null,
    providerGroup: string,
    contractType: ContractTypes
  ) => VoidFunction;
};

export default function ContractMatchTableRow({
  isEven,
  contract,
  color,
  showAllMatches,
  isSelectable,
  contractID,
  handleSelectContract,
}: Props): JSX.Element | null {
  const isSelected = contract.id === contractID;
  const hasUserMadeASelection = contractID !== null;
  const classes = useStyles({ isEven, color, isHighlighted: isSelected });
  const { id, providerGroup, contractType, patients, terms } = contract;

  if (hasUserMadeASelection && !(showAllMatches || isSelected)) return null;
  const displayTerms = terms.map(getTermText);
  const patientNames = patients.map(patient => patient.name);

  const actionBtnChildren = isSelected ? (
    <>
      <CancelIcon className={classes.deselectIcon} />
      DESELECT
    </>
  ) : (
    'SELECT'
  );

  const actionBtnHandler = handleSelectContract(
    isSelected ? null : id,
    providerGroup,
    contractType
  );

  return (
    <TableRow
      className={classNames(classes.tableRow, isSelected && classes.selectedRow)}
      data-testid="contract-table-row"
    >
      <TableCell>
        <Link
          to={`/contracts/${id}`}
          title="Link to Contract"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <LinkIcon className={classes.linkIcon} />
        </Link>
      </TableCell>

      {contract.id && contractType === 'DirectOptIn' ? (
        <TableCell>
          <OptInHoverMeta id={contract.id} value={splitCamelCase(contractType)} />{' '}
        </TableCell>
      ) : (
        <TableCell>{splitCamelCase(contractType)}</TableCell>
      )}

      <TableCell>{providerGroup}</TableCell>
      <TableCell>
        <TooltipStringArray stringArr={patientNames} />
      </TableCell>
      <TableCell className={classes.termList}>
        <ul>
          {displayTerms.length === 1
            ? displayTerms[0]
            : displayTerms.map(term => <li key={term}>{term}</li>)}
        </ul>
      </TableCell>
      {isSelectable && (
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={actionBtnHandler}
            className={classes.button}
          >
            {actionBtnChildren}
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
}
