import React, { ElementType, ReactNode } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import styled from '@material-ui/core/styles/styled';

import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import { colors } from '@apps/shared/src/style';
import { isArrayFilled, validArray } from '../../shared/typeChecks';
import { ChipBundle } from '../types/contracts';

const useStyles = makeStyles({
  header: {
    alignItems: 'center',
  },
  column: {
    padding: '1em',
    width: 'calc(100% - 2em)',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  box: {
    minWidth: '35em',
  },
  icon: {
    marginRight: '0.35em',
  },
});

export const TopLevelCell = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  padding: theme.spacing(2),
  color: colors.black,
  backgroundColor: colors.grey12,
  display: 'flex',
}));

function displayDefault(isEdit: boolean, defaultMessage: string): JSX.Element | null {
  if (!isEdit && defaultMessage) return <Chip label={defaultMessage} />;
  return null;
}

type Item = { id?: number; npi?: string; taxID?: string };

export function itemKey(item: Item): string | number {
  const { id, npi, taxID } = item;
  if (id) return id;
  if (npi && taxID) return `${npi}${taxID}`;
  if (npi) return npi;
  if (taxID) return taxID;
  return JSON.stringify(item);
}

function displayItems(isEdit: boolean, chipBundles: ChipBundle[]): ReactNode {
  return chipBundles.map(bundle => {
    const { items, ItemChip, handleRemoveItem, handleClickItem } = bundle;
    return items.map((item: Item, index: number) => (
      <ItemChip
        key={itemKey(item)}
        index={index}
        item={item}
        isEdit={isEdit}
        handleRemove={handleRemoveItem}
        onClick={handleClickItem}
      />
    ));
  });
}

type Props = {
  isEdit: boolean;
  allowEmpty?: boolean;
  chipBundles: ChipBundle[];
  title: string;
  Icon?: ElementType;
  gridArea: string;
  children: ReactNode;
  defaultMessage: string;
};
export default function ChipBox({
  isEdit,
  allowEmpty,
  chipBundles,
  title,
  Icon,
  gridArea,
  children,
  defaultMessage = '',
}: Props): JSX.Element | null {
  const classes = useStyles();
  const hasItems = validArray(chipBundles).some(bundle => isArrayFilled(bundle.items));
  if (!(isEdit || hasItems || defaultMessage)) return null;

  // eslint-disable-next-line no-nested-ternary
  const content = hasItems
    ? displayItems(isEdit, chipBundles)
    : allowEmpty
    ? displayItems(isEdit, [])
    : displayDefault(isEdit, defaultMessage);

  return (
    <Card className={classes.box} style={{ gridArea }}>
      <TopLevelCell className={classes.header}>
        {Icon && <Icon className={classes.icon} />}
        {title}
      </TopLevelCell>
      <div className={classes.column}>
        {content}
        {children}
      </div>
    </Card>
  );
}
