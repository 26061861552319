import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '30px',
    maxWidth: '350px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    },
  },
}));

type ParentProps = {
  children: JSX.Element;
  classes?: ClassNameMap<'card'>;
  showLogo?: boolean;
};

const UserCard: React.FC<ParentProps> = (props): JSX.Element => {
  const { showLogo, classes, children } = props;
  const rootClass = useStyles();
  return (
    <Card className={clsx(rootClass.card, classes?.card)}>
      {showLogo && (
        <CardContent>
          <img alt="6 Degrees Health Logo" src="./images/6_degrees_logo.svg" height="70px" />
        </CardContent>
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default UserCard;
