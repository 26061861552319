import React, { ChangeEventHandler } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  input: { margin: '1rem', width: '10rem' },
});

export type ParentProps = {
  type: 'start' | 'end';
  dateISO: string;
  errorText: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
};

export default function DateInput({
  type,
  dateISO,
  errorText,
  handleChange,
}: ParentProps): JSX.Element {
  const classes = useStyles();

  const labelText = type === 'start' ? 'Start Date' : 'End Date';
  const isError = errorText !== '';

  return (
    <div className={classes.input}>
      <FormControl fullWidth>
        <InputLabel htmlFor={`${type}-date`} shrink>
          {labelText}
        </InputLabel>
        <Input
          id={`${type}-date`}
          type="date"
          value={dateISO}
          onChange={handleChange}
          error={isError}
        />
        <FormHelperText id={`${type}-date-error-text`} error={isError}>
          {errorText}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
