import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import { claimStatusCodes as s } from '../shared/status';

const newIcon = 'add_circle_outline';
const startedIcon = 'settings';
const doneIcon = 'check_circle';
const infoIcon = 'info';
const flagIcon = 'flag';
const deniedIcon = 'cancel';

const moneyIcon = { icon: 'attach_money', iconSize: '28px' };
const gavelIcon = { icon: 'gavel', iconSize: '22px' };
const hospitalIcon = { icon: 'local_hospital', iconSize: '22px' };
const verifyIcon = { icon: 'verified_user', iconSize: '24px' };
const holdIcon = { icon: 'pan_tool', iconSize: '18px' };
const editIcon = { icon: 'edit', iconSize: '28px' };
const researchIcon = { icon: 'mood_bad', iconSize: '28px' };
const researchedIcon = { icon: 'mood', iconSize: '28px' };
const ineligibleIcon = { icon: 'highlight_off', iconSize: '28px' };

const icons = {
  [s.repriceNew]: { ...moneyIcon, color: colors.green, smallIcon: newIcon },
  [s.repriceStarted]: { ...moneyIcon, color: colors.grey50, smallIcon: startedIcon },
  [s.repriced]: { ...moneyIcon, color: colors.green, smallIcon: doneIcon },
  [s.miscalculation]: { ...moneyIcon, color: colors.red, smallIcon: infoIcon },
  // [s.adjudicateNew]: { ...gavelIcon, color: colors.blueLight, smallIcon: newIcon },
  // [s.adjudicateStarted]: { ...gavelIcon, color: colors.grey50, smallIcon: startedIcon },
  // [s.adjudicated]: { ...gavelIcon, color: colors.blueLight, smallIcon: doneIcon },
  // [s.adjudicateMiscalculation]: { ...gavelIcon, color: colors.red, smallIcon: infoIcon },
  [s.clinicalNew]: { ...hospitalIcon, color: colors.redRose, smallIcon: newIcon },
  [s.clinicalStarted]: { ...hospitalIcon, color: colors.grey50, smallIcon: startedIcon },
  [s.clinicalReviewed]: { ...hospitalIcon, color: colors.redRose, smallIcon: doneIcon },
  // [s.adjudicateVerifyNew]: { ...verifyIcon, color: colors.yellow, smallIcon: newIcon },
  [s.verifyNew]: { ...verifyIcon, color: colors.yellow, smallIcon: newIcon },
  // [s.adjudicateVerifyStarted]: { ...verifyIcon, color: colors.grey50, smallIcon: startedIcon },
  [s.verifyStarted]: { ...verifyIcon, color: colors.grey50, smallIcon: startedIcon },
  // [s.adjudicateVerified]: { ...verifyIcon, color: colors.yellow, smallIcon: doneIcon },
  [s.verified]: { ...verifyIcon, color: colors.yellow, smallIcon: doneIcon },
  // [s.adjudicateFlagged]: { icon: flagIcon, color: colors.purple },
  [s.flagged]: { icon: flagIcon, color: colors.purple },
  // [s.adjudicateHold]: { ...holdIcon, color: colors.blue },
  [s.onHold]: { ...holdIcon, color: colors.blue },
  // [s.adjudicateDenied]: { icon: deniedIcon, color: colors.red },
  [s.complete]: { icon: doneIcon, color: colors.blue },
  [s.error]: { icon: infoIcon, color: colors.red },
  [s.dataEntry]: { ...editIcon, color: colors.yellow, smallIcon: startedIcon },
  [s.dataEntryValidation]: { ...editIcon, color: colors.green, smallIcon: newIcon },
  [s.dataEntryError]: { ...editIcon, color: colors.red, smallIcon: infoIcon },
  [s.researchNew]: { ...researchIcon, color: colors.redScarlet, smallIcon: newIcon },
  [s.researchStarted]: { ...researchIcon, color: colors.grey50, smallIcon: startedIcon },
  [s.researched]: { ...researchedIcon, color: colors.redScarlet, smallIcon: doneIcon },
  [s.ineligible]: { ...ineligibleIcon, color: colors.redScarlet },
  [s.ineligibleComplete]: { icon: doneIcon, color: colors.red },
};

const useStyles = makeStyles({
  icon: {
    lineHeight: '20px',
  },
  smallIcon: {
    marginTop: '12px',
    fontSize: '14px',
  },
});

export default function StatusIcons(props) {
  const status = inflateString(props.status);
  const classes = useStyles();
  const statusIcon = icons[status] || {};
  const iconSize = statusIcon.iconSize || '26px';
  const iconMargin = statusIcon.icon === 'attach_money' ? '-10px' : '-3px';
  return (
    <div>
      <i
        className={classNames(classes.icon, 'material-icons')}
        style={{ color: statusIcon.color, fontSize: iconSize }}
      >
        {statusIcon.icon}
      </i>
      {statusIcon.smallIcon && (
        <i
          className={classNames(classes.smallIcon, 'material-icons')}
          style={{
            marginLeft: iconMargin,
            color: statusIcon.color,
          }}
        >
          {statusIcon.smallIcon}
        </i>
      )}
    </div>
  );
}

StatusIcons.propTypes = {
  status: PropTypes.string.isRequired,
};
