import axios from 'axios';
import { Dispatch } from 'react';
import { toRFC3339 } from '../utilities/stringUtilities';
import { getAxiosRequestConfig } from '../utilities/http';
import r, {
  AddUser,
  NextPage,
  PreviousPage,
  RegisterActionTypes,
  SetLoginInfo,
  SetPageCount,
  SetUserInfo,
  VerifyUserCredentials,
  VerifyUserEmail,
} from './types/registerActions';
import { LoginInfo, UserInfo } from './types/register';
import { AddPlanActionTypes } from '../addPlan/types/addPlanActions';
import { resetAddPlan } from '../addPlan/actions';
import { PlanInfo } from '../addPlan/types/addPlanTypes';

export function verifyUserCredentials(planInfo: PlanInfo): VerifyUserCredentials {
  return {
    type: r.VERIFY_USER_CREDENTIALS,
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v3/members/verify-credentials`,
      {
        ...planInfo,
        dateOfBirth: toRFC3339(planInfo.dateOfBirth),
      },
      getAxiosRequestConfig()
    ),
  };
}

export function verifyUserEmail(email: string): VerifyUserEmail {
  return {
    type: r.VERIFY_USER_EMAIL,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v3/members/verify-email/${email}`,
      getAxiosRequestConfig()
    ),
  };
}

export function addUser(planInfo: PlanInfo, userInfo: UserInfo, redirectTo: string): AddUser {
  return {
    type: r.ADD_USER,
    meta: { redirectTo },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v3/members/add`,
      {
        ...planInfo,
        dateOfBirth: toRFC3339(planInfo.dateOfBirth),
        email: userInfo.email,
        password: userInfo.password,
      },
      getAxiosRequestConfig()
    ),
  };
}

export function setUserInfo(key: keyof UserInfo, value: UserInfo[keyof UserInfo]): SetUserInfo {
  return {
    type: r.SET_USER_INFO,
    meta: { key, value },
  };
}

export function setLoginInfo(
  key: keyof LoginInfo,
  value: LoginInfo[keyof LoginInfo]
): SetLoginInfo {
  return {
    type: r.SET_LOGIN_INFO,
    meta: { key, value },
  };
}

// export function setBiometricInfo(
//   key: keyof BiometricsInfo,
//   value: BiometricsInfo[keyof BiometricsInfo]
// ): SetBiometricsInfo {
//   return {
//     type: r.SET_BIOMETRIC_INFO,
//     meta: { key, value },
//   };
// }

export function previousPage(): PreviousPage {
  return {
    type: r.PREVIOUS_PAGE,
  };
}

export function nextPage(): NextPage {
  return {
    type: r.NEXT_PAGE,
  };
}

export function setPageCount(pageCount: number): SetPageCount {
  return {
    type: r.SET_PAGE_COUNT,
    meta: pageCount,
  };
}

export function resetRegisterPages() {
  return (dispatch: Dispatch<RegisterActionTypes | AddPlanActionTypes>): void => {
    dispatch(resetAddPlan());
    dispatch({
      type: r.RESET_REGISTER_PAGES,
    });
  };
}
