import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';

import TextInput from '../../shared/components/TextInput';
import { getCaseSummary, resetSettlements } from '../settlementsActions';
import { SFCase, SettlementsClaimSummary, PlanImpact } from '../types/types';
import { RootState } from '../../shared/types/types';
import { anyMessages } from '../settlementsReducer';

const useStyles = makeStyles({
  newReport: {
    paddingBottom: '16px',
    paddingLeft: '50px',
  },
  refreshIcon: {
    paddingLeft: '3px',
  },
  caseInput: {
    display: 'flex',
    width: '420px',
    paddingLeft: '50px',
  },
  input: {
    maxWidth: '220px',
  },
  button: {
    minWidth: '200px',
    height: '45px',
    marginLeft: '12px',
  },
});

type StateProps = {
  sfCases: SFCase[];
  caseInputError: string;
  claims: SettlementsClaimSummary[];
  planImpact?: PlanImpact;
  messages?: Record<string, string[]>;
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    sfCases: settlements.sfCases,
    claims: settlements.claims,
    caseInputError: settlements.caseInputError,
    planImpact: settlements.planImpact,
    messages: settlements.messages,
  };
};

const mapDispatchToProps = {
  getCaseSummary,
  resetSettlements,
};

type Props = StateProps & typeof mapDispatchToProps;

export function AddCaseForm({
  sfCases,
  caseInputError,
  getCaseSummary,
  resetSettlements,
  planImpact,
  messages,
}: Props): JSX.Element {
  const [caseNumber, setCaseNumber] = useState('');
  const classes = useStyles();

  const isCaseNumberMatching = sfCases.some(sfCase => sfCase.caseNumber === caseNumber);
  const isAddCaseBtnDisabled = caseNumber.length !== 8 || isCaseNumberMatching;
  const errorLabel = isCaseNumberMatching ? 'Cannot select the same case twice' : caseInputError;
  const entireFormDisabled = Boolean(planImpact || (messages && anyMessages(messages)));
  const showRefreshButton: boolean = sfCases.length > 0;

  const handleCaseInput = (e: React.FormEvent<HTMLInputElement>): void => {
    setCaseNumber(e.currentTarget.value);
  };

  const handleSubmitNewCase = (): void => {
    getCaseSummary(caseNumber);
    setCaseNumber('');
  };

  return (
    <>
      {!entireFormDisabled && (
        <form className={classes.caseInput}>
          <TextInput
            className={classes.input}
            fullWidth
            label="Case Number (8 digits)"
            aria-label="Case Number (8 digits)"
            value={caseNumber}
            onChange={handleCaseInput}
            errorLabel={errorLabel}
            maxLength={8}
            type="number"
            autoFocus
          />
          <Button
            className={classes.button}
            variant="outlined"
            onClick={handleSubmitNewCase}
            type="submit"
            disabled={isAddCaseBtnDisabled}
          >
            Add Case
          </Button>
        </form>
      )}
      {showRefreshButton && (
        <div className={classes.newReport}>
          <Button className={classes.button} variant="contained" onClick={resetSettlements}>
            Create New Report <RefreshIcon className={classes.refreshIcon} />
          </Button>
        </div>
      )}
    </>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCaseForm));
