import React, { useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import AutocompleteSelect from '../../shared/components/AutocompleteSelect';
import ProviderOption from './ProviderOption';
import { RootState, InputChangeHandler } from '../../shared/types/types';
import { MediVIProvider, MediVIChartOptions } from '../types/types';
import { getProviderAutocomplete, updateMediVIChartOptions } from '../settlementsActions';

const useStyles = makeStyles({
  container: {
    margin: '8px 0 24px',
    textAlign: 'left',
  },
});

type StateProps = {
  selectedProvider: MediVIProvider;
  providerAutocompleteOptions: MediVIProvider[];
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    selectedProvider: settlements.mediVIChartOptions.selectedProvider,
    providerAutocompleteOptions: settlements.mediVIChartOptions.providerAutocompleteOptions,
  };
};

const mapDispatchToProps = { getProviderAutocomplete, updateMediVIChartOptions };

type DispatchProps = {
  updateMediVIChartOptions: (
    field: keyof MediVIChartOptions,
    value: MediVIProvider | number | boolean
  ) => void;
  getProviderAutocomplete: (searchText: string) => void;
};

type Props = StateProps & DispatchProps;

export function ProviderSelect({
  selectedProvider,
  providerAutocompleteOptions,
  getProviderAutocomplete,
  updateMediVIChartOptions,
}: Props): JSX.Element {
  const [input, setInput] = useState(selectedProvider.name);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(!selectedProvider.name && !selectedProvider.ccn);

  const classes = useStyles();

  if (!isEdit) {
    const handleClick = (): void => setIsEdit(true);
    return (
      <Button onClick={handleClick} className={classes.container}>
        {`${selectedProvider.name} (${selectedProvider.ccn})`}
        <EditIcon />
      </Button>
    );
  }

  const handleChange: InputChangeHandler = e => {
    const { value } = e.target;
    setInput(value);
    if (value.length > 1) {
      getProviderAutocomplete(value);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleSelect = (provider: MediVIProvider): void => {
    if (provider && provider.name && provider.ccn) {
      setInput(provider.name);
      updateMediVIChartOptions('selectedProvider', provider);
      setIsEdit(false);
    }
    setIsOpen(false);
  };

  const handleBlur = (): void => {
    setInput('');
    setIsOpen(false);
  };
  const handleFocus = (): void => {
    if (providerAutocompleteOptions.length > 0) setIsOpen(true);
  };

  return (
    <div className={classes.container}>
      <AutocompleteSelect
        onSelect={handleSelect}
        label="Select Provider"
        RenderComponent={ProviderOption}
        onChange={handleChange}
        inputValue={input}
        options={providerAutocompleteOptions}
        isOpen={isOpen}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSelect);
