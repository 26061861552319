export function validArray(arr) {
  return Array.isArray(arr) ? arr : [];
}

export function isValidFunction(fxn) {
  return typeof fxn === 'function';
}

export function validObject(obj) {
  return isObject(obj) ? obj : {};
}

export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

export function isValidArray(arr) {
  return Array.isArray(arr);
}

export function isArrayFilled(arr) {
  return validArray(arr).length > 0;
}
