import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { colors } from '@apps/shared/src/style';

const useStyles = makeStyles({
  center: {
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '50px',
    '@media print': {
      display: 'flex',
    },
  },
  address: {
    borderLeft: '2px solid #000',
    paddingLeft: '15px',
    fontSize: '10px',
  },
  primary: {
    color: colors.orange,
  },
});

export default function PDFHeader(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.center}>
      <div>
        <img
          alt="6 degrees logo"
          height={90}
          src="https://claims.6degreeshealth.com/images/6degrees_logo_horiz.png"
        />
      </div>
      <div className={classes.address}>
        <div>5800 NE Pinefarm Ct, Ste 200</div>
        <div>Hillsboro, OR 97124</div>
        <div>T 503.640.9933</div>
        <div>F 503.616.7174</div>
        <div className={classes.primary}>6degreeshealth.com</div>
      </div>
    </div>
  );
}
