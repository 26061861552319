import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import { Bar } from 'react-chartjs-2';

import Typography from '@material-ui/core/Typography';

import { RootState } from '../../shared/types/types';
import { ImpactChartData, MediVIChartOptions } from '../types/types';
import { generateSettlementsChartElements } from './chartOptions';
import { getMediVIChart } from '../settlementsActions';

import ExcludedProviders from './ExcludedProviders';

const useStyles = makeStyles({
  header: {
    marginBottom: '1em',
    marginTop: '1em',
  },
  printChart: {
    height: '800px',
    width: '925px',
  },
  regChart: {
    height: '600px',
    width: '1300px',
  },
  medivi: {
    pageBreakBefore: 'always',
  },
});

const mapDispatchToProps = {
  getMediVIChart,
};

type StateProps = {
  chartData?: ImpactChartData;
  showChart: boolean;
  mediVIChartOptions: MediVIChartOptions;
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    chartData: settlements.chartData,
    showChart: settlements.displayReportOptions.showChart,
    mediVIChartOptions: settlements.mediVIChartOptions,
  };
};

type Props = StateProps & typeof mapDispatchToProps;

export function Chart({
  chartData,
  showChart,
  mediVIChartOptions,
  getMediVIChart,
}: Props): JSX.Element | null {
  const {
    selectedProvider,
    providerLimit,
    selectedRadius,
    showInpatient,
    showOutpatient,
    printView,
  } = mediVIChartOptions;
  const classes = useStyles();

  useEffect(() => {
    if (selectedProvider.ccn && selectedRadius && providerLimit) {
      getMediVIChart(selectedProvider.ccn, selectedRadius, providerLimit);
    }
  }, [getMediVIChart, providerLimit, selectedProvider.ccn, selectedRadius]);

  if (!showChart || !chartData) return null;

  const { labels, inpatientRatios, outpatientRatios, excludedProviders } = chartData;

  const { data, options } = generateSettlementsChartElements(
    selectedProvider.name,
    labels,
    inpatientRatios,
    outpatientRatios,
    showInpatient,
    showOutpatient,
    printView
  );

  return (
    <div className={classes.medivi}>
      <Typography variant="h5" className={classes.header}>
        Provider Cost Comparison
      </Typography>
      <div className={printView ? classes.printChart : classes.regChart}>
        <Bar data={data} options={options} redraw />
      </div>
      <ExcludedProviders excludedProviders={excludedProviders} />
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Chart);
