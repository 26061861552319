import { createTheme } from '@material-ui/core';

import { colors, hexToRgba } from '@apps/shared/src/style';

import getFlagRowBGColor from './components/flag/getFlagRowBGColor';

export default createTheme({
  palette: {
    primary: {
      main: colors.blue,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.orange,
      contrastText: colors.white,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiAvatar: {
      root: {
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        opacity: '1 !important',
        maxWidth: '600px',
      },
    },
  },
  headerHeight: {
    full: 70,
    small: 45,
  },
  footerHeight: {
    full: 50,
  },
  contractRoster: {
    table: {
      maxHeight: 400,
      headerRowHeight: 50,
      bodyRowHeight: 80,
    },
  },
  flagTable: {
    table: {
      '& th, td': {
        textAlign: 'center',
        border: 'none',
        padding: '0.5rem',
        '&:first-child': {
          paddingLeft: '0.75rem',
        },
        '&:last-child': {
          paddingRight: '0.75rem',
        },
      },
    },
    tableHead: {
      backgroundColor: ({ color }) => hexToRgba(color, 0.85),
      '& th': {
        color: 'white',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        padding: '0.5em 0.25em',
      },
    },
    actionButtonCol: {
      width: '175px',
    },
    actionButton: {
      width: '160px',
    },
    tableRow: {
      backgroundColor: getFlagRowBGColor,
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
    },
    linkIcon: {
      color: ({ color }) => color,
    },
  },
});
