import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, Divider } from '@material-ui/core';

import VisibilityAdornment from '@apps/shared/src/components/VisibilityAdornment';
import { UserState } from '@apps/shared/src/auth/types';
import { login } from '@apps/shared/src/auth/userActions';
import { LoginArg } from '@apps/shared/src/auth/userActionTypes';
import { setLoginInfo, resetRegisterPages } from '@apps/shared/src/register/actions';
import { LoginInfo, RegisterState } from '@apps/shared/src/register/types/register';

const useStyles = makeStyles({
  validatorForm: {
    width: '100%',
  },
  validator: {
    marginTop: '15px',
    width: '100%',
  },
  divider: {
    margin: '0 40%',
  },
  forgotPasswordContainer: {
    textAlign: 'right',
  },
  forgotPassword: {
    textTransform: 'none',
    padding: '0',
  },
  registerContainer: {
    textAlign: 'center',
  },
  registerButton: {
    margin: '5px 0',
  },
});

type StateProps = {
  loginInfo: RegisterState['loginInfo'];
  isFetching: UserState['isFetching'];
};

type ParentProps = {
  hideRegistration?: boolean;
  application: 'medivi' | 'claims';
};

const mapStateToProps = ({
  register,
  user,
}: {
  register: RegisterState;
  user: UserState;
}): StateProps => ({
  loginInfo: register.loginInfo,
  isFetching: user.isFetching,
});

type DispatchProps = {
  setLoginInfo: typeof setLoginInfo;
  login: (arg: LoginArg) => void;
  resetRegisterPages: () => void;
};

const mapDispatchToProps = {
  setLoginInfo,
  login,
  resetRegisterPages,
};

type Props = ParentProps & StateProps & DispatchProps;

export function LoginForm({
  loginInfo,
  isFetching,
  hideRegistration,
  setLoginInfo,
  login,
  resetRegisterPages,
  application,
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const handleChange =
    (key: keyof LoginInfo) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setLoginInfo(key, e.currentTarget.value);
    };

  const handleSubmit =
    (loginInfo: LoginInfo) =>
    (e: React.FormEvent<Element>): void => {
      e.preventDefault();
      login({ ...loginInfo, application, destinationURL: '/provider-search' });
    };

  const handleRegisterClick: VoidFunction = () => {
    resetRegisterPages();
    history.push('/register');
  };

  const handleForgotPasswordClick: VoidFunction = () => {
    history.push('/password-reset');
  };

  ValidatorForm.addValidationRule('noWhitespacePadding', value => value.trim() === value);

  return (
    <>
      <ValidatorForm onSubmit={handleSubmit(loginInfo)} className={classes.validatorForm}>
        <Grid container direction="column" justify="space-between" spacing={2}>
          <Grid item xs={12}>
            <TextValidator
              name="email"
              id="email"
              type="email"
              className={classes.validator}
              label="Email"
              onChange={handleChange('email')}
              value={loginInfo.email}
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'Must be valid email']}
            />
            <TextValidator
              name="password"
              id="password"
              className={classes.validator}
              label="Password"
              onChange={handleChange('password')}
              value={loginInfo.password}
              validators={['required', 'noWhitespacePadding', 'maxStringLength:128']}
              errorMessages={[
                'This field is required',
                'No leading or trailing whitespace',
                'Password must be less than 128 characters',
              ]}
              inputProps={{ autoCapitalize: 'none' }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                type: showPassword ? 'text' : 'password',
                endAdornment: (
                  <VisibilityAdornment
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.forgotPasswordContainer}>
            <Button
              onClick={handleForgotPasswordClick}
              disabled={isFetching}
              className={classes.forgotPassword}
            >
              Forgot password
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              fullWidth
              type="submit"
              disabled={isFetching}
            >
              Log In
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          {!hideRegistration && (
            <Grid item xs={12} className={classes.registerContainer}>
              <Typography>Don&apos;t have an account?</Typography>
              <Button
                className={classes.registerButton}
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleRegisterClick}
                disabled={isFetching}
              >
                Register here
              </Button>
            </Grid>
          )}
        </Grid>
      </ValidatorForm>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
