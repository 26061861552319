import React from 'react';
import { connect } from 'react-redux';

import ContractMatchTable from './ContractMatchTable';
import flagTypes from '../shared/components/flag/flagTypes';
import Flag from '../shared/components/flag/Flag';
import FlagText from '../shared/components/flag/FlagText';
import DupeTable from '../shared/components/flag/DupeTable';
import { updateClaimContract } from './claimsActions';
import { Alerts, DupeMatch, ContractMatch } from './types/repricingTypes';
import { RootState } from '../shared/types/types';
import { ContractTypes } from '../contracts/types/contracts';

const mapDispatchToProps = {
  updateClaimContract,
};

type DispatchProps = {
  updateClaimContract: (
    claimID: string,
    contractID: number | null,
    providerGroup?: string,
    contractType?: ContractTypes
  ) => void;
};

const mapStateToProps = ({ claims }: RootState): StateProps => {
  return {
    alerts: claims.selectedClaim.alerts,
    dupeMatch: claims.dupeMatch,
    contractMatch: claims.contractMatch,
    claimID: claims.selectedClaim.id,
    contractID: claims.selectedClaim.simpleClaim.contractID,
    status: claims.selectedClaim.status,
  };
};

type StateProps = {
  alerts: Alerts;
  dupeMatch: DupeMatch;
  contractMatch: ContractMatch;
  claimID: string;
  contractID: number | null;
  status: string;
};

type Props = StateProps & DispatchProps;

export function ClaimFlags({
  alerts,
  dupeMatch: { dupeErr, dupes },
  contractMatch: { contractErr, contracts },
  claimID,
  contractID,
  status,
  updateClaimContract,
}: Props): JSX.Element {
  const alertKeys = alerts && Object.keys(alerts);
  const showAlerts = alertKeys && alertKeys.length > 0;
  const showDupes = dupes && dupes.length > 0;
  const showContracts = contracts && contracts.length > 0;

  return (
    <>
      {showAlerts &&
        alertKeys.map(key => {
          const alertHasContent = Array.isArray(alerts[key]) && alerts[key].length > 0;
          return (
            <Flag key={key} type={key}>
              {alertHasContent && <FlagText content={alerts[key]} />}
            </Flag>
          );
        })}
      {dupeErr && (
        <Flag key="dupeErr" type="dupeErr">
          <FlagText content={[[dupeErr]]} />
        </Flag>
      )}
      {showDupes && (
        <Flag key="duplicate" type="duplicate">
          <DupeTable dupes={dupes} color={flagTypes.duplicate.color} />
        </Flag>
      )}
      {contractErr && (
        <Flag key="contractErr" type="contractErr">
          <FlagText content={[[contractErr]]} />
        </Flag>
      )}
      {showContracts && (
        <Flag key="contract" type={contractID ? 'associatedContract' : 'contract'}>
          <ContractMatchTable
            contracts={contracts}
            color={flagTypes.contract.color}
            claimID={claimID}
            contractID={contractID}
            status={status}
            updateClaimContract={updateClaimContract}
          />
        </Flag>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimFlags);
