import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import LinkIcon from '@material-ui/icons/Link';

import { colors } from '@apps/shared/src/style';
import { formatCurrency } from '@apps/shared/src/utilities';
import { RootState, InputChangeHandler } from '../shared/types/types';

import TextInput from '../shared/components/TextInput';
import { SettlementsClaimSummary, SettlementMethodology, PlanImpact } from './types/types';
import { formatDate } from '../shared/globals';
import { updateSettlementAmount } from './settlementsActions';

const claimsTableColumnTitles = [
  'SF Case #',
  'Patient',
  'Date Of Service',
  'Billing Provider',
  'Service Location',
  'Plan Number',
  'Claim Status',
  'URL',
  'Billed Amount',
  'Medicare Allowed',
  'Settlement Amount',
];

const useStyles = makeStyles({
  title: {
    margin: '10px',
  },
  table: {
    overflowY: 'scroll',
    maxHeight: '500px',
  },
  header: {
    backgroundColor: colors.grey07,
  },
  proposed: {
    maxWidth: '110px',
    color: colors.blue,
    marginBottom: '-10px',
    marginTop: '5px !important',
  },
  limitLargeText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
  },
});

type StateProps = {
  claims: SettlementsClaimSummary[];
  settlementMethodology: SettlementMethodology;
  planImpact?: PlanImpact;
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    claims: settlements.claims,
    settlementMethodology: settlements.settlementMethodology,
    planImpact: settlements.planImpact,
  };
};

const mapDispatchToProps = {
  updateSettlementAmount,
};

type Props = StateProps & typeof mapDispatchToProps;

export function ClaimsTable({
  claims,
  updateSettlementAmount,
  settlementMethodology,
  planImpact,
}: Props): JSX.Element | null {
  const classes = useStyles();

  const formsDisabled = Boolean(planImpact);

  if (claims.length === 0) return null;

  const handleSettlementAmountUpdate =
    (claimID: string): InputChangeHandler =>
    (e): void => {
      const newAmount = e.target.value ? parseFloat(e.target.value) : 0;
      updateSettlementAmount(claimID, newAmount);
    };

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        Claims
      </Typography>
      <Card className={classes.table}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow className={classes.header}>
              {claimsTableColumnTitles.map(title => (
                <TableCell key={title} align="center">
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {claims.map(claim => (
              <TableRow key={claim.id}>
                <TableCell>{claim.sfCaseNumber}</TableCell>
                <TableCell className={classes.limitLargeText}>{claim.patientName}</TableCell>
                <TableCell>{formatDate(claim.dateOfService)}</TableCell>
                <TableCell className={classes.limitLargeText}>{claim.billingProvider}</TableCell>
                <TableCell className={classes.limitLargeText}>{claim.serviceLocation}</TableCell>
                <TableCell>{claim.policyNumber}</TableCell>
                <TableCell>{claim.claimStatus}</TableCell>
                <TableCell>
                  <Link to={`/repricing/${claim.id}`}>
                    <LinkIcon />
                  </Link>
                </TableCell>
                <TableCell align="right">{formatCurrency(claim.billedAmount)}</TableCell>
                <TableCell align="right">{formatCurrency(claim.medicareAllowed)}</TableCell>
                <TableCell>
                  <TextInput
                    className={classes.proposed}
                    isMoneyFixed
                    value={claim.proposedSettlementAmount}
                    disabled={settlementMethodology !== 'manual' || formsDisabled}
                    onChange={handleSettlementAmountUpdate(claim.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsTable);
