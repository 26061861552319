import React from 'react';

import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  link: {
    wordBreak: 'break-word',
  },
});

type Props = {
  claimID: string;
  displayText: string;
};

export default function RepricingLink({ claimID, displayText }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Link
      to={`/repricing/${claimID}`}
      target="_blank"
      rel="noopener noreferrer"
      title={displayText}
      className={classes.link}
    >
      {displayText}
    </Link>
  );
}
