import axios from 'axios';
import { required, updateAndValidate, validate, validateAll, isState } from '../shared/validators';
import { UPDATE_VALUE, VALIDATE } from './rootReducer';

export const LOOKUP_SERVICE_LOCATION_PROVIDER = 'LOOKUP_SERVICE_LOCATION_PROVIDER';
export const LOOKUP_SERVICE_LOCATION_PROVIDER_PENDING = `${LOOKUP_SERVICE_LOCATION_PROVIDER}_PENDING`;
export const LOOKUP_SERVICE_LOCATION_PROVIDER_FULFILLED = `${LOOKUP_SERVICE_LOCATION_PROVIDER}_FULFILLED`;
export const LOOKUP_SERVICE_LOCATION_PROVIDER_REJECTED = `${LOOKUP_SERVICE_LOCATION_PROVIDER}_REJECTED`;
export const LOOKUP_SERVICE_LOCATION_PROVIDER_CLEAR = `${LOOKUP_SERVICE_LOCATION_PROVIDER}_CLEAR`;

export const TOGGLE_SERVICE_LOCATION_ADDRESS_IS_EDITABLE = 'TOGGLE_ADDRESS_IS_EDITABLE';
export const TOGGLE_SERVICE_LOCATION_ADDRESS_VERIFIED = 'TOGGLE_ADDRESS_VERIFIED';

export function clearServiceLocationProviderLookup(path) {
  return { type: `${path}/${LOOKUP_SERVICE_LOCATION_PROVIDER_CLEAR}` };
}

export function lookupServiceLocationProvider(path, id) {
  return {
    type: `${path}/${LOOKUP_SERVICE_LOCATION_PROVIDER}`,
    payload: axios.get(`/api/providers/${id}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function updateServiceLocationProviderValue(path, name, value) {
  return { type: `${path}/${UPDATE_VALUE}`, payload: { name, value } };
}

export function updateServiceLocationNPI(value) {
  return dispatch => {
    dispatch(
      updateServiceLocationProviderValue('selectedClaim.claims[0].serviceLocation', 'id', value)
    );
    if (value.length === 10) {
      dispatch(lookupServiceLocationProvider('selectedClaim.claims[0].serviceLocation', value));
    }
    if (value.length === 0) {
      dispatch(clearServiceLocationProviderLookup('selectedClaim.claims[0].serviceLocation'));
    }
  };
}

export function toggleServiceLocationAddressIsEditable(path) {
  return { type: `${path}/${TOGGLE_SERVICE_LOCATION_ADDRESS_IS_EDITABLE}` };
}

export function toggleServiceLocationAddressVerified(path) {
  return { type: `${path}/${TOGGLE_SERVICE_LOCATION_ADDRESS_VERIFIED}` };
}

const errorTextGetter = {
  zip: reduxState => getZipErrMsg(reduxState),
  id: reduxState => getIDErrMsg(reduxState),
  ein: reduxState => getEINErrMsg(reduxState),
  state: reduxState => getStateErrMsg(reduxState),
};

function getZipErrMsg({ zip, npiAddress, addressVerified }) {
  if (required(zip) || zip.length !== 5) return '5-digit zip';
  if (!addressVerified && npiAddress && zip !== npiAddress.zip)
    return `Mismatch NPI zip: ${npiAddress ? npiAddress.zip : ''}. Verify!`;
  return '';
}

function getIDErrMsg({ id }) {
  return required(id) || !/^\d{10}?$/.test(id) ? '10-digit NPI' : '';
}

function getEINErrMsg({ ein }) {
  if (ein) return !/^\d{9}?$/.test(ein) ? '9-digit EIN' : '';
  return '';
}

function getStateErrMsg({ state }) {
  if (required(state) || state.length !== 2) return '2-letter code';
  if (!isState(state)) return 'Invalid state code';
  return '';
}

export const newServiceLocationProvider = () => {
  return {
    lastNameOrOrg: '',
    id: '',
    ein: '',
    city: '',
    state: '',
    zip: '',
    npiAddress: null,
    addressVerified: false,
    addressIsEditable: false,
  };
};

const serviceLocationReducer = (state = newServiceLocationProvider(), action) => {
  switch (action.type) {
    case VALIDATE:
      return validateAll(state, errorTextGetter);

    case LOOKUP_SERVICE_LOCATION_PROVIDER_PENDING:
      return { ...state, npiAddress: null };

    case LOOKUP_SERVICE_LOCATION_PROVIDER_FULFILLED: {
      const newState = {
        ...state,
        npiAddress: action.payload.data,
        ...action.payload.data,
        // zip: state.zip,
        changed: { ...state.changed, zip: true },
      };
      return validateAll(newState, errorTextGetter);
    }

    case LOOKUP_SERVICE_LOCATION_PROVIDER_REJECTED:
      return {
        ...state,
        errors: {
          ...state.errors,
          id: action.payload.response.data ? action.payload.response.data : 'Unknown error',
        },
      };

    case LOOKUP_SERVICE_LOCATION_PROVIDER_CLEAR:
      return { ...newServiceLocationProvider(), npiAddress: null };

    case UPDATE_VALUE: {
      const uState = updateAndValidate(
        state,
        action.payload.name,
        action.payload.value,
        errorTextGetter[action.payload.name],
        true
      );
      if (uState.errors.zip && uState.errors.zip.startsWith('Mismatch NPI'))
        uState.addressIsEditable = true;
      return uState;
    }

    case TOGGLE_SERVICE_LOCATION_ADDRESS_IS_EDITABLE:
      return { ...state, addressIsEditable: !state.addressIsEditable };

    case TOGGLE_SERVICE_LOCATION_ADDRESS_VERIFIED: {
      const aState = { ...state, addressVerified: !state.addressVerified };
      return {
        ...aState,
        errors: { ...aState.errors, zip: validate(errorTextGetter.zip, aState) },
      };
    }

    default:
      return state;
  }
};

export default serviceLocationReducer;
