import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import { getInitials } from '@apps/shared/src/utilities';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';

import styledAvatar from '../styledAvatar';
import * as types from '../../types/propTypes';

const Avatar = styledAvatar(30, colors.blue);

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 1rem',
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  },
  downIcon: {
    height: '0.75em',
  },
  avatar: {
    marginRight: '0.25rem',
  },
  popover: {
    marginTop: '1em',
  },
}));

export function getUserButtonInfo(currentUser) {
  const validUserString = inflateString(currentUser && currentUser.fullName);
  return {
    initials: getInitials(validUserString) || 'Me',
    userName: validUserString || 'Profile',
  };
}

export default function UserButton({ currentUser }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const { initials, userName } = getUserButtonInfo(currentUser);
  const [userAnchor, setUserAnchor] = useState(null);

  const handleUserMenuToggle = e => setUserAnchor(userAnchor ? null : e.currentTarget);

  return (
    <>
      <Button onClick={handleUserMenuToggle} className={classes.root}>
        <Avatar className={classes.avatar}>{initials}</Avatar>
        {!isSmall && <Typography variant="body2">{userName}</Typography>}
        <DownIcon className={classes.downIcon} />
      </Button>
      <Popover
        className={classes.popover}
        open={Boolean(userAnchor)}
        onClose={handleUserMenuToggle}
        anchorEl={userAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List component="nav">
          <Link to="/change-password">
            <ListItem button>
              <ListItemText>Change Password</ListItemText>
            </ListItem>
          </Link>
        </List>
      </Popover>
    </>
  );
}

UserButton.defaultProps = {
  currentUser: undefined,
};

UserButton.propTypes = {
  currentUser: types.simpleUser,
};
