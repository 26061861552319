import Monat, { MDY, YMD } from '@apps/shared/src/utilities/monat';

const stateCodes = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const isState = text => {
  return stateCodes.includes(text);
};

export const checkUnits = units => {
  const nonNumeric = Number.isNaN(parseFloat(units));
  return nonNumeric || units < 1 ? 'Must be >= 1' : '';
};

export const required = text => {
  return text === '' ? 'Required' : '';
};

export const validate = (errorTextFunc, state) => {
  return errorTextFunc !== undefined ? errorTextFunc(state) : '';
};

// return array of [isValid (bool), parsedDate (string), error (string)]
export const checkDate = (input, required) => {
  let isValid = false;
  let error = '';
  let validDate;

  if (input === '') return required ? [false, input, 'Required'] : [true, input, error];

  const monat = new Monat(MDY, YMD);
  const validDates = monat.parseDelimited(input).filter(date => date.isValidComplete());

  switch (validDates.length) {
    case 1: {
      validDate = validDates[0];
      isValid = true;
      break;
    }
    case 2: {
      error = 'Ambiguous Date';
      break;
    }
    default: {
      error = 'Invalid Date';
    }
  }
  const formattedDate =
    validDate && validDate.format.name !== 'mm/dd/yyyy' ? validDate.toFormat(MDY) : validDate;
  const parsedDate = formattedDate ? formattedDate.toString() : '';

  return [isValid, parsedDate, error];
};

export const isBeforeNow = date => {
  // Not set is ok
  if (date === undefined || date === '') return '';

  // Ensure to date is before now.
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  today = `${mm}/${dd}/${yyyy}`;
  return datesInOrder(date, today) ? '' : 'Before today';
};

export const datesInOrder = (d1, d2) => {
  return new Date(d1) <= new Date(d2);
};

export const checkPlaceOfSvc = placeOfSvc => {
  const zeroOrTwoDigits = placeOfSvc.length === 0 || placeOfSvc.length === 2;
  return zeroOrTwoDigits ? '' : 'Must be 2-digits';
};

export const checkDiagCodePtr = ptr => {
  // ptrRegEx matches integers separated by whitespace, commas, or colons
  const ptrRegEx = /^(?:\d+(?:[\s,:]+|$))+$/i;
  const isValidPtr = ptrRegEx.test(ptr);
  return isValidPtr ? '' : 'Valid pointer';
};

export const validateAll = (state, errorTextGetter) => {
  const newState = { ...state, errors: { ...state.errors }, changed: { ...state.changed } };
  Object.keys(newState).forEach(prop => {
    if (prop !== 'errors' && prop !== 'changed' && errorTextGetter !== undefined) {
      newState.errors[prop] = validate(errorTextGetter[prop], newState);
      newState.changed[prop] = true;
    }
  });
  return newState;
};

export function updateAndValidate(state, name, value, errorTextFunc, changed) {
  const newState = { ...state, [name]: value };
  return {
    ...newState,
    errors: { ...newState.errors, [name]: validate(errorTextFunc, newState) },
    changed: { ...newState.changed, [name]: changed },
  };
}

export function getZipErrMsg(state) {
  const { zip, firstName, lastNameOrOrg, id, dateOfBirth } = state;
  const anyFieldFilled = firstName || lastNameOrOrg || id || dateOfBirth;

  if (!anyFieldFilled) return '';
  if (required(zip)) return 'Required';
  if (zip.length !== 5) return '5-digit zip';

  return '';
}

export const getErrorText = (state, name) => {
  return state.changed && state.changed[name] && state.errors && state.errors[name] !== ''
    ? state.errors[name]
    : '';
};

export const checkTypeOfBill = text => {
  if (text.length !== 3) {
    return '3-digit Bill Type';
  }
  return '';
};
