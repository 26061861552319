import { inflateNumber } from '../inflators';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export function formatCurrency(amount: number | string = 0): string {
  return currencyFormatter.format(inflateNumber(amount));
}
