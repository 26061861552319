import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';

import { colors } from '@apps/shared/src/style';
import { formatCurrency } from '@apps/shared/src/utilities';

import { SFCase, SettlementsClaimSummary } from '../types/types';
import { pluralize } from '../../contracts/contractUtilities';
import { sumClaimsField } from '../impactClaims/ImpactClaimsTotalsRow';

const useStyles = makeStyles({
  caseCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '300px',
  },
  deleteButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  header: {
    display: 'flex',
    color: colors.orange,
  },
  row: {
    display: 'flex',
    fontSize: '14px',
    padding: '2px',
  },
  field: {
    minWidth: '190px',
  },
  divider: {
    margin: '10px 30px',
  },
  monetaryValues: {
    width: '200px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export function getProviderNames(claims: SettlementsClaimSummary[]): string[] {
  return Array.from(new Set(claims.map(claim => claim.billingProvider)));
}

export function getProposedSettlementTotal(claims: SettlementsClaimSummary[]): number {
  return sumClaimsField(claims, 'proposedSettlementAmount');
}

export function getProposedAdditionalPayment(
  proposedSettlementTotal: number,
  repriceTotal: number
): number {
  const additionalPayment = proposedSettlementTotal - repriceTotal;
  return additionalPayment > 0 ? additionalPayment : 0;
}

type ParentProps = {
  sfCase: SFCase;
  claims: SettlementsClaimSummary[];
  removeCase: (sfCaseNumber: string) => void;
  isDeleteDisabled: boolean;
};

type Props = ParentProps;

export function Case({ sfCase, claims, removeCase, isDeleteDisabled }: Props): JSX.Element {
  const classes = useStyles();

  const providerNames = getProviderNames(claims);
  const proposedSettlementTotal = getProposedSettlementTotal(claims);
  const proposedAdditionalPayment = getProposedAdditionalPayment(
    proposedSettlementTotal,
    sfCase.repriceTotal
  );

  const handleClick = (): void => {
    removeCase(sfCase.caseNumber);
  };

  const caseContents = [
    { title: 'Plan Name', value: sfCase.planName },
    { title: 'Plan ID', value: sfCase.groupNumber },
    { title: 'TPA', value: sfCase.tpa },
    { title: 'Case Type', value: sfCase.typeOfCase },
    { title: 'Status', value: sfCase.status },
    {
      title: `Billing ${pluralize('Provider', providerNames.length)}`,
      value: providerNames.join(', '),
    },
  ];

  const caseTotals = [
    { title: 'Total Billed', value: formatCurrency(sfCase.billedAmountTotal) },
    { title: 'Reprice Total', value: formatCurrency(sfCase.repriceTotal) },
    { title: 'Balance Bill Total', value: formatCurrency(sfCase.balanceBillTotal) },
    { title: 'Proposed Additional Payment', value: formatCurrency(proposedAdditionalPayment) },
    { title: 'Proposed Settlement Total', value: formatCurrency(proposedSettlementTotal) },
  ];

  return (
    <div className={classes.caseCard}>
      <div>
        {!isDeleteDisabled && (
          <IconButton
            className={classes.deleteButton}
            onClick={handleClick}
            data-testid={`deleteBtn-${sfCase.caseNumber}`}
          >
            <DeleteIcon />
          </IconButton>
        )}
        <Typography variant="h6" className={classes.header}>
          <span className={classes.field}>Case Number:</span>
          <span>{sfCase.caseNumber}</span>
        </Typography>

        {caseContents.map(c => (
          <div key={c.title} className={classes.row}>
            <div className={classes.field}>{c.title}:</div>
            <div>{c.value}</div>
          </div>
        ))}
      </div>
      <div>
        <Divider className={classes.divider} />
        {caseTotals.map(c => (
          <div key={c.title} className={classes.row}>
            <div className={classes.field}>{c.title}:</div>
            <div className={classes.monetaryValues}>{c.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
