import React from 'react';
import { connect } from 'react-redux';

import ProviderIcon from '@material-ui/icons/LocalHospital';

import RosterForm from './RosterForm';
import AddRoster from './AddRoster';
import RosterChip from '../chips/RosterChip';
import ChipBox from '../chips/ChipBox';
import { checkIsSCAorLOA } from '../contractUtilities';

import {
  getRoster,
  removeRoster,
  createNewRoster,
  getOrCreateManualRoster,
} from '../contractsActions';
import { ContractDetail, ContractProvider } from '../types/contracts';
import { RootState } from '../../shared/types/types';
import SCAProvider from './SCAProvider';

type ParentProps = {
  isEdit: boolean;
};
type StateProps = {
  selectedContract: ContractDetail;
  isRosterOpen: boolean;
  isRosterLoading: boolean;
  selectedProvider: ContractProvider;
};
type DispatchProps = {
  getRoster: (rosterID: number) => void;
  removeRoster: (rosterID: number) => void;
  getOrCreateManualRoster: VoidFunction;
};
type Props = ParentProps & StateProps & DispatchProps;

export const ContractProviders = ({
  isEdit,
  selectedContract,
  isRosterOpen,
  isRosterLoading,
  getRoster,
  removeRoster,
  getOrCreateManualRoster,
}: Props): JSX.Element => {
  const rosters = selectedContract ? selectedContract.rosters : [];
  const rosterBundle = {
    items: rosters,
    handleRemoveItem: removeRoster,
    handleClickItem: getRoster,
    ItemChip: RosterChip,
  };

  const isAddButtonDisabled = isRosterOpen || isRosterLoading;

  if (checkIsSCAorLOA(selectedContract.contractType)) return <SCAProvider isEdit={isEdit} />;

  return (
    <ChipBox
      isEdit={isEdit}
      title="Provider Rosters"
      Icon={ProviderIcon}
      chipBundles={[rosterBundle]}
      gridArea="providers"
      defaultMessage={`\u274c - NO PROVIDERS SPECIFIED`}
    >
      {isEdit && (
        <AddRoster
          isRosterLoading={isRosterLoading}
          isAddButtonDisabled={isAddButtonDisabled}
          handleAddManualRoster={getOrCreateManualRoster}
        />
      )}
      <RosterForm isEdit={isEdit} />
    </ChipBox>
  );
};

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    selectedContract: contracts.selectedContract,
    isRosterOpen: contracts.isRosterOpen,
    isRosterLoading: contracts.isRosterLoading,
    selectedProvider: contracts.selectedProvider,
  };
};

const mapDispatchToProps = {
  getRoster,
  removeRoster,
  createNewRoster,
  getOrCreateManualRoster,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractProviders);
