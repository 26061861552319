import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { colors } from '@apps/shared/src/style';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { addButtonStyle, deleteButtonStyle } from './sharedConfig';
import * as types from '../../shared/types/propTypes';
import {
  defaultSFTPServer,
  default834Config,
  defaultPayerOverride,
  defaultPatientCSVConfig,
} from './defaultTypes';

const useStyles = makeStyles({
  configLayout: {
    display: 'flex',
  },
  configName: {
    margin: '5px 0px 5px 0px',
  },
  marginButton: {
    margin: '5px 10px 5px 10px',
  },
  addButton: { ...addButtonStyle, marginLeft: '10px' },
  deleteButton: deleteButtonStyle,
  saveButton: {
    marginLeft: '10px',
    marginRight: '10px',
    backgroundColor: `${colors.blue}`,
    '&:hover': {
      backgroundColor: `${colors.blueDark}`,
    },
  },
  claimInfoCell: {
    margin: '10px',
  },
});

export default function ConfigHeader({
  selectedConfig,
  isReadOnly,
  toggleEditMode,
  handleSave,
  handleSetObject,
  handleDeleteConfig,
}) {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const classes = useStyles();
  const deleteConfig = () => {
    handleDeleteConfig(selectedConfig.id);
    setShowConfirm(false);
  };
  if (!selectedConfig) {
    return null;
  }
  const handleDelete = () => {
    setShowConfirm(true);
  };
  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <Dialog open={showConfirm} onClose={handleCloseConfirm}>
        <DialogTitle>Delete Config</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this Config?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>No</Button>
          <Button onClick={deleteConfig}>Yes</Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'flex', gap: '.5rem' }}>
        <h1 className={classes.configName}>Config Name: {selectedConfig.name}</h1>
        {!isReadOnly && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.deleteButton}
            onClick={handleDelete}
          >
            <DeleteForeverIcon /> &nbsp; Delete
          </Button>
        )}
      </div>
      <Button
        variant="contained"
        color="secondary"
        className={classes.marginButton}
        onClick={toggleEditMode}
      >
        {isReadOnly ? 'Edit' : 'Cancel'}
      </Button>
      {!isReadOnly && (
        <>
          <Button
            variant="contained"
            color="secondary"
            className={classes.saveButton}
            onClick={() => handleSave(selectedConfig)}
          >
            <SaveAltIcon /> &nbsp; Save Updates
          </Button>
          {!selectedConfig.patient834Config && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              onClick={handleSetObject('patient834Config', default834Config())}
            >
              <CreateNewFolderIcon /> &nbsp; Patient 834 Config
            </Button>
          )}
          {!selectedConfig.patientCSVConfig && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              onClick={handleSetObject('patientCSVConfig', defaultPatientCSVConfig())}
            >
              <CreateNewFolderIcon /> &nbsp; Patient CSV Config
            </Button>
          )}
          {!selectedConfig.payerOverrides && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              onClick={handleSetObject('payerOverrides', [defaultPayerOverride()])}
            >
              <CreateNewFolderIcon /> &nbsp; Payer Override Config
            </Button>
          )}
          {!selectedConfig.repriceCodeMappings && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              onClick={handleSetObject('repriceCodeMappings', {})}
            >
              <CreateNewFolderIcon /> &nbsp; Reprice Code Mapping Config
            </Button>
          )}
          {!selectedConfig.sftpServers && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              onClick={handleSetObject('sftpServers', [defaultSFTPServer()])}
            >
              <CreateNewFolderIcon /> &nbsp; SFTP Server Config
            </Button>
          )}
        </>
      )}
    </>
  );
}

ConfigHeader.defaultProps = {
  selectedConfig: null,
};

ConfigHeader.propTypes = {
  selectedConfig: types.clientConfig,
  isReadOnly: PropTypes.bool.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSetObject: PropTypes.func.isRequired,
  handleDeleteConfig: PropTypes.func,
};
