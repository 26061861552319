import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { colors } from '@apps/shared/src/style';
import { formatCurrency } from '@apps/shared/src/utilities';

import { PlanImpact, ImpactValues, ImpactRow } from './types/types';
import { formatFloat } from '../shared/globals';
import { RootState } from '../shared/types/types';

function calculateDisplayNumbers(planImpact: PlanImpact): ImpactValues {
  const {
    currentFacility,
    currentProfessional,
    proposedProfessionalSettlement,
    proposedFacilitySettlement,
    finalFacility,
    finalProfessional,
  } = planImpact;

  const showFacility = proposedFacilitySettlement.additionalPayment > 0;
  const showProfessional = proposedProfessionalSettlement.additionalPayment > 0;

  const currentRow: ImpactRow = {
    title: 'Current Plan Performance',
    facilityPaid: formatCurrency(currentFacility.proposedSettlementAmount),
    facilityMultiple: formatFloat(currentFacility.medicareMultiple),
    isFacilityOverTarget: currentFacility.overTarget,
    professionalPaid: formatCurrency(currentProfessional.proposedSettlementAmount),
    professionalMultiple: formatFloat(currentProfessional.medicareMultiple),
    isProfessionalOverTarget: currentProfessional.overTarget,
  };

  const conditionalFacPaid = showFacility
    ? `${formatCurrency(proposedFacilitySettlement.additionalPayment)} additional`
    : '';
  const conditionalFacMultiplier = showFacility
    ? `+ ${formatFloat(proposedFacilitySettlement.medicareMultiple, 3)}
    adjustment`
    : '';

  const conditionalProfPaid = showProfessional
    ? `${formatCurrency(proposedProfessionalSettlement.additionalPayment)} additional`
    : '';

  const conditionalProfMultiplier = showProfessional
    ? `+ ${formatFloat(proposedProfessionalSettlement.medicareMultiple, 3)} adjustment`
    : '';

  const proposedRow: ImpactRow = {
    title: 'Proposed Settlement',
    facilityPaid: conditionalFacPaid,
    facilityMultiple: conditionalFacMultiplier,
    isFacilityOverTarget: proposedFacilitySettlement.overTarget,
    professionalPaid: conditionalProfPaid,
    professionalMultiple: conditionalProfMultiplier,
    isProfessionalOverTarget: proposedProfessionalSettlement.overTarget,
  };

  const newRow: ImpactRow = {
    title: 'Final Plan Performance',
    facilityPaid: formatCurrency(finalFacility.proposedSettlementAmount),
    facilityMultiple: formatFloat(finalFacility.medicareMultiple),
    isFacilityOverTarget: finalFacility.overTarget,
    professionalPaid: formatCurrency(finalProfessional.proposedSettlementAmount),
    professionalMultiple: formatFloat(finalProfessional.medicareMultiple),
    isProfessionalOverTarget: finalProfessional.overTarget,
  };

  const impactValues: ImpactValues = {
    current: currentRow,
    proposed: proposedRow,
    new: newRow,
  };

  return impactValues;
}

export function oneYearInterval(): string {
  const date = new Date();
  const endDate = date.toDateString();
  const endDateMinusDayOfWeek = endDate.substr(4);
  date.setMonth(date.getMonth() - 12);
  const startDate = date.toDateString();
  const startDateMinusDayOfWeek = startDate.substr(4);

  return `${startDateMinusDayOfWeek} - ${endDateMinusDayOfWeek}`;
}

const planImpactColumnTitles = [
  'Total Paid',
  'Medicare Multiple',
  'Total Paid',
  'Medicare Multiple',
];

const useStyles = makeStyles({
  root: {
    '@media print': {
      paddingTop: '30px',
    },
  },
  card: {
    marginBottom: '1em',
    width: 'fit-content',
  },
  header1: {
    backgroundColor: colors.grey07,
  },
  header2: {
    backgroundColor: colors.grey05,
  },
  timeFrame: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.85em',
    paddingBottom: '5px',
  },
  icon: {
    fontSize: '1.2em',
    paddingRight: '5px',
  },
  noTarget: {},
  overTarget: {
    color: colors.red,
  },
  withinTarget: {
    color: colors.green,
  },
});

type StateProps = {
  planImpact?: PlanImpact;
};
const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    planImpact: settlements.planImpact,
  };
};

type Props = StateProps;

export function PlanImpactSummary({ planImpact }: Props): JSX.Element | null {
  const classes = useStyles();

  if (!planImpact) {
    return null;
  }

  const impactValues = calculateDisplayNumbers(planImpact);

  const getTargetClass = (isOverTarget: boolean | undefined): keyof typeof classes => {
    if (isOverTarget === undefined) {
      return 'noTarget';
    }
    return isOverTarget ? 'overTarget' : 'withinTarget';
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5">Plan Impact</Typography>
      <Typography className={classes.timeFrame}>
        <DateRangeIcon className={classes.icon} /> {oneYearInterval()}
      </Typography>
      <Card className={classes.card}>
        <Table>
          <TableHead>
            <TableRow className={classes.header1}>
              <TableCell />
              <TableCell align="center" colSpan={2}>
                FACILITY
              </TableCell>
              <TableCell align="center" colSpan={2}>
                PROFESSIONAL
              </TableCell>
            </TableRow>
            <TableRow className={classes.header2}>
              <TableCell />
              {planImpactColumnTitles.map((t, i) => (
                // eslint-disable-next-line
                <TableCell key={`${t}${i}`} align="center">
                  {t}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[impactValues.current, impactValues.proposed, impactValues.new].map(row => {
              const facilityClass = getTargetClass(row.isFacilityOverTarget);
              const professionalClass = getTargetClass(row.isProfessionalOverTarget);
              return (
                <TableRow key={row.title}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="right">{row.facilityPaid}</TableCell>
                  <TableCell align="center" className={classes[facilityClass]}>
                    {row.facilityMultiple}
                  </TableCell>
                  <TableCell align="right">{row.professionalPaid}</TableCell>
                  <TableCell align="center" className={classes[professionalClass]}>
                    {row.professionalMultiple}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default connect(mapStateToProps)(PlanImpactSummary);
