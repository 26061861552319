import Logout from '@apps/shared/src/auth/Logout';
import PasswordReset from '@apps/shared/src/auth/PasswordReset';
import PasswordResetSuccess from '@apps/shared/src/auth/PasswordResetSuccess';
import SessionTimeoutManager from '@apps/shared/src/auth/AppSessionManager';
import PrivateRoute from '@apps/shared/src/auth/PrivateRoute';
import { getAuth } from '@apps/shared/src/auth/userActions';
import VerifyEmail from '@apps/shared/src/auth/VerifyEmail';
import VerifyResetPassword from '@apps/shared/src/auth/VerifyResetPassword';
import WithRedirect from '@apps/shared/src/auth/WithRedirect';
import MFAPopup from '@apps/shared/src/MFA/MFA';
import AccountDisabled from '@apps/shared/src/auth/AccountDisabled';
import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ChangePassword from './auth/ChangePassword';
import CreateNewPassword from './auth/CreateNewPassword';
import CreateProfile from './auth/CreateProfile';
import Login from './auth/Login';
import Users from './auth/Users';
import Welcome from './auth/Welcome';
import Contracts from './contracts/Contracts';
import EDI from './edi/EDI';
import PasswordNotifyModal from './auth/PasswordExpireModal';
import eventSource from './eventSourceListener';
import NewClaims from './manualClaim/NewClaims';
import Reports from './reports/Reports';
import Repricing from './repricing/Repricing';
import CaseMiniView from './settlements/CaseMiniView';
import SnackbarManager from './shared/components/snackbar/SnackbarManager';
import theme from './shared/theme';

const mapStateToProps = state => {
  return {
    isPasswordExpired: state.user.currentUser?.isPasswordExpired || false,
    isMFANeeded: state.user.currentUser?.isMFANeeded || false,
    isAccountDeactivated: state.user.currentUser?.isAccountDeactivated || false,
  };
};

export class App extends React.Component {
  UNSAFE_componentWillMount() {
    let csrfToken;
    const search = queryString.parse(window.location.search);
    if (search.csrfToken) {
      csrfToken = decodeURIComponent(search.csrfToken);
      localStorage.setItem('csrfToken', csrfToken);
    }
    this.props.dispatch(getAuth(csrfToken)).then(() => eventSource(this.props.dispatch));
  }

  render() {
    if (this.props.isPasswordExpired) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <WithRedirect>
              <PasswordNotifyModal />
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <PrivateRoute path="/change-password" component={ChangePassword} />
              </Switch>
            </WithRedirect>
          </BrowserRouter>
          <SnackbarManager />
        </ThemeProvider>
      );
    }

    if (this.props.isMFANeeded) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <WithRedirect>
              <MFAPopup isMFANeeded={this.props.isMFANeeded} />
              <SessionTimeoutManager />
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
              </Switch>
            </WithRedirect>
          </BrowserRouter>
          <SnackbarManager />
        </ThemeProvider>
      );
    }

    if (this.props.isAccountDeactivated) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <WithRedirect>
              <AccountDisabled isAccountDisabled={this.props.isAccountDeactivated} />
              <SessionTimeoutManager />
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
              </Switch>
            </WithRedirect>
          </BrowserRouter>
          <SnackbarManager />
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <WithRedirect>
            <SessionTimeoutManager />
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Route path="/password-reset" component={PasswordReset} />
              <Route path="/password-reset-success" component={PasswordResetSuccess} />
              <Route path="/verify-reset-password/:code?" component={VerifyResetPassword} />
              <Route path="/update-password" component={CreateNewPassword} />
              <Route path="/verify-email/:code?" component={VerifyEmail} />
              <Route path="/create-profile" component={CreateProfile} />
              <PrivateRoute path="/admin" requiredRole="claims-admin" component={Users} />
              <PrivateRoute path="/change-password" component={ChangePassword} />

              <PrivateRoute path="/repricing/:id" component={Repricing} />
              {/* <PrivateRoute path="/adjudication/:id" component={Repricing} />
              <PrivateRoute path="/adjudication" component={Repricing} /> */}
              <PrivateRoute path="/repricing" component={Repricing} />
              {/* the static keys below are used to force remount of NewClaims component on route change */}
              <PrivateRoute path="/new-claim" component={NewClaims} key="new" />
              <PrivateRoute path="/edit-claim/:id" component={NewClaims} key="edit" />
              <PrivateRoute path="/validate-claim/:id" component={NewClaims} key="validate" />
              <PrivateRoute path="/reports" component={Reports} />
              <PrivateRoute path="/welcome" component={Welcome} />
              <PrivateRoute
                path="/contracts/:id"
                // removing these for now to only gate routes by 'claims-read' role except /admin
                // requiredRoles={['claims-contract', 'claims-contractadmin']}
                component={Contracts}
              />
              <PrivateRoute
                path="/contracts"
                // requiredRoles={['claims-contract', 'claims-contractadmin']}
                component={Contracts}
              />
              <PrivateRoute path="/edi" component={EDI} />
              <PrivateRoute path="/case-mini-view/:caseNumber" component={CaseMiniView} />
              <Redirect to="/repricing" />
            </Switch>
          </WithRedirect>
        </BrowserRouter>
        <SnackbarManager />
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isPasswordExpired: PropTypes.bool.isRequired,
  isMFANeeded: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(App);
