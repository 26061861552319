export function isValidDate(date: unknown): boolean {
  return date instanceof Date && !Number.isNaN(date.getTime());
}

export function parseDate(dateStr: unknown): Date | null {
  if (typeof dateStr !== 'string') return null;

  const date = new Date(dateStr);
  if (isValidDate(date)) return date;
  return null;
}
