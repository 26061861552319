import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List as VirtualList } from 'react-virtualized';
import { withStyles } from '@material-ui/core';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import ClaimListItem from './ClaimListItem';
import NoResults from '../shared/components/NoResults';
import Search from '../repricing/Search';
import * as types from '../shared/types/propTypes';
import { validArray } from '../shared/typeChecks';
import Pagination from './Pagination';

const styles = {
  claimList: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '300px',
  },
  flexContainer: {
    flex: '1 1 auto',
    padding: '0, 1rem',
  },
  virtualList: {
    outline: 'none',
  },
};

export class ClaimList extends Component {
  componentDidMount() {
    document.body.addEventListener('keydown', this.handleKeyDown, true);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.handleKeyDown, true);
  }

  handleKeyDown = e => {
    if (!e || !e.target || !e.keyCode) return;
    if (
      (e.target.tagName === 'BODY' ||
        e.target.id === 'claim-list' ||
        (typeof e.target.className === 'string' &&
          e.target.className.includes('claim-list-item'))) &&
      (e.keyCode === 38 || e.keyCode === 40)
    ) {
      e.preventDefault();
      e.stopPropagation();
      switch (e.keyCode) {
        case 38: {
          // 'ArrowUp'
          if (!this.props.isFirstClaim && typeof this.props.handleNavigatePrev === 'function') {
            this.props.handleNavigatePrev();
          }
          break;
        }
        case 40: {
          // 'ArrowDown'
          if (!this.props.isLastClaim && typeof this.props.handleNavigateNext === 'function') {
            this.props.handleNavigateNext();
          }
          break;
        }
        default:
          break;
      }
    }
  };

  renderRow = ({ index, key, style }) => {
    const {
      claimList,
      markedForRemoval,
      animateMarked,
      handleClaimListItemKeyPress,
      handleClaimListItemSelect,
      selectedID,
    } = this.props;
    if (!claimList || !claimList[index]) return null;
    const claim = claimList[index];
    const isSelected = claim && claim.id === selectedID;
    const animateOut =
      animateMarked &&
      Array.isArray(markedForRemoval) &&
      markedForRemoval.includes(claim.id) &&
      !isSelected;

    return (
      <ClaimListItem
        key={key}
        style={style}
        isSelected={isSelected}
        animateOut={animateOut}
        claim={claim}
        handleKeyPress={handleClaimListItemKeyPress(claim.id)}
        handleSelect={handleClaimListItemSelect(claim.id)}
      />
    );
  };

  render() {
    const {
      loading,
      allowSearch,
      filterGroupName,
      filterGroup,
      classes,
      currentPage,
      pageSize,
      onPageChange,
      onPageSizeChange,
    } = this.props;
    const claimList = validArray(this.props.claimList);
    return (
      <div className={classes.claimList}>
        <Search
          filterGroup={filterGroup}
          filterGroupName={filterGroupName}
          allowSearch={allowSearch}
          currentPage={currentPage}
          pageSize={pageSize}
        />
        <LoadingSpinner isLoading={loading} />
        <NoResults results={claimList} description="claims" loadingStatus={loading} />
        <div className={classes.flexContainer}>
          <AutoSizer>
            {({ width, height }) => (
              <>
                <VirtualList
                  id="claim-list"
                  style={{ outline: 'none' }}
                  className={classes.virtualList}
                  width={width}
                  height={height - 50}
                  rowHeight={56}
                  rowRenderer={this.renderRow}
                  rowCount={claimList.length}
                />
                <Pagination
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalItems={claimList.length}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  totalPages={this.props.totalPages}
                />
              </>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

ClaimList.defaultProps = {
  isFirstClaim: false,
  isLastClaim: false,
  markedForRemoval: [],
  animateMarked: false,
  handleNavigatePrev: null,
  handleNavigateNext: null,
  selectedID: '',
  filterGroup: [],
  filterGroupName: '',
};

ClaimList.propTypes = {
  isFirstClaim: PropTypes.bool,
  isLastClaim: PropTypes.bool,
  markedForRemoval: PropTypes.arrayOf(PropTypes.string),
  animateMarked: PropTypes.bool,
  handleNavigatePrev: PropTypes.func,
  handleNavigateNext: PropTypes.func,
  handleClaimListItemKeyPress: PropTypes.func.isRequired,
  handleClaimListItemSelect: PropTypes.func.isRequired,
  claimList: PropTypes.arrayOf(types.claim).isRequired,
  selectedID: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  allowSearch: PropTypes.bool.isRequired,
  filterGroup: PropTypes.arrayOf(PropTypes.string),
  filterGroupName: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default withStyles(styles)(ClaimList);
