import { AxiosError } from 'axios';
import { inflateString } from '../inflators';
import { isNonEmptyString, isValidString } from '../guards';

export function doesStringInclude(str: unknown, substr: unknown): boolean {
  return inflateString(str).toLowerCase().includes(inflateString(substr).toLowerCase());
}

// temp function until ensureArray and validArray are converted and consolidated across apps
// inflateArray, when properly implemented, will eventually live in ../inflators
function inflateArray(arr: unknown): unknown[] {
  return Array.isArray(arr) ? arr : [];
}

export function doesStringArrayIncludeString(arr: unknown, substr: unknown): boolean {
  return inflateArray(arr).some(str => doesStringInclude(str, substr));
}

// converts 'camelCase' string to space-separated 'Title Case' string
export function splitCamelCase(str: string): string {
  return isValidString(str)
    ? str
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1 $2')
        .replace(/^\w/, c => c.toUpperCase())
    : '';
}

export function getInitials(name: string): string {
  return name
    .split(' ')
    .map(n => n.charAt(0))
    .join('');
}

export function truncateString(str: string, maxLength: number): string {
  return str.length <= maxLength ? str : `${str.slice(0, maxLength - 1)}…`;
}

export function cleanString(str: unknown): string {
  return isNonEmptyString(str) ? str.trim().toLowerCase() : '';
}

export function plusPad(str: string): string {
  return str.split(' ').filter(Boolean).join('+');
}

export function stripPunctuation(str: string): string {
  return str.replace(/[^\w]|_/g, '');
}

export function toSentenceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// toRFC3339 applies UTC-0 timezone.
export function toRFC3339(dateString: string): string {
  const date = new Date(dateString);
  const iso = date.toISOString();
  return `${iso.split('.')[0]}+00:00`;
}

export function toISODateString(dateString: string): string {
  const date = new Date(dateString);
  return date.toISOString();
}

export function getRequestErrorMessage(e: unknown): string {
  return (
    ((e as AxiosError).response?.data as string) ||
    (e as AxiosError).message ||
    'Something went wrong. Please try again'
  );
}

export function isNumberBetween(a: string, min: number, max: number): boolean {
  const num = Number.parseFloat(a);
  if (num === undefined) return false;

  if (min < max) return num >= min && num <= max;
  return num >= max && num <= min;
}
