import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';

import Services from './Services';
import ClaimHeader from './ClaimHeader';
import ClaimFlags from './ClaimFlags';
import ClaimMetadata from '../claimInfo/ClaimMetadata';
import * as types from '../shared/types/propTypes';
import ClaimMessage from '../shared/components/ClaimMessage';

const useStyles = makeStyles({
  root: {
    padding: '1rem',
    overflowY: 'auto',
  },
  claim: {
    fontSize: '14px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

export function ClaimDetail({ selectedClaim, urlId, isAdjud, handleEditPolicyNum }) {
  const classes = useStyles();

  if (!urlId) {
    return <ClaimMessage text="Please select a claim" />;
  }
  if (!(selectedClaim && selectedClaim.id)) {
    return <ClaimMessage text="Invalid claim ID" />;
  }
  if (!selectedClaim.simpleClaim) {
    return <ClaimMessage text="Selected claim missing simple claim" />;
  }

  const { policyNum, formType, typeOfBill, statementFromDate } = selectedClaim.simpleClaim;

  return (
    <div className={classes.root}>
      <ClaimFlags />
      <Card className={classes.claim}>
        <ClaimHeader claim={selectedClaim} handleEditPolicyNum={handleEditPolicyNum} />
        <Services
          claim={selectedClaim}
          isAdjud={isAdjud}
          saveMetadata={{
            claimID: selectedClaim.id,
            planID: policyNum,
            formType,
            billType: typeOfBill,
            statementDate: statementFromDate,
          }}
        />
        <ClaimMetadata simpleClaim={selectedClaim.simpleClaim} />
      </Card>
    </div>
  );
}

ClaimDetail.defaultProps = {
  selectedClaim: undefined,
  urlId: '',
};
ClaimDetail.propTypes = {
  selectedClaim: types.claim,
  urlId: PropTypes.string,
  isAdjud: PropTypes.bool.isRequired,
  handleEditPolicyNum: PropTypes.func.isRequired,
};

export default React.memo(ClaimDetail);
