import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, claimColors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';

const scoreIconSize = 23;

export function CircleIcon() {
  return (
    <svg width={scoreIconSize} height={scoreIconSize}>
      <circle
        fill={claimColors.professional}
        cx={scoreIconSize / 2}
        cy={scoreIconSize / 2}
        r={scoreIconSize / 2}
      />
    </svg>
  );
}

export function SquareIcon() {
  return (
    <svg width={scoreIconSize} height={scoreIconSize}>
      <rect fill={claimColors.outpatient} width={scoreIconSize} height={scoreIconSize} />
    </svg>
  );
}

export function DiamondIcon() {
  return (
    <svg width={scoreIconSize} height={scoreIconSize}>
      <rect
        fill={claimColors.inpatient}
        x={(scoreIconSize / 4) * Math.sqrt(2)}
        y={(scoreIconSize / -4) * Math.sqrt(2)}
        height={(scoreIconSize / 2) * Math.sqrt(2)}
        width={(scoreIconSize / 2) * Math.sqrt(2)}
        transform="rotate(45)"
      />
    </svg>
  );
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: scoreIconSize,
    height: scoreIconSize,
  },
  text: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: scoreIconSize,
    height: scoreIconSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    fontSize: `${scoreIconSize / 2}px`,
  },
});

export default function ScoreIcon({ score }) {
  const classes = useStyles();
  const displayScore = inflateString(score);

  let icon;
  switch (displayScore[0]) {
    case 'P':
      icon = <CircleIcon />;
      break;
    case 'O':
      icon = <SquareIcon />;
      break;
    case 'I':
      icon = <DiamondIcon />;
      break;
    default:
      icon = <CircleIcon />;
  }
  return (
    <div className={classes.root}>
      {icon}
      <div className={classes.text}>{displayScore}</div>
    </div>
  );
}

ScoreIcon.propTypes = {
  score: PropTypes.string.isRequired,
};
