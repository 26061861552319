import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import TextInput from './TextInput';
import * as types from '../shared/types/propTypes';
import { externalStatusNoteTypes } from './externalNoteTypes';

export default function ExternalStatusNote({ value, saveMetadata, save, type }) {
  const handleTypeChange = e => {
    save({
      ...saveMetadata,
      stringVal: e.target.value,
      fieldName: `simpleClaim.extStatusNote.code`,
    });
    if (!shouldShowInput(e.target.value)) {
      // when dropdown doesn't have freeText, scrub existing comment
      save({
        ...saveMetadata,
        stringVal: '',
        fieldName: 'simpleClaim.extStatusNote.comment',
      });
    }
  };

  const shouldShowInput = code => {
    const note = externalStatusNoteTypes.find(i => i.code === code);
    return note ? note.hasFreeText === true : false;
  };

  const e = value && value.code ? value : externalStatusNoteTypes[0];
  const showInput = shouldShowInput(e.code);
  return (
    <>
      <FormControl
        style={{
          gridColumn: showInput ? 'external-start' : 'external',
          width: type !== 'aging' ? '100%' : '18em',
        }}
      >
        <Select value={e.code} onChange={handleTypeChange} style={{ fontSize: '1em' }}>
          {externalStatusNoteTypes.map(option => (
            <MenuItem
              key={option.code}
              value={option.code}
              style={{
                padding: '0 1rem',
                fontSize: '0.75rem',
                height: '1rem',
                minHeight: '1.5rem',
              }}
            >
              {option.comment}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {showInput && (
        <TextInput
          value={e.comment}
          isText
          saveMetadata={{ ...saveMetadata, fieldName: 'simpleClaim.extStatusNote.comment' }}
          save={save}
          style={{ fontSize: '1em', width: '100%', maxWidth: '18em', marginLeft: '2%' }}
        />
      )}
    </>
  );
}

ExternalStatusNote.defaultProps = {
  value: undefined,
  type: '',
};

ExternalStatusNote.propTypes = {
  save: PropTypes.func.isRequired,
  saveMetadata: types.saveMetadata.isRequired,
  value: types.externalComment,
  type: PropTypes.string,
};
