import { AxiosPromise } from 'axios';
import { AllPlansResponse, MasterPlan, PlanElection } from './plans';

export const GET_PLAN = 'GET_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const GET_PLAN_PENDING = 'GET_PLAN_PENDING';
export const GET_PLAN_FULFILLED = 'GET_PLAN_FULFILLED';
export const GET_PLAN_REJECTED = 'GET_PLAN_REJECTED';

export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_ALL_PLANS_PENDING = 'GET_ALL_PLANS_PENDING';
export const GET_ALL_PLANS_FULFILLED = 'GET_ALL_PLANS_FULFILLED';
export const GET_ALL_PLANS_REJECTED = 'GET_ALL_PLANS_REJECTED';

export const SAVE_PLAN = 'SAVE_PLAN';
export const SAVE_PLAN_PENDING = 'SAVE_PLAN_PENDING';
export const SAVE_PLAN_REJECTED = 'SAVE_PLAN_REJECTED';
export const SAVE_PLAN_FULFILLED = 'SAVE_PLAN_FULFILLED';

export const SAVE_PLAN_ELECTION = 'SAVE_PLAN_ELECTION';
export const SAVE_PLAN_ELECTION_PENDING = 'SAVE_PLAN_ELECTION_PENDING';
export const SAVE_PLAN_ELECTION_REJECTED = 'SAVE_PLAN_ELECTION_REJECTED';
export const SAVE_PLAN_ELECTION_FULFILLED = 'SAVE_PLAN_ELECTION_FULFILLED';

export const UPDATE_PLAN_INFO = 'UPDATE_PLAN_INFO';
export const UPDATE_MASTER_CENSUS_NAME = 'UPDATE_MASTER_CENSUS_NAME';
export const UPDATE_PLAN_ELECTIONS = 'UPDATE_PLAN_ELECTIONS';
export const ADD_PLAN_ELECTION = 'ADD_PLAN_ELECTION';
export const UPDATE_PLAN_ELECTION = 'UPDATE_PLAN_ELECTION';
export const SET_SELECTED_ELECTION = 'SET_SELECTED_ELECTION';
export const REMOVE_SELECTED_ELECTION = 'REMOVE_SELECTED_ELECTION';
export const DELETE_PLAN_ELECTION = 'DELETE_PLAN_ELECTION';
export const SET_FILTERED_TPAS = 'UPDATE_TPA_FILTER';

export const ADD_NEW_PLAN_TPA = 'ADD_NEW_PLAN_TPA';
export const REMOVE_NEW_PLAN_TPA = 'REMOVE_NEW_PLAN_TPA';

interface UpdatePlanAction {
  type: typeof UPDATE_PLAN_INFO;
  meta: {
    field: keyof MasterPlan;
    value: number | string | boolean;
    masterPlanID: number;
  };
}

interface UpdateCensusNameAction {
  type: typeof UPDATE_MASTER_CENSUS_NAME;
  meta: {
    masterCensus: string[];
    masterPlanID: number;
  };
}

interface UpdatePlanElectionsAction {
  type: typeof UPDATE_PLAN_ELECTIONS;
  meta: {
    planElections: PlanElection[];
    masterPlanID: number;
  };
}

interface AddPlanElectionAction {
  type: typeof ADD_PLAN_ELECTION;
  meta: {
    planElection: PlanElection;
    FEUniqueId: number;
  };
}

interface UpdatePlanElectionAction {
  type: typeof UPDATE_PLAN_ELECTION;
  meta: {
    planElection: PlanElection;
    sixDegPlanID: number;
  };
}

interface SetSelectedElectionAction {
  type: typeof SET_SELECTED_ELECTION;
  meta: {
    selectedID: string;
    masterPlanID: number;
  };
}

interface RemoveSelectedElectionAction {
  type: typeof REMOVE_SELECTED_ELECTION;
}

interface DeletePlanElectionAction {
  type: typeof DELETE_PLAN_ELECTION;
  meta: {
    selectedID: string;
    masterPlanID: number;
  };
}

interface GetPlansAction {
  type: typeof GET_ALL_PLANS;
  payload: AxiosPromise;
}

interface GetPlansPendingAction {
  type: typeof GET_ALL_PLANS_PENDING;
}

interface GetPlansFulfilledAction {
  type: typeof GET_ALL_PLANS_FULFILLED;
  payload: {
    data: AllPlansResponse;
  };
}

interface GetPlansRejectedAction {
  type: typeof GET_ALL_PLANS_REJECTED;
}

type GetPlansActions =
  | GetPlansAction
  | GetPlansPendingAction
  | GetPlansFulfilledAction
  | GetPlansRejectedAction;

interface GetPlanAction {
  type: typeof GET_PLAN;
  payload: AxiosPromise;
  meta: {
    masterPlanID: number;
  };
}

interface DeletePlanAction {
  type: typeof DELETE_PLAN;
  payload: AxiosPromise;
  meta: {
    masterPlanID: number;
  };
}

interface GetPlanPendingAction {
  type: typeof GET_PLAN_PENDING;
  meta: {
    masterPlanID: number;
  };
}

interface GetPlanFulfilledAction {
  type: typeof GET_PLAN_FULFILLED;
  payload: {
    data: {
      masterPlan: MasterPlan;
      planElections: PlanElection[];
    };
  };
}

interface GetPlanRejectedAction {
  type: typeof GET_PLAN_REJECTED;
  meta: {
    masterPlanID: number;
  };
}

type GetPlanActions =
  | GetPlanAction
  | GetPlanPendingAction
  | GetPlanFulfilledAction
  | GetPlanRejectedAction;

type DeletePlanActions = DeletePlanAction;

interface SavePlanAction {
  type: typeof SAVE_PLAN;
  payload: AxiosPromise;
}

interface SavePlanPendingAction {
  type: typeof SAVE_PLAN_PENDING;
}

interface SavePlanFulfilledAction {
  type: typeof SAVE_PLAN_FULFILLED;
  payload: {
    masterPlan: MasterPlan;
  };
}

interface SavePlanRejectedAction {
  type: typeof SAVE_PLAN_REJECTED;
  payload: any;
}

type SavePlanActions =
  | SavePlanAction
  | SavePlanPendingAction
  | SavePlanFulfilledAction
  | SavePlanRejectedAction;

interface SavePlanElectionAction {
  type: typeof SAVE_PLAN_ELECTION;
  payload: AxiosPromise;
}

interface SavePlanElectionPendingAction {
  type: typeof SAVE_PLAN_ELECTION_PENDING;
}

interface SavePlanElectionFulfilledAction {
  type: typeof SAVE_PLAN_ELECTION_FULFILLED;
  payload: {
    data: {
      planElection: PlanElection;
    };
  };
}

interface SavePlanElectionRejectedAction {
  type: typeof SAVE_PLAN_ELECTION_REJECTED;
}

type SavePlanElectionActions =
  | SavePlanElectionAction
  | SavePlanElectionPendingAction
  | SavePlanElectionFulfilledAction
  | SavePlanElectionRejectedAction;

interface UpdateTPAFilterAction {
  type: typeof SET_FILTERED_TPAS;
  meta: {
    filteredTPAs: number[];
  };
}

interface AddNewPlanTPA {
  type: typeof ADD_NEW_PLAN_TPA;
}

interface RemoveNewPlanTPA {
  type: typeof REMOVE_NEW_PLAN_TPA;
}

export type PlanActionTypes =
  | SavePlanActions
  | SavePlanElectionActions
  | GetPlanActions
  | DeletePlanActions
  | GetPlansActions
  | UpdatePlanAction
  | UpdateCensusNameAction
  | UpdatePlanElectionsAction
  | AddPlanElectionAction
  | UpdatePlanElectionAction
  | SetSelectedElectionAction
  | RemoveSelectedElectionAction
  | DeletePlanElectionAction
  | UpdateTPAFilterAction
  | AddNewPlanTPA
  | RemoveNewPlanTPA;
