import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';

import { TPA } from '../../edi/planEditor/types/plans';

const useStyles = makeStyles({
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal',
    alignItems: 'flex-start',
  },
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  name: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  planID: {
    fontSize: '.85em',
    color: colors.greyDark,
  },
});

type Props = {
  option: TPA;
  index: number;
  highlightedIndex: number;
  getItemProps: (obj: object) => void;
};
export default function PlanOption({
  option,
  index,
  highlightedIndex,
  getItemProps,
}: Props): JSX.Element {
  const classes = useStyles();
  const { tpaName, accountID } = option;
  const key = `${tpaName}-${accountID}`;

  return (
    <MenuItem
      {...getItemProps({
        item: option,
        key,
        component: 'div',
        dense: true,
        selected: highlightedIndex === index,
        className: classes.menuItem,
      })}
    >
      <div className={classes.firstRow}>
        <div className={classes.name}>
          <Typography variant="body2" noWrap>
            <strong>{inflateString(tpaName)}</strong>
          </Typography>
        </div>
      </div>
      <Typography variant="body2" className={classes.planID}>
        Account ID: {accountID}
      </Typography>
    </MenuItem>
  );
}
