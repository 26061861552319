import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

type Props = {
  isOpen: boolean;
  label: string;
  contentText: string;
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const useStyles = makeStyles({
  actions: {
    padding: '0 1rem 1rem',
  },
});

export default function ConfirmDialog({
  isOpen,
  label,
  contentText,
  cancelText = 'CANCEL',
  confirmText = 'CONFIRM',
  onCancel,
  onConfirm,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="text" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button color="secondary" variant="contained" onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
