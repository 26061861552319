import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';

import { colors } from '@apps/shared/src/style';
import * as types from '../../shared/types/propTypes';

const useStyles = makeStyles({
  useSelectedIDs: {
    marginRight: '1em',
  },
  greyText: {
    color: colors.grey67,
  },
});

export default function ViewSelectedClaimsSwitch({
  filteredAging,
  selectedIDs,
  onChange,
  viewSelectedClaims,
}) {
  const classes = useStyles();

  let numOfClaims =
    filteredAging && filteredAging.length > 0 && selectedIDs && selectedIDs.length > 0
      ? filteredAging.filter(c => selectedIDs.includes(c.id)).length
      : 0;
  if (numOfClaims === 0) numOfClaims = filteredAging.length;
  const label = `${numOfClaims} claim${numOfClaims !== 1 ? 's' : ''}`;

  return (
    <span className={classes.useSelectedIDs}>
      <Tooltip title="Only View Selected Claims">
        <Switch checked={viewSelectedClaims} onChange={onChange} value="viewSelectedClaims" />
      </Tooltip>
      <span className={viewSelectedClaims ? '' : classes.greyText}>{label}</span>
    </span>
  );
}

ViewSelectedClaimsSwitch.propTypes = {
  filteredAging: PropTypes.arrayOf(types.reportRow).isRequired,
  selectedIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  viewSelectedClaims: PropTypes.bool.isRequired,
};
