/* eslint-disable @typescript-eslint/no-explicit-any */
import { SimpleUser } from './types';

export function isSimpleUser(simpleUser: any): simpleUser is SimpleUser {
  return (
    Boolean(simpleUser) &&
    typeof simpleUser.userID === 'string' &&
    typeof simpleUser.fullName === 'string' &&
    Array.isArray(simpleUser.roles)
  );
}
