import React from 'react';
import PropTypes from 'prop-types';

import Refresh from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export default function RefreshButton({ tooltip, onClick }) {
  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick}>
        <Refresh />
      </IconButton>
    </Tooltip>
  );
}

RefreshButton.propTypes = {
  tooltip: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
