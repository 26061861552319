import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import TextInput from '../../shared/components/TextInput';

import { SettlementMethodology } from '../types/types';
import { InputChangeHandler } from '../../shared/types/types';

const useStyles = makeStyles({
  radioText: {
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  textInput: {
    maxWidth: '80px',
    marginLeft: '28px',
  },
});

type ParentProps = {
  label: string;
  disabled: boolean;
  selectedMethodology: SettlementMethodology;
  methodology: SettlementMethodology;
  percentValue?: number;
  handleChange: InputChangeHandler;
  handleBlur: InputChangeHandler;
};

type Props = ParentProps;

export default function SettlementMethodOption({
  methodology,
  percentValue,
  handleChange,
  handleBlur,
  selectedMethodology,
  label,
  disabled,
}: Props): JSX.Element {
  const classes = useStyles();

  const isSelected = selectedMethodology === methodology;

  return (
    <div className={classes.radioText}>
      <FormControlLabel
        value={methodology}
        control={<Radio color="primary" />}
        label={label}
        disabled={disabled}
      />
      {isSelected && percentValue !== undefined && (
        <TextInput
          className={classes.textInput}
          isPercent
          value={percentValue}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
      )}
    </div>
  );
}
