import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { colors } from '@apps/shared/src/style';

const useStyles = makeStyles({
  iconButton: {
    width: '36px',
    height: '36px',
    lineHeight: '36px',
    fontSize: '18px',
    padding: '0',
    color: colors.grey37,
  },
});

export default function IconButtonCompact({
  tooltip,
  className,
  children,
  onClick,
  onMouseDown,
  isDisabled,
  id,
}) {
  const classes = useStyles();
  const iconButton = (
    <IconButton
      id={id}
      className={classNames(className, classes.iconButton)}
      onClick={onClick}
      onMouseDown={onMouseDown}
      disabled={isDisabled}
    >
      {children}
    </IconButton>
  );
  return tooltip ? <Tooltip title={tooltip}>{iconButton}</Tooltip> : iconButton;
}

IconButtonCompact.defaultProps = {
  tooltip: null,
  className: null,
  onClick: null,
  onMouseDown: null,
  isDisabled: false,
  id: 'icon-button',
};

IconButtonCompact.propTypes = {
  tooltip: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  isDisabled: PropTypes.bool,
  id: PropTypes.string,
};
