import { clearRedirect, createProfile, login } from '@apps/shared/src/auth/userActions';
import { checkCompromisedPassword } from '@apps/shared/src/auth/passwordUtils'; // Import password compromise check function
import UserCard from '@apps/shared/src/auth/UserCard';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const styles = {
  inputWrapper: {
    width: '100%',
    marginTop: '10px',
  },
};

class CreateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      password: '',
      rePassword: '',
      isValid: false,
      nameDirty: false,
      passDirty: false,
      rePassDirty: false,
      errors: {
        fullName: '',
        password: '',
        rePassword: '',
        agreeCheck: '',
      },
      hasMinChar: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isCompromised: false,
      isChecking: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatch(clearRedirect()); // Clear any redirect before leaving
  }

  setValue = (name, validate) => e => {
    if (validate) this.setState({ [name]: e.target.value }, this.validate);
    else this.setState({ [name]: e.target.value });
  };

  validate = () => {
    const errors = {
      fullName: '',
      password: '',
      rePassword: '',
    };
    const { fullName, password, rePassword, passDirty, rePassDirty, nameDirty } = this.state;

    if (fullName.trim() !== fullName) errors.fullName = 'No leading or trailing whitespace';
    else if (nameDirty && fullName.trim().length < 3) errors.fullName = 'Minimum 3 characters';

    if (password.trim() !== password) errors.password = 'No leading or trailing whitespace';
    if (passDirty) {
      if (password.length < 12) errors.password = 'Minimum 12 characters';
      else if (password.length > 128) errors.password = 'Maximum 128 characters';
    }

    if (rePassDirty && rePassword?.length > 0 && password !== rePassword)
      errors.rePassword = 'Passwords must match';

    this.setState({ errors });

    const valCheck =
      this.state.password.trim().length > 0 &&
      this.state.rePassword.trim().length > 0 &&
      this.state.fullName.trim().length > 0;
    const errCount = Object.values(errors).filter(x => x !== '').length > 0;
    const defaultEmailCheck = this.props.registerEmail !== undefined;
    return this.setState({ isValid: !errCount && valCheck && defaultEmailCheck });
  };

  checkPassword = async password => {
    this.setState({ isChecking: true });
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let hasMinChar = false;
    let isCompromised = false;

    if (password.length >= 12) {
      hasMinChar = true;
    }
    if (/[A-Z]/.test(password)) {
      hasUppercase = true;
    }
    if (/[a-z]/.test(password)) {
      hasLowercase = true;
    }
    if (/[0-9]/.test(password)) {
      hasNumber = true;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      hasSpecialChar = true;
    }

    // Call the API to check if the password is compromised
    try {
      isCompromised = await checkCompromisedPassword(password);
    } catch (error) {
      isCompromised = false;
    }

    this.setState({
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
      hasMinChar,
      isCompromised,
      isChecking: false,
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.createProfile();
  };

  login = () => {
    this.props.dispatch(
      login({
        email: this.props.registerEmail,
        password: this.state.password,
        application: 'claims',
        destinationURL: '/repricing',
      })
    );
  };

  createProfile = () => {
    this.props.dispatch(createProfile(this.state.fullName, this.state.password, this.props.app));
  };

  handleChange = event => {
    const {
      target: { value },
    } = event;
    this.setState({ password: value });
    this.checkPassword(value);
  };

  render() {
    const { classes } = this.props;

    const form = (
      <form onSubmit={this.handleSubmit} style={{ width: '100%', margin: 'auto' }}>
        <div style={{ maxWidth: '250px', margin: 'auto', textAlign: 'left' }}>
          <FormControl className={classes.inputWrapper} error={!!this.state.errors.fullName}>
            <InputLabel htmlFor="fullName">Full Name</InputLabel>
            <Input
              id="fullName"
              value={this.state.fullName}
              onChange={this.setValue('fullName')}
              onBlur={() => this.validate()}
              onFocus={() => this.setState({ nameDirty: true })}
              label="Full Name"
              fullWidth
              style={{ maxWidth: '250px' }}
              aria-describedby="fullNameErrorText"
            />
            <FormHelperText id="fullNameErrorText">{this.state.errors.fullName}</FormHelperText>
          </FormControl>

          <FormControl
            className={classes.inputWrapper}
            error={!!this.state.errors.email}
            style={{ paddingBottom: '20px' }}
          >
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input id="email" value={this.props.registerEmail} label="Email" readOnly fullWidth />
          </FormControl>

          <FormControl className={classes.inputWrapper} error={!!this.state.errors.password}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              value={this.state.password}
              onChange={this.handleChange}
              onBlur={() => this.validate()}
              onFocus={() => this.setState({ passDirty: true })}
              type="password"
              fullWidth
              aria-describedby="passwordErrorText"
            />
            <FormHelperText id="passwordErrorText">{this.state.errors.password}</FormHelperText>
            {this.state.password && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {[
                  { label: '12 Characters', state: this.state.hasMinChar },
                  { label: 'Uppercase', state: this.state.hasUppercase },
                  { label: 'Lowercase', state: this.state.hasLowercase },
                  { label: 'Number', state: this.state.hasNumber },
                  { label: 'Special Character', state: this.state.hasSpecialChar },
                ].map(({ label, state }) => (
                  <span key={label}>
                    <span style={{ color: state ? 'green' : 'red' }}>
                      {state ? '\u2714' : '\u2718'} <span style={{ color: 'black' }}>{label}</span>
                    </span>
                  </span>
                ))}
                <span>
                  <span style={{ color: this.state.isCompromised ? 'red' : 'green' }}>
                    {this.state.isCompromised ? '\u2718' : '\u2714'}{' '}
                    <span style={{ color: 'black' }}>Not a Compromised Password</span>
                  </span>
                </span>
              </div>
            )}
          </FormControl>

          <FormControl className={classes.inputWrapper} error={!!this.state.errors.rePassword}>
            <InputLabel htmlFor="rePassword">Re-enter Password</InputLabel>
            <Input
              id="rePassword"
              value={this.state.rePassword}
              onChange={this.setValue('rePassword', true)}
              onFocus={() => this.setState({ rePassDirty: true })}
              onBlur={() => this.validate()}
              type="password"
              fullWidth
              style={{ maxWidth: '250px' }}
              aria-describedby="rePasswordErrorText"
            />
            <FormHelperText id="rePasswordErrorText">{this.state.errors.rePassword}</FormHelperText>
          </FormControl>
        </div>
        <Button
          style={{ margin: '40px auto 0', maxWidth: '350px' }}
          variant="contained"
          type="submit"
          color="primary"
          disabled={!this.state.isValid || this.state.isChecking}
          fullWidth
        >
          {this.state.isChecking ? 'Checking...' : 'CREATE ACCOUNT'}
        </Button>
      </form>
    );

    return this.props.redirectTo !== '' ? (
      <Redirect to={this.props.redirectTo} />
    ) : (
      <UserCard showLogo={!this.state.showTOS}>{form}</UserCard>
    );
  }
}

CreateProfile.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  registerEmail: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
};
CreateProfile.defaultProps = {
  redirectTo: '',
};

function mapStateToProps(state) {
  return {
    redirectTo: state.user.redirectTo,
    registerEmail: state.user.currentUser?.email,
    app: state.admin.app,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(CreateProfile));
