import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { TextFieldProps } from '@material-ui/core/TextField';
import CalendarIcon from '@material-ui/icons/Event';
import KeyboardIcon from '@material-ui/icons/Keyboard';

import { isValidDate } from '../utilities/date';

type Props = Pick<TextFieldProps, 'className' | 'fullWidth' | 'margin' | 'required'> & {
  name: string;
  label: string;
  value: string;
  isDefaultNative?: boolean;
  onChange: (dateValue: string) => void;
  onClick?: MouseEventHandler<HTMLElement>;
};

function formatDateInputValue(input: string): string {
  const nonNumberPattern = /\D/g;
  const sanitizedInput = input.replace(nonNumberPattern, '');
  const month = sanitizedInput.substring(0, 2);
  const day = sanitizedInput.substring(2, 4);
  const year = sanitizedInput.substring(4, 8);

  let value = month;
  if (day !== '') value += `/${day}`;
  if (year !== '') value += `/${year}`;

  return value;
}

const dateDisplayValuePattern = /^\d{2}\/\d{2}\/\d{4}$/; // mm/dd/yyyy
const dateValuePattern = /^\d{4}-\d{2}-\d{2}$/; // yyyy-mm-dd

// Convert yyyy-mm-dd to mm/dd/yyyy
function dateValueToDateDisplayValue(value: string): string {
  if (!dateValuePattern.test(value)) return '';
  const [year, month, day] = value.split('-');
  return `${month}/${day}/${year}`;
}

// Convert mm/dd/yyyy to yyyy-mm-dd
function dateDisplayValueToDateValue(value: string): string {
  if (!dateDisplayValuePattern.test(value)) return '';
  const [month, day, year] = value.split('/');
  return `${year}-${month}-${day}`;
}

function isDateInputValueValid(value: string): boolean {
  if (!dateDisplayValuePattern.test(value)) return false;

  const date = new Date(value);
  if (!isValidDate(date)) return false;

  // Check for weird dates like Feb 31st
  const [month, day, year] = value.split('/').map(a => Number.parseInt(a, 10));
  return date.getDate() === day && date.getMonth() + 1 === month && date.getFullYear() === year;
}

export default function DateInput({
  className,
  name,
  label,
  value,
  required,
  fullWidth,
  margin,
  isDefaultNative = false,
  onChange,
  onClick,
}: Props): JSX.Element {
  const [inputValue, setInputValue] = useState(dateValueToDateDisplayValue(value)); // mm/dd/yyyy
  const [nativeInputValue, setNativeInputValue] = useState(value); // yyyy-mm-dd
  const [isNativeInput, setIsNativeInput] = useState(isDefaultNative);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ValidatorForm.addValidationRule('isDate', isDateInputValueValid);
    return (): void => ValidatorForm.removeValidationRule('isDate');
  }, []);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    if (value === '') {
      setNativeInputValue('');
      onChange('');
    }

    const formattedValue = formatDateInputValue(value);
    setInputValue(formattedValue);

    if (!isDateInputValueValid(formattedValue)) return;

    const nativeValue = dateDisplayValueToDateValue(formattedValue);
    setNativeInputValue(nativeValue);
    onChange(nativeValue);
  };

  const handleNativeInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    setInputValue(dateValueToDateDisplayValue(value));
    setNativeInputValue(value);
    onChange(value);
  };

  const handleInputClick: MouseEventHandler<HTMLInputElement> = e => {
    if (inputRef.current) inputRef.current.setSelectionRange(0, inputValue.length);
    if (onClick) onClick(e);
  };

  const handleInputKeyUp: KeyboardEventHandler<HTMLInputElement> = e => {
    if ((e.key === 'ArrowLeft' || e.key === 'ArrowRight') && inputRef.current)
      inputRef.current.setSelectionRange(0, inputValue.length);
  };

  const handleIconClick: MouseEventHandler<{}> = () => setIsNativeInput(!isNativeInput);

  const validators = [];
  const errorMessages = [];
  if (required) {
    validators.push('required');
    errorMessages.push('This field is required.');
    if (!isNativeInput) {
      validators.push('isDate');
      errorMessages.push('Must be valid date');
    }
  }

  if (isNativeInput)
    return (
      <TextValidator
        inputRef={inputRef}
        className={className}
        fullWidth={fullWidth}
        margin={margin}
        label={label}
        name={name}
        type="date"
        onClick={onClick}
        onChange={handleNativeInputChange}
        value={nativeInputValue}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleIconClick}>
                <KeyboardIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        validators={validators}
        errorMessages={errorMessages}
      />
    );

  return (
    <TextValidator
      inputRef={inputRef}
      className={className}
      fullWidth={fullWidth}
      margin={margin}
      label={label}
      name={name}
      type="text"
      onClick={handleInputClick}
      onChange={handleInputChange}
      onKeyUp={handleInputKeyUp}
      value={inputValue}
      placeholder="mm/dd/yyyy"
      InputProps={{
        inputProps: {
          inputMode: 'numeric',
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleIconClick}>
              <CalendarIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      validators={validators}
      errorMessages={errorMessages}
    />
  );
}
