import React from 'react';
import { inflateString, inflateNumber } from '@apps/shared/src/inflators';
import TextFieldInput from './TextFieldInput';
import SelectionInput from './SelectionInput';
import CodeInput from './CodeInput';
import RangeInput from './RangeInput';
import PercentInput from './PercentInput';
import MoneyInput from './MoneyInput';
import { termItems } from '../termElements';
import { TermItem, ContractTerm, HandleTermValueChange, RangeType } from '../../types/contracts';
import { validArray } from '../../../shared/typeChecks';

type Props = {
  termItem: TermItem;
  currentValues: ContractTerm;
  handleChange: HandleTermValueChange;
};
export default function TermInputSelection({
  termItem,
  currentValues,
  handleChange,
}: Props): JSX.Element | null {
  switch (termItem) {
    case termItems.limitedServices:
    case termItems.extraStipulations:
      return (
        <TextFieldInput
          value={inflateString(currentValues[termItem])}
          handleChange={handleChange}
          id={termItem}
        />
      );
    case termItems.claimType:
      return (
        <SelectionInput
          value={inflateString(currentValues[termItem])}
          handleChange={handleChange}
          id={termItem}
          options={[
            { value: 'facility', name: 'Facility' },
            { value: 'professional', name: 'Professional' },
            { value: 'unspecified', name: 'Unspecified' },
          ]}
        />
      );
    case termItems.revCode:
    case termItems.hcpcsOrCPT:
      return (
        <CodeInput
          value={validArray(currentValues[termItem]).map(inflateString)}
          handleChange={handleChange}
          id={termItem}
        />
      );
    case termItems.unlistedCharges: {
      return null;
    }
    case termItems.rangeType: {
      return (
        <RangeInput
          startRange={inflateString(currentValues.startRange)}
          endRange={inflateString(currentValues.endRange)}
          rangeType={inflateString(currentValues.rangeType) as RangeType}
          handleChange={handleChange}
          id={termItem}
        />
      );
    }
    case termItems.percentCMS:
    case termItems.percentBilledCharges:
      return (
        <PercentInput
          value={inflateNumber(currentValues[termItem])}
          handleChange={handleChange}
          id={termItem}
        />
      );
    case termItems.perDiem:
    case termItems.flatRate:
    case termItems.caseRate:
    case termItems.maxAmount:
      return (
        <MoneyInput
          value={inflateNumber(currentValues[termItem])}
          handleChange={handleChange}
          id={termItem}
        />
      );
    default:
      return <div>Unknown Term Item</div>;
  }
}
