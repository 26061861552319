import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { notesColumns } from '../shared/grid';
import { claimStatusCodes } from '../shared/status';
import * as types from '../shared/types/propTypes';
import { setAllExternalComments, updateClaim } from './claimsActions';
import ExternalRepricingNote from './ExternalRepricingNote';
import ExternalStatusNote from './ExternalStatusNote';
import HoverableText from './HoverableText';
import TextInput from './TextInput';

class Notes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // show: this.getShow(props),
      show: true,
    };
  }

  getShow = props => {
    const { type, internal, external } = props;
    return (
      type === 'claim' ||
      (internal && internal !== '') ||
      (external && external.code && external.code !== 'DEG')
    );
  };

  toggleShow = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  save = v => {
    this.props.dispatch(updateClaim(v));
  };

  setAllExtComments = code => {
    this.props.dispatch(setAllExternalComments(code));
  };

  render() {
    const {
      svc,
      type,
      internal,
      intCmntFieldName,
      external,
      externalStatus,
      extCmtFieldName,
      saveMetadata,
      claimStatus,
      showqpa,
      originalrepricedamt,
    } = this.props;
    const label = column => ({ gridColumn: column, fontWeight: '500' });

    const hoverData = [
      { field: 'Zip:', value: svc?.qpaZipCode ? svc?.qpaZipCode : 'N/A' },
      { field: 'Zip Type:', value: svc?.qpaZipCodeType ? svc?.qpaZipCodeType : 'N/A' },
      { field: 'Division:', value: svc?.qpaTable ? svc?.qpaTable : 'N/A' },
    ];
    // const { showqpa} = showQPA
    return (
      <>
        {/* <GridColumn center>
          <IconButtonCompact
            id={`${saveMetadata.serviceIndex}-${this.state.show ? 'show' : 'hide'}-note`}
            onClick={this.toggleShow}
          >
            <DescriptionIcon style={{ color: colors.blue }} />
          </IconButtonCompact> */}
        {/* {showQPA && (
            <>
              <TextInput
                id={`${saveMetadata.serviceIndex}-QPA`}
                disabled
                style={{ position: 'absolute' }}
                value={qpa}
                isMoney
                fullWidth
              />
              <Tooltip title={qpaLocation} placement="bottom">
                <p
                  style={{
                    position: 'relative',
                    top: '50%',
                    right: '35%',
                    color: colors.blueLight,
                  }}
                >
                  QPA
                </p>
              </Tooltip>
            </>
          )} */}
        {/* </GridColumn> */}
        <div style={{ gridColumn: '1 / -2', display: 'grid', gridTemplateColumns: notesColumns }}>
          <div style={label('internal')}>Internal Comments</div>
          <div style={label('external')}>
            {`${type === 'claim' ? 'Set All ' : ''}External Repricing Notes`}
          </div>
          <TextInput
            isText
            id={`${saveMetadata.serviceIndex}-internal-comments`}
            multiline
            value={internal}
            saveMetadata={{ ...saveMetadata, fieldName: intCmntFieldName }}
            save={this.save}
            style={{ fontSize: '14px', marginRight: '1rem' }}
          />
          <ExternalRepricingNote
            type={type}
            id={`${saveMetadata.serviceIndex}-external-comments`}
            value={external}
            save={this.save}
            setAll={this.setAllExtComments}
            saveMetadata={saveMetadata}
            fieldPrefix={extCmtFieldName}
          />
          {type === 'claim' &&
            (claimStatus === claimStatusCodes.onHold ||
              claimStatus === claimStatusCodes.flagged) && (
              <>
                <div style={{ gridColumn: 'external', fontWeight: '500', marginTop: '0.5em' }}>
                  External Status Notes
                </div>
                <ExternalStatusNote
                  value={externalStatus}
                  save={this.save}
                  saveMetadata={saveMetadata}
                />
              </>
            )}
          {showqpa && svc?.repricing?.formulaType === 'qpa' && (
            <div
              style={{
                marginTop: '1%',
                gridColumn: '1 / -1',
                display: 'grid',
                gridGap: '5%',
                gridTemplateColumns: '10% 10%',
                justifyContent: 'end',
              }}
            >
              <HoverableText
                type="qpa"
                code="qpaAmount"
                value={svc.qpaAmount}
                qpaHoverData={hoverData}
              >
                {' '}
                <TextInput
                  style={{ alignSelf: 'flex-end' }}
                  id={`${saveMetadata.serviceIndex}-qpa`}
                  value={svc.qpaAmount}
                  isMoney
                  disabled
                  fullWidth
                  label="QPA"
                />
              </HoverableText>

              <TextInput
                id={`${saveMetadata.serviceIndex}-original-reprice-amount`}
                value={originalrepricedamt}
                disabled
                isMoney
                fullWidth
                label="Orignal Repriced Amount"
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

Notes.defaultProps = {
  svc: {},
  internal: '',
  claimStatus: '',
  externalStatus: undefined,
  external: undefined,
  qpaAmount: null,
  originalrepricedamt: null,
};

Notes.propTypes = {
  svc: types.serviceItem,
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  internal: PropTypes.string,
  intCmntFieldName: PropTypes.string.isRequired,
  external: types.externalComment,
  externalStatus: types.externalComment,
  extCmtFieldName: PropTypes.string.isRequired,
  saveMetadata: types.saveMetadata.isRequired,
  claimStatus: PropTypes.string,
  qpaAmount: PropTypes.number,
  originalrepricedamt: PropTypes.number,
};

export default connect()(Notes);
