import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { ExceptionsReportExcludedTPA } from '../types';

type Props = {
  exclusion: ExceptionsReportExcludedTPA;
};

export default function ExclusionsTableRow({ exclusion }: Props): JSX.Element {
  const { name, sixDegTPAID, reason } = exclusion;

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{sixDegTPAID}</TableCell>
      <TableCell>{reason}</TableCell>
    </TableRow>
  );
}
