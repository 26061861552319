import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import MapIcon from '@material-ui/icons/Map';
import RoomIcon from '@material-ui/icons/Room';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
  TableCellHeaderTop,
  Column,
  addButtonStyle,
  deleteButtonStyle,
  configSectionStyle,
} from './sharedConfig';
import { defaultRepriceCodeMapping } from './defaultTypes';

import * as types from '../../shared/types/propTypes';
import { validArray } from '../../shared/typeChecks';
import TextInput from '../../shared/components/TextInput';

const useStyles = makeStyles({
  configSection: configSectionStyle,
  addButton: addButtonStyle,
  deleteButton: deleteButtonStyle,
  claimInfoCell: {
    margin: '10px',
  },
});

export default function RepriceCodeMappings({
  repriceCodeMappings,
  isReadOnly,
  handleSetObject,
  handleUnSetObject,
  handleTextChange,
  handlePushToArray,
  handleRemoveFromArray,
}) {
  const classes = useStyles();

  const [newMappingKey, setNewMappingKey] = useState('XXX');

  const handleNewMappingName = () => e => {
    setNewMappingKey(e.target.value);
  };

  const addMappingToConfig = handleSetObject(`repriceCodeMappings.${newMappingKey}`, [
    defaultRepriceCodeMapping(),
  ]);

  const enterNewMappingSection = () => () => {
    addMappingToConfig();
    setNewMappingKey('XXX');
  };

  if (!repriceCodeMappings) {
    return null;
  }

  return (
    <Paper className={classes.claimInfoCell}>
      <>
        <TableCellHeaderTop>
          <Typography>
            <MapIcon /> &nbsp; Reprice Code Mappings
          </Typography>
          {!isReadOnly && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.deleteButton}
              onClick={handleUnSetObject('repriceCodeMappings')}
            >
              <DeleteForeverIcon /> &nbsp; Mapping Config
            </Button>
          )}
        </TableCellHeaderTop>

        {!isReadOnly && (
          <TableCellHeaderTop>
            <TextInput
              readOnly={isReadOnly}
              label="New Mapping:"
              aria-label="New Mapping:"
              onChange={handleNewMappingName()}
              value={newMappingKey}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              onClick={enterNewMappingSection()}
            >
              <CreateNewFolderIcon /> &nbsp; Mapping Section
            </Button>
          </TableCellHeaderTop>
        )}
      </>
      <Column>
        <div className={classes.configSection}>
          {Object.keys(repriceCodeMappings).map(key => {
            return (
              <Paper className={classes.claimInfoCell} key={key}>
                <TableCellHeaderTop>
                  <Typography>
                    <RoomIcon /> &nbsp;
                    {key}
                  </Typography>
                  {!isReadOnly && (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.deleteButton}
                        onClick={handleUnSetObject(`repriceCodeMappings.${key}`)}
                      >
                        <DeleteForeverIcon /> &nbsp; Mapping Section
                      </Button>
                    </>
                  )}
                </TableCellHeaderTop>

                {!isReadOnly && (
                  <TableCellHeaderTop>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.addButton}
                      onClick={handlePushToArray(
                        `repriceCodeMappings.${key}`,
                        defaultRepriceCodeMapping()
                      )}
                    >
                      <AddCircleIcon /> &nbsp; Mapping
                    </Button>
                  </TableCellHeaderTop>
                )}

                <Column>
                  {validArray(repriceCodeMappings[key]).map((code, i) => {
                    return (
                      <Paper className={classes.claimInfoCell} key={key}>
                        <TableCellHeaderTop>
                          {key} Map #{i + 1}
                          {!isReadOnly && (
                            <Button
                              variant="contained"
                              color="secondary"
                              className={classes.deleteButton}
                              onClick={handleRemoveFromArray(`repriceCodeMappings.${key}`, i)}
                            >
                              <HighlightOffIcon />
                            </Button>
                          )}
                        </TableCellHeaderTop>
                        <Column>
                          <TextInput
                            readOnly={isReadOnly}
                            label="Element"
                            aria-label="Element"
                            fullWidth
                            onChange={handleTextChange(`repriceCodeMappings.${key}.${i}.element`)}
                            value={code.element}
                          />
                          <TextInput
                            readOnly={isReadOnly}
                            label="Code"
                            aria-label="Code"
                            fullWidth
                            onChange={handleTextChange(`repriceCodeMappings.${key}.${i}.code`)}
                            value={code.code}
                          />
                        </Column>
                      </Paper>
                    );
                  })}
                </Column>
              </Paper>
            );
          })}
        </div>
      </Column>
    </Paper>
  );
}

RepriceCodeMappings.defaultProps = {
  repriceCodeMappings: null,
};

RepriceCodeMappings.propTypes = {
  repriceCodeMappings: types.repriceCodeMappings,
  isReadOnly: PropTypes.bool.isRequired,
  handleSetObject: PropTypes.func.isRequired,
  handleUnSetObject: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handlePushToArray: PropTypes.func.isRequired,
  handleRemoveFromArray: PropTypes.func.isRequired,
};
