import React from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import { addOrUpdateRoster, cancelNewRoster } from '../contractsActions';
import Providers from './Providers';
import { TopLevelCell } from '../chips/ChipBox';
import { ContractRoster } from '../types/contracts';
import { RootState } from '../../shared/types/types';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '1em',
  },
  top: {
    padding: '1em',
    gridArea: 'top',
  },
  icon: {
    marginRight: '1em',
  },
  buttons: {
    textAlign: 'right',
  },
  action: {
    margin: '0.5em',
  },
}));

type ParentProps = {
  isEdit: boolean;
};
type StateProps = {
  isContractLoading: boolean;
  selectedRoster: ContractRoster;
  isRosterOpen: boolean;
  isRosterLoading: boolean;
  isNPILookupPending: boolean;
};
type DispatchProps = {
  addOrUpdateRoster: () => void;
  cancelNewRoster: () => void;
};
type Props = ParentProps & StateProps & DispatchProps;

export function RosterForm({
  isEdit,
  selectedRoster,
  isRosterOpen,
  cancelNewRoster,
  addOrUpdateRoster,
  isContractLoading,
  isRosterLoading,
  isNPILookupPending,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (isRosterLoading && !isEdit) return <LoadingSpinner />;

  if (!isRosterOpen) return null;

  const isSaveButtonDisabled = isContractLoading || isNPILookupPending;

  return (
    <Card className={classes.container}>
      <TopLevelCell className={classes.top}>{selectedRoster.name}</TopLevelCell>
      <Providers isEdit={isEdit} />
      <TopLevelCell className={classes.buttons}>
        <Button variant="outlined" className={classes.action} onClick={cancelNewRoster}>
          {isEdit ? 'Cancel' : 'Close'}
        </Button>
        {isEdit && (
          <Button
            variant="contained"
            color="primary"
            className={classes.action}
            onClick={addOrUpdateRoster}
            disabled={isSaveButtonDisabled}
          >
            Save Roster
          </Button>
        )}
      </TopLevelCell>
    </Card>
  );
}

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    isContractLoading: contracts.isContractLoading,
    selectedRoster: contracts.selectedRoster,
    isRosterOpen: contracts.isRosterOpen,
    isRosterLoading: contracts.isRosterLoading,
    isNPILookupPending: contracts.isNPILookupPending,
  };
};

const mapDispatchToProps = {
  addOrUpdateRoster,
  cancelNewRoster,
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterForm);
