import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import ScoreIcon from './ScoreIcon';
import StatusIcons from './StatusIcons';
import ListItemContent from './ListItemContent';
import * as types from '../shared/types/propTypes';

const useStyles = makeStyles({
  listItem: {
    display: 'grid',
    transition: 'opacity 0.75s',
    overflow: 'hidden',
    gridTemplateColumns: 'max-content 40px auto',
    boxShadow: '1px 3px 0px 0px rgba(0,0,0,0.11)',
    textDecoration: 'none',
    padding: '8px',
    color: colors.black,
    cursor: 'pointer',
    '&:focus': {
      border: `2px dashed ${colors.focused}`,
      outline: 'none',
    },
    '&:hover, &:active': {
      backgroundColor: colors.hovered,
    },
    border: `2px solid transparent`,
    marginBottom: '2px',
  },
  overdueItem: {
    borderLeft: `4px solid ${colors.redScarlet}`,
    boxShadow: '1px 3px 0px 0px rgba(0,0,0,0.11)',
    '&:focus': {
      border: `2px dashed ${colors.redScarlet}`,
      outline: 'none',
    },
  },
  dueItem: {
    borderLeft: `4px solid ${colors.yellow}`,
    boxShadow: '1px 3px 0px 0px rgba(0,0,0,0.11)',
    '&:focus': {
      border: `2px dashed ${colors.yellow}`,
      outline: 'none',
    },
  },
  animateOut: {
    opacity: '0',
  },
  selected: {
    backgroundColor: colors.selected,
  },
});

// export function getDaysSinceCreation(date) {
//   const oneDay = 24 * 60 * 60 * 1000;
//   const currentDate = new Date();
//   const creationDate = new Date(date);
//   return Math.round(Math.abs((currentDate - creationDate) / oneDay));
// }

export function parseProps(claim) {
  const validClaim = claim || {};
  const { id, status, simpleClaim = {}, returnDueDate, batches } = validClaim;
  const { score } = simpleClaim;
  return { id: inflateString(id), score, status, returnDueDate, batches };
}

export default function ClaimListItem(props) {
  const { style, isSelected, animateOut, claim, handleSelect, handleKeyPress } = props;
  const { id, score, status, returnDueDate, batches } = parseProps(claim);

  const classes = useStyles();

  const claimListItemClasses = classNames('claim-list-item', classes.listItem, {
    [classes.selected]: isSelected,
    [classes.animateOut]: animateOut,
    [classes.overdueItem]: claim?.priorityStatus === 'overdue',
    [classes.dueItem]: claim?.priorityStatus === 'due',
  });

  return (
    <div style={style}>
      <div
        onClick={handleSelect}
        onKeyDown={handleKeyPress}
        role="button"
        tabIndex="0"
        className={claimListItemClasses}
        id={`claim-list-item-${id}`}
      >
        <ScoreIcon score={score} />
        <StatusIcons status={status} />
        <ListItemContent claim={claim} />
      </div>
    </div>
  );
}

ClaimListItem.defaultProps = {
  style: {},
  isSelected: false,
  animateOut: false,
};

ClaimListItem.propTypes = {
  style: PropTypes.shape(),
  claim: types.claim.isRequired,
  isSelected: PropTypes.bool,
  animateOut: PropTypes.bool,
  handleKeyPress: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};
