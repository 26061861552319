import { Dispatch } from 'redux';
import axios from 'axios';
import s, { SettlementsActionTypes } from './types/actionTypes';
import {
  SettlementMethodology,
  SFCase,
  SettlementsClaimSummary,
  GetImpactRequest,
  DisplayReportOptions,
  MediVIChartOptions,
  MediVIProvider,
} from './types/types';
import { RootState } from '../shared/types/types';

type SettlementsDispatch = Dispatch<SettlementsActionTypes>;

export function getCaseSummary(caseNumber: string): SettlementsActionTypes {
  return {
    type: s.GET_CASE_SUMMARY,
    payload: axios.get(`/api/settlements/case/${caseNumber}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
    meta: { caseNumber },
  };
}

export function getImpactReport() {
  return (dispatch: SettlementsDispatch, getState: () => RootState): void => {
    const { sfCases, claims } = getState().settlements;

    const impactRequest = createImpactRequest(sfCases, claims);

    dispatch({
      type: s.GET_IMPACT_REPORT,
      payload: axios.post(`/api/settlements/impact-report/`, impactRequest, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
    });
  };
}

export function getProviderReport() {
  return (dispatch: SettlementsDispatch, getState: () => RootState): void => {
    const { sfCases, claims } = getState().settlements;

    const impactRequest = createImpactRequest(sfCases, claims);

    dispatch({
      type: s.GET_PROVIDER_REPORT,
      payload: axios.post(`/api/settlements/provider-report/`, impactRequest, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
    });
  };
}

export function createImpactRequest(
  sfCases: SFCase[],
  claims: SettlementsClaimSummary[]
): GetImpactRequest {
  const impactRequest: GetImpactRequest = {
    sfCaseNumbers: [],
    claimSettlementLookup: {},
  };
  claims.forEach(claim => {
    impactRequest.claimSettlementLookup[claim.id] = claim.proposedSettlementAmount;
  });
  sfCases.forEach(sfCase => {
    impactRequest.sfCaseNumbers.push(sfCase.caseNumber);
  });
  return impactRequest;
}

export function removeCase(caseNumber: string): SettlementsActionTypes {
  return {
    type: s.REMOVE_CASE,
    meta: { caseNumber },
  };
}

export function resetSettlements(): SettlementsActionTypes {
  return {
    type: s.RESET_SETTLEMENTS,
  };
}

export function updateSettlementAmount(claimID: string, amount: number): SettlementsActionTypes {
  return {
    type: s.UPDATE_SETTLEMENT_AMOUNT,
    meta: { claimID, amount },
  };
}

export function updateAllSettlementAmounts(): SettlementsActionTypes {
  return {
    type: s.UPDATE_ALL_SETTLEMENT_AMOUNTS,
  };
}

export function updateSettlementMethodology(
  methodology: SettlementMethodology
): SettlementsActionTypes {
  return {
    type: s.UPDATE_SETTLEMENT_METHODOLOGY,
    meta: { methodology },
  };
}

export function updateSettlementPercent(settlementPercent: number): SettlementsActionTypes {
  return {
    type: s.UPDATE_SETTLEMENT_PERCENT,
    meta: { settlementPercent },
  };
}

export function updateReportOptions(field: keyof DisplayReportOptions): SettlementsActionTypes {
  return {
    type: s.UPDATE_REPORT_DISPLAY_OPTIONS,
    meta: { field },
  };
}

export function updateMediVIChartOptions(
  field: keyof MediVIChartOptions,
  value: MediVIProvider | number | boolean
): SettlementsActionTypes {
  return {
    type: s.UPDATE_MEDIVI_CHART_OPTIONS,
    meta: { field, value },
  };
}

export function getProviderAutocomplete(searchText: string): SettlementsActionTypes {
  return {
    type: s.GET_PROVIDER_AUTOCOMPLETE,
    payload: axios.get(`/api/settlements/provider-autocomplete/${searchText}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getMediVIChart(
  ccn: string,
  radius: number,
  providerLimit: number
): SettlementsActionTypes {
  return {
    type: s.GET_MEDIVI_CHART,
    payload: axios.post(
      `/api/settlements/chart/`,
      { ccn, radius, providerLimit },
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}

export function toggleChartPrintView(): SettlementsActionTypes {
  return {
    type: s.TOGGLE_CHART_PRINT_VIEW,
  };
}

export function setSavedManualSettlementAmounts(
  amounts: Record<string, number>
): SettlementsActionTypes {
  return {
    type: s.SET_SAVED_MANUAL_SETTLEMENT_AMOUNTS,
    meta: { amounts },
  };
}

export function setURLSynced(isURLSynced: boolean): SettlementsActionTypes {
  return {
    type: s.SET_URL_SYNCED,
    meta: { isURLSynced },
  };
}
