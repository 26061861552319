import React, { useState, ChangeEvent, MouseEvent } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Popover from '@material-ui/core/Popover';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import { RootState } from '../../shared/types/types';
import { setFilteredTPAs } from './plansActions';
import { TPA } from './types/plans';

const useStyles = makeStyles({
  filterButton: {
    height: '50px',
  },
  buttonIcon: {
    marginLeft: '.25em',
  },
  checkbox: {
    marginRight: '0.5em',
  },
  formGroup: {
    padding: '1em',
  },
  formLabel: {
    height: '1.5rem',
  },
});

type StateProps = {
  filteredTPAs: number[];
  allTPAs: Record<number, TPA>;
};

const mapStateToProps = ({ plans }: RootState): StateProps => {
  const { allTPAs, filteredTPAs } = plans;
  return {
    allTPAs,
    filteredTPAs,
  };
};

const mapDispatchToProps = {
  setFilteredTPAs,
};

type Props = StateProps & typeof mapDispatchToProps;

export function TPAFilter({ allTPAs, filteredTPAs, setFilteredTPAs }: Props): JSX.Element {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const isAllSelected =
    Object.keys(allTPAs).length === filteredTPAs.length || filteredTPAs.length === 0;

  const isInFilterList = (tpaID: string): boolean => filteredTPAs.includes(+tpaID);

  const getTPAName = (tpaID: string): string =>
    tpaID === '' ? 'No Matching TPA' : allTPAs[+tpaID].tpaName;

  const handleAllTPAFilter = (): void => {
    setFilteredTPAs([]);
  };

  const handleTPACheckClick = (e: ChangeEvent<HTMLInputElement>): void => {
    const clickedTPAID = +e.currentTarget.value;
    if (filteredTPAs.includes(clickedTPAID)) {
      setFilteredTPAs(filteredTPAs.filter(tpaID => tpaID !== clickedTPAID));
    } else {
      setFilteredTPAs([...filteredTPAs, clickedTPAID]);
    }
  };

  const Icon = isOpen ? KeyboardArrowUp : KeyboardArrowDown;

  return (
    <>
      <Button
        className={classes.filterButton}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Filter By TPA
        <Icon className={classes.buttonIcon} />
      </Button>
      <Popover
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            className={classes.formLabel}
            control={<Checkbox value="All" checked={isAllSelected} onChange={handleAllTPAFilter} />}
            label={<Typography variant="body2">ALL TPAs</Typography>}
          />
          {Object.keys(allTPAs)
            .sort((a, b) => getTPAName(a).localeCompare(getTPAName(b)))
            .map(tpaID => (
              <FormControlLabel
                className={classes.formLabel}
                key={tpaID}
                control={
                  <Checkbox
                    value={tpaID}
                    checked={isInFilterList(tpaID)}
                    onChange={handleTPACheckClick}
                  />
                }
                label={<Typography variant="body2">{getTPAName(tpaID)}</Typography>}
              />
            ))}
        </FormGroup>
      </Popover>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TPAFilter);
