import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import { colors } from '@apps/shared/src/style';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1)}px`,
  },
  leftDivider: {
    borderLeft: `1px solid ${colors.grey17}`,
  },
  rightDivider: {
    borderRight: `1px solid ${colors.grey17}`,
  },
}));

export default function ClaimFooterItem(props) {
  const classes = useStyles();
  const { children, divider, visible = true } = props;

  if (!visible) return null;

  let dividerClass;
  if (divider === 'right') dividerClass = classes.rightDivider;
  if (divider === 'left') dividerClass = classes.leftDivider;

  return <div className={classNames(classes.item, dividerClass)}>{children}</div>;
}

ClaimFooterItem.defaultProps = {
  children: null,
  divider: '',
  visible: true,
};

ClaimFooterItem.propTypes = {
  children: PropTypes.node,
  divider: PropTypes.string,
  visible: PropTypes.bool,
};
