import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PlanImpactSummary from './PlanImpactSummary';
import ImpactClaims from './impactClaims/ImpactClaims';

import Chart from './chart/Chart';
import PDFHeader from './PDFHeader';
import PDFTopText from './PDFTopText';

import { PlanImpact } from './types/types';
import { RootState } from '../shared/types/types';
import AllCases from './cases/AllCases';

const useStyles = makeStyles({
  hardItem: {
    width: '1000px',
    height: '1px',
    color: 'white',
    fontSize: '1px',
    display: 'flex',
  },
  report: {
    padding: '1em',
    '@media print': {
      margin: '20px',
    },
  },
  printer: {
    breakInside: 'avoid',
    '@media print': {
      paddingTop: '30px',
      paddingBottom: '30px',
    },
  },
  printOnly: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
});

type StateProps = {
  planImpact?: PlanImpact;
  showPlanImpact: boolean;
};
const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    planImpact: settlements.planImpact,
    showPlanImpact: settlements.displayReportOptions.showPlanImpact,
  };
};

type Props = StateProps;

export function FinalReportItems({ planImpact, showPlanImpact }: Props): JSX.Element {
  const classes = useStyles();

  const hasPlanImpactReport = Boolean(
    showPlanImpact &&
      planImpact &&
      (planImpact.finalFacility.proposedSettlementAmount ||
        planImpact.finalProfessional.proposedSettlementAmount)
  );

  return (
    <div className={classes.report}>
      <PDFHeader />
      <PDFTopText />
      <div className={classes.hardItem}>&nbsp;</div>
      <div className={classes.printOnly}>
        <Typography variant="h5">Cases</Typography>
        <AllCases />
      </div>
      <div className={classes.printer}>{hasPlanImpactReport && <PlanImpactSummary />}</div>
      <div className={classes.printer}>
        <ImpactClaims />
      </div>
      <div className={classes.printer}>
        <Chart />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(FinalReportItems);
