import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import * as types from '../../shared/types/propTypes';
import { validArray } from '../../shared/typeChecks';

import TextInput from '../../shared/components/TextInput';
import { defaultPayerOverride } from './defaultTypes';
import {
  TableCellHeaderTop,
  Column,
  configSectionStyle,
  addButtonStyle,
  deleteButtonStyle,
  configIconStyle,
} from './sharedConfig';

const useStyles = makeStyles({
  configSection: configSectionStyle,
  configIcon: configIconStyle,
  addButton: addButtonStyle,
  deleteButton: deleteButtonStyle,
  claimInfoCell: {
    margin: '10px',
  },
  dropDown: {
    marginBottom: '20px',
  },
  dropDownHeader: {
    marginTop: '10px',
  },
});

export default function PayerOverrides({
  payerOverrides,
  handleTextChange,
  handleBoolChange,
  isReadOnly,
  handlePushToArray,
  handleRemoveFromArray,
  handleUnSetObject,
}) {
  const classes = useStyles();

  if (!payerOverrides) {
    return null;
  }

  const validPayerOverrides = validArray(payerOverrides);

  return (
    <Paper className={classes.claimInfoCell}>
      <TableCellHeaderTop>
        <Typography>
          <CallSplitIcon className={classes.configIcon} /> &nbsp; Payer Overrides
        </Typography>
        {!isReadOnly && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.deleteButton}
            onClick={handleUnSetObject('payerOverrides')}
          >
            <DeleteForeverIcon /> &nbsp; Override Config
          </Button>
        )}
      </TableCellHeaderTop>
      {!isReadOnly && (
        <TableCellHeaderTop>
          <Button
            variant="contained"
            color="secondary"
            className={classes.addButton}
            onClick={handlePushToArray('payerOverrides', defaultPayerOverride())}
          >
            <AddCircleIcon /> &nbsp; Payer Override
          </Button>
        </TableCellHeaderTop>
      )}
      <Column>
        <div className={classes.configSection}>
          {validPayerOverrides.map((payerOverride, i) => {
            return (
              <Paper className={classes.claimInfoCell} key={payerOverride}>
                <TableCellHeaderTop>
                  Payer Override #{i + 1}
                  {!isReadOnly && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.deleteButton}
                      onClick={handleRemoveFromArray('payerOverrides', i)}
                    >
                      <DeleteForeverIcon /> &nbsp; Override
                    </Button>
                  )}
                </TableCellHeaderTop>
                <Column>
                  <TextInput
                    readOnly={isReadOnly}
                    label="PayerID"
                    aria-label="PayerID"
                    fullWidth
                    onChange={handleTextChange(`payerOverrides.${i}.payerID`)}
                    value={payerOverride.payerID}
                  />
                  <TextInput
                    readOnly={isReadOnly}
                    label="RepriceSegmentName"
                    aria-label="RepriceSegmentName"
                    fullWidth
                    onChange={handleTextChange(`payerOverrides.${i}.repriceSegmentName`)}
                    value={payerOverride.repriceSegmentName}
                  />
                  <TextInput
                    readOnly={isReadOnly}
                    label="NameOverride"
                    aria-label="NameOverride"
                    fullWidth
                    onChange={handleTextChange(`payerOverrides.${i}.nameOverride`)}
                    value={payerOverride.nameOverride}
                  />
                  <Typography className={classes.dropDownHeader}>DoubleUsage:</Typography>
                  <FormControl className={classes.dropDown}>
                    <Select
                      autoWidth
                      readOnly={isReadOnly}
                      label="DoubleUsage"
                      aria-label="DoubleUsage"
                      onChange={handleBoolChange(`payerOverrides.${i}.doubleUsage`)}
                      value={payerOverride.doubleUsage ? 'true' : 'false'}
                    >
                      <MenuItem key={0} value="true">
                        True
                      </MenuItem>
                      <MenuItem key={1} value="false">
                        False
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Column>
              </Paper>
            );
          })}
        </div>
      </Column>
    </Paper>
  );
}

PayerOverrides.defaultProps = {
  payerOverrides: null,
};

PayerOverrides.propTypes = {
  payerOverrides: PropTypes.arrayOf(types.payerOverride),
  isReadOnly: PropTypes.bool.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleBoolChange: PropTypes.func.isRequired,
  handlePushToArray: PropTypes.func.isRequired,
  handleRemoveFromArray: PropTypes.func.isRequired,
  handleUnSetObject: PropTypes.func.isRequired,
};
