/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { User } from '../../auth/types';
import u, { AdminActionTypes, RoleArg } from './adminActionTypes';
import { AdminState } from './adminTypes';

export const defaultAdminState: AdminState = {
  users: [],
  appRoles: [],
  // Im not sure how much the app name matters it seems as if there is only really one user source but setting up for future segregation
  // eslint-disable-next-line no-nested-ternary
  // app: window.location.hostname.includes('claims')
  //   ? 'claims'
  //   : window.location.port === '3000'
  //   ? 'claims'
  //   : 'medivi',
  app: window.location.hostname.includes('claims') ? 'claims' : 'medivi',
  fetching: false,
};

const adminReducer = (
  state: AdminState = defaultAdminState,
  action: AdminActionTypes
): AdminState => {
  switch (action.type) {
    case u.GET_USERS_PENDING: {
      return { ...state, fetching: true };
    }
    case u.GET_USERS_REJECTED: {
      return { ...state, fetching: false };
    }
    case u.GET_USERS_FULFILLED: {
      const users = Array.isArray(action.payload.data) ? action.payload.data : [];
      const appRoles = state.app
        ? users
            .flatMap((i: User) => i.roles)
            .filter((role, index, acc) => role.startsWith(state.app) && acc.indexOf(role) === index)
            .sort()
        : [];
      // it seems we generate the appRoles array based off of what roles the users already have...this is hacky needs to be removed when roles are reworked.
      if (
        appRoles.length > 0 &&
        state.app === 'claims' &&
        !appRoles.includes('claims-contractsuperadmin')
      )
        appRoles.push('claims-contractsuperadmin');

      return { ...state, fetching: false, users, appRoles };
    }

    case u.INVITE_USER_PENDING: {
      return { ...state, fetching: true, userInvited: false };
    }
    case u.INVITE_USER_REJECTED:
      return { ...state, fetching: false, userInvited: false };
    case u.INVITE_USER_FULFILLED: {
      return { ...state, fetching: false, userInvited: true };
    }
    case u.CLEAR_INVITE_STATUS: {
      return { ...state, userInvited: undefined };
    }

    case u.ADD_ROLE_PENDING:
    case u.ADD_ROLE_REJECTED: {
      return { ...state };
    }
    case u.ADD_ROLE_FULFILLED: {
      const { userID, role }: RoleArg = action.meta;
      const userIndex = state.users!.findIndex(user => user.userID === userID);

      if (userIndex === -1 || state.users![userIndex].roles.includes(role)) return state;

      if (!state.users![userIndex].roles.includes(role)) {
        const tempArr = [...state.users!];
        const modifiedUser = tempArr.filter(user => user.userID === userID)[0];
        tempArr.map(user => user.userID === modifiedUser.userID && modifiedUser.roles.push(role));
        tempArr[userIndex] = modifiedUser;
        return {
          ...state,
          users: [...tempArr],
        };
      }
      return { ...state };
    }

    case u.REMOVE_ROLE_PENDING:
    case u.REMOVE_ROLE_REJECTED: {
      return { ...state };
    }
    case u.REMOVE_ROLE_FULFILLED: {
      const { userID, role }: RoleArg = action.meta;
      const userIndex = state.users!.findIndex(user => user.userID === userID);

      if (userIndex === -1 || !state.users![userIndex].roles.includes(role)) return state;

      if (state.users![userIndex].roles.includes(role)) {
        const tempArr = [...state.users!];
        const modifiedUser = tempArr.filter(user => user.userID === userID)[0];
        modifiedUser.roles = modifiedUser.roles.filter(r => r !== role);
        tempArr[userIndex] = modifiedUser;
        return {
          ...state,
          users: [...tempArr],
        };
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export default adminReducer;
