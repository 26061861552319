import React, { useState, useEffect } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import ContractMatchTableRow from './ContractMatchTableRow';
import ContractMatchButtons from './ContractMatchButtons';
import { allowContractSelection } from '../shared/status';
import { PartialContract } from './types/repricingTypes';
import { AppTheme } from '../shared/types/themeType';
import { ContractTypes } from '../contracts/types/contracts';

export const columnHeaders = ['Link', 'Type', 'Provider Group', 'Patients', 'Terms'];

export function checkHideableContracts(
  numUnselectedContracts: number,
  contractID: number | null
): boolean {
  return contractID !== null && numUnselectedContracts > 0;
}

export function partitionBySelectedID(
  contracts: PartialContract[],
  contractID: number | null
): { selectedContract: PartialContract | undefined; unselectedContracts: PartialContract[] } {
  let selectedContract;
  const unselectedContracts = [...contracts];
  const selectedContractIndex = contracts.findIndex((c: PartialContract) => c.id === contractID);
  if (selectedContractIndex >= 0)
    selectedContract = unselectedContracts.splice(selectedContractIndex, 1)[0];
  return { selectedContract, unselectedContracts };
}

type makeStylesProps = Pick<Props, 'color'>;

const useStyles = makeStyles<AppTheme, makeStylesProps>(theme => ({
  noContract: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  table: { ...theme.flagTable.table },
  tableHead: theme.flagTable.tableHead,
  actionCol: theme.flagTable.actionButtonCol,
}));

type Props = {
  contracts: PartialContract[];
  color: string;
  claimID: string;
  contractID: number | null;
  status: string;
  updateClaimContract: (
    claimID: string,
    contractID: number | null,
    providerGroup?: string,
    contractType?: ContractTypes
  ) => void;
};

export default function ContractMatchTable({
  contracts,
  color,
  claimID,
  contractID,
  status,
  updateClaimContract,
}: Props): JSX.Element {
  const classes = useStyles({ color });

  const { selectedContract, unselectedContracts } = partitionBySelectedID(contracts, contractID);
  const hasHideableContracts = checkHideableContracts(unselectedContracts.length, contractID);
  const [showHidden, setShowHidden] = useState(!hasHideableContracts);

  useEffect(() => {
    if (hasHideableContracts) setShowHidden(false);
  }, [selectedContract, hasHideableContracts]);

  const isSelectableStatus = allowContractSelection(status);
  const hasUserDeclinedMatch = contractID === 0;
  const shouldShowTable = !hasUserDeclinedMatch || showHidden;

  const handleSelectContract =
    (contractID: number | null, providerGroup?: string, contractType?: ContractTypes) =>
    (): void => {
      updateClaimContract(claimID, contractID, providerGroup, contractType);
    };
  const handleShowHidden: VoidFunction = () => setShowHidden(true);
  const handleHideUnselected: VoidFunction = () => setShowHidden(false);

  return (
    <TableContainer>
      {hasUserDeclinedMatch && (
        <Typography className={classes.noContract}>No Contract Used</Typography>
      )}
      {shouldShowTable && (
        <Table className={classes.table} data-testid="contractMatchTable">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columnHeaders.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
              {isSelectableStatus && <TableCell className={classes.actionCol}>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedContract && (
              <ContractMatchTableRow
                key={selectedContract.id}
                isEven={false}
                contract={selectedContract}
                color={color}
                showAllMatches={showHidden}
                isSelectable={isSelectableStatus}
                contractID={contractID}
                handleSelectContract={handleSelectContract}
              />
            )}
            {unselectedContracts.map((contract, i) => (
              <ContractMatchTableRow
                key={contract.id}
                isEven={(i + 1) % 2 === 0}
                contract={contract}
                color={color}
                showAllMatches={showHidden}
                isSelectable={isSelectableStatus}
                contractID={contractID}
                handleSelectContract={handleSelectContract}
              />
            ))}
          </TableBody>
        </Table>
      )}
      <ContractMatchButtons
        hasHideableContracts={hasHideableContracts}
        showHidden={showHidden}
        isSelectableStatus={isSelectableStatus}
        contractID={contractID}
        handleHideUnselected={handleHideUnselected}
        handleShowHidden={handleShowHidden}
        handleSelectContract={handleSelectContract}
      />
    </TableContainer>
  );
}
