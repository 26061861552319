import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import ProviderIcon from '@material-ui/icons/LocalHospital';

import { TopLevelCell } from '../chips/ChipBox';
import Providers from './Providers';
import SCAProviderFooter from './SCAProviderFooter';
import { addOrUpdateRoster, getOrCreateManualRoster } from '../contractsActions';
import { ContractDetail } from '../types/contracts';
import { RootState } from '../../shared/types/types';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '1em',
  },
  cardHeader: {
    padding: '1em',
    alignItems: 'center',
  },
  icon: {
    marginRight: '0.35em',
  },
}));

type ParentProps = {
  isEdit: boolean;
};

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    selectedContract: contracts.selectedContract,
    isRosterLoading: contracts.isRosterLoading,
    hasUnsavedRosterChanges: contracts.hasUnsavedRosterChanges,
    selectedRosterID: contracts.selectedRoster.id,
  };
};
type StateProps = {
  selectedContract: ContractDetail;
  isRosterLoading: boolean;
  hasUnsavedRosterChanges: boolean;
  selectedRosterID: number;
};

const mapDispatchToProps = {
  addOrUpdateRoster,
  getOrCreateManualRoster,
};
type DispatchProps = {
  addOrUpdateRoster: VoidFunction;
  getOrCreateManualRoster: VoidFunction;
};

type Props = ParentProps & StateProps & DispatchProps;

export function SCAProvider({
  isEdit,
  selectedContract,
  isRosterLoading,
  hasUnsavedRosterChanges,
  selectedRosterID,
  addOrUpdateRoster,
  getOrCreateManualRoster,
}: Props): JSX.Element {
  const classes = useStyles();

  useEffect(() => {
    getOrCreateManualRoster();
  }, [selectedContract.id, getOrCreateManualRoster]);

  return (
    <>
      <Card className={classes.container}>
        <TopLevelCell className={classes.cardHeader}>
          <ProviderIcon className={classes.icon} />
          Providers
        </TopLevelCell>
        <Providers isEdit={isEdit} />
        {isEdit && (
          <SCAProviderFooter
            addOrUpdateRoster={addOrUpdateRoster}
            hasUnsavedRosterChanges={hasUnsavedRosterChanges}
            isRosterLoading={isRosterLoading}
            isNewRoster={selectedRosterID === 0}
            handleCancelChanges={getOrCreateManualRoster}
          />
        )}
      </Card>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SCAProvider);
