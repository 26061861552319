import { createStyles, makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import React from 'react';
import classNames from 'classnames';
import { ClassNamesFn } from 'classnames/types';

type ParentProps = {
  isLoading: boolean;
  color?: string;
  size?: string | number;
  className?: ClassNamesFn;
};

const LoadingIndicator: React.FC<ParentProps> = (props): JSX.Element => {
  const { isLoading, color, size, className } = props;
  const useStyles = makeStyles(theme =>
    createStyles({
      loader: {
        border: `5px solid ${grey['200']}`,
        borderTop: `5px solid ${color}`,
        borderRadius: '50%',
        display: isLoading ? '' : 'none',
        width: spinnerSize,
        height: spinnerSize,
        animation: 'loading-indicator-spinner 1.5s linear infinite',
      },
    })
  );
  const spinnerSize = (size && +size) || 50;
  const styles = useStyles();
  return (
    <>
      <style>
        {`
            @keyframes loading-indicator-spinner {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }`}
      </style>
      <div className={classNames(styles.loader, className)} />
    </>
  );
};

export default LoadingIndicator;
