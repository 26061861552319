export const claimStatusCodes = {
  repriceNew: 'RepriceNew',
  repriceStarted: 'RepriceStarted',
  repriced: 'Repriced',
  miscalculation: 'Miscalculation',
  clinicalNew: 'ClinicalNew',
  clinicalStarted: 'ClinicalStarted',
  clinicalReviewed: 'ClinicalReviewed',
  flagged: 'Flagged',
  onHold: 'OnHold',
  verifyNew: 'VerifyNew',
  verifyStarted: 'VerifyStarted',
  verified: 'Verified',
  complete: 'Complete',
  error: 'Error',
  resend: 'Resend',
  dataEntry: 'DataEntry',
  dataEntryValidation: 'DataEntryValidation',
  dataEntryError: 'DataEntryError',
  // adjudicateNew: 'AdjudicateNew',
  // adjudicateStarted: 'AdjudicateStarted',
  // adjudicated: 'Adjudicated',
  // adjudicateMiscalculation: 'AdjudicateMiscalculation',
  // adjudicateDenied: 'AdjudicateDenied',
  // adjudicateFlagged: 'AdjudicateFlagged',
  // adjudicateHold: 'AdjudicateHold',
  // adjudicateVerifyNew: 'AdjudicateVerifyNew',
  // adjudicateVerifyStarted: 'AdjudicateVerifyStarted',
  // adjudicateVerified: 'AdjudicateVerified',
  researchNew: 'ResearchNew',
  researchStarted: 'ResearchStarted',
  researched: 'Researched',
  ineligible: 'Ineligible',
};

export const qpaZipCodeTypeStatus = {
  invalid: 'invalid',
  service: 'service',
  billing: 'billing',
};

export const contractStatusCodes = {
  dataEntry: 'DataEntry', // under dataEnterer's ownership
  active: 'Active',
};

export const filterGroups = {
  repricing: [
    claimStatusCodes.repriceNew,
    claimStatusCodes.repriceStarted,
    claimStatusCodes.repriced,
    claimStatusCodes.miscalculation,
    claimStatusCodes.clinicalNew,
    claimStatusCodes.clinicalStarted,
    claimStatusCodes.clinicalReviewed,
    claimStatusCodes.flagged,
    claimStatusCodes.onHold,
    claimStatusCodes.verifyNew,
    claimStatusCodes.verifyStarted,
    claimStatusCodes.verified,
    claimStatusCodes.researchNew,
    claimStatusCodes.researchStarted,
    claimStatusCodes.researched,
    claimStatusCodes.ineligible,
  ],
  // adjudication: [
  //   claimStatusCodes.adjudicateNew,
  //   claimStatusCodes.adjudicateStarted,
  //   claimStatusCodes.adjudicated,
  //   claimStatusCodes.adjudicateMiscalculation,
  //   claimStatusCodes.adjudicateVerifyNew,
  //   claimStatusCodes.adjudicateVerifyStarted,
  //   claimStatusCodes.adjudicateVerified,
  //   claimStatusCodes.adjudicateFlagged,
  //   claimStatusCodes.adjudicateHold,
  //   claimStatusCodes.adjudicateDenied,
  // ],
  manualClaims: [
    claimStatusCodes.dataEntry,
    claimStatusCodes.dataEntryValidation,
    claimStatusCodes.dataEntryError,
  ],
};

export function getMenuItems(status) {
  switch (status) {
    case claimStatusCodes.miscalculation:
      return { primary: claimStatusCodes.repriceStarted };
    case claimStatusCodes.clinicalNew:
      return { primary: claimStatusCodes.clinicalStarted };
    case claimStatusCodes.clinicalStarted:
      return {
        primary: claimStatusCodes.clinicalReviewed,
        options: [claimStatusCodes.clinicalNew],
      };
    case claimStatusCodes.clinicalReviewed:
      return {
        primary: claimStatusCodes.repriceNew,
        options: [claimStatusCodes.clinicalStarted, claimStatusCodes.verifyNew],
      };
    case claimStatusCodes.flagged:
      return {
        primary: claimStatusCodes.verifyNew,
        options: [
          claimStatusCodes.clinicalNew,
          claimStatusCodes.repriceNew,
          claimStatusCodes.researchNew,
        ],
      };
    case claimStatusCodes.onHold:
      return {
        primary: claimStatusCodes.verifyNew,
        options: [
          claimStatusCodes.clinicalNew,
          claimStatusCodes.repriceNew,
          claimStatusCodes.researchNew,
          claimStatusCodes.error,
        ],
      };
    case claimStatusCodes.repriced:
      return {
        primary: claimStatusCodes.verifyNew,
        options: [claimStatusCodes.clinicalNew, claimStatusCodes.repriceStarted],
      };
    default:
    case claimStatusCodes.repriceNew:
      return {
        primary: claimStatusCodes.repriceStarted,
        options: [
          claimStatusCodes.clinicalNew,
          // claimStatusCodes.adjudicateNew,
          claimStatusCodes.researchNew,
        ],
      };
    case claimStatusCodes.repriceStarted:
      return {
        primary: claimStatusCodes.repriced,
        options: [
          claimStatusCodes.clinicalNew,
          // claimStatusCodes.adjudicateNew,
          claimStatusCodes.repriceNew,
        ],
      };
    case claimStatusCodes.verified:
    case claimStatusCodes.verifyNew:
      return {
        primary: claimStatusCodes.verifyStarted,
        options: [
          claimStatusCodes.clinicalNew,
          // claimStatusCodes.adjudicateNew,
          claimStatusCodes.repriceNew,
        ],
      };
    case claimStatusCodes.verifyStarted:
      return {
        primary: claimStatusCodes.verified,
        options: [
          claimStatusCodes.clinicalNew,
          // claimStatusCodes.adjudicateNew,
          claimStatusCodes.miscalculation,
          claimStatusCodes.repriceNew,
          claimStatusCodes.verifyNew,
          claimStatusCodes.error,
        ],
      };
    // case claimStatusCodes.adjudicateNew:
    //   return { primary: claimStatusCodes.adjudicateStarted };
    // case claimStatusCodes.adjudicateStarted:
    //   return { primary: claimStatusCodes.adjudicated, options: [claimStatusCodes.adjudicateNew] };
    // case claimStatusCodes.adjudicated:
    //   return {
    //     primary: claimStatusCodes.adjudicateVerifyNew,
    //     options: [claimStatusCodes.adjudicateStarted],
    //   };
    // case claimStatusCodes.adjudicateMiscalculation:
    //   return { primary: claimStatusCodes.adjudicateStarted };
    // case claimStatusCodes.adjudicateVerifyNew:
    //   return {
    //     primary: claimStatusCodes.adjudicateVerifyStarted,
    //     options: [claimStatusCodes.adjudicateNew],
    //   };
    // case claimStatusCodes.adjudicateVerifyStarted:
    //   return {
    //     primary: claimStatusCodes.adjudicateVerified,
    //     options: [claimStatusCodes.adjudicateVerifyNew, claimStatusCodes.adjudicateMiscalculation],
    //   };
    // case claimStatusCodes.adjudicateVerified:
    //   return {
    //     primary: claimStatusCodes.repriceNew,
    //     options: [
    //       claimStatusCodes.clinicalNew,
    //       claimStatusCodes.adjudicateDenied,
    //       claimStatusCodes.adjudicateVerifyStarted,
    //     ],
    //   };
    // case claimStatusCodes.adjudicateFlagged:
    //   return {
    //     primary: claimStatusCodes.adjudicateNew,
    //     options: [claimStatusCodes.adjudicateVerifyNew],
    //   };
    // case claimStatusCodes.adjudicateHold:
    //   return {
    //     primary: claimStatusCodes.adjudicateNew,
    //     options: [claimStatusCodes.adjudicateVerifyNew],
    //   };
    // case claimStatusCodes.adjudicateDenied:
    //   return {
    //     primary: claimStatusCodes.adjudicateVerifyStarted,
    //     options: [claimStatusCodes.adjudicateNew],
    //   };
    case claimStatusCodes.researchNew:
      return { primary: claimStatusCodes.researchStarted };
    case claimStatusCodes.researchStarted:
      return {
        primary: claimStatusCodes.researched,
        options: [claimStatusCodes.ineligible, claimStatusCodes.researchNew],
      };
    case claimStatusCodes.researched:
      return {
        primary: claimStatusCodes.repriceNew,
        options: [claimStatusCodes.ineligible, claimStatusCodes.researchStarted],
      };
    case claimStatusCodes.ineligible:
      return { primary: claimStatusCodes.researchNew };
    case claimStatusCodes.complete:
    case claimStatusCodes.ineligibleComplete:
    case claimStatusCodes.error:
      return { primary: claimStatusCodes.verified };
  }
}

export function isStatusNew(status) {
  return (
    status === claimStatusCodes.repriceNew ||
    status === claimStatusCodes.clinicalNew ||
    // status === claimStatusCodes.adjudicateNew ||
    status === claimStatusCodes.researchNew
  );
}

export function isStatusResearch(status) {
  return (
    status === claimStatusCodes.researchNew ||
    status === claimStatusCodes.researchStarted ||
    status === claimStatusCodes.researched
  );
}

export function isStatusClinical(status) {
  return (
    status === claimStatusCodes.clinicalNew ||
    status === claimStatusCodes.clinicalStarted ||
    status === claimStatusCodes.clinicalReviewed
  );
}

export function allowContractSelection(status) {
  return status === claimStatusCodes.repriceStarted || status === claimStatusCodes.verifyStarted;
}
