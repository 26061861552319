import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { colors } from '@apps/shared/src/style';
import { updateReportOptions } from './settlementsActions';
import { SettlementsClaimSummary, DisplayReportOptions } from './types/types';
import { RootState } from '../shared/types/types';

const useStyles = makeStyles({
  container: {
    marginRight: '160px',
  },
  greyText: {
    color: colors.greyDark,
  },
  switches: {
    display: 'flex',
    flexDirection: 'column',
  },
});

type StateProps = {
  displayReportOptions: DisplayReportOptions;
  claims: SettlementsClaimSummary[];
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    displayReportOptions: settlements.displayReportOptions,
    claims: settlements.claims,
  };
};

const mapDispatchToProps = {
  updateReportOptions,
};

type DispatchProps = {
  updateReportOptions: (field: keyof DisplayReportOptions) => void;
};

type Props = StateProps & DispatchProps;

export function ReportOptions({
  displayReportOptions,
  updateReportOptions,
  claims,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (claims.length === 0) return null;

  const { showCostMultiple, showChart, showPlanImpact } = displayReportOptions;

  const handleToggle = (field: keyof DisplayReportOptions) => (): void => {
    updateReportOptions(field);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5">Display Options:</Typography>

      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={showCostMultiple} onChange={handleToggle('showCostMultiple')} />
          }
          label="Show Cost Multiple"
        />
        <FormControlLabel
          control={<Switch checked={showChart} onChange={handleToggle('showChart')} />}
          label="Show MediVI Chart"
        />
        <FormControlLabel
          control={<Switch checked={showPlanImpact} onChange={handleToggle('showPlanImpact')} />}
          label="Show Plan Impact"
        />
      </FormGroup>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportOptions);
