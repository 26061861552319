import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import { formatDate } from '../contractsReducer';

import { formatArrayStrings } from '../contractUtilities';
import { ContractPatient } from '../types/contracts';

const useStyles = makeStyles({
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal',
    alignItems: 'flex-start',
  },
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  person: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  dob: {
    paddingLeft: '1em',
    fontSize: '.85em',
    color: colors.greyDark,
  },
  memberID: {
    paddingLeft: '1em',
  },
  details: {
    fontSize: '.85em',
    color: colors.greyDark,
  },
});

type Props = {
  option: ContractPatient;
  index: number;
  highlightedIndex: number;
  getItemProps: (obj: object) => void;
};
export default function PatientOption({
  option,
  index,
  highlightedIndex,
  getItemProps,
}: Props): JSX.Element {
  const classes = useStyles();
  const { id, name, dob, memberID, address } = option;
  return (
    <MenuItem
      {...getItemProps({
        item: option,
        key: id,
        component: 'div',
        dense: true,
        selected: highlightedIndex === index,
        className: classes.menuItem,
      })}
    >
      <div className={classes.firstRow}>
        <div className={classes.person}>
          <Typography variant="body2" noWrap>
            <strong>{inflateString(name)}</strong>
          </Typography>
          {dob && (
            <Typography variant="body2" noWrap className={classes.dob}>
              DOB: {formatDate(dob)}
            </Typography>
          )}
        </div>
        {memberID && (
          <Typography variant="body2" className={classes.memberID}>
            MEMBER ID: {memberID}
          </Typography>
        )}
      </div>
      {address && (
        <Typography variant="body2" noWrap className={classes.details}>
          {formatArrayStrings(address)}
        </Typography>
      )}
    </MenuItem>
  );
}
