import React, { useState, useEffect, FormEventHandler } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DateInput from '@apps/shared/src/components/DateInput';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import { RootState } from '../../shared/types/types';
import { ExceptionsReportDates, Exception, ExceptionsReportExcludedTPA } from '../types';
import ExceptionsTable from './ExceptionsTable';
import ExclusionsTable from './ExclusionsTable';
import { getExceptionsReport, getTPAExclusions } from '../reportsActions';

const useStyles = makeStyles({
  container: {
    padding: '2rem',
    boxSizing: 'border-box',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formGroup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    margin: '0 0.5rem 0.5rem 0',
  },
  submitButton: {
    margin: '0 0.5rem 0.5rem 0',
    alignSelf: 'flex-end',
  },
  captions: {
    marginBottom: '0.5rem',
    '& > *': {
      display: 'block',
    },
  },
  description: {
    marginBottom: '0.5rem',
  },
  exclusions: {
    marginTop: '3rem',
  },
});

const mapDispatchToProps = {
  getExceptionsReport,
  getTPAExclusions,
};

type StateProps = {
  exceptions: Exception[];
  exclusions: ExceptionsReportExcludedTPA[];
  isLoading: boolean;
};

const mapStateToProps = ({ reports: { exceptionsReport, loading } }: RootState): StateProps => ({
  exceptions: exceptionsReport?.exceptions || [],
  exclusions: exceptionsReport?.exclusions || [],
  isLoading: loading || false,
});

type Props = StateProps & typeof mapDispatchToProps;

export function Exceptions({
  exceptions,
  exclusions,
  isLoading,
  getExceptionsReport,
  getTPAExclusions,
}: Props): JSX.Element {
  const classes = useStyles();
  const [exceptionsDates, setExceptionsDates] = useState<ExceptionsReportDates>({});

  useEffect(() => {
    getTPAExclusions();
  }, [getTPAExclusions]);

  const handleStartDateChange: (dateValue: string) => void = date => {
    setExceptionsDates(dates => ({ ...dates, start: date }));
  };

  const handleEndDateChange: (dateValue: string) => void = date => {
    setExceptionsDates(dates => ({ ...dates, end: date }));
  };

  const handleSubmit: FormEventHandler<Element> = e => {
    e.preventDefault();
    getExceptionsReport(exceptionsDates.start, exceptionsDates.end);
  };

  return (
    <div className={classes.container}>
      <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.formGroup}>
          <DateInput
            className={classes.input}
            name="startDate"
            label="Start date"
            value={exceptionsDates.start || ''}
            isDefaultNative
            onChange={handleStartDateChange}
          />
          <DateInput
            className={classes.input}
            name="endDate"
            label="End date"
            value={exceptionsDates.end || ''}
            isDefaultNative
            onChange={handleEndDateChange}
          />
        </div>
        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          Submit
        </Button>
      </ValidatorForm>
      <div className={classes.captions}>
        <Typography variant="caption" color="primary">
          Start date defaults to one week before end date
        </Typography>
        <Typography variant="caption" color="primary">
          End date defaults to today
        </Typography>
      </div>
      <Typography variant="body2" className={classes.description}>
        Report to generate a list of TPAs we have not received a file from within the given date
        range.
      </Typography>
      {exceptions.length > 0 && <ExceptionsTable exceptions={exceptions} />}
      <LoadingSpinner isLoading={isLoading} />
      <div className={classes.exclusions}>
        <Typography variant="h6">Excluded TPAs</Typography>
        <Typography variant="body2" className={classes.description}>
          List of TPAs not considered in report because we do not regularly expect files from them.
        </Typography>
        <ExclusionsTable exclusions={exclusions} />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Exceptions);
