/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/styles/makeStyles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { RootState } from '../shared/types/types';
import { getClaim, updateClaim } from './claimsActions';
import { SBIData } from './types/repricingTypes';
import { required } from '../shared/validators';

const useStyles = makeStyles({
  label: {
    '&>span': {
      fontSize: 'inherit',
    },
  },
  checkbox: {
    padding: 0,
    display: 'flex',
    placeContent: 'flex-start',
  },
});

type StateProps = {
  claimID: string;
  isSurpriseBill: boolean;
  SBIData: SBIData;
};

function mapStateToProps({ claims }: RootState): StateProps {
  return {
    claimID: claims.selectedClaim.id,
    isSurpriseBill: claims.selectedClaim.simpleClaim.surpriseBillIndicator || false,
    SBIData: claims.selectedClaim.simpleClaim.sbi || { sbiCode: '', sbiReason: '' },
  };
}

type DispatchProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateClaim: (updateInfo: any) => void;
  getClaim: (id: any) => void;
  // updateRepricing: (updateInfo: any) => void
};

const mapDispatchToProps = { updateClaim, getClaim };

type ParentProps = {
  claimID: string;
  isSurpriseBill?: boolean;
  SBIData: SBIData;
};

type Props = StateProps & DispatchProps & ParentProps;

export function SurpriseBillIndicator({
  claimID,
  isSurpriseBill,
  updateClaim,
  SBIData,
}: Props): JSX.Element {
  const classes = useStyles();
  const [sbiReasonText, setSbiReasonText] = React.useState<string>('');
  const [toggleDialog, setToggleDialog] = React.useState<boolean>(false);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [allowReasonEdit, setAllowReasonEdit] = React.useState<boolean>(false);
  const [sbiCodeText, setSbiCodeText] = React.useState<string>('');
  const [sbiReasonTextErr, setSbiReasonTextErr] = React.useState<string>('');

  // This function is triggered when the select changes
  // const selectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
  //   const { value } = event.target;
  //   const code = value.slice(0, 3);
  //   const reason = value.slice(4);
  //   if (code === '000') setToggleDialog(true);
  //   else {
  //     updateClaim({
  //       claimID,
  //       fieldName: 'simpleClaim.sbi.sbiCode',
  //       stringVal: code,
  //     });
  //     updateClaim({
  //       claimID,
  //       fieldName: 'simpleClaim.sbi.sbiReason',
  //       stringVal: reason,
  //     });
  //   }
  //   setAllowReasonEdit(false);
  // };

  const handleEditSbi = (): void => {
    if (isSurpriseBill) {
      setSbiReasonText('');
      setSbiCodeText('');
      updateClaim({
        claimID,
        fieldName: 'simpleClaim.surpriseBillIndicator',
        boolVal: !isSurpriseBill,
        clearRepriceAndQpa: true,
      });
      updateClaim({
        claimID,
        fieldName: 'simpleClaim.sbi.sbiCode',
        stringVal: '',
      });
      updateClaim({
        claimID,
        fieldName: 'simpleClaim.sbi.sbiReason',
        stringVal: '',
      });
      getClaim(claimID);
    } else {
      setToggleDialog(true);
    }
    getClaim(claimID);
    setShowConfirm(false);
    setAllowReasonEdit(true);
  };

  const handleClose = (): void => {
    setToggleDialog(false);
    setShowConfirm(false);
    setAllowReasonEdit(false);
  };

  /* eslint-disable no-param-reassign */
  // submits user inputted sbiReason
  const handleReasonSubmit = (): void => {
    if (sbiReasonText.length > 50 || sbiReasonText === '') {
      setSbiReasonTextErr('Reason must not be empty or should not exceed 50 char.');
      return;
    }
    setToggleDialog(false);
    SBIData.sbiReason = '';
    updateClaim({
      claimID,
      fieldName: 'simpleClaim.surpriseBillIndicator',
      boolVal: !isSurpriseBill,
    });

    updateClaim({
      claimID,
      fieldName: 'simpleClaim.sbi.sbiCode',
      stringVal: sbiCodeText,
    });
    updateClaim({
      claimID,
      fieldName: 'simpleClaim.sbi.sbiReason',
      stringVal: sbiReasonText,
    });
    setSbiReasonTextErr('');
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSbiReasonText(value);
    setSbiCodeText('000');
    SBIData.sbiReason = sbiReasonText;
    SBIData.sbiCode = sbiCodeText;

    setAllowReasonEdit(false);
  };
  /* eslint-enable no-param-reassign */

  const openConfirm = (): void => {
    setShowConfirm(true);
  };

  return (
    <>
      <Dialog open={showConfirm} onClose={handleClose}>
        <DialogTitle>Edit SBI</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to edit the Surprise Bill Indicator checkbox and reason code
            dropdown?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleEditSbi}>Agree</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={toggleDialog} onClose={handleClose}>
        <DialogTitle>code: 000</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a reason for the surprise bill indicator.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            onChange={handleReasonChange}
            id="sbiReason standard-error-helper-text"
            label="SBI Reason*"
            type="text"
            fullWidth
            variant="standard"
            error={sbiReasonTextErr !== ''}
            helperText={sbiReasonTextErr}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleReasonSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Checkbox
        className={classes.checkbox}
        id="surprise-bill-indicator"
        value="simpleClaim.surpriseBillIndicator"
        checked={isSurpriseBill}
        onChange={openConfirm}
      />
      <div>
        {isSurpriseBill && (
          <span id="sbi-reason-select-option1">{`${SBIData.sbiCode || sbiCodeText} ${
            SBIData.sbiReason || sbiReasonText
          }`}</span>
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SurpriseBillIndicator);
