import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import AddCaseForm from './AddCaseForm';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    padding: '1em',
    maxWidth: '1400px',
  },
  title: {
    fontSize: '1.5em',
  },
});

export default function Settlements(): JSX.Element {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <Typography variant="h5">Settlement Impact Report</Typography>
      <AddCaseForm />
    </header>
  );
}
