import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import BlockIcon from '@material-ui/icons/Block';

import {
  takeDuplicateAction,
  copyClaimWorker,
  denyCPPDuplicateClaimWorker,
} from '../../../repricing/claimsActions';

const useStyles = makeStyles({
  buttonIcon: {
    marginLeft: '0.25em',
    fontSize: '1.25em',
  },
});

export function DupeTableAction({ matchIsCashPrePay, matchID, takeDuplicateAction }) {
  const classes = useStyles();

  const actionTypes = {
    copy: {
      text: 'COPY',
      icon: <ContentCopyIcon className={classes.buttonIcon} />,
      worker: copyClaimWorker,
    },
    denyCPP: {
      text: 'Deny CPP Duplicate',
      icon: <BlockIcon className={classes.buttonIcon} />,
      worker: denyCPPDuplicateClaimWorker,
    },
  };

  const { text, icon, worker } = matchIsCashPrePay ? actionTypes.denyCPP : actionTypes.copy;

  return (
    <Button variant="text" size="small" onClick={() => takeDuplicateAction(worker, matchID)}>
      {text}
      {icon}
    </Button>
  );
}

DupeTableAction.propTypes = {
  matchIsCashPrePay: PropTypes.bool.isRequired,
  matchID: PropTypes.string.isRequired,
  takeDuplicateAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  takeDuplicateAction,
};

export default connect(null, mapDispatchToProps)(DupeTableAction);
