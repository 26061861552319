import { CSSProperties } from 'react';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import { termItems, ruleTypeList, conditionTypeList } from './terms/termElements';
import { contractStatusCodes as statusCodes } from '../shared/status';

import { validArray } from '../shared/typeChecks';
import {
  ContractDetail,
  ContractStatus,
  TermItem,
  ListItem,
  ContractProvider,
  ContractRoster,
  ContractError,
  ContractTypes,
} from './types/contracts';
import { isSimpleUser } from '../shared/types/typeGuards';

export function contractStatusColor(status: ContractStatus): CSSProperties['color'] {
  switch (status) {
    case statusCodes.dataEntry:
      return colors.orange;
    case statusCodes.active:
      return colors.green;
    default:
      return colors.grey37;
  }
}

export function dateRangeToText(start: string, end: string): string {
  if (!start && !end) return '';
  if (start && end) return `${start} \u2014 ${end}`;
  if (start && !end) return `after ${start}`;
  return `until ${end}`;
}

export function pluralize(noun: string, count: number, suffix = 's'): string {
  return `${noun}${count !== 1 ? suffix : ''}`;
}

export function modifyList(prefix: string): (item: ListItem, index: number) => ListItem {
  return (item: ListItem, index: number): ListItem => {
    return index === 0 ? { ...item, name: `${prefix} ${item.name}` } : item;
  };
}

export function isRangeElement(item: TermItem): boolean {
  return [termItems.startRange, termItems.endRange, termItems.rangeInclusive].includes(item);
}

export function orderTermItems(
  selectedItems: TermItem[],
  lists: { value: string; name: string }[][]
): TermItem[] {
  const orderedItems: TermItem[] = [];
  validArray(lists).forEach(list => {
    validArray(selectedItems).forEach(item => {
      validArray(list).forEach(type => {
        if (item === inflateString(type.value)) orderedItems.push(item);
      });
    });
  });
  validArray(selectedItems).forEach(item => {
    if (!orderedItems.includes(item)) orderedItems.push(item);
  });
  return orderedItems;
}

export function getValueFromKey(key: string) {
  return (obj: Record<string, string>): string => {
    return obj[key] ? obj[key] : '';
  };
}

export function getCodeConfig(type: string): {
  allowedCharRegex: string | undefined;
  maxLength: number | undefined;
} {
  let allowedCharRegex, maxLength;
  if (type === termItems.hcpcsOrCPT) {
    allowedCharRegex = `a-zA-Z0-9`;
    maxLength = 5;
  }
  if (type === termItems.revCode) {
    allowedCharRegex = `0-9`;
    maxLength = 4;
  }
  return { allowedCharRegex, maxLength };
}

export function removeMatchingItem<Item>(items: Item[], element: Item): void {
  const i = items.indexOf(element);
  if (i >= 0) items.splice(i, 1);
}

export function isArrayIndexValid<Item>(index: number, array: Item[]): boolean {
  return Number.isSafeInteger(index) && index >= 0 && index < array.length;
}
export function isBadItem<Item>(item: Item): boolean {
  return item === undefined || item === null;
}
export function removeItemAtIndex<Item>(items: Item[], index: number): Item[] {
  if (!isArrayIndexValid(index, items)) return items;
  return [...items.slice(0, index), ...items.slice(index + 1)];
}

export function insertItemIntoList<Item>(items: Item[], item: Item): Item[] {
  if (isBadItem(item)) return items;
  return items.concat(item);
}

export function splitSelectedTermItems(selectedTermItems: TermItem[]): {
  selectedRules: TermItem[];
  selectedConditions: TermItem[];
} {
  const selectedRules = selectedTermItems.filter(i => ruleTypeList.includes(i));
  const selectedConditions = selectedTermItems.filter(i => conditionTypeList.includes(i));
  return { selectedRules, selectedConditions };
}

export function formatArrayStrings(str: string): string {
  return inflateString(str)
    .split(',')
    .map(s => s.trim())
    .filter(s => s)
    .join(', ')
    .trim();
}

export function removeNonDigitCharacters(str: string): string {
  return str.replace(/[^\d]/g, '');
}

export function checkProviderCanBeSaved(provider: ContractProvider): boolean {
  return !provider.npiError && !provider.taxIDError && isNonEmptyProvider(provider);
}

export function checkAllProvidersCanBeSaved(providers: ContractProvider[]): boolean {
  return providers.every(checkProviderCanBeSaved);
}

export function getNPIError(npi: string): string {
  if (npi.length === 0) return '';
  if (npi.length !== 10) return 'NPI must be 10 digits';
  if (npi[0] !== '1') return 'NPI must start with 1';
  return '';
}

export function getTaxIDError(taxID: string): string {
  if (taxID.length !== 9 && taxID.length !== 0) return 'TaxID must be 9 digits';
  return '';
}

export function isNonEmptyProvider({ npi, taxID }: ContractProvider): boolean {
  return Boolean(npi || taxID);
}

export function getNonEmptyProviders(providers: ContractProvider[]): ContractProvider[] {
  return providers.filter(isNonEmptyProvider);
}

export function getManualRoster(contract: ContractDetail): ContractRoster | null {
  for (let i = 0; i < contract.rosters.length; i++) {
    if (contract.rosters[i].isManual) {
      return contract.rosters[i];
    }
  }
  return null;
}

type ProviderNameCheck = {
  isMissingName: boolean;
  displayName: string;
};
export function evaluateProviderName(name: string, npi: string): ProviderNameCheck {
  const isMissingName = Boolean(npi && !getNPIError(npi) && !name);
  const displayName = isMissingName ? 'No provider name found for NPI' : name;
  return { isMissingName, displayName };
}

export function getContractVisibilities(
  isContractor: boolean,
  isContractLoading: boolean,
  contract: ContractDetail,
  userID: string,
  urlID?: string
): {
  isEdit: boolean;
  isNew: boolean;
  shouldShowUndefinedError: boolean;
  shouldShowURLError: boolean;
} {
  const isEdit =
    isContractor &&
    contract.status === statusCodes.dataEntry &&
    isSimpleUser(contract.owner) &&
    contract.owner.userID === userID;
  const isUndefinedURL = urlID === undefined;
  const isBadURL = !isUndefinedURL && contract.id !== 0 && contract.id !== Number(urlID);
  const isNew = isUndefinedURL && isContractor;
  const shouldShowUndefinedError = !isContractLoading && isUndefinedURL && !isContractor;
  const shouldShowURLError = !isContractLoading && isBadURL;

  return {
    isEdit,
    isNew,
    shouldShowUndefinedError,
    shouldShowURLError,
  };
}

export function getErrorMessage(isUndefined: boolean, isInvalid: boolean): ContractError {
  if (isUndefined)
    return {
      title: 'No Contract Selected',
      message: 'Please select a contract from the list to proceed',
    };
  if (isInvalid)
    return {
      title: 'Invalid URL',
      message: 'Please verify the URL or select a contract from the list to proceed',
    };
  return {
    title: 'Error Loading Contract',
    message:
      'Please verify the URL, verify your permissions, or select a contract from the list to proceed',
  };
}

export function getContractSpecifics(
  isContractor: boolean,
  isContractLoading: boolean,
  contract: ContractDetail,
  userID: string,
  id?: string
): {
  isEdit: boolean;
  isNew: boolean;
  contractError?: ContractError;
} {
  const { isEdit, isNew, shouldShowUndefinedError, shouldShowURLError } = getContractVisibilities(
    isContractor,
    isContractLoading,
    contract,
    userID,
    id
  );
  let contractError;
  if (shouldShowURLError || shouldShowUndefinedError)
    contractError = getErrorMessage(shouldShowUndefinedError, shouldShowURLError);

  return {
    isEdit,
    isNew,
    contractError,
  };
}

export function checkContractRoles(roles: string[]): Record<string, boolean> {
  return {
    isContractor: roles.includes('claims-contract'),
    isContractAdmin: roles.includes('claims-contractadmin'),
  };
}

// can take ownership of any contract in dataEntry
export function isSuperClaimsContractUser(roles: string[]): boolean {
  return roles.includes('claims-contractsuperadmin');
}

export function checkIsSCAorLOA(contractType: ContractTypes): boolean {
  return contractType === ContractTypes.SingleCase || contractType === ContractTypes.LOA;
}
