import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import { colors } from '@apps/shared/src/style';
import { checkRoles } from '@apps/shared/src/auth/util';
import { isArrayFilled } from '../shared/typeChecks';

const useStyles = makeStyles({
  reportList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '2rem 1rem 0',
    minWidth: '175px',
  },
  button: {
    marginTop: '5px',
  },
  active: {
    background: colors.grey17,
  },
  // needed to get small "Plans" button to align with others
  smallButton: {
    minWidth: 'unset',
  },
  hidden: {
    display: 'none',
  },
});

const reportButtons = [
  { label: 'All Claims', path: 'all-claims' },
  { label: 'Claims Aging', path: 'claims-aging' },
  { label: 'Duplicate Claims', path: 'duplicates' },
  { label: 'Daily Claims', path: 'daily-claims' },
  { label: 'In-Process Claims', path: 'in-process' },
  { label: 'Patients', path: 'patients' },
  { label: 'Plans', path: 'plans', isSmall: true },
  { label: 'Batches', path: 'batches' },
  { label: 'Advanced Batches', path: 'advanced-batches' },
  { label: 'Settlements', path: 'settlements' },
  { label: 'Monthly Reporting', path: 'monthly-reporting', allowedRoles: ['claims-monthlyreport'] },
  { label: '% Savings Report', path: 'invoices' },
  { label: 'Claim Traffic', path: 'claim-traffic' },
  { label: 'Exceptions Report', path: 'exceptions' },
];

function ReportsList({ currentUserRoles }) {
  const classes = useStyles();
  const isButtonHidden = allowedRoles =>
    isArrayFilled(allowedRoles) && !checkRoles(currentUserRoles, allowedRoles);

  return (
    <nav className={classes.reportList}>
      {reportButtons.map(({ label, path, isSmall, allowedRoles }) => (
        <Button
          key={label}
          component={NavLink}
          to={`/reports/${path}`}
          classes={{ root: isSmall ? classes.smallButton : '' }}
          className={isButtonHidden(allowedRoles) ? classes.hidden : classes.button}
          activeClassName={classes.active}
        >
          {label}
        </Button>
      ))}
    </nav>
  );
}

ReportsList.defaultProps = {
  currentUserRoles: [],
};
ReportsList.propTypes = {
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
};

function mapStateToProps({ user }) {
  return {
    currentUserRoles: user.currentUser.roles,
  };
}

export default connect(mapStateToProps)(ReportsList);
