export const childReducer = (state, action, nameToReducerMap) => {
  const components = action && action.type && action.type[0] !== '@' ? action.type.split('/') : [];
  if (components.length !== 2) return state;

  const stateCopy = { ...state };
  let current = stateCopy;
  const branches = components[0].split('.');
  for (let i = 0; i < branches.length; i++) {
    const name = branches[i];
    const isLast = i === branches.length - 1;
    const newState = name.includes('[')
      ? getArrayState(current, action, components[1], name, isLast, nameToReducerMap)
      : getPropState(current, action, components[1], name, isLast, nameToReducerMap);
    if (newState === undefined) return state;
    current[newState.name] = newState.state;
    current = newState.state;
    if (newState.index !== undefined) {
      // set current to array item
      current = current[newState.index];
    }
  }
  return stateCopy;
};

export const getArrayState = (currentState, action, type, name, isLast, nameToReducerMap) => {
  const n = getArrayNameAndIndex(name);
  if (n === undefined) return undefined;

  const arr = currentState[n.name];
  if (arr === undefined) return undefined;

  let newItem;
  if (isLast) {
    const reducer = nameToReducerMap[n.name];
    if (!reducer) return undefined;
    newItem = reducer(arr[n.index], { ...action, type });
  } else {
    newItem = arr[n.index];
  }
  return {
    name: n.name,
    state: [...arr.slice(0, n.index), newItem, ...arr.slice(n.index + 1)],
    index: n.index,
  };
};

export const getPropState = (currentState, action, type, name, isLast, nameToReducerMap) => {
  if (isLast) {
    const reducer = nameToReducerMap[name];
    if (!reducer) return undefined;
    return { name, state: reducer(currentState[name], { ...action, type }) };
  }
  return { name, state: { ...currentState[name] } };
};

export const getArrayNameAndIndex = v => {
  const name = /(.*?)\[(\d*)\]/.exec(v);
  return name && name.length === 3 ? { name: name[1], index: Number(name[2]) } : undefined;
};

export default childReducer;
