import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';

const buttonStyles = {
  borderRadius: '.5rem',
  padding: '.5rem 1rem',
  margin: '0.5rem',
};

const iconStyles = {
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles({
  dialogActions: {
    justifyContent: 'center',
  },
  dialogContentText: {
    textAlign: 'center',
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '20px',
    marginTop: '1rem',
  },
  cancelBtn: {
    ...buttonStyles,
    width: '45%',
  },
  successBtn: {
    ...buttonStyles,
    width: '100%',
    padding: '0.6rem 0',
    background: '#F97140',
    color: 'white',
    '&.MuiButton-textSecondary:hover': {
      background: '#F97140 !important',
    },
  },
  successIcon: {
    ...iconStyles,
    width: '40%',
    height: '40%',
  },
  modalIcon: {
    ...iconStyles,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type Props = {
  icon?: string;
  title: string;
  message: string;
  buttonText: string;
  open: boolean;
  handleSuccess: () => void;
};

export default function SuccessDialog({
  icon,
  title,
  message,
  buttonText,
  open,
  handleSuccess,
}: Props): JSX.Element {
  const classes = useStyles();

  const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
      padding: '2rem 1rem',
    },
  });
  const handleSuccessBtn = (): void => {
    handleSuccess();
  };
  return (
    <StyledDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className={classes.modalIcon}>
          <img src={icon} alt="" className={classes.successIcon} />
        </div>
        <Typography className={classes.dialogTitle}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleSuccessBtn} className={classes.successBtn} color="secondary">
          {buttonText}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
