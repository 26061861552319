import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from '@material-ui/core/styles/styled';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Typography } from '@material-ui/core';

import { colors } from '@apps/shared/src/style';
import { debounce } from '@apps/shared/src/utilities';
import {
  searchGlobal,
  searchQueue,
  setSearchScope,
  setSelectedTPAGroup,
  toggleCertificationScore,
  getClaims,
  toggleStatusFilter,
  toggleAllFilterGroup,
  toggleShowTestClaims,
  toggleShowManualClaims,
  toggleShowNonExportedClaims,
  updateClaimSort,
  isScoreAllowed,
  shouldAllowAllScores,
} from './claimsActions';
import { tpas, tpaGroups } from '../shared/globals';
import IconButtonCompact from '../shared/components/IconButtonCompact';
import * as types from '../shared/types/propTypes';

const CheckboxCompact = styled(Checkbox)({
  height: 24,
});

const RadioCompact = styled(Radio)({
  height: 24,
});

function TPASelect({ dispatch, selectedTPAGroup }) {
  const handleChange = e => dispatch(setSelectedTPAGroup(e.target.value));
  const tpaDisplay = typeof selectedTPAGroup === 'number' ? selectedTPAGroup : -1;

  return (
    <Select value={tpaDisplay} onChange={handleChange}>
      <MenuItem value={-1}>All TPAs</MenuItem>
      {tpaGroups.map(g => (
        <MenuItem key={g.id} value={g.id}>
          {g.group.map(id => tpas.find(tpa => tpa.id === id).name).join(', ')}
        </MenuItem>
      ))}
    </Select>
  );
}

TPASelect.propTypes = {
  selectedTPAGroup: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const certScores = ['P1', 'P2', 'P3', 'O1', 'O2', 'O3', 'O4', 'I1'];

const styles = {
  button: {
    minWidth: 0,
    padding: '6px',
    boxShadow: 'none',
  },
  buttonLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  buttonCenter: {
    borderRadius: 0,
  },
};

function CertificationScores({ dispatch, scores, roles, disabled, allowAllScores, classes }) {
  const handleClick = score => () => dispatch(toggleCertificationScore(score));

  return (
    <div>
      {certScores.map((score, index) => {
        const scoreApproved = allowAllScores || isScoreAllowed(score, roles);
        return (
          <Button
            key={score}
            disabled={!scoreApproved || disabled}
            onClick={handleClick(score)}
            variant={scoreApproved ? 'contained' : 'text'}
            color={scores[score] ? 'secondary' : 'default'}
            className={classNames({
              [classes.button]: true,
              [classes.buttonLeft]: index === 0,
              [classes.buttonRight]: index === certScores.length - 1,
              [classes.buttonCenter]: index > 0 && index < certScores.length - 1,
            })}
          >
            {score}
          </Button>
        );
      })}
    </div>
  );
}

CertificationScores.defaultProps = {
  scores: {},
  roles: [],
  disabled: false,
  allowAllScores: false,
};
CertificationScores.propTypes = {
  scores: PropTypes.objectOf(PropTypes.bool),
  roles: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  allowAllScores: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const StyledCertificationScores = withStyles(styles)(CertificationScores);

class Search extends Component {
  debouncedSearch = debounce(this.search, 400);

  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
    };
  }

  searchTermChanged = event => {
    this.debouncedSearch(event.target.value, this.props.search.global);
  };

  toggleSearchScope = () => {
    this.search(this.props.search.searchTerm, !this.props.search.global);
  };

  toggleFilter = filter => () => {
    this.props.dispatch(toggleStatusFilter(filter));
    this.search(this.props.search.searchTerm, false);
  };

  toggleAllFilterGroup = () => {
    this.props.dispatch(toggleAllFilterGroup(this.props.filterGroup));
  };

  toggleShowFilters = filterGroupName => () => {
    const { showFilters } = this.state;
    if (showFilters)
      this.props.dispatch(
        getClaims(
          filterGroupName,
          this.props.currentPage,
          this.props.pageSize,
          this.props.sortBy,
          this.props.showTestClaims,
          this.props.showManualClaims,
          this.props.showNonExportedClaims
        )
      );
    this.setState({ showFilters: !showFilters });
  };

  toggleShowClaims = () => {
    this.props.dispatch(toggleShowTestClaims());
  };

  toggleManualClaims = () => {
    if (this.props.showManualClaims) {
      this.props.dispatch(toggleShowNonExportedClaims(false));
    }
    this.props.dispatch(toggleShowManualClaims());
  };

  toggleNonExportedClaims = event => {
    const isChecked = event.target.checked;
    this.props.dispatch(toggleShowNonExportedClaims(isChecked));
  };

  changeSortBy = sortBy => () => {
    this.props.dispatch(updateClaimSort(sortBy));
  };

  chooseSearchIcon = global => (global ? <SearchIcon /> : <FilterListIcon />);

  chooseExpandIcon = showFilters => (showFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  search(searchTerm, isGlobal) {
    if (isGlobal) {
      if (searchTerm && searchTerm.length >= 3) {
        this.props.dispatch(searchGlobal(searchTerm));
      } else {
        this.props.dispatch(setSearchScope(true));
      }
    } else {
      this.props.dispatch(searchQueue(searchTerm));
    }
  }

  render() {
    const {
      filters,
      filterGroup,
      filterGroupName,
      search,
      allowSearch = true,
      selectedTPAGroup,
      certificationScores,
      roles,
      dispatch,
    } = this.props;
    const { showFilters } = this.state;
    const allSelected = filterGroup.every(filter => filters[filter]);
    const { global } = search;
    const allowAllScores = shouldAllowAllScores(filterGroupName);

    return (
      <div
        style={{
          padding: '0 0 0 2rem',
          borderBottom: `1px solid ${colors.grey12}`,
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto min-content min-content',
            alignItems: 'center',
            padding: '4px 0',
          }}
        >
          {allowSearch && (
            <>
              <FormControl fullWidth>
                <Input
                  onChange={this.searchTermChanged}
                  defaultValue={search.searchTerm}
                  placeholder={global ? 'Search all claims' : 'Search in queue'}
                />
              </FormControl>
              <IconButtonCompact onClick={this.toggleSearchScope}>
                {this.chooseSearchIcon(global)}
              </IconButtonCompact>
            </>
          )}
          {!allowSearch && (
            <>
              <div style={{ textAlign: 'right', color: colors.grey37 }}>Options</div>
              <div />
            </>
          )}
          <IconButtonCompact onClick={this.toggleShowFilters(filterGroupName)}>
            {this.chooseExpandIcon(showFilters)}
          </IconButtonCompact>
        </div>
        <Collapse
          in={showFilters}
          style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
        >
          <FormControl component="fieldset" style={{ margin: '15px 0' }} disabled={global}>
            <FormLabel component="legend">Filter Queue:</FormLabel>
            <FormGroup>
              <FormControlLabel
                style={{ fontWeight: 'bold', height: '1.5rem' }}
                key="toggleAll"
                label={
                  <Typography variant="body2">
                    {allSelected ? 'CLEAR ALL' : 'SELECT ALL'}
                  </Typography>
                }
                control={
                  <CheckboxCompact
                    checked={allSelected}
                    onChange={this.toggleAllFilterGroup}
                    checkedIcon={<ClearIcon />}
                    disableRipple
                  />
                }
              />
              {filterGroup.map(filter => (
                <FormControlLabel
                  style={{ marginLeft: '0.5rem', height: '1.5rem' }}
                  key={filter}
                  label={<Typography variant="body2">{filter}</Typography>}
                  control={
                    <CheckboxCompact
                      checked={filters[filter]}
                      onChange={this.toggleFilter(filter)}
                      disableRipple
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl fullWidth disabled={global}>
            <FormLabel component="legend">TPA Groups:</FormLabel>
            <TPASelect selectedTPAGroup={selectedTPAGroup} dispatch={dispatch} />
          </FormControl>
          <FormControl disabled={global}>
            <br />
            <FormLabel component="legend">Certification Scores:</FormLabel>
            <StyledCertificationScores
              disabled={global}
              scores={certificationScores}
              dispatch={dispatch}
              roles={roles}
              allowAllScores={allowAllScores}
            />
          </FormControl>
          <FormControl disabled={global}>
            <br />
            <FormLabel component="legend">Options:</FormLabel>
            <FormGroup>
              <FormControlLabel
                style={{ height: '1.5rem' }}
                label={<Typography variant="body2">Show Manual Claims</Typography>}
                control={
                  <CheckboxCompact
                    checked={this.props.showManualClaims}
                    onChange={this.toggleManualClaims}
                    name="showManualClaims"
                    disableRipple
                  />
                }
              />
              {this.props.showManualClaims && (
                <FormControlLabel
                  style={{ height: '1.5rem', marginLeft: '1rem' }}
                  label={
                    <Typography variant="body2" style={{ fontSize: '12px' }}>
                      Only Non-Exported
                    </Typography>
                  }
                  control={
                    <CheckboxCompact
                      checked={this.props.showNonExportedClaims}
                      onChange={this.toggleNonExportedClaims}
                      name="nonExported"
                      disableRipple
                    />
                  }
                />
              )}

              <FormControlLabel
                style={{ height: '1.5rem' }}
                label={<Typography variant="body2">Show Test Claims</Typography>}
                control={
                  <CheckboxCompact
                    checked={!this.props.showTestClaims}
                    onChange={this.toggleShowClaims}
                    name="showTestClaims"
                    disableRipple
                  />
                }
              />
              <FormControlLabel
                style={{ height: '1.5rem' }}
                label={<Typography variant="body2">Sort by Date of Service</Typography>}
                control={
                  <RadioCompact
                    checked={this.props.sortBy === 'dateOfService'}
                    onChange={this.changeSortBy('dateOfService')}
                    disableRipple
                  />
                }
              />
              <FormControlLabel
                style={{ height: '1.5rem' }}
                label={<Typography variant="body2">Sort by Date Received</Typography>}
                control={
                  <RadioCompact
                    checked={this.props.sortBy === 'dateReceived'}
                    onChange={this.changeSortBy('dateReceived')}
                    disableRipple
                  />
                }
              />
            </FormGroup>
          </FormControl>
          <FormControl>
            <br />
            <Button
              variant="contained"
              color="secondary"
              onClick={this.toggleShowFilters(filterGroupName)}
            >
              Confirm
            </Button>
            <br />
          </FormControl>
        </Collapse>
      </div>
    );
  }
}

Search.defaultProps = {
  filterGroup: [],
  filterGroupName: '',
  allowSearch: true,
  selectedTPAGroup: -1,
  certificationScores: {},
  roles: [],
  showTestClaims: false,
  showManualClaims: false,
  showNonExportedClaims: false,
  sortBy: '',
};
Search.propTypes = {
  filters: PropTypes.objectOf(PropTypes.bool).isRequired,
  filterGroup: PropTypes.arrayOf(PropTypes.string),
  filterGroupName: PropTypes.string,
  search: PropTypes.shape({
    global: PropTypes.bool,
    searchTerm: PropTypes.string,
    results: PropTypes.arrayOf(types.claim),
  }).isRequired,
  allowSearch: PropTypes.bool,
  selectedTPAGroup: PropTypes.number,
  certificationScores: PropTypes.objectOf(PropTypes.bool),
  roles: PropTypes.arrayOf(PropTypes.string),
  showTestClaims: PropTypes.bool,
  showManualClaims: PropTypes.bool,
  showNonExportedClaims: PropTypes.bool,
  sortBy: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

function mapStateToProps({ claims, user }) {
  return {
    filters: claims.statusFilters,
    showTestClaims: claims.showTestClaims,
    showManualClaims: claims.showManualClaims,
    showNonExportedClaims: claims.showNonExportedClaims,
    sortBy: claims.sortBy,
    search: claims.search,
    selectedTPAGroup: claims.selectedTPAGroup,
    certificationScores: claims.certificationScores,
    roles: user.currentUser.roles,
  };
}

export default connect(mapStateToProps)(Search);
