import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import PlanCard from './PlanCard';
import PlanEditorHeader from './PlanEditorHeader';

import { RootState } from '../../shared/types/types';
import { MasterPlan, PlanElection } from './types/plans';

import { getPlans } from './plansActions';

const filterPlansByTPA = (
  allMasterPlans: Record<number, MasterPlan>,
  selectedTPAIDs: number[]
): Record<number, MasterPlan> => {
  const filteredPlans: Record<number, MasterPlan> = {};

  Object.values(allMasterPlans).forEach(plan => {
    if (selectedTPAIDs.includes(plan.sixDegTPAID)) {
      filteredPlans[plan.masterPlanID] = plan;
    }
  });

  return filteredPlans;
};

const useStyles = makeStyles({
  planContainer: {
    maxWidth: '1920px',
    minWidth: '800px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  loadingBuffer: {
    marginTop: '200px',
  },
});

type StateProps = {
  masterPlans: Record<number, MasterPlan>;
  allPlanElections: Record<number | string, PlanElection>;
  areAllPlansLoading: boolean;
  filteredTPAs: number[];
  selectedElection: string;
  isError: boolean;
  isLoading: boolean;
};

const mapStateToProps = ({ plans }: RootState): StateProps => {
  const { masterPlans, allPlanElections, areAllPlansLoading, filteredTPAs, isError, isLoading } =
    plans;
  return {
    masterPlans,
    allPlanElections,
    areAllPlansLoading,
    filteredTPAs,
    isError,
    isLoading,
  };
};

const mapDispatchToProps = {
  getPlans,
};

type Props = StateProps & typeof mapDispatchToProps;

function PlanEditor({
  masterPlans,
  allPlanElections,
  getPlans,
  areAllPlansLoading,
  filteredTPAs,
  isLoading,
  isError,
}: Props): JSX.Element {
  const classes = useStyles();
  const [selectedSubPlanId, setSelectedSubPlanId] = useState<number | null>(null); // Track selected Sub Plan ID

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  const filteredPlans =
    filteredTPAs.length === 0 ? masterPlans : filterPlansByTPA(masterPlans, filteredTPAs);

  // Function to check if master census should be displayed
  // const shouldDisplayMasterCensus = (masterPlanID: number): boolean => {
  //   const masterPlan = masterPlans[masterPlanID];
  //   return masterPlan && masterPlan.censusNames && selectedSubPlanId !== null;
  // };

  return (
    <>
      <LoadingSpinner isLoading={areAllPlansLoading} containerClassName={classes.loadingBuffer} />
      {!areAllPlansLoading && Object.keys(masterPlans).length > 0 && (
        <div className={classes.planContainer}>
          <PlanEditorHeader />
          {Object.keys(filteredPlans)
            .sort((a, b) => parseInt(b, 10) - parseInt(a, 10))
            .map(masterPlanID => {
              if (masterPlanID === '0') {
                return null;
              }

              const id = parseInt(masterPlanID, 10);

              return (
                <PlanCard
                  key={masterPlanID}
                  isNewPlan={false}
                  masterPlan={masterPlans[id]}
                  planElections={allPlanElections}
                  isLoading={isLoading}
                  isError={isError}
                />
              );
            })}
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanEditor);
