import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import { AppTheme } from '../shared/types/themeType';
import { ContractTypes } from '../contracts/types/contracts';

const useStyles = makeStyles<AppTheme>(theme => ({
  outerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    ...theme.flagTable.actionButtonCol,
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5rem 0.5rem 0',
  },
  button: theme.flagTable.actionButton,
}));

export type ParentProps = {
  hasHideableContracts: boolean;
  showHidden: boolean;
  isSelectableStatus: boolean;
  contractID: number | null;
  handleHideUnselected: VoidFunction;
  handleShowHidden: VoidFunction;
  handleSelectContract: (
    contractID: number | null,
    providerGroup?: string,
    contractType?: ContractTypes
  ) => VoidFunction;
};

export default function ContractMatchButtons({
  hasHideableContracts,
  showHidden,
  isSelectableStatus,
  contractID,
  handleHideUnselected,
  handleShowHidden,
  handleSelectContract,
}: ParentProps): JSX.Element {
  const classes = useStyles();
  const clickHandler = showHidden ? handleHideUnselected : handleShowHidden;
  const showHideText = showHidden ? 'HIDE UNSELECTED' : 'SHOW HIDDEN';

  return (
    <div className={classes.outerContainer}>
      {hasHideableContracts && (
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={clickHandler}
            className={classes.button}
          >
            {showHideText}
          </Button>
        </div>
      )}
      {isSelectableStatus && contractID === null && (
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSelectContract(0)}
            className={classes.button}
          >
            CONTRACT NOT USED
          </Button>
        </div>
      )}
    </div>
  );
}
