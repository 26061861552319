import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import { MediVIProvider } from '../types/types';

const useStyles = makeStyles({
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal',
    alignItems: 'flex-start',
  },
});

type Props = {
  option: MediVIProvider;
  index: number;
  highlightedIndex: number;
  getItemProps: (obj: object) => void;
};
export default function ProviderOption({
  option,
  index,
  highlightedIndex,
  getItemProps,
}: Props): JSX.Element {
  const classes = useStyles();
  const { name, ccn, city, state, zip } = option;

  return (
    <MenuItem
      {...getItemProps({
        item: option,
        key: `${name} (${ccn})`,
        component: 'div',
        dense: true,
        selected: highlightedIndex === index, // allows for keyboard navigation
        className: classes.menuItem,
      })}
    >
      <Typography variant="body2" noWrap>
        {`${name} (${ccn})`}
      </Typography>
      <Typography variant="caption" noWrap>
        {`${city} ${state}, ${zip}`}
      </Typography>
    </MenuItem>
  );
}
