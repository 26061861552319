import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';

import { colors } from '@apps/shared/src/style';
import DateInput from '../../manualClaim/DateInput';
import { dateRangeToText } from '../contractUtilities';
import { UpdateContract } from '../types/contracts';
import { checkDate } from '../../shared/validators';

const useStyles = makeStyles({
  dateRange: {
    display: 'flex',
    alignItems: 'center',
  },
  separator: {
    margin: '0 1rem',
    color: colors.grey50,
  },
});

type Props = {
  isEdit: boolean;
  updateContract: UpdateContract;
  start: string;
  end: string;
};
export default function ContractDateRange({
  start,
  end,
  isEdit,
  updateContract,
}: Props): JSX.Element {
  const classes = useStyles();
  const [errors, setErrors] = useState({ start: '', end: '' });
  const [dates, setDates] = useState({ start, end });
  useEffect(() => {
    setDates({ start, end });
  }, [start, end]);

  const existingValue = (type: string): string => (type === 'start' ? dates.start : dates.end);
  const handleChange =
    (type: string) =>
    (newValue: string): void => {
      if (newValue !== existingValue(type)) {
        const [isValidDate, parsedDate, err] = checkDate(newValue, false);
        if (isValidDate) {
          updateContract(`${type}Date`, parsedDate);
          setErrors({ ...errors, [type]: '' });
        } else {
          setErrors({ ...errors, [type]: err });
        }
        setDates({ ...dates, [type]: parsedDate });
      }
    };

  return isEdit ? (
    <div className={classes.dateRange}>
      <DateInput
        label="Start Date"
        value={dates.start}
        onValueChange={handleChange('start')}
        errorLabel={errors.start}
      />
      <span className={classes.separator}>&#08212;</span>

      <DateInput
        label="End Date"
        value={dates.end}
        onValueChange={handleChange('end')}
        errorLabel={errors.end}
      />
    </div>
  ) : (
    <Typography variant="subtitle1">{dateRangeToText(start, end)}</Typography>
  );
}
