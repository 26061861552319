import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '@apps/shared/src/style';

const useStyles = makeStyles({
  head: {
    display: 'block',
    marginRight: '1rem',
  },
  firstHeader: {
    textAlign: 'center',
    fontStyle: 'italic',
  },
  incoming: {
    background: colors.green,
  },
  manual: {
    background: colors.blueLight,
  },
  completed: {
    background: colors.yellow,
  },
  verify: {
    background: colors.orange,
  },
  error: {
    background: colors.red,
  },
  secondHeaderRow: {
    '& th': {
      backgroundColor: colors.grey20,
      padding: '5px',
      width: '50px',
    },
  },
  date: {
    width: '75px !important',
  },
  handler: {
    width: '150px !important',
  },
  border: {
    borderRight: '3px solid grey',
  },
});

export default function DailyClaimsHeader({ isIndividual }) {
  const classes = useStyles();
  return (
    <thead className={classes.head}>
      <tr>
        <th colSpan={isIndividual ? 1 : 3} />
        {!isIndividual && (
          <th
            colSpan={4}
            className={classNames(classes.firstHeader, classes.incoming, classes.border)}
          >
            Incoming Claims
          </th>
        )}
        <th colSpan={2} className={classNames(classes.firstHeader, classes.manual, classes.border)}>
          Manual Claims
        </th>
        <th colSpan="12" className={classNames(classes.firstHeader, classes.completed)}>
          Completed Claims
        </th>
        <th className={classNames(classes.firstHeader, classes.verify)}>Verify</th>
        <th className={classNames(classes.firstHeader, classes.error)}>Miscalc</th>
      </tr>
      <tr className={classes.secondHeaderRow}>
        {!isIndividual && <th /> /* empty column for +/- buttons */}
        <th className={classes.date}>Date</th>
        {!isIndividual && (
          <>
            <th className={classes.handler}>Handler</th>
            <th>Prof.</th>
            <th>Outpat.</th>
            <th>Inpat.</th>
            <th className={classes.border}>Total</th>
          </>
        )}
        <th>Entry</th>
        <th className={classes.border}>Validate</th>
        <th>P1</th>
        <th>P2</th>
        <th>P3</th>
        <th>O1</th>
        <th>O2</th>
        <th>O3</th>
        <th>O4</th>
        <th>I1</th>
        <th>Total</th>
        <th>Points</th>
        <th>Rpr Svcs</th>
        <th className={classes.border}>Ttl Svcs</th>
        <th className={classes.border}>Total</th>
        <th>Total</th>
      </tr>
    </thead>
  );
}

DailyClaimsHeader.propTypes = {
  isIndividual: PropTypes.bool.isRequired,
};
