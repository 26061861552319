import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import styled from '@material-ui/core/styles/styled';

export default function styledAvatar(size, backgroundColor, styles) {
  const AvatarStyled = styled(Avatar)({
    width: `${size}px`,
    height: `${size}px`,
    lineHeight: `${size}px`,
    fontSize: `${size / 2}px`,
    backgroundColor,
    ...styles,
  });
  return React.forwardRef((props, ref) => <AvatarStyled {...props} ref={ref} />);
}
