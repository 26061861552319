import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DailyClaimsRow from './DailyClaimsRow';
import { dailyClaimsRow } from '../../shared/types/propTypes';

export default function DailyClaimsDateGroup({ date, index, handlerTotals, totalRow }) {
  const [isBreakout, setIsBreakout] = useState(false);
  const toggleIsBreakout = () => setIsBreakout(!isBreakout);

  return (
    <>
      <DailyClaimsRow
        row={totalRow}
        handler="All"
        date={date}
        index={index}
        isBreakout={isBreakout}
        isIndividual={false}
        toggleIsBreakout={toggleIsBreakout}
      />
      {isBreakout &&
        handlerTotals.map(t => (
          <DailyClaimsRow
            key={t.handler + date}
            row={t.totals}
            handler={t.handler}
            date={date}
            index={index}
            isBreakout={isBreakout}
            isIndividual={false}
          />
        ))}
    </>
  );
}

DailyClaimsDateGroup.propTypes = {
  date: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handlerTotals: PropTypes.arrayOf(
    PropTypes.shape({ handler: PropTypes.string, totals: dailyClaimsRow })
  ).isRequired,
  totalRow: dailyClaimsRow.isRequired,
};
