import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { inflateString } from '@apps/shared/src/inflators';
import TextInput from '../../../shared/components/TextInput';
import { TermInputProps } from '../../types/contracts';

const useStyles = makeStyles({
  termInput: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em',
  },
});

export default function TextFieldInput({
  value,
  handleChange,
  id,
  fullWidth,
  maxLength,
}: TermInputProps): JSX.Element {
  const classes = useStyles();
  return (
    <div
      style={{ maxWidth: !fullWidth ? '12em' : '100%', width: '100%' }}
      className={classes.termInput}
    >
      <TextInput
        fullWidth
        type="text"
        maxLength={maxLength}
        id={id}
        value={inflateString(value)}
        onChange={handleChange}
        autoFocus
      />
    </div>
  );
}
