import { inflateNumber, inflateString } from '@apps/shared/src/inflators';
import { doesStringInclude, doesStringArrayIncludeString } from '@apps/shared/src/utilities';
import {
  ContractSummary,
  ContractPatient,
  EventsourceUpdate,
  ContractState,
  ContractTerm,
  ContractRoster,
  ContractProvider,
  ContractDetail,
  ContractUpdate,
  ContractPlan,
  ComparisonType,
  RangeType,
  TermItem,
  TermEntry,
  AutocompleteOption,
  ContractTypes,
  ContractTypesDisplay,
} from './types/contracts';
import * as c from './types/actions';
import { validArray, validObject, isValidArray, isArrayFilled } from '../shared/typeChecks';
import { isSimpleUser } from '../shared/types/typeGuards';
import { inflateSimpleUser, inflateSimpleUserFromIDAndName } from '../shared/types/inflaters';
import { inflateContractType, inflateContractOwnerTypes } from './types/inflaters';
import { contractStatusCodes as statusCodes } from '../shared/status';
import { orderTermItems, checkIsSCAorLOA } from './contractUtilities';
import {
  ruleTypes,
  rangeTypes,
  conditionTypes,
  termItems as allowedTermItems,
} from './terms/termElements';

export function emptyContract(): ContractDetail {
  return {
    id: 0,
    contractType: ContractTypes.Invalid,
    status: '',
    link: '',
    lastEditedBy: [],
    startDate: '',
    endDate: '',
    note: '',
    providerGroup: '',
    patients: [],
    plans: [],
    rosters: [],
    terms: [],
    contractOwnerType: '',
  };
}

export function emptyRosterValues(): ContractRoster {
  return {
    id: 0,
    name: 'Manual Roster',
    isManual: true,
    lastModifiedDate: '',
    providers: [],
    providerCount: 0,
  };
}

export function emptyProviderValues(): ContractProvider {
  return {
    npi: '',
    npiError: '',
    name: '',
    taxID: '',
    taxIDError: '',
  };
}

export function emptyTermValues(): ContractTerm {
  return {
    id: 0,
    percentCMS: 0,
    percentBilledCharges: 0,
    startRange: 0,
    endRange: 999,
    rangeInclusive: false,
    rangeType: 'days',
    revCode: [],
    hcpcsOrCPT: [],
    unlistedCharges: false,
    claimType: 'facility',
    limitedServices: '',
    extraStipulations: '',
    perDiem: 0.0,
    maxAmount: 0.0,
    flatRate: 0.0,
    caseRate: 0.0,
    comparisonType: 'lesser',
  };
}

export function emptySummary(): ContractSummary {
  return {
    id: 0,
    startDate: '',
    endDate: '',
    status: '',
    ownerID: '',
    ownerName: '',
    patients: [],
    providerNames: [],
    providerNPIs: [],
    providerTaxIDs: [],
    patientSummary: '',
    providerGroup: '',
    contractType: ContractTypes.Invalid,
    lastEditedBy: [],
  };
}

export const defaultState: ContractState = {
  contracts: [],
  filteredContracts: [],
  selectedContract: emptyContract(),
  autocompleteOptions: [],
  autocompleteType: undefined,
  searchText: '',
  listLoading: false,
  isContractLoading: false,
  currentTermValues: emptyTermValues(),
  isTermOpen: false,
  isOpenTermOpen: false,
  selectedTermItems: [],
  isRosterOpen: false,
  isRosterLoading: false,
  hasUnsavedRosterChanges: false,
  selectedRoster: emptyRosterValues(),
  selectedProvider: emptyProviderValues(),
  isPlanOpen: false,
  isPatientOpen: false,
  isNPILookupPending: false,
  contractBeingLoaded: undefined,
};

export default (
  state: ContractState = defaultState,
  action: c.ContractActionTypes
): ContractState => {
  switch (action.type) {
    case c.GET_CONTRACTS_PENDING: {
      return { ...state, listLoading: true, contracts: [] };
    }
    case c.GET_CONTRACTS_REJECTED: {
      return { ...state, listLoading: false };
    }
    case c.GET_CONTRACTS_FULFILLED: {
      const contracts = formatAndInflateSummaries(validArray(validObject(action.payload).data));
      return {
        ...state,
        listLoading: false,
        contracts,
      };
    }

    case c.GET_CONTRACT_PENDING: {
      return {
        ...state,
        isContractLoading: true,
        selectedRoster: emptyRosterValues(),
        selectedProvider: emptyProviderValues(),
        isRosterOpen: false,
        isRosterLoading: false,
        contractBeingLoaded: action.meta.id,
      };
    }
    case c.GET_CONTRACT_REJECTED: {
      if (action.meta.id !== state.contractBeingLoaded) return state;

      return {
        ...state,
        selectedContract: emptyContract(),
        isContractLoading: false,
        contractBeingLoaded: undefined,
      };
    }
    case c.GET_CONTRACT_FULFILLED: {
      if (action.payload.data.id !== state.contractBeingLoaded) return state;
      return {
        ...state,
        isContractLoading: false,
        isTermOpen: false,
        isOpenTermOpen: false,
        currentTermValues: emptyTermValues(),
        selectedTermItems: [],
        selectedContract: formatAndInflateContract(action.payload.data),
        contractBeingLoaded: undefined,
        hasUnsavedRosterChanges: false,
      };
    }

    case c.SEARCH_CONTRACTS: {
      return {
        ...state,
        searchText: action.meta,
        filteredContracts: getFilteredContracts(state.contracts, action.meta),
      };
    }

    case c.UPDATE_CONTRACTS_ON_EVENTSOURCE: {
      return {
        ...state,
        contracts: updateContractList(state.contracts, action.payload),
        selectedContract: updateSelectedContractOnEventSource(
          state.selectedContract,
          action.payload
        ),
      };
    }

    case c.DELETE_CONTRACT_PENDING: {
      return {
        ...state,
        isContractLoading: true,
      };
    }

    case c.DELETE_CONTRACT_REJECTED: {
      return {
        ...state,
        isContractLoading: false,
      };
    }

    case c.DELETE_CONTRACT_FULFILLED: {
      if (state.selectedContract.id !== action.meta.id)
        return {
          ...state,
          isContractLoading: false,
        };

      const contractsWithDeletedRemoved = removeDeletedContractFromSummaries(
        state.contracts,
        action.meta.id
      );
      const filteredContractsWithDeletedRemoved = removeDeletedContractFromSummaries(
        state.filteredContracts,
        action.meta.id
      );

      return {
        ...state,
        selectedContract: emptyContract(),
        selectedRoster: emptyRosterValues(),
        selectedProvider: emptyProviderValues(),
        currentTermValues: emptyTermValues(),
        contracts: contractsWithDeletedRemoved,
        filteredContracts: filteredContractsWithDeletedRemoved,
        isContractLoading: false,
      };
    }

    case c.CREATE_CONTRACT_PENDING: {
      return {
        ...state,
        isContractLoading: true,
      };
    }

    case c.CREATE_CONTRACT_REJECTED: {
      return {
        ...state,
        isContractLoading: false,
      };
    }

    case c.CREATE_CONTRACT_FULFILLED: {
      const { summary, newContract } = validObject(validObject(action.payload).data);
      const newContractSummary = formatAndInflateSummary(summary);
      const contracts = [newContractSummary, ...state.contracts];
      const filteredContracts = filterBySearchText(state.searchText, contracts);

      return {
        ...state,
        contracts,
        filteredContracts,
        selectedContract: formatAndInflateContract(newContract),
        isPatientOpen: false,
        isPlanOpen: false,
        isContractLoading: false,
      };
    }

    case c.UPDATE_CONTRACT_FULFILLED: {
      if (state.selectedContract.id !== action.meta.id) return state;

      const updatedContractSummary = formatAndInflateSummary(action.payload.data);
      const updatedSummaries = replaceContractSummaryInList(
        state.contracts,
        updatedContractSummary
      );
      const filteredUpdatedSummaries = getFilteredContracts(updatedSummaries, state.searchText);

      return {
        ...state,
        selectedContract: updateAndInflateSelectedContract(
          state.selectedContract,
          action.meta.updateObject,
          updatedContractSummary,
          action.meta.elementToAttach
        ),
        isPatientOpen: false,
        isPlanOpen: false,
        contracts: updatedSummaries,
        filteredContracts: filteredUpdatedSummaries,
      };
    }

    case c.SET_AUTOCOMPLETE_TYPE: {
      return {
        ...state,
        autocompleteOptions: [],
        autocompleteType: action.payload,
      };
    }

    case c.GET_AUTOCOMPLETE_FULFILLED: {
      return {
        ...state,
        autocompleteOptions: validArray(action.payload.data),
      };
    }

    case c.CLEAR_SELECTED_CONTRACT: {
      return {
        ...state,
        selectedContract: emptyContract(),
        selectedRoster: emptyRosterValues(),
        selectedProvider: emptyProviderValues(),
        isRosterLoading: false,
        isRosterOpen: false,
      };
    }

    case c.UPDATE_TERM_VALUE: {
      return {
        ...state,
        currentTermValues: {
          ...state.currentTermValues,
          [action.payload.termType]: action.payload.value,
        },
      };
    }

    case c.CLEAR_TERM_VALUES: {
      return {
        ...state,
        currentTermValues: emptyTermValues(),
      };
    }

    case c.SET_SELECTED_TERM: {
      const isOpenFormatTerm = action.payload.term?.openFormatTextTerm?.length;
      return isOpenFormatTerm
        ? {
            ...state,
            currentTermValues: action.payload.term,
            selectedTermItems: orderTermItems(
              ['openFormatTextTerm'],
              [ruleTypes, rangeTypes, conditionTypes]
            ),
            isOpenTermOpen: true,
          }
        : {
            ...state,
            currentTermValues: action.payload.term,
            selectedTermItems: selectAndSortTermItems(action.payload.term),
            isTermOpen: true,
          };
    }

    case c.SUBMIT_NEW_TERM_FULFILLED: {
      const id = action.payload.data;
      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          terms: updateContractTerms(state.selectedContract.terms, id, action.meta),
        },
        currentTermValues: emptyTermValues(),
        selectedTermItems: [],
        isTermOpen: false,
        isOpenTermOpen: false,
      };
    }

    case c.SET_SELECTED_TERM_ITEMS: {
      return {
        ...state,
        selectedTermItems: orderTermItems(action.payload.selections, [
          ruleTypes,
          rangeTypes,
          conditionTypes,
        ]),
      };
    }

    case c.LOOKUP_NPI_PENDING: {
      return {
        ...state,
        isNPILookupPending: true,
      };
    }

    case c.LOOKUP_NPI_REJECTED: {
      if (action.meta.npi !== state.selectedProvider.npi) {
        return {
          ...state,
          isNPILookupPending: false,
        };
      }
      return {
        ...state,
        isNPILookupPending: false,
        selectedProvider: {
          ...state.selectedProvider,
          name: '',
        },
      };
    }

    case c.LOOKUP_NPI_FULFILLED: {
      const {
        meta: { npi },
        payload: { data },
      } = action;
      const newState = {
        ...state,
        isNPILookupPending: false,
      };

      if (npi !== state.selectedProvider.npi) return newState;

      const providerName = `${data.firstName} ${data.lastNameOrOrg}`.trim();
      newState.selectedProvider = {
        ...state.selectedProvider,
        name: providerName,
      };

      return newState;
    }

    case c.SET_SELECTED_PROVIDER: {
      return {
        ...state,
        selectedProvider: action.payload,
      };
    }

    case c.PARSE_ROSTER_FILE_FULFILLED:
    case c.GET_ROSTER_FULFILLED: {
      const {
        payload: { data },
        meta: { selectedContractID },
      } = action;

      if (state.selectedContract.id !== selectedContractID)
        return { ...state, isRosterLoading: false };

      const inflatedRoster = inflateRoster(data);

      return {
        ...state,
        selectedRoster: inflatedRoster,
        selectedProvider: emptyProviderValues(),
        isRosterOpen: true,
        isRosterLoading: false,
        hasUnsavedRosterChanges: false,
      };
    }

    case c.GET_ROSTER_PENDING: {
      return {
        ...state,
        isRosterLoading: true,
        isRosterOpen: false,
      };
    }
    case c.PARSE_ROSTER_FILE_PENDING:
    case c.ADD_OR_UPDATE_ROSTER_PENDING: {
      return {
        ...state,
        isRosterLoading: true,
      };
    }

    case c.GET_ROSTER_REJECTED:
    case c.PARSE_ROSTER_FILE_REJECTED:
    case c.ADD_OR_UPDATE_ROSTER_REJECTED: {
      return {
        ...state,
        isRosterLoading: false,
      };
    }

    case c.ADD_OR_UPDATE_ROSTER_FULFILLED: {
      const { meta, payload } = action;
      const { submittedRoster } = meta;
      const { rosterID, contractID } = payload.data;
      const { selectedContract } = state;

      if (selectedContract.id !== contractID) return { ...state, isRosterLoading: false };
      submittedRoster.providerCount = submittedRoster.providers.length;

      const addedOrUpdatedRoster = {
        ...submittedRoster,
        id: rosterID,
      };
      const selectedRoster = checkIsSCAorLOA(selectedContract.contractType)
        ? addedOrUpdatedRoster
        : emptyRosterValues();

      return {
        ...state,
        selectedRoster,
        isRosterOpen: false,
        isRosterLoading: false,
        hasUnsavedRosterChanges: false,
        selectedContract: {
          ...state.selectedContract,
          rosters: insertOrReplaceRoster(state.selectedContract.rosters, addedOrUpdatedRoster),
        },
      };
    }

    case c.REMOVE_ROSTER_PENDING: {
      return {
        ...state,
        isRosterLoading: true,
        isContractLoading: true,
      };
    }
    case c.REMOVE_ROSTER_REJECTED: {
      return {
        ...state,
        isRosterLoading: false,
        isContractLoading: false,
      };
    }
    case c.REMOVE_ROSTER_FULFILLED: {
      const { payload } = action;
      const { rosterID } = payload.data;

      const { selectedContract, selectedRoster, isRosterOpen } = state;

      const isDeletingSelectedRoster = selectedRoster.id === rosterID;

      return {
        ...state,
        isRosterLoading: false,
        isContractLoading: false,
        selectedContract: {
          ...selectedContract,
          rosters: removeRosterFromContract(selectedContract.rosters, rosterID),
        },
        selectedRoster: isDeletingSelectedRoster ? emptyRosterValues() : { ...selectedRoster },
        isRosterOpen: isDeletingSelectedRoster ? false : isRosterOpen,
      };
    }

    case c.UPDATE_SELECTED_ROSTER: {
      return {
        ...state,
        selectedRoster: action.payload,
      };
    }

    case c.SET_BOOLEAN_ELEMENT: {
      const { fieldName, bool } = action.payload;
      return {
        ...state,
        [fieldName]: bool,
      };
    }

    default:
      return state;
  }
};

export function formatDate(rawDate: string): string {
  if (rawDate === undefined || rawDate === '' || rawDate === '0001-01-01T00:00:00Z') return '';
  return new Date(rawDate).toLocaleDateString('en-US', {
    timeZone: 'UTC',
  });
}

export function inflateRoster(rawRoster: ContractRoster): ContractRoster {
  const raw = validObject(rawRoster);
  return {
    id: inflateNumber(raw.id),
    name: inflateString(raw.name),
    isManual: Boolean(raw.isManual),
    lastModifiedDate: inflateString(raw.lastModifiedDate),
    providers: inflateProviders(raw.providers),
    providerCount: inflateNumber(raw.providerCount),
    npiColumnName: inflateString(raw.npiColumnName),
    taxIDColumnName: inflateString(raw.taxIDColumnName),
  };
}

export function inflateProvider(rawProvider: ContractProvider): ContractProvider {
  const raw = validObject(rawProvider);
  const provider: ContractProvider = {
    npi: inflateString(raw.npi),
    npiError: inflateString(raw.npiError),
    name: inflateString(raw.name),
    taxID: inflateString(raw.taxID),
    taxIDError: inflateString(raw.taxIDError),
  };
  return provider;
}

export function inflateProviders(rawProviders: ContractProvider[]): ContractProvider[] {
  return validArray(rawProviders).map(inflateProvider);
}

type RawPatient = {
  id?: number;
  memberID?: string;
  name?: string;
  dob?: string;
  aliases?: string[];
  address?: string;
};
export function inflatePatient(rawPatient: RawPatient): ContractPatient {
  const raw = validObject(rawPatient);
  return {
    id: inflateNumber(raw.id),
    memberID: inflateString(raw.memberID),
    name: inflateString(raw.name),
    dob: inflateString(raw.dob),
    aliases: validArray(raw.aliases).map(inflateString),
  };
}

export function inflatePlan(rawPlan: ContractPlan): ContractPlan {
  const raw = validObject(rawPlan);
  return {
    id: inflateNumber(raw.id),
    planName: inflateString(raw.planName),
    policyNum: inflateString(raw.policyNum),
  };
}

type RawTerm = {
  id?: number;
  percentCMS?: number;
  percentBilledCharges?: number;
  startRange?: number;
  endRange?: number;
  rangeInclusive?: boolean;
  rangeType?: RangeType;
  revCode?: string[];
  hcpcsOrCPT?: string[];
  unlistedCharges?: boolean;
  claimType?: string;
  limitedServices?: string;
  extraStipulations?: string;
  perDiem?: number;
  maxAmount?: number;
  flatRate?: number;
  caseRate?: number;
  comparisonType?: ComparisonType;
};
export function inflateTerm(rawTerm: RawTerm): ContractTerm {
  const raw = validObject(rawTerm);
  return {
    id: inflateNumber(raw.id),
    percentCMS: inflateNumber(raw.percentCMS),
    percentBilledCharges: inflateNumber(raw.percentBilledCharges),
    startRange: inflateNumber(raw.startRange),
    endRange: inflateNumber(raw.endRange),
    rangeInclusive: Boolean(raw.rangeInclusive),
    rangeType: validRangeType(raw.rangeType),
    revCode: validArray(raw.revCode).map(inflateString),
    hcpcsOrCPT: validArray(raw.hcpcsOrCPT).map(inflateString),
    unlistedCharges: Boolean(raw.unlistedCharges),
    claimType: inflateString(raw.claimType),
    limitedServices: inflateString(raw.limitedServices),
    extraStipulations: inflateString(raw.extraStipulations),
    perDiem: inflateNumber(raw.perDiem),
    maxAmount: inflateNumber(raw.maxAmount),
    flatRate: inflateNumber(raw.flatRate),
    caseRate: inflateNumber(raw.caseRate),
    comparisonType: validComparisionType(raw.comparisonType),
    openFormatTextTerm: inflateString(raw.openFormatTextTerm),
  };
}

function validComparisionType(item: ComparisonType): ComparisonType {
  if (item === 'greater' || item === 'lesser') return item;
  return '';
}

function validRangeType(item: RangeType): RangeType {
  if (item === 'dollars' || item === 'days') return item;
  return '';
}

export function inflateContractSummary(rawContractSummary: ContractSummary): ContractSummary {
  const raw = validObject(rawContractSummary);
  return {
    id: inflateNumber(raw.id),
    startDate: inflateString(raw.startDate),
    endDate: inflateString(raw.endDate),
    status: inflateString(raw.status),
    ownerID: inflateString(raw.ownerID),
    ownerName: inflateString(raw.ownerName),
    patients: validArray(raw.patients).map(inflateString),
    providerNames: validArray(raw.providerNames).map(inflateString),
    providerNPIs: validArray(raw.providerNPIs).map(inflateString),
    providerTaxIDs: validArray(raw.providerTaxIDs).map(inflateString),
    patientSummary: inflateString(raw.patientSummary),
    providerGroup: inflateString(raw.providerGroup),
    contractType: inflateContractType(raw.contractType),
    dataEnterer: inflateSimpleUserFromIDAndName(raw.dataEntererID, raw.dataEntererName),
    lastEditedBy: validArray(raw.lastEditedBy).map(c => inflateString(c)),
  };
}

export function inflateContractSummaries(summaries: ContractSummary[]): ContractSummary[] {
  return validArray(summaries).map(inflateContractSummary);
}

export function formatAndInflateSummaries(summaries: ContractSummary[]): ContractSummary[] {
  return validArray(summaries).map(formatAndInflateSummary);
}

export function formatAndInflateSummary(summary: ContractSummary): ContractSummary {
  const s = inflateContractSummary(summary);
  if (s.startDate) s.startDate = formatDate(s.startDate);
  if (s.endDate) s.endDate = formatDate(s.endDate);
  return s;
}

export function removeDeletedContractFromSummaries(
  summaries: ContractSummary[],
  deletedContractID: number
): ContractSummary[] {
  return summaries.filter(summary => summary.id !== deletedContractID);
}

export function inflateContract(rawContract: ContractDetail): ContractDetail {
  const raw = validObject(rawContract);
  return {
    id: inflateNumber(raw.id),
    status: inflateString(raw.status),
    link: inflateString(raw.link),
    startDate: inflateString(raw.startDate),
    endDate: inflateString(raw.endDate),
    note: inflateString(raw.note),
    owner: inflateSimpleUserFromIDAndName(raw.ownerID, raw.ownerName),
    dataEnterer: inflateSimpleUserFromIDAndName(raw.dataEntererID, raw.dataEntererName),
    patients: validArray(raw.patients).map(inflatePatient),
    rosters: validArray(raw.rosters).map(inflateRoster),
    plans: validArray(raw.plans).map(inflatePlan),
    lastEditedBy: validArray(raw.lastEditedBy).map(c => inflateString(c)),
    terms: validArray(raw.terms).map(inflateTerm),
    providerGroup: inflateString(raw.providerGroup),
    contractType: inflateContractType(raw.contractType),
    contractOwnerType: inflateContractOwnerTypes(raw.contractOwnerType),
  };
}

export function formatAndInflateContract(rawContract: ContractDetail): ContractDetail {
  const c = inflateContract(rawContract);
  if (c.startDate) c.startDate = formatDate(c.startDate);
  if (c.endDate) c.endDate = formatDate(c.endDate);
  if (c.patients) c.patients = transformPatients(c.patients);
  return c;
}

function transformPatients(rawPatients: ContractPatient[]): ContractPatient[] {
  return validArray(rawPatients).map(patient => {
    const p = { ...patient };
    if (p.dob) {
      p.dob = formatDate(p.dob);
    }
    return p;
  });
}

function getFilteredContracts(contracts: ContractSummary[], searchText: string): ContractSummary[] {
  let filtered = contracts;
  if (searchText) {
    filtered = filterBySearchText(searchText, filtered);
  }
  return filtered;
}

export function filterBySearchText(
  searchText: string,
  filtered: ContractSummary[]
): ContractSummary[] {
  const t = searchText.toLowerCase();
  return filtered.filter(
    contract =>
      doesStringArrayIncludeString(contract.providerNames, t) ||
      doesStringArrayIncludeString(contract.patients, t) ||
      doesStringInclude(contract.providerGroup, t) ||
      doesStringInclude(contract.contractType, t) ||
      doesStringInclude(ContractTypesDisplay[contract.contractType], t) ||
      doesStringArrayIncludeString(contract.providerNPIs, t) ||
      doesStringArrayIncludeString(contract.providerTaxIDs, t)
  );
}

function updateContractList(list: ContractSummary[], update: EventsourceUpdate): ContractSummary[] {
  const ownerID = isSimpleUser(update.owner) ? update.owner.userID : '';
  const updateContractSummaryWithMatchingID = (summary: ContractSummary): ContractSummary =>
    summary.id === update.id ? { ...summary, status: update.status, ownerID } : summary;
  return list.map(updateContractSummaryWithMatchingID).sort(byStatusThenByContractID);
}

function replaceContractSummaryInList(
  list: ContractSummary[],
  newSummary: ContractSummary
): ContractSummary[] {
  return list.map(summary => (summary.id === newSummary.id ? newSummary : summary));
}

function byStatusThenByContractID(a: ContractSummary, b: ContractSummary): number {
  if (a.status !== b.status) {
    if (a.status === statusCodes.dataEntry) {
      return -1;
    }
    if (b.status === statusCodes.dataEntry) {
      return 1;
    }
  }
  return b.id - a.id;
}

function updateSelectedContractOnEventSource(
  contract: ContractDetail,
  update: EventsourceUpdate
): ContractDetail {
  if (contract.id !== update.id) return contract;
  if (update.dataEnterer)
    return {
      ...contract,
      status: update.status,
      owner: inflateSimpleUser(update.owner),
      dataEnterer: inflateSimpleUser(update.dataEnterer),
    };
  return {
    ...contract,
    status: update.status,
    owner: inflateSimpleUser(update.owner),
  };
}

function updateAndInflateSelectedContract(
  contract: ContractDetail,
  update: ContractUpdate,
  updatedSummary: ContractSummary,
  elementToAttach: AutocompleteOption
): ContractDetail {
  const c = { ...contract };
  c.owner = { userID: updatedSummary.ownerID, fullName: updatedSummary.ownerName, roles: [] };
  c.lastEditedBy = updatedSummary.lastEditedBy;
  switch (update.action) {
    case 'link': {
      c.link = update.stringVal;
      break;
    }
    case 'status': {
      c.status = update.stringVal;
      break;
    }
    case 'note': {
      c.note = update.stringVal;
      break;
    }
    case 'addPlan': {
      c.plans = c.plans.concat(validObject(elementToAttach));
      break;
    }
    case 'removePatient': {
      c.patients = c.patients.filter(p => p.id !== update.intVal);
      break;
    }
    case 'removePlan': {
      c.plans = c.plans.filter(p => p.id !== update.intVal);
      break;
    }
    case 'addPatient': {
      c.patients = c.patients.concat(validObject(elementToAttach));
      break;
    }
    case 'addUnidentifiedPatient': {
      c.patients = c.patients.concat(validObject(elementToAttach));
      break;
    }
    case 'removeUnidentifiedPatient': {
      c.patients = c.patients.filter(p => p.name !== update.stringVal);
      break;
    }
    case 'startDate': {
      c.startDate = formatDate(inflateString(updatedSummary.startDate));
      break;
    }
    case 'endDate': {
      c.endDate = formatDate(inflateString(updatedSummary.endDate));
      break;
    }
    case 'removeTerm': {
      c.terms = c.terms.filter(t => t.id !== update.intVal);
      break;
    }
    case 'providerGroup': {
      c.providerGroup = inflateString(update.stringVal);
      break;
    }
    case 'contractType': {
      c.contractType = inflateContractType(update.stringVal);
      if (c.contractType === ContractTypes.Direct) c.plans = [];
      break;
    }
    case 'contractOwnerType': {
      c.contractOwnerType = inflateContractOwnerTypes(update.stringVal);
      break;
    }
    default: {
      break;
    }
  }
  return c;
}

export function insertOrReplaceRoster(
  rosters: ContractRoster[],
  addedOrUpdatedRoster: ContractRoster
): ContractRoster[] {
  const updatedRosters = [...rosters];

  const existingRosterIndex = updatedRosters.findIndex(
    roster => roster.id === addedOrUpdatedRoster.id
  );
  if (existingRosterIndex >= 0) {
    updatedRosters[existingRosterIndex] = addedOrUpdatedRoster;
  } else {
    updatedRosters.push(addedOrUpdatedRoster);
  }

  return updatedRosters;
}

function removeRosterFromContract(rosters: ContractRoster[], rosterID: number): ContractRoster[] {
  return rosters.filter(r => r.id !== rosterID);
}

function updateContractTerms(
  terms: ContractTerm[],
  returnedID: number,
  term: ContractTerm
): ContractTerm[] {
  if (returnedID === term.id) return terms.map(t => (t.id === term.id ? term : t));
  return [...terms, { ...term, id: returnedID }];
}

function termEntryIsAllowed([key, value]: TermEntry): boolean {
  return (
    !!value &&
    allowedTermItems[key] &&
    !['id', 'comparisonType'].includes(key) &&
    (!isValidArray(value) || isArrayFilled(value))
  );
}

function returnTermItem([key]: TermEntry): TermItem {
  return key as TermItem;
}

export function gatherSelectedTermItems(term: ContractTerm): TermItem[] {
  return Object.entries(term).filter(termEntryIsAllowed).map(returnTermItem);
}

export function selectAndSortTermItems(term: ContractTerm): TermItem[] {
  return orderTermItems(gatherSelectedTermItems(term), [ruleTypes, rangeTypes, conditionTypes]);
}
