import { colors } from '@apps/shared/src/style';

type FlagType = {
  color: string;
  label: string;
  warning: boolean;
  title: string;
  id: string;
};

const flagTypes: Record<string, FlagType> = {
  contract: {
    color: colors.orange,
    label: 'Contract flag',
    warning: true,
    title: 'This claim may have an associated contract',
    id: 'possible-associated-contract-alert',
  },
  associatedContract: {
    color: colors.orange,
    label: 'Contract flag',
    warning: true,
    title: 'This claim has an associated contract',
    id: 'associated-contract-alert',
  },
  eligibility: {
    color: colors.red,
    label: 'Eligibility alert flag',
    warning: false,
    title: 'This claim has one or more of the following alerts associated with it:',
    id: 'eligibility-alert',
  },
  duplicate: {
    color: colors.grey,
    label: 'Duplicate alert flag',
    warning: false,
    title: 'Expand to view possible duplicates',
    id: 'duplicate-alert',
  },
  dupeErr: {
    color: colors.blue,
    label: 'Duplicate Error',
    warning: false,
    title: 'There was an error while checking for duplicates',
    id: 'duplicate-error-alert',
  },
  contractErr: {
    color: colors.blue,
    label: 'Contract Matching Error',
    warning: false,
    title: 'There was an error while checking for contract matches',
    id: 'contract-match-error',
  },
  default: {
    color: colors.green,
    label: 'Generic alert flag',
    warning: false,
    title: 'This claim has one or more of of the following alerts associated with it:',
    id: 'generic-alert',
  },
};

export default flagTypes;
