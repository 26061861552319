import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { logout } from '@apps/shared/src/auth/userActions';
import { useHistory } from 'react-router-dom';

const mapDispatchToProps = {
  logout,
};

type DispatchProps = {
  logout: () => void;
};

type ParentProps = {
  isAccountDisabled: boolean;
};

type Props = ParentProps & DispatchProps;

const AccountDisabled = ({ isAccountDisabled }: Props) => {
  const history = useHistory();

  const handleReturnToLogin = () => {
    history.push('/logout');
  };

  return (
    <Dialog open={isAccountDisabled} hideBackdrop disableEscapeKeyDown>
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '2rem 0',
          }}
        >
          <img src="./images/6_degrees_logo.svg" style={{ width: '10rem' }} alt="6 degrees logo" />
        </div>
        <DialogContent>
          <Typography>
            We’re sorry, but your account has been disabled. If you believe this is in error or
            would like the account reactivated, please contact{' '}
            <a href="mailto:appsupport@6degreeshealth.com">appsupport@6degreeshealth.com</a>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReturnToLogin} color="primary" variant="contained">
            Return to Login
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default connect(null, mapDispatchToProps)(AccountDisabled);
