import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import ContractFooterOwner from './ContractFooterOwner';
import ContractFooterHandlers from './ContractFooterHandlers';
import ContractFooterStatus from './ContractFooterStatus';
import ContractFooterButtons from './ContractFooterButtons';
import DeleteContractButton from './DeleteContractButton';

import { checkContractRoles, isSuperClaimsContractUser } from '../contractUtilities';
import { ContractDetail } from '../types/contracts';
import { RootState } from '../../shared/types/types';
import { isSimpleUser } from '../../shared/types/typeGuards';

import { updateContract, deleteContract } from '../contractsActions';

const useStyles = makeStyles({
  footer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1rem',
  },
  footerRight: {
    display: 'flex',
  },
  footerItem: {
    marginLeft: '1em',
  },
});

type StateProps = {
  contract: ContractDetail;
  userID: string;
  roles: string[];
};
type DispatchProps = {
  updateContract: (action: string, value: string | number) => void;
  deleteContract: VoidFunction;
};
type Props = StateProps & DispatchProps;

export function ContractFooter({
  contract,
  updateContract,
  roles,
  userID,
  deleteContract,
}: Props): JSX.Element {
  const classes = useStyles();
  const { isContractor, isContractAdmin } = checkContractRoles(roles);
  const isSuperAdmin = isSuperClaimsContractUser(roles);
  const { owner, status, lastEditedBy, dataEnterer } = contract;
  const isOwner = isSimpleUser(owner) && userID === owner.userID;
  return (
    <div className={classes.footer}>
      <div className={classes.footerRight}>
        <ContractFooterOwner owner={owner} />
        <ContractFooterHandlers
          dataEnterer={lastEditedBy.length > 0 ? lastEditedBy : dataEnterer?.fullName}
        />
        <ContractFooterStatus status={status} />
        <ContractFooterButtons
          status={status}
          updateContract={updateContract}
          isContractor={isContractor}
          isOwner={isOwner}
          // can take ownership of contract in dataEntry status
          isSuperAdmin={isSuperAdmin}
        />
      </div>
      {((isContractAdmin && isContractor) || isOwner) && (
        <DeleteContractButton
          deleteContract={deleteContract}
          isContractor={isContractor}
          isContractAdmin={isContractAdmin}
          isOwner={isOwner}
        />
      )}
    </div>
  );
}

const mapStateToProps = ({ contracts, user }: RootState): StateProps => {
  return {
    contract: contracts.selectedContract,
    userID: user?.currentUser?.userID || '',
    roles: user?.currentUser?.roles || [],
  };
};

const mapDispatchToProps = {
  updateContract,
  deleteContract,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractFooter);
