import {
  required,
  updateAndValidate,
  isBeforeNow,
  checkDate,
  validateAll,
  getZipErrMsg,
} from '../shared/validators';
import { UPDATE_VALUE, VALIDATE } from './rootReducer';

export const updateIndivOrOrgValue = (path, name, value) => {
  return { type: `${path}/${UPDATE_VALUE}`, payload: { name, value } };
};

const errorTextGetter = {
  id: state => getIDErrMsg(state),
  dateOfBirth: state => getDOBErrMsg(state),
  zip: state => getZipErrMsg(state),
};

function getIDErrMsg(state) {
  return !state.isRequired ? '' : required(state.id);
}

function getDOBErrMsg(state) {
  const [isValid, , error] = checkDate(state.dateOfBirth);
  return isValid ? isBeforeNow(state.dateOfBirth) : error;
}

export const newPersonOrOrg = isRequired => {
  return {
    firstName: '',
    lastNameOrOrg: '',
    id: '',
    dateOfBirth: '',
    zip: '',
    isRequired,
  };
};

export const individualOrOrgReducer = (state = newPersonOrOrg(), action) => {
  switch (action.type) {
    case VALIDATE:
      return validateAll(state, errorTextGetter);

    case UPDATE_VALUE:
      return updateAndValidate(
        state,
        action.payload.name,
        action.payload.value,
        errorTextGetter[action.payload.name],
        true
      );

    default:
      return state;
  }
};

export default individualOrOrgReducer;
