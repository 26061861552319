import React, { Component } from 'react';

type ParentProps = {
  children: JSX.Element;
};

type Props = ParentProps;

// must wrap components to print in class component to hold state
// eslint-disable-next-line react/prefer-stateless-function
class PDFPrintClassWrapper extends Component<Props> {
  render(): JSX.Element {
    return <div>{this.props.children}</div>;
  }
}

export default PDFPrintClassWrapper;
