import React from 'react';

import * as types from '../shared/types/propTypes';
import { formatName, formatLocation } from '../shared/globals';

export default function Provider({ provider }) {
  if (!provider) return null;
  const { firstName, lastNameOrOrg, ein, npi } = provider;
  return (
    <div>
      <div>{formatName(firstName, lastNameOrOrg)}</div>
      <div>{formatLocation(provider)}</div>
      {ein && <div>EIN: {ein}</div>}
      {npi && (
        <div>
          <span>NPI: </span>
          <a
            href={`https://npiregistry.cms.hhs.gov/provider-view/${npi}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {npi}
          </a>
        </div>
      )}
    </div>
  );
}

Provider.propTypes = {
  provider: types.provider.isRequired,
};
