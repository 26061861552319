import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { colors, hexToRgba } from '@apps/shared/src/style';

import { ContractProvider, ContractRosterTheme } from '../types/contracts';
import { evaluateProviderName } from '../contractUtilities';
import {
  FocusHandler,
  ClickHandler,
  KeyHandler,
  InputChangeHandler,
} from '../../shared/types/types';

const useStyles = makeStyles<ContractRosterTheme>(theme => ({
  tableRow: {
    height: theme.contractRoster.table.bodyRowHeight,
  },
  unselectedTableRow: {
    '&:hover': {
      background: hexToRgba(colors.blue, 0.15),
    },
  },
  selectedTableRow: {
    background: hexToRgba(colors.blue, 0.35),
  },
  missingName: {
    fontStyle: 'italic',
    color: colors.red,
  },
}));

type Props = {
  rowIndex: number;
  provider: ContractProvider;
  isSelected: boolean;
  isNewRow: boolean;
  handleSelectRow?: FocusHandler;
  handleBlur?: FocusHandler;
  handleDelete?: ClickHandler;
  handleNPIChange?: InputChangeHandler;
  handleTaxIDChange?: InputChangeHandler;
  handleKeyDownFromLastCell?: KeyHandler;
};

export default function EditableProviderRow({
  rowIndex,
  provider,
  isSelected,
  isNewRow,
  handleSelectRow,
  handleBlur,
  handleDelete,
  handleNPIChange,
  handleTaxIDChange,
  handleKeyDownFromLastCell,
}: Props): JSX.Element {
  const classes = useStyles();
  const npiRef = useRef<HTMLInputElement>(null);

  // Autofocus npi input on initial render of a new selected row
  useEffect(() => {
    if (npiRef.current && isNewRow) npiRef.current.select();
  }, [isNewRow]);

  const { npi, npiError, taxID, taxIDError, name } = provider;
  const { isMissingName, displayName } = evaluateProviderName(name, npi);
  const displayNameClass = isMissingName ? classes.missingName : undefined;
  const selectedRowClass = isSelected ? classes.selectedTableRow : classes.unselectedTableRow;

  return (
    <TableRow
      className={classNames(classes.tableRow, selectedRowClass)}
      onFocus={handleSelectRow}
      onBlur={handleBlur}
    >
      <TableCell>
        {handleDelete && (
          <IconButton
            tabIndex={-1}
            onClick={handleDelete}
            aria-label="Delete Row"
            data-row-index={rowIndex}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>

      <TableCell>
        <TextField
          inputRef={isSelected ? npiRef : undefined}
          inputProps={{
            'data-row-index': rowIndex,
          }}
          value={npi}
          onChange={handleNPIChange}
          error={Boolean(npiError)}
          helperText={npiError}
        />
      </TableCell>
      <TableCell>
        <TextField
          inputProps={{
            'data-row-index': rowIndex,
          }}
          value={taxID}
          onChange={handleTaxIDChange}
          error={Boolean(taxIDError)}
          helperText={taxIDError}
          onKeyDown={handleKeyDownFromLastCell}
        />
      </TableCell>
      <TableCell className={displayNameClass}>{displayName}</TableCell>
    </TableRow>
  );
}
