import React from 'react';
import { ContractPatient } from '../types/contracts';
import { validArray } from '../../shared/typeChecks';

function displayAliases(aliases: string[]): JSX.Element[] {
  return validArray(aliases).map(a => <div key={a}>A.K.A. {a}</div>);
}

export default function PatientTooltipTitle(patient: ContractPatient): JSX.Element {
  const { name, aliases, memberID, dob, id } = patient;

  return (
    <div key={patient.id}>
      {name && <div>{name}</div>}
      {aliases && displayAliases(aliases)}
      {memberID && <div>Member ID: {memberID}</div>}
      {dob && <div>DOB: {dob}</div>}
      {!id && (
        <div>
          <hr />* Patient not found in Eligibility Records at time of recording
        </div>
      )}
    </div>
  );
}
