import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
  censusNames: {
    marginBottom: '20px',
  },
  censusChip: {
    margin: '5px',
  },
});

type ParentProps = {
  masterCensus: string[];
  handleRemoveClick?: (index: number) => VoidFunction;
};

type Props = ParentProps;

export default function CensusNames({ masterCensus, handleRemoveClick }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.censusNames}>
      <Typography variant="body2" color="textSecondary">
        Master Census:
      </Typography>
      {masterCensus.map((name, i) => {
        return (
          <Chip
            key={name}
            label={name}
            className={classes.censusChip}
            onDelete={handleRemoveClick ? handleRemoveClick(i) : undefined}
          />
        );
      })}
    </div>
  );
}
