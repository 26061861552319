import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@mui/material/Input';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { colors } from '@apps/shared/src/style';
import { GridColumn } from '../shared/grid';
import styledAvatar from '../shared/components/styledAvatar';
import MenuItemCompact from '../shared/components/MenuItemCompact';

import * as types from '../shared/types/propTypes';

import { updateQpa, updateRepricing, updateFormulaType } from './claimsActions';
import TextInput from './TextInput';
import { formulas, formulaList } from './formulas';
import ZipCodeDialog from './ZipCodeDialog';

const Avatar = styledAvatar(20, colors.grey37);

const styles = {
  tooltip: {
    fontSize: '14px',
  },
};

function getFormula(formulaType) {
  return formulas[formulaType];
}

const mapDispatchToProps = {
  updateRepricing,
  updateFormulaType,
  updateQpa,
};

export class Formula extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openZipCode: false,
      closeZipCode: false,
    };
  }

  openZipCodeDialog = () => {
    this.setState({ openZipCode: true });
  };

  closeZipCodeDialog = () => {
    this.setState({ openZipCode: false });
  };

  setType(type) {
    const { repricing, updateFormulaType, saveMetadata } = this.props;
    this.setState({ open: false });

    if (repricing.formulaType !== type) {
      updateFormulaType({
        ...saveMetadata,
        formulaType: type,
      });
    }
  }

  handleMenuOpen = e => {
    e.preventDefault();
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  handleMenuClose = () => this.setState({ open: false });

  render() {
    const { repricing, svc, showqpa, saveMetadata, classes, updateRepricing, updateQpa } =
      this.props;

    const selectedFormula =
      getFormula(svc?.swappedRepriceWithQPA ? 'qpa' : repricing.formulaType) ?? {};

    const menu = (
      <Popover
        open={this.state.open}
        onClose={this.handleMenuClose}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <MenuList>
          {repricing.formulaType && (
            <MenuItemCompact onClick={() => this.setType('')}>
              <em>None</em>
            </MenuItemCompact>
          )}
          {formulaList
            .filter(formula => showqpa || formula.name !== 'QPA')
            .map(formula => (
              <MenuItemCompact
                key={formula.key}
                onClick={() => this.setType(formula.key)}
                selected={selectedFormula.name === formula.name}
              >
                {formula.name}
              </MenuItemCompact>
            ))}
        </MenuList>
      </Popover>
    );

    const zipCodeDialog = (
      <ZipCodeDialog
        open={this.state.openZipCode}
        close={this.state.closeZipCode}
        openZipDialog={this.openZipCodeDialog}
        closeZipDialog={this.closeZipCodeDialog}
        save={updateQpa}
        saveMetadata={saveMetadata}
      />
    );

    const formulaButton = (
      <GridColumn>
        <Tooltip
          title={`${selectedFormula.name}: ${selectedFormula.formula}`}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Avatar
            aria-label="formulaButton"
            style={{ cursor: 'pointer', marginLeft: '30%' }}
            onClick={this.handleMenuOpen}
            id="formulaButton"
          >
            <span style={{ fontSize: '14px' }}>f</span>
            <span style={{ fontSize: '10px' }}>x</span>
          </Avatar>
        </Tooltip>
        {menu}
      </GridColumn>
    );

    if (!selectedFormula.fields) {
      return (
        <GridColumn style={{ gridColumn: 'formula' }}>
          <Button
            id={`s${saveMetadata.serviceIndex}-formula-button`}
            variant="outlined"
            onClick={this.handleMenuOpen}
          >
            Select Reference Type
          </Button>
          {menu}
        </GridColumn>
      );
    }

    return (
      <>
        {formulaButton}
        {selectedFormula.fields(repricing).map(field => (
          <GridColumn id={`${saveMetadata.serviceIndex}-${field.id}`} key={field.fieldName}>
            {field.id === 'qpa' ? (
              <>
                <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '20ch' }}>
                  <Input
                    id={`${saveMetadata.serviceIndex}-${field.id}-input`}
                    value={svc.qpaZipCode}
                    label={field.label}
                    aria-describedby="standard-zip-code-helper-text"
                    inputProps={{
                      'aria-label': 'Zip code',
                      style: { fontSize: '14px' },
                    }}
                    onClick={this.openZipCodeDialog}
                    fullWidth
                    readOnly
                  />
                  <FormHelperText id="standard-zip-code-helper-text">Zip Code</FormHelperText>
                </FormControl>
              </>
            ) : (
              <TextInput
                id={`${saveMetadata.serviceIndex}-${field.id}-input`}
                value={field.value}
                label={field.label}
                labelHover={field.labelFull}
                isPercent={field.isPercent}
                fullWidth
                saveMetadata={{ ...saveMetadata, fieldName: field.fieldName }}
                save={updateRepricing}
              />
            )}
          </GridColumn>
        ))}
        {zipCodeDialog}
      </>
    );
  }
}

Formula.defaultProps = {
  repricing: {},
  saveMetadata: {},
};
Formula.propTypes = {
  repricing: types.repricing,
  saveMetadata: types.saveMetadata,
  updateRepricing: PropTypes.func.isRequired,
  updateQpa: PropTypes.func.isRequired,
  updateFormulaType: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Formula));
