/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContractTypes, ContractOwnerTypes } from './contracts';
import { isContractType, isContractOwnerTypes } from './typeGuards';

export function inflateContractType(t: any): ContractTypes {
  if (isContractType(t)) return t;
  return ContractTypes.Invalid;
}

export function inflateContractOwnerTypes(t: any): ContractOwnerTypes {
  return isContractOwnerTypes(t) ? t : '';
}
