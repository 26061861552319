import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DupeTableRow from './DupeTableRow';
import * as types from '../../types/propTypes';
import { validArray } from '../../typeChecks';

const useStyles = makeStyles(theme => ({
  table: theme.flagTable.table,
  tableHead: theme.flagTable.tableHead,
}));

export const columnHeaders = ['Link', 'Date Received', 'Ref #', 'Claim #', 'Match', 'CPP Match'];

export default function DupeTable({ dupes, color }) {
  const classes = useStyles({ color });

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columnHeaders.map(header => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {validArray(dupes).map((dupe, i) => (
            <DupeTableRow key={dupe.matchID} dupe={dupe} isEven={(i + 1) % 2 === 0} color={color} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DupeTable.propTypes = {
  dupes: PropTypes.arrayOf(types.dupe).isRequired,
  color: PropTypes.string.isRequired,
};
