import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { colors } from '@apps/shared/src/style';
import { ContractProvider, ContractRosterTheme } from '../types/contracts';
import { evaluateProviderName } from '../contractUtilities';

const useStyles = makeStyles<ContractRosterTheme>(theme => ({
  missingName: {
    fontStyle: 'italic',
    color: colors.red,
  },
}));

type Props = {
  provider: ContractProvider;
};

export default function ReadOnlyProviderRow({ provider }: Props): JSX.Element {
  const classes = useStyles();
  const { npi, taxID, name } = provider;
  const { isMissingName, displayName } = evaluateProviderName(name, npi);
  const displayNameClass = isMissingName ? classes.missingName : undefined;

  return (
    <TableRow>
      <TableCell>{npi}</TableCell>
      <TableCell>{taxID}</TableCell>
      <TableCell className={displayNameClass}>{displayName}</TableCell>
    </TableRow>
  );
}
