import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import { validArray } from '../../typeChecks';

const styles = {
  container: {
    marginTop: '1em',
    flexGrow: 1,
    position: 'relative',
  },
  compact: {
    flexGrow: 1,
    position: 'relative',
  },
  dropdown: {
    maxWidth: '100vw',
    overflowY: 'auto',
  },
};

function getMaxAttribute(attribute, node) {
  if (node) {
    switch (attribute) {
      case 'height': {
        return `calc(100vh - ${node.getBoundingClientRect().top + node.clientHeight}px)`;
      }
      case 'width': {
        return node.clientWidth + 24;
      }
      default: {
        return undefined;
      }
    }
  }
  return undefined;
}

class AutocompleteSelect extends PureComponent {
  popperNode;

  handleKeyDown = e => {
    if (e.keyCode === 27 && this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  itemToString = item => (item ? item.name : '');

  setInputRef = node => {
    this.popperNode = node;
  };

  render() {
    const {
      classes,
      className,
      inputValue,
      onSelect,
      onChange,
      onBlur,
      onClick,
      onFocus,
      onStateChange,
      placeholder,
      label,
      isOpen,
      options,
      compact,
      RenderComponent,
    } = this.props;

    const containerClasses = compact ? [classes.compact] : [classes.container];
    if (className) containerClasses.push(className);

    return (
      <Downshift
        inputValue={inputValue}
        onSelect={onSelect}
        itemToString={this.itemToString}
        onStateChange={onStateChange}
      >
        {({ getInputProps, getItemProps, inputValue, highlightedIndex, clearSelection }) => {
          const inputProps = {
            ...getInputProps({
              onChange: e => {
                if (e.target.value === '') clearSelection();
                onChange(e);
              },
              onBlur,
              onClick,
              onFocus,
              placeholder,
              onKeyDown: this.handleKeyDown,
              value: inputValue,
              inputRef: this.setInputRef,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }),
          };

          return (
            <div className={classNames(containerClasses)}>
              <TextField InputProps={inputProps} fullWidth label={label} />
              <Popper
                open={isOpen}
                anchorEl={this.popperNode}
                style={{ minWidth: getMaxAttribute('width', this.popperNode), zIndex: 200 }}
                placement="bottom-start"
                modifiers={{
                  flip: { enabled: false },
                  preventOverflow: { enabled: true, boundariesElement: 'window' },
                }}
              >
                <Paper
                  square
                  className={classes.dropdown}
                  style={{ maxHeight: getMaxAttribute('height', this.popperNode) }}
                >
                  {validArray(options).map((option, index) => (
                    <RenderComponent
                      key={JSON.stringify(option)}
                      option={option}
                      index={index}
                      highlightedIndex={highlightedIndex}
                      getItemProps={getItemProps}
                    />
                  ))}
                </Paper>
              </Popper>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

AutocompleteSelect.defaultProps = {
  className: undefined,
  inputValue: '',
  isOpen: false,
  options: [],
  placeholder: '',
  onClick: undefined,
  onFocus: undefined,
  onBlur: undefined,
  label: undefined,
  compact: false,
  onStateChange: undefined,
};
AutocompleteSelect.propTypes = {
  RenderComponent: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  compact: PropTypes.bool,
  onStateChange: PropTypes.func,
};

export default withStyles(styles)(AutocompleteSelect);
