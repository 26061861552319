import React from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';

import { inflateNumber } from '@apps/shared/src/inflators';
import SettlementMethodOption from './SettlementMethodOption';
import { SettlementsClaimSummary, SettlementMethodology, PlanImpact } from '../types/types';
import {
  updateAllSettlementAmounts,
  updateSettlementMethodology,
  updateSettlementPercent,
} from '../settlementsActions';
import { RootState, InputChangeHandler } from '../../shared/types/types';
import { anyMessages } from '../settlementsReducer';

const useStyles = makeStyles({
  methodology: {
    width: '350px',
    marginRight: '64px',
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    claims: settlements.claims,
    settlementMethodology: settlements.settlementMethodology,
    settlementPercent: settlements.settlementPercent,
    planImpact: settlements.planImpact,
    messages: settlements.messages,
  };
};

type StateProps = {
  claims: SettlementsClaimSummary[];
  settlementMethodology: SettlementMethodology;
  planImpact?: PlanImpact;
  settlementPercent: number;
  messages?: Record<string, string[]>;
};

const mapDispatchToProps = {
  updateAllSettlementAmounts,
  updateSettlementMethodology,
  updateSettlementPercent,
};

type Props = StateProps & typeof mapDispatchToProps;

export function SettlementMethodForm({
  claims,
  settlementMethodology,
  updateAllSettlementAmounts,
  updateSettlementMethodology,
  updateSettlementPercent,
  planImpact,
  settlementPercent,
  messages,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (claims.length === 0) return null;

  const isFormDisabled = Boolean(planImpact || (messages && anyMessages(messages)));

  const handleChange: InputChangeHandler = e => {
    updateSettlementPercent(inflateNumber(e.target.value));
  };

  const handleBlur = (): void => {
    updateAllSettlementAmounts();
  };

  const handleSubmit: InputChangeHandler = e => {
    const methodology = e.target.value;
    updateSettlementMethodology(methodology as SettlementMethodology);
    updateAllSettlementAmounts();
  };

  return (
    <div className={classes.methodology}>
      <Typography variant="h5">Methodology:</Typography>

      <RadioGroup className={classes.options} value={settlementMethodology} onChange={handleSubmit}>
        <SettlementMethodOption
          label="Percent of Bill"
          selectedMethodology={settlementMethodology}
          methodology="billedAmount"
          percentValue={settlementPercent}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={isFormDisabled}
        />
        <SettlementMethodOption
          label="Percent of Medicare"
          selectedMethodology={settlementMethodology}
          methodology="medicareAllowed"
          percentValue={settlementPercent}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={isFormDisabled}
        />
        <SettlementMethodOption
          label="Manual Entry"
          methodology="manual"
          selectedMethodology={settlementMethodology}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={isFormDisabled}
        />
      </RadioGroup>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementMethodForm);
