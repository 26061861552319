import React from 'react';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { ClickHandler } from '../../../shared/types/types';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  handleSaveClick: ClickHandler;
  handleCompleteAndSubmitClick: ClickHandler;
  handleNewClaimClick: ClickHandler;
};

export default function NewManualClaimButtons({
  handleSaveClick,
  handleCompleteAndSubmitClick,
  handleNewClaimClick,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Tooltip title="Save Changes to Claim">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleSaveClick}
          id="save-claim-button"
        >
          Save
        </Button>
      </Tooltip>

      <Tooltip title="Save Claim and Submit for Repricing">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleCompleteAndSubmitClick}
          id="save-and-submit-claim-button"
        >
          Save and Submit
        </Button>
      </Tooltip>

      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={handleNewClaimClick}
        id="reset-form-button"
      >
        Reset Form
      </Button>
    </>
  );
}
