import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function BreakoutButton({ toggleIsBreakout, isBreakout }) {
  return (
    <td>
      {toggleIsBreakout && (
        <IconButton size="small" onClick={toggleIsBreakout}>
          {isBreakout ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      )}
    </td>
  );
}

BreakoutButton.defaultProps = {
  toggleIsBreakout: undefined,
};

BreakoutButton.propTypes = {
  isBreakout: PropTypes.bool.isRequired,
  toggleIsBreakout: PropTypes.func,
};
