import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatCurrency } from '@apps/shared/src/utilities';
import { updateRepricing } from './claimsActions';
import { getStatementDateString } from '../shared/globals';
import { Grid, GridRow, GridColumn } from '../shared/grid';
import Formula from './Formula';
import Notes from './Notes';
import AdjudicationFields from './AdjudicationFields';
import HoverableText from './HoverableText';
import ServiceReferences from './ServiceReferences';
import * as types from '../shared/types/propTypes';
import RepricingMethodology from './RepricingMethodology';
import RepriceInput from './RepriceInput';

const colon = ':\u200b'; // Zero-width space after colon to allow word wrapping of long codes

function joinProcedure(svc, statementDate) {
  const { procQual, procCode, procMod, procDesc } = svc;
  const procedure = [];
  if (procQual && procCode) {
    // Matches formatting in Go FormatProcedure
    procedure.push(procQual, procCode);
    if (procMod) {
      procedure.push(...procMod);
    }
    if (procDesc) {
      // Ensure we have 4 procedure modifiers
      if (procedure.length < 6) {
        procedure.length = 6;
      }
      procedure.push(procDesc);
    }
  }
  return (
    <HoverableText
      type="procedure"
      value={procedure.join(':')}
      date={svc.serviceFromDate || statementDate}
    >
      {procedure.join(colon)}
    </HoverableText>
  );
}

function SvcColumns({ svc, saveMetadata, isAdjud, isProf, hasAdjudData, id }) {
  const {
    serviceFromDate,
    serviceToDate,
    revCode,
    diagnosisCodePointers,
    quantity,
    billedCharge,
    placeOfSvc,
    adjudication,
  } = svc;

  return (
    <>
      {isProf && (
        <GridColumn id={`${id}-service-date`}>
          {getStatementDateString(serviceFromDate, serviceToDate)}
        </GridColumn>
      )}
      {!isProf && <GridColumn id={`${id}-rev-code`}>{revCode}</GridColumn>}
      <GridColumn id={`${id}-procedure`} style={{ hyphens: 'auto' }}>
        {joinProcedure(svc, saveMetadata.statementDate)}
      </GridColumn>
      {isProf && Array.isArray(diagnosisCodePointers) && (
        <GridColumn id={`${id}-diagnosis-code-pointers`}>
          {diagnosisCodePointers.join(colon)}
        </GridColumn>
      )}
      <GridColumn id={`${id}-quantity`} style={{ margin: 'auto' }}>
        {quantity && parseFloat(quantity.toFixed(2))}
      </GridColumn>
      <GridColumn id={`${id}-billed-charge`}>{formatCurrency(billedCharge)}</GridColumn>
      {isProf && <GridColumn id={`${id}-place-service`}>{placeOfSvc}</GridColumn>}
      {!isAdjud && hasAdjudData && (
        <GridColumn id={`${id}-adjudication`}>
          {adjudication && adjudication.determination}
        </GridColumn>
      )}
      {isAdjud && <AdjudicationFields adjudication={adjudication} saveMetadata={saveMetadata} />}
    </>
  );
}

SvcColumns.defaultProps = {
  svc: {},
  saveMetadata: {},
};
SvcColumns.propTypes = {
  svc: types.serviceItem,
  saveMetadata: types.saveMetadata,
  isAdjud: PropTypes.bool.isRequired,
  isProf: PropTypes.bool.isRequired,
  hasAdjudData: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export function ServiceItem({
  svc,
  saveMetadata,
  showAllValidation,
  isAdjud,
  isProf,
  hasAdjudData,
  columnWidths,
  updateRepricing,
  showQPA,
  qpaAmount,
  qpaLocation,
}) {
  const {
    repricing = {},
    adjudication = {},
    procCode,
    procMod,
    placeOfSvc,
    serviceFromDate,
    swappedRepriceWithQPA,
  } = svc;

  const { extCmnt = {}, formulaType } = repricing;
  const id = `s${saveMetadata.serviceIndex}`;
  const isDenied = adjudication.determination === 'Denied';
  const hcpcs = procCode;
  const hcpcsMods = procMod;
  const placeOfService = placeOfSvc;

  const isRefAdjDisabled = formulaType === undefined || formulaType === '';
  const { showqpa, errMsg } = showQPA;
  return (
    <Grid columns={columnWidths} component={GridRow}>
      <SvcColumns
        svc={svc}
        id={`${id}`}
        isAdjud={isAdjud}
        isProf={isProf}
        hasAdjudData={hasAdjudData}
        saveMetadata={saveMetadata}
      />
      {!isAdjud && !isDenied && (
        <Formula
          svc={svc}
          repricing={repricing}
          showqpa={showqpa}
          saveMetadata={{
            ...saveMetadata,
            hcpcs,
            hcpcsMods,
            placeOfService,
            serviceDate: serviceFromDate,
          }}
        />
      )}
      {!isAdjud && (
        <>
          <ServiceReferences
            refAdj={repricing?.refAdj}
            reference={repricing?.reference}
            saveMetadata={saveMetadata}
            isRefAdjDisabled={isRefAdjDisabled}
          />
          <RepricingMethodology
            svcRepricing={repricing}
            saveMetadata={saveMetadata}
            save={updateRepricing}
          />

          <RepriceInput
            showAllValidation={showAllValidation}
            isDisabled={isDenied}
            svc={svc}
            id={id}
            svcRepricing={repricing}
            repricedTotal={repricing.total}
            saveMetadata={saveMetadata}
            showqpa={showqpa}
            errMsg={errMsg}
            swappedRepriceWithQPA={swappedRepriceWithQPA}
          />
        </>
      )}

      <Notes
        key={`${saveMetadata.claimID}-${id}-${extCmnt && extCmnt.code}`}
        type="line"
        external={extCmnt}
        svc={svc}
        internal={repricing.intCmnt}
        showqpa={showqpa}
        errMsg={errMsg}
        qpaAmount={qpaAmount}
        originalrepricedamt={repricing.originalRepricedAmount}
        qpaLocation={qpaLocation}
        saveMetadata={saveMetadata}
        intCmntFieldName="repricing.intCmnt"
        extCmtFieldName="repricing.extCmnt"
      />
    </Grid>
  );
}

ServiceItem.defaultProps = {
  svc: {},
  saveMetadata: {},
  qpaAmount: null,
  qpaLocation: '',
};
ServiceItem.propTypes = {
  svc: types.serviceItem,
  saveMetadata: types.saveMetadata,
  showAllValidation: PropTypes.bool.isRequired,
  isAdjud: PropTypes.bool.isRequired,
  isProf: PropTypes.bool.isRequired,
  hasAdjudData: PropTypes.bool.isRequired,
  columnWidths: PropTypes.string.isRequired,
  updateRepricing: PropTypes.func.isRequired,
  qpaAmount: PropTypes.number,
  qpaLocation: PropTypes.string,
};

const mapStateToProps = ({ claims }) => ({
  showAllValidation:
    claims.selectedClaim != null && claims.selectedClaim.showAllValidation === true,
});

const mapDispatchToProps = {
  updateRepricing,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceItem);
