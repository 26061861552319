import React from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';

import ContractSearch from './ContractSearch';
import ContractDisplayListItems from './ContractDisplayListItems';

import { HandleListClick } from '../types/contracts';
import { RootState } from '../../shared/types/types';

const useStyles = makeStyles({
  container: {
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 65px)',
  },
});

type ParentProps = {
  handleListClick: HandleListClick;
};
type StateProps = {
  loading: boolean;
  searchText: string;
};
type Props = ParentProps & StateProps;

export function ContractList({ loading, handleListClick, searchText }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <>
        <ContractSearch searchText={searchText} />
        {!loading && <ContractDisplayListItems handleListClick={handleListClick} />}
      </>
    </div>
  );
}

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    loading: contracts.listLoading,
    searchText: contracts.searchText,
  };
};

export default connect(mapStateToProps)(ContractList);
