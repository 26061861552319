import React, { MouseEventHandler } from 'react';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItemCompact from '../shared/components/MenuItemCompact';
import { Formula } from './types/repricingTypes';

type Props = Pick<PopoverProps, 'anchorEl' | 'onClose'> & {
  isOpen: boolean;
  formulaList: Formula[];
  selectedFormula: Formula;
  onFormulaChange: (newFormulaKey: Formula['key']) => void;
};

export default function FormulaMenu({
  anchorEl,
  isOpen,
  formulaList,
  selectedFormula,
  onClose,
  onFormulaChange,
}: Props): JSX.Element {
  const handleMenuItemClick: MouseEventHandler<HTMLLIElement> = event => {
    const { formulaKey } = event.currentTarget.dataset;
    if (selectedFormula.key !== formulaKey) onFormulaChange(formulaKey || '');
  };

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <MenuList>
        {formulaList.map(formula => (
          <MenuItemCompact
            key={formula.key}
            data-formula-key={formula.key}
            onClick={handleMenuItemClick}
            selected={selectedFormula.name === formula.name}
          >
            {formula.name}
          </MenuItemCompact>
        ))}
      </MenuList>
    </Popover>
  );
}
