import axios from 'axios';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_PENDING = `${FETCH_USER}_PENDING`;
export const FETCH_USER_FULFILLED = `${FETCH_USER}_FULFILLED`;
export const FETCH_USER_REJECTED = `${FETCH_USER}_REJECTED`;
export const FETCH_USER_CANCELLED = `${FETCH_USER}_CANCELLED`;

export const GET_APP_USERS = 'GET_APP_USERS';
export const GET_APP_USERS_PENDING = `${GET_APP_USERS}_PENDING`;
export const GET_APP_USERS_FULFILLED = `${GET_APP_USERS}_FULFILLED`;
export const GET_APP_USERS_REJECTED = `${GET_APP_USERS}_REJECTED`;
export const GET_APP_USERS_CANCELLED = `${GET_APP_USERS}_CANCELLED`;

export const GET_USERS = 'GET_USERS';
export const GET_USERS_PENDING = `${GET_USERS}_PENDING`;
export const GET_USERS_FULFILLED = `${GET_USERS}_FULFILLED`;
export const GET_USERS_REJECTED = `${GET_USERS}_REJECTED`;
export const GET_USERS_CANCELLED = `${GET_USERS}_CANCELLED`;

export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_PENDING = `${ADD_ROLE}_PENDING`;
export const ADD_ROLE_FULFILLED = `${ADD_ROLE}_FULFILLED`;
export const ADD_ROLE_REJECTED = `${ADD_ROLE}_REJECTED`;

export const REMOVE_ROLE = 'REMOVE_ROLE';
export const REMOVE_ROLE_PENDING = `${REMOVE_ROLE}_PENDING`;
export const REMOVE_ROLE_FULFILLED = `${REMOVE_ROLE}_FULFILLED`;
export const REMOVE_ROLE_REJECTED = `${REMOVE_ROLE}_REJECTED`;

export const LOGIN = 'LOGIN';
export const LOGIN_PENDING = `${LOGIN}_PENDING`;
export const LOGIN_FULFILLED = `${LOGIN}_FULFILLED`;
export const LOGIN_REJECTED = `${LOGIN}_REJECTED`;

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const CREATE_PROFILE_PENDING = `${CREATE_PROFILE}_PENDING`;
export const CREATE_PROFILE_FULFILLED = `${CREATE_PROFILE}_FULFILLED`;
export const CREATE_PROFILE_REJECTED = `${CREATE_PROFILE}_REJECTED`;

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_PENDING = `${UPDATE_PASSWORD}_PENDING`;
export const UPDATE_PASSWORD_FULFILLED = `${UPDATE_PASSWORD}_FULFILLED`;
export const UPDATE_PASSWORD_REJECTED = `${UPDATE_PASSWORD}_REJECTED`;

export const LOGOUT = 'LOGOUT';
export const LOGOUT_PENDING = `${LOGOUT}_PENDING`;
export const LOGOUT_FULFILLED = `${LOGOUT}_FULFILLED`;
export const LOGOUT_REJECTED = `${LOGOUT}_REJECTED`;

export const REGISTER = 'REGISTER';
export const REGISTER_PENDING = `${REGISTER}_PENDING`;
export const REGISTER_FULFILLED = `${REGISTER}_FULFILLED`;
export const REGISTER_REJECTED = `${REGISTER}_REJECTED`;

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_PENDING = `${REQUEST_PASSWORD_RESET}_PENDING`;
export const REQUEST_PASSWORD_RESET_FULFILLED = `${REQUEST_PASSWORD_RESET}_FULFILLED`;
export const REQUEST_PASSWORD_RESET_REJECTED = `${REQUEST_PASSWORD_RESET}_REJECTED`;

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_PENDING = `${VERIFY_EMAIL}_PENDING`;
export const VERIFY_EMAIL_FULFILLED = `${VERIFY_EMAIL}_FULFILLED`;
export const VERIFY_EMAIL_REJECTED = `${VERIFY_EMAIL}_REJECTED`;

export const VERIFY_PASSWORD_RESET = 'VERIFY_PASSWORD_RESET';
export const VERIFY_PASSWORD_RESET_PENDING = `${VERIFY_PASSWORD_RESET}_PENDING`;
export const VERIFY_PASSWORD_RESET_FULFILLED = `${VERIFY_PASSWORD_RESET}_FULFILLED`;
export const VERIFY_PASSWORD_RESET_REJECTED = `${VERIFY_PASSWORD_RESET}_REJECTED`;

export const REDIRECT_COMPLETE = 'REDIRECT_COMPLETE';
export const REDIRECT_TO = 'REDIRECT_TO';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export function getCurrentUser() {
  const csrfToken = localStorage.getItem('csrfToken');
  if (csrfToken) {
    return {
      type: FETCH_USER,
      payload: axios.get('/api/users/me', { headers: { 'X-CSRF-Token': csrfToken } }),
    };
  }
  return {
    type: FETCH_USER_CANCELLED,
  };
}

export function redirectTo(path) {
  return {
    type: REDIRECT_TO,
    payload: path,
  };
}

export function getAppUsers(appName) {
  return {
    type: GET_APP_USERS,
    payload: axios.get(`/api/app-users/${appName}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
    meta: { appName },
  };
}

export function getUsers() {
  return {
    type: GET_USERS,
    payload: axios.get('/api/users', {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function addRole(userID, role) {
  return {
    type: ADD_ROLE,
    meta: { userID, role },
    payload: axios.put(
      `/api/users/${userID}/add-role`,
      { role },
      { headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') } }
    ),
  };
}

export function removeRole(userID, role) {
  return {
    type: REMOVE_ROLE,
    meta: { userID, role },
    payload: axios.put(
      `/api/users/${userID}/remove-role`,
      { role },
      { headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') } }
    ),
  };
}

export function logout(destinationURL) {
  return {
    type: LOGOUT,
    payload: axios.post(
      '/api/logout',
      {},
      { headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') } }
    ),
    meta: { destinationURL },
  };
}

export function login(email, password, application, destinationURL) {
  return {
    type: LOGIN,
    payload: axios.post('/api/login', { email, password, application, rememberMe: true }),
    meta: { destinationURL },
  };
}

export function verifyEmail(emailVerificationCode) {
  return {
    type: VERIFY_EMAIL,
    payload: axios.post('/api/verifyEmail', { emailVerificationCode }),
  };
}

export function verifyPasswordReset(emailVerificationCode) {
  return {
    type: VERIFY_PASSWORD_RESET,
    payload: axios.post('/api/verifyPasswordReset', { emailVerificationCode }),
  };
}

export function register(email, path, application, destinationURL) {
  return {
    type: REGISTER,
    payload: axios.post(
      `/api${path}`,
      { email, application, destinationURL },
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
    meta: { destinationURL: `${path}-success` },
  };
}

export function requestPasswordReset(email, application, destinationURL) {
  return {
    type: REQUEST_PASSWORD_RESET,
    payload: axios.post('/api/request-password-reset', { email, application, destinationURL }),
    meta: { destinationURL: '/request-password-reset-success' },
  };
}

export function clearRedirect() {
  return {
    type: REDIRECT_COMPLETE,
  };
}

export function resetPassword(password, destinationURL = '/password-reset-success') {
  const data = new FormData();
  data.append('password', password);

  return {
    type: UPDATE_PASSWORD,
    payload: axios.post('/api/updatePassword', data, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
    meta: { destinationURL },
  };
}

export function changePassword(
  email,
  currentPassword,
  newPassword,
  destinationURL = '/password-reset-success'
) {
  const data = JSON.stringify({
    email,
    currentPassword,
    newPassword,
  });

  return {
    type: CHANGE_PASSWORD,
    payload: axios.post('/api/updatePasswordLoggedIn', data, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
    meta: { destinationURL },
  };
}

export function createProfile(fullname, password, destinationURL = '/welcome') {
  const data = new FormData();
  data.append('fullName', fullname);
  data.append('password', password);
  data.append('file', null);

  return {
    type: CREATE_PROFILE,
    payload: axios.post('/api/createProfile', data, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
    meta: { destinationURL },
  };
}
