import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { colors } from '@apps/shared/src/style';
import { updateAdjudication, multiUpdateAdjudication, updateShareAmt } from './claimsActions';
import TextInput from './TextInput';
import Notes from './Notes';
import { Grid, GridRow, GridColumn } from '../shared/grid';
import * as types from '../shared/types/propTypes';

export class ClaimAdjudicationLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 'Placeholder',
      determination: 'Placeholder',
    };
  }

  changeAdjudication = v => this.props.dispatch(updateAdjudication(v));

  changeAllAdjudications = e => {
    const { simpleClaim = {}, saveMetadata, dispatch } = this.props;
    dispatch(
      multiUpdateAdjudication(
        { ...saveMetadata, fieldName: e.target.name, stringVal: e.target.value },
        simpleClaim.services
      )
    );
  };

  changeShareAmt = v => this.props.dispatch(updateShareAmt(v));

  render() {
    const { simpleClaim = {}, saveMetadata, columnWidths } = this.props;
    const { adjudication, extCmnt, intCmnt, forMemberReimbursement } = simpleClaim;

    return (
      <Grid columns={columnWidths} component={GridRow} style={{ backgroundColor: colors.grey2 }}>
        {forMemberReimbursement && (
          <GridColumn style={{ color: colors.red }}>For Member Reimbursement</GridColumn>
        )}
        <GridColumn style={{ gridColumn: 'adjud-start' }}>
          <Select
            name="category"
            label="Category"
            value={this.state.category}
            onChange={this.changeAllAdjudications}
            style={{ width: '100%' }}
          >
            <MenuItem value="Placeholder" disabled>
              Set All
            </MenuItem>
            <MenuItem value="NotAllowed">Not Allowed</MenuItem>
            <MenuItem value="Preventive">Preventive</MenuItem>
            <MenuItem value="Supplemental">Supplemental</MenuItem>
          </Select>
        </GridColumn>
        <GridColumn>
          <Select
            name="determination"
            label="Determination"
            value={this.state.determination}
            onChange={this.changeAllAdjudications}
            style={{ width: '100%' }}
          >
            <MenuItem value="Placeholder" disabled>
              Set All
            </MenuItem>
            <MenuItem value="Denied">Denied</MenuItem>
            <MenuItem value="Allowed">Allowed</MenuItem>
          </Select>
        </GridColumn>
        <GridColumn>
          <TextInput
            id={saveMetadata.claimID}
            isMoney
            value={adjudication && adjudication.shareAmt}
            fullWidth
            saveMetadata={{ ...saveMetadata, fieldName: 'simpleClaim.adjudication.shareAmt' }}
            save={this.changeShareAmt}
          />
        </GridColumn>
        <Notes
          key={`${saveMetadata.claimID}-adj-${extCmnt && extCmnt.code}`}
          type="claim"
          external={extCmnt}
          internal={intCmnt}
          saveMetadata={saveMetadata}
          intCmntFieldName="simpleClaim.intCmnt"
          extCmtFieldName="simpleClaim.extCmnt"
        />
      </Grid>
    );
  }
}

ClaimAdjudicationLine.propTypes = {
  simpleClaim: types.simpleClaim.isRequired,
  saveMetadata: types.saveMetadata.isRequired,
  columnWidths: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ClaimAdjudicationLine);
