import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { Exception } from '../types';

type Props = {
  exception: Exception;
};

export default function ExceptionsTableRow({ exception }: Props): JSX.Element {
  const { name, sixDegTPAID } = exception;

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{sixDegTPAID}</TableCell>
    </TableRow>
  );
}
