import { AxiosPromise, AxiosResponse, AxiosError } from 'axios';
import { User } from '../../auth/types';

enum u {
  GET_USERS = 'GET_USERS',
  GET_USERS_PENDING = 'GET_USERS_PENDING',
  GET_USERS_FULFILLED = 'GET_USERS_FULFILLED',
  GET_USERS_REJECTED = 'GET_USERS_REJECTED',

  ADD_ROLE = 'ADD_ROLE',
  ADD_ROLE_PENDING = 'ADD_ROLE_PENDING',
  ADD_ROLE_FULFILLED = 'ADD_ROLE_FULFILLED',
  ADD_ROLE_REJECTED = 'ADD_ROLE_REJECTED',

  REMOVE_ROLE = 'REMOVE_ROLE',
  REMOVE_ROLE_PENDING = 'REMOVE_ROLE_PENDING',
  REMOVE_ROLE_FULFILLED = 'REMOVE_ROLE_FULFILLED',
  REMOVE_ROLE_REJECTED = 'REMOVE_ROLE_REJECTED',

  INVITE_USER = 'INVITE_USER',
  INVITE_USER_PENDING = 'INVITE_USER_PENDING',
  INVITE_USER_FULFILLED = 'INVITE_USER_FULFILLED',
  INVITE_USER_REJECTED = 'INVITE_USER_REJECTED',
  CLEAR_INVITE_STATUS = 'CLEAR_INVITE_STATUS',
}
export default u;

export type GetUsers = {
  type: typeof u.GET_USERS;
  payload: AxiosPromise<User[]>;
};

type GetUsersPending = {
  type: typeof u.GET_USERS_PENDING;
  payload: AxiosPromise<User[]>;
};

type GetUsersRejected = {
  type: typeof u.GET_USERS_REJECTED;
  payload: AxiosError<[]>;
};

type GetUsersFulfilled = {
  type: typeof u.GET_USERS_FULFILLED;
  payload: AxiosResponse<User[]>;
};

export type GetUsersActions = GetUsers | GetUsersPending | GetUsersRejected | GetUsersFulfilled;

export type AddRole = {
  type: typeof u.ADD_ROLE;
  meta: { userID: string; role: string };
  payload: AxiosPromise<void>;
};

type AddRolePending = {
  type: typeof u.ADD_ROLE_PENDING;
  meta: { userID: string; role: string };
  payload: AxiosPromise<void>;
};

type AddRoleRejected = {
  type: typeof u.ADD_ROLE_REJECTED;
  meta: { userID: string; role: string };
  payload: AxiosError<void>;
};

type AddRoleFulfilled = {
  type: typeof u.ADD_ROLE_FULFILLED;
  meta: { userID: string; role: string };
  payload: AxiosResponse<void>;
};

export type AddRoleActions = AddRole | AddRolePending | AddRoleFulfilled | AddRoleRejected;

export type RemoveRole = {
  type: typeof u.REMOVE_ROLE;
  meta: { userID: string; role: string };
  payload: AxiosPromise<void>;
};

type RemoveRolePending = {
  type: typeof u.REMOVE_ROLE_PENDING;
  meta: { userID: string; role: string };
  payload: AxiosPromise<void>;
};

type RemoveRoleRejected = {
  type: typeof u.REMOVE_ROLE_REJECTED;
  meta: { userID: string; role: string };
  payload: AxiosError<void>;
};

type RemoveRoleFulfilled = {
  type: typeof u.REMOVE_ROLE_FULFILLED;
  meta: { userID: string; role: string };
  payload: AxiosResponse<void>;
};

export type RemoveRoleActions =
  | RemoveRole
  | RemoveRolePending
  | RemoveRoleFulfilled
  | RemoveRoleRejected;

export type InviteUser = {
  type: typeof u.INVITE_USER;
  payload: AxiosPromise;
};

type InviteUserPending = {
  type: typeof u.INVITE_USER_PENDING;
  payload: AxiosPromise;
};

type InviteUserFulfilled = {
  type: typeof u.INVITE_USER_FULFILLED;
  payload: AxiosResponse;
};

type InviteUserRejected = {
  type: typeof u.INVITE_USER_REJECTED;
  payload: AxiosError;
};

export type ClearInviteStatus = {
  type: typeof u.CLEAR_INVITE_STATUS;
};

export type InviteUserActions =
  | InviteUser
  | InviteUserPending
  | InviteUserFulfilled
  | InviteUserRejected
  | ClearInviteStatus;

export type AdminActionTypes =
  | GetUsersActions
  | AddRoleActions
  | RemoveRoleActions
  | InviteUserActions;

export type RoleArg = {
  role: string;
  userID: string;
};
