import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';

import { colors } from '@apps/shared/src/style';
import { getCodeInfo, getContractPlanInfo, qpaHoverInfo } from './claimsActions';
import * as types from '../shared/types/propTypes';

const StyledHover = withStyles({
  tooltip: {
    backgroundColor: 'rgb(80,80,80)',
    fontSize: '14px',
    padding: '8px 12px',
  },
})(Tooltip);

class HoverableText extends PureComponent {
  handleOpen = () => {
    const { type, value, optIn, date, dispatch, qpaHoverData } = this.props;

    if (type === 'qpa') dispatch(qpaHoverInfo(type, value, qpaHoverData));
    else if (!optIn) dispatch(getCodeInfo(type, value, date));
    else dispatch(getContractPlanInfo(value));
  };

  render() {
    const { hoverInfo, children, optIn, type } = this.props;
    let content = '';

    if (Array.isArray(hoverInfo) && hoverInfo.length > 0) {
      content =
        !optIn || type === 'qpa' ? (
          <div style={{ display: 'flex' }}>
            <div style={{ color: colors.yellow, fontWeight: 'bold', marginRight: '10px' }}>
              {hoverInfo.map(code => (
                <div key={`${code.field}-${code.value}`}>{code.field}</div>
              ))}
            </div>
            <div>
              {hoverInfo.map(code => (
                <div key={`${code.field}-${code.value}`}>{code.value}</div>
              ))}
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <div style={{ color: colors.yellow, fontWeight: 'bold', marginRight: '10px' }}>
              {hoverInfo.map(num => (
                <div key={`${num.number}-${num.name}`}>{num.number}</div>
              ))}
            </div>
            <div>
              {hoverInfo.map(name => (
                <div key={`${name.number}-${name.name}`}>{name.name}</div>
              ))}
            </div>
          </div>
        );
    } else if (typeof hoverInfo === 'string') {
      content = hoverInfo;
    }

    return (
      <StyledHover
        style={{
          tooltip: {
            width: optIn ? '600px' : '320px',
          },
        }}
        title={content}
        enterDelay={200}
        onOpen={this.handleOpen}
      >
        <span
          style={{
            textDecoration: 'underline dotted',
            textDecorationColor: colors.grey50,
          }}
        >
          {children}
        </span>
      </StyledHover>
    );
  }
}

HoverableText.defaultProps = {
  date: '',
  hoverInfo: '',
  children: null,
  optIn: false,
};
HoverableText.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  date: PropTypes.string,
  hoverInfo: types.codeEntryOrString,
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  optIn: PropTypes.bool,
};

function mapStateToProps({ claims }, ownProps) {
  const { type, value, optIn } = ownProps;
  let hoverInfo = '';
  if (claims.codeInfo && claims.codeInfo[type] && claims.codeInfo[type][value]) {
    hoverInfo = claims.codeInfo[type][value];
  } else if (optIn && claims.contractPlanInfo?.length > 0) {
    hoverInfo = claims.contractPlanInfo.map(row => {
      return {
        number: row.policyNum,
        name: row.planName,
      };
    });
  }
  return { hoverInfo };
}

export default connect(mapStateToProps)(HoverableText);
