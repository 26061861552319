import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';

import { colors } from '@apps/shared/src/style';

const useStyles = makeStyles({
  toggleInFilter: {
    marginLeft: '-15px',
  },
  greyText: {
    color: colors.grey67,
  },
});

export default function SelectAgingSwitch({ isChecked, toggleShowOnlyAging }) {
  const classes = useStyles();
  const label = isChecked ? 'Show Aging' : 'Custom Date';
  return (
    <span className={classes.toggleInFilter}>
      <Tooltip title="Show Aging Claims">
        <Switch checked={isChecked} onChange={toggleShowOnlyAging} value={label} />
      </Tooltip>
      <span className={isChecked ? '' : classes.greyText}>{label}</span>
    </span>
  );
}

SelectAgingSwitch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  toggleShowOnlyAging: PropTypes.func.isRequired,
};
