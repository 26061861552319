import { UserState } from '@apps/shared/src/auth/types';
import { requestPasswordReset } from '@apps/shared/src/auth/userActions';
import { PasswordResetArg } from '@apps/shared/src/auth/userActionTypes';
import { setLoginInfo } from '@apps/shared/src/register/actions';
import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../components/LoadingIndicator';
import { RegisterState } from '../register/types/register';
import { colors } from '../style/colors';

const useStyles = makeStyles({
  container: {
    width: '81%',
    maxWidth: '400px',
    margin: 'auto',
    marginTop: '15%',
  },
  instructions: {
    fontSize: '18px',
  },
  emailValidator: {
    margin: '0.5rem 0 1.5rem',
  },
  buttonText: {
    textTransform: 'none',
    color: 'white',
  },
  backToLogin: {
    marginTop: '.5rem',
    textTransform: 'none',
  },
  resendResetDialog: {
    height: '15%',
  },
  dialogButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  dialogText: {
    maxWidth: '75%',
    margin: 'auto',
    padding: '7% 0 15%',
  },
});

type StateProps = {
  isFetching: UserState['isFetching'];
  email: string;
};

const mapStateToProps = ({
  user,
  register,
}: {
  user: UserState;
  register: RegisterState;
}): StateProps => ({
  isFetching: user.isFetching,
  email: register.loginInfo.email,
});

type DispatchProps = {
  setLoginInfo: typeof setLoginInfo;
  requestPasswordReset: ({ email, destinationURL, isResend }: PasswordResetArg) => void;
};

const mapDispatchToProps = {
  setLoginInfo,
  requestPasswordReset,
};

type Props = StateProps & DispatchProps;

export function PasswordResetForm({
  isFetching,
  email,
  requestPasswordReset,
  setLoginInfo,
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const getApplication =
    window.location.origin.includes('claims') || window.location.origin.includes('3000')
      ? 'Claims'
      : 'MediVI';

  const handleEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLoginInfo('email', e.target.value);
  };

  const handlePasswordResetRequest = (): void => {
    requestPasswordReset({
      email,
      destinationURL: '/login',
      isResend: false,
      application: getApplication,
    });
    setOpenDialog(true);
  };

  const handleBackToLoginClick: VoidFunction = () => {
    history.push('/login');
  };

  return (
    <>
      <ValidatorForm onSubmit={handlePasswordResetRequest} className={classes.container}>
        <Grid container direction="column" justify="space-between" spacing={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={classes.instructions}>
              Please enter the email address associated with your account
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextValidator
              name="email"
              id="email"
              type="email"
              className={classes.emailValidator}
              label="Email"
              onChange={handleEmailAddressChange}
              value={email}
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'Must be valid email']}
              fullWidth
              disabled={isFetching}
            />
          </Grid>

          <Grid item xs={12} style={{ width: '65%', marginLeft: '17%' }}>
            <Button
              style={{ backgroundColor: '#EF7125' }}
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              className={classes.buttonText}
              disabled={isFetching}
            >
              Request Password Reset
            </Button>
            <Button
              style={{ width: '100%' }}
              onClick={handleBackToLoginClick}
              disabled={isFetching}
              className={classes.backToLogin}
              name="Back to login"
            >
              {'< Back to login'}
            </Button>
          </Grid>
        </Grid>
        <Dialog title="Email Sent" open={openDialog} style={{ overflowY: 'hidden' }}>
          <p className={classes.dialogText}>{`An email is being sent to ${email}`}</p>
          <div style={{ margin: '-10% auto 5%' }}>
            <LoadingIndicator isLoading={isFetching} size={25} color={colors.orange} />
          </div>
        </Dialog>
      </ValidatorForm>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
