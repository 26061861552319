import MonatFormat from './MonatFormat';

const sanitizePattern = /[^\d]/g;
export const delimPattern = /[/-]/g;

export function validYearPartial(year, flexibleYear) {
  return (
    !year ||
    (flexibleYear && year.length <= 2) ||
    (year.length <= 4 && /^20|^19|^[21]?$/.test(year))
  );
}
export function validMonthPartial(month) {
  return !month || month.length <= 1 || validMonth(month);
}
export function validDayPartial(day, month, year) {
  return !day || day.length <= 1 || validDay(day, month, year);
}

export function validBlock(block, blockType, year, month) {
  switch (blockType) {
    case 'yyyy':
      return validYear(block);
    case 'mm':
      return validMonth(block);
    case 'dd':
      return validDay(block, month, year);
    default:
      return false;
  }
}

export function validYear(year) {
  return year && year.length === 4 && +year >= 1900 && +year <= 2099;
}

export function validMonth(month) {
  return month && month.length === 2 && +month >= 1 && +month <= 12;
}

export function validDay(day, month, year) {
  return day && day.length === 2 && +day >= 1 && +day <= getDaysInMonth(+month, +year);
}

export function formatBlock(block, blockType, flexibleYear) {
  switch (blockType) {
    case 'yyyy':
      return flexibleYear ? expandYear(block) : block;
    case 'mm':
      return block.padStart(2, '0');
    case 'dd':
      return block.padStart(2, '0');
    default:
      return block;
  }
}

function formatYear(year, flexible, forceExpand = false) {
  if (flexible && (forceExpand || (year !== '20' && year !== '19'))) {
    return expandYear(year);
  }
  return year;
}

function expandYear(year) {
  if (year && year.length === 2) {
    const maxYear = (new Date().getFullYear() % 100) + 10;
    return (year <= maxYear ? '20' : '19') + year;
  }
  return year;
}

function getDaysInMonth(month, year) {
  const feb = !year || isLeapYear(year) ? 29 : 28;
  return [31, feb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
}

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function sanitizeDelims(value) {
  return value.replace(sanitizePattern, '');
}

export function getSanitizedPosition(value, position) {
  return position - (value.slice(0, position).match(sanitizePattern) || []).length;
}

export function parseYear(input, flexible) {
  if (flexible && input.length === 2) {
    return [formatYear(input, flexible, true), ''];
  }
  return [input.slice(0, 4), input.slice(4)];
}

export function parseMonth(input) {
  if (input.charAt(0) > 1 || input.slice(0, 2) > 12) {
    return [`0${input.charAt(0)}`, input.slice(1)];
  }
  return [input.slice(0, 2), input.slice(2)];
}

export function parseDay(input, month, year) {
  const maxDay = getDaysInMonth(month, year);
  const maxDigit = month === 2 ? 2 : 3;
  if (input.charAt(0) > maxDigit || input.slice(0, 2) > maxDay) {
    return [`0${input.charAt(0)}`, input.slice(1)];
  }
  return [input.slice(0, 2), input.slice(2)];
}

export const MDY = new MonatFormat('mm/dd/yyyy', '/', true);
export const YMD = new MonatFormat('yyyy-mm-dd', '-', false);
