import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import ClaimMessage from '../shared/components/ClaimMessage';
import Warnings from './Warnings';
import PrintableReports from './PrintableReports';

import { getImpactReport, getProviderReport } from './settlementsActions';
import { anyMessages } from './settlementsReducer';
import { SFCase, PlanImpact, SettlementsClaimSummary, ImpactChartData } from './types/types';
import { RootState } from '../shared/types/types';

const useStyles = makeStyles({
  container: {
    padding: '1em',
    margin: '1em 0',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '40%',
    margin: '0 auto 1em auto',
  },
  report: {
    padding: '1em',
  },
  printer: {
    pageBreakInside: 'avoid',
  },
});

type StateProps = {
  sfCases: SFCase[];
  claims: SettlementsClaimSummary[];
  planImpact?: PlanImpact;
  chartData?: ImpactChartData;
  messages: Record<string, string[]>;
  isReportLoading: boolean;
};
const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    sfCases: settlements.sfCases,
    claims: settlements.claims,
    planImpact: settlements.planImpact,
    chartData: settlements.chartData,
    messages: settlements.messages,
    isReportLoading: settlements.isReportLoading,
  };
};

const mapDispatchToProps = {
  getImpactReport,
  getProviderReport,
};

type DispatchProps = {
  getImpactReport: () => void;
  getProviderReport: () => void;
};

type Props = StateProps & DispatchProps;

export function Reports({
  sfCases,
  claims,
  getImpactReport,
  planImpact,
  getProviderReport,
  messages,
  isReportLoading,
}: Props): JSX.Element {
  const classes = useStyles();

  const isButtonDisabled =
    anyMessages(messages) ||
    isReportLoading ||
    sfCases.length === 0 ||
    !claims.some(claim => claim.proposedSettlementAmount !== 0);
  const hasReport = Boolean(planImpact);

  if (sfCases.length === 0) {
    return (
      <ClaimMessage text="Please enter at least one Salesforce Case Number to run Settlement Report" />
    );
  }

  return (
    <Card raised={hasReport} className={classes.container}>
      <Warnings warnings={messages} />
      {!hasReport ? (
        <>
          <div className={classes.buttonContainer}>
            <Button
              disabled={isButtonDisabled}
              variant="outlined"
              onClick={getImpactReport}
              className={classes.button}
            >
              Generate Plan Impact Report
            </Button>
            <Button
              disabled={isButtonDisabled}
              variant="outlined"
              onClick={getProviderReport}
              className={classes.button}
            >
              Generate Provider Report
            </Button>
          </div>
          <LoadingSpinner isLoading={isReportLoading} />
        </>
      ) : (
        <PrintableReports />
      )}
    </Card>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
