/* eslint-disable no-param-reassign */
const professional = {
  name: 'Professional',
  key: 'professional',
  formula: 'CF × (Work RVU × Work GPCI + PE RVU × PE GPCI + MP RVU × MP GPCI) × Quantity',
  fields(repricing) {
    if (!repricing.professional) repricing.professional = {};
    return [
      {
        label: 'CF',
        labelFull: 'Conversion Factor',
        value: repricing.professional.cf,
        fieldName: 'repricing.professional.cf',
        id: 'conversion-factor',
      },
      {
        label: 'Work RVU',
        labelFull: 'Work Relative Value Unit',
        value: repricing.professional.workRVU,
        fieldName: 'repricing.professional.workRVU',
        id: 'work-relative-value-unit',
      },
      {
        label: 'Work GPCI',
        labelFull: 'Work Geographic Practice Cost Index',
        value: repricing.professional.workGPCI,
        fieldName: 'repricing.professional.workGPCI',
        id: 'work-geo-practice-cost-index',
      },
      {
        label: 'PE RVU',
        labelFull: 'Practice Expense Relative Value Unit',
        value: repricing.professional.nonFacPERVU,
        fieldName: 'repricing.professional.nonFacPERVU',
        id: 'practice-expense-relative-value-unit',
      },
      {
        label: 'PE GPCI',
        labelFull: 'Practice Expense Geographic Practice Cost Index',
        value: repricing.professional.peGPCI,
        fieldName: 'repricing.professional.peGPCI',
        id: 'practice-expense-geo-practice-cost-index',
      },
      {
        label: 'MP RVU',
        labelFull: 'Malpractice Relative Value Unit',
        value: repricing.professional.mpRVU,
        fieldName: 'repricing.professional.mpRVU',
        id: 'malpractice-relative-value-unit',
      },
      {
        label: 'MP GPCI',
        labelFull: 'Malpractice Geographic Practice Cost Index',
        value: repricing.professional.mpGPCI,
        fieldName: 'repricing.professional.mpGPCI',
        id: 'malpractice-geo-practice-cost-index',
      },
    ];
  },
};

const outpatient = {
  name: 'Outpatient OPPS',
  key: 'outpatient',
  formula: 'CF × APC Rel Weight × (Labor Percent × Wage Index + non-Labor Percent) × Quantity',
  fields(repricing) {
    if (!repricing.outpatient) repricing.outpatient = {};
    return [
      {
        label: 'CF',
        labelFull: 'Conversion Factor',
        value: repricing.outpatient.cf,
        fieldName: 'repricing.outpatient.cf',
        id: 'conversion-factor',
      },
      {
        label: 'APC Rel W',
        labelFull: 'Ambulatory Payment Classification Relative Weight',
        value: repricing.outpatient.apcRelWeight,
        fieldName: 'repricing.outpatient.apcRelWeight',
        id: 'ambulatory-payment-classification-relative-weight',
      },
      {
        label: 'Labor Pct',
        labelFull: 'Labor Percent',
        value: repricing.outpatient.laborPct,
        fieldName: 'repricing.outpatient.laborPct',
        isPercent: true,
        id: 'labor-percent',
      },
      {
        label: 'Wage Index',
        value: repricing.outpatient.wageIndex,
        fieldName: 'repricing.outpatient.wageIndex',
        id: 'wage-index',
      },
    ];
  },
};

const inpatient = {
  name: 'Acute IPPS',
  key: 'inpatient',
  formula:
    'Base Rate × (Labor Percent × Wage Index + non-Labor Percent) × MS-DRG Weight × Quantity',
  fields(repricing) {
    if (!repricing.inpatient) repricing.inpatient = {};
    return [
      {
        label: 'Base Rate',
        value: repricing.inpatient.baseRate,
        fieldName: 'repricing.inpatient.baseRate',
        id: 'base-rate',
      },
      {
        label: 'Labor Pct',
        labelFull: 'Labor Percent',
        value: repricing.inpatient.laborPct,
        fieldName: 'repricing.inpatient.laborPct',
        isPercent: true,
        id: 'labor-percent',
      },
      {
        label: 'Wage Index',
        value: repricing.inpatient.wageIndex,
        fieldName: 'repricing.inpatient.wageIndex',
        id: 'wage-index',
      },
      {
        label: 'MS-DRG Wt',
        labelFull: 'Medicare Severity Diagnosis Related Group Weight',
        value: repricing.inpatient.msDRGWgt,
        fieldName: 'repricing.inpatient.msDRGWgt',
        id: 'medicate-severity-diagnosis-related-group-weight',
      },
    ];
  },
};

const cmsRate = {
  name: 'CMS Rate',
  key: 'cmsRate',
  formula: 'Rate × Quantity',
  fields(repricing) {
    if (!repricing.cmsRate) repricing.cmsRate = {};
    return [
      {
        label: 'Rate',
        value: repricing.cmsRate.rate,
        fieldName: 'repricing.cmsRate.rate',
        id: 'cms-rate',
      },
    ];
  },
};

const labs = {
  name: 'Labs',
  key: 'labs',
  formula: 'Allowed Value × Quantity',
  fields(repricing) {
    if (!repricing.labs) repricing.labs = {};
    return [
      {
        label: 'Allowed Val',
        labelFull: 'Allowed Value',
        value: repricing.labs.allowedVal,
        fieldName: 'repricing.labs.allowedVal',
        id: 'allowed-value',
      },
    ];
  },
};

const dialysis = {
  name: 'Outpatient Dialysis',
  key: 'dialysis',
  id: 'dialysis',
  formula:
    '(Base Rate × (Labor Percent × Wage Index + non-Labor Percent) × PPS Adjustment Factors + High Cost Outlier + (Self-dialysis Add-on Adjustment × Hospital Wage Index)) × Quantity',
  fields(repricing) {
    if (!repricing.dialysis) repricing.dialysis = {};
    return [
      {
        label: 'Base Rate',
        value: repricing.dialysis.baseRate,
        fieldName: 'repricing.dialysis.baseRate',
        id: 'base-rate',
      },
      {
        label: 'Labor Pct',
        labelFull: 'Labor Percent',
        value: repricing.dialysis.laborPct,
        fieldName: 'repricing.dialysis.laborPct',
        isPercent: true,
        id: 'labor-percent',
      },
      {
        label: 'Wage Index',
        value: repricing.dialysis.wageIndex,
        fieldName: 'repricing.dialysis.wageIndex',
        id: 'wage-index',
      },
      {
        label: 'PPS Adj',
        labelFull: 'Prospective Payment System Adjustment Factors',
        value: repricing.dialysis.ppsAdj,
        fieldName: 'repricing.dialysis.ppsAdj',
        id: 'prospective-payment-system-adjustment-factor',
      },
      {
        label: 'Hi Cost Otlr',
        labelFull: 'High Cost Outlier',
        value: repricing.dialysis.hiCostOtlr,
        fieldName: 'repricing.dialysis.hiCostOtlr',
        id: 'high-cost-outlier',
      },
      {
        label: 'Self-dia Add',
        labelFull: 'Self-Dialysis Add-on Adjustment',
        value: repricing.dialysis.selfDiaAdd,
        fieldName: 'repricing.dialysis.selfDiaAdd',
        id: 'self-dialysis',
      },
    ];
  },
};

const drugs = {
  name: 'Part B Drugs - ASP',
  key: 'drugs',
  formula: '(Payment Limit + (Payment Limit × Adjustment Percent)) × Quantity',
  fields(repricing) {
    if (!repricing.drugs) repricing.drugs = {};
    return [
      {
        label: 'Pmt Limit',
        labelFull: 'Payment Limit',
        value: repricing.drugs.pmtLimit,
        fieldName: 'repricing.drugs.pmtLimit',
        id: 'payment-limit',
      },
      {
        label: 'Adj Pct',
        labelFull: 'Adjustment Percent',
        value: repricing.drugs.adjPct,
        fieldName: 'repricing.drugs.adjPct',
        id: 'adjustment-percent',
        isPercent: true,
      },
    ];
  },
};

const ambulatorySurgery = {
  name: 'Ambulatory Surgery Center',
  key: 'ambulatorySurgery',
  formula: 'CF × APC Rel Weight × (Labor Percent × Wage Index + non-Labor Percent) × Quantity',
  fields(repricing) {
    if (!repricing.ambulatorySurgery) repricing.ambulatorySurgery = {};
    return [
      {
        label: 'CF',
        labelFull: 'Conversion Factor',
        value: repricing.ambulatorySurgery.cf,
        fieldName: 'repricing.ambulatorySurgery.cf',
        id: 'conversion-factor',
      },
      {
        label: 'APC Rel W',
        labelFull: 'Ambulatory Payment Classification Relative Weight',
        value: repricing.ambulatorySurgery.apcRelWeight,
        fieldName: 'repricing.ambulatorySurgery.apcRelWeight',
        id: 'ambulatory-payment-classification-relative-weight',
      },
      {
        label: 'Labor Pct',
        labelFull: 'Labor Percent',
        value: repricing.ambulatorySurgery.laborPct,
        fieldName: 'repricing.ambulatorySurgery.laborPct',
        isPercent: true,
        id: 'labor-percent',
      },
      {
        label: 'Wage Index',
        value: repricing.ambulatorySurgery.wageIndex,
        fieldName: 'repricing.ambulatorySurgery.wageIndex',
        id: 'wage-index',
      },
    ];
  },
};

const ambulance = {
  name: 'Ambulance',
  key: 'ambulance',
  formula: 'CF × RVU × (Labor Percent × PE GPCI + non-Labor Percent) × Quantity',
  fields(repricing) {
    if (!repricing.ambulance) repricing.ambulance = {};
    return [
      {
        label: 'CF',
        labelFull: 'Conversion Factor',
        value: repricing.ambulance.cf,
        fieldName: 'repricing.ambulance.cf',
        id: 'conversion-factor',
      },
      {
        label: 'RVU',
        labelFull: 'Relative Value Unit',
        value: repricing.ambulance.rvu,
        fieldName: 'repricing.ambulance.rvu',
        id: 'relative-value-unit',
      },
      {
        label: 'Labor Pct',
        labelFull: 'Labor Percent',
        value: repricing.ambulance.laborPct,
        fieldName: 'repricing.ambulance.laborPct',
        isPercent: true,
        id: 'labor-percent',
      },
      {
        label: 'PE GPCI',
        labelFull: 'Practice Expense Geographic Practice Cost Index',
        value: repricing.ambulance.peGPCI,
        fieldName: 'repricing.ambulance.peGPCI',
        id: 'practice-expense-geo-practice-cost-index',
      },
    ];
  },
};

const ambulanceMileage = {
  name: 'Ambulance Mileage',
  key: 'ambulanceMileage',
  formula: 'Mileage Rate × Quantity (mileage)',
  fields(repricing) {
    if (!repricing.ambulance) repricing.ambulance = {};
    return [
      {
        label: 'Mile Rate',
        labelFull: 'Mileage Rate',
        value: repricing.ambulance.mileRate,
        fieldName: 'repricing.ambulance.mileRate',
        id: 'milage-rate',
      },
    ];
  },
};

const skilledNursing = {
  name: 'Skilled Nursing Facility',
  key: 'skilledNursing',
  formula: 'Base Rate × (Labor Percent × Wage Index + non-Labor Percent) × RUG Weight × Quantity',
  fields(repricing) {
    if (!repricing.skilledNursing) repricing.skilledNursing = {};
    return [
      {
        label: 'Base Rate',
        value: repricing.skilledNursing.baseRate,
        fieldName: 'repricing.skilledNursing.baseRate',
        id: 'base-rate',
      },
      {
        label: 'Labor Pct',
        labelFull: 'Labor Percent',
        value: repricing.skilledNursing.laborPct,
        fieldName: 'repricing.skilledNursing.laborPct',
        isPercent: true,
        id: 'labor-percent',
      },
      {
        label: 'Wage Index',
        value: repricing.skilledNursing.wageIndex,
        fieldName: 'repricing.skilledNursing.wageIndex',
        id: 'wage-index',
      },
      {
        label: 'RUG Weight',
        labelFull: 'Resource Utilization Group Weight',
        value: repricing.skilledNursing.rugWeight,
        fieldName: 'repricing.skilledNursing.rugWeight',
        id: 'resource-utilization-group-weight',
      },
    ];
  },
};

const anesthesia = {
  name: 'Anesthesia',
  key: 'anesthesia',
  formula: 'CF × (Quantity/15 + BaseUnits)',
  fields(repricing) {
    if (!repricing.anesthesia) repricing.anesthesia = {};
    return [
      {
        label: 'CF',
        labelFull: 'Conversion Factor',
        value: repricing.anesthesia.cf,
        fieldName: 'repricing.anesthesia.cf',
        id: 'conversion-factor',
      },
      {
        label: 'Base Units',
        value: repricing.anesthesia.baseUnits,
        fieldName: 'repricing.anesthesia.baseUnits',
        id: 'base-units',
      },
    ];
  },
};

const hospice = {
  name: 'Hospice',
  key: 'hospice',
  formula: 'Base Rate × (Labor Percent × Wage Index + non-Labor Percent) × Quantity',
  fields(repricing) {
    if (!repricing.hospice) repricing.hospice = {};
    return [
      {
        label: 'Base Rate',
        value: repricing.hospice.baseRate,
        fieldName: 'repricing.hospice.baseRate',
        id: 'base-rate',
      },
      {
        label: 'Labor Pct',
        labelFull: 'Labor Percent',
        value: repricing.hospice.laborPct,
        fieldName: 'repricing.hospice.laborPct',
        isPercent: true,
        id: 'labor-percent',
      },
      {
        label: 'Wage Index',
        value: repricing.hospice.wageIndex,
        fieldName: 'repricing.hospice.wageIndex',
        id: 'wage-index',
      },
    ];
  },
};

const alternateReference = {
  name: 'Alternate Reference',
  key: 'alternateReference',
  formula: 'UCR Rate × Unit',
  fields(repricing) {
    if (!repricing.alternateReference) repricing.alternateReference = {};
    return [
      {
        label: 'UCR Rate',
        value: repricing.alternateReference.ucrRate,
        fieldName: 'repricing.alternateReference.ucrRate',
        id: 'ucr-rate',
      },
      {
        label: 'Unit',
        value: repricing.alternateReference.unit,
        fieldName: 'repricing.alternateReference.unit',
        id: 'unit',
      },
    ];
  },
};

const qpa = {
  name: 'QPA',
  key: 'qpa',
  formula: 'zipcode',
  fields() {
    return [
      {
        label: 'Zip Code',
        fieldName: 'qpaZipCode',
        id: 'qpa',
      },
    ];
  },
};

export const formulaList = [
  professional,
  outpatient,
  inpatient,
  cmsRate,
  alternateReference,
  labs,
  dialysis,
  drugs,
  ambulatorySurgery,
  ambulance,
  ambulanceMileage,
  skilledNursing,
  anesthesia,
  hospice,
  qpa,
];

export const formulas = {};
formulaList.forEach(formula => {
  formulas[formula.key] = formula;
});

export default formulas;
