import React from 'react';
import PropTypes from 'prop-types';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { inflateString } from '@apps/shared/src/inflators';
import { validArray } from '../../shared/typeChecks';
import TextInput from '../../shared/components/TextInput';
import { TableCellHeaderTop, Column, addButtonStyle, deleteButtonStyle } from './sharedConfig';

const useStyles = makeStyles({
  addButton: addButtonStyle,
  deleteButton: deleteButtonStyle,
  claimInfoCell: {
    margin: '10px',
  },
  colCell: {
    margin: '5px',
  },
});

export default function ColArray({
  name,
  parent,
  colArray,
  isReadOnly,
  handleIntegerChange,
  handlePushToArray,
  handleRemoveFromArray,
}) {
  const classes = useStyles();

  const validColArray = validArray(colArray);
  const validParent = inflateString(parent);
  const validName = inflateString(name);

  return (
    <Paper className={classes.claimInfoCell}>
      <TableCellHeaderTop>
        <Typography>{validName}</Typography>
        {!isReadOnly && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.addButton}
            onClick={handlePushToArray(`${validParent}.${validName}`, 0)}
          >
            <AddCircleIcon />
          </Button>
        )}
      </TableCellHeaderTop>
      <Column>
        {validColArray.map((col, i) => {
          return (
            <div key={`${col}`}>
              <TextInput
                className={classes.colCell}
                readOnly={isReadOnly}
                allowedCharRegex="\d"
                onChange={handleIntegerChange(`${validParent}.${validName}.${i}`)}
                value={col}
              />
              {!isReadOnly && (
                <Button
                  variant="contained"
                  color="secondary"
                  data-testid={`col-array-delete-button-${i}`}
                  className={classes.deleteButton}
                  onClick={handleRemoveFromArray(`${validParent}.${validName}`, i)}
                >
                  <HighlightOffIcon />
                </Button>
              )}
            </div>
          );
        })}
      </Column>
    </Paper>
  );
}

ColArray.propTypes = {
  name: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  colArray: PropTypes.arrayOf(PropTypes.number).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  handleIntegerChange: PropTypes.func.isRequired,
  handlePushToArray: PropTypes.func.isRequired,
  handleRemoveFromArray: PropTypes.func.isRequired,
};
