import React from 'react';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import ClaimFooterItem from '../../repricing/ClaimFooterItem';
import { contractStatusCodes as statusCodes } from '../../shared/status';
import { UpdateContract, ContractStatus } from '../types/contracts';

type ButtonInfo = {
  text: string;
  newStatus: ContractStatus;
  isDisabled: boolean;
  title: string;
  isSuperAdmin?: boolean;
};

export function getButtonInfo(
  status: ContractStatus,
  isOwner: boolean,
  isSuperAdmin: boolean
): ButtonInfo {
  const buttonInfo: ButtonInfo = {
    text: '',
    newStatus: '',
    isDisabled: false,
    title: '',
  };

  switch (status) {
    case statusCodes.active:
      buttonInfo.text = 'Edit Contract';
      buttonInfo.newStatus = statusCodes.dataEntry;
      break;
    case statusCodes.dataEntry:
      if (!isSuperAdmin) {
        buttonInfo.text = 'Activate Contract';
        buttonInfo.newStatus = statusCodes.active;
        buttonInfo.isDisabled = !isOwner;
        buttonInfo.title = !isOwner ? 'Only owner can update status' : '';
      } else if (isSuperAdmin && !isOwner) {
        buttonInfo.text = 'Take Ownership';
        buttonInfo.newStatus = statusCodes.dataEntry;
        buttonInfo.title = !isOwner ? 'Take ownership of this contract.' : '';
      } else {
        buttonInfo.text = 'Activate Contract';
        buttonInfo.newStatus = statusCodes.active;
        buttonInfo.isDisabled = false;
        buttonInfo.title = '';
      }
      break;
    default:
      break;
  }
  return buttonInfo;
}

type Props = {
  status: ContractStatus;
  updateContract: UpdateContract;
  isContractor: boolean;
  isOwner: boolean;
  isSuperAdmin?: boolean;
};
export default function ContractFooterButtons({
  status,
  updateContract,
  isContractor = false,
  isOwner,
  isSuperAdmin = false,
}: Props): JSX.Element | null {
  if (!isContractor) return null;

  const { text, newStatus, isDisabled, title } = getButtonInfo(status, isOwner, isSuperAdmin);
  if (!text || !newStatus) return null;

  const handleClick = isDisabled ? undefined : (): void => updateContract('status', newStatus);

  return (
    <ClaimFooterItem>
      <Tooltip title={title}>
        <span>
          {/* <span/> makes Tooltip work when disabled: https://material-ui.com/components/tooltips/#disabled-elements */}
          <Button variant="contained" disabled={isDisabled} color="primary" onClick={handleClick}>
            {text}
          </Button>
        </span>
      </Tooltip>
    </ClaimFooterItem>
  );
}
