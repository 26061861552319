import axios from 'axios';
import { getAxiosRequestConfig } from '../utilities/http';
import { toRFC3339 } from '../utilities/stringUtilities';
import a, {
  AddPlan,
  CloseCredentialsInUseDialog,
  ResetAddPlan,
  SetPlanInfo,
} from './types/addPlanActions';
import { PlanInfo } from './types/addPlanTypes';

export function addPlan(
  planInfo: PlanInfo,
  providerSearchZipCode: string,
  redirectTo?: string
): AddPlan {
  return {
    type: a.ADD_PLAN,
    meta: { redirectTo },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v2/add-plan`,
      {
        ...planInfo,
        dateOfBirth: toRFC3339(planInfo.dateOfBirth),
        confirmPassword: undefined,
        providerSearchZip: providerSearchZipCode,
      },
      getAxiosRequestConfig()
    ),
  };
}

export function setPlanInfo(key: keyof PlanInfo, value: PlanInfo[keyof PlanInfo]): SetPlanInfo {
  return {
    type: a.SET_PLAN_INFO,
    meta: { key, value },
  };
}

export function resetAddPlan(): ResetAddPlan {
  return {
    type: a.RESET_ADD_PLAN,
  };
}

export function closeCredentialsInUseDialog(): CloseCredentialsInUseDialog {
  return {
    type: a.CLOSE_CREDENTIALS_IN_USE_DIALOG,
  };
}
