import React from 'react';
import { Column, Table, AutoSizer, TableHeaderProps, TableCellProps } from 'react-virtualized';
import makeStyles from '@material-ui/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';

const useVirtualizedTableStyles = makeStyles({
  table: {
    // Changing margin in column doesn't change margin in column header.
    // And I couldn't find any other way to do this.
    '& .ReactVirtualized__Table__headerColumn': {
      margin: '0 !important',
    },
  },
  column: {
    margin: '0 !important',
  },
});

const useMuiTableStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'baseline',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    fontSize: '0.8rem',
    height: '0.8rem',
    lineHeight: '1rem',
    textTransform: 'none',
    whiteSpace: 'normal',
  },
});

export type Data = Record<string, React.ReactNode>;

export type ColumnData = {
  dataKey: string;
  label: string;
  isNumeric?: boolean;
  width: number;
};

export type CellProps = Pick<TableCellProps, 'cellData' | 'columnIndex'> & {
  columns: ColumnData[];
  rowHeight: number;
};

export type HeaderCellProps = TableHeaderProps & {
  columnIndex: number;
  headerHeight: number;
  columns: ColumnData[];
};

export type CellRenderer = ({ cellData, columns, rowHeight }: CellProps) => JSX.Element;

export type HeaderCellRenderer = ({
  label,
  columnIndex,
  headerHeight,
  columns,
}: HeaderCellProps) => JSX.Element;

function MuiCell({ cellData, columnIndex, columns, rowHeight }: CellProps): JSX.Element {
  const classes = useMuiTableStyles();
  const isNumberOrString = typeof cellData === 'number' || typeof cellData === 'string';
  return (
    <TableCell
      className={classes.cell}
      height={rowHeight}
      component="div"
      variant="body"
      title={isNumberOrString ? `${cellData}` : ''}
      align={columns[columnIndex].isNumeric ? 'right' : 'left'}
    >
      {cellData}
    </TableCell>
  );
}

function MuiHeaderCell({
  label,
  columnIndex,
  headerHeight,
  columns,
}: HeaderCellProps): JSX.Element {
  const classes = useMuiTableStyles();
  return (
    <TableCell
      className={classNames(classes.cell, classes.header)}
      height={headerHeight}
      component="div"
      variant="head"
      title={label?.toString()}
      align={columns[columnIndex].isNumeric ? 'right' : 'left'}
    >
      {label}
    </TableCell>
  );
}

type VirtualizedTableProps = {
  columns: ColumnData[];
  data: Data[];
  rowHeight?: number;
  headerHeight?: number;
  dataTransformer?: (data: Data) => Data;
  headerCellRenderer?: HeaderCellRenderer;
  cellRenderer?: CellRenderer;
};

export default function VirtualizedTable({
  columns,
  rowHeight = 48,
  headerHeight = 48,
  data,
  dataTransformer,
  headerCellRenderer = MuiHeaderCell,
  cellRenderer = MuiCell,
}: VirtualizedTableProps): JSX.Element {
  const classes = useVirtualizedTableStyles();

  const HeaderCell = headerCellRenderer;
  const Cell = cellRenderer;

  return (
    <AutoSizer>
      {({ height, width }): JSX.Element => (
        <Table
          className={classes.table}
          height={height || 100} // Adding "|| 100" so tests won't fail. See https://github.com/bvaughn/react-virtualized/issues/493
          width={width || 100}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          rowCount={data.length}
          rowGetter={({ index }): Data =>
            dataTransformer ? dataTransformer(data[index]) : data[index]
          }
        >
          {columns.map(({ dataKey, label, width }, index) => {
            return (
              <Column
                key={dataKey}
                className={classes.column}
                label={label}
                dataKey={dataKey}
                width={width}
                headerRenderer={(headerProps: TableHeaderProps): JSX.Element => (
                  <HeaderCell
                    {...headerProps}
                    columnIndex={index}
                    headerHeight={headerHeight}
                    columns={columns}
                  />
                )}
                cellRenderer={({ cellData }: Pick<TableCellProps, 'cellData'>): JSX.Element => (
                  <Cell
                    cellData={cellData}
                    columnIndex={index}
                    rowHeight={headerHeight}
                    columns={columns}
                  />
                )}
              />
            );
          })}
        </Table>
      )}
    </AutoSizer>
  );
}
