import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { formatCurrency } from '@apps/shared/src/utilities';

import MonthSelect from './MonthSelect';
import { getDuplicates } from './reportsActions';
import * as types from '../shared/types/propTypes';

export function Duplicates({ duplicates, isLoading, getDuplicates }) {
  const handleChange = month => {
    if (month) getDuplicates(month);
  };

  return (
    <div style={{ paddingTop: '1rem' }}>
      <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '1rem' }}>
        <span>Duplicate Claims for:</span>
        <MonthSelect isDisabled={isLoading} onSelect={handleChange} />
      </div>
      <LoadingSpinner isLoading={isLoading} />
      {!isLoading && duplicates.length > 0 && (
        <table border="0" style={{ fontSize: '13px', textAlign: 'left' }}>
          <thead>
            <tr>
              <th>Billed</th>
              <th>Plan</th>
              <th>TPA</th>
              <th>Claim Type</th>
              <th>IDs</th>
              <th>Patient</th>
              <th>Statement Date</th>
            </tr>
          </thead>
          <tbody>
            {duplicates.map(r => (
              <tr key={r.ids.join(',')}>
                <td>{formatCurrency(r.billedAmt)}</td>
                <td>{r.policyNum}</td>
                <td>{r.tpa}</td>
                <td>{r.claimType}</td>
                <td>
                  {r.ids.map(id => (
                    <div key={id}>
                      <Link to={`/repricing/${id}`}>{id}</Link>
                    </div>
                  ))}
                </td>
                <td>{r.patient || r.subscriber}</td>
                <td>{r.statementDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

Duplicates.propTypes = {
  duplicates: PropTypes.arrayOf(types.duplicate).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getDuplicates: PropTypes.func.isRequired,
};

function mapStateToProps({ reports }) {
  return {
    isLoading: reports.loading,
    duplicates: reports.duplicates,
  };
}

const mapDispatchToProps = {
  getDuplicates,
};

export default connect(mapStateToProps, mapDispatchToProps)(Duplicates);
