import { AxiosPromise, AxiosResponse } from 'axios';
import { CredentialsInUseResponse } from '../../addPlan/types/addPlanActions';
import { PlanInfo } from '../../addPlan/types/addPlanTypes';
import { User } from '../../auth/types';
import { LoginInfo, UserInfo } from './register';

enum r {
  VERIFY_USER_CREDENTIALS = 'VERIFY_USER_CREDENTIALS',
  VERIFY_USER_CREDENTIALS_PENDING = 'VERIFY_USER_CREDENTIALS_PENDING',
  VERIFY_USER_CREDENTIALS_FULFILLED = 'VERIFY_USER_CREDENTIALS_FULFILLED',
  VERIFY_USER_CREDENTIALS_REJECTED = 'VERIFY_USER_CREDENTIALS_REJECTED',
  VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL',
  VERIFY_USER_EMAIL_PENDING = 'VERIFY_USER_EMAIL_PENDING',
  VERIFY_USER_EMAIL_FULFILLED = 'VERIFY_USER_EMAIL_FULFILLED',
  VERIFY_USER_EMAIL_REJECTED = 'VERIFY_USER_EMAIL_REJECTED',
  ADD_USER = 'ADD_USER',
  ADD_USER_PENDING = 'ADD_USER_PENDING',
  ADD_USER_FULFILLED = 'ADD_USER_FULFILLED',
  ADD_USER_REJECTED = 'ADD_USER_REJECTED',
  SET_LOGIN_INFO = 'SET_LOGIN_INFO',
  // SET_BIOMETRIC_INFO = 'SET_BIOMETRIC_INFO',
  SET_USER_INFO = 'SET_USER_INFO',
  SET_HAS_ACCOUNT = 'SET_HAS_ACCOUNT',
  RESET_REGISTER_PAGES = 'RESET_REGISTER_PAGES',
  PREVIOUS_PAGE = 'PREVIOUS_PAGE',
  NEXT_PAGE = 'NEXT_PAGE',
  SET_PAGE_COUNT = 'SET_PAGE_COUNT',
  REGISTER = 'REGISTER',
  REGISTER_PENDING = 'REGISTER_PENDING',
  REGISTER_FULFILLED = 'REGISTER_FULFILLED',
  REGISTER_REJECTED = 'REGISTER_REJECTED',
}
export default r;

export type VerifyUserArg = {
  currentPage: number;
  planInfo: PlanInfo;
  userInfo: UserInfo;
  hasAccount: boolean;
  redirectTo?: string;
};

export type VerifyUserCredentials = {
  type: typeof r.VERIFY_USER_CREDENTIALS;
  payload: AxiosPromise<CredentialsInUseResponse | string>;
};
export type VerifyCredentialsPending = {
  type: typeof r.VERIFY_USER_CREDENTIALS_PENDING;
};
export type VerifyUserCredentialsFulfilled = {
  type: typeof r.VERIFY_USER_CREDENTIALS_FULFILLED;
  payload: AxiosResponse<CredentialsInUseResponse | string>;
};
export type VerifyUserCredentialsRejected = {
  type: typeof r.VERIFY_USER_CREDENTIALS_REJECTED;
};
type VerifyUserCredentialsActions =
  | VerifyUserCredentials
  | VerifyCredentialsPending
  | VerifyUserCredentialsRejected
  | VerifyUserCredentialsFulfilled;

type VerifyUserEmailResponse = {
  hasAccount: boolean;
};

export type VerifyUserEmail = {
  type: typeof r.VERIFY_USER_EMAIL;
  payload: AxiosPromise<VerifyUserEmailResponse>;
};
export type VerifyUserEmailPending = {
  type: typeof r.VERIFY_USER_EMAIL_PENDING;
};
export type VerifyUserEmailFulfilled = {
  type: typeof r.VERIFY_USER_EMAIL_FULFILLED;
  payload: AxiosResponse<VerifyUserEmailResponse>;
};
export type VerifyUserEmailRejected = {
  type: typeof r.VERIFY_USER_EMAIL_REJECTED;
};
type VerifyUserEmailActions =
  | VerifyUserEmail
  | VerifyUserEmailPending
  | VerifyUserEmailRejected
  | VerifyUserEmailFulfilled;

export type AddUser = {
  type: typeof r.ADD_USER;
  meta: { redirectTo: string };
  payload: AxiosPromise<AddUserResponse>;
};
export type AddUserPending = {
  type: typeof r.ADD_USER_PENDING;
  meta: { redirectTo: string };
};
export type AddUserFulfilled = {
  type: typeof r.ADD_USER_FULFILLED;
  meta: { redirectTo: string };
  payload: AxiosResponse<AddUserResponse>;
};
export type AddUserRejected = {
  type: typeof r.ADD_USER_REJECTED;
  meta: { redirectTo: string };
};
type AddUserActions = AddUser | AddUserPending | AddUserRejected | AddUserFulfilled;

export type SetUserInfo = {
  type: typeof r.SET_USER_INFO;
  meta: {
    key: keyof UserInfo;
    value: UserInfo[keyof UserInfo];
  };
};

export type SetLoginInfo = {
  type: typeof r.SET_LOGIN_INFO;
  meta: {
    key: keyof LoginInfo;
    value: LoginInfo[keyof LoginInfo];
  };
};

// export type SetBiometricsInfo = {
//   type: typeof r.SET_BIOMETRIC_INFO;
//   meta: {
//     key: keyof BiometricsInfo;
//     value: BiometricsInfo[keyof BiometricsInfo];
//   };
// };

export type ResetRegisterPages = {
  type: typeof r.RESET_REGISTER_PAGES;
};

export type PreviousPage = {
  type: typeof r.PREVIOUS_PAGE;
};

export type NextPage = {
  type: typeof r.NEXT_PAGE;
};

export type SetPageCount = {
  type: typeof r.SET_PAGE_COUNT;
  meta: number;
};

export type RegisterActionTypes =
  | VerifyUserCredentialsActions
  | VerifyUserEmailActions
  | AddUserActions
  | SetUserInfo
  | SetLoginInfo
  | ResetRegisterPages
  | PreviousPage
  | NextPage
  | SetPageCount;

export type AddUserLoginResponse = {
  result: string;
  csrfToken: string;
  user: User;
};

export type AddUserResponse = AddUserLoginResponse | string;

export function isAddUserLoginResponse(data: unknown): data is AddUserLoginResponse {
  return (
    (data as AddUserLoginResponse).csrfToken !== undefined &&
    (data as AddUserLoginResponse).user !== undefined
  );
}
