import { AxiosPromise } from 'axios';
import { Plan } from '../../planSelect/types/plan';
import { Provider } from '../../providerSearch/types/providerSearch';
import { PlanInfo } from './addPlanTypes';

enum a {
  ADD_PLAN = 'ADD_PLAN',
  ADD_PLAN_PENDING = 'ADD_PLAN_PENDING',
  ADD_PLAN_REJECTED = 'ADD_PLAN_REJECTED',
  ADD_PLAN_FULFILLED = 'ADD_PLAN_FULFILLED',
  SET_PLAN_INFO = 'SET_PLAN_INFO',
  RESET_ADD_PLAN = 'RESET_ADD_PLAN',
  CLOSE_CREDENTIALS_IN_USE_DIALOG = 'CLOSE_CREDENTIALS_IN_USE_DIALOG',
}
export default a;

type AddPlanResponse = {
  plans: Plan[];
  providers: Provider[];
};

export type CredentialsInUseResponse = {
  message: string;
  existingEmail: string;
};

export type AddPlan = {
  type: typeof a.ADD_PLAN;
  meta: { redirectTo?: string };
  payload: AxiosPromise<AddPlanResponse>;
};

export type AddPlanPending = {
  type: typeof a.ADD_PLAN_PENDING;
  meta: { redirectTo?: string };
};

export type AddPlanFulfilled = {
  type: typeof a.ADD_PLAN_FULFILLED;
  meta: { redirectTo?: string };
  payload: { data: AddPlanResponse | CredentialsInUseResponse };
};

export type AddPlanRejected = {
  type: typeof a.ADD_PLAN_REJECTED;
  meta: { redirectTo?: string };
};

export type SetPlanInfo = {
  type: typeof a.SET_PLAN_INFO;
  meta: {
    key: keyof PlanInfo;
    value: PlanInfo[keyof PlanInfo];
  };
};

export type ResetAddPlan = {
  type: typeof a.RESET_ADD_PLAN;
};

export type CloseCredentialsInUseDialog = {
  type: typeof a.CLOSE_CREDENTIALS_IN_USE_DIALOG;
};

export type AddPlanActions = AddPlan | AddPlanPending | AddPlanRejected | AddPlanFulfilled;

export type AddPlanActionTypes =
  | AddPlanActions
  | SetPlanInfo
  | ResetAddPlan
  | CloseCredentialsInUseDialog;

export function isCredentialsInUseResponse(data: unknown): data is CredentialsInUseResponse {
  const r = data as CredentialsInUseResponse;
  return r.message !== undefined && r.existingEmail !== undefined;
}

export function isAddPlanResponse(data: unknown): data is AddPlanResponse {
  const r = data as AddPlanResponse;
  return r.plans !== undefined && r.providers !== undefined;
}
