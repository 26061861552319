import React from 'react';
import PropTypes from 'prop-types';

import { Grid, GridRow, GridColumn } from '../shared/grid';

export default function SvcColumnHeaders({ isProf, isAdjud, hasAdjudData, columnWidths }) {
  return (
    <Grid columns={columnWidths} component={GridRow} header>
      {isProf && <GridColumn>Date</GridColumn>}
      {!isProf && (
        <GridColumn>
          Rev
          <br />
          Code
        </GridColumn>
      )}
      <GridColumn>Procedure</GridColumn>
      {isProf && (
        <GridColumn>
          <span title="Diagnosis Pointer">
            Diag
            <br />
            Pntr
          </span>
        </GridColumn>
      )}
      <GridColumn>Quantity</GridColumn>
      <GridColumn>Amount</GridColumn>
      {isProf && <GridColumn>Facility</GridColumn>}
      {!isAdjud && hasAdjudData && <GridColumn>Adjudication</GridColumn>}
      {!isAdjud && <GridColumn style={{ gridColumn: 'formula' }}>Reference Formula</GridColumn>}
      {!isAdjud && <GridColumn style={{ gridColumn: 'reference' }}>Reference</GridColumn>}
      {!isAdjud && (
        <GridColumn style={{ gridColumn: 'repricing-methodology', marginLeft: '-16%' }}>
          Repricing Methodology
        </GridColumn>
      )}
      {isAdjud && <GridColumn>Category</GridColumn>}
      {isAdjud && <GridColumn>Plan + Member Accumulator Determination</GridColumn>}
      <GridColumn style={{ marginLeft: '30%' }}>
        {isAdjud ? 'Share' : 'Repriced'}
        <br />
        Amount
      </GridColumn>
    </Grid>
  );
}

SvcColumnHeaders.propTypes = {
  isProf: PropTypes.bool.isRequired,
  isAdjud: PropTypes.bool.isRequired,
  hasAdjudData: PropTypes.bool.isRequired,
  columnWidths: PropTypes.string.isRequired,
};
