import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import { Column } from '../../shared/grid';

import TextInput from '../../shared/components/TextInput';
import { defaultTPA } from './defaultTypes';
import { set } from './mutationFunctions';

const useStyles = makeStyles({
  paper: {
    margin: '15em auto',
    width: '20em',
  },
  title: {
    fontWeight: 'bold',
    padding: '1em',
    color: colors.black,
    backgroundColor: colors.grey05,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },
  column: {
    padding: '1em',
  },
  overwrite: {
    color: colors.white,
    backgroundColor: colors.orange,
    marginTop: '1em',
  },
  cancel: {
    marginTop: '1em',
  },
});

export default function NewTPA({
  open,
  toggleNewTPA,
  handleNewTPA,
  openTPADetail,
  tpaDetail,
  toggleCloseTPADetail,
}) {
  const classes = useStyles();

  const [newTPA, setNewTPA] = useState(defaultTPA());

  const handleTextChange = field => e => {
    const { value } = e.target;
    const newTPACopy = { ...newTPA };
    set(newTPACopy, field, value);
    setNewTPA(newTPACopy);
  };

  const handleSubmit = newTPA => {
    handleNewTPA(newTPA);
    setNewTPA('');
  };

  const disabled = !(
    inflateString(newTPA.tpaName).length > 2 &&
    inflateString(newTPA.nickname).length > 2 &&
    inflateString(newTPA.accountID).length > 2
  );

  return !openTPADetail ? (
    <Modal open={open}>
      <Paper className={classes.paper}>
        <div className={classes.title}>Add New TPA</div>
        <Column className={classes.column}>
          <TextInput
            label="TPA Name"
            fullWidth
            onChange={handleTextChange('tpaName')}
            value={newTPA.tpaName}
          />

          <TextInput
            label="TPA Nick Name"
            fullWidth
            onChange={handleTextChange('nickname')}
            value={newTPA.nickname}
          />
          <TextInput
            label="Salesforce account"
            fullWidth
            onChange={handleTextChange('accountID')}
            value={newTPA.accountID}
          />
          <Button
            className={classes.overwrite}
            variant="contained"
            fullWidth
            disabled={disabled}
            onClick={() => handleSubmit(newTPA)}
          >
            Create
          </Button>
          <Button className={classes.cancel} variant="contained" fullWidth onClick={toggleNewTPA}>
            Cancel
          </Button>
        </Column>
      </Paper>
    </Modal>
  ) : (
    <Modal open={openTPADetail}>
      <Paper className={classes.paper}>
        <div className={classes.title}>Success!</div>
        <Column className={classes.Column}>
          <p>SixDegTPAId: {tpaDetail.sixDegTPAID}</p>
          <p>TPA Name: {tpaDetail.tpaName}</p>
          <p>TPA Nickname: {tpaDetail.nickname}</p>
          <p>Salesforce Account id: {tpaDetail.accountID}</p>
          <Button
            className={classes.cancel}
            variant="contained"
            fullWidth
            onClick={toggleCloseTPADetail}
          >
            Close
          </Button>
        </Column>
      </Paper>
    </Modal>
  );
}

NewTPA.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleNewTPA: PropTypes.func.isRequired,
  handleNewTPA: PropTypes.func.isRequired,
};
