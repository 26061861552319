import { firstBy } from 'thenby';

import { inflateNumber, inflateString } from '@apps/shared/src/inflators';
import * as c from './claimsActions';
import { claimScoreOrder, getPlanName, planErrorMessages } from '../shared/globals';
import { claimStatusCodes } from '../shared/status';
import { inflateContractMatch } from './types/inflaters';
import { validArray } from '../shared/typeChecks';

const defaultSettings = {
  statusFilters: {
    [claimStatusCodes.repriceNew]: true,
    [claimStatusCodes.repriceStarted]: true,
    [claimStatusCodes.repriced]: true,
    [claimStatusCodes.miscalculation]: false,
    // [claimStatusCodes.adjudicateNew]: true,
    // [claimStatusCodes.adjudicateStarted]: true,
    // [claimStatusCodes.adjudicated]: true,
    // [claimStatusCodes.adjudicateMiscalculation]: true,
    // [claimStatusCodes.adjudicateVerifyNew]: true,
    // [claimStatusCodes.adjudicateVerifyStarted]: true,
    // [claimStatusCodes.adjudicateVerified]: true,
    // [claimStatusCodes.adjudicateFlagged]: true,
    // [claimStatusCodes.adjudicateHold]: true,
    // [claimStatusCodes.adjudicateDenied]: false,
    [claimStatusCodes.clinicalNew]: true,
    [claimStatusCodes.clinicalStarted]: true,
    [claimStatusCodes.clinicalReviewed]: true,
    [claimStatusCodes.flagged]: true,
    [claimStatusCodes.onHold]: true,
    [claimStatusCodes.verifyNew]: true,
    [claimStatusCodes.verifyStarted]: true,
    [claimStatusCodes.verified]: false,
    [claimStatusCodes.dataEntry]: true,
    [claimStatusCodes.dataEntryValidation]: true,
    [claimStatusCodes.dataEntryError]: true,
    [claimStatusCodes.researchNew]: false,
    [claimStatusCodes.researchStarted]: false,
    [claimStatusCodes.researched]: false,
    [claimStatusCodes.ineligible]: false,
  },
  showTestClaims: false,
  showManualClaims: false,
  showNonExportedClaims: false,
  sortBy: 'dateReceived',
  selectedTPAGroup: -1, // stored as index of tpaGroups
  certificationScores: {
    P1: true,
    P2: true,
    P3: true,
    O1: true,
    O2: true,
    O3: true,
    O4: true,
    I1: true,
  },
};

let settings = { ...defaultSettings };
try {
  const parsedSettings = JSON.parse(localStorage.getItem('uisettings'));
  if (parsedSettings) {
    settings = { ...defaultSettings, ...parsedSettings };
  }
} catch (e) {
  //
}

export const initialClaimState = {
  statusFilters: settings.statusFilters,
  showTestClaims: settings.showTestClaims,
  showManualClaims: settings.showManualClaims,
  showNonExportedClaims: settings.showNonExportedClaims,
  sortBy: settings.sortBy,
  selectedTPAGroup: settings.selectedTPAGroup,
  certificationScores: settings.certificationScores,
  claims: [],
  totalPages: 0,
  selectedClaim: {},
  selectedPlan: [],
  dupeMatch: {
    dupeErr: '',
    dupes: [],
  },
  contractMatch: {
    contractErr: '',
    contracts: [],
  },
  search: {
    fetchingClaims: false,
  },
  markedForRemoval: [],
  animateMarked: false,
  codeInfo: {},
  plans: [],
  pendingNoteUpdate: null,
  isStatusUpdatePending: false,
};

function saveUISettings(key, newValue) {
  settings[key] = newValue;
  localStorage.setItem('uisettings', JSON.stringify(settings));
}

export function updateObject(obj, fieldName, newValue) {
  const res = { ...obj };
  const filters = fieldName.split('.');

  switch (filters.length) {
    case 0:
      return res;
    case 1:
      res[filters[0]] = newValue;
      return res;
    case 2:
      if (!res[filters[0]]) res[filters[0]] = { [filters[1]]: undefined };
      return { ...res, [filters[0]]: { ...res[filters[0]], [filters[1]]: newValue } };
    default:
      if (!res[filters[0]]) res[filters[0]] = { [filters[1]]: { [filters[2]]: undefined } };
      if (!res[filters[0]][filters[1]]) res[filters[0]][filters[1]] = { [filters[2]]: undefined };
      return {
        ...res,
        [filters[0]]: {
          ...res[filters[0]],
          [filters[1]]: { ...res[filters[0]][filters[1]], [filters[2]]: newValue },
        },
      };
  }
}

export function validateAll(c) {
  const claim = { ...c };
  if (!claim || !claim.simpleClaim) {
    return claim;
  }
  const { simpleClaim } = claim;
  const { repriceTotal } = simpleClaim;
  let { services = [] } = simpleClaim;
  if (!Array.isArray(services) || services.length < 1) {
    return claim;
  }
  services = services.map(svc => ({ ...svc, allValidation: {} }));
  const claimValidation = {};

  if (services.some(svc => svcHasRepriceTotal(svc) || svcHasExtNote(svc))) {
    services = services.map(s => {
      const svc = s;
      if (svcNeedsNote(svc)) {
        svc.allValidation = {
          error: 'Zero repriced services need a note',
          errorShort: 'Needs note',
        };
        claimValidation.svcHasError = true;
      }
      return svc;
    });
  }
  // No services have been repriced and no services have a note
  else {
    const hasAllSvcNotes = services.every(svcHasExtNote);
    if (!(repriceTotal || hasAllSvcNotes || allServicesDenied(simpleClaim))) {
      claimValidation.error = 'Zero repriced claims need external notes on all services';
      claimValidation.errorShort = 'Need service notes';
    }
  }

  claim.simpleClaim.services = services;
  claim.allValidation = claimValidation;
  return claim;
}

export function svcNeedsNote(s) {
  const { repricing, adjudication } = s;
  return (
    !(repricing && repricing.total) &&
    !(repricing && repricing.extCmnt && repricing.extCmnt.code !== 'DEG') &&
    !(adjudication && adjudication.determination === 'Denied')
  );
}

export function svcHasRepriceTotal(svc) {
  return Boolean(svc.repricing && svc.repricing.total);
}

export function svcHasExtNote(svc) {
  return Boolean(svc.repricing && svc.repricing.extCmnt && svc.repricing.extCmnt.code);
}

export function validateClaim(c) {
  const claim = { ...c };
  if (!claim || !claim.simpleClaim) {
    return claim;
  }
  const { simpleClaim = {} } = claim;
  const { repriceTotal, billAmt } = simpleClaim;
  const billedAmt = billAmt || 0;
  const repriceAmt = repriceTotal || 0;
  const validation = {};

  if (!allServicesDenied(simpleClaim)) {
    if (repriceAmt - billedAmt > 0.009) {
      validation.error = 'Cannot reprice greater than billed';
      validation.errorShort = '> Billed';
    } else if (repriceAmt < billedAmt / 10 && !allServicesDenied(simpleClaim)) {
      validation.warning = 'Claims repriced to less than 10% of billed are flagged by default';
      validation.warningShort = '< 10% Billed';
    }
  }

  validation.showValidation =
    simpleClaim.hasOwnProperty('repriceTotal') ||
    Boolean(claim.simpleClaim.extCmnt && claim.simpleClaim.extCmnt.code);
  claim.validation = validation;
  return claim;
}

export function validateAllServices(c) {
  const claim = { ...c };
  if (!(claim.simpleClaim && Array.isArray(claim.simpleClaim.services))) {
    return claim;
  }

  let hasError = false;
  for (let i = 0; i < claim.simpleClaim.services.length; i++) {
    const svc = claim.simpleClaim.services[i];
    if (svc.validation && !!svc.validation.error) {
      hasError = true;
      break;
    }
  }
  claim.svcValidation = { hasError };
  return claim;
}

export function validateService(s) {
  const service = { ...s };
  const { repricing = {} } = service;
  const billedAmt = service.billedCharge || 0;
  const validation = {};

  if (repricing.total - billedAmt > 0.009) {
    validation.error = 'Cannot reprice greater than billed';
    validation.errorShort = '> Billed';
  } else if (repricing.total < 0) {
    validation.error = 'Cannot reprice negative';
    validation.errorShort = 'Negative';
  }

  const { extCmnt } = repricing;

  validation.showValidation = repricing.hasOwnProperty('total') || !!(extCmnt && extCmnt.code);
  service.validation = validation;
  return service;
}

export function allServicesDenied(simpleClaim) {
  const { services } = simpleClaim;
  return (
    Array.isArray(services) &&
    services.every(svc => svc.adjudication && svc.adjudication.determination === 'Denied')
  );
}

export function miscalcSort(a, b) {
  const aInMisCalc = a.status === 'Miscalculation' || a.status === 'AdjudicateMiscalculation';
  const bInMisCalc = b.status === 'Miscalculation' || b.status === 'AdjudicateMiscalculation';
  return bInMisCalc - aInMisCalc;
}

export function scoreSort(a, b) {
  const scoreA = a.simpleClaim && a.simpleClaim.score;
  const scoreB = b.simpleClaim && b.simpleClaim.score;
  if ((!scoreA && !scoreB) || scoreA === scoreB) return 0;

  // sort claims without scores at the top
  if (scoreA && !scoreB) return 1;
  if (scoreB && !scoreA) return -1;

  const scoreDiff =
    claimScoreOrder.indexOf(a.simpleClaim.score) - claimScoreOrder.indexOf(b.simpleClaim.score);
  return scoreDiff > 0 ? 1 : -1;
}

export function dateSort(sortBy) {
  return (a, b) => {
    const sortableDOS = (a.earliestDt || b.earliestDt) && a.earliestDt !== b.earliestDt;
    const dateReceivedA = (a.batches && a.batches[0] && a.batches[0].date) || null;
    const dateReceivedB = (b.batches && b.batches[0] && b.batches[0].date) || null;
    const sortableDtReceived = (dateReceivedA || dateReceivedB) && dateReceivedA !== dateReceivedB;

    let compare = 0;
    switch (sortBy) {
      case 'dateOfService':
        if (sortableDOS) {
          compare = compareDates(a.earliestDt, b.earliestDt);
        } else if (sortableDtReceived) {
          compare = compareDates(dateReceivedA, dateReceivedB);
        }
        break;
      case 'dateReceived':
        if (sortableDtReceived) {
          compare = compareDates(dateReceivedA, dateReceivedB);
        } else if (sortableDOS) {
          compare = compareDates(a.earliestDt, b.earliestDt);
        }
        break;
      default:
        throw new Error(
          'Invalid dateSort function parameter "sortBy". Allowed values are "dateOfService" and "dateReceived"'
        );
    }
    return compare;
  };
}

function priorityStringToNumber(str) {
  switch (str) {
    case 'overdue':
      return 0;
    case 'due':
      return 1;
    default:
      return 2;
  }
}

export function prioritySort(a, b) {
  return priorityStringToNumber(a.priorityStatus) - priorityStringToNumber(b.priorityStatus);
}

export function sortClaims(claims, sortBy, isAdjud) {
  if (isAdjud) {
    return [...claims].sort(dateSort(sortBy));
  }

  return [...claims].sort(
    firstBy(prioritySort).thenBy(miscalcSort).thenBy(dateSort(sortBy)).thenBy(scoreSort)
  );
}

// compares two dates of string format "YYYY-MM-DD"
export function compareDates(dateA, dateB) {
  if (dateA && !dateB) return 1;
  if (!dateA && dateB) return -1;
  if (dateA < dateB) return -1;
  if (dateA > dateB) return 1;
  return 0;
}

function updateClaims(claims, claimUpdate) {
  const i = claims.findIndex(c => c.id === claimUpdate.id);
  return i >= 0
    ? [...claims.slice(0, i), { ...claims[i], ...claimUpdate }, ...claims.slice(i + 1)]
    : [...claims];
}

function removeClaim(claims, id) {
  const i = claims.findIndex(c => c.id === id);
  return i >= 0 ? [...claims.slice(0, i), ...claims.slice(i + 1)] : [...claims];
}

export function updateSelectedClaim(selectedClaim, claimUpdate) {
  if (selectedClaim.id === claimUpdate.id) {
    return {
      ...selectedClaim,
      status: claimUpdate.status,
      owner: claimUpdate.owner,
      handlers: { ...selectedClaim.handlers, ...claimUpdate.handlers },
      services: selectedClaim.simpleClaim?.services?.map(s => {
        return {
          ...s,
          repricing: {
            ...s.repricing,
            extCmnt: s.repricing.extCmnt,
          },
        };
      }),
    };
  }
  return selectedClaim;
}

function removeNonSelected(markedForRemoval, selectedClaimId) {
  return markedForRemoval.filter(id => id === selectedClaimId);
}

export default (state = initialClaimState, action) => {
  switch (action.type) {
    case c.UPDATE_REPRICING_METHODOLOGY_FULFILLED: {
      const { payload } = action;
      if (payload?.data) {
        const simpleClaim = payload.data;

        simpleClaim.services = simpleClaim.services.map(validateService);

        const mergedClaim = {
          ...state.selectedClaim,
          simpleClaim: {
            ...state.selectedClaim.simpleClaim,
            services: simpleClaim.services,
            effectiveMultiple: simpleClaim.effectiveMultiple,
            repriceTotal: simpleClaim.repriceTotal,
          },
        };

        return {
          ...state,
          selectedClaim: validateAll(validateClaim(validateAllServices(mergedClaim))),
        };
      }
      return state;
    }

    case c.UPDATE_QPA_ZIP_CODE_FULFILLED: {
      const { payload } = action;
      if (payload && payload.data) {
        const simpleClaim = payload.data;

        simpleClaim.services = simpleClaim.services.map(validateService);

        const mergedClaim = {
          ...state.selectedClaim,
          simpleClaim: {
            ...state.selectedClaim.simpleClaim,
            services: simpleClaim.services,
            effectiveMultiple: simpleClaim.effectiveMultiple,
            refTotal: simpleClaim.refTotal,
            repriceTotal: simpleClaim.repriceTotal,
          },
        };

        return {
          ...state,
          selectedClaim: validateAll(validateClaim(validateAllServices(mergedClaim))),
        };
      }
      return state;
    }

    case c.UPDATE_FORMULA_TYPE_FULFILLED:
    case c.UPDATE_REPRICING_FULFILLED: {
      const { payload } = action;
      if (payload && payload.data) {
        const simpleClaim = payload.data;

        simpleClaim.services = simpleClaim.services.map(validateService);

        const mergedClaim = {
          ...state.selectedClaim,
          simpleClaim: {
            ...state.selectedClaim.simpleClaim,
            services: simpleClaim.services,
            effectiveMultiple: simpleClaim.effectiveMultiple,
            refTotal: simpleClaim.refTotal,
            repriceTotal: simpleClaim.repriceTotal,
          },
        };

        return {
          ...state,
          selectedClaim: validateAll(validateClaim(validateAllServices(mergedClaim))),
        };
      }
      return state;
    }

    case c.UPDATE_CLAIM_FULFILLED: {
      const m = action.meta;
      const { payload } = action;
      let value;
      if (m.hasOwnProperty('numberVal')) {
        value = m.numberVal;
      } else if (m.hasOwnProperty('stringVal')) {
        value = m.stringVal;
      } else if (m.hasOwnProperty('boolVal')) {
        value = m.boolVal;
      }

      if (m.serviceIndex === undefined) {
        if (payload && payload.data && payload.data.simpleClaim) {
          const { simpleClaim } = payload.data;
          return {
            ...state,
            selectedClaim: validateAll(
              validateAllServices({
                ...state.selectedClaim,
                simpleClaim: {
                  ...simpleClaim,
                  services: simpleClaim.services.map((svc, sindex) => {
                    if (sindex !== m.serviceIndex) return validateService(svc);
                    return validateService(updateObject(svc, m.fieldName, value));
                  }),
                },
              })
            ),
          };
        }
        // claim-level update
        return {
          ...state,
          selectedClaim: updateObject(state.selectedClaim, m.fieldName, value),
        };
      }

      const sc = state.selectedClaim.simpleClaim;

      if (m.serviceIndex !== undefined && sc && sc.services && sc.services[m.serviceIndex]) {
        // service-level update
        return {
          ...state,
          selectedClaim: validateAll(
            validateAllServices({
              ...state.selectedClaim,
              simpleClaim: {
                ...sc,
                services: sc.services.map((svc, sindex) => {
                  if (sindex !== m.serviceIndex) return svc;
                  return validateService(updateObject(svc, m.fieldName, value));
                }),
              },
            })
          ),
        };
      }
      return state;
    }

    case c.UPDATE_STATUS_PENDING: {
      return { ...state, isStatusUpdatePending: true };
    }

    case c.UPDATE_STATUS_REJECTED:
    case c.UPDATE_STATUS_FULFILLED: {
      const { payload } = action;
      if (payload && payload.data) {
        const selectedClaim = payload.data;
        const { simpleClaim } = selectedClaim;

        simpleClaim.services = simpleClaim.services.map(validateService);
        const mergedClaim = {
          ...selectedClaim,
          simpleClaim: {
            ...state.selectedClaim.simpleClaim,
            services: simpleClaim.services,
            effectiveMultiple: simpleClaim.effectiveMultiple,
            refTotal: simpleClaim.refTotal,
            repriceTotal: simpleClaim.repriceTotal,
          },
        };

        return {
          ...state,
          isStatusUpdatePending: false,
          selectedClaim: validateAll(validateClaim(validateAllServices(mergedClaim))),
        };
      }
      return state;
    }

    case c.SELECT_CONTRACT_FULFILLED: {
      const { claimID, contractID, providerGroup, contractType } = action.meta;
      if (claimID !== state.selectedClaim.id) return state;

      // If user selected "NO CONTRACT SELECTED", don't modify external comments
      if (contractID === 0)
        return {
          ...state,
          selectedClaim: {
            ...state.selectedClaim,
            simpleClaim: {
              ...state.selectedClaim.simpleClaim,
              contractID,
            },
          },
        };

      let extCmnt;
      if (contractID) {
        const isSCAOrLOA = contractType === 'SingleCase' || contractType === 'LOA';
        extCmnt = {
          code: isSCAOrLOA ? 'SCA' : 'CON',
          comment: `${providerGroup} - ${contractType}`,
        };
      }

      const updatedServices = state.selectedClaim.simpleClaim?.services?.map(s => {
        return {
          ...s,
          repricing: {
            ...s.repricing,
            extCmnt,
          },
        };
      });

      return {
        ...state,
        selectedClaim: {
          ...state.selectedClaim,
          simpleClaim: {
            ...state.selectedClaim.simpleClaim,
            contractID,
            services: updatedServices,
          },
        },
      };
    }

    case c.GET_CLAIM_FULFILLED: {
      const { claim = {}, dupeMatch, contractMatch } = action.payload.data;

      const { simpleClaim } = claim;

      if (simpleClaim && simpleClaim.services) {
        claim.simpleClaim.services = simpleClaim.services.map(s => validateService(s));
      }
      const validatedClaim = validateAll(validateClaim(validateAllServices(claim)));

      if (validatedClaim && validatedClaim.simpleClaim) {
        validatedClaim.simpleClaim.planName = getPlanName(
          inflateString(validatedClaim.simpleClaim.policyNum),
          inflateNumber(validatedClaim.sixDegTPAID),
          inflateNumber(validatedClaim.sixDegPlanID),
          validArray(state.plans),
          validatedClaim.earliestDt
        );
      }

      return {
        ...state,
        selectedClaim: validatedClaim,
        dupeMatch,
        contractMatch: inflateContractMatch(contractMatch),
        codeInfo: {},
        contractPlanInfo: {},
      };
    }

    case c.GET_CLAIMS_PENDING: {
      return {
        ...state,
        claims: [],
        search: {
          ...state.search,
          fetchingClaims: true,
        },
      };
    }

    case c.GET_CLAIMS_REJECTED:
    case c.GET_CLAIMS_FULFILLED: {
      const isAdjud = action.meta.group === 'adjudication';

      return {
        ...state,
        claims: Array.isArray(action.payload.data.claims)
          ? sortClaims(action.payload.data.claims, state.sortBy, isAdjud)
          : [],
        totalPages: action.payload.data.totalPages || 0,
        search: {
          ...state.search,
          fetchingClaims: false,
        },
      };
    }

    case c.SEARCH_GLOBAL_PENDING: {
      return {
        ...state,
        search: {
          ...state.search,
          fetchingClaims: true,
          results: [],
        },
      };
    }

    case c.SEARCH_GLOBAL_REJECTED:
    case c.SEARCH_GLOBAL_FULFILLED: {
      return {
        ...state,
        search: {
          ...state.search,
          fetchingClaims: false,
          global: true,
          searchTerm: action.meta,
          results: Array.isArray(action.payload.data) ? action.payload.data : [],
        },
      };
    }

    case c.SEARCH_QUEUE: {
      return {
        ...state,
        search: { ...state.search, global: false, searchTerm: action.meta },
      };
    }

    case c.EXPORT_CLAIM_FULFILLED: {
      const url = window.URL.createObjectURL(new Blob([action.payload.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `claim_${action.meta}.xlsx`);
      link.click();
      window.URL.revokeObjectURL(url);
      return state;
    }

    case c.SET_SEARCH_SCOPE: {
      const results = action.meta && !state.search.global ? [] : state.search.results;
      return {
        ...state,
        search: { ...state.search, global: action.meta, results },
      };
    }

    case c.SET_TPA_GROUP: {
      saveUISettings('selectedTPAGroup', action.meta);
      return { ...state, selectedTPAGroup: action.meta };
    }

    case c.TOGGLE_CERTIFICATION_SCORE: {
      const score = action.meta;
      const scores = { ...state.certificationScores, [score]: !state.certificationScores[score] };
      saveUISettings('certificationScores', scores);
      return { ...state, certificationScores: scores };
    }

    case c.TOGGLE_STATUS: {
      const status = action.meta;
      const filters = { ...state.statusFilters, [status]: !state.statusFilters[status] };
      saveUISettings('statusFilters', filters);
      return { ...state, statusFilters: filters };
    }

    case c.TOGGLE_ALL_FILTER_GROUP: {
      const filterGroup = action.meta;
      const newFilters = { ...state.statusFilters };
      const allSelected = filterGroup.every(filter => newFilters[filter]);
      filterGroup.forEach(filter => {
        newFilters[filter] = !allSelected;
      });
      saveUISettings('statusFilters', newFilters);
      return { ...state, statusFilters: newFilters };
    }

    case c.TOGGLE_SHOW_TEST_CLAIMS: {
      saveUISettings('showTestClaims', !state.showTestClaims);
      return { ...state, showTestClaims: !state.showTestClaims };
    }

    case c.TOGGLE_SHOW_MANUAL_CLAIMS: {
      saveUISettings('showManualClaims', !state.showManualClaims);
      return { ...state, showManualClaims: !state.showManualClaims };
    }

    case c.TOGGLE_SHOW_NON_EXPORTED_CLAIMS: {
      const showNonExportedClaims = action.payload;
      saveUISettings('showNonExportedClaims', showNonExportedClaims);
      return { ...state, showNonExportedClaims };
    }

    case c.UPDATE_CLAIM_SORT: {
      saveUISettings('sortBy', action.meta.sortBy);
      return {
        ...state,
        sortBy: action.meta.sortBy,
        // claims: sortClaims(state.claims, action.meta.sortBy),
      };
    }

    case c.UPDATE_SHOW_ALL_VALIDATION: {
      return {
        ...state,
        selectedClaim: {
          ...state.selectedClaim,
          showAllValidation: action.payload,
        },
      };
    }

    case c.UPDATE_CLAIMS_ON_EVENTSOURCE: {
      return {
        ...state,
        claims: updateClaims(state.claims, action.payload),
        selectedClaim: updateSelectedClaim(state.selectedClaim, action.payload),
      };
    }

    case c.UPDATE_CLAIM_EXTERNALCOMMENTS_FULFILLED: {
      const { payload } = action;
      if (payload && payload.data) {
        const simpleClaim = payload.data;
        const mergedClaim = {
          ...state.selectedClaim,
          simpleClaim: {
            ...state.selectedClaim.simpleClaim,
            services: simpleClaim.services,
          },
        };

        return {
          ...state,
          selectedClaim: mergedClaim,
        };
      }
      return state;
    }

    case c.SCRUB_SELECTED_CLAIM: {
      return { ...state, selectedClaim: {} };
    }

    case c.REMOVE_CLAIM_FROM_QUEUE: {
      return { ...state, claims: removeClaim(state.claims, action.payload) };
    }

    case c.MARK_CLAIM_FOR_REMOVAL: {
      return {
        ...state,
        markedForRemoval: [...state.markedForRemoval, action.payload],
      };
    }

    case c.CLEAR_MARKED_FOR_REMOVAL: {
      return {
        ...state,
        markedForRemoval: removeNonSelected(state.markedForRemoval, state.selectedClaim.id),
        animateMarked: false,
      };
    }

    case c.SET_ANIMATE_MARKED: {
      return { ...state, animateMarked: true };
    }

    case c.GET_CODE_INFO_FULFILLED: {
      const { type, code } = action.meta;
      const info = action.payload.data;
      return {
        ...state,
        codeInfo: {
          ...state.codeInfo,
          [type]: {
            ...state.codeInfo[type],
            [code]: info,
          },
        },
      };
    }

    case c.GET_CONTRACT_PLAN_INFO_FULFILLED: {
      const { plans } = action.payload.data;
      const justPlanInfo = [];
      plans.map(plan => {
        return justPlanInfo.push({
          policyNum: plan.policyNum,
          planName: plan.planName,
        });
      });
      return {
        ...state,
        contractPlanInfo: justPlanInfo,
      };
    }
    case c.GET_POSSIBLE_PLAN_FULFILLED: {
      const selectedPlan = action.payload.data;
      if (state.selectedClaim && state.selectedClaim.simpleClaim) {
        const policyNum =
          state.selectedClaim.simpleClaim && state.selectedClaim.simpleClaim.policyNum;
        const earliestDate = state.selectedClaim.earliestDt;
        if (
          (!state.selectedClaim.simpleClaim.planName ||
            state.selectedClaim.simpleClaim.planName === planErrorMessages.noPlans) &&
          policyNum
        ) {
          const { sixDegTPAID, sixDegPlanID } = state.selectedClaim;
          const planName = getPlanName(
            policyNum,
            sixDegTPAID,
            sixDegPlanID,
            selectedPlan,
            earliestDate
          );
          return {
            ...state,
            selectedPlan,
            selectedClaim: {
              ...state.selectedClaim,
              simpleClaim: { ...state.selectedClaim.simpleClaim, planName },
            },
          };
        }
      }
      return { ...state, selectedPlan };
    }
    case c.GET_PLANS_FULFILLED: {
      const plans = action.payload.data;
      if (state.selectedClaim && state.selectedClaim.simpleClaim) {
        const policyNum =
          state.selectedClaim.simpleClaim && state.selectedClaim.simpleClaim.policyNum;
        const earliestDate = state.selectedClaim.earliestDt;
        if (
          (!state.selectedClaim.simpleClaim.planName ||
            state.selectedClaim.simpleClaim.planName === planErrorMessages.noPlans) &&
          policyNum
        ) {
          const { sixDegTPAID, sixDegPlanID } = state.selectedClaim;
          const planName = getPlanName(policyNum, sixDegTPAID, sixDegPlanID, plans, earliestDate);
          return {
            ...state,
            plans,
            selectedClaim: {
              ...state.selectedClaim,
              simpleClaim: { ...state.selectedClaim.simpleClaim, planName },
            },
          };
        }
      }
      return { ...state, plans };
    }

    case c.OPEN_CONFIRM_MODAL: {
      return {
        ...state,
        pendingNoteUpdate: action.meta,
      };
    }

    case c.CLOSE_CONFIRM_MODAL: {
      return {
        ...state,
        pendingNoteUpdate: null,
      };
    }

    case c.DENY_CPP_DUPLICATE_FULFILLED:
    case c.COPY_CLAIM_FULFILLED: {
      const newSimple = action.payload.data.simple;
      if (!newSimple.planName)
        newSimple.planName = inflateString(state.selectedClaim.simpleClaim.planName);
      if (newSimple.services) newSimple.services.forEach(validateService);
      return {
        ...state,
        selectedClaim: validateAll(
          validateClaim(
            validateAllServices({
              ...state.selectedClaim,
              simpleClaim: newSimple,
            })
          )
        ),
      };
    }

    case c.UPDATE_PLAN: {
      const { planName, sixDegPlanID } = action.payload;
      return {
        ...state,
        selectedClaim: {
          ...state.selectedClaim,
          sixDegPlanID,
          simpleClaim: {
            ...state.selectedClaim.simpleClaim,
            planName,
          },
        },
      };
    }

    default:
      return state;
  }
};
