import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0.4em 0.8em',
    margin: '0 0.25em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      padding: '0.2em 0.4em',
    },
  },
  active: {
    backgroundColor: colors.selected,
  },
}));

const NavLinkForwardRef = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />);

export default function NavButton(props) {
  const { children, to, visible = true } = props;
  const classes = useStyles();

  if (!visible) return null;

  return (
    <Button
      className={classes.root}
      component={NavLinkForwardRef}
      to={inflateString(to)}
      activeClassName={classes.active}
    >
      {children}
    </Button>
  );
}

NavButton.defaultProps = {
  children: null,
  visible: true,
};

NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  visible: PropTypes.bool,
};
