import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { addSnackbar } from '../shared/components/snackbar/snackbarReducer';
import { updateStatus, updateShowAllValidation, setStatus } from './claimsActions';
import DropDownButton from './DropDownButton';
import {
  claimStatusCodes as statusCodes,
  getMenuItems,
  isStatusNew,
  isStatusResearch,
} from '../shared/status';
import * as types from '../shared/types/propTypes';

export class StatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = this.setHardStopError(props.claim);
    this.initShowAllValidation(props.claim.status);
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.setHardStopError(nextProps.claim));
    if (this.props.claim.id !== nextProps.claim.id) {
      this.initShowAllValidation(nextProps.claim.status);
    }
  }

  setHardStopError(claim) {
    if (this.props.isAdjud) return { hardStop: false }; // ignore validation errors if in adjudication (no validation currently defined)

    const { validation = {}, svcValidation = {}, allValidation = {} } = claim;
    return {
      hardStop:
        !!validation.error ||
        svcValidation.hasError ||
        !!allValidation.error ||
        allValidation.svcHasError,
    };
  }

  initShowAllValidation(status) {
    const showAllValidation = !(isStatusNew(status) || isStatusResearch(status));
    this.props.updateShowAllValidation(showAllValidation);
  }

  render() {
    const { hardStop } = this.state;
    const { setStatus, claim, isStatusUpdatePending } = this.props;
    const { status, showAllValidation, svcValidation } = claim;
    const { primary, options } = getMenuItems(status);
    const buttonProps = {
      variant: 'contained',
      color: 'primary',
      disabled:
        (showAllValidation && hardStop && status === statusCodes.repriceStarted) ||
        (svcValidation.hasError &&
          status !== statusCodes.verifyNew &&
          status !== statusCodes.verifyStarted &&
          status !== statusCodes.researchNew &&
          status !== statusCodes.researchStarted) ||
        isStatusUpdatePending,
      onClick: () => setStatus(primary, claim, hardStop),
    };
    const mapOptions = option => ({
      label: option,
      onClick: () => setStatus(option, claim, hardStop),
    });

    return Array.isArray(options) && options.length > 0 ? (
      <DropDownButton options={options.map(mapOptions)} {...buttonProps}>
        Set to {primary}
      </DropDownButton>
    ) : (
      <Button {...buttonProps}>Set to {primary}</Button>
    );
  }
}

StatusButton.defaultProps = {
  claim: {},
};
StatusButton.propTypes = {
  claim: types.claim,
  isStatusUpdatePending: PropTypes.bool.isRequired,
  isAdjud: PropTypes.bool.isRequired,
  handleFlaggedClick: PropTypes.func.isRequired,
  handleOnHoldClick: PropTypes.func.isRequired,
  // mapDispatchToProps //
  updateShowAllValidation: PropTypes.func.isRequired,
  addSnackbar: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  updateStatus,
  updateShowAllValidation,
  addSnackbar,
  setStatus,
};

export default connect(null, mapDispatchToProps)(StatusButton);
