import queryString from 'query-string';
import update from 'immutability-helper';
import { doesStringArrayIncludeString } from '@apps/shared/src/utilities';
import * as u from './userActions';

const user = {
  fetching: false,
  loggedIn: false,
  currentUser: {},
  users: [],
  appRoles: [],
  csrfToken: localStorage.getItem('csrfToken'),
  redirectTo: '',
  registerEmail: '',
  registerDestinationURL: '',
  passwordResetSuccess: false,
  isCaseMember: false,
};

export default (state = user, action) => {
  switch (action.type) {
    case u.FETCH_USER_PENDING:
    case u.CREATE_PROFILE_PENDING:
    case u.LOGIN_PENDING:
    case u.REGISTER_PENDING:
    case u.VERIFY_EMAIL_PENDING: {
      return { ...state, fetching: true };
    }

    case u.FETCH_USER_FULFILLED: {
      return { ...state, fetching: false, loggedIn: true, currentUser: action.payload.data };
    }

    case u.GET_APP_USERS_FULFILLED:
    case u.GET_USERS_FULFILLED: {
      const users = Array.isArray(action.payload.data) ? action.payload.data : [];
      const appRoles =
        action.type === u.GET_APP_USERS_FULFILLED && action.meta && action.meta.appName
          ? users
              .flatMap(i => i.roles)
              .filter((v, i, a) => v.startsWith(action.meta.appName) && a.indexOf(v) === i)
              .sort()
          : [];
      return { ...state, fetching: false, loggedIn: true, users, appRoles };
    }

    case u.FETCH_USER_REJECTED:
    case u.FETCH_USER_CANCELLED:
    case u.CREATE_PROFILE_REJECTED:
    case u.LOGIN_REJECTED: {
      localStorage.setItem('csrfToken', '');
      return { ...state, fetching: false, loggedIn: false, csrfToken: '', currentUser: {} };
    }

    case u.LOGOUT_REJECTED:
    case u.LOGOUT_FULFILLED: {
      localStorage.setItem('csrfToken', '');
      return {
        ...state,
        fetching: false,
        loggedIn: false,
        csrfToken: '',
        currentUser: {},
        redirectTo: getRedirectURL(action.meta.destinationURL),
      };
    }

    case u.REQUEST_PASSWORD_RESET_FULFILLED: {
      return {
        ...state,
        fetching: false,
        redirectTo: getRedirectURL('/request-password-reset-success', state.csrfToken),
      };
    }

    case u.REGISTER_FULFILLED: {
      return { ...state, fetching: false, redirectTo: action.meta.destinationURL };
    }

    case u.REQUEST_PASSWORD_RESET_REJECTED:
    case u.VERIFY_PASSWORD_RESET_REJECTED:
    case u.REGISTER_REJECTED: {
      return { ...state, fetching: false };
    }

    case u.VERIFY_EMAIL_FULFILLED: {
      const { destinationURL, csrfToken, email } = action.payload.data;
      localStorage.setItem('csrfToken', csrfToken);
      return {
        ...state,
        fetching: false,
        redirectTo: '/create-profile',
        csrfToken,
        registerEmail: email,
        registerDestinationURL: destinationURL,
      };
    }

    case u.REQUEST_PASSWORD_RESET_PENDING:
    case u.VERIFY_PASSWORD_RESET_PENDING: {
      return { ...state, fetching: true };
    }

    case u.VERIFY_PASSWORD_RESET_FULFILLED: {
      const { destinationURL, csrfToken, email } = action.payload.data;
      localStorage.setItem('csrfToken', csrfToken);
      return {
        ...state,
        fetching: false,
        redirectTo: '/update-password',
        csrfToken,
        registerEmail: email,
        registerDestinationURL: destinationURL,
      };
    }

    case u.UPDATE_PASSWORD_FULFILLED: {
      const { csrfToken, user } = action.payload.data;
      localStorage.setItem('csrfToken', csrfToken);
      return {
        ...state,
        fetching: false,
        csrfToken,
        loggedIn: true,
        currentUser: user,
        passwordResetSuccess: true,
      };
    }

    case u.LOGIN_FULFILLED:
    case u.CREATE_PROFILE_FULFILLED: {
      const { csrfToken, user } = action.payload.data;
      const isCaseMember =
        user.roles.includes('medivi-casesmember') &&
        user.roles.includes('medivi-casesubmissionmember');
      localStorage.setItem('csrfToken', csrfToken);
      return {
        ...state,
        fetching: false,
        csrfToken,
        loggedIn: true,
        currentUser: user,
        redirectTo: getRedirectURL(
          state.registerDestinationURL || action.meta.destinationURL,
          csrfToken
        ),
        isCaseMember,
      };
    }

    case u.REDIRECT_TO: {
      return { ...state, redirectTo: action.payload };
    }

    case u.REDIRECT_COMPLETE: {
      return { ...state, redirectTo: '' };
    }

    case u.ADD_ROLE_FULFILLED: {
      const { userID, role } = action.meta;
      const userIndex = state.users.findIndex(user => user.userID === userID);

      if (userIndex === -1 || doesStringArrayIncludeString(state.users[userIndex].roles, role))
        return state;

      if (state.users[userIndex].roles == null) {
        return update(state, {
          users: {
            [userIndex]: {
              roles: { $set: [role] },
            },
          },
        });
      }

      return update(state, {
        users: {
          [userIndex]: {
            roles: { $push: [role] },
          },
        },
      });
    }

    case u.REMOVE_ROLE_FULFILLED: {
      const { userID, role } = action.meta;
      const userIndex = state.users.findIndex(user => user.userID === userID);
      if (userIndex === -1 || state.users[userIndex].roles == null) return state;

      const roleIndex = state.users[userIndex].roles.indexOf(role);
      if (roleIndex === -1) return state;

      return update(state, {
        users: {
          [userIndex]: {
            roles: { $splice: [[roleIndex, 1]] },
          },
        },
      });
    }

    default:
      return state;
  }
};

export function getRedirectURL(destinationURL, csrfToken) {
  if (
    !destinationURL ||
    (destinationURL.startsWith('http') && destinationURL.startsWith(window.location.origin))
  )
    return destinationURL;

  const parsed = queryString.parseUrl(destinationURL);
  const from = parsed.query && parsed.query.from ? encodeURIComponent(parsed.query.from) : '';
  const search = getSearchQuery(from, csrfToken);
  return `${parsed.url}${search}`;
}

export function getSearchQuery(from, csrfToken) {
  if (from || csrfToken) return `?${queryString.stringify({ csrfToken, from })}`;
  return '';
}
