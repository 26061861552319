import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';

import MenuItemCompact from './MenuItemCompact';
import { isArrayFilled, validArray } from '../typeChecks';

const useStyles = makeStyles({
  addButton: {
    marginTop: '0.5em',
  },
});

function getButtonText(options, selected) {
  if (!isArrayFilled(options)) return 'Select Option';

  const selectedOption = options.find(o => o.value === selected);
  return selectedOption ? selectedOption.name : options[0].name;
}

function createMenuItemClickHandler(handleClick, menuOptionValue, handleClose) {
  return () => {
    handleClick(menuOptionValue);
    handleClose();
  };
}

export default function PopperSelect({
  options,
  setOption,
  selected,
  buttonVariant,
  buttonText,
  visible = true,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!visible) return null;

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} className={classes.addButton} variant={buttonVariant}>
        {buttonText || getButtonText(options, selected)}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
      >
        <MenuList>
          {validArray(options).map(option => (
            <MenuItemCompact
              key={option.value}
              onClick={createMenuItemClickHandler(setOption, option.value, handleClose)}
              selected={selected === option.value}
            >
              {option.name}
            </MenuItemCompact>
          ))}
        </MenuList>
      </Popover>
    </>
  );
}

PopperSelect.defaultProps = {
  buttonVariant: undefined,
  buttonText: undefined,
  selected: undefined,
  visible: true,
};
PopperSelect.propTypes = {
  buttonVariant: PropTypes.string,
  buttonText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.string,
  setOption: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};
