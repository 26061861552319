import React from 'react';

import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

type ParentProps = {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function VisibilityAdornment({
  showPassword,
  setShowPassword,
}: ParentProps): JSX.Element {
  const toggleShowPassword: VoidFunction = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
}
