import MonatDate from './MonatDate';
import {
  sanitizeDelims,
  parseYear,
  parseMonth,
  parseDay,
  formatBlock,
  validBlock,
} from './monatUtilities';

export default class MonatFormat {
  constructor(format, delim, flexibleYear) {
    this.blocks = format.split(delim);
    this.delim = delim;
    this.flexibleYear = flexibleYear;
    this.name = format;
    this.yearIndex = this.blocks.indexOf('yyyy');
    this.monthIndex = this.blocks.indexOf('mm');
    this.dayIndex = this.blocks.indexOf('dd');
  }

  parseNumeric(input) {
    let value = sanitizeDelims(input);
    let year = '';
    let month = '';
    let day = '';
    const blocks = this.blocks.map(block => {
      switch (block) {
        case 'yyyy':
          [year, value] = parseYear(value, this.flexibleYear);
          return year;
        case 'mm':
          [month, value] = parseMonth(value);
          return month;
        case 'dd':
          [day, value] = parseDay(value, +month, +year);
          return day;
        default:
          return '';
      }
    });
    return new MonatDate(this, blocks, year, month, day);
  }

  insertDelim(date, position) {
    const blocks = [];
    let blockPos = 0;
    for (let i = 0; i < this.blocks.length; i++) {
      const blockSize = this.blocks[i].length;
      let block;
      if (position > blockPos && position <= blockPos + blockSize) {
        const blockType = this.blocks[i];
        block = formatBlock(date.slice(blockPos, position), blockType, this.flexibleYear);
        if (!validBlock(block, blockType, blocks[this.yearIndex], blocks[this.monthIndex])) {
          return null;
        }
        blockPos = position;
      } else {
        block = date.slice(blockPos, blockPos + blockSize);
        blockPos += blockSize;
      }
      blocks.push(block);
    }
    return new MonatDate(this, blocks);
  }
}
