import React, { ReactNode, MouseEventHandler } from 'react';
import classNames from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  button: {
    margin: '0.2rem',
    padding: '.4rem 1rem',
  },
  selectedButton: {
    border: '1px solid transparent',
  },
});

type Props = Pick<ButtonProps, 'color'> & {
  selectedValue?: Option['value'];
  options: Option[];
  onChange: (value: Option['value']) => void;
  isDisabled?: ButtonProps['disabled'];
};

export type Option = {
  value: string;
  content: ReactNode;
  title?: string;
};

export default function ExclusiveButtons({
  selectedValue,
  options,
  onChange,
  color = 'primary',
  isDisabled = false,
}: Props): JSX.Element {
  const classes = useStyles();

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    const { value } = event.currentTarget;
    if (selectedValue !== value) onChange(value);
  };

  return (
    <div>
      {options.map(option => {
        const isSelected = selectedValue === option.value;
        return (
          <Tooltip title={option.title || ''} key={option.value}>
            <Button
              className={
                isSelected ? classNames(classes.button, classes.selectedButton) : classes.button
              }
              color={color}
              variant={isSelected ? 'contained' : 'outlined'}
              value={option.value}
              onClick={onButtonClick}
              disabled={isDisabled}
              disableElevation
            >
              {option.content}
            </Button>
          </Tooltip>
        );
      })}
    </div>
  );
}
