import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Policy from './Policy';
import Person from '../claimInfo/Person';
import Provider from '../claimInfo/Provider';
import Title from '../claimInfo/Title';
import LabeledFlexColumn from '../claimInfo/LabeledFlexColumn';
import { FlexRow } from '../shared/grid';
import * as types from '../shared/types/propTypes';
import SurpriseBillIndicator from './SurpriseBillIndicator';

const useStyles = makeStyles({
  billingInfoRow: {
    marginBottom: '0.5rem',
  },
});

export default function ClaimHeader({ claim, handleEditPolicyNum }) {
  const classes = useStyles();

  if (!(claim && claim.simpleClaim)) return null;

  const { simpleClaim, status, earliestDt, sixDegTPAID, sixDegPlanID, senderName } = claim;
  const { billProv, serviceLocation, subscriber, patient, policyNum } = simpleClaim;
  const isEditable = typeof status === 'string' && status.startsWith('Research');

  const serviceLoc = serviceLocation ? <Provider provider={serviceLocation} /> : null;

  return (
    <>
      <Title simpleClaim={simpleClaim} />
      <FlexRow className={classes.billingInfoRow}>
        <LabeledFlexColumn label="Billing Provider">
          <Provider provider={billProv} />
        </LabeledFlexColumn>

        <LabeledFlexColumn label="Service Location">{serviceLoc}</LabeledFlexColumn>

        <LabeledFlexColumn label="Policy">
          <Policy
            status={status}
            earliestDt={earliestDt}
            sixDegTPAID={sixDegTPAID}
            sixDegPlanID={sixDegPlanID}
            save={handleEditPolicyNum}
            policyNum={policyNum}
            isEditable={isEditable}
            senderName={senderName}
          />
        </LabeledFlexColumn>

        <LabeledFlexColumn label="Subscriber">
          <Person details={subscriber} earliestDt={earliestDt} />
        </LabeledFlexColumn>

        <LabeledFlexColumn label="Patient">
          <Person details={patient} earliestDt={earliestDt} />
        </LabeledFlexColumn>

        <LabeledFlexColumn label="Surprise Bill Indicator">
          <SurpriseBillIndicator />
        </LabeledFlexColumn>
      </FlexRow>
    </>
  );
}

ClaimHeader.propTypes = {
  claim: types.claim.isRequired,
  handleEditPolicyNum: PropTypes.func.isRequired,
};
