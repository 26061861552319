import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import { inflateNumber } from '@apps/shared/src/inflators';
import { colors } from '@apps/shared/src/style';
import { updateRepricing } from './claimsActions';
import Notes from './Notes';
import TextInput from './TextInput';
import { parseTypeOfBill } from '../manualClaim/claimsReducer';
import { Grid, GridRow, GridColumn } from '../shared/grid';
import * as types from '../shared/types/propTypes';
import ConfirmDialog from './ConfirmDialog';
import RepricingMethodologyOverride from './RepricingMethodologyOverride';

const styles = {
  grid: {
    backgroundColor: colors.grey05,
  },
  disabled: {
    color: colors.grey25,
  },
  effectiveMultipleContainer: {
    gridColumn: 'effective-multiple',
    justifySelf: 'end',
    marginInlineEnd: '3em',
  },
  effectiveMultiple: {
    fontSize: 14,
    textDecoration: 'underline dotted',
    paddingRight: '0.3em',
  },
  infoIcon: {
    width: '0.7em',
    fill: colors.blue,
    padding: '0 0.1em',
  },
  refTotal: {
    gridColumn: 'reference-amt',
  },
  claimTotal: {
    gridColumn: 'total',
  },
};

function formatEffectiveMultiple(effectiveMultiple) {
  if (!Number.isFinite(effectiveMultiple)) return '';
  return Math.round(effectiveMultiple * 100) / 100;
}
export function TotalItem({
  claim,
  saveMetadata,
  showAllValidation,
  columnWidths,
  claimStatus,
  classes,
  updateRepricing,
}) {
  const [dialog, setDialog] = useState({
    isOpen: false,
    contentText: '',
    onConfirm: undefined,
  });
  const { simpleClaim, validation = {}, allValidation = {} } = claim;
  const {
    typeOfBill,
    formType,
    repriceTotal,
    extCmnt,
    extStatusNote,
    intCmnt,
    refTotal,
    services,
    effectiveMultiple,
  } = simpleClaim;
  const claimType = parseTypeOfBill(typeOfBill, formType);
  const isNotInpatient = claimType !== 'I';
  const effectiveMultipleText = formatEffectiveMultiple(effectiveMultiple);

  const saveRefTotal = updateInfo => {
    for (const service of services) {
      if (service.repricing?.reference !== undefined) {
        setDialog({
          isOpen: true,
          contentText: `You entered a ref total amount of $${inflateNumber(
            updateInfo.numberVal
          )}. Overwrite this claim's existing reference amounts and reprice totals?`,
          onConfirm: () => {
            updateRepricing(updateInfo);
            setDialog(dialog => ({ ...dialog, isOpen: false }));
          },
        });
        return;
      }
    }
    updateRepricing(updateInfo);
  };

  const handleCancel = () => setDialog(dialog => ({ ...dialog, isOpen: false }));

  return (
    <Grid columns={columnWidths} component={GridRow} className={classes.grid}>
      {effectiveMultipleText !== '' && (
        <GridColumn className={classes.effectiveMultipleContainer}>
          <Tooltip title="Claim Total / Ref Total" aria-label="claim total / ref total">
            <Typography variant="body1" className={classes.effectiveMultiple}>
              Effective Reference Multiple:
            </Typography>
          </Tooltip>
          {effectiveMultipleText}
        </GridColumn>
      )}
      <GridColumn
        className={classNames({
          [classes.refTotal]: true,
          [classes.disabled]: isNotInpatient,
        })}
      >
        <TextInput
          id={claim.claimID}
          isMoney
          disabled={isNotInpatient}
          value={refTotal}
          fullWidth
          saveMetadata={{ ...saveMetadata, fieldName: 'simpleClaim.refTotal' }}
          save={saveRefTotal}
          label="Ref Total"
        />
      </GridColumn>
      <RepricingMethodologyOverride
        saveMetadata={saveMetadata}
        services={services}
        setDialog={setDialog}
      />
      <GridColumn
        className={classNames({
          [classes.claimTotal]: true,
          [classes.disabled]: isNotInpatient,
        })}
      >
        <TextInput
          id={claim.claimID}
          isMoney
          disabled
          value={repriceTotal}
          validation={{
            ...validation,
            ...allValidation,
            showValidation: validation.showValidation || showAllValidation,
          }}
          fullWidth
          label="Claim Total"
        />
      </GridColumn>

      <Notes
        key={`${saveMetadata.claimID}-tot-${extCmnt && extCmnt.code}`}
        type="claim"
        external={extCmnt}
        externalStatus={extStatusNote}
        claimStatus={claimStatus}
        internal={intCmnt}
        saveMetadata={saveMetadata}
        intCmntFieldName="simpleClaim.intCmnt"
        extCmtFieldName="simpleClaim.extCmnt"
      />

      <ConfirmDialog
        isOpen={dialog.isOpen}
        label="Overwrite Warning"
        contentText={dialog.contentText}
        onCancel={handleCancel}
        onConfirm={dialog.onConfirm}
      />
    </Grid>
  );
}

TotalItem.propTypes = {
  claim: types.claim.isRequired,
  claimStatus: PropTypes.string.isRequired,
  showAllValidation: PropTypes.bool.isRequired,
  columnWidths: PropTypes.string.isRequired,
  saveMetadata: types.saveMetadata.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  updateRepricing: PropTypes.func.isRequired,
};

const mapStateToProps = ({ claims }) => ({
  showAllValidation:
    claims.selectedClaim != null && claims.selectedClaim.showAllValidation === true,
});

const mapDispatchToProps = {
  updateRepricing,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TotalItem));
