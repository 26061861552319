import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../shared/components/TextInput';
import { checkDate } from '../shared/validators';

const DateInput = ({ onValueChange, ...passThroughProps }) => {
  const handleBlur = value => {
    const [isValid, parsedDate] = checkDate(value.trim());
    const newValue = isValid ? parsedDate : value.trim();

    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return <TextInput placeholder="M/D/Y or Y-M-D" onBlur={handleBlur} {...passThroughProps} />;
};

DateInput.defaultProps = {
  value: '',
  onValueChange: null,
};
DateInput.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func,
};

export default DateInput;
