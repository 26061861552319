import React from 'react';

import AddIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/HighlightOff';

import IconButtonCompact from '../../../shared/components/IconButtonCompact';

type Props = {
  handleConfirm: () => void;
  handleCancel: () => void;
};
export default function ConfirmOrCancelCompact({
  handleConfirm,
  handleCancel,
}: Props): JSX.Element {
  return (
    <>
      <IconButtonCompact onMouseDown={handleConfirm}>
        <AddIcon />
      </IconButtonCompact>
      <IconButtonCompact onMouseDown={handleCancel}>
        <RemoveIcon />
      </IconButtonCompact>
    </>
  );
}
