import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState } from './types';
import Redirect from './Redirect';
import { clearRedirect } from './userActions';

type UserState = RootState['user'];
type StateProps = {
  redirectTo: UserState['redirectTo'];
};
const mapStateToProps = ({ user }: RootState): StateProps => ({
  redirectTo: user.redirectTo,
});

type DispatchProps = {
  clearRedirect: typeof clearRedirect;
};

const mapDispatchToProps = {
  clearRedirect,
};

type ParentProps = {
  children: JSX.Element;
};
type Props = ParentProps & StateProps & DispatchProps;

function WithRedirect({ children, redirectTo, clearRedirect }: Props): JSX.Element {
  const notEmpty = redirectTo != null && redirectTo !== '';
  const sameOrigin = isSameOrigin(redirectTo);
  useEffect(() => {
    if (notEmpty) {
      if (sameOrigin) {
        clearRedirect();
      } else {
        window.location.href = redirectTo;
      }
    }
  }, [notEmpty, sameOrigin, redirectTo, clearRedirect]);

  if (notEmpty) {
    if (sameOrigin) {
      return <Redirect to={redirectTo} />;
    }

    return <p>Redirecting to: {redirectTo}.</p>;
  }

  return children;
}

function isSameOrigin(url: string): boolean {
  const windowOrigin = window.location.origin;
  const fullURL = new URL(url, windowOrigin);

  return fullURL.origin === windowOrigin;
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRedirect);
