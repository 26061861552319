import React from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import Card from '@material-ui/core/Card';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import { Case } from './Case';
import { RootState } from '../../shared/types/types';
import { SFCase, SettlementsClaimSummary, PlanImpact } from '../types/types';
import { removeCase, resetSettlements } from '../settlementsActions';

export function filterClaimsByCaseNumber(
  claims: SettlementsClaimSummary[],
  caseNumber: string
): SettlementsClaimSummary[] {
  return claims.filter(claim => claim.sfCaseNumber === caseNumber);
}

const useStyles = makeStyles({
  allCases: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  case: {
    padding: '10px',
    margin: '8px',
    width: '430px',
    position: 'relative',
    pageBreakAfter: 'always',
    '&:nth-child(6)': {
      '@media print': {
        marginBottom: '80px',
      },
    },
    '@media print': {
      '&:nth-child(6), &:nth-child(5)': {
        marginBottom: '80px',
      },
      '&:nth-child(11n), &:nth-child(12n)': {
        marginBottom: '340px',
      },
    },
  },
});

type StateProps = {
  sfCases: SFCase[];
  claims: SettlementsClaimSummary[];
  loadingCases: number;
  planImpact?: PlanImpact;
  messages?: Record<string, string[]>;
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    sfCases: settlements.sfCases,
    loadingCases: settlements.loadingCases,
    claims: settlements.claims,
    planImpact: settlements.planImpact,
    messages: settlements.messages,
  };
};

const mapDispatchToProps = {
  removeCase,
  resetSettlements,
};

type Props = StateProps & typeof mapDispatchToProps;

export function AllCases({
  sfCases,
  loadingCases,
  claims,
  removeCase,
  planImpact,
  messages,
}: Props): JSX.Element {
  const classes = useStyles();

  const isDeleteDisabled = Boolean(planImpact);

  return (
    <div className={classes.allCases}>
      {sfCases.map(sfCase => {
        return (
          <Card key={sfCase.caseNumber} className={classes.case}>
            <Case
              sfCase={sfCase}
              claims={filterClaimsByCaseNumber(claims, sfCase.caseNumber)}
              removeCase={removeCase}
              isDeleteDisabled={isDeleteDisabled}
            />
          </Card>
        );
      })}

      {loadingCases > 0 && (
        <Card className={classes.case} data-testid="loadingCard">
          <LoadingSpinner />
        </Card>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCases);
