import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { isValidInteger } from '@apps/shared/src/guards';
import { termItems } from './terms/termElements';
import {
  ContractUpdate,
  EventsourceUpdate,
  ContractState,
  TermItem,
  ContractProvider,
  ContractRoster,
  LoadableElement,
  OpenableElement,
  AutocompleteOptions,
  AutocompleteOption,
  AutocompleteType,
  ContractTerm,
  TermValue,
  ContractTypes,
} from './types/contracts';
import * as c from './types/actions';
import { RootState } from '../shared/types/types';
import { inflatePatient, emptyRosterValues, emptyProviderValues } from './contractsReducer';
import {
  checkAllProvidersCanBeSaved,
  getNonEmptyProviders,
  removeNonDigitCharacters,
  getNPIError,
  getTaxIDError,
  getManualRoster,
  checkIsSCAorLOA,
} from './contractUtilities';
import { addSnackbar as addMessage } from '../shared/components/snackbar/snackbarReducer';
import { SnackbarAction } from '../shared/components/snackbar/snackbarTypes';

type ContractDispatch = Dispatch<c.ContractActionTypes>;

/* Contract Actions */

export function getContracts(): c.ContractActionTypes {
  return {
    type: c.GET_CONTRACTS,
    payload: axios.get(`/api/contracts`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getContract(id: number): c.ContractActionTypes {
  return {
    type: c.GET_CONTRACT,
    meta: { id },
    payload: axios.get(`/api/contracts/${id}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function searchAutocomplete(type: AutocompleteType, searchText: string) {
  return (dispatch: ContractDispatch, getState: () => RootState): void => {
    const { autocompleteType } = getState().contracts;
    if (type !== autocompleteType)
      dispatch({
        type: c.SET_AUTOCOMPLETE_TYPE,
        payload: type,
      });
    if (searchText.length > 2)
      dispatch({
        type: c.GET_AUTOCOMPLETE,
        payload: axios.get(`/api/autocomplete/${type}/${searchText}`, {
          headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
        }),
      });
  };
}

export function clearContract(): c.ContractActionTypes {
  return {
    type: c.CLEAR_SELECTED_CONTRACT,
  };
}

export function searchContracts(text: string): c.ContractActionTypes {
  return {
    type: c.SEARCH_CONTRACTS,
    meta: text,
  };
}

export function updateContractOnEventSource(
  contractUpdate: EventsourceUpdate
): c.ContractActionTypes {
  return {
    type: c.UPDATE_CONTRACTS_ON_EVENTSOURCE,
    payload: contractUpdate,
  };
}

function selectElement(
  autocompleteOptions: AutocompleteOptions,
  updateObject: ContractUpdate
): AutocompleteOption | undefined {
  switch (updateObject.action) {
    case 'addPatient':
    case 'addPlan':
      return autocompleteOptions.find(o => o.id === updateObject.intVal);
    case 'addUnidentifiedPatient':
      return inflatePatient({ name: updateObject.stringVal });
    default:
      return undefined;
  }
}

export function updateContract(action: string, value: string | number) {
  return (
    dispatch: Dispatch<c.ContractActionTypes | SnackbarAction>,
    getState: () => RootState
  ): void => {
    const {
      autocompleteOptions,
      hasUnsavedRosterChanges,
      selectedContract: { id },
    } = getState().contracts;

    const isActivatingUnsavedChanges =
      action === 'status' && value === 'Active' && hasUnsavedRosterChanges;
    if (isActivatingUnsavedChanges) {
      dispatch(addMessage('Save changes to roster before activating contract'));
      return;
    }

    const valueType = isValidInteger(value) ? 'intVal' : 'stringVal';
    const updateObject = { action, [valueType]: value } as unknown as ContractUpdate;

    const elementToAttach = selectElement(autocompleteOptions, updateObject);
    if (id)
      dispatch({
        type: c.UPDATE_CONTRACT,
        meta: { id, updateObject, elementToAttach },
        payload: axios.put(`/api/contracts/${id}`, updateObject, {
          headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
        }),
      });
  };
}

export function createContract(contractType: ContractTypes): c.ContractActionTypes {
  return {
    type: c.CREATE_CONTRACT,
    payload: axios.post(
      `/api/contracts`,
      { contractType },
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}

export function deleteContract() {
  return (dispatch: ContractDispatch, getState: () => RootState): void => {
    const {
      selectedContract: { id },
    } = getState().contracts;
    dispatch({
      type: c.DELETE_CONTRACT,
      meta: { id },
      payload: axios.delete(`/api/contracts/${id}`, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
    });
  };
}

export function handleClearTermValues(): c.ContractActionTypes {
  return {
    type: c.CLEAR_TERM_VALUES,
  };
}

export function setCurrentTerm(term: ContractTerm): c.ContractActionTypes {
  return {
    type: c.SET_SELECTED_TERM,
    payload: { term },
  };
}

export function updateTermValue(
  value: TermValue,
  termType: TermItem,
  clear: boolean
): c.ContractActionTypes {
  if (!clear)
    return {
      type: c.UPDATE_TERM_VALUE,
      payload: {
        termType,
        value,
      },
    };
  return {
    type: c.UPDATE_TERM_VALUE,
    payload: {
      termType,
      value: '',
    },
  };
}

function gatherTerm(currentTermValues: ContractTerm, selectedTermItems: TermItem[]): ContractTerm {
  const term: { [index: string]: string | number | boolean | string[] | undefined } = {};
  selectedTermItems.forEach(item => {
    term[item] = currentTermValues[item];
  });
  if (
    selectedTermItems.filter((item: TermItem) =>
      [termItems.percentCMS, termItems.flatRate, termItems.percentBilledCharges].includes(item)
    ).length >= 2
  ) {
    term.comparisonType = currentTermValues.comparisonType;
  }
  if (currentTermValues.id) term.id = currentTermValues.id;
  if (currentTermValues.rangeInclusive) {
    if (currentTermValues.startRange) term.startRange = currentTermValues.startRange;
    if (currentTermValues.endRange) term.endRange = currentTermValues.endRange;
  }
  if (selectedTermItems.includes(termItems.unlistedCharges)) term.unlistedCharges = true;
  return term;
}

export function handleSubmitNewTerm() {
  return (dispatch: ContractDispatch, getState: () => RootState): void => {
    const state = getState().contracts;
    const { id } = state.selectedContract;
    const { currentTermValues, selectedTermItems } = state;
    const term = gatherTerm(currentTermValues, selectedTermItems);
    dispatch({
      type: c.SUBMIT_NEW_TERM,
      payload: axios.post(`/api/new-contract-term/${id}`, term, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
      meta: term,
    });
  };
}

export function handleSetSelectedTermItems(selections: TermItem[]): c.ContractActionTypes {
  return {
    type: c.SET_SELECTED_TERM_ITEMS,
    payload: { selections },
  };
}

export function cancelTerm() {
  return (dispatch: ContractDispatch): void => {
    dispatch(setIsElementOpen('term', false));
    dispatch(handleSetSelectedTermItems([]));
    dispatch(handleClearTermValues());
  };
}

export function cancelOpenTerm() {
  return (dispatch: ContractDispatch): void => {
    dispatch(setIsElementOpen('openTerm', false));
    dispatch(handleSetSelectedTermItems([]));
    dispatch(handleClearTermValues());
  };
}

/* Roster Actions */

export function addOrUpdateRoster() {
  return (
    dispatch: Dispatch<c.ContractActionTypes | SnackbarAction>,
    getState: () => RootState
  ): void => {
    const state: ContractState = getState().contracts;
    const {
      selectedContract: { id, contractType },
      selectedRoster,
    } = state;

    const nonEmptyProviders = getNonEmptyProviders(selectedRoster.providers);
    nonEmptyProviders.map(provider => (provider.npi !== '' ? +provider.npi : provider.npi));

    const rosterSaveError = checkRosterSaveError(nonEmptyProviders, contractType);
    if (rosterSaveError !== '') {
      dispatch(addMessage(rosterSaveError));
      return;
    }

    const nonEmptyRoster = {
      ...selectedRoster,
      providers: nonEmptyProviders,
    };
    dispatch({
      type: c.ADD_OR_UPDATE_ROSTER,
      meta: {
        submittedRoster: nonEmptyRoster,
      },
      payload: axios.post(
        `/api/roster`,
        {
          submittedRoster: nonEmptyRoster,
          submittedContractID: id,
        },
        {
          headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
        }
      ),
    });
  };
}

export function getOrCreateManualRoster() {
  return (
    dispatch: ThunkDispatch<RootState, undefined, c.ContractActionTypes>,
    getState: () => RootState
  ): void => {
    const manualRoster = getManualRoster(getState().contracts.selectedContract);

    if (manualRoster) dispatch(getRoster(manualRoster.id));
    else dispatch(createNewRoster());
  };
}

function checkRosterSaveError(
  nonEmptyProviders: ContractProvider[],
  contractType: ContractTypes
): string {
  if (nonEmptyProviders.length < 1) return 'At least one provider required to save';

  if (!checkAllProvidersCanBeSaved(nonEmptyProviders)) {
    const whatWeAreSaving = checkIsSCAorLOA(contractType) ? 'provider' : 'roster';
    return `Unable to save ${whatWeAreSaving}. Please fix errors`;
  }
  return '';
}

export function getRoster(rosterID: number) {
  return (dispatch: ContractDispatch, getState: () => RootState): void => {
    const {
      contracts: {
        selectedRoster,
        isContractLoading,
        isRosterLoading,
        selectedContract: { id, contractType },
      },
    } = getState();

    const isDuplicateRequest =
      rosterID === selectedRoster.id && contractType !== ContractTypes.SingleCase;
    if (isContractLoading || isRosterLoading || isDuplicateRequest) return;

    dispatch({
      type: c.GET_ROSTER,
      meta: {
        selectedContractID: id,
      },
      payload: axios.get(`/api/roster/${rosterID}`, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
    });
  };
}

export function removeRoster(rosterID: number) {
  return (dispatch: ContractDispatch, getState: () => RootState): void => {
    const { isRosterLoading, isContractLoading } = getState().contracts;

    if (isRosterLoading || isContractLoading) return;

    dispatch({
      type: c.REMOVE_ROSTER,
      payload: axios.delete(`/api/roster/${rosterID}`, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
    });
  };
}

export function updateSelectedRoster(roster: ContractRoster): c.ContractActionTypes {
  return {
    type: c.UPDATE_SELECTED_ROSTER,
    payload: roster,
  };
}

const openableFields: Record<OpenableElement, string> = {
  roster: 'isRosterOpen',
  patient: 'isPatientOpen',
  plan: 'isPlanOpen',
  term: 'isTermOpen',
  openTerm: 'isOpenTermOpen',
};
export function setIsElementOpen(
  elementName: OpenableElement,
  bool: boolean
): c.ContractActionTypes {
  return {
    type: c.SET_BOOLEAN_ELEMENT,
    payload: { fieldName: openableFields[elementName], bool },
  };
}

const loadableFields: Record<LoadableElement, string> = {
  contract: 'isContractLoading',
};
export function setIsElementLoading(
  elementName: LoadableElement,
  bool: boolean
): c.ContractActionTypes {
  return {
    type: c.SET_BOOLEAN_ELEMENT,
    payload: { fieldName: loadableFields[elementName], bool },
  };
}

export function cancelNewRoster() {
  return (dispatch: Dispatch<c.ContractActionTypes>): void => {
    dispatch(updateSelectedRoster(emptyRosterValues()));
    dispatch(setHasUnsavedRosterChanges(false));
    dispatch(setIsElementOpen('roster', false));
  };
}

export function createNewRoster() {
  return (dispatch: ContractDispatch): void => {
    dispatch(setIsElementOpen('roster', true));
  };
}

export function setHasUnsavedRosterChanges(
  hasUnsavedRosterChanges: boolean
): c.ContractActionTypes {
  return {
    type: c.SET_BOOLEAN_ELEMENT,
    payload: {
      fieldName: 'hasUnsavedRosterChanges',
      bool: hasUnsavedRosterChanges,
    },
  };
}

/* Provider Actions */

export function setSelectedProvider(p: ContractProvider): c.ContractActionTypes {
  return {
    type: c.SET_SELECTED_PROVIDER,
    payload: p,
  };
}

export function addProvider() {
  return (dispatch: ContractDispatch, getState: () => RootState): void => {
    const { selectedRoster } = getState().contracts;

    dispatch(
      updateSelectedRoster({
        ...selectedRoster,
        providers: [...selectedRoster.providers, emptyProviderValues()],
      })
    );
  };
}

export function removeProvider(index: number) {
  return (dispatch: ContractDispatch, getState: () => RootState): void => {
    const { selectedRoster } = getState().contracts;
    const updatedProviders = [...selectedRoster.providers];
    updatedProviders.splice(index, 1);
    dispatch(
      updateSelectedRoster({
        ...selectedRoster,
        providers: updatedProviders,
      })
    );
  };
}

export function saveSelectedProviderToRoster(row: number) {
  return (
    dispatch: ThunkDispatch<RootState, undefined, c.ContractActionTypes>,
    getState: () => RootState
  ): void => {
    if (row < 0) return;
    const { selectedProvider, selectedRoster } = getState().contracts;
    const updatedProviders = [...selectedRoster.providers];
    updatedProviders[row] = { ...selectedProvider };

    dispatch(
      updateSelectedRoster({
        ...selectedRoster,
        providers: updatedProviders,
      })
    );
  };
}

export function lookupProvider(npi: string): c.ContractActionTypes {
  return {
    type: c.LOOKUP_NPI,
    meta: { npi },
    payload: axios.get(`/api/providers/${npi}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function setNPIForSelectedProvider(npi: string) {
  return (
    dispatch: ThunkDispatch<RootState, undefined, c.ContractActionTypes>,
    getState: () => RootState
  ): void => {
    const { selectedProvider, isNPILookupPending, hasUnsavedRosterChanges } = getState().contracts;
    if (isNPILookupPending) return;
    const scrubbedNPIInput = removeNonDigitCharacters(npi);
    const npiError = getNPIError(scrubbedNPIInput);
    dispatch(
      setSelectedProvider({ ...selectedProvider, npi: scrubbedNPIInput, npiError, name: '' })
    );
    if (!hasUnsavedRosterChanges) dispatch(setHasUnsavedRosterChanges(true));
    if (scrubbedNPIInput && !npiError) dispatch(lookupProvider(scrubbedNPIInput));
  };
}

export function setTaxIDForSelectedProvider(taxID: string) {
  return (
    dispatch: ThunkDispatch<RootState, undefined, c.ContractActionTypes>,
    getState: () => RootState
  ): void => {
    const { selectedProvider, hasUnsavedRosterChanges } = getState().contracts;
    const scrubbedTaxIDInput = removeNonDigitCharacters(taxID);
    const taxIDError = getTaxIDError(scrubbedTaxIDInput);
    dispatch(setSelectedProvider({ ...selectedProvider, taxID: scrubbedTaxIDInput, taxIDError }));
    if (!hasUnsavedRosterChanges) dispatch(setHasUnsavedRosterChanges(true));
  };
}

/* File Upload Actions */
export function parseRosterFile(file: File) {
  return (
    dispatch: Dispatch<c.ContractActionTypes | SnackbarAction>,
    getState: () => RootState
  ): void => {
    const {
      contracts: {
        isContractLoading,
        isRosterLoading,
        selectedContract: { id },
      },
    } = getState();

    if (isContractLoading || isRosterLoading) return;

    const data = new FormData();
    data.append('file', file);
    data.append('uploadType', 'roster');

    dispatch({
      type: c.PARSE_ROSTER_FILE,
      meta: {
        selectedContractID: id,
      },
      payload: axios.post(`/api/file-upload`, data, {
        headers: {
          'X-CSRF-Token': localStorage.getItem('csrfToken'),
          'Content-Type': 'multipart/form-data',
        },
      }),
    });
  };
}
