import React, { FormEventHandler, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { TPA } from '../../edi/planEditor/types/plans';
import AutocompleteSelect from '../../shared/components/AutocompleteSelect';
import TPAOption from './TPAOption';

const useStyles = makeStyles({
  input: {
    margin: '0 0.5rem 0.5rem 0',
    width: 300,
  },
});

type Props = {
  allTPAs: Record<number, TPA>;
  className?: string;
  onChange: (sixDegTPAID?: number) => void;
};

function tpasToTPAOptions(allTPAs: Record<number, TPA>): TPA[] {
  const tpaOptions: TPA[] = [];
  for (const key in allTPAs) {
    if (Object(allTPAs).hasOwnProperty(key)) {
      tpaOptions.push(allTPAs[key]);
    }
  }
  return tpaOptions;
}

function searchTPAs(tpas: TPA[], searchText: string): TPA[] {
  return tpas.filter(
    tpas =>
      tpas.tpaName.toLowerCase().includes(searchText.toLowerCase()) ||
      tpas.accountID.toLowerCase().includes(searchText.toLowerCase()) ||
      tpas.sixDegTPAID.toString().includes(searchText)
  );
}

export default function TPASelect({ allTPAs, onChange }: Props): JSX.Element {
  const classes = useStyles();
  const allTPAOptions = tpasToTPAOptions(allTPAs);
  const [input, setInput] = useState({
    value: '',
    isDropdownOpen: false,
    selectedOption: undefined as TPA | undefined,
    matchingOptions: allTPAOptions,
  });

  useEffect(
    () => setInput(state => ({ ...state, matchingOptions: tpasToTPAOptions(allTPAs) })),
    [allTPAs]
  );

  const handleInputValueChange: FormEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;
    setInput(state => ({
      ...state,
      value,
      matchingOptions: searchTPAs(allTPAOptions, value),
    }));
  };

  const handleSelect = (tpa?: TPA): void => {
    setInput({
      value: tpa?.tpaName || '',
      isDropdownOpen: false,
      selectedOption: tpa,
      matchingOptions: tpa ? [tpa] : allTPAOptions,
    });
    onChange(tpa?.sixDegTPAID);
  };

  const handleBlur = (): void =>
    setInput(state => ({
      ...state,
      value: state.selectedOption?.tpaName || '',
      isDropdownOpen: false,
    }));

  const handleClick = (): void => setInput(state => ({ ...state, isDropdownOpen: true }));

  return (
    <AutocompleteSelect
      compact
      className={classes.input}
      inputValue={input.value}
      onSelect={handleSelect}
      onChange={handleInputValueChange}
      onBlur={handleBlur}
      onClick={handleClick}
      label="TPA"
      placeholder="Search by TPA name or account ID"
      isOpen={input.isDropdownOpen}
      options={input.matchingOptions}
      RenderComponent={TPAOption}
    />
  );
}
