import React from 'react';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import { ContractOwnerTypes, UpdateContract } from '../types/contracts';
import { InputChangeHandler } from '../../shared/types/types';
import { capitalizeWords } from '../../shared/globals';

const useStyles = makeStyles({
  ownerType: {
    display: 'flex',
    alignItems: 'baseline',
  },
  ownerTypeLabel: {
    marginRight: '.5em',
  },
});

type Props = {
  isEdit: boolean;
  contractOwnerType: ContractOwnerTypes;
  updateContract: UpdateContract;
};

export default function ContractOwnerType({
  isEdit,
  contractOwnerType,
  updateContract,
}: Props): JSX.Element {
  const classes = useStyles();
  const handleButtonUpdate: InputChangeHandler = e => {
    updateContract('contractOwnerType', e.target.value);
  };

  const label = 'Owner Type';

  if (!isEdit) {
    const text = contractOwnerType ? capitalizeWords(contractOwnerType) : 'Unspecified';
    return (
      <div className={classes.ownerType}>
        <Typography className={classes.ownerTypeLabel} variant="body1">{`${label}:`}</Typography>
        <Typography variant="body2">{text}</Typography>
      </div>
    );
  }

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup row onChange={handleButtonUpdate} value={contractOwnerType}>
        <FormControlLabel
          control={<Radio checked={contractOwnerType === 'internal'} />}
          value="internal"
          label="Internal"
        />
        <FormControlLabel
          control={<Radio checked={contractOwnerType === 'external'} />}
          value="external"
          label="External"
        />
      </RadioGroup>
    </FormControl>
  );
}
