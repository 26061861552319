import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import { updateContract } from '../contractsActions';
import { ContractDetail, UpdateContract } from '../types/contracts';
import { RootState } from '../../shared/types/types';
import ContractNote from './ContractNote';
import ContractLink from './ContractLink';
import ContractDateRange from './ContractDateRange';
import ContractProviderGroup from './ContractProviderGroup';
import ContractType from './ContractType';
import ContractOwnerType from './ContractOwnerType';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    paddingLeft: '0.5rem',
    justifyContent: 'space-between',
  },
  contractInfo: {
    // style all children except first
    '& > *:nth-child(n+2)': {
      marginTop: '0.75rem',
    },
  },
});

type ParentProps = {
  isEdit: boolean;
};
type StateProps = {
  contract: ContractDetail;
};
type DispatchProps = {
  updateContract: UpdateContract;
};
type Props = ParentProps & StateProps & DispatchProps;

export function ContractHeader({ contract, isEdit = false, updateContract }: Props): JSX.Element {
  const classes = useStyles();

  const { startDate, endDate, link, note, providerGroup, contractType, plans, contractOwnerType } =
    contract;
  const hasPlans = plans.length > 0;

  return (
    <div className={classes.header}>
      <div className={classes.contractInfo}>
        <ContractType
          isEdit={isEdit}
          updateContract={updateContract}
          contractType={contractType}
          hasPlans={hasPlans}
        />
        <ContractProviderGroup
          providerGroup={providerGroup}
          isEdit={isEdit}
          updateContract={updateContract}
        />
        <ContractLink link={link} isEdit={isEdit} updateContract={updateContract} />
        <ContractDateRange
          start={startDate}
          end={endDate}
          isEdit={isEdit}
          updateContract={updateContract}
        />
        <ContractOwnerType
          isEdit={isEdit}
          contractOwnerType={contractOwnerType}
          updateContract={updateContract}
        />
      </div>
      <ContractNote note={note} isEdit={isEdit} updateContract={updateContract} />
    </div>
  );
}

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    contract: contracts.selectedContract,
  };
};

const mapDispatchToProps = {
  updateContract,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractHeader);
