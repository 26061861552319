import React, { useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { colors } from '@apps/shared/src/style';
import { RootState } from '../../shared/types/types';
import { addNewPlanAndTPA, removeNewPlanAndTPA, removeSelectedElection } from './plansActions';
import PlanCard from './PlanCard';
import { MasterPlan, PlanElection } from './types/plans';
import SuccessDialog from '../../shared/components/successDialog';

const useStyles = makeStyles({
  newButton: {
    height: '50px',
    marginRight: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    color: colors.white,
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: colors.greenMuted,
    },
  },
});

const mapDispatchToProps = {
  addNewPlanAndTPA,
  removeNewPlanAndTPA,
  removeSelectedElection,
};

type StateProps = {
  masterPlan: MasterPlan;
  allPlanElections: Record<number | string, PlanElection>;
};

const mapStateToProps = ({ plans }: RootState): StateProps => {
  return {
    masterPlan: plans.masterPlans[0],
    allPlanElections: plans.allPlanElections,
  };
};

type Props = StateProps & typeof mapDispatchToProps;

function NewPlan({
  masterPlan,
  allPlanElections,
  removeNewPlanAndTPA,
  removeSelectedElection,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isNewOpen, setIsNewOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleOpen = (): void => {
    removeSelectedElection();
    setIsNewOpen(true);
  };

  const handleClose = (): void => {
    setIsNewOpen(false);
    removeNewPlanAndTPA();
  };

  const handleCloseConfirm = (): void => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Button className={classes.newButton} onClick={handleOpen}>
        New Plan
      </Button>
      <Dialog open={isNewOpen} onClose={handleClose} maxWidth="md">
        <PlanCard
          isNewPlan
          masterPlan={masterPlan}
          setOpenSuccess={setOpenSuccess}
          handleClose={handleClose}
          planElections={allPlanElections}
        />
      </Dialog>
      <SuccessDialog
        open={openSuccess}
        icon="../images/success.png"
        title="Success!"
        message="Plan added successfully."
        buttonText="Close"
        handleSuccess={handleCloseConfirm}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPlan);
