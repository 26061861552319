import React, { useState, MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import { GridColumn } from '../shared/grid';
import { updateRepricing, updateRepricingMethodology } from './claimsActions';
import RepricingMethodologyFormulaButton from './RepricingMethodologyFormulaButton';
import FormulaMenu from './FormulaMenu';
import { planMult, repricingMethodologies } from './repricingMethodologies';
import TextInput from './TextInput';
import { Formula, Repricing, SaveMetadata } from './types/repricingTypes';

type ParentProps = {
  svcRepricing: Repricing;
  saveMetadata: SaveMetadata;
};

const mapDispatchToProps = {
  updateRepricing,
  updateRepricingMethodology,
};

type DispatchProps = {
  updateRepricing: (updateInfo?: SaveMetadata) => void;
  updateRepricingMethodology: (updateInfo?: SaveMetadata) => void;
};

type Props = ParentProps & DispatchProps;

export function getSelectedRepricingMethodology(repricingMethodologyKey?: string): Formula {
  return (
    repricingMethodologies.find(methodology => methodology.key === repricingMethodologyKey) ||
    planMult
  );
}

export function RepricingMethodology({
  svcRepricing,
  saveMetadata,
  updateRepricing,
  updateRepricingMethodology,
}: Props): JSX.Element {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();

  const selectedFormula = getSelectedRepricingMethodology(svcRepricing.repricingMethodology);

  const onMenuOpen: MouseEventHandler<HTMLDivElement> = (event): void => {
    setMenuAnchorEl(event.currentTarget);
  };
  const onMenuClose = (): void => setMenuAnchorEl(undefined);
  const onFormulaChange = (formulaKey: Formula['key']): void => {
    setMenuAnchorEl(undefined);
    updateRepricingMethodology({ ...saveMetadata, repricingMethodology: formulaKey });
  };

  return (
    <>
      <GridColumn>
        <RepricingMethodologyFormulaButton selectedFormula={selectedFormula} onClick={onMenuOpen} />
        <FormulaMenu
          onFormulaChange={onFormulaChange}
          selectedFormula={selectedFormula}
          onClose={onMenuClose}
          anchorEl={menuAnchorEl}
          isOpen={Boolean(menuAnchorEl)}
          formulaList={repricingMethodologies}
        />
      </GridColumn>
      {selectedFormula.fields(svcRepricing).map((field, index) => (
        <GridColumn key={field.fieldName}>
          <TextInput
            id={`s${saveMetadata.serviceIndex}-${field.fieldName}`}
            value={field.value}
            label={field.label}
            labelHover={field.labelFull}
            isPercent={field.isPercent}
            fullWidth
            saveMetadata={{ ...saveMetadata, fieldName: field.fieldName }}
            save={updateRepricing}
          />
        </GridColumn>
      ))}
    </>
  );
}

export default connect(null, mapDispatchToProps)(RepricingMethodology);
