import React from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import { RootState } from '../../shared/types/types';
import StartOrContinue from './StartOrContinue';
import ResolveDuplicates from './ResolveDuplicates';
import { getMonthlyReportingPreflight } from '../reportsActions';

const useStyles = makeStyles({
  container: {
    padding: '2rem',
    boxSizing: 'border-box',
  },
});

const mapDispatchToProps = {
  getMonthlyReportingPreflight,
};

type StateProps = {
  month: string;
  year: number;
  errorMessage: string;
  isReportInProgress: boolean;
  numClaims: number;
  isReportGenerated: boolean;
  isLoading: boolean;
};

const mapStateToProps = ({ reports: { monthlyReporting, loading } }: RootState): StateProps => ({
  month: monthlyReporting?.month || '',
  year: monthlyReporting?.year || 0,
  errorMessage: monthlyReporting?.errorMessage || '',
  isReportInProgress: monthlyReporting?.isReportInProgress || false,
  numClaims: monthlyReporting?.numClaims || 0,
  isReportGenerated: monthlyReporting?.isReportGenerated || false,
  isLoading: loading || false,
});

type Props = StateProps & typeof mapDispatchToProps;

export function MonthlyReporting({
  month,
  year,
  errorMessage,
  isReportInProgress,
  numClaims,
  isReportGenerated,
  isLoading,
  getMonthlyReportingPreflight,
}: Props): JSX.Element {
  const classes = useStyles();

  if (isReportInProgress) {
    return (
      <div className={classes.container}>
        <ResolveDuplicates />
      </div>
    );
  }

  if (isReportGenerated) {
    return (
      <div className={classes.container}>
        <Typography variant="h6" component="h2" color="secondary" align="center">
          Success!
        </Typography>

        <Typography variant="body2" align="center">
          You have successfully completed your <br />
          report for {month} {year} for {numClaims} claims.
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <StartOrContinue
        getMonthlyReportingPreflight={getMonthlyReportingPreflight}
        isLoading={isLoading}
      />
      <Typography variant="body1" color="error">
        {errorMessage}
      </Typography>
      <LoadingSpinner isLoading={isLoading} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyReporting);
