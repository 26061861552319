import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '@apps/shared/src/auth/types';
import SessionTimeout from './SessionTimeout';
import { KeepSessionAlive } from './userActions';

type StateProps = {
  timeout: string;
  isLoggedIn: boolean;
};

const mapStateToProps = ({ user }: RootState): StateProps => ({
  timeout: '840000',
  isLoggedIn: user.isLoggedIn,
});

type Props = StateProps;

// Debounce function to limit the frequency of API calls
function debounce(func: Function, delay: number): (...args: any[]) => void {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export function SessionTimeoutManager({ timeout, isLoggedIn }: Props): JSX.Element {
  const [lastInteraction, setLastInteraction] = useState<number>(Date.now());
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    // Debounce the KeepSessionAlive function
    const debounceKeepSessionAlive = debounce(() => {
      setLastInteraction(Date.now());
      KeepSessionAlive();
      localStorage.setItem('currentSessionTime', Date.now().toString());
    }, 500);
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setLastInteraction(Date.now());
        localStorage.setItem('currentSessionTime', Date.now().toString());
      }
    };

    if (!showPopup && isLoggedIn) {
      window.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('click', debounceKeepSessionAlive);
      window.addEventListener('keypress', debounceKeepSessionAlive);
      window.addEventListener('scroll', debounceKeepSessionAlive, true);
    }

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('click', debounceKeepSessionAlive);
      window.removeEventListener('keypress', debounceKeepSessionAlive);
      window.removeEventListener('scroll', debounceKeepSessionAlive, true);
    };
  }, [showPopup, isLoggedIn]);

  useEffect(() => {
    const shouldRender =
      location.pathname === '/' ||
      location.pathname === '/login' ||
      location.pathname === '/welcome' ||
      location.pathname === '/register';

    if (isLoggedIn && !shouldRender) {
      const intervalId = setInterval(() => {
        const storedTime = localStorage.getItem('currentSessionTime');

        if (storedTime) {
          const storedTimestamp = parseInt(storedTime, 10); // Specify radix parameter
          const storedDate = new Date(storedTimestamp).getTime();
          const now = Date.now();
          const timeSinceLastInteraction = now - storedDate;

          if (timeSinceLastInteraction && timeSinceLastInteraction >= Number(timeout)) {
            setShowPopup(true);
          } else {
            setShowPopup(false);
          }
        }
      }, 1000);

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }

    // Add a default return statement outside the conditional block
    return undefined;
  }, [lastInteraction, timeout, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      setLastInteraction(Date.now());
      localStorage.setItem('currentSessionTime', Date.now().toString());
      setShowPopup(false);
    } else {
      setShowPopup(false);
    }
  }, [isLoggedIn]);

  const stayLoggedIn = () => {
    setLastInteraction(Date.now());
    setShowPopup(false);
    KeepSessionAlive();
  };

  return (
    <>
      {showPopup && (
        <SessionTimeout
          sessionExpired={showPopup}
          stayLoggedIn={stayLoggedIn}
          setShowPopup={setShowPopup}
          setLastInteraction={setLastInteraction}
        />
      )}
    </>
  );
}

export default connect(mapStateToProps)(SessionTimeoutManager);
