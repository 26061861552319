import queryString from 'query-string';

import {
  SFCase,
  SettlementsClaimSummary,
  SettlementMethodology,
  DisplayReportOptions,
  MediVIChartOptions,
  SettlementsQueryValues,
} from '../types/types';

export function createSearchString(
  sfCases: SFCase[],
  claims: SettlementsClaimSummary[],
  settlementMethodology: SettlementMethodology,
  settlementPercent: number,
  { showCostMultiple, showChart, showPlanImpact }: DisplayReportOptions,
  {
    providerLimit,
    selectedRadius,
    showInpatient,
    showOutpatient,
    selectedProvider: { ccn: selectedCCN, name: selectedProviderName },
  }: MediVIChartOptions
): string {
  const savedManualSettlementAmounts: Record<string, number> = {};
  const caseNumber = sfCases.map(c => c.caseNumber);

  claims.forEach(claim => {
    if (claim.proposedSettlementAmount)
      savedManualSettlementAmounts[claim.id] = claim.proposedSettlementAmount;
  });

  const queryObject: SettlementsQueryValues = {
    providerLimit,
    selectedRadius,
    selectedCCN,
    selectedProviderName,
    showInpatient,
    showOutpatient,
    showCostMultiple,
    showChart,
    showPlanImpact,
    settlementPercent,
    settlementMethodology,
    caseNumber,
  };

  const baseQueryValueString = queryString.stringify(queryObject, { skipNull: true });
  const proposedSettlements =
    settlementMethodology === 'manual' ? queryString.stringify(savedManualSettlementAmounts) : '';
  const proposedSettlementsString = proposedSettlements ? `&${proposedSettlements}` : '';

  return `/reports/settlements?${baseQueryValueString}${proposedSettlementsString}`;
}
