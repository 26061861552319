import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import TextInput from '../../../shared/components/TextInput';
import { termItems, rangeTypes } from '../termElements';
import { RangeType, HandleTermValueChange, TermItem } from '../../types/contracts';

const useStyles = makeStyles({
  termInput: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em',
  },
  dollars: {
    width: '6em',
  },
  days: {
    width: '3em',
  },
  connector: {
    width: '2em',
    textAlign: 'center',
  },
  typeInputs: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1em',
    marginLeft: '1em',
  },
  typeInput: {
    marginTop: '-1em',
  },
});

type Props = {
  startRange: string;
  endRange: string;
  rangeType: RangeType;
  handleChange: HandleTermValueChange;
  id: TermItem;
};
export default function RangeInput({
  startRange,
  endRange,
  rangeType,
  handleChange,
  id,
}: Props): JSX.Element {
  const classes = useStyles();
  const isDollars = rangeType === 'dollars';
  const classname = isDollars ? classes.dollars : classes.days;
  const onSelect = (e: React.FormEvent<HTMLInputElement>): void => {
    handleChange({ id: termItems.rangeType, value: e.currentTarget.value });
  };

  return (
    <div className={classes.termInput}>
      <TextInput
        id={termItems.startRange}
        maxLength={isDollars ? 8 : 3}
        value={startRange}
        allowedCharRegex="0-9"
        isMoney={isDollars}
        isInteger={!isDollars}
        onChange={handleChange}
        autoFocus
        className={classname}
      />
      <div className={classes.connector}> - </div>
      <TextInput
        id={termItems.endRange}
        maxLength={isDollars ? 8 : 3}
        value={endRange}
        allowedCharRegex="0-9"
        isMoney={isDollars}
        isInteger={!isDollars}
        onChange={handleChange}
        className={classname}
      />

      <div className={classes.typeInputs}>
        <RadioGroup onChange={onSelect}>
          {rangeTypes.map(o => (
            <FormControlLabel
              value={o.value}
              checked={o.value === rangeType}
              control={<Radio color="primary" />}
              label={o.name}
              key={o.value}
              className={classes.typeInput}
            />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}
