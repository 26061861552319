import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import { Button, FormControl, InputLabel, MenuItem, Select, SelectProps } from '@material-ui/core';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import DateInput from './DateInput';

import { getClaimTrafficReport, getAllPlans, getAllTPAs } from '../reportsActions';
import { ClickHandler, RootState } from '../../shared/types/types';
import { Plan, TPA } from '../types';
import { buildFilename, determineEndDateErrorText, determineStartDateErrorText } from './utils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  input: { margin: '1rem', width: '10rem' },
  wideInput: { margin: '1rem', width: 'fit-content', minWidth: '10rem' },
  buttonContainer: { display: 'flex' },
  loadingSpinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '1rem',
  },
});

const mapDispatchToProps = {
  getClaimTrafficReport,
  getAllPlans,
  getAllTPAs,
};
type DispatchProps = {
  getClaimTrafficReport: (
    reportType: string,
    id: number,
    startDate: string,
    endDate: string,
    filename: string
  ) => void;
  getAllPlans: VoidFunction;
  getAllTPAs: VoidFunction;
};

const mapStateToProps = ({ reports }: RootState): StateProps => {
  return {
    isDownloading: reports.isDownloading,
    plans: reports.plans,
    tpas: reports.tpas,
  };
};
type StateProps = {
  isDownloading: boolean;
  plans: Plan[];
  tpas: TPA[];
};

type Props = DispatchProps & StateProps;

export function ClaimTraffic({
  getClaimTrafficReport,
  getAllPlans,
  getAllTPAs,
  isDownloading,
  plans,
  tpas,
}: Props): JSX.Element {
  const classes = useStyles();

  const [reportType, setReportType] = useState('plan');
  const [sixDegID, setSixDegID] = useState(0);
  const [startDateISO, setStartDateISO] = useState('');
  const [endDateISO, setEndDateISO] = useState('');
  const [startDateErrorText, setStartDateErrorText] = useState('');
  const [endDateErrorText, setEndDateErrorText] = useState('');

  useEffect(() => {
    getAllPlans();
  }, [getAllPlans]);

  useEffect(() => {
    getAllTPAs();
  }, [getAllTPAs]);

  useEffect(() => {
    setStartDateErrorText(determineStartDateErrorText(startDateISO, endDateISO));
    setEndDateErrorText(determineEndDateErrorText(startDateISO, endDateISO));
  }, [startDateISO, endDateISO, setStartDateErrorText, setEndDateErrorText]);

  const handleSelectReport: SelectProps['onChange'] = e => {
    setSixDegID(0);
    setReportType(String(e.target.value));
  };

  const handleEnterID: SelectProps['onChange'] = e => {
    setSixDegID(Number(e.target.value));
  };

  const handleEnterStartDate: ChangeEventHandler<HTMLInputElement> = e => {
    setStartDateISO(e.currentTarget.value);
  };

  const handleEnterEndDate: ChangeEventHandler<HTMLInputElement> = e => {
    setEndDateISO(e.currentTarget.value);
  };

  const handleSubmit: ClickHandler = () => {
    let name = '';
    if (isPlan) {
      const plan = plans.find(p => p.sixDegPlanID === sixDegID);
      if (plan !== undefined) {
        name = plan.planName;
      }
    } else {
      const tpa = tpas.find(t => t.sixDegTPAID === sixDegID);
      if (tpa !== undefined) {
        name = tpa.tpaName;
      }
    }
    getClaimTrafficReport(
      reportType,
      sixDegID,
      startDateISO,
      endDateISO,
      buildFilename(name, startDateISO, endDateISO)
    );
  };

  const isPlan = reportType === 'plan';
  const isStartDateError = startDateErrorText !== '';
  const isEndDateError = endDateErrorText !== '';

  return (
    <div className={classes.container}>
      <div className={classes.input}>
        <FormControl fullWidth>
          <InputLabel id="claim-traffic-type">Select report type</InputLabel>
          <Select
            labelId="claim-traffic-type"
            id="claim-traffic-type"
            value={reportType}
            label="Select Report Type"
            onChange={handleSelectReport}
          >
            <MenuItem value="plan">Plan</MenuItem>
            <MenuItem value="tpa">TPA</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className={classes.wideInput}>
        <FormControl fullWidth>
          <InputLabel id={isPlan ? 'select-plan' : 'select-tpa'}>
            Select {isPlan ? 'Plan' : 'TPA'}
          </InputLabel>
          <Select
            labelId={isPlan ? 'select-plan' : 'select-tpa'}
            value={sixDegID === 0 ? '' : sixDegID}
            onChange={handleEnterID}
          >
            {isPlan
              ? plans.map(p => (
                  <MenuItem
                    key={`${p.sixDegPlanID}`}
                    value={p.sixDegPlanID}
                  >{`${p.planName}`}</MenuItem>
                ))
              : tpas.map(t => (
                  <MenuItem value={t.sixDegTPAID} key={t.sixDegTPAID}>
                    {t.tpaName}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>

      <DateInput
        type="start"
        dateISO={startDateISO}
        errorText={startDateErrorText}
        handleChange={handleEnterStartDate}
      />
      <DateInput
        type="end"
        dateISO={endDateISO}
        errorText={endDateErrorText}
        handleChange={handleEnterEndDate}
      />

      <div className={classes.input}>
        <div className={classes.buttonContainer}>
          <Button
            onClick={handleSubmit}
            disabled={sixDegID === 0 || isDownloading || isStartDateError || isEndDateError}
            variant="contained"
            color="secondary"
          >
            Get Report
          </Button>
          {isDownloading && (
            <LoadingSpinner
              isLoading={isDownloading}
              unstyledContainer
              containerClassName={classes.loadingSpinner}
              size="2rem"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTraffic);
