import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '@apps/shared/src/style';
import BreakoutButton from './BreakoutButton';
import { dailyClaimsRow } from '../../shared/types/propTypes';

const useStyles = makeStyles({
  row: {
    '& td': {
      width: '50px',
      padding: '5px',
    },
  },
  date: {
    width: '75px !important',
  },
  handler: {
    width: '150px !important',
  },
  even: {
    background: colors.grey12,
  },
  border: {
    borderRight: '3px solid grey',
  },
});

export default function DailyClaimsRow({
  row,
  handler,
  date,
  index,
  isBreakout,
  isIndividual,
  toggleIsBreakout,
}) {
  const classes = useStyles();
  if (!row) return null;

  let professionalTotal, outpatientTotal, inpatientTotal, allTotal, displayDate;
  if (handler === 'All') {
    displayDate = date;
    professionalTotal = row.in.p1 + row.in.p2 + row.in.p3;
    outpatientTotal = row.in.o1 + row.in.o2 + row.in.o3 + row.in.o4;
    inpatientTotal = row.in.i1;
    allTotal =
      row.in.p1 + row.in.p2 + row.in.p3 + row.in.o1 + row.in.o2 + row.in.o3 + row.in.o4 + row.in.i1;
  }

  const total =
    row.out.p1 +
    row.out.p2 +
    row.out.p3 +
    row.out.o1 +
    row.out.o2 +
    row.out.o3 +
    row.out.o4 +
    row.out.i1;
  const score =
    row.out.p1 +
    2 * (row.out.p2 + row.out.p3 + row.out.o1) +
    3 * (row.out.o2 + row.out.o3 + row.out.o4 + row.out.i1);

  const isRowEven = (index + 1) % 2 === 0;
  return (
    <tr className={classNames(classes.row, isRowEven && classes.even)}>
      {isIndividual ? (
        <td className={classes.date}>{date}</td>
      ) : (
        <>
          <BreakoutButton toggleIsBreakout={toggleIsBreakout} isBreakout={isBreakout} />
          <td className={classes.date}>{displayDate}</td>
          <td className={classes.handler}>{handler}</td>
          <td>{professionalTotal}</td>
          <td>{outpatientTotal}</td>
          <td>{inpatientTotal}</td>
          <td className={classes.border}>{allTotal}</td>
        </>
      )}
      <td>{row.in.dataEntry}</td>
      <td className={classes.border}>{row.in.entryValidate}</td>
      <td>{row.out.p1}</td>
      <td>{row.out.p2}</td>
      <td>{row.out.p3}</td>
      <td>{row.out.o1}</td>
      <td>{row.out.o2}</td>
      <td>{row.out.o3}</td>
      <td>{row.out.o4}</td>
      <td>{row.out.i1}</td>
      <td>{total}</td>
      <td>{score}</td>
      <td>{row.out.repriceServices}</td>
      <td>{row.out.totalServices}</td>
      <td>{row.out.verify}</td>
      <td>{row.out.miscalc}</td>
    </tr>
  );
}

DailyClaimsRow.defaultProps = {
  row: undefined,
  toggleIsBreakout: undefined,
};

DailyClaimsRow.propTypes = {
  row: dailyClaimsRow,
  handler: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isBreakout: PropTypes.bool.isRequired,
  isIndividual: PropTypes.bool.isRequired,
  toggleIsBreakout: PropTypes.func,
};
