import React, { useState, ReactNode } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Slide from '@material-ui/core/Slide';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
  sidebarToggle: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    zIndex: 100,
    position: 'absolute',
    top: '10px',
    borderRadius: '0 20% 20% 0',
  },
}));

type Props = {
  children?: ReactNode;
};
export default function Sidebar({ children }: Props): JSX.Element | null {
  const classes = useStyles();
  const [sidebarOpen, setSideBarOpen] = useState(true);

  if (!children) return null;

  const toggleSidebar: VoidFunction = () => setSideBarOpen(!sidebarOpen);
  const sidebarIcon = sidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />;

  return (
    <>
      <ButtonBase
        color="primary"
        className={classes.sidebarToggle}
        onClick={toggleSidebar}
        aria-label="toggle sidebar"
      >
        {sidebarIcon}
      </ButtonBase>
      <Slide direction="right" in={sidebarOpen} mountOnEnter unmountOnExit>
        <aside>{children}</aside>
      </Slide>
    </>
  );
}
