import { Formula, Repricing } from './types/repricingTypes';

export const planMult: Formula = {
  name: 'Plan Multiplier',
  key: 'planMult',
  formula: 'Ref × Plan Multiplier',
  fields(repricing?: Repricing) {
    return [
      {
        label: 'Plan Mult',
        value: repricing?.planMult,
        fieldName: 'repricing.planMult',
      },
    ];
  },
};

const contractFlatRate: Formula = {
  name: 'Contracted Flat Rate',
  key: 'contractFlatRate',
  formula: 'Rate',
  fields(repricing?: Repricing) {
    return [
      {
        label: 'Rate',
        value: repricing?.contractFlatRate,
        fieldName: 'repricing.contractFlatRate',
      },
    ];
  },
};

export const contractPctBill: Formula = {
  name: 'Contracted % of Billed',
  key: 'contractPctBill',
  formula: 'Amount × Pct Billed',
  fields(repricing?: Repricing) {
    return [
      {
        label: 'Pct Billed',
        value: repricing?.contractPctBill,
        fieldName: 'repricing.contractPctBill',
        isPercent: true,
      },
    ];
  },
};

const contractPctCMS: Formula = {
  name: 'Contracted % of CMS',
  key: 'contractPctCMS',
  formula: 'Ref × Pct CMS',
  fields(repricing?: Repricing) {
    return [
      {
        label: 'Pct CMS',
        value: repricing?.contractPctCMS,
        fieldName: 'repricing.contractPctCMS',
        isPercent: true,
      },
    ];
  },
};

export const repricingMethodologies: Formula[] = [
  planMult,
  contractFlatRate,
  contractPctBill,
  contractPctCMS,
];
