import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinkIcon from '@material-ui/icons/Link';

import ExclusiveButtons, { Option } from '@apps/shared/src/components/ExclusiveButtons';

import { colors } from '@apps/shared/src/style';
import { formatCurrency } from '@apps/shared/src/utilities';
import { formatDate } from '../../shared/globals';
import { MonthlyReportingDuplicateClaim } from '../types';

import { resolveMonthlyReportingDuplicate } from '../reportsActions';
import { RootState } from '../../shared/types/types';

const useStyles = makeStyles({
  keepRow: {
    backgroundColor: colors.beige,
  },
  removeRow: {
    backgroundColor: colors.grey12,
    '& > *': {
      color: colors.greyText,
    },
  },
  row: {
    boxShadow: 'none',
  },
  link: {
    color: colors.orange,
    '&:hover': {
      color: colors.orangeDark,
    },
    '&:visited': {
      color: colors.redScarlet,
    },
  },
  keepOrRemoveButtons: {
    minWidth: 'max-content',
  },
});

const keepOrRemoveDuplicateOptions: Option[] = [
  { value: 'true', content: 'Keep' },
  { value: 'false', content: 'Remove' },
];

const mapDispatchToProps = {
  resolveMonthlyReportingDuplicate,
};

type StateProps = {
  isReportGenerating: boolean;
};

const mapStateToProps = ({ reports }: RootState): StateProps => ({
  isReportGenerating: reports.loading || false,
});

type ParentProps = {
  rowData: MonthlyReportingDuplicateClaim;
  groupIndex: number;
  claimIndex: number;
};

type Props = ParentProps & StateProps & typeof mapDispatchToProps;

export function DuplicatesTableRow({
  isReportGenerating,
  rowData,
  groupIndex,
  claimIndex,
  resolveMonthlyReportingDuplicate,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    claimId,
    billingProvider,
    patientName,
    dateOfService,
    diagnosisCode,
    billedAmount,
    repriceTotal,
    keepDuplicate,
    isResolving,
  } = rowData;

  const onKeepOrRemoveDuplicate = (value: string): void => {
    if (value === 'true') {
      resolveMonthlyReportingDuplicate(claimId, true, groupIndex, claimIndex);
    } else if (value === 'false') {
      resolveMonthlyReportingDuplicate(claimId, false, groupIndex, claimIndex);
    }
  };

  const getTableRowClassName = (): string => {
    switch (keepDuplicate) {
      case true:
        return classes.keepRow;
      case false:
        return classes.removeRow;
      default:
        return '';
    }
  };

  return (
    <TableRow className={getTableRowClassName()}>
      <TableCell size="small" align="center">
        <Link
          title={claimId}
          className={classes.link}
          to={`/repricing/${claimId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkIcon fontSize="small" />
        </Link>
      </TableCell>
      <TableCell size="small" align="center">
        {billingProvider}
      </TableCell>
      <TableCell size="small" align="center">
        {patientName}
      </TableCell>
      <TableCell size="small" align="center">
        {formatDate(dateOfService)}
      </TableCell>
      <TableCell size="small" align="center">
        {diagnosisCode}
      </TableCell>
      <TableCell size="small" align="center">
        {formatCurrency(billedAmount)}
      </TableCell>
      <TableCell size="small" align="center">
        {formatCurrency(repriceTotal)}
      </TableCell>
      <TableCell size="small" align="center">
        <div className={classes.keepOrRemoveButtons}>
          <ExclusiveButtons
            options={keepOrRemoveDuplicateOptions}
            selectedValue={keepDuplicate?.toString()}
            onChange={onKeepOrRemoveDuplicate}
            color="secondary"
            isDisabled={isResolving || isReportGenerating}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicatesTableRow);
