import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { ContractTypes, ContractTypesDisplay, UpdateContract } from '../types/contracts';
import { InputChangeHandler } from '../../shared/types/types';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

type Props = {
  isEdit: boolean;
  hasPlans: boolean;
  contractType: ContractTypes;
  updateContract: UpdateContract;
};
export default function ContractType({
  isEdit,
  hasPlans,
  contractType,
  updateContract,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const displayContractType = ContractTypesDisplay[contractType];

  const isOptIn = contractType === ContractTypes.DirectOptIn;
  const showCheckBox = isEdit && (contractType === ContractTypes.Direct || isOptIn);

  const handleOptInToggle: InputChangeHandler = e => {
    if (e.target.checked) updateContract('contractType', ContractTypes.DirectOptIn);
    else if (hasPlans) handleOpenModal();
    else updateContract('contractType', ContractTypes.Direct);
  };

  const handleTypeChangeToDirect: VoidFunction = () => {
    updateContract('contractType', ContractTypes.Direct);
    handleCloseModal();
  };

  const handleCloseModal: VoidFunction = () => setIsModalOpen(false);
  const handleOpenModal: VoidFunction = () => setIsModalOpen(true);

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h6">{displayContractType}</Typography>
        {showCheckBox && (
          <FormControlLabel
            control={<Checkbox checked={isOptIn} onChange={handleOptInToggle} color="secondary" />}
            label="Opt-In"
          />
        )}
      </div>

      <Dialog open={isModalOpen} onClose={handleCloseModal} aria-describedby="change-type-alert">
        <DialogContent>
          <DialogContentText id="change-type-alert">
            This change will remove associated plans from this contract. Are you sure you want to
            continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            CANCEL
          </Button>
          <Button onClick={handleTypeChangeToDirect} color="primary" autoFocus>
            REMOVE PLANS
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
