import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Exception } from '../types';
import ExceptionsTableRow from './ExceptionsTableRow';

type Column = {
  dataKey: keyof Exception;
  label: string;
};

const columns: Column[] = [
  {
    dataKey: 'name',
    label: 'Name',
  },
  {
    dataKey: 'sixDegTPAID',
    label: 'SixDegTPAID',
  },
];

type Props = {
  exceptions: Exception[];
};

export default function ExceptionsTable({ exceptions }: Props): JSX.Element {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table stickyHeader aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell scope="row" key={column.dataKey}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {exceptions.map(exception => (
            <ExceptionsTableRow key={exception.name} exception={exception} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
