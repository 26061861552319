/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import ContractListItem from './ContractListItem';
import NoResults from '../../shared/components/NoResults';

import { ContractSummary, HandleListClick } from '../types/contracts';
import { RootState } from '../../shared/types/types';

const useStyles = makeStyles({
  list: {
    overflowY: 'scroll',
    width: '100%',
  },
});

type ParentProps = {
  handleListClick: HandleListClick;
};
type StateProps = {
  contracts: ContractSummary[];
  selectedID: number;
  loadingID?: number;
  userID: string;
};
type Props = ParentProps & StateProps;

export function ContractDisplayListItems({
  contracts,
  selectedID,
  loadingID,
  userID,
  handleListClick,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <NoResults results={contracts} loadingStatus={false} description="contracts" />
      <div className={classes.list}>
        {contracts.map(c => {
          const isSelected = loadingID !== undefined ? c.id === loadingID : c.id === selectedID;
          const hideContract = c.status === 'DataEntry' && c.ownerID !== userID && !isSelected;
          return hideContract ? null : (
            <ContractListItem
              key={c.id}
              contract={c}
              handleListClick={handleListClick}
              isSelected={isSelected}
            />
          );
        })}
      </div>
    </>
  );
}

const mapStateToProps = ({ contracts, user }: RootState): StateProps => {
  return {
    contracts: contracts.filteredContracts,
    selectedID: contracts.selectedContract.id,
    loadingID: contracts.contractBeingLoaded,
    userID: user.currentUser?.userID || '',
  };
};

export default connect(mapStateToProps)(ContractDisplayListItems);
