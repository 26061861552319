import React from 'react';

import Handlers from '../../shared/components/handlers/Handlers';
import ClaimFooterItem from '../../repricing/ClaimFooterItem';

type Props = {
  dataEnterer: string[] | string | undefined;
};
export default function ContractFooterHandlers({ dataEnterer }: Props): JSX.Element | null {
  if (!dataEnterer) return null;
  return (
    <>
      <ClaimFooterItem>
        <Handlers handlers={{ dataEnterer }} />
      </ClaimFooterItem>
    </>
  );
}
