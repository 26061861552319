import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { colors } from '@apps/shared/src/style';
import { formatCurrency } from '@apps/shared/src/utilities';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import { MonthlyReportingDuplicateClaim } from '../types';
import DuplicatesTable from './DuplicatesTable';
import ConfirmDialog from '../../repricing/ConfirmDialog';

import { generateMonthlyReport } from '../reportsActions';
import { addSnackbar as addMessage } from '../../shared/components/snackbar/snackbarReducer';
import { RootState } from '../../shared/types/types';

const useStyles = makeStyles({
  container: {
    height: '100%',
    padding: '1rem',
    boxSizing: 'border-box',
    backgroundColor: colors.grey05,
    borderRadius: '1rem',
  },
  header: {
    paddingBottom: '0.75rem',
  },
  divider: {
    margin: '0 -1rem',
    backgroundColor: colors.orange,
  },
  subtitle: {
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 'fit-content',
    marginTop: '1rem',
  },
  loadingSpinnerContainer: {
    height: '1rem',
    alignItems: 'center',
    margin: '0 1rem',
  },
});

type StateProps = {
  month: string;
  year: number;
  duplicatesMinBilledAmount: number;
  duplicateGroups: MonthlyReportingDuplicateClaim[][];
  errorMessage: string;
  isReportGenerating: boolean;
};

const mapStateToProps = ({ reports: { monthlyReporting, loading } }: RootState): StateProps => ({
  month: monthlyReporting?.month || '',
  year: monthlyReporting?.year || 0,
  duplicatesMinBilledAmount: monthlyReporting?.duplicatesMinBilledAmount || 0,
  duplicateGroups: monthlyReporting?.duplicateGroups || [],
  errorMessage: monthlyReporting?.errorMessage || '',
  isReportGenerating: loading || false,
});

const mapDispatchToProps = {
  generateMonthlyReport,
  addMessage,
};

type Props = StateProps & typeof mapDispatchToProps;

function getResolvedDuplicatesCount(duplicateGroups: MonthlyReportingDuplicateClaim[][]): {
  resolvedDuplicatesCount: number;
  duplicatesCount: number;
} {
  let resolvedDuplicatesCount = 0;
  let duplicatesCount = 0;

  for (const group of duplicateGroups) {
    resolvedDuplicatesCount += group.filter(claim => claim.keepDuplicate != null).length;
    duplicatesCount += group.length;
  }

  return { resolvedDuplicatesCount, duplicatesCount };
}

export function ResolveDuplicates({
  month,
  year,
  duplicatesMinBilledAmount,
  duplicateGroups,
  errorMessage,
  isReportGenerating,
  generateMonthlyReport,
  addMessage,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const hasUnresolvedDuplicates = duplicateGroups.some(group =>
    group.some(claim => claim.keepDuplicate == null)
  );

  useEffect(() => {
    if (errorMessage) addMessage(errorMessage);
  }, [errorMessage, addMessage]);

  const toggleDialog = (): void => setIsDialogOpen(!isDialogOpen);

  const confirmDialog = (): void => {
    setIsDialogOpen(false);
    generateMonthlyReport();
  };

  const { resolvedDuplicatesCount, duplicatesCount } = getResolvedDuplicatesCount(duplicateGroups);

  return (
    <div className={classes.container}>
      <Typography variant="h6" component="h2" className={classes.header}>
        Monthly Reporting for {month} {year}
      </Typography>

      <Divider className={classes.divider} />

      <div className={classes.subtitle}>
        <Typography variant="subtitle1" color="secondary">
          Duplicate Claims &gt; {formatCurrency(duplicatesMinBilledAmount)}
        </Typography>
        {duplicateGroups.length > 0 && (
          <Typography variant="subtitle1">
            {resolvedDuplicatesCount}/{duplicatesCount} potential duplicates resolved
          </Typography>
        )}
      </div>

      {duplicateGroups.length === 0 && (
        <Typography variant="body1" align="center">
          It looks like you have no duplicate claims to review. Please click &quot;Generate
          Report&quot; to continue.
        </Typography>
      )}

      {duplicateGroups.length > 0 && <DuplicatesTable duplicateGroups={duplicateGroups} />}

      <div className={classes.footer}>
        <LoadingSpinner
          isLoading={isReportGenerating}
          containerClassName={classes.loadingSpinnerContainer}
        />

        <Button
          variant="contained"
          color="secondary"
          disabled={hasUnresolvedDuplicates || isReportGenerating}
          onClick={toggleDialog}
        >
          Generate Report
        </Button>
      </div>

      <ConfirmDialog
        isOpen={isDialogOpen}
        onCancel={toggleDialog}
        onConfirm={confirmDialog}
        label={`Finalize Monthly Report for ${month} ${year}?`}
        contentText="You will not be able to come back to this screen and make any changes to the duplicates once this report is submitted"
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolveDuplicates);
