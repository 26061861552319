import { colors, hexToRgba } from '@apps/shared/src/style';

export default function getFlagRowBGColor({
  isEven,
  color,
  isHighlighted,
}: {
  isEven: boolean;
  color: string;
  isHighlighted?: boolean;
}): string {
  const newColor = isHighlighted ? colors.yellowBright : color;
  let opacity = 0.2;
  if (isEven) opacity *= 2;
  if (isHighlighted) opacity *= 2;
  return hexToRgba(newColor, opacity);
}
