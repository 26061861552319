import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  noResults: {
    fontSize: '16px',
    textAlign: 'center',
    padding: '5px',
    fontWeight: 'bold',
  },
});

export default function NoResults(props) {
  const { results, loadingStatus, description } = props;
  const classes = useStyles();

  if ((Array.isArray(results) && results.length > 0) || loadingStatus) return null;
  return <Typography className={classes.noResults}>{`No ${description} found`}</Typography>;
}

NoResults.defaultProps = {
  description: 'results',
};

NoResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingStatus: PropTypes.bool.isRequired,
  description: PropTypes.string,
};
