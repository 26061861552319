import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, styled } from '@material-ui/core/styles';
import classNames from 'classnames';

import { colors } from '@apps/shared/src/style';

// all in % of serviceItem width except fieldWidth
// revCode
const revCodeWidth = 4;
// date
const dtWidth = 5;
// procedure
const procWidth = 7;
// quantity
const qtyWidth = 3;
// diag. pntr
const ptrWidth = 4;
// reference
const amtWidth = 7;
// ref adj
const refAdj = 4;
// repricing meth...
const repricingMethodologyWidth = 5;
// facility
const facWidth = 3;
// adjudication -- almost never used
const adjudicationWidth = 2;
// ??
const catWidth = 40;
// width in pixels of each formula field
const fieldWidth = 70;
// ??
const determinationWidth = 15;
// modification buttons
const formulaButtonWidth = 3;
// note button
const noteButtonWidth = 10;
// ext note input -- these last three are on the lower level -- both levels should be 100ish (theres padding and margins in there too)
const noteFieldWidth = 28;
// ext note type selection
const noteSelectWidth = 20;
// internal note input
const shareAmtWidth = 38;

export const columns = (...cols) => cols.map(a => (typeof a === 'number' ? `${a}%` : a)).join` `;
export const repeat = (count, size) =>
  `repeat(${count}, ${typeof size === 'number' ? `${size}px` : size})`;

const columnGap = 0;
const formulaFields = 7;
export const repricingColumns = columns(
  formulaButtonWidth,
  '[formula-start effective-multiple-start]',
  repeat(formulaFields, fieldWidth),
  '[formula-end reference-start reference-adj-start]',
  refAdj,
  '[reference-adj-end effective-multiple-end reference-amt-start]',
  amtWidth,
  '[reference-amt-end reference-end]',
  formulaButtonWidth,
  '[repricing-methodology-start]',
  repricingMethodologyWidth,
  '[repricing-methodology-end total-start]',
  amtWidth,
  '[total-end]',
  noteButtonWidth
);
export const instColumns = columns(revCodeWidth, procWidth, qtyWidth, amtWidth);
export const profColumns = columns(dtWidth, procWidth, ptrWidth, qtyWidth, dtWidth, facWidth);
export const adjudColumns = columns(
  '[adjud-start]',
  catWidth,
  determinationWidth,
  shareAmtWidth,
  noteButtonWidth
);
export const notesColumns = columns(
  '[internal]',
  'auto',
  columnGap,
  '[external-start]',
  noteSelectWidth,
  noteFieldWidth,
  '[external-end]'
);
export const adjudColumn = columns(adjudicationWidth);

const gridStyles = theme => ({
  root: {
    boxSizing: 'border-box',
    display: 'grid',
    gridRowGap: `${theme.spacing(1)}px`,
    gridColumnGap: `${theme.spacing(1)}px`,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
});
const useGridStyles = makeStyles(gridStyles);

const gridRowStyles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${colors.grey17}`,
    width: '100%',
    minWidth: 'fit-content',
    '&:hover, &:active': {
      backgroundColor: colors.grey05,
    },
  },
  header: {
    borderBottom: `1px solid ${colors.grey37}`,
    backgroundColor: colors.grey05,
    width: '100%',
    minWidth: 'fit-content',
    fontWeight: 'bold',
    '&:hover, &:active': {
      backgroundColor: colors.grey05,
    },
  },
});
const useGridRowStyles = makeStyles(gridRowStyles);

const gridColumnStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
  },
});
const useGridColumnStyles = makeStyles(gridColumnStyles);

const flexRowStyles = ({ theme }) => ({
  display: 'flex',
  padding: `0 ${theme.spacing(0.5)}px`,
});

const flexColumnStyles = ({ theme }) => ({
  flex: 1,
  padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
});

const columnStyles = ({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
});

export function Grid(props) {
  const classes = useGridStyles(props);
  const { columns, component, className, style, ...otherProps } = props;
  const GridComponent = component;
  return (
    <GridComponent
      className={classNames(classes.root, className)}
      style={{ gridTemplateColumns: columns, ...style }}
      {...otherProps}
    />
  );
}

Grid.defaultProps = {
  className: '',
  style: {},
};
Grid.propTypes = {
  columns: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

export function GridRow(props) {
  const classes = useGridRowStyles(props);
  const { header, className, ...otherProps } = props;

  return (
    <div
      className={classNames(classes.root, { [classes.header]: header }, className)}
      {...otherProps}
    />
  );
}

GridRow.defaultProps = {
  header: false,
  className: '',
};
GridRow.propTypes = {
  header: PropTypes.bool,
  className: PropTypes.string,
};

export function GridColumn(props) {
  const classes = useGridColumnStyles(props);
  const { center, className, children, ...otherProps } = props;
  return (
    <div
      className={classNames(classes.root, { [classes.center]: center }, className)}
      {...otherProps}
    >
      {children}
    </div>
  );
}

GridColumn.defaultProps = {
  center: false,
  className: '',
  children: null,
};
GridColumn.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export const FlexRow = styled('div')(flexRowStyles);
export const FlexColumn = styled('div')(flexColumnStyles);
export const Column = styled('div')(columnStyles);
