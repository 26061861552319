import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List as VirtualList, AutoSizer } from 'react-virtualized';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { colors, claimColors } from '@apps/shared/src/style';
import { toISODateString } from '@apps/shared/src/utilities';

import { addSnackbar as addMessage } from '../../shared/components/snackbar/snackbarReducer';
import NoResults from '../../shared/components/NoResults';
import { getFiles, getFilesV2, deleteFile } from '../reportsActions';
import styledAvatar from '../../shared/components/styledAvatar';
import BatchSearch from './BatchSearch';
import * as types from '../../shared/types/propTypes';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '300px',
    minWidth: '300px',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  numResults: {
    margin: '0.5rem 0 0 1rem',
    fontSize: 'small',
  },
  listContainer: {
    flex: '1 1 auto',
  },
  link: {
    textDecoration: 'none',
    padding: '8px',
    color: colors.black,
    display: 'grid',
    gridTemplateColumns: 'max-content minmax(30px, max-content) auto',
    gridColumnGap: '10px',
  },
  selectedLink: {
    backgroundColor: colors.grey12,
  },
  primaryInfo: {
    display: 'flex',
  },
  secondaryInfo: {
    fontSize: '13px',
    color: colors.greyDark,
    display: 'flex',
  },
  flexRight: {
    textAlign: 'right',
    flexGrow: '1',
  },
  error: {
    color: colors.red,
  },
  warning: {
    color: colors.orange,
  },
};

const avatarIn = styledAvatar(23, claimColors.batchIn);
const avatarOut = styledAvatar(23, claimColors.batchOut);

class BatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: '',
      checkedItems: {},
      selectAll: false,
    };
  }

  componentDidMount() {
    if (this.props.showBatchesOnLoad) this.props.getFiles();
  }

  setSearchFilter = newFilter => {
    this.setState({ searchFilter: newFilter });
  };

  handleCheckboxChange = id => {
    this.setState(prevState => ({
      checkedItems: {
        ...prevState.checkedItems,
        [id]: !prevState.checkedItems[id],
      },
      selectAll: false,
    }));
  };

  handleSelectAll = () => {
    const { files } = this.props;
    const { selectAll } = this.state;
    const newCheckedItems = {};
    if (!selectAll) {
      files.forEach(file => {
        newCheckedItems[file.id] = true;
      });
    }
    this.setState({
      checkedItems: newCheckedItems,
      selectAll: !selectAll,
    });
  };

  handleDelete = () => {
    const { checkedItems, searchFilter } = this.state;
    const fileIdsToDelete = Object.keys(checkedItems).filter(id => checkedItems[id]);

    fileIdsToDelete.forEach(fileId => {
      this.props.deleteFile(fileId);
    });

    this.props.addMessage('Files deleted successfully');
    this.setState({ checkedItems: {}, selectAll: false });

    const filter = { ...searchFilter };
    if (filter.startDate) filter.startDate = toISODateString(`${filter.startDate} 00:00:00`);
    if (filter.endDate) filter.endDate = toISODateString(`${filter.endDate} 23:59:59`);
    this.props.getFilesV2(filter);

    // Redirect to a new URL
    this.props.history.push('/reports/batches');
  };

  renderRow = ({ index, key, style }) => {
    const { files, batchId, classes, baseURL, roles } = this.props;
    const { checkedItems } = this.state;
    const file = files[index];
    const Avatar = file.direction === 'out' ? avatarOut : avatarIn;
    const ext = file.filename && file.filename.substring(file.filename.lastIndexOf('.') + 1);
    const isEDIAdmin = (roles || []).includes('claims-edi-admin');

    return (
      <div style={{ ...style }} key={key} className={classes.flexContainer}>
        <div style={{ marginBottom: '.5rem' }}>
          {isEDIAdmin && this.state.searchFilter.eligibilityFiles === true && (
            <input
              type="checkbox"
              checked={checkedItems[file.id] || false}
              onChange={() => this.handleCheckboxChange(file.id)}
              className={classes.checkBox}
            />
          )}
        </div>

        <Link
          to={`${baseURL}${file.id}`}
          className={classNames({
            [classes.link]: true,
            [classes.selectedLink]: batchId === file.id,
          })}
        >
          <Avatar>{file.direction}</Avatar>
          <div>{ext}</div>
          <div>
            <div className={classes.primaryInfo}>
              <div>
                <strong>{file.clientName}</strong>
              </div>
              {file.error && (
                <div className={classNames(classes.flexRight, classes.error)}>ERROR</div>
              )}
              {file.warning && !file.error && (
                <div className={classNames(classes.flexRight, classes.warning)}>WARNING</div>
              )}
            </div>
            <div className={classes.secondaryInfo}>
              <div>{file.date}</div>
              <div className={classes.flexRight}>{file.plan}</div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  render() {
    const {
      files,
      getFiles,
      classes,
      loading,
      displaySearchBar,
      displayNumResults,
      getFilesV2,
      roles,
    } = this.props;
    const { searchFilter, checkedItems, selectAll } = this.state;

    return (
      <div>
        <div className={classes.container}>
          {displaySearchBar && (
            <BatchSearch
              getFiles={getFiles}
              getFilesV2={getFilesV2}
              roles={roles}
              searchFilter={searchFilter}
              setSearchFilter={this.setSearchFilter}
              checkedItems={checkedItems}
              handleSelectAll={this.handleSelectAll}
              handleDelete={this.handleDelete}
              selectAll={selectAll}
            />
          )}
          <LoadingSpinner isLoading={loading} />
          {displayNumResults ? (
            <div className={classes.numResults}>{files.length} results</div>
          ) : (
            <NoResults results={files} description="batches" loadingStatus={loading} />
          )}
          <div className={classes.listContainer}>
            <AutoSizer>
              {({ width, height }) => (
                <VirtualList
                  id="batch-list"
                  style={{ outline: 'none' }}
                  width={width}
                  height={height}
                  rowHeight={50}
                  rowRenderer={this.renderRow}
                  rowCount={files.length}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    );
  }
}

BatchList.propTypes = {
  files: PropTypes.arrayOf(types.file).isRequired,
  batchId: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  baseURL: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  getFiles: PropTypes.func.isRequired,
  getFilesV2: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  displaySearchBar: PropTypes.bool,
  displayNumResults: PropTypes.bool,
  showBatchesOnLoad: PropTypes.bool,
  deleteFile: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
};

BatchList.defaultProps = {
  displaySearchBar: false,
  displayNumResults: false,
  showBatchesOnLoad: true,
};

const mapStateToProps = ({ reports, user }) => ({
  files: reports.files,
  loading: reports.loading,
  roles: user.currentUser.roles,
});

const mapDispatchToProps = {
  getFiles,
  getFilesV2,
  deleteFile,
  addMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(BatchList)));
