import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { InputChangeHandler, FocusHandler } from '../../shared/types/types';
import { UpdateContract } from '../types/contracts';

type Props = {
  link: string;
  isEdit: boolean;
  updateContract: UpdateContract;
};

export default function ContractLink({ link, isEdit, updateContract }: Props): JSX.Element | null {
  const [linkValue, setLinkValue] = useState(link);

  const handleChange: InputChangeHandler = e => {
    setLinkValue(e.target.value.trim());
  };

  const handleBlur: FocusHandler = () => {
    updateContract('link', linkValue);
  };

  if (isEdit)
    return (
      <TextField
        label="Link to PDF"
        value={linkValue}
        onBlur={handleBlur}
        onChange={handleChange}
        fullWidth
      />
    );
  if (link !== '')
    return (
      <div>
        <Link href={link} title="Link to Contract" target="_blank" rel="noopener noreferrer">
          Contract Link
        </Link>
      </div>
    );
  return null;
}
