import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List as VirtualList, AutoSizer } from 'react-virtualized';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { colors, claimColors } from '@apps/shared/src/style';

import NoResults from '../../shared/components/NoResults';
import { getFiles } from '../reportsActions';
import styledAvatar from '../../shared/components/styledAvatar';
import BatchSearch from './BatchSearch';
import * as types from '../../shared/types/propTypes';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '300px',
    minWidth: '300px',
  },
  numResults: {
    margin: '0.5rem 0 0 1rem',
    fontSize: 'small',
  },
  listContainer: {
    flex: '1 1 auto',
  },
  link: {
    textDecoration: 'none',
    padding: '8px',
    color: colors.black,
    display: 'grid',
    gridTemplateColumns: 'max-content minmax(30px, max-content) auto',
    gridColumnGap: '10px',
  },
  selectedLink: {
    backgroundColor: colors.grey12,
  },
  primaryInfo: {
    display: 'flex',
  },
  secondaryInfo: {
    fontSize: '13px',
    color: colors.greyDark,
    display: 'flex',
  },
  flexRight: {
    textAlign: 'right',
    flexGrow: '1',
  },
  singleRow: {
    display: 'flex',
    gap: '.2rem',
    alignItems: 'center',
  },
  checkBox: {
    marginTop: '-4px',
  },
  error: {
    color: colors.red,
  },
  warning: {
    color: colors.orange,
  },
  deleteBtn: {
    backgroundColor: '#D90027',
    marginTop: '1rem',
    marginLeft: '1rem',
  },
};

const avatarIn = styledAvatar(23, claimColors.batchIn);
const avatarOut = styledAvatar(23, claimColors.batchOut);

class AdvancedBatchList extends Component {
  componentDidMount() {
    if (this.props.showBatchesOnLoad) this.props.getFiles();
  }

  renderRow = ({ index, key, style }) => {
    const { files, batchId, classes, baseURL } = this.props;
    const file = files[index];
    const Avatar = file.direction === 'out' ? avatarOut : avatarIn;
    const ext = file.filename && file.filename.substring(file.filename.lastIndexOf('.') + 1);

    return (
      <div style={{ ...style }} key={key} className={classes.singleRow}>
        <Link
          to={`${baseURL}${file.id}`}
          className={classNames({
            [classes.link]: true,
            [classes.selectedLink]: batchId === file.id,
          })}
        >
          <Avatar>{file.direction}</Avatar>
          <div>{ext}</div>
          <div>
            <div className={classes.primaryInfo}>
              <div>
                <strong>{file.clientName}</strong>
              </div>
              {file.error && (
                <div className={classNames(classes.flexRight, classes.error)}>ERROR</div>
              )}
              {file.warning && !file.error && (
                <div className={classNames(classes.flexRight, classes.warning)}>WARNING</div>
              )}
            </div>
            <div className={classes.secondaryInfo}>
              <div>{file.date}</div>
              <div className={classes.flexRight}>{file.plan}</div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  render() {
    const { files, classes, loading, displaySearchBar, displayNumResults, getFiles } = this.props;

    return (
      <div className={classes.container}>
        {displaySearchBar && <BatchSearch getFiles={getFiles} />}
        <LoadingSpinner isLoading={loading} />
        {displayNumResults ? (
          <div className={classes.numResults}>{files.length} results</div>
        ) : (
          <NoResults results={files} description="batches" loadingStatus={loading} />
        )}
        <div className={classes.listContainer}>
          <AutoSizer>
            {({ width, height }) => (
              <VirtualList
                id="batch-list"
                style={{ outline: 'none' }}
                width={width}
                height={height}
                rowHeight={50}
                rowRenderer={this.renderRow}
                rowCount={files.length}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

AdvancedBatchList.propTypes = {
  files: PropTypes.arrayOf(types.file).isRequired,
  batchId: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  baseURL: PropTypes.string.isRequired,
  getFiles: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  displaySearchBar: PropTypes.bool,
  displayNumResults: PropTypes.bool,
  showBatchesOnLoad: PropTypes.bool,
};

AdvancedBatchList.defaultProps = {
  displaySearchBar: false,
  displayNumResults: false,
  showBatchesOnLoad: true,
};

const mapDispatchToProps = {
  getFiles,
};

const mapStateToProps = ({ reports, user }) => ({
  files: reports.files,
  loading: reports.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdvancedBatchList));
