import React from 'react';
import { connect } from 'react-redux';

import TermIcon from '@material-ui/icons/ViewList';

import { inflateString } from '@apps/shared/src/inflators';
import AddTerm from './AddTerm';
import TermChip from '../chips/TermChip';
import ChipBox from '../chips/ChipBox';

import {
  ContractTerm,
  TermItem,
  TermValue,
  HandleTermValueChange,
  OpenableElement,
  ContractTypes,
} from '../types/contracts';
import { RootState } from '../../shared/types/types';

import {
  updateTermValue,
  handleSubmitNewTerm,
  setIsElementOpen,
  handleSetSelectedTermItems,
  setCurrentTerm,
  updateContract,
  cancelTerm,
  cancelOpenTerm,
} from '../contractsActions';
import OpenTextTerm from './OpenTextTerm';

type ParentProps = {
  isEdit: boolean;
};
type StateProps = {
  terms: ContractTerm[];
  currentTermValues: ContractTerm;
  isTermOpen: boolean;
  isOpenTermOpen: boolean;
  selectedTermItems: TermItem[];
  contractType: ContractTypes;
};
type DispatchProps = {
  updateTermValue: (value: TermValue, termType: TermItem, clear: boolean) => void;
  handleSubmitNewTerm: VoidFunction;
  setIsElementOpen: (elementName: OpenableElement, open: boolean) => void;
  handleSetSelectedTermItems: (selections: TermItem[]) => void;
  updateContract: (action: string, value: string | number) => void;
  setCurrentTerm: (term: ContractTerm) => void;
  cancelTerm: VoidFunction;
  cancelOpenTerm: VoidFunction;
};

type Props = ParentProps & StateProps & DispatchProps;

export function ContractTerms({
  isEdit,
  terms,
  currentTermValues,
  isTermOpen,
  isOpenTermOpen,
  selectedTermItems,
  contractType,
  updateTermValue,
  handleSubmitNewTerm,
  setIsElementOpen,
  handleSetSelectedTermItems,
  updateContract,
  setCurrentTerm,
  cancelTerm,
  cancelOpenTerm,
}: Props): JSX.Element | null {
  if (contractType === ContractTypes.LOA) return null;

  const handleRemoveTerm = (termID: TermItem): void => {
    updateContract('removeTerm', termID);
  };

  const handleTermValueChange: HandleTermValueChange = (e): void => {
    if (e.target) {
      const { value, id, type } = e.target;
      updateTermValue(
        type === 'tel' ? Number(parseFloat(inflateString(value)).toFixed(2)) : value,
        id,
        false
      );
    } else {
      updateTermValue(e.value, e.id, false);
    }
  };

  const termBundle = {
    items: terms,
    handleRemoveItem: handleRemoveTerm,
    handleClickItem: setCurrentTerm,
    ItemChip: TermChip,
  };

  return (
    <ChipBox
      isEdit={isEdit}
      title="Terms"
      Icon={TermIcon}
      chipBundles={[termBundle]}
      gridArea="terms"
      defaultMessage={`\u274c - NO TERMS SPECIFIED`}
    >
      {isEdit && (
        <div style={{ display: !isTermOpen && !isOpenTermOpen ? 'flex' : 'block' }}>
          {!isOpenTermOpen && (
            <AddTerm
              currentValues={currentTermValues}
              isTermOpen={isTermOpen}
              selectedTermItems={selectedTermItems}
              handleChange={handleTermValueChange}
              handleSubmitNewTerm={handleSubmitNewTerm}
              setIsElementOpen={setIsElementOpen}
              handleSetSelectedTermItems={handleSetSelectedTermItems}
              cancelTerm={cancelTerm}
            />
          )}
          {!isTermOpen && (
            <OpenTextTerm
              currentValues={currentTermValues}
              isTermOpen={isOpenTermOpen}
              selectedTermItems={selectedTermItems}
              handleChange={handleTermValueChange}
              handleSubmitNewTerm={handleSubmitNewTerm}
              setIsElementOpen={setIsElementOpen}
              handleSetSelectedTermItems={handleSetSelectedTermItems}
              cancelTerm={cancelOpenTerm}
            />
          )}
        </div>
      )}
    </ChipBox>
  );
}

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    terms: contracts.selectedContract.terms,
    currentTermValues: contracts.currentTermValues,
    isTermOpen: contracts.isTermOpen,
    isOpenTermOpen: contracts.isOpenTermOpen,
    selectedTermItems: contracts.selectedTermItems,
    contractType: contracts.selectedContract.contractType,
  };
};

const mapDispatchToProps = {
  updateTermValue,
  handleSubmitNewTerm,
  setIsElementOpen,
  handleSetSelectedTermItems,
  setCurrentTerm,
  updateContract,
  cancelTerm,
  cancelOpenTerm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractTerms);
