import { checkRoles } from '@apps/shared/src/auth/util';
import { colors } from '@apps/shared/src/style';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { validArray } from '../../typeChecks';
import * as types from '../../types/propTypes';
import NavButton from './NavButton';
import UserButton from './UserButton';

const column = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
};

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.headerHeight,
    overflowY: 'hidden',
    display: 'flex',
    borderBottom: `1px solid ${colors.grey05}`,
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '1.25rem',
    marginLeft: '1.5em',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
      fontSize: '1rem',
      lineHeight: 1,
    },
  },
  left: { ...column, justifyContent: 'flex-start' },
  center: { ...column, justifyContent: 'center' },
  right: { ...column, justifyContent: 'flex-end' },
  logoutButton: {
    padding: '0.5em 1em',
  },
}));

export function checkAllRoles(userRoles) {
  const validUserRoles = validArray(userRoles);
  return {
    isReader: checkRoles(validUserRoles, ['claims-read']),
    isRepricer: checkRoles(validUserRoles, ['claims-reprice']),
    isVerifier: checkRoles(validUserRoles, ['claims-verify']),
    isClinician: checkRoles(validUserRoles, ['claims-clinical']),
    isReporter: checkRoles(validUserRoles, ['claims-report']),
    isAdmin: checkRoles(validUserRoles, ['claims-admin']),
    // isAdjudicator: checkRoles(validUserRoles, ['claims-adjudicate', 'claims-adjudicateverify']),
    isContractRecorder: checkRoles(validUserRoles, ['claims-contract']),
    isEDIImplementer: checkRoles(validUserRoles, ['claims-edi']),
  };
}

export function Header({ currentUser }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMedOrLess = useMediaQuery(theme.breakpoints.down('md'));
  const isMedOrMore = useMediaQuery(theme.breakpoints.up('md'));

  const iconSrc = isMedOrLess ? '/images/android-chrome-192x192.png' : '/images/6_degrees_logo.svg';

  const userRoles = validArray(currentUser && currentUser.roles);
  const {
    isReader,
    isRepricer,
    isVerifier,
    isClinician,
    isReporter,
    isAdmin,
    // isAdjudicator,
    isContractRecorder,
    isEDIImplementer,
  } = checkAllRoles(userRoles);

  return (
    <Toolbar className={classes.root}>
      <div className={classes.left}>
        <img alt="6 Degrees Health" src={iconSrc} height="40" />
        {isMedOrMore && (
          <Typography color="textSecondary" className={classes.title}>
            Claims Processor
          </Typography>
        )}
      </div>
      <div className={classes.center}>
        <NavButton visible={isRepricer} to="/new-claim">
          NEW&nbsp;CLAIMS
        </NavButton>

        {/* <NavButton visible={isAdjudicator} to="/adjudication">
          ADJUDICATION
        </NavButton> */}

        <NavButton visible={isReader || isRepricer || isVerifier || isClinician} to="/repricing">
          REPRICING
        </NavButton>

        <NavButton visible={isContractRecorder} to="/contracts">
          CONTRACTS
        </NavButton>

        <NavButton visible={isAdmin} to="/admin">
          ADMIN
        </NavButton>

        <NavButton visible={isReporter} to="/reports">
          REPORTS
        </NavButton>

        <NavButton visible={isEDIImplementer} to="/edi">
          EDI
        </NavButton>
      </div>
      <div className={classes.right}>
        <UserButton currentUser={currentUser} />
        <Link to="/logout">
          <Button variant="outlined" className={classes.logoutButton} href="logout">
            LOG OUT
          </Button>
        </Link>
      </div>
    </Toolbar>
  );
}

Header.defaultProps = {
  currentUser: undefined,
};
Header.propTypes = {
  currentUser: types.simpleUser,
};

function mapStateToProps({ user }) {
  return {
    currentUser: user.currentUser,
  };
}

export default connect(mapStateToProps)(Header);
