import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function ClaimMessage({ text }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h6">{text}</Typography>
    </div>
  );
}

ClaimMessage.propTypes = {
  text: PropTypes.string.isRequired,
};
