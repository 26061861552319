import React from 'react';
import { Route } from 'react-router-dom';

import AppLayout from '../shared/components/layout/AppLayout';
import ReportsList from './ReportsList';
import AllClaims from './AllClaims';
import ClaimsAging from './claimsAging/ClaimsAging';
import Duplicates from './Duplicates';
import DailyClaims from './dailyClaims/DailyClaims';
import InProcessClaims from './InProcessClaims';
import Patients from './Patients';
import Plans from './Plans';
import Batches from './batches/Batches';
import AdvancedBatches from './batches/AdvancedBatches';
import Settlements from '../settlements/Settlements';
import MonthlyReporting from './monthlyReporting/MonthlyReporting';
import InvoiceReport from './InvoiceReport';
import ClaimTraffic from './claimTraffic/ClaimTraffic';
import Exceptions from './exceptions/Exceptions';

export default function Reports() {
  return (
    <AppLayout showHeader sidebar={<ReportsList />}>
      <Route path="/reports/all-claims" component={AllClaims} />
      <Route path="/reports/claims-aging" component={ClaimsAging} />
      <Route path="/reports/duplicates" component={Duplicates} />
      <Route path="/reports/daily-claims" component={DailyClaims} />
      <Route path="/reports/in-process" component={InProcessClaims} />
      <Route path="/reports/patients" component={Patients} />
      <Route path="/reports/plans" component={Plans} />
      <Route path="/reports/batches/:id?" component={Batches} />
      <Route path="/reports/advanced-batches/:id?" component={AdvancedBatches} />
      <Route path="/reports/settlements" component={Settlements} />
      <Route path="/reports/monthly-reporting" component={MonthlyReporting} />
      <Route path="/reports/invoices" component={InvoiceReport} />
      <Route path="/reports/claim-traffic" component={ClaimTraffic} />
      <Route path="/reports/exceptions" component={Exceptions} />
    </AppLayout>
  );
}
