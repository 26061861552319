import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer, List as VirtualList } from 'react-virtualized';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { formatCurrency, splitCamelCase } from '@apps/shared/src/utilities';

import { colors } from '@apps/shared/src/style';

import { getInProcessClaimsAndFilter, filterInProcess, unassignClaimOwner } from './reportsActions';
import * as types from '../shared/types/propTypes';
import ReportSearchInput from './ReportSearchInput';

const claimHeight = 25;
const aboveTheTable = '168px';
const leftOfTheTable = '230px';
const styles = {
  filterHeader: {
    padding: '0.5em 0 1em',
    top: '0',
    zIndex: '1',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
  },
  greyText: { color: colors.greyDark },
  leftAlign: { textAlign: 'left' },
  inProcessReport: {
    maxWidth: `calc(100vw - ${leftOfTheTable})`,
    minWidth: '1275px',
    height: 'calc(100vh - 65px)',
  },
  gridDefinition: {
    display: 'grid',
    gridTemplateAreas: '" subscriber patient billedAmt claimNum refNum status unassign owner"',
    gridTemplateColumns: '12em       12em    6em       16em     16em   15em   1.5em      12em',
    gridGap: '0.5em',
    padding: '0.5em 0',
    fontSize: '0.75em',
    zIndex: '1',
    height: `calc(${claimHeight}px - 2em)`,
  },
  gridHeader: {
    fontWeight: '500',
    backgroundColor: colors.greyDark,
    color: colors.white,
    height: '1.5em',
  },
  crop: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  patient: { gridArea: 'patient' },
  subscriber: { gridArea: 'subscriber' },
  billedAmt: {
    gridArea: 'billedAmt',
    fontWeight: '500',
    textAlign: 'right',
    paddingRight: '1em',
  },
  claimNum: { gridArea: 'claimNum' },
  refNum: { gridArea: 'refNum' },
  status: { gridArea: 'status' },
  owner: { gridArea: 'owner' },
  unassignButton: {
    gridArea: 'unassign',
    color: colors.grey25,
    fontSize: '1.5em',
    marginTop: '-0.1em',
    transition: 'color 0.25s',
    '&:hover': {
      color: colors.red,
      cursor: 'pointer',
    },
  },
  unassignedOwner: {
    color: colors.greyDark,
    fontStyle: 'italic',
  },
  modal: {
    top: aboveTheTable,
    left: `calc(${leftOfTheTable} - 2em)`,
    zIndex: '2',
  },
};

function InProcessReportTable({ claims, currentUser, loading, handleOwnerUnassign }) {
  return loading ? null : (
    <>
      <InProcessReportTableHeaderStyled claims={claims} />
      <InProcessReportTableDataStyled
        claims={claims}
        handleOwnerUnassign={handleOwnerUnassign}
        loading={loading}
        currentUser={currentUser}
      />
    </>
  );
}

InProcessReportTable.defaultProps = {
  claims: undefined,
  currentUser: undefined,
  loading: false,
};
InProcessReportTable.propTypes = {
  claims: PropTypes.arrayOf(types.reportRow),
  currentUser: types.uiUser,
  loading: PropTypes.bool,
  handleOwnerUnassign: PropTypes.func.isRequired,
};

function InProcessReportTableHeader({ classes }) {
  return (
    <div className={classNames(classes.gridDefinition, classes.gridHeader)}>
      <span title="Subscriber" className={classes.subscriber}>
        Subscriber
      </span>
      <span title="Patient" className={classes.patient}>
        Patient
      </span>
      <span title="Billed Amount" className={classes.billedAmt}>
        Billed
      </span>
      <span title="Claim Number" className={classes.claimNum}>
        Claim Number
      </span>
      <span title="Reference Number" className={classes.refNum}>
        Ref Number
      </span>
      <span title="Status" className={classes.status}>
        Status
      </span>
      <span title="Owner" className={classes.owner}>
        Owner
      </span>
    </div>
  );
}

InProcessReportTableHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const InProcessReportTableHeaderStyled = withStyles(styles)(InProcessReportTableHeader);

function InProcessReportTableData({ claims, classes, handleOwnerUnassign, currentUser }) {
  const renderRow = ({ index, key, style }) => {
    const c = claims[index];
    const patients = c.patients ? c.patients.join(', ') : '';
    const subscribers = c.subscribers ? c.subscribers.join(', ') : '';
    const claimNums = c.claimNums ? c.claimNums.join(', ') : '';
    const refNums = c.refNums ? c.refNums.join(', ') : '';
    const billedAmt = formatCurrency(c.billedAmt);
    const status = splitCamelCase(c.status);
    const isAdmin = currentUser.roles.includes('claims-admin');
    const hasOwner = c.owner && c.owner.id;
    const hasOwnerName = c.owner && c.owner.fullName;

    return (
      <div key={key} style={style}>
        <div key={key} style={{ ...styles.gridDefinition }}>
          <span
            title={`Subscriber: ${subscribers}`}
            className={classNames(classes.subscriber, classes.greyText, classes.crop)}
          >
            {subscribers}
          </span>
          <span
            title={`Patient: ${patients}`}
            className={classNames(classes.patient, classes.greyText, classes.crop)}
          >
            {patients}
          </span>
          <span title={`Billed Amount: ${billedAmt}`} className={classes.billedAmt}>
            {billedAmt}
          </span>
          <span
            title={`Claim Number: ${claimNums}`}
            className={classNames(classes.crop, classes.claimNum)}
          >
            <Link to={`/repricing/${c.id}`}>{claimNums}</Link>
          </span>
          <span
            title={`Reference Number: ${refNums}`}
            className={classNames(classes.crop, classes.refNum)}
          >
            <Link to={`/repricing/${c.id}`}>{refNums}</Link>
          </span>
          <span title="Status" className={classes.status}>
            {status}
          </span>
          {hasOwner ? (
            <>
              {isAdmin && (
                <Tooltip title="Unassign owner">
                  <ClearIcon
                    onClick={handleOwnerUnassign(c.id)}
                    className={classes.unassignButton}
                  />
                </Tooltip>
              )}
              {hasOwnerName ? (
                <span
                  title={`Owner: ${c.owner.fullName}`}
                  className={classNames(classes.crop, classes.owner)}
                >
                  {c.owner.fullName}
                </span>
              ) : (
                <span title="This user has no name given" className={classNames(classes.owner)}>
                  Unnamed
                </span>
              )}
            </>
          ) : (
            <span
              title="This claim has no assigned owner"
              className={classNames(classes.owner, classes.unassignedOwner)}
            >
              Unassigned
            </span>
          )}
        </div>
      </div>
    );
  };

  renderRow.propTypes = types.RenderRow;

  return (
    <AutoSizer>
      {({ width, height }) => (
        <VirtualList
          id="inprocess-list"
          style={{ height: `calc(100vh - ${aboveTheTable})` }}
          width={width}
          height={height}
          rowHeight={claimHeight}
          rowRenderer={renderRow}
          rowCount={claims.length}
        />
      )}
    </AutoSizer>
  );
}

InProcessReportTableData.defaultProps = {
  claims: undefined,
  currentUser: undefined,
};
InProcessReportTableData.propTypes = {
  claims: PropTypes.arrayOf(types.reportRow),
  currentUser: types.uiUser,
  handleOwnerUnassign: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const InProcessReportTableDataStyled = withStyles(styles)(InProcessReportTableData);

class InProcessClaims extends Component {
  componentDidMount() {
    this.props.dispatch(getInProcessClaimsAndFilter(this.props.searchText));
  }

  handleSearch = e => this.props.dispatch(filterInProcess(e.currentTarget.value));
  handleClearSearch = () => this.props.dispatch(filterInProcess(''));
  handleOwnerUnassign = claimID => () => this.props.dispatch(unassignClaimOwner(claimID));

  render() {
    const { claims, loading, searchText, currentUser, classes } = this.props;
    const numOfClaims = Array.isArray(claims) ? claims.length : 0;
    return (
      <div className={classes.inProcessReport}>
        <div className={classes.filterHeader}>
          <ReportSearchInput
            searchText={searchText}
            handleSearch={this.handleSearch}
            handleClearSearch={this.handleClearSearch}
            label="Search In-Process Claims"
            style={{ width: '15em', marginRight: '1em' }}
          />
          <span className={classes.greyText}>
            {numOfClaims} claim{numOfClaims !== 1 ? 's' : ''}
          </span>
        </div>
        <LoadingSpinner isLoading={loading} />
        <InProcessReportTable
          claims={claims}
          handleOwnerUnassign={this.handleOwnerUnassign}
          loading={loading}
          currentUser={currentUser}
        />
      </div>
    );
  }
}

InProcessClaims.defaultProps = {
  claims: undefined,
  searchText: undefined,
  currentUser: undefined,
};
InProcessClaims.propTypes = {
  claims: PropTypes.arrayOf(types.reportRow),
  loading: PropTypes.bool.isRequired,
  searchText: PropTypes.string,
  currentUser: types.simpleUser,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps({ reports, user }) {
  return {
    claims: reports.filteredInProcessClaims,
    loading: reports.loading,
    searchText: reports.inProcessSearchText,
    currentUser: user.currentUser,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(InProcessClaims));
