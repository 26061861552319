import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors, claimColors } from '@apps/shared/src/style';
import { formatCurrency } from '@apps/shared/src/utilities';

import { FlexRow, Column } from '../shared/grid';
import { parseTypeOfBill } from '../manualClaim/claimsReducer';
import HoverableText from '../repricing/HoverableText';
import * as types from '../shared/types/propTypes';

export function formatTypeOfBill(typeOfBill) {
  if (typeof typeOfBill !== 'string') return '';
  return typeOfBill.length === 3 ? `${typeOfBill.slice(0, 2)} ${typeOfBill[2]}` : typeOfBill;
}

export function parseClaimType(claimType) {
  switch (claimType) {
    case 'P':
      return [claimColors.professional, 'Professional Services'];
    case 'O':
      return [claimColors.outpatient, 'Outpatient Services'];
    case 'I':
      return [claimColors.inpatient, 'Inpatient Services'];
    default:
      return [colors.grey50, 'Services'];
  }
}

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-evenly',
    fontSize: '20px',
  },
});

export default function Title({ simpleClaim }) {
  const classes = useStyles();
  if (!simpleClaim) return null;

  const { typeOfBill, formType, claimNum, billAmt, statementFromDate } = simpleClaim;
  const refNum = simpleClaim.refNum !== claimNum ? simpleClaim.refNum : '';

  const claimType = parseTypeOfBill(typeOfBill, formType);
  const [color, serviceType] = parseClaimType(claimType);
  const formattedTypeOfBill = formatTypeOfBill(typeOfBill);

  const [billCodeTitle, billCodeType] =
    formType === 'B' ? ['Place of Service', 'place-of-service'] : ['Type of Bill', 'bill-type'];

  return (
    <FlexRow style={{ color }} className={classes.root}>
      <Column id="claim-amount-container">
        <strong>{serviceType}:</strong> {formatCurrency(billAmt)}
      </Column>
      <Column>
        <strong>{`${billCodeTitle}: `}</strong>
        <HoverableText
          id="type-of-bill"
          type={billCodeType}
          value={typeOfBill}
          date={statementFromDate}
        >
          {formattedTypeOfBill}
        </HoverableText>
      </Column>
      {refNum && (
        <Column>
          <strong>Ref #:</strong>
          <span id="ref-number">{refNum}</span>
        </Column>
      )}
      {claimNum && (
        <Column>
          <strong>Claim #:</strong>
          <span id="claim-number">{claimNum}</span>
        </Column>
      )}
    </FlexRow>
  );
}

Title.propTypes = {
  simpleClaim: types.simpleClaim.isRequired,
};
