import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/styles';

import ActiveIcon from '@material-ui/icons/Check';
import DataEntryIcon from '@material-ui/icons/Create';

import SCAIcon from '@material-ui/icons/Person';
import LOAIcon from '@material-ui/icons/Mail';
import DirectIcon from '@material-ui/icons/Assignment';

import { colors } from '@apps/shared/src/style';
import { capitalizeWords } from '../../shared/globals';
import { contractStatusCodes as statusCodes } from '../../shared/status';
import { contractStatusColor } from '../contractUtilities';
import { ContractStatus, ContractTypes } from '../types/contracts';

function getStatusIcon(status: ContractStatus): JSX.Element {
  switch (status) {
    case statusCodes.active: {
      return <ActiveIcon fontSize="inherit" data-testid="ActiveIcon" />;
    }
    case statusCodes.dataEntry:
    default: {
      return <DataEntryIcon fontSize="inherit" data-testid="DataEntryIcon" />;
    }
  }
}

function getTypeIcon(type: ContractTypes): JSX.Element {
  switch (type) {
    case ContractTypes.SingleCase: {
      return <SCAIcon fontSize="inherit" data-testid="SCAIcon" />;
    }
    case ContractTypes.Direct:
    case ContractTypes.DirectOptIn: {
      return <DirectIcon fontSize="inherit" data-testid="DirectIcon" />;
    }
    case ContractTypes.LOA:
    default: {
      return <LOAIcon fontSize="inherit" data-testid="LOAIcon" />;
    }
  }
}

const useStyles = makeStyles<null, { statusColor: CSSProperties['color'] }>({
  icon: {
    display: 'flex',
    alignItems: 'baseline',
    width: '15%',
  },
  statusIcon: {
    color: ({ statusColor }): CSSProperties['color'] => statusColor,
    marginLeft: '-3px',
    fontSize: '1rem',
  },
  typeIcon: {
    color: colors.blue,
    fontSize: '1.75rem',
  },
});

type Props = {
  status: ContractStatus;
  type: ContractTypes;
};
export default function ContractIcon({ status, type }: Props): JSX.Element {
  const StatusIcon = getStatusIcon(status);
  const statusColor = contractStatusColor(status);
  const TypeIcon = getTypeIcon(type);
  const classes = useStyles({ statusColor });

  return (
    <div className={classes.icon} title={capitalizeWords(status)}>
      <span className={classes.typeIcon}>{TypeIcon}</span>
      <span className={classes.statusIcon}>{StatusIcon}</span>
    </div>
  );
}
