import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import Button from '@material-ui/core/Button';

import { TopLevelCell } from '../chips/ChipBox';

const useStyles = makeStyles({
  action: {
    margin: '0.5em',
  },
});

type Props = {
  handleSubmitNewTerm: VoidFunction;
  cancelTerm: VoidFunction;
};
export default function EditTermButtons({ handleSubmitNewTerm, cancelTerm }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TopLevelCell>
      <Button onClick={cancelTerm} variant="outlined" className={classes.action}>
        Cancel
      </Button>
      <Button
        onClick={handleSubmitNewTerm}
        variant="contained"
        color="primary"
        className={classes.action}
      >
        Save Term
      </Button>
    </TopLevelCell>
  );
}
