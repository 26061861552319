import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { ContractRosterTheme } from '../types/contracts';

const useStyles = makeStyles<ContractRosterTheme, Partial<Props>>(theme => ({
  headerRow: {
    height: theme.contractRoster.table.headerRowHeight,
  },
  tableHeaderTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  tableMappedTitles: {
    fontSize: '1em',
  },
  tableCell: {
    verticalAlign: 'top',
  },
}));

type Props = {
  isEdit: boolean;
  npiColumnName?: string;
  taxIDColumnName?: string;
};
export default function ProviderTableHeader({
  isEdit,
  npiColumnName,
  taxIDColumnName,
}: Props): JSX.Element {
  const classes = useStyles({ isEdit });

  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        {/* empty <TableCell /> col header for delete button */}
        {isEdit && <TableCell />}
        <TableCell className={classes.tableCell}>
          <Typography className={classes.tableHeaderTitle}>NPI</Typography>
          {npiColumnName && (
            <Typography className={classes.tableMappedTitles}>
              (&quot;{npiColumnName}&quot;)
            </Typography>
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography className={classes.tableHeaderTitle}>Tax ID</Typography>
          {taxIDColumnName && (
            <Typography className={classes.tableMappedTitles}>
              (&quot;{taxIDColumnName}&quot;)
            </Typography>
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography className={classes.tableHeaderTitle}>Provider Name</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
