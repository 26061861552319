import React, { useState } from 'react';

import AutocompleteSelect, { PlanOption } from '../../shared/components/AutocompleteSelect';
import PatientOption from './PatientOption';
import UnidentifiedPatientInput from './UnidentifiedPatientInput';

import { validArray } from '../../shared/typeChecks';
import { capitalizeWords } from '../../shared/globals';
import {
  ContractPlan,
  ContractPatient,
  UpdateContract,
  AutocompleteOptions,
  PatientSelectionType,
  SetIsElementOpen,
  ContractTypes,
} from '../types/contracts';
import { checkIsSCAorLOA } from '../contractUtilities';

type Props = {
  visible: boolean;
  plans: ContractPlan[];
  patients: ContractPatient[];
  updateContract: UpdateContract;
  autocompleteOptions: AutocompleteOptions;
  handleAutocompleteSearch: (type: PatientSelectionType, searchText: string) => void;
  isPatientOpen: boolean;
  isPlanOpen: boolean;
  setIsElementOpen: SetIsElementOpen;
  contractType: ContractTypes;
};
export default function PatientInputs({
  visible,
  plans,
  patients,
  updateContract,
  autocompleteOptions,
  handleAutocompleteSearch,
  isPatientOpen,
  isPlanOpen,
  setIsElementOpen,
  contractType,
}: Props): JSX.Element | null {
  const [planSearchText, setPlanSearchText] = useState('');
  const [patientSearchText, setPatientSearchText] = useState('');

  if (!visible) return null;

  const handleAddItem =
    (type: PatientSelectionType) =>
    (p?: { id: number }): void => {
      if (p?.id) updateContract(`add${capitalizeWords(type)}`, p.id);
    };

  const handleSearch =
    (type: PatientSelectionType) =>
    (e: React.FormEvent<HTMLInputElement>): void => {
      const setSearchText = type === 'patient' ? setPatientSearchText : setPlanSearchText;
      const searchText = e.currentTarget.value;
      setSearchText(searchText);
      handleAutocompleteSearch(type, searchText);
      if (searchText.length >= 3) {
        setIsElementOpen(type, true);
      }
    };

  const handleBlur = (type: PatientSelectionType) => (): void => {
    setIsElementOpen(type, false);
    if (type === 'patient') {
      setPatientSearchText('');
    } else if (type === 'plan') {
      setPlanSearchText('');
    }
  };

  const handleClick = (type: PatientSelectionType) => (): void => {
    const searchText = type === 'patient' ? patientSearchText : planSearchText;
    if (searchText.length >= 3) setIsElementOpen(type, true);
  };

  const filteredAutocompleteOptions = (
    selectedElements: ContractPlan[] | ContractPatient[]
  ): AutocompleteOptions => {
    const selectedIDs = validArray(selectedElements).map(el => el.id);
    return autocompleteOptions.filter(opt => !selectedIDs.includes(opt.id));
  };

  const isSCAorLOA = checkIsSCAorLOA(contractType);
  const showPlanInput =
    contractType === ContractTypes.DirectOptIn || (isSCAorLOA && plans.length < 1);

  return (
    <>
      {showPlanInput && (
        <AutocompleteSelect
          inputValue={planSearchText}
          onSelect={handleAddItem('plan')}
          onChange={handleSearch('plan')}
          onBlur={handleBlur('plan')}
          onClick={handleClick('plan')}
          placeholder="Add Plan via Name or Plan ID"
          isOpen={isPlanOpen}
          options={filteredAutocompleteOptions(plans)}
          RenderComponent={PlanOption}
        />
      )}
      {isSCAorLOA && (
        <>
          <AutocompleteSelect
            inputValue={patientSearchText}
            onSelect={handleAddItem('patient')}
            onChange={handleSearch('patient')}
            onBlur={handleBlur('patient')}
            onClick={handleClick('patient')}
            placeholder="Add Patient from Eligibility Records via Name or Member ID"
            isOpen={isPatientOpen}
            options={filteredAutocompleteOptions(patients)}
            RenderComponent={PatientOption}
          />
          <UnidentifiedPatientInput updateContract={updateContract} />
        </>
      )}
    </>
  );
}
