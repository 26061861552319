import { colors } from '@apps/shared/src/style';
import { formatCurrency } from '@apps/shared/src/utilities';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../shared/types/types';
import ClaimLink from './ClaimLink';
import { getCaseSummary } from './settlementsActions';
import { SettlementsClaimSummary } from './types/types';

function calculateClaimTotals(claims: SettlementsClaimSummary[]): {
  billedTotal: number;
  allowedTotal: number;
} {
  return claims.reduce(
    (totals, claim) => ({
      billedTotal: totals.billedTotal + claim.billedAmount,
      allowedTotal: totals.allowedTotal + claim.medicareAllowed,
    }),
    { billedTotal: 0, allowedTotal: 0 }
  );
}

const useStyles = makeStyles({
  miniView: { maxWidth: '325.75px', margin: 'auto' },
  header: { backgroundColor: colors.grey07 },
  message: { padding: '16px' },
  totals: { fontWeight: 'bold' },
});

type StateProps = {
  match: { params: { caseNumber: string } };
  claims: SettlementsClaimSummary[];
  loadingCases: number;
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    match: {
      params: {
        caseNumber: window.location.href
          .split('/')
          .filter((piece, index) => index === window.location.href.split('/').length - 1)[0],
      },
    },
    claims: settlements.claims,
    loadingCases: settlements.loadingCases,
  };
};

const mapDispatchToProps = {
  getCaseSummary,
};

type Props = StateProps & typeof mapDispatchToProps;
export function CaseMiniView({ match, claims, getCaseSummary, loadingCases }: Props): JSX.Element {
  const classes = useStyles();

  useEffect(() => {
    getCaseSummary(match.params.caseNumber);
  }, [getCaseSummary, match.params.caseNumber]);

  if (claims.length === 0 && loadingCases === 0)
    return (
      <Card className={classes.miniView}>
        <Typography variant="body1" className={classes.message}>
          No Claims Linked to Case
        </Typography>
      </Card>
    );

  const tableColumnTitles = ['Claim', 'Billed Amount', 'Medicare'];
  const { billedTotal, allowedTotal } = calculateClaimTotals(claims);

  return (
    <TableContainer className={classes.miniView}>
      <Table size="small">
        <TableHead className={classes.header}>
          <TableRow>
            {tableColumnTitles.map(title => (
              <TableCell padding="none" key={title} align="center">
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {claims.map(claim => (
            <TableRow key={claim.id}>
              <TableCell padding="none" align="left">
                <ClaimLink claim={claim} />
              </TableCell>
              <TableCell padding="none" align="right">
                {formatCurrency(claim.billedAmount)}
              </TableCell>
              <TableCell padding="none" align="right">
                {formatCurrency(claim.medicareAllowed)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell padding="none" align="right" className={classes.totals}>
              Totals
            </TableCell>
            <TableCell padding="none" align="right" className={classes.totals}>
              {formatCurrency(billedTotal)}
            </TableCell>
            <TableCell padding="none" align="right" className={classes.totals}>
              {formatCurrency(allowedTotal)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseMiniView);
