import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Paper from '@material-ui/core/Paper';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { doesStringInclude, doesStringArrayIncludeString } from '@apps/shared/src/utilities';
import VirtualizedTable from '@apps/shared/src/components/VirtualizedTable';

import DownloadToExcelButton from './DownloadToExcelButton';
import MonthSelect from './MonthSelect';
import { getAllClaimsReport as getReport, exportAllClaimsReport } from './reportsActions';
import * as types from '../shared/types/propTypes';

import RepricingLink from './RepricingLink';
import { getClaimNumText, getRefNum } from './reportsUtils';
import { formatDate, dateIncludes } from '../shared/globals';
import ReportSearchInput from './ReportSearchInput';
import { moneyIncludes } from './reportsReducer';

const menuHeight = '90px';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '1rem',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    padding: '1px 3px',
  },
  table: {
    minWidth: '2675px',
    height: `calc(100vh - ${theme.headerHeight.full}px - ${menuHeight} - 1rem)`,
    boxSizing: 'border-box',
  },
  searchBar: {
    alignSelf: 'flex-start',
    width: '15rem',
  },
}));

const columns = [
  {
    width: 600,
    label: 'Billing Provider Name',
    dataKey: 'providerName',
  },
  {
    width: 600,
    label: 'Service Location Name',
    dataKey: 'serviceLocationName',
  },
  {
    width: 300,
    label: 'Location',
    dataKey: 'location',
  },
  {
    width: 250,
    label: 'Zip',
    dataKey: 'zip',
  },
  {
    width: 300,
    label: 'TPA',
    dataKey: 'tpa',
  },
  {
    width: 250,
    label: 'Plan',
    dataKey: 'policyNum',
  },
  {
    width: 220,
    label: 'Complete Date',
    dataKey: 'completeDate',
  },
  {
    width: 220,
    label: 'Date of Service',
    dataKey: 'serviceDate',
  },
  {
    width: 100,
    label: 'Type',
    dataKey: 'type',
  },
  {
    width: 300,
    label: 'Patient',
    dataKey: 'patient',
  },
  {
    width: 300,
    label: 'Subscriber',
    dataKey: 'subscriber',
  },
  {
    width: 200,
    label: 'Diagnosis',
    dataKey: 'diagnosis',
  },
  {
    width: 200,
    label: 'Amount',
    dataKey: 'amount',
    isNumeric: true,
  },
  {
    width: 200,
    label: 'Reprice',
    dataKey: 'reprice',
    isNumeric: true,
  },
  {
    width: 200,
    label: 'Savings',
    dataKey: 'savings',
    isNumeric: true,
  },
  {
    width: 200,
    label: '% Savings',
    dataKey: 'savingsPercent',
    isNumeric: true,
  },
  {
    width: 350,
    label: 'Claim Number',
    dataKey: 'claimNum',
  },
  {
    width: 500,
    label: 'Reference Number',
    dataKey: 'refNum',
  },
];

function transformData(data) {
  if (!data) return null;
  return {
    providerName: data.providerName,
    serviceLocationName: data.serviceLocationName,
    location: data.location,
    zip: data.zip,
    tpa: data.tpa,
    policyNum: data.policyNum,
    completeDate:
      data.completeTime !== '0001-01-01T00:00:00Z' ? formatDate(data.completeTime) : null,
    serviceDate: formatDate(data.earliestDt),
    type: data.claimTypes,
    patient: data.patients || data.subscribers,
    subscriber: data.subscribers,
    diagnosis: data.principalDiagnosis,
    amount: `$${data.billedAmt.toFixed(2)}`,
    reprice: `$${data.reprice.toFixed(2)}`,
    savings: `$${data.savings.toFixed(2)}`,
    savingsPercent: `${(data.savingsPercent * 100).toFixed(2)}%`,
    claimNum: <RepricingLink claimID={data.id} displayText={getClaimNumText(data)} />,
    refNum: <RepricingLink claimID={data.id} displayText={getRefNum(data)} />,
  };
}

function filterData(data, searchKey) {
  if (searchKey === '' || !data || data.length === 0) return data;

  return data.filter(
    entry =>
      doesStringInclude(entry.providerName, searchKey) ||
      doesStringInclude(entry.serviceLocationName, searchKey) ||
      doesStringInclude(entry.location, searchKey) ||
      doesStringInclude(entry.zip, searchKey) ||
      doesStringInclude(entry.tpa, searchKey) ||
      doesStringInclude(entry.policyNum, searchKey) ||
      dateIncludes(entry.completeTime, searchKey) ||
      dateIncludes(entry.earliestDt, searchKey) ||
      doesStringArrayIncludeString(entry.claimTypes, searchKey) ||
      doesStringArrayIncludeString(entry.patients, searchKey) ||
      doesStringArrayIncludeString(entry.subscribers, searchKey) ||
      doesStringInclude(entry.principalDiagnosis, searchKey) ||
      doesStringInclude(entry.tpa, searchKey) ||
      moneyIncludes(entry.billedAmt, searchKey) ||
      moneyIncludes(entry.reprice, searchKey) ||
      doesStringInclude(getClaimNumText(entry), searchKey) ||
      doesStringInclude(getRefNum(entry), searchKey)
  );
}
export function AllClaims({ report, isLoading, isDownloading, exportAllClaimsReport, getReport }) {
  const classes = useStyles();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(report);

  const onSelect = month => {
    if (month) getReport(month);
    setSelectedMonth(month);
  };

  const handleExportClick = () => {
    exportAllClaimsReport(selectedMonth);
  };

  const handleSearch = e => {
    const searchKey = e.currentTarget.value;
    setSearchText(searchKey);
    setFilteredData(filterData(report, searchKey));
  };

  const handleClearSearch = useCallback(() => {
    setSearchText('');
    setFilteredData(report);
  }, [report]);

  useEffect(handleClearSearch, [report, handleClearSearch]);

  const isInputVisible = !isLoading && report.length !== 0;
  const isNumClaimsTextVisible = !isLoading && selectedMonth !== '';

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <MonthSelect isDisabled={isLoading} onSelect={onSelect} label="All claims for" />
        {isInputVisible && (
          <>
            <DownloadToExcelButton
              disabled={!isInputVisible}
              isDownloading={isDownloading}
              onClick={handleExportClick}
              tooltipTitle={`Download all ${report.length || 0} ${selectedMonth} claims to Excel`}
            />
            <div className={classes.searchBar}>
              <ReportSearchInput
                searchText={searchText}
                handleSearch={handleSearch}
                handleClearSearch={handleClearSearch}
                label="Search all claims"
              />
            </div>
          </>
        )}
        {isNumClaimsTextVisible && <span>{`${filteredData.length || 0} claims found`}</span>}
      </div>

      <LoadingSpinner isLoading={isLoading} />
      {isInputVisible && (
        <div className={classes.tableContainer}>
          <Paper className={classes.table}>
            <VirtualizedTable
              columns={columns}
              data={filteredData}
              dataTransformer={transformData}
            />
          </Paper>
        </div>
      )}
    </div>
  );
}

AllClaims.propTypes = {
  report: PropTypes.arrayOf(types.reportRow).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  exportAllClaimsReport: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
};

function mapStateToProps({ reports }) {
  return {
    report: reports.report,
    isLoading: reports.loading,
    isDownloading: reports.isDownloading,
  };
}

const mapDispatchToProps = {
  exportAllClaimsReport,
  getReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllClaims);
