import axios from 'axios';
import { required, validate, updateAndValidate, validateAll, isState } from '../shared/validators';
import { UPDATE_VALUE, VALIDATE } from './rootReducer';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const openModal = () => ({
  type: OPEN_MODAL,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

const initialState = {
  isOpen: false,
};

const fileUploadModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, isOpen: true };
    case CLOSE_MODAL:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default fileUploadModalReducer;
