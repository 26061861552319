import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { colors } from '@apps/shared/src/style';

import { getPlanReport } from './reportsActions';
import * as types from '../shared/types/propTypes';

const styles = {
  table: {
    fontSize: '13px',
    textAlign: 'left',
    borderSpacing: 0,
    position: 'relative',
  },
  thead: {
    display: 'block',
  },
  tbody: {
    display: 'block',
    height: 'calc(100vh - 200px)',
    overflow: 'auto',
  },
  everyOtherRow: {
    background: colors.grey12,
  },
  cell: {
    width: '100px',
    maxWidth: '100px',
    padding: '3px',
  },
  cellLarge: {
    width: '150px',
    maxWidth: '150px',
    padding: '3px',
  },
  cellSmall: {
    width: '50px',
    maxWidth: '50px',
    padding: '3px',
  },
  cellVeryLarge: {
    width: '250px',
    maxWidth: '250px',
    padding: '3px',
  },
};

function cleanDate(date) {
  return date.slice(0, 10);
}

function emptyIfInfDate(date) {
  const cleaned = cleanDate(date);
  return cleaned === '9999-01-01' ? '' : cleaned;
}

function emptyIfZeroDate(date) {
  const cleaned = cleanDate(date);
  return cleaned === '0001-01-01' ? '' : cleaned;
}

class Plans extends Component {
  componentDidMount() {
    this.props.dispatch(getPlanReport());
  }

  render() {
    const { plans, loading, classes } = this.props;
    let planRows;
    if (Array.isArray(plans)) {
      planRows = plans.map((p, index) => (
        <tr
          key={`${p.TPAName}-${p.PlanID}-${p.StartDate}-${p.EndDate}`}
          className={index % 2 === 0 ? classes.everyOtherRow : ''}
        >
          <td className={classes.cellLarge}>{p.PlanID}</td>
          <td className={classes.cellLarge}>{p.CensusNames.join(', ')}</td>
          <td className={classes.cellLarge}>{p.PlanName}</td>
          <td className={classes.cell}>{p.TPAName}</td>
          <td className={classes.cell}>{emptyIfZeroDate(p.StartDate)}</td>
          <td className={classes.cell}>{emptyIfInfDate(p.EndDate)}</td>
          <td className={classes.cell}>{p.FacilityMultiplier}</td>
          <td className={classes.cell}>{p.ProfessionalMultiplier}</td>
          <td className={classes.cell}>{p.DrugMultiplier}</td>
          <td className={classes.cellVeryLarge}>{p.AnesthesiaMultiplier}</td>
        </tr>
      ));
    }
    return (
      <div>
        Plans:
        <LoadingSpinner isLoading={loading} />
        {!loading && (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr>
                <th className={classes.cellLarge}>Plan ID</th>
                <th className={classes.cellLarge}>Census Names</th>
                <th className={classes.cellLarge}>Plan Name</th>
                <th className={classes.cell}>TPA Name</th>
                <th className={classes.cell}>Start Date</th>
                <th className={classes.cell}>End Date</th>
                <th className={classes.cell}>Facility Multiplier</th>
                <th className={classes.cell}>Professional Multiplier</th>
                <th className={classes.cell}>Drug Multiplier</th>
                <th className={classes.cellVeryLarge}>Anesthesia Multiplier</th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>{planRows}</tbody>
          </table>
        )}
      </div>
    );
  }
}

Plans.defaultProps = {
  plans: undefined,
  loading: false,
};
Plans.propTypes = {
  plans: PropTypes.arrayOf(types.uiPlan),
  loading: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps({ reports }) {
  return {
    plans: reports.plans,
    loading: reports.loading,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Plans));
