import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import ConfigList from './ConfigList';
import MainConfig from './MainConfig';
import NewConfig from './NewConfig';
import NewTPA from './NewTPA';
import ConfigHeader from './ConfigHeader';
import SFTPServers from './SFTPServers';
import RepriceCodeMappings from './RepriceCodeMappings';
import PayerOverrides from './PayerOverrides';
import PatientCSVConfig from './PatientCSVConfig';
import Patient834Config from './Patient834Config';
import ChangeHistory from './ChangeHistory';

import { addSnackbar as addMessage } from '../../shared/components/snackbar/snackbarReducer';
import ClaimMessage from '../../shared/components/ClaimMessage';
import * as types from '../../shared/types/propTypes';

import { set, unSet, pushTo, removeFrom } from './mutationFunctions';

import {
  apiAllConfigs,
  apiGetConfig,
  apiNewConfig,
  apiSaveConfig,
  apiDeleteConfig,
  apiNewTPA,
} from './api';
import { defaultTPA } from './defaultTypes';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  configList: {
    width: '280px',
  },
  configContent: {
    marginLeft: '20px',
  },
  introText: {
    marginTop: '15px',
  },
  configFields: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export default function Config({ match, history }) {
  const [selectedConfig, setSelectedClientConfig] = useState(null);
  const [allClientConfigs, setAllClientConfigs] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [loading, setLoading] = useState(true);
  const [newOpen, setNewOpen] = useState(false);
  const [openTPADetail, setOpenTPADetail] = useState(false);
  const [newTPAOpen, setNewTPAOpen] = useState(false);
  const [tpaDetail, setTpaDetail] = useState(defaultTPA);
  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchConfig = useCallback(
    async configID => {
      try {
        const result = await apiGetConfig(configID);
        setSelectedClientConfig(result.data);
      } catch (error) {
        dispatch(addMessage(error.response.data));
      }
    },
    [dispatch]
  );

  const getConfig = useCallback(() => {
    setSelectedClientConfig(null);
    setLoading(true);
    const configID = match.params.id;

    if (configID) {
      fetchConfig(configID);
    }
    setLoading(false);
  }, [fetchConfig, match.params.id]);

  const fetchConfigs = useCallback(async () => {
    setSelectedClientConfig(null);
    try {
      const result = await apiAllConfigs();
      setAllClientConfigs(
        result.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      );
    } catch (error) {
      if (error.response) {
        dispatch(addMessage(error.response.data));
      } else if (error.request) {
        dispatch(addMessage(error.request));
      } else {
        dispatch(addMessage(error.message));
      }
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    setIsReadOnly(true);
    getConfig();
  }, [match.params.id, getConfig]);

  useEffect(() => {
    fetchConfigs();
  }, [fetchConfigs]);

  const saveNewConfig = async newConfig => {
    try {
      const result = await apiNewConfig(newConfig);
      const newID = result && result.data && result.data.id;
      if (newID !== undefined) {
        history.push(`/edi/configEditor/${newID}`);
        dispatch(addMessage('New config created!'));
      } else {
        dispatch(addMessage('Problem getting new ID from api'));
      }
    } catch (error) {
      // this call returns a 200 yet falls through to this error statement then exceptions out on the error.response.data == null somewhat predictably -- remove/fix when snackbar rewrite is done
      dispatch(addMessage(error.response.data));
    }
  };

  const saveNewTPA = async newTPA => {
    try {
      const result = await apiNewTPA(newTPA);

      if (result.status === 200) {
        const { data } = result;
        if (data) {
          setOpenTPADetail(!openTPADetail);
          setTpaDetail(data);
          dispatch(addMessage('New TPA created!'));
        }
      }
    } catch (error) {
      // Handle errors here
      dispatch(addMessage(error.response.data));
    }
  };

  const handleNewConfig = async newConfig => {
    setNewOpen(false);
    setIsReadOnly(true);
    await saveNewConfig(newConfig);
    fetchConfigs();
  };

  const handleNewTPA = async newTPA => {
    setNewTPAOpen(false);
    setIsReadOnly(true);
    await saveNewTPA(newTPA);
    fetchConfigs();
  };

  const saveConfig = async clientConfig => {
    try {
      await apiSaveConfig(clientConfig);
      dispatch(addMessage('Config successfully saved'));
    } catch (error) {
      dispatch(addMessage(error.response.data));
      // this call returns a 200 yet falls through to this error statement then exceptions out on the error.response.data == null somewhat predictably -- remove/fix when snackbar rewrite is done
      // dispatch(addMessage(error?.response?.data));
      getConfig();
    }
  };
  const deleteConfig = async clientConfig => {
    try {
      await apiDeleteConfig(clientConfig);
      dispatch(addMessage('Config successfully deleted'));
    } catch (error) {
      // this call returns a 200 yet falls through to this error statement then exceptions out on the error.response.data == null somewhat predictably -- remove/fix when snackbar rewrite is done
      // dispatch(addMessage(error?.response?.data));
      fetchConfigs();
    }
  };

  const handleSave = clientConfig => {
    saveConfig(clientConfig);
    setIsReadOnly(!isReadOnly);
  };

  const handleDeleteConfig = clientConfig => {
    deleteConfig(clientConfig);
  };

  const toggleNewConfig = () => {
    setNewOpen(!newOpen);
  };
  const toggleNewTPA = () => {
    setNewTPAOpen(!newTPAOpen);
  };
  const toggleCloseTPADetail = () => {
    setOpenTPADetail(!openTPADetail);
  };

  const toggleEditMode = () => {
    if (!isReadOnly) {
      getConfig();
    }
    setIsReadOnly(!isReadOnly);
  };

  const handleParentConfigChange = e => {
    const { value } = e.target;
    const newConfig = { ...selectedConfig };
    set(newConfig, 'parentID', value);
    setSelectedClientConfig(newConfig);
  };

  const handleMRFFileTypeChange = e => {
    const { value } = e.target;
    const newConfig = { ...selectedConfig };
    set(newConfig, 'mrfFileType', value);
    setSelectedClientConfig(newConfig);
  };

  const handleTextChange = field => e => {
    const { value } = e.target;
    const newConfig = { ...selectedConfig };
    set(newConfig, field, value);
    setSelectedClientConfig(newConfig);
  };

  const handleIntegerChange = field => e => {
    const value = parseInt(e.target.value, 10);
    const newConfig = { ...selectedConfig };
    set(newConfig, field, value);
    setSelectedClientConfig(newConfig);
  };

  const handleBoolChange = field => e => {
    const value = e.target.value === 'true';
    const newConfig = { ...selectedConfig };
    set(newConfig, field, value);
    setSelectedClientConfig(newConfig);
  };

  const handleSetObject = (field, value) => () => {
    const newConfig = { ...selectedConfig };
    set(newConfig, field, value);
    setSelectedClientConfig(newConfig);
  };

  const handleUnSetObject = field => () => {
    const newConfig = { ...selectedConfig };
    unSet(newConfig, field);
    setSelectedClientConfig(newConfig);
  };

  const handlePushToArray = (field, value) => () => {
    const newConfig = { ...selectedConfig };
    pushTo(newConfig, field, value);
    setSelectedClientConfig(newConfig);
  };

  const handleRemoveFromArray = (field, value) => () => {
    const newConfig = { ...selectedConfig };
    removeFrom(newConfig, field, value);
    setSelectedClientConfig(newConfig);
  };

  const patientCSVConfig = selectedConfig && selectedConfig.patientCSVConfig;
  const patient834Config = selectedConfig && selectedConfig.patient834Config;
  const sftpServers =
    selectedConfig && selectedConfig.sftpServers ? selectedConfig.sftpServers : null;
  const payerOverrides = selectedConfig && selectedConfig.payerOverrides;
  const repriceCodeMappings = selectedConfig && selectedConfig.repriceCodeMappings;
  const changeHistory = selectedConfig && selectedConfig.changeHistory;

  if (loading) {
    return <LoadingSpinner isLoading={loading} />;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.configList}>
          <ConfigList
            allClientConfigs={allClientConfigs}
            selectedID={match.params.id}
            toggleNewConfig={toggleNewConfig}
            toggleNewTPA={toggleNewTPA}
          />
        </div>
        <div className={classes.configContent}>
          <ConfigHeader
            selectedConfig={selectedConfig}
            isReadOnly={isReadOnly}
            toggleEditMode={toggleEditMode}
            handleSave={handleSave}
            handleDeleteConfig={handleDeleteConfig}
            handleSetObject={handleSetObject}
          />
          <div className={classes.configFields}>
            {!selectedConfig ? (
              <div className={classes.introText}>
                <ClaimMessage text="Select a Client Loader Config" />
              </div>
            ) : (
              <>
                <MainConfig
                  selectedConfig={selectedConfig}
                  isReadOnly={isReadOnly}
                  handleTextChange={handleTextChange}
                  handleIntegerChange={handleIntegerChange}
                  handleBoolChange={handleBoolChange}
                  allClientConfigs={allClientConfigs}
                  handleParentConfigChange={handleParentConfigChange}
                  handleMRFFileTypeChange={handleMRFFileTypeChange}
                />
                <SFTPServers
                  sftpServers={sftpServers}
                  isReadOnly={isReadOnly}
                  handleTextChange={handleTextChange}
                  handleBoolChange={handleBoolChange}
                  handlePushToArray={handlePushToArray}
                  handleRemoveFromArray={handleRemoveFromArray}
                  handleUnSetObject={handleUnSetObject}
                />
                <RepriceCodeMappings
                  repriceCodeMappings={repriceCodeMappings}
                  isReadOnly={isReadOnly}
                  handleSetObject={handleSetObject}
                  handleUnSetObject={handleUnSetObject}
                  handleTextChange={handleTextChange}
                  handleRemoveFromArray={handleRemoveFromArray}
                  handlePushToArray={handlePushToArray}
                />
                <PayerOverrides
                  payerOverrides={payerOverrides}
                  isReadOnly={isReadOnly}
                  handleTextChange={handleTextChange}
                  handleBoolChange={handleBoolChange}
                  handlePushToArray={handlePushToArray}
                  handleRemoveFromArray={handleRemoveFromArray}
                  handleUnSetObject={handleUnSetObject}
                />
                <PatientCSVConfig
                  patientCSVConfig={patientCSVConfig}
                  isReadOnly={isReadOnly}
                  handleIntegerChange={handleIntegerChange}
                  handleTextChange={handleTextChange}
                  handleBoolChange={handleBoolChange}
                  handlePushToArray={handlePushToArray}
                  handleRemoveFromArray={handleRemoveFromArray}
                  handleUnSetObject={handleUnSetObject}
                />
                <Patient834Config
                  patient834Config={patient834Config}
                  isReadOnly={isReadOnly}
                  handleTextChange={handleTextChange}
                  handleIntegerChange={handleIntegerChange}
                  handleBoolChange={handleBoolChange}
                  handleRemoveFromArray={handleRemoveFromArray}
                  handleUnSetObject={handleUnSetObject}
                />
                <ChangeHistory changeHistory={changeHistory} />
              </>
            )}
          </div>
        </div>
      </div>
      <NewConfig
        open={newOpen}
        toggleNewConfig={toggleNewConfig}
        handleNewConfig={handleNewConfig}
      />
      <NewTPA
        open={newTPAOpen}
        toggleNewTPA={toggleNewTPA}
        handleNewTPA={handleNewTPA}
        openTPADetail={openTPADetail}
        tpaDetail={tpaDetail}
        toggleCloseTPADetail={toggleCloseTPADetail}
      />
    </>
  );
}

Config.propTypes = {
  match: types.routerMatch.isRequired,
  history: types.history.isRequired,
};
