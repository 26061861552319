import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import * as types from '../types/propTypes';

const useStyles = makeStyles({
  inline: {
    display: 'inline',
  },
});

export default function BatchLinks(props) {
  const classes = useStyles();

  const { batches, isManual, variant } = props;
  if (!Array.isArray(batches)) return null;

  return (
    <Typography variant={variant}>
      <strong>Batches: </strong>
      {batches.map((batch, index) => (
        <span key={batch.batchID}>
          {index > 0 ? ', ' : ''}
          <Tooltip title={batch.date}>
            {isManual ? (
              <Typography id="manual-batches" className={classes.inline}>
                {batch.direction}
              </Typography>
            ) : (
              <Link id={`batch-${batch.direction}`} to={`/reports/batches/${batch.batchID}`}>
                {batch.direction}
              </Link>
            )}
          </Tooltip>
        </span>
      ))}
    </Typography>
  );
}

BatchLinks.defaultProps = {
  variant: 'subtitle1',
};

BatchLinks.propTypes = {
  batches: PropTypes.arrayOf(types.batch).isRequired,
  isManual: PropTypes.bool.isRequired,
  variant: PropTypes.string,
};
