import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import BatchList from './BatchList';
import BatchDetail from './BatchDetail';
import * as types from '../../shared/types/propTypes';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
});

export default function Batches({ match }) {
  const batchId = (match && match.params && match.params.id) || '';
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <BatchList batchId={batchId} baseURL="/reports/batches/" displaySearchBar />
      <BatchDetail batchId={batchId} />
    </div>
  );
}

Batches.propTypes = {
  match: types.routerMatch.isRequired,
};
