import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import ContractDetails from './ContractDetails';
import ContractMessage from './ContractMessage';
import NewContract from './NewContract';
import { ContractError } from './types/contracts';

const useStyles = makeStyles({
  loadingSpinner: {
    height: '75%',
    alignItems: 'center',
  },
});

type ParentProps = {
  contractError?: ContractError;
  isEdit: boolean;
  isLoading: boolean;
  isNew: boolean;
  selectedContractID: number;
};

type Props = ParentProps;

export default function MainContent({
  isLoading,
  isEdit,
  isNew,
  contractError,
  selectedContractID,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (isLoading) return <LoadingSpinner containerClassName={classes.loadingSpinner} />;

  if (contractError)
    return <ContractMessage title={contractError.title} message={contractError.message} visible />;

  if (isNew) return <NewContract />;

  if (selectedContractID === 0) return null;

  return <ContractDetails isEdit={isEdit} />;
}
