import React from 'react';
import PropTypes from 'prop-types';

import * as types from '../shared/types/propTypes';
import { formatDate, getAgeAtService, formatName, formatLocation } from '../shared/globals';

export default function Person({ details, earliestDt }) {
  if (!details) return null;
  return (
    <div>
      <div>{formatName(details.firstName, details.lastName)}</div>
      {details.dateOfBirth && earliestDt && (
        <div>
          <span style={{ paddingRight: '5px' }}>DOB: {formatDate(details.dateOfBirth)},</span>
          <span>Age: {getAgeAtService(details.dateOfBirth, earliestDt)}</span>
        </div>
      )}
      <div>{formatLocation(details)}</div>
    </div>
  );
}

Person.defaultProps = {
  details: undefined,
  earliestDt: '',
};
Person.propTypes = {
  details: types.person,
  earliestDt: PropTypes.string,
};
