import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { TopLevelCell } from '../chips/ChipBox';
import { insertItemIntoList } from '../contractUtilities';
import {
  ContractTerm,
  HandleTermValueChange,
  SetIsElementOpen,
  TermItem,
} from '../types/contracts';
import EditTermButtons from './EditTermButtons';
import TextFieldInput from './inputs/TextFieldInput';

const useStyles = makeStyles({
  termBox: {
    marginTop: '1em',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '0.35em',
  },
});

type Props = {
  currentValues: ContractTerm;
  isTermOpen: boolean;
  selectedTermItems: TermItem[];
  handleChange: HandleTermValueChange;
  handleSubmitNewTerm: VoidFunction;
  setIsElementOpen: SetIsElementOpen;
  handleSetSelectedTermItems: (validTermItems: TermItem[]) => void;
  cancelTerm: VoidFunction;
};

export default function OpenTextTerm({
  currentValues,
  isTermOpen,
  selectedTermItems,
  handleChange,
  handleSubmitNewTerm,
  setIsElementOpen,
  handleSetSelectedTermItems,
  cancelTerm,
}: Props): JSX.Element {
  const classes = useStyles();

  const addTerm: VoidFunction = () => {
    setIsElementOpen('openTerm', true);
    const newSelectedTermItems = insertItemIntoList(selectedTermItems, 'openFormatTextTerm');
    handleSetSelectedTermItems(newSelectedTermItems);
  };

  if (!isTermOpen)
    return (
      <div>
        <Button
          style={{ marginLeft: '9%', width: '100%' }}
          variant="contained"
          color="primary"
          onClick={addTerm}
          className={classes.termBox}
        >
          Add Open Text Term
        </Button>
      </div>
    );

  const isUpdateTerm = Boolean(currentValues.id);
  const TermIcon = isUpdateTerm ? EditIcon : AddCircleIcon;
  const termHeaderText = isUpdateTerm ? 'Edit Term' : 'New Term';

  return (
    <Card className={classes.termBox}>
      <TopLevelCell className={classes.top}>
        <TermIcon className={classes.icon} />
        {termHeaderText}
      </TopLevelCell>
      <div style={{ width: '98%' }}>
        <TextFieldInput
          value={currentValues.openFormatTextTerm || ''}
          handleChange={handleChange}
          fullWidth
          maxLength={150}
          id="openFormatTextTerm"
        />
      </div>
      <EditTermButtons handleSubmitNewTerm={handleSubmitNewTerm} cancelTerm={cancelTerm} />
    </Card>
  );
}
