import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { colors } from '@apps/shared/src/style';
import { MonthlyReportingDuplicateClaim } from '../types';
import DuplicatesTableRow from './DuplicatesTableRow';

const tableContainerOffset = '21rem';

const useStyles = makeStyles({
  container: {
    maxHeight: `calc(100vh - ${tableContainerOffset})`,
  },
  header: {
    backgroundColor: colors.white,
  },
  blankRow: {
    backgroundColor: colors.grey05,
    '&:last-child': {
      display: 'none',
    },
  },
});

type Column = {
  dataKey: keyof MonthlyReportingDuplicateClaim;
  label: string;
};

const columns: Column[] = [
  {
    dataKey: 'claimId',
    label: 'Link',
  },
  {
    dataKey: 'billingProvider',
    label: 'Billing Provider',
  },
  {
    dataKey: 'patientName',
    label: 'Patient Name',
  },
  {
    dataKey: 'dateOfService',
    label: 'Date of Service',
  },
  {
    dataKey: 'diagnosisCode',
    label: 'Diagnosis Code',
  },
  {
    dataKey: 'billedAmount',
    label: 'Billed Total',
  },
  {
    dataKey: 'repriceTotal',
    label: 'Repriced Amount',
  },
  {
    dataKey: 'keepDuplicate',
    label: 'Keep/Remove Claim',
  },
];

type Props = {
  duplicateGroups: MonthlyReportingDuplicateClaim[][];
};

export default function DuplicatesTable({ duplicateGroups }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} elevation={0} className={classes.container}>
      <Table stickyHeader aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell className={classes.header} align="center" scope="row" key={column.dataKey}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {duplicateGroups.map((group, groupIndex) => [
            group.map((row, rowIndex) => (
              <DuplicatesTableRow
                key={row.claimId}
                rowData={row}
                groupIndex={groupIndex}
                claimIndex={rowIndex}
              />
            )),
            <TableRow key={`blank-row-${group[0].claimId}`} className={classes.blankRow}>
              <TableCell colSpan={columns.length} />
            </TableRow>,
          ])}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
