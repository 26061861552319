import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import TPAFilter from './TPAFilter';
import NewPlan from './NewPlan';

const useStyles = makeStyles({
  header: {
    height: '70px',
    width: '100%',
  },
  controlArea: {
    marginTop: '10px',
    height: '100%',
    width: '780px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default function PlanEditorHeader(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.controlArea}>
        <NewPlan />
        <TPAFilter />
      </div>
    </div>
  );
}
