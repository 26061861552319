import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    display: 'none',
    marginBottom: '50px',
    '@media print': {
      display: 'block',
    },
  },
  header: {
    textAlign: 'center',
    marginBottom: '10px',
  },
});

export default function PDFTopText(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className="header" variant="h4">
        Settlement Report Summary
      </Typography>
    </div>
  );
}
