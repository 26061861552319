// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function debounce<F extends (...args: any) => any>(
  callback: F,
  wait: number
): (this: unknown, ...args: Parameters<F>) => void {
  let timeout: number, timer: number;
  if (!Number.isInteger(wait)) {
    // eslint-disable-next-line no-console
    console.warn('Called debounce without a valid number');
    timeout = 300;
  } else {
    timeout = wait;
  }

  return function debouncer(this: unknown, ...args: Parameters<F>): void {
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      callback.apply(this, args);
    }, timeout);
  };
}
