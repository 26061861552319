import React from 'react';
import PropTypes from 'prop-types';

import StorageIcon from '@material-ui/icons/Storage';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DnsIcon from '@material-ui/icons/Dns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import { defaultSFTPServer } from './defaultTypes';
import {
  TableCellHeaderTop,
  Column,
  addButtonStyle,
  deleteButtonStyle,
  configSectionStyle,
  configIconStyle,
} from './sharedConfig';

import * as types from '../../shared/types/propTypes';
import { validArray } from '../../shared/typeChecks';
import TextInput from '../../shared/components/TextInput';

const useStyles = makeStyles({
  configSection: configSectionStyle,
  configIcon: configIconStyle,
  addButton: addButtonStyle,
  deleteButton: deleteButtonStyle,
  claimInfoCell: {
    margin: '10px',
  },
  dropDown: {
    marginBottom: '20px',
  },
  dropDownHeader: {
    marginTop: '10px',
  },
});

export default function SFTPServers({
  sftpServers,
  handleTextChange,
  handleBoolChange,
  isReadOnly,
  handlePushToArray,
  handleRemoveFromArray,
  handleUnSetObject,
}) {
  const classes = useStyles();

  if (!sftpServers) {
    return null;
  }

  const validSftpServers = validArray(sftpServers);

  return (
    <Paper className={classes.claimInfoCell}>
      <TableCellHeaderTop>
        <Typography>
          <StorageIcon className={classes.configIcon} /> &nbsp; SFTP Servers
        </Typography>
        {!isReadOnly && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.deleteButton}
            onClick={handleUnSetObject('sftpServers')}
          >
            <DeleteForeverIcon /> &nbsp; SFTP Config
          </Button>
        )}
      </TableCellHeaderTop>
      {!isReadOnly && (
        <TableCellHeaderTop>
          <Button
            variant="contained"
            color="secondary"
            className={classes.addButton}
            onClick={handlePushToArray('sftpServers', defaultSFTPServer())}
          >
            <AddCircleIcon /> &nbsp; SFTP Server
          </Button>
        </TableCellHeaderTop>
      )}
      <Column className={classes.configSection}>
        {validSftpServers.map((sftpServer, i) => {
          return (
            <Paper className={classes.claimInfoCell} key={sftpServer}>
              <TableCellHeaderTop>
                <Typography>
                  <DnsIcon className={classes.configIcon} /> &nbsp; SFTP Server #{i + 1}
                </Typography>
                {!isReadOnly && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={handleRemoveFromArray('sftpServers', i)}
                  >
                    <HighlightOffIcon />
                  </Button>
                )}
              </TableCellHeaderTop>
              <Column>
                <TextInput
                  readOnly={isReadOnly}
                  label="PrivateKeyPath"
                  aria-label="PrivateKeyPath"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.privateKeyPath`)}
                  value={sftpServer.privateKeyPath}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="HostKeyPath"
                  aria-label="HostKeyPath"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.hostKeyPath`)}
                  value={sftpServer.hostKeyPath}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="Username"
                  aria-label="Username"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.username`)}
                  value={sftpServer.username}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="Password"
                  aria-label="Password"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.password`)}
                  value={sftpServer.password}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="ServerAndPort"
                  aria-label="ServerAndPort"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.serverAndPort`)}
                  value={sftpServer.serverAndPort}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="OutgoingFolder"
                  aria-label="OutgoingFolder"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.outgoingFolder`)}
                  value={sftpServer.outgoingFolder}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="IncomingFolder"
                  aria-label="IncomingFolder"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.incomingFolder`)}
                  value={sftpServer.incomingFolder}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="ReportsFolder"
                  aria-label="ReportsFolder"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.reportsFolder`)}
                  value={sftpServer.reportsFolder}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="MRFFolder"
                  aria-label="MRFFolder"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.mrfFolder`)}
                  value={sftpServer.mrfFolder}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="CashPrePayFolder"
                  aria-label="CashPrePayFolder"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.cashPrePayFolder`)}
                  value={sftpServer.cashPrePayFolder}
                />
                <TextInput
                  readOnly={isReadOnly}
                  label="FileTypeRestriction"
                  aria-label="FileTypeRestriction"
                  fullWidth
                  onChange={handleTextChange(`sftpServers.${i}.fileTypeRestriction`)}
                  value={sftpServer.fileTypeRestriction}
                />
                <Typography className={classes.dropDownHeader}>DisableReceive:</Typography>
                <FormControl className={classes.dropDown}>
                  <Select
                    autoWidth
                    readOnly={isReadOnly}
                    label="DisableReceive"
                    aria-label="DisableReceive"
                    onChange={handleBoolChange(`sftpServers.${i}.disableReceive`)}
                    value={sftpServer.disableReceive ? 'true' : 'false'}
                  >
                    <MenuItem key={0} value="true">
                      True
                    </MenuItem>
                    <MenuItem key={1} value="false">
                      False
                    </MenuItem>
                  </Select>
                </FormControl>
                <Typography className={classes.dropDownHeader}>DisableSend:</Typography>
                <FormControl className={classes.dropDown}>
                  <Select
                    autoWidth
                    readOnly={isReadOnly}
                    label="DisableSend"
                    aria-label="DisableSend"
                    onChange={handleBoolChange(`sftpServers.${i}.disableSend`)}
                    value={sftpServer.disableSend ? 'true' : 'false'}
                  >
                    <MenuItem key={0} value="true">
                      True
                    </MenuItem>
                    <MenuItem key={1} value="false">
                      False
                    </MenuItem>
                  </Select>
                </FormControl>

                <Typography className={classes.dropDownHeader}>UseNonPreferredCiphers:</Typography>
                <FormControl className={classes.dropDown}>
                  <Select
                    autoWidth
                    readOnly={isReadOnly}
                    label="UseNonPreferredCiphers"
                    aria-label="UseNonPreferredCiphers"
                    onChange={handleBoolChange(`sftpServers.${i}.useNonPreferredCiphers`)}
                    value={sftpServer.useNonPreferredCiphers ? 'true' : 'false'}
                  >
                    <MenuItem key={0} value="true">
                      True
                    </MenuItem>
                    <MenuItem key={1} value="false">
                      False
                    </MenuItem>
                  </Select>
                </FormControl>
              </Column>
            </Paper>
          );
        })}
      </Column>
    </Paper>
  );
}

SFTPServers.defaultProps = {
  sftpServers: [],
};

SFTPServers.propTypes = {
  sftpServers: PropTypes.arrayOf(types.sftpServer),
  isReadOnly: PropTypes.bool.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleBoolChange: PropTypes.func.isRequired,
  handlePushToArray: PropTypes.func.isRequired,
  handleRemoveFromArray: PropTypes.func.isRequired,
  handleUnSetObject: PropTypes.func.isRequired,
};
