import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: theme.typography.body2,
}));

type Props = Pick<DialogProps, 'open'> & {
  onClose: () => void;
};

export default function AdvancedBatchesHelpDialog({ open, onClose }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <DialogTitle>Advanced batches report instructions</DialogTitle>
      <DialogContent className={classes.content} dividers>
        <Typography>
          <strong>Minimum search criteria</strong>
        </Typography>
        <p>
          Search must contain at least one of the following: TPA, plan, filename/error text, or
          client name. Try theses options:
        </p>
        <ul>
          <li>Filename/error</li>
          <li>TPA and date range</li>
          <li>Plan and date range</li>
          <li>Client name and date range</li>
        </ul>
        <p>
          If there is a TPA, plan, or client name selected, a date range is required, and has to be
          within 60 days.
        </p>
        <p>If a TPA is selected, only plans belonging to that TPA can be selected.</p>
        <p>Only batches with claims are searchable by TPA or plan.</p>
        <p>The number of results returned is limited to 400.</p>
        <Typography>
          <strong>How filename/error search works</strong>
        </Typography>
        <p>
          If there is a TPA or plan selected: The search text is matched on filename only, and the
          filename has to include the whole search text.
        </p>
        If there is no TPA or plan selected:
        <ul>
          <li>
            The search text is separated by spaces and punctuation (including hyphens). Then if any
            part of the search text matches with any part of the filename or error text in our
            database, it counts as a file match. Ex: <em>SOMEFILE.837.999</em> will match with all
            files named <em>SOMEFILE</em>, all files with <em>SOMEFILE</em> in the error text, all
            837s, and all 999s
          </li>
          <li>
            To find a specific file, avoid having spaces or punctuation in the search text.
            Alternatively, you can add quotes around the search text to find an exact match. Ex:{' '}
            <em>SOMEFILE</em> or <em>&quot;SOMEFILE.837.999&quot;</em>
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
