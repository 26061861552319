import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { dateRangeToText } from '../contractUtilities';

const useStyles = makeStyles({
  dateRange: {
    fontSize: '.85em',
  },
});

type Props = {
  start: string;
  end: string;
};
export default function DisplayDateRange({ start, end }: Props): JSX.Element {
  const classes = useStyles();

  return <span className={classes.dateRange}>{dateRangeToText(start, end)}</span>;
}
