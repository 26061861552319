import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  card: {
    background: 'white',
    fontFamily: 'Roboto, sans-serif',
    padding: '30px',
    maxWidth: '350px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '230px',
    textAlign: 'justify',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    },
  },
  passwordExpireHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    borderBottom: '1px solid #A8A8A8',
  },
  p: {
    cursor: 'pointer',
    color: '#A8A8A8',
    fontWeight: '700',
    marginLeft: '4%',
    fontSize: '18px',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  },
}));

const SessionTimeoutModal = ({ classes, stayLoggedIn }) => {
  const [timer, setTimer] = useState(60);
  const rootClass = useStyles();
  const history = useHistory();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(seconds => seconds - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(intervalId);
      history.push('/logout');
    }

    return () => clearInterval(intervalId);
  }, [timer]);

  const logOff = () => {
    history.push('/logout');
  };

  return (
    <div className={clsx(rootClass.card, classes?.card)}>
      <div className={rootClass.passwordExpireHead}>
        <h2>Session Timeout</h2>
        <div
          role="button"
          tabIndex={0}
          onClick={stayLoggedIn}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              stayLoggedIn();
            }
          }}
          className={rootClass.p}
        >
          x
        </div>
      </div>
      <p>
        You&#39;re being timed out due to inactivity. Please choose to stay signed in or to log off.
        Otherwise, you&#39;ll be logged out automatically.
      </p>
      <div className={rootClass.btnWrapper}>
        <Button onClick={logOff} variant="outlined">
          Log Off
        </Button>
        <Button onClick={stayLoggedIn} variant="contained" color="primary">
          Stay Logged In({timer})
        </Button>
      </div>
    </div>
  );
};

export default SessionTimeoutModal;
