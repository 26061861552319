import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { inflateString } from '@apps/shared/src/inflators';
import TooltippedChip from './TooltippedChip';
import { pluralize } from '../contractUtilities';
import { ContractRoster, ContractProvider } from '../types/contracts';
import { isArrayFilled } from '../../shared/typeChecks';

export function generateProviderText(
  providers: ContractProvider[],
  providerCount?: number
): string {
  if (isArrayFilled(providers))
    return `${providers.length} ${pluralize('Provider', providers.length)}`;
  if (providerCount) return `${providerCount} ${pluralize('Provider', providerCount)}`;
  return 'No providers in roster';
}

function generateChipText(name: string, providerText: string): string {
  return `${inflateString(name)}${providerText && ` - ${providerText}`}`;
}

type Props = {
  isEdit: boolean;
  isSelected: boolean;
  item: ContractRoster;
  handleRemove: (id: number) => void;
  onClick: (id: number) => void;
};

export default function RosterChip({
  isEdit,
  handleRemove,
  item: roster,
  onClick,
  isSelected,
}: Props): JSX.Element {
  const { id, name, providers, providerCount } = roster;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = isEdit ? (): void => handleRemove(id) : undefined;
  const handleClick = (): void => onClick(roster.id);

  const providerText = generateProviderText(providers, providerCount);
  const chipText = generateChipText(name, providerText);

  const handleCloseModal = (): void => setIsModalOpen(false);
  const handleOpenModal = isEdit ? (): void => setIsModalOpen(true) : undefined;

  return (
    <>
      <TooltippedChip
        chipKey={id}
        tooltip=""
        chipText={chipText}
        onDelete={handleOpenModal}
        onClick={handleClick}
        isSelected={isSelected}
      />

      <Dialog open={isModalOpen} onClose={handleCloseModal} aria-describedby="delete-roster-alert">
        <DialogContent>
          <DialogContentText id="delete-roster-alert">
            Are you sure you want to delete roster {name} with {providerCount}&nbsp;
            {pluralize('provider', providers.length)}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            CANCEL
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            DELETE ROSTER
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
