import axios from 'axios';

export async function apiAllConfigs() {
  const setup = {
    headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
  };
  const result = await axios.get(`/api/getClientConfigs`, setup);
  return result;
}

export async function apiGetConfig(configID) {
  const setup = {
    method: 'get',
    url: `/api/configEditor/${configID}`,
    headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
  };

  const result = await axios(setup);
  return result;
}
export async function apiDeleteConfig(configID) {
  const setup = {
    method: 'delete',
    url: `/api/configEditor/${configID}`,
    headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
  };

  const result = await axios(setup);
  return result;
}

export async function apiNewConfig(newConfig) {
  const setup = {
    method: 'post',
    url: `/api/createClientConfig`,
    headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    data: newConfig,
  };

  const result = await axios(setup);
  return result;
}

export async function apiNewTPA(newTPA) {
  const setup = {
    method: 'post',
    url: `/api/createTPA`,
    headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    data: newTPA,
  };

  const result = await axios(setup);
  return result;
}

export async function apiSaveConfig(clientConfig) {
  const setup = {
    method: 'post',
    url: `/api/updateClientConfig`,
    headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    data: clientConfig,
  };
  await axios(setup);
}
