export function determineStartDateErrorText(startDateISO: string, endDateISO: string): string {
  const startDate = new Date(startDateISO);
  const endDate = new Date(endDateISO);
  const today = new Date();

  if (startDate > today) {
    return 'Invalid start date';
  }
  if (endDateISO !== '' && startDate > endDate) {
    return 'Start date after end date';
  }
  if (
    (endDateISO === '' && today.getTime() - startDate.getTime() > 604800000) ||
    endDate.getTime() - startDate.getTime() > 604800000
  ) {
    return 'Please limit query to 1 week';
  }

  return '';
}

export function determineEndDateErrorText(startDateISO: string, endDateISO: string): string {
  const startDate = new Date(startDateISO);
  const endDate = new Date(endDateISO);
  const today = new Date();

  if (endDate > today) {
    return 'Invalid end date';
  }
  if (endDate < startDate) {
    return 'End date before start date';
  }
  if (startDateISO === '' && endDateISO !== '') {
    return 'Please select a start date';
  }
  if (endDate.getTime() - startDate.getTime() > 604800000) {
    return 'Please limit query to 1 week';
  }
  return '';
}

export function buildFilename(name: string, startDateISO: string, endDateISO: string): string {
  let filename = name;
  if (startDateISO !== '') {
    filename += endDateISO === '' ? ` starting ${startDateISO}` : ` from ${startDateISO}`;
  }
  if (endDateISO !== '') {
    filename += ` through ${endDateISO}`;
  }
  if (startDateISO === '' && endDateISO === '') {
    filename += ` before ${new Date().toISOString().slice(0, 10)}`;
  }
  return filename;
}
