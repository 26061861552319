import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import HandlersIcon from '@material-ui/icons/People';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { colors } from '@apps/shared/src/style';
import { splitCamelCase } from '@apps/shared/src/utilities';
import StatusButton from './StatusButton';
import JSONViewer from '../shared/components/JSONViewer';
import HandlersTooltip from '../shared/components/handlers/HandlersTooltip';
import QueueNavigator from '../shared/components/QueueNavigator';
import IconButtonCompact from '../shared/components/IconButtonCompact';
import FlaggedOnHold from '../shared/components/FlaggedOnHold';
import ClaimFooterItem from './ClaimFooterItem';
import * as types from '../shared/types/propTypes';
import { validArray } from '../shared/typeChecks';
import BatchLinks from '../shared/components/BatchLinks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem',
    height: theme.footerHeight.full,
    borderTop: `1px solid ${colors.grey05}`,
  },
  group: {
    display: 'flex',
  },
  red: {
    color: colors.red,
  },
  owner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editIcon: {
    marginLeft: '-8px',
    marginRight: '4px',
  },
  greenButton: {
    color: colors.green,
  },
}));

export function checkClaimProps({
  x12DocType,
  prodOrTest,
  batches,
  owner,
  handlers,
  senderName,
  isExported,
  simpleClaim,
}) {
  return {
    isManual: x12DocType === 'manual',
    isTest: prodOrTest === 'T',
    hasBatches: validArray(batches).length > 0,
    hasOwner: Boolean(owner && owner.fullName),
    hasHandlers: Boolean(
      handlers && Object.values(handlers).some(h => h.fullName && h.fullName.length > 0)
    ),
    hasSender: Boolean(senderName),
    isExported: Boolean(isExported),
    hasClaimFile: Boolean(simpleClaim?.claimFile),
  };
}

function ClaimFooter(props) {
  const {
    selectedClaim,
    claimInList,
    isFirstClaim,
    isLastClaim,
    isAdjud,
    isStatusUpdatePending,
    handleExportClick,
    handleFlaggedClick,
    handleOnHoldClick,
    handleNavigatePrev,
    handleNavigateNext,
    handleGetClaimFileClick,
    handleEditClaim,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMedOrLess = useMediaQuery(theme.breakpoints.down('md'));

  const [isExported, setIsExported] = useState(false);

  useEffect(() => {
    if (selectedClaim) {
      setIsExported(selectedClaim.isExported);
    }
  }, [selectedClaim]);

  if (!selectedClaim || typeof selectedClaim !== 'object' || !selectedClaim.id) return null;

  const { status, batches, senderName, owner, handlers } = selectedClaim;
  const { isManual, isTest, hasBatches, hasOwner, hasHandlers, hasSender, hasClaimFile } =
    checkClaimProps(selectedClaim);

  const typographyVariant = isMedOrLess ? 'subtitle2' : 'subtitle1';

  const buttonClass = isExported ? classes.greenButton : '';

  return (
    <div className={classes.root}>
      <div className={classes.group}>
        <ClaimFooterItem>
          <IconButtonCompact
            id="download-excel"
            tooltip="Export to Excel"
            onClick={handleExportClick}
          >
            <GetAppIcon className={buttonClass} />
          </IconButtonCompact>
          <JSONViewer selectedClaim={selectedClaim} />
        </ClaimFooterItem>

        <ClaimFooterItem divider="left" visible={isManual}>
          <IconButtonCompact
            id="pencil"
            tooltip="Edit"
            className={classes.editIcon}
            onClick={handleEditClaim}
          >
            <EditIcon />
          </IconButtonCompact>
          <Typography
            id={`claim-origin-${!isTest && !hasBatches && !hasSender ? 'manual' : ''}`}
            variant={typographyVariant}
            className={classes.red}
          >
            <strong>Manual Claim</strong>
          </Typography>
        </ClaimFooterItem>

        <ClaimFooterItem divider="left" visible={isTest}>
          <Typography id="claim-origin-test" variant={typographyVariant} className={classes.red}>
            <strong>Test Claim</strong>
          </Typography>
        </ClaimFooterItem>

        <ClaimFooterItem divider="left" visible={hasBatches}>
          <BatchLinks batches={batches} isManual={isManual} variant={typographyVariant} />
        </ClaimFooterItem>

        <ClaimFooterItem divider="left">
          <Typography variant={typographyVariant} onClick={handleGetClaimFileClick}>
            <Link to={`/repricing/${selectedClaim.id}`}>
              {selectedClaim.simpleClaim.claimFile.OriginalFilename}
            </Link>
          </Typography>
        </ClaimFooterItem>

        <ClaimFooterItem divider="left" visible={hasSender}>
          <Typography id="sender" variant={typographyVariant}>
            <strong>Sender:</strong> {senderName}
          </Typography>
        </ClaimFooterItem>
      </div>

      <div className={classes.group}>
        <ClaimFooterItem visible={hasOwner}>
          <Typography variant={typographyVariant} className={classes.owner}>
            <strong>Owner:</strong> {owner && owner.fullName}
          </Typography>
        </ClaimFooterItem>

        <ClaimFooterItem divider="right" visible={hasHandlers} id="handlers-icon">
          <IconButtonCompact id="handlers" tooltip={<HandlersTooltip handlers={handlers} />}>
            <HandlersIcon />
          </IconButtonCompact>
        </ClaimFooterItem>

        <ClaimFooterItem>
          <Typography id="claim-status" variant={typographyVariant}>
            <strong>Status:</strong> {splitCamelCase(status)}
          </Typography>
        </ClaimFooterItem>
        <ClaimFooterItem>
          <StatusButton
            claim={selectedClaim}
            isAdjud={isAdjud}
            isStatusUpdatePending={isStatusUpdatePending}
            handleFlaggedClick={handleFlaggedClick}
            handleOnHoldClick={handleOnHoldClick}
          />
        </ClaimFooterItem>

        <ClaimFooterItem>
          <FlaggedOnHold
            status={status}
            isStatusUpdatePending={isStatusUpdatePending}
            handleFlaggedClick={handleFlaggedClick}
            handleOnHoldClick={handleOnHoldClick}
          />
        </ClaimFooterItem>

        <ClaimFooterItem divider="left" visible={claimInList}>
          <QueueNavigator
            isFirstClaim={isFirstClaim}
            isLastClaim={isLastClaim}
            handleNavigatePrev={handleNavigatePrev}
            handleNavigateNext={handleNavigateNext}
          />
        </ClaimFooterItem>
      </div>
    </div>
  );
}

ClaimFooter.defaultProps = {
  selectedClaim: {},
};
ClaimFooter.propTypes = {
  selectedClaim: types.claim,
  claimInList: PropTypes.bool.isRequired,
  isFirstClaim: PropTypes.bool.isRequired,
  isLastClaim: PropTypes.bool.isRequired,
  isAdjud: PropTypes.bool.isRequired,
  isStatusUpdatePending: PropTypes.bool.isRequired,
  handleExportClick: PropTypes.func.isRequired,
  handleGetClaimFileClick: PropTypes.func.isRequired,
  handleFlaggedClick: PropTypes.func.isRequired,
  handleOnHoldClick: PropTypes.func.isRequired,
  handleNavigatePrev: PropTypes.func.isRequired,
  handleNavigateNext: PropTypes.func.isRequired,
  handleEditClaim: PropTypes.func.isRequired,
};

export default ClaimFooter;
