import { newPersonOrOrg, individualOrOrgReducer } from './indivOrOrgReducer';
import {
  newUIService,
  servicesReducer,
  serviceItemReducer,
  VALIDATE_PROFESSIONAL,
} from './servicesReducer';
import {
  required,
  updateAndValidate,
  datesInOrder,
  isBeforeNow,
  validate,
  validateAll,
  checkTypeOfBill,
  checkDate,
} from '../shared/validators';
import { getPlanName } from '../shared/globals';
import { UPDATE_VALUE, VALIDATE } from './rootReducer';
import { newProvider } from './providerReducer';

export function updatePolicyNumber(
  path,
  policyNum,
  sixDegTPAID,
  sixDegPlanID,
  plans,
  earliestDate
) {
  const planName = getPlanName(policyNum, sixDegTPAID, sixDegPlanID, plans, earliestDate);
  return dispatch => {
    dispatch(updateClaimValue(path, 'sixDegTPAID', sixDegTPAID));
    dispatch(updateClaimValue(path, 'policyNum', policyNum.toUpperCase()));
    dispatch(updateClaimValue(path, 'planName', planName));
    dispatch(updateClaimValue(path, 'sixDegPlanID', sixDegPlanID));
  };
}

export function updateClaimValue(path, name, value) {
  return { type: `${path}/${UPDATE_VALUE}`, payload: { name, value } };
}

export const claimsReducer = (state, action) => {
  switch (action.type) {
    case VALIDATE:
      return state.map(i => claimItemReducer(i, { type: VALIDATE }));

    default:
      return state;
  }
};

const errorTextGetter = {
  typeOfBill: state => getTypeOfBillErrMsg(state),
  resubmissionCode: state => getResubCodeErrMsg(state),
  refNum: state => required(state.refNum),
  policyNum: state => required(state.policyNum),
  diagnoses: state => getDiagnosesErrMsg(state),
  principalDiagnoses: state => getPrincipalDiagnosesErrMsg(state),
  statementFromDate: state => getStatementDateErrMsg('statementFromDate', state),
  statementToDate: state => getStatementDateErrMsg('statementToDate', state),
};

function getTypeOfBillErrMsg(state) {
  return state.formType !== 'B' ? checkTypeOfBill(state.typeOfBill) : '';
}

function getResubCodeErrMsg(state) {
  return state.resubmissionCode.length > 1 ? 'Must be empty or 1-digit code' : '';
}

function getDiagnosesErrMsg(state) {
  return required(state.diagnoses) || !/^[a-z,A-Z,\d,\s]+$/.test(state.diagnoses)
    ? '1 or more codes'
    : '';
}

function getPrincipalDiagnosesErrMsg(state) {
  return required(state.principalDiagnoses) || !/^[a-z,A-Z,\d,\s]+$/.test(state.principalDiagnoses)
    ? '1 or more codes'
    : '';
}

const getStatementDateErrMsg = (fieldName, state) => {
  if (state.formType === 'B') return '';
  if (state[fieldName] === '') return 'Required';
  const [isValid, , checkError] = checkDate(state[fieldName]);
  if (!isValid) return checkError;
  const beforeError = isBeforeNow(state[fieldName]);
  if (beforeError !== '') return beforeError;

  switch (fieldName) {
    case 'statementFromDate':
      return state.statementToDate !== '' &&
        !datesInOrder(state.statementFromDate, state.statementToDate)
        ? 'Start must be before end'
        : '';
    case 'statementToDate':
      return state.statementFromDate !== '' &&
        !datesInOrder(state.statementFromDate, state.statementToDate)
        ? 'End must be after start'
        : '';
    default:
      return '';
  }
};

export const parseTypeOfBill = (typeOfBill, formType) => {
  if (formType === 'B') return 'P'; // Professional
  if (!typeOfBill || typeOfBill.length !== 3) return undefined;
  if (formType === 'A') {
    const facilityClass = typeOfBill.charAt(1);
    if (facilityClass === '3') return 'O'; // Outpatient
    return 'I'; // Inpatient
  }
  return undefined;
};

export const newClaim = () => {
  return {
    formType: 'B',
    typeOfBill: '',
    resubmissionCode: '',
    patient: newPersonOrOrg(false),
    policyNum: '',
    planName: '',
    sixDegTPAID: undefined,
    refNum: '',
    billAmt: 0,
    drg: '',
    diagnoses: '',
    admittingDiagnoses: '',
    principalDiagnoses: '',
    statementFromDate: '',
    statementToDate: '',
    services: [{ ...newUIService() }],
    subscriber: newPersonOrOrg(true),
    serviceLocation: newProvider(),
    claimFile: undefined,
  };
};

export const claimItemReducer = (state = newClaim(), action) => {
  switch (action.type) {
    case VALIDATE: {
      const services = servicesReducer(state.services, { type: VALIDATE });
      if (state.formType === 'B') {
        services[0] = serviceItemReducer(services[0], {
          type: VALIDATE_PROFESSIONAL,
        });
      }
      return {
        ...validateAll(state, errorTextGetter),
        patient: individualOrOrgReducer(state.patient, { type: VALIDATE }),
        services,
        subscriber: individualOrOrgReducer(state.subscriber, { type: VALIDATE }),
      };
    }
    case UPDATE_VALUE: {
      const { name, value } = action.payload;
      const newState = updateAndValidate(state, name, value, errorTextGetter[name], true);
      const { statementToDate, statementFromDate, errors } = newState;

      if (name === 'statementFromDate' && statementToDate)
        errors.statementToDate = validate(errorTextGetter.statementToDate, newState);
      else if (name === 'statementToDate' && statementFromDate)
        errors.statementFromDate = validate(errorTextGetter.statementFromDate, newState);

      return newState;
    }
    default:
      return state;
  }
};

export default claimItemReducer;
