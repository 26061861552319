import React, { useState, useEffect } from 'react';

import { Typography, Button, IconButton, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Input from '@material-ui/core/Input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Modal from '@material-ui/core/Modal';
import { closeModal } from '../fileUploadModalReducer';

const useStyles = makeStyles({
  modalOverlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    padding: '1%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    background: 'white',
    padding: '10px',
    borderRadius: '5px',
    width: '35vw',
    maxWidth: '100%',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'right',
  },
  modalBody: {
    padding: '0 2vw 2vw 2vw',
  },
  titleSubText: {
    color: '#838181',
    marginTop: '5px',
  },
  modelSubText: {
    color: '#C01F1A',
    margin: '3vh 0',
  },
  uploadArea: {
    marginTop: '10px',
    border: '2px dashed #ccc',
    padding: '20px',
    borderRadius: '20px',
    borderColor: '#366BED',
    cursor: 'pointer',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dndArea: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  browseArea: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadLabel: {
    margin: '1vw',
    color: '#494C54',
  },
  modalFooter: {
    display: 'grid',
    justifyItems: 'stretch',
    gridRow: '2',
    padding: '0 2vw 2vw 2vw',
    gridAutoFlow: 'column',
    gap: '2vw',
  },
  uploadButton: {
    backgroundColor: '#888787',
    color: '#FFFFFF',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#888787',
  },
  greyText: {
    color: '#888787',
  },
  VisuallyHiddenInput: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  },
  selectFileButton: {
    display: 'none',
  },
  slectedFile: {
    marginTop: '10px',
    color: '#888787',
  },
});

export default function FileUploadModal({
  open,
  toggleFileUpload,
  handleFileUpload,
  selectedFile,
  setSelectedFile,
  claimType,
  setClaimType,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
    toggleFileUpload(false); // Close the modal using the prop function
  };

  const handleFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      handleClose();
    }
    handleClose();
  };

  const handleDrop = event => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setSelectedFile(event.dataTransfer.files[0]);
      // setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleFileTypeChange = event => {
    setClaimType(event.target.value);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    handleClose();
  };

  return (
    <Modal open={open}>
      <div className={classes.modalOverlay}>
        <div className={classes.modalContent}>
          <div className={classes.modalHeader}>
            {/* <div className={classes.modalHeaderClose}> */}
            <IconButton onClick={handleRemoveFile} size="medium">
              <span>&times;</span>
            </IconButton>
            {/* <Button onClick={handleClose}>X</Button> */}
            {/* </div> */}
          </div>
          <div className={classes.modalBody}>
            <Typography variant="h5">Upload File</Typography>
            <Typography className={classes.titleSubText}>
              Please upload a UBO4 or HCFA claim Form
            </Typography>
            <div className={classes.modelSubText}>
              <Typography>* .pdf is the supported file format.</Typography>
              <Typography>* File size should be less than 2MB.</Typography>
            </div>
            <div className={classes.claimFileType}>
              {/* <Typography>* Please select the type of file you want to upload</Typography> */}
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  * Please select the type of file you want to upload
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={claimType}
                  onChange={handleFileTypeChange}
                >
                  <FormControlLabel value="UBO4" control={<Radio disabled />} label="UBO4" />
                  <FormControlLabel value="HCFA" control={<Radio disabled />} label="HCFA" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={classes.uploadArea}>
              <div
                className={classes.dndArea}
                onDrop={handleDrop}
                onDragOver={event => event.preventDefault()}
              >
                <img src="/images/upload-button.png" alt="upload file icon" />

                <Typography> Drag & Drop your files here</Typography>
                <br />
                <Typography className={classes.greyText}> OR </Typography>
              </div>
              <div className={classes.browseArea}>
                <Button color="primary" variant="contained" component="label">
                  <Input
                    type="file"
                    className={classes.selectFileButton}
                    onChange={handleFileChange}
                  />
                  Browse Files
                </Button>
              </div>
              {/* {selectedFile && (
                <div className={classes.slectedFile}>
                  <Typography> {selectedFile.name}
                  <IconButton onClick={handleRemoveFile} size="medium">
                    <span>&times;</span>
                  </IconButton></Typography>
                </div>
              )} */}
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button
              variant="outlined"
              disableElevation
              size="medium"
              className={classes.cancelButton}
              onClick={handleRemoveFile}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              size="medium"
              className={classes.uploadButton}
              onClick={handleUpload}
              disabled={!selectedFile}
            >
              Upload
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

FileUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleFileUpload: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  selectedFile: PropTypes.any,
  setSelectedFile: PropTypes.any,
  claimType: PropTypes.string,
  setClaimType: PropTypes.any,
};
