import sha1 from 'crypto-js/sha1';
import axios from 'axios';

export const checkCompromisedPassword = async password => {
  // Step 1: Hash the password using SHA-1
  const hashedPassword = sha1(password).toString().toUpperCase();

  // Step 2: Get the first 5 characters of the hashed password
  const prefixHash = hashedPassword.slice(0, 5);
  const suffixHash = hashedPassword.slice(5);

  try {
    // Step 3: Call the HIBP API with the prefix
    const response = await axios.get(`https://api.pwnedpasswords.com/range/${prefixHash}`);

    // Step 4: Parse the response
    const matchingSuffixes = response.data.split('\n');

    // Step 5: Check if the suffix exists in the API response
    const isCompromised = matchingSuffixes.some(suffix => suffix.startsWith(suffixHash));

    return isCompromised;
  } catch (error) {
    console.error('Error checking password:', error);
    return false;
  }
};
