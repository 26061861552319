import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import { colors } from '@apps/shared/src/style';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

const useStyles = makeStyles(() => ({
  saveProvider: {
    margin: '1em',
  },
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
  },
  isSaved: {
    color: colors.success,
    display: 'flex',
  },
}));

type Props = {
  addOrUpdateRoster: VoidFunction;
  handleCancelChanges: VoidFunction;
  isRosterLoading: boolean;
  hasUnsavedRosterChanges: boolean;
  isNewRoster: boolean;
};

export default function SCAProviderFooter({
  addOrUpdateRoster,
  handleCancelChanges,
  isRosterLoading,
  hasUnsavedRosterChanges,
  isNewRoster,
}: Props): JSX.Element {
  const classes = useStyles();

  const showSavedIndicator = !(hasUnsavedRosterChanges || isNewRoster || isRosterLoading);

  return (
    <div className={classes.cardFooter}>
      <Button
        className={classes.saveProvider}
        variant="outlined"
        color="primary"
        onClick={handleCancelChanges}
        disabled={isRosterLoading || !hasUnsavedRosterChanges}
      >
        Cancel Changes
      </Button>
      <Button
        className={classes.saveProvider}
        variant="contained"
        color="primary"
        onClick={addOrUpdateRoster}
        disabled={isRosterLoading || !hasUnsavedRosterChanges}
      >
        Save Providers
      </Button>
      <LoadingSpinner isLoading={isRosterLoading} size={30} unstyledContainer />
      {showSavedIndicator && (
        <div className={classes.isSaved}>
          <CheckCircleIcon />
          <Typography>Saved</Typography>
        </div>
      )}
    </div>
  );
}
