import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import DateInput from '../../manualClaim/DateInput';
import SelectAgingSwitch from './SelectAgingSwitch';

const useStyles = makeStyles({
  buttonIcon: {
    marginLeft: '.25em',
  },
  dateFilter: {
    marginTop: '1.5em',
  },
});

export default function FilterReportsByTPAButton({
  allTPAs,
  selectedTPAs,
  updateSelectedTPAs,
  showOnlyAging,
  toggleShowOnlyAging,
  formatStartDate,
  formatEndDate,
  startDate,
  endDate,
  startDateErr,
  endDateErr,
}) {
  const classes = useStyles();
  const [tpaAnchor, setTPAanchor] = useState(null);

  const handleTPAClick = e => {
    setTPAanchor(e.currentTarget);
  };

  const handleTPAMenuClose = () => setTPAanchor(null);

  const isAllSelected = allTPAs.length === selectedTPAs.length || selectedTPAs.length === 0;

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleTPAClick}>
        {selectedTPAs.length > 0 && <CheckBoxIcon style={{ marginRight: '0.5em' }} />}
        Filter Claims
        {tpaAnchor ? (
          <KeyboardArrowUp className={classes.buttonIcon} />
        ) : (
          <KeyboardArrowDown className={classes.buttonIcon} />
        )}
      </Button>
      <Popover
        open={Boolean(tpaAnchor)}
        onClose={handleTPAMenuClose}
        anchorEl={tpaAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FormGroup style={{ padding: '1em' }}>
          <FormControlLabel
            style={{ height: '1.5rem' }}
            control={<Checkbox value="All" checked={isAllSelected} onChange={updateSelectedTPAs} />}
            label={<Typography variant="body2">All TPAs</Typography>}
          />
          {allTPAs.map(tpa => (
            <FormControlLabel
              style={{ height: '1.5rem' }}
              key={tpa}
              control={
                <Checkbox
                  value={tpa}
                  checked={selectedTPAs.includes(tpa)}
                  onChange={updateSelectedTPAs}
                />
              }
              label={
                <Typography variant="body2">{tpa === '' ? 'No Matching TPA' : tpa}</Typography>
              }
            />
          ))}
          <div className={classes.dateFilter} />
          <SelectAgingSwitch isChecked={showOnlyAging} toggleShowOnlyAging={toggleShowOnlyAging} />
          {!showOnlyAging && (
            <>
              <DateInput
                id="startDate"
                label="Start Date"
                value={startDate}
                onValueChange={formatStartDate}
                errorLabel={startDateErr}
              />
              <DateInput
                id="endDate"
                label="End Date"
                value={endDate}
                onValueChange={formatEndDate}
                errorLabel={endDateErr}
              />
            </>
          )}
        </FormGroup>
      </Popover>
    </>
  );
}

FilterReportsByTPAButton.propTypes = {
  allTPAs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTPAs: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateSelectedTPAs: PropTypes.func.isRequired,
  formatStartDate: PropTypes.func.isRequired,
  formatEndDate: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  startDateErr: PropTypes.string.isRequired,
  endDateErr: PropTypes.string.isRequired,
  showOnlyAging: PropTypes.bool.isRequired,
  toggleShowOnlyAging: PropTypes.func.isRequired,
};
