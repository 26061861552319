import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { GridColumn } from '../shared/grid';
import { updateAdjudication } from './claimsActions';
import * as types from '../shared/types/propTypes';

function AdjudicationFields({ adjudication, saveMetadata, dispatch }) {
  const changeAdjudication = e => {
    const fieldName = `adjudication.${e.target.name}`;
    const stringVal = e.target.value;
    dispatch(updateAdjudication({ ...saveMetadata, fieldName, stringVal }));
  };

  const disabledDetermination =
    adjudication.category === '' || adjudication.category === 'NotAllowed';

  return (
    <>
      <GridColumn style={{ gridColumn: 'adjud-start' }}>
        <Select
          name="category"
          label="Category"
          value={adjudication.category || ''}
          onChange={changeAdjudication}
          style={{ width: '100%' }}
        >
          <MenuItem value="NotAllowed">Not Allowed</MenuItem>
          <MenuItem value="Preventive">Preventive</MenuItem>
          <MenuItem value="Supplemental">Supplemental</MenuItem>
        </Select>
      </GridColumn>
      <GridColumn>
        <Select
          name="determination"
          label="Determination"
          disabled={disabledDetermination}
          value={adjudication.determination || ''}
          onChange={changeAdjudication}
          style={{ width: '100%' }}
        >
          <MenuItem value="Denied">Denied</MenuItem>
          <MenuItem value="Allowed">Allowed</MenuItem>
        </Select>
      </GridColumn>
      <GridColumn />
    </>
  );
}

AdjudicationFields.defaultProps = {
  adjudication: {},
};
AdjudicationFields.propTypes = {
  adjudication: types.adjudication,
  saveMetadata: types.saveMetadata.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(AdjudicationFields);
