import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import ServiceItem from './ServiceItem';
import TotalItem from './TotalItem';
import ClaimAdjudicationLine from './ClaimAdjudicationLine';
import SvcColumnHeaders from './SvcColumnHeaders';
import * as types from '../shared/types/propTypes';

import {
  instColumns,
  profColumns,
  repricingColumns,
  adjudColumns,
  adjudColumn,
} from '../shared/grid';
import { qpaZipCodeTypeStatus } from '../shared/status';

export function checkHasAdjudData(services) {
  return (
    Array.isArray(services) &&
    services.some(svc => svc.adjudication && svc.adjudication.determination !== '')
  );
}

export function getServiceColumnWidths(isProf, isAdjud, hasAdjudData) {
  const typeOfBillColumns = isProf ? profColumns : instColumns;
  const adjudDataColumn = !isAdjud && hasAdjudData ? ` ${adjudColumn} ` : ' ';
  const adjudOrRepricingColumns = isAdjud ? adjudColumns : repricingColumns;

  return `${typeOfBillColumns}${adjudDataColumn}${adjudOrRepricingColumns}`;
}

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
  },
  header: {
    top: 0,
    position: 'sticky',
    zIndex: 100,
  },
  servicesWrapper: {
    maxHeight: '400px',
  },
});

export default function Services({ claim, saveMetadata, isAdjud }) {
  const classes = useStyles();

  if (!(claim && claim.simpleClaim)) return null;
  const { simpleClaim, status } = claim;
  const { formType, serviceLocation, subscriber, patient, billProv, services } = simpleClaim;

  const isProf = formType === 'B';
  const hasAdjudData = checkHasAdjudData(services);
  const columnWidths = getServiceColumnWidths(isProf, isAdjud, hasAdjudData);

  const checkPickupLocationAvailability = () => {
    if (claim.hierarchy) {
      try {
        for (let i = 0; i < claim.hierarchy.length; i++) {
          if (claim.hierarchy[i].claimDetails !== null) {
            for (let j = 0; j < claim.hierarchy[i].claimDetails.length; j++) {
              if (claim.hierarchy[i].claimDetails[j].provs) {
                for (let k = 0; k < claim.hierarchy[i].claimDetails[j].provs.length; k++) {
                  const { name } = claim.hierarchy[i].claimDetails[j].provs[k];
                  if (name.entity === 'PW') {
                    return true;
                  }
                }
              }
            }
          }
        }
      } catch (err) {
        return false;
      }
    }
    return false;
  };
  const checkServiceItemForSurpriseBillProcCode = code => {
    // hardcoding these for the moment until endpoint
    const procCodes = [
      '99281',
      '99282',
      '99283',
      '99284',
      '99285',
      '99291',
      'G0380',
      'G0381',
      'G0382',
      'G0383',
      'G0384',
      'G0390',
      'A0430',
      'A0431',
      'A0435',
      'A0436',
    ];
    return procCodes.filter(procCode => procCode === code).length > 0;
  };
  const checkServiceItemForAirAmbulanceCode = code => {
    // hardcoding these for the moment until endpoint
    const procCodes = ['A0430', 'A0431', 'A0435', 'A0436'];
    return procCodes.filter(procCode => procCode === code).length > 0;
  };
  const checkServiceItemForEmergencyRevCode = code => {
    // hardcoding these for the moment until endpoint
    const emergencyRevCode = ['0450', '0452', '0459', '0981', '450', '452', '459', '981'];
    return emergencyRevCode.filter(emergencyRevCode => emergencyRevCode === code).length > 0;
  };
  const checkSBIForQPA = serviceItem => {
    const { procCode, revCode, qpaAmount, qpaZipCodeType } = serviceItem;
    // only display if surpriseBillIndicator -- if 002/003 all service items display qpa -- else only display qpa if proc/rev code is a 'surpriseBill' code
    let errorMsg;
    // if (simpleClaim?.surpriseBillIndicator) {
    //   if (simpleClaim?.sbi?.sbiCode === '002') {
    //     if (checkServiceItemForAirAmbulanceCode(procCode) && !checkPickupLocationAvailability()) {
    //       errorMsg =
    //         'Pick up location not present, please enter appropriate zip code to calculate QPA.';
    //       return { showqpa: true, errMsg: errorMsg };
    //     }
    //     if (qpaZipCodeType === qpaZipCodeTypeStatus.invalid) {
    //       errorMsg = 'Invalid zip code, unable to calculate QPA. Please enter a valid zip code.';
    //       return { showqpa: true, errMsg: errorMsg };
    //     }
    //     if (qpaAmount == null) {
    //       errorMsg = 'Cannot calculate QPA, data not available. Please, reprice with RBP.';
    //       return { showqpa: true, errMsg: errorMsg };
    //     }
    //     if (revCode != null && checkServiceItemForEmergencyRevCode(revCode) && procCode == null) {
    //       errorMsg = 'Flagged for emergency Rev Code. Please reprice with RBP.';
    //       return { showqpa: true, errMsg: errorMsg };
    //     }
    //     return { showqpa: true, errMsg: errorMsg };
    //   }
    // if (
    //   simpleClaim?.sbi?.sbiCode === '001' &&
    //   (checkServiceItemForSurpriseBillProcCode(procCode) ||
    //     checkServiceItemForEmergencyRevCode(revCode))
    // ) {
    //   if (checkServiceItemForAirAmbulanceCode(procCode) && !checkPickupLocationAvailability()) {
    //     errorMsg =
    //       'Pick up location not present, please enter appropriate zip code to calculate QPA.';
    //     return { showqpa: true, errMsg: errorMsg };
    //   }
    //   if (qpaZipCodeType === qpaZipCodeTypeStatus.invalid) {
    //     errorMsg = 'Invalid zip code, unable to calculate QPA. Please enter a valid zip code.';
    //     return { showqpa: true, errMsg: errorMsg };
    //   }
    //   // if (qpaAmount == null) {
    //   //   errorMsg =
    //   //     'Cannot calculate QPA, zip code or procedure code missing from data. Please, reprice with RBP.';
    //   //   return { showqpa: true, errMsg: errorMsg };
    //   // }
    //   if (checkServiceItemForEmergencyRevCode(revCode) && procCode == null) {
    //     errorMsg = 'Flagged for emergency Rev Code. Please reprice with RBP.';
    //     return { showqpa: true, errMsg: errorMsg };
    //   }
    //   return { showqpa: true, errMsg: errorMsg };
    // }
    //   if (simpleClaim?.sbi?.sbiCode) {
    //     return { showqpa: true, errMsg: errorMsg };
    //   }
    //   return { showqpa: false, errMsg: errorMsg };
    // }

    if (simpleClaim?.surpriseBillIndicator) {
      if (
        simpleClaim?.sbi?.sbiCode === '002' ||
        checkServiceItemForSurpriseBillProcCode(procCode)
      ) {
        if (checkServiceItemForAirAmbulanceCode(procCode) && !checkPickupLocationAvailability()) {
          return {
            showqpa: true,
            errMsg:
              'Pick up location not present, please enter appropriate zip code to calculate QPA.',
          };
        }
        if (qpaZipCodeType === qpaZipCodeTypeStatus.invalid) {
          return {
            showqpa: true,
            errMsg: 'Invalid zip code, unable to calculate QPA. Please enter a valid zip code.',
          };
        }
        if (qpaAmount == null) {
          return {
            showqpa: true,
            errMsg: 'Cannot calculate QPA, data not available. Please, reprice with RBP.',
          };
        }
        if (revCode != null && checkServiceItemForEmergencyRevCode(revCode) && procCode == null) {
          return {
            showqpa: true,
            errMsg: 'Flagged for emergency Rev Code. Please reprice with RBP.',
          };
        }
        return { showqpa: true, errMsg: errorMsg };
      }
      if (
        simpleClaim?.sbi?.sbiCode === '001' ||
        (!checkServiceItemForSurpriseBillProcCode(procCode) &&
          checkServiceItemForEmergencyRevCode(revCode))
      ) {
        if (checkServiceItemForAirAmbulanceCode(procCode) && !checkPickupLocationAvailability()) {
          return {
            showqpa: true,
            errMsg:
              'Pick up location not present, please enter appropriate zip code to calculate QPA.',
          };
        }
        if (qpaZipCodeType === qpaZipCodeTypeStatus.invalid) {
          return {
            showqpa: true,
            errMsg: 'Invalid zip code, unable to calculate QPA. Please enter a valid zip code.',
          };
        }
        if (qpaAmount == null) {
          return {
            showqpa: true,
            errMsg: 'Cannot calculate QPA, data not available. Please, reprice with RBP.',
          };
        }
        if (revCode != null && checkServiceItemForEmergencyRevCode(revCode) && procCode == null) {
          return {
            showqpa: true,
            errMsg: 'Flagged for emergency Rev Code. Please reprice with RBP.',
          };
        }
        return { showqpa: true, errMsg: errorMsg };
      }
    }

    return { showqpa: false, errMsg: errorMsg };
  };

  // no header requested in mock up - if testing reveals this is required pass hasQPAData to SvcColumnHeaders
  // const checkHasQPAData = () => {
  //   return (
  //     Array.isArray(services) &&
  //     services.some(
  //       svc =>
  //         svc.procCode &&
  //         checkServiceItemForSurpriseBillProcCode(svc.procCode) &&
  //         svc.revCode &&
  //         checkServiceItemForSupriseBillRevCode(svc.revCode)
  //     )
  //   );
  // };

  // const hasQPAData = simpleClaim.surpriseBillIndicator && !!checkHasQPAData;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <SvcColumnHeaders
          isAdjud={isAdjud}
          isProf={isProf}
          hasAdjudData={hasAdjudData}
          columnWidths={columnWidths}
        />
      </div>
      <div className={classes.servicesWrapper}>
        {services &&
          services.map((s, index) => (
            <ServiceItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${claim.id}-${index}`} // no unique service item key available, but items never added/removed
              svc={s}
              isAdjud={isAdjud}
              isProf={isProf}
              showQPA={checkSBIForQPA(s)}
              qpaAmount={s.qpaAmount}
              hasAdjudData={hasAdjudData}
              columnWidths={columnWidths}
              claim={claim}
              saveMetadata={{
                ...saveMetadata,
                serviceIndex: index,
                serviceDate: s.serviceFromDate,
                serviceZip: (serviceLocation && serviceLocation.zip) || (billProv && billProv.zip),
                patientZip: (patient && patient.zip) || (subscriber && subscriber.zip),
              }}
            />
          ))}
        {isAdjud ? (
          <ClaimAdjudicationLine
            simpleClaim={simpleClaim}
            saveMetadata={saveMetadata}
            columnWidths={columnWidths}
          />
        ) : (
          <TotalItem
            claim={claim}
            saveMetadata={saveMetadata}
            columnWidths={columnWidths}
            claimStatus={status}
          />
        )}
      </div>
    </div>
  );
}

Services.defaultProps = {
  claim: undefined,
};
Services.propTypes = {
  claim: types.claim,
  saveMetadata: types.saveMetadata.isRequired,
  isAdjud: PropTypes.bool.isRequired,
};
