import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import { UpdateContract } from '../types/contracts';
import { InputChangeHandler, FocusHandler } from '../../shared/types/types';

const useStyles = makeStyles({
  note: {
    flexGrow: 1,
    marginLeft: '3rem',
    maxWidth: '65%',
    alignSelf: 'flex-end',
  },
});

type Props = {
  note: string;
  isEdit: boolean;
  updateContract: UpdateContract;
};
export default function ContractNote({ note, isEdit, updateContract }: Props): JSX.Element | null {
  const classes = useStyles();
  const [noteValue, setNoteValue] = useState(note);

  if (!note && !isEdit) return null;

  const handleChange: InputChangeHandler = e => setNoteValue(e.target.value);
  const handleBlur: FocusHandler = () => updateContract('note', noteValue);

  return (
    <div className={classes.note}>
      <TextField
        label="Internal Note"
        multiline
        rows={isEdit ? '10' : ''}
        rowsMax={!isEdit ? '10' : ''}
        value={noteValue}
        onChange={handleChange}
        onBlur={handleBlur}
        margin="normal"
        variant="outlined"
        disabled={!isEdit}
        fullWidth
      />
    </div>
  );
}
