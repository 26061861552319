import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import { getErrorText } from '../shared/validators';
import IconButtonCompact from '../shared/components/IconButtonCompact';
import TextInput from '../shared/components/TextInput';
import * as types from '../shared/types/propTypes';

export default class Provider extends Component {
  handleTextChange = name => e =>
    this.props.updateProviderValue(name, e.target.value.toUpperCase());

  handleNPIChange = e => this.props.updateNPI(e.target.value);
  handleAddressVerified = () => this.props.toggleAddressVerified();
  handleAddressIsEditable = () => this.props.toggleAddressIsEditable();

  renderAddress() {
    const { prov, isReadOnly, serviceLocation } = this.props;
    const { addressIsEditable, lastNameOrOrg, city, state } = prov;
    if (addressIsEditable) {
      return (
        <FullAddress
          prov={prov}
          handleTextChange={this.handleTextChange}
          handleAddressVerified={this.handleAddressVerified}
        />
      );
    }
    if (lastNameOrOrg || city || state) {
      return (
        <ShortAddress
          prov={prov}
          isReadOnly={isReadOnly}
          handleAddressIsEditable={this.handleAddressIsEditable}
          serviceLocation={serviceLocation}
        />
      );
    }
    return null;
  }

  render() {
    const { prov, isReadOnly, serviceLocation } = this.props;
    return (
      <>
        <TextInput
          readOnly={isReadOnly}
          label="NPI Number"
          allowedCharRegex="\d"
          maxLength={10}
          onChange={this.handleNPIChange}
          value={prov.id}
          errorLabel={getErrorText(prov, 'id')}
          id={`${serviceLocation ? 'service-provider' : 'provider'}-npi-number`}
        />
        <TextInput
          readOnly={isReadOnly}
          label="Zip"
          allowedCharRegex="\d"
          maxLength={5}
          onChange={this.handleTextChange('zip')}
          value={prov.zip}
          errorLabel={getErrorText(prov, 'zip')}
          id={`${serviceLocation ? 'service-provider' : 'provider'}-zip`}
        />
        {this.renderAddress()}
        <TextInput
          readOnly={isReadOnly}
          label="EIN Number"
          allowedCharRegex="\d"
          maxLength={9}
          onChange={this.handleTextChange('ein')}
          value={prov.ein}
          errorLabel={getErrorText(prov, 'ein')}
          id={`${serviceLocation ? 'service-provider' : 'provider'}-ein`}
        />
      </>
    );
  }
}

Provider.propTypes = {
  prov: types.provider.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  updateProviderValue: PropTypes.func.isRequired,
  updateNPI: PropTypes.func.isRequired,
  toggleAddressVerified: PropTypes.func.isRequired,
  toggleAddressIsEditable: PropTypes.func.isRequired,
  serviceLocation: PropTypes.bool,
};
Provider.defaultProps = {
  serviceLocation: false,
};

const ShortAddress = ({ prov, isReadOnly, handleAddressIsEditable, serviceLocation }) => {
  const { firstName, lastNameOrOrg, city, state } = prov;
  return (
    <div style={{ marginTop: 16 }}>
      <div>
        <Typography variant="body2">
          {firstName} {lastNameOrOrg}
        </Typography>
        <Typography variant="body2">
          {city}, {state}
        </Typography>
      </div>
      {!isReadOnly && (
        <div style={{ padding: 0, width: 30 }}>
          <IconButtonCompact
            tooltip="Edit address"
            id={`edit-${serviceLocation ? 'service-provider' : 'provider'}-address-button`}
            onClick={handleAddressIsEditable}
          >
            <EditIcon />
          </IconButtonCompact>
        </div>
      )}
    </div>
  );
};

ShortAddress.defaultProps = {
  serviceLocation: false,
};

ShortAddress.propTypes = {
  prov: types.provider.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  handleAddressIsEditable: PropTypes.func.isRequired,
  serviceLocation: PropTypes.bool,
};

const FullAddress = ({ prov, handleTextChange, handleAddressVerified }) => {
  const { lastNameOrOrg, firstName, city, state, addressVerified } = prov;
  return (
    <>
      <TextInput
        label="Last Name or Company"
        multiline
        rowsMax={3}
        onChange={handleTextChange('lastNameOrOrg')}
        value={lastNameOrOrg}
        errorLabel={getErrorText(prov, 'lastNameOrOrg')}
      />
      <TextInput
        label="First Name"
        onChange={handleTextChange('firstName')}
        value={firstName}
        errorLabel={getErrorText(prov, 'firstName')}
      />
      <TextInput
        label="City"
        onChange={handleTextChange('city')}
        value={city}
        errorLabel={getErrorText(prov, 'city')}
      />
      <TextInput
        label="State"
        onChange={handleTextChange('state')}
        allowedCharRegex="a-zA-Z"
        maxLength={2}
        value={state}
        errorLabel={getErrorText(prov, 'state')}
      />
      <FormControlLabel
        control={<Checkbox checked={addressVerified} onChange={handleAddressVerified} />}
        label="Address Verified"
      />
    </>
  );
};

FullAddress.propTypes = {
  prov: types.provider.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleAddressVerified: PropTypes.func.isRequired,
};
