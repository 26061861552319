import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect as RedirectInternal } from 'react-router-dom';

type ParentProps = {
  to: string;
};

export default function Redirect({ to }: ParentProps): JSX.Element {
  const windowOrigin = window.location.origin;
  const url = new URL(to, windowOrigin);
  const sameOrigin = url.origin === windowOrigin;
  useEffect(() => {
    if (!sameOrigin) {
      window.location.href = to;
    }
  }, [to, sameOrigin]);

  return !sameOrigin ? <section>Redirecting to {to}</section> : <RedirectInternal to={to} />;
}

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
};
