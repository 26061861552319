import MonatDate from './MonatDate';
import { delimPattern, sanitizeDelims, getSanitizedPosition } from './monatUtilities';

export default class Monat {
  constructor(...formats) {
    this.formats = formats;
    this.delims = formats.map(f => f.delim).join``;
    this.userFormat = '';
  }

  parseNumeric(date) {
    return this.formats
      .map(format => format.parseNumeric(date))
      .filter(format => format.isValidPartial());
  }

  parseDelimited(date) {
    const blocks = date ? date.split(delimPattern) : [];
    if (blocks.length === 1) {
      return this.parseNumeric(date);
    }
    return this.formats
      .map(format => new MonatDate(format, blocks).toComplete())
      .filter(date => date.isValidComplete());
  }

  insertDelim(date, position) {
    const sanitized = sanitizeDelims(date);
    const sanitizedPosition = getSanitizedPosition(date, position);
    const parsed = this.formats
      .map(format => format.insertDelim(sanitized, sanitizedPosition))
      .filter(date => date && date.isValidPartial());
    if (parsed.length === 1) {
      this.userFormat = parsed[0].name;
    }
    return parsed;
  }

  setCompleted(value) {
    return this.formats
      .map(format => format.parseNumeric(value).toComplete())
      .filter(format => format.isValidPartial());
  }

  isDelim(char) {
    return this.delims.includes(char);
  }
}
