import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { colors } from '@apps/shared/src/style';
import ClaimFooterItem from '../../repricing/ClaimFooterItem';

const useStyles = makeStyles({
  deleteButton: {
    background: colors.red,
    color: colors.white,
    '&:hover': {
      background: colors.redScarlet,
    },
  },
  deleteIcon: {
    marginRight: '0.5rem',
  },
});

type Props = {
  deleteContract: VoidFunction;
  isContractor: boolean;
  isContractAdmin: boolean;
  isOwner: boolean;
};

export default function DeleteContractButton({
  isOwner,
  isContractor,
  isContractAdmin,
  deleteContract,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showButton = isContractAdmin || (isContractor && isOwner);

  const handleCloseModal: VoidFunction = () => setIsModalOpen(false);
  const handleOpenModal: VoidFunction = () => setIsModalOpen(true);

  if (!showButton) return null;

  return (
    <>
      <ClaimFooterItem>
        <Button onClick={handleOpenModal} variant="contained" className={classes.deleteButton}>
          <DeleteIcon className={classes.deleteIcon} />
          Delete Contract
        </Button>
      </ClaimFooterItem>
      <Dialog open={isModalOpen} onClose={handleCloseModal} aria-describedby="change-type-alert">
        <DialogContent>
          <DialogContentText id="change-type-alert">
            This change will delete the selected contract. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            CANCEL
          </Button>
          <Button onClick={deleteContract} color="primary" autoFocus>
            DELETE CONTRACT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
