import React from 'react';
import PropTypes from 'prop-types';

import TimelineIcon from '@material-ui/icons/Timeline';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UpdateIcon from '@material-ui/icons/Update';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { isValidArray, isArrayFilled } from '../../shared/typeChecks';
import * as types from '../../shared/types/propTypes';

import {
  TableCellHeaderTop,
  Column,
  addButtonStyle,
  deleteButtonStyle,
  configSectionStyle,
  configIconStyle,
} from './sharedConfig';

const useStyles = makeStyles({
  configSection: configSectionStyle,
  configIcon: configIconStyle,
  addButton: addButtonStyle,
  deleteButton: deleteButtonStyle,
  claimInfoCell: {
    margin: '10px',
  },
  dropDown: {
    marginBottom: '20px',
  },
  dropDownHeader: {
    marginTop: '10px',
  },
  exPanel: {
    marginBottom: '10px',
  },
  historyText: {
    wordWrap: 'break-word',
    width: '100%',
  },
});

export default function ChangeHistory({ changeHistory }) {
  const classes = useStyles();

  if (!changeHistory) {
    return null;
  }

  if (!(isValidArray(changeHistory) && isArrayFilled(changeHistory))) return null;

  const sortedChangeHistory = changeHistory.reverse();

  return (
    <Paper className={classes.claimInfoCell}>
      <TableCellHeaderTop>
        <Typography>
          <TimelineIcon className={classes.configIcon} /> &nbsp; Change History
        </Typography>
      </TableCellHeaderTop>
      <Column className={classes.configSection}>
        {sortedChangeHistory.map(change => {
          return (
            <Paper key={change.date}>
              <Accordion className={classes.exPanel}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>
                    <UpdateIcon /> &nbsp; {change.date}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <div className={classes.historyText}>
                    <Typography>
                      <strong>User:</strong> {change.user && change.user.fullName}
                    </Typography>
                    <br />
                    <Typography>
                      <strong>Diff:</strong> {change.diff}
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Paper>
          );
        })}
      </Column>
    </Paper>
  );
}

ChangeHistory.defaultProps = {
  changeHistory: null,
};

ChangeHistory.propTypes = {
  changeHistory: PropTypes.arrayOf(types.changeHistory),
};
