import React from 'react';
import { connect } from 'react-redux';

import PatientsIcon from '@material-ui/icons/SupervisorAccount';

import { isValidNumber } from '@apps/shared/src/guards';
import { capitalizeWords } from '../../shared/globals';
import {
  ContractPlan,
  ContractPatient,
  AutocompleteOptions,
  AutocompleteType,
  PatientSelectionType,
  OpenableElement,
  ContractTypes,
} from '../types/contracts';
import { RootState } from '../../shared/types/types';
import { setIsElementOpen, searchAutocomplete, updateContract } from '../contractsActions';

import PatientInputs from './PatientInputs';
import PatientChip from '../chips/PatientChip';
import PlanChip from '../chips/PlanChip';
import ChipBox from '../chips/ChipBox';
import { checkIsSCAorLOA } from '../contractUtilities';

type ParentProps = {
  isEdit: boolean;
};
type StateProps = {
  contractType: ContractTypes;
  plans: ContractPlan[];
  patients: ContractPatient[];
  autocompleteOptions: AutocompleteOptions;
  isPatientOpen: boolean;
  isPlanOpen: boolean;
};
type DispatchProps = {
  setIsElementOpen: (elementName: OpenableElement, open: boolean) => void;
  searchAutocomplete: (type: AutocompleteType, searchText: string) => void;
  updateContract: (action: string, value: string | number) => void;
};
type Props = ParentProps & StateProps & DispatchProps;

export function ContractPatients({
  isEdit,
  patients,
  plans,
  updateContract,
  autocompleteOptions,
  searchAutocomplete,
  setIsElementOpen,
  isPatientOpen,
  isPlanOpen,
  contractType,
}: Props): JSX.Element | null {
  if (contractType === ContractTypes.Direct) return null;

  const handleRemoveItem =
    (type: PatientSelectionType) =>
    (value: number | string): void => {
      const formattedType = isValidNumber(value)
        ? capitalizeWords(type)
        : `Unidentified${capitalizeWords(type)}`;
      const action = `remove${formattedType}`;
      updateContract(action, value);
    };

  const planBundle = {
    items: plans,
    handleRemoveItem: handleRemoveItem('plan'),
    ItemChip: PlanChip,
  };
  const patientBundle = {
    items: patients,
    handleRemoveItem: handleRemoveItem('patient'),
    ItemChip: PatientChip,
  };

  const boxTitle = checkIsSCAorLOA(contractType) ? 'Patients' : 'Plans';
  const defaultMsg =
    contractType === ContractTypes.DirectOptIn
      ? 'No Associated Patients'
      : 'All 6 Degrees Health Associated Patients';

  return (
    <ChipBox
      isEdit={isEdit}
      title={boxTitle}
      Icon={PatientsIcon}
      chipBundles={[planBundle, patientBundle]}
      gridArea="patients"
      defaultMessage={defaultMsg}
    >
      <PatientInputs
        visible={isEdit}
        plans={plans}
        patients={patients}
        updateContract={updateContract}
        autocompleteOptions={autocompleteOptions}
        handleAutocompleteSearch={searchAutocomplete}
        isPatientOpen={isPatientOpen}
        isPlanOpen={isPlanOpen}
        setIsElementOpen={setIsElementOpen}
        contractType={contractType}
      />
    </ChipBox>
  );
}

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    contractType: contracts.selectedContract.contractType,
    plans: contracts.selectedContract.plans,
    patients: contracts.selectedContract.patients,
    autocompleteOptions: contracts.autocompleteOptions,
    isPatientOpen: contracts.isPatientOpen,
    isPlanOpen: contracts.isPlanOpen,
  };
};

const mapDispatchToProps = {
  setIsElementOpen,
  searchAutocomplete,
  updateContract,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractPatients);
