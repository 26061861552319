import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import SelectionInput from './SelectionInput';
import { HandleTermValueChange } from '../../types/contracts';

const useStyles = makeStyles({
  termComparison: {
    paddingLeft: '1em',
  },
});

type Props = {
  currentValue: string;
  handleChange: HandleTermValueChange;
  visible?: boolean;
};
export default function TermComparison({
  currentValue,
  handleChange,
  visible = true,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (!visible) return null;

  return (
    <div className={classes.termComparison}>
      <SelectionInput
        value={currentValue}
        handleChange={handleChange}
        id="comparisonType"
        options={[
          { value: 'lesser', name: 'The Lesser Of' },
          { value: 'greater', name: 'The Greater Of' },
        ]}
      />
    </div>
  );
}
