import { inflateString } from '@apps/shared/src/inflators';
import { doesStringInclude } from '@apps/shared/src/utilities';

export function searchClaim(searchTerm, claim) {
  const validSearchTerm = inflateString(searchTerm);
  if (!(validSearchTerm && claim.simpleClaim)) return true;

  const { simpleClaim } = claim;

  return (
    simpleClaim &&
    (searchNumber(validSearchTerm, simpleClaim, 'billAmt') ||
      searchString(validSearchTerm, simpleClaim, 'policyNum') ||
      searchString(validSearchTerm, simpleClaim, 'claimNum') ||
      searchString(validSearchTerm, simpleClaim, 'refNum') ||
      searchObject(validSearchTerm, simpleClaim.patient) ||
      searchObject(validSearchTerm, simpleClaim.subscriber) ||
      searchObject(validSearchTerm, simpleClaim.billProv) ||
      searchObject(validSearchTerm, simpleClaim.serviceLocation))
  );
}

export function searchNumber(searchTerm, value, prop) {
  const num = parseFloat(searchTerm);
  return value.hasOwnProperty(prop) && !Number.isNaN(num) && Math.abs(value[prop] - num) <= 0.5;
}

export function searchString(searchTerm, value, prop) {
  if (!inflateString(searchTerm)) return false;
  return value.hasOwnProperty(prop) && doesStringInclude(value[prop], searchTerm);
}

export function searchObject(searchTerm, object) {
  if (!object || typeof searchTerm !== 'string') return false;
  const term = searchTerm.toUpperCase();
  const values = Object.values(object);
  return values.some(val => typeof val === 'string' && val.toUpperCase().includes(term));
}
