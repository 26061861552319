import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';

import { SettlementsClaimSummary } from './types/types';

const useStyles = makeStyles({
  linkText: {
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '120px',
  },
});

type ParentProps = {
  claim: SettlementsClaimSummary;
};

type Props = ParentProps;
export default function ClaimLink({ claim: { claimNumber, refNumber, id } }: Props): JSX.Element {
  const classes = useStyles();
  const linkText = claimNumber || refNumber || id.toUpperCase();

  return (
    <div className={classes.linkText}>
      <Link to={`/repricing/${id}`} target="_blank">
        {linkText}
      </Link>
    </div>
  );
}
