import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/styles/makeStyles';
import { AvatarProps } from 'material-ui';
import { colors } from '@apps/shared/src/style';
import { Formula } from './types/repricingTypes';

const useStyles = makeStyles({
  tooltip: {
    fontSize: 14,
  },
  avatar: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    backgroundColor: colors.grey37,
    margin: 'auto',
  },
  $: {
    fontSize: 14,
  },
  x: {
    fontSize: 10,
  },
});

type Props = Pick<AvatarProps, 'onClick'> & {
  selectedFormula: Formula;
};

export default function RepricingMethodologyFormulaButton({
  selectedFormula,
  onClick,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Tooltip
      title={`${selectedFormula.name}: ${selectedFormula.formula}`}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <Avatar
        aria-label="methodFormulaButton"
        className={classes.avatar}
        onClick={onClick}
        id="methodFormulaButton"
      >
        <span className={classes.$}>$</span>
        <span className={classes.x}>x</span>
      </Avatar>
    </Tooltip>
  );
}
