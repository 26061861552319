/* eslint-disable @typescript-eslint/no-non-null-assertion */
import LoadingIndicator from '@apps/shared/src/components/LoadingIndicator';
import { isValidNumber } from '@apps/shared/src/guards';
import { colors } from '@apps/shared/src/style/colors';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AppLayout from '../shared/components/layout/AppLayout';
import { validObject } from '../shared/typeChecks';
import { RootState } from '../shared/types/types';
import ContractContent from './ContractContent';
import { clearContract, getContract, getContracts } from './contractsActions';
import { checkContractRoles, getContractSpecifics } from './contractUtilities';
import ContractFooter from './footer/ContractFooter';
import ContractList from './list/ContractList';
import { ContractActionTypes } from './types/actions';
import { ContractDetail, ContractSummary } from './types/contracts';

type StateProps = {
  roles: string[];
  userID: string;
  isContractLoading: boolean;
  contract: ContractDetail;
  listLoading: boolean;
  contracts: ContractSummary[];
};

const Contracts: React.FC<Props> = (props): JSX.Element => {
  const {
    contract,
    contracts,
    roles,
    userID,
    getContract,
    getContracts,
    clearContract,
    isContractLoading,
    listLoading,
  } = props;

  const history = useHistory();
  const match: { id: string } = useParams();

  useEffect(() => {
    if (!listLoading && contracts?.length < 1) getContracts();
  }, [listLoading, contracts, getContracts]);

  useEffect(() => {
    if ('id' in match && match.id !== undefined) {
      const id = +match.id;
      if (isValidNumber(id)) getContract(id);
      else clearContract();
    }
  }, [clearContract, getContract, match]);

  useEffect(() => {
    history.push(`/contracts/${contract.id ? contract.id : ''}`);
  }, [history, contract.id]);

  const { isContractor } = checkContractRoles(roles);

  const { isEdit, isNew, contractError } = getContractSpecifics(
    isContractor,
    isContractLoading,
    contract,
    userID,
    validObject(match).id
  );

  const handleListClick = (id: number): void => {
    if (id !== contract.id) history.push(`/contracts/${id}`);
  };

  return (
    <AppLayout
      sidebar={
        roles.includes('claims-contract') && <ContractList handleListClick={handleListClick} />
      }
      contentFooter={contract.id ? <ContractFooter /> : null}
    >
      {listLoading ? (
        <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
          <LoadingIndicator isLoading={listLoading} size={75} color={colors.orange} />
        </div>
      ) : (
        <ContractContent
          isLoading={isContractLoading}
          isEdit={isEdit}
          isNew={isNew}
          contractError={contractError}
          selectedContractID={contract.id}
        />
      )}
    </AppLayout>
  );
};

// Container

type Props = StateProps & DispatchProps;

type DispatchProps = {
  getContracts: () => void;
  getContract: (id: number) => ContractActionTypes;
  clearContract: () => void;
};

const mapStateToProps = ({ contracts, user }: RootState): StateProps => ({
  roles: user!.currentUser!.roles,
  userID: user!.currentUser!.userID,
  isContractLoading: contracts.isContractLoading,
  contract: contracts.selectedContract,
  listLoading: contracts.listLoading,
  contracts: contracts.contracts || [],
});

const mapDispatchToProps = {
  getContracts,
  getContract,
  clearContract,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
