import React from 'react';
import { Route, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import AppLayout from '../shared/components/layout/AppLayout';
import ConfigEditor from './configEditor/Config';
import PlanEditor from './planEditor/PlanEditor';

const useStyles = makeStyles({
  ediMenu: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '130px',
    paddingTop: '40px',
  },
  button: {
    justifyContent: 'start',
    width: '85%',
  },
});

export default function EDI() {
  return (
    <AppLayout sidebar={<MenuEDI />}>
      <MainEDI />
    </AppLayout>
  );
}

function MenuEDI() {
  const classes = useStyles();
  return (
    <div className={classes.ediMenu}>
      <Button component={Link} to="/edi/configEditor" className={classes.button}>
        Config Editor
      </Button>
      <Button component={Link} to="/edi/planEditor" className={classes.button}>
        Plan Editor
      </Button>
    </div>
  );
}

function MainEDI() {
  return (
    <div>
      <Route path="/edi/configEditor/:id?" component={ConfigEditor} />
      <Route path="/edi/planEditor" component={PlanEditor} />
    </div>
  );
}
