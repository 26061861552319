import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styled from '@material-ui/core/styles/styled';
import Typography from '@material-ui/core/Typography';

const ButtonPrimary = styled(Button)({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
});
const ButtonOption = styled(Button)({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  minWidth: 0,
  padding: '6px 0',
});

class DropDownButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = onClick => event => {
    event.preventDefault();
    onClick();
    this.setState({ open: false });
  };

  handleClickExpand = event => {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => this.setState({ open: false });

  render() {
    const { options, onClick, children, ...other } = this.props;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ButtonPrimary
          onClick={this.handleClick(onClick)}
          id={`set-${children[children.length - 1]}`}
          {...other}
        >
          <Typography noWrap variant="button">
            {children}
          </Typography>
        </ButtonPrimary>
        <ButtonOption onClick={this.handleClickExpand} {...other}>
          <ArrowDropDownIcon />
        </ButtonOption>
        <Popover
          open={this.state.open}
          onClose={this.handleClose}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuList>
            {options.map(option => (
              <MenuItem key={option.label} onClick={this.handleClick(option.onClick)}>
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Popover>
      </div>
    );
  }
}

DropDownButton.defaultProps = {
  children: null,
};
DropDownButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default DropDownButton;
