/* eslint-disable @typescript-eslint/no-explicit-any */
import { inflateString, inflateNumber } from '@apps/shared/src/inflators';
import { ContractMatch, PartialContract } from './repricingTypes';
import { inflateContractType } from '../../contracts/types/inflaters';
import { inflatePatient, inflateTerm } from '../../contracts/contractsReducer';
import { validObject, validArray } from '../../shared/typeChecks';

export function inflateContractMatch(rawContractMatch: any): ContractMatch {
  const raw = validObject(rawContractMatch);
  return {
    contractErr: inflateString(raw.contractErr),
    contracts: validArray(raw.contracts).map(inflatePartialContract),
  };
}

export function inflatePartialContract(rawContract: any): PartialContract {
  const raw = validObject(rawContract);
  return {
    id: inflateNumber(raw.id),
    patients: validArray(raw.patients).map(inflatePatient),
    terms: validArray(raw.terms).map(inflateTerm),
    providerGroup: inflateString(raw.providerGroup),
    contractType: inflateContractType(raw.contractType),
  };
}
