import React, { useState } from 'react';

import Drawer from '@material-ui/core/Drawer';
import BuildIcon from '@material-ui/icons/Build';
import { makeStyles } from '@material-ui/core';

import IconButtonCompact from './IconButtonCompact';
import * as types from '../types/propTypes';

const useStyles = makeStyles({
  drawer: {
    paddingLeft: '.5em',
  },
  pre: {
    overflow: 'auto',
    maxWidth: '80vw',
    whiteSpace: 'pre-wrap',
  },
});

export default function JSONViewer({ selectedClaim }) {
  const [showJSON, setShowJSON] = useState(false);
  const classes = useStyles();

  const handleJSONClick = () => setShowJSON(!showJSON);
  const handleJSONClose = () => setShowJSON(false);

  return (
    <>
      <IconButtonCompact id="wrench" tooltip="View JSON" onClick={handleJSONClick}>
        <BuildIcon />
      </IconButtonCompact>
      <Drawer
        open={showJSON}
        onClose={handleJSONClose}
        anchor="right"
        classes={{ paper: classes.drawer }}
      >
        <h2>JSON Viewer</h2>
        <pre className={classes.pre}>{JSON.stringify(selectedClaim, null, 2)}</pre>
      </Drawer>
    </>
  );
}

JSONViewer.defaultProps = {
  selectedClaim: {},
};
JSONViewer.propTypes = {
  selectedClaim: types.claim,
};
