import React, { useState } from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';

import TextInput from '../../shared/components/TextInput';
import ConfirmOrCancelCompact from '../terms/inputs/ConfirmOrCancelCompact';
import { UpdateContract } from '../types/contracts';
import { KeyHandler, InputChangeHandler } from '../../shared/types/types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '24em',
    marginTop: '1em',
  },
});

type Props = {
  updateContract: UpdateContract;
};
export default function UnidentifiedPatientInput({ updateContract }: Props): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  const handleInputOpen = (): void => setOpen(true);

  if (!open)
    return (
      <div className={classes.root}>
        <Button variant="outlined" onClick={handleInputOpen}>
          Add Patient NOT found in eligibility
        </Button>
      </div>
    );

  const handleConfirm = (): void => {
    updateContract(`addUnidentifiedPatient`, name.trim());
    setName('');
  };
  const handleCancel = (): void => {
    setOpen(false);
    setName('');
  };
  const handleChange: InputChangeHandler = e => {
    setName(e.currentTarget.value.toUpperCase());
  };
  const handleKeyDown: KeyHandler = e => {
    if (e.keyCode === 13) handleConfirm();
  };

  return (
    <div className={classes.root}>
      <TextInput
        label="Patient Name"
        value={name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        fullWidth
        autoFocus
      />
      <ConfirmOrCancelCompact handleConfirm={handleConfirm} handleCancel={handleCancel} />
    </div>
  );
}
