import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import * as types from '../shared/types/propTypes';

const useStyles = makeStyles({
  contentWrapper: {
    minWidth: 0,
  },
  flexTruncate: {
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  flexBox: { display: 'flex' },
  flexExact: { flex: '0 0 auto' },
  flexFill: { flex: '1 0 auto' },
  flexGrow: { flexGrow: 1 },
  alignRight: { textAlign: 'right' },
  secondaryText: { fontSize: '13px', color: colors.greyDark },
  padLeft: { paddingLeft: '0.25em' },
  bold: { fontWeight: 'bold' },
});

export function getDisplayDate(date) {
  const dateString = new Date(date).toLocaleDateString('en-US', {
    timeZone: 'UTC',
  });
  return dateString !== 'Invalid Date' ? `- ${dateString}` : '';
}

export function getDisplayBillAmt(billAmt) {
  return typeof billAmt === 'number' ? `$${Math.round(billAmt)}` : '?';
}

export function getDisplayName(first, last) {
  return `${inflateString(first)} ${inflateString(last)}`.trim();
}

export function parseProps(claim) {
  const validClaim = claim || {};
  const { simpleClaim = {}, earliestDt } = validClaim;
  const { billProv = {}, billAmt, policyNum } = simpleClaim;
  const { firstName, lastNameOrOrg, city, state } = billProv;

  return {
    policyNum: inflateString(policyNum),
    city: inflateString(city),
    state: inflateString(state),
    displayBillAmt: getDisplayBillAmt(billAmt),
    displayName: getDisplayName(firstName, lastNameOrOrg),
    displayDate: getDisplayDate(earliestDt),
  };
}

export default function ListItemContent({ claim }) {
  const classes = useStyles();
  if (!claim) return null;
  const { policyNum, city, state, displayName, displayBillAmt, displayDate } = parseProps(claim);

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.flexBox}>
        <span className={classNames(classes.flexTruncate, classes.bold)}>{displayName}</span>
        <span className={classNames(classes.flexFill, classes.alignRight, classes.padLeft)}>
          {displayBillAmt}
        </span>
      </div>

      <div className={classNames(classes.flexBox, classes.secondaryText)}>
        <span className={classes.flexTruncate}>{city}</span>
        <span className={classNames(classes.flexExact, classes.padLeft)}>{state}</span>
        <div className={classNames(classes.flexFill, classes.flexBox, classes.padLeft)}>
          <span>{displayDate}</span>
          <span className={classNames(classes.alignRight, classes.flexGrow, classes.padLeft)}>
            {policyNum}
          </span>
        </div>
      </div>
    </div>
  );
}

ListItemContent.propTypes = {
  claim: types.claim.isRequired,
};
