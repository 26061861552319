import React from 'react';
import styled from '@material-ui/core/styles/styled';
import MenuItem from '@material-ui/core/MenuItem';

const MenuItemCompact = styled(MenuItem)({
  lineHeight: '32px',
  minHeight: '32px',
  fontSize: '14px',
  paddingTop: 0,
  paddingBottom: 0,
});

export default React.memo(MenuItemCompact);
