import React from 'react';

import Typography from '@material-ui/core/Typography';

import { inflateString } from '@apps/shared/src/inflators';
import { splitCamelCase } from '@apps/shared/src/utilities';
import ClaimFooterItem from '../../repricing/ClaimFooterItem';

type Props = {
  status: string;
};
export default function ContractFooter({ status }: Props): JSX.Element {
  return (
    <>
      <ClaimFooterItem visible={Boolean(status)}>
        <Typography variant="subtitle1">
          <strong>Status: </strong>
          {splitCamelCase(inflateString(status))}
        </Typography>
      </ClaimFooterItem>
    </>
  );
}
