import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import ContractHeader from './header/ContractHeader';
import ContractProviders from './providers/ContractProviders';
import ContractPatients from './patients/ContractPatients';
import ContractTerms from './terms/ContractTerms';

const useStyles = makeStyles({
  details: {
    // style all children
    '& > *': {
      margin: '1rem',
    },
  },
});

type ParentProps = {
  isEdit: boolean;
};

type Props = ParentProps;

export default function ContractDetails({ isEdit }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.details}>
      <ContractHeader isEdit={isEdit} />
      <ContractProviders isEdit={isEdit} />
      <ContractPatients isEdit={isEdit} />
      <ContractTerms isEdit={isEdit} />
    </div>
  );
}
