import React, { useRef } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { parseRosterFile } from '../contractsActions';
import { RootState, InputChangeHandler } from '../../shared/types/types';

const useStyles = makeStyles({
  uploadIcon: {
    marginRight: '0.5rem',
  },
  input: {
    // hide native input styling in favor of MUI Button and Typography
    display: 'none',
  },
});

const mapStateToProps = ({ contracts }: RootState): StateProps => {
  return {
    isRosterOpen: contracts.isRosterOpen,
    isContractLoading: contracts.isContractLoading,
    isRosterLoading: contracts.isRosterLoading,
  };
};
type StateProps = {
  isRosterOpen: boolean;
  isContractLoading: boolean;
  isRosterLoading: boolean;
};

const mapDispatchToProps = {
  parseRosterFile,
};
type DispatchProps = {
  parseRosterFile: (file: File) => void;
};

type Props = StateProps & DispatchProps;

export function RosterUploadButton({
  parseRosterFile,
  isRosterOpen,
  isContractLoading,
  isRosterLoading,
}: Props): JSX.Element {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect: InputChangeHandler = e => {
    if (e.target.files) parseRosterFile(e.target.files[0]);
    if (fileInputRef && fileInputRef.current) fileInputRef.current.value = '';
  };

  const isDisabled = isRosterOpen || isContractLoading || isRosterLoading;

  return (
    <>
      <label htmlFor="roster-file-input">
        <input
          ref={fileInputRef}
          id="roster-file-input"
          type="file"
          accept=".csv,.xlsx"
          onChange={handleFileSelect}
          className={classes.input}
          disabled={isDisabled}
        />
        <Button variant="outlined" color="primary" component="span" disabled={isDisabled}>
          <CloudUploadIcon className={classes.uploadIcon} />
          Add Roster From File
        </Button>
      </label>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterUploadButton);
