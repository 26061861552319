import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

type StateProps = {
  open: boolean;
  closeZipDialog: () => void;
  claimID: string;
  saveMetadata: object;
  save: any;
};

type props = StateProps;

export default function ZipCodeDialog({
  open,
  closeZipDialog,
  saveMetadata,
  save,
}: props): JSX.Element {
  const [inValidZipCode, setInvalidZipCode] = React.useState<boolean>(true);
  const [zipCode, setZipCode] = React.useState<number>();
  // This function is triggered when the select changes
  const handleChange = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.value = e.target.value.replace(/[\D\s]/, '');

    if (e.target.value === '' || e.target.value.length <= 5) {
      setZipCode(e.target.value);
      setInvalidZipCode(false);
    }

    if (e.target.value.length < 5) {
      setInvalidZipCode(true);
    }
  };

  const submitQpaZipCode = (): void => {
    save({
      ...saveMetadata,
      qpaZipCode: String(zipCode),
    });
    setZipCode(undefined);
    setInvalidZipCode(true);
    closeZipDialog();
  };

  return (
    <Dialog open={open} onClose={closeZipDialog}>
      <DialogTitle>Edit QPA</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DialogContentText>
            Enter new zip code and click &quot;Submit&quot;. This will overwrite QPA Repriced
            Amount.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            onChange={e => handleChange(e)}
            value={zipCode}
            id="sbiReason"
            label="New zip code to calculate"
            fullWidth
            variant="standard"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeZipDialog}>Cancel</Button>
        <Button disabled={inValidZipCode} onClick={submitQpaZipCode}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
