import React from 'react';
import PlanTooltipTitle from './PlanTooltipTitle';
import { ContractPlan } from '../types/contracts';
import TooltippedChip from './TooltippedChip';

type Props = {
  isEdit: boolean;
  handleRemove: (id: number) => void;
  item: ContractPlan;
};

export default function PlanChip({ isEdit, handleRemove, item: plan }: Props): JSX.Element {
  const handleDelete = (): void => handleRemove(plan.id);
  const chipText = `ALL PATIENTS FROM ${plan.planName ? plan.planName : 'UNKNOWN PLAN'}`;

  return (
    <TooltippedChip
      chipKey={plan.id}
      tooltip={PlanTooltipTitle(plan)}
      chipText={chipText}
      onDelete={isEdit ? handleDelete : undefined}
    />
  );
}
