import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { HandleTermValueChange, TermItem } from '../../types/contracts';

const useStyles = makeStyles({
  inputs: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1em',
    marginLeft: '1em',
  },
  input: {
    marginTop: '-1em',
  },
});

type Props = {
  value: string;
  handleChange: HandleTermValueChange;
  id: TermItem;
  options: { value: string; name: string }[];
};
export default function SelectionInput({ value, handleChange, id, options }: Props): JSX.Element {
  const classes = useStyles();
  const onSelect = (e: React.FormEvent<HTMLInputElement>): void => {
    handleChange({ value: e.currentTarget.value, id });
  };
  return (
    <div className={classes.inputs}>
      <RadioGroup value={value} onChange={onSelect}>
        {options.map(o => (
          <FormControlLabel
            value={o.value}
            control={<Radio color="primary" />}
            label={o.name}
            className={classes.input}
            key={o.value}
          />
        ))}
      </RadioGroup>
    </div>
  );
}
