import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { closeSnackbar, nextSnackbar } from './snackbarReducer';

function SnackbarManager({ message, open, closeSnackbar, nextSnackbar }) {
  return (
    <Snackbar
      message={message}
      open={open}
      onClose={closeSnackbar}
      onExited={nextSnackbar}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      ClickAwayListenerProps={{ mouseEvent: 'onMouseDown' }}
    />
  );
}

SnackbarManager.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  nextSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ snackbars }) => ({
  message: snackbars.current,
  open: snackbars.open,
});
const mapDispatchToProps = {
  closeSnackbar,
  nextSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarManager);
