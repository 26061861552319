import React, { useState, useEffect, MouseEventHandler, SetStateAction, Dispatch } from 'react';
import { connect } from 'react-redux';
import { inflateNumber } from '@apps/shared/src/inflators';
import { GridColumn } from '../shared/grid';
import { updateRepricing } from './claimsActions';
import RepricingMethodologyFormulaButton from './RepricingMethodologyFormulaButton';
import FormulaMenu from './FormulaMenu';
import { repricingMethodologies } from './repricingMethodologies';
import TextInput from './TextInput';
import { Formula, Repricing, SaveMetadata } from './types/repricingTypes';
import { getSelectedRepricingMethodology } from './RepricingMethodology';
import { ServiceItem } from '../shared/types/types';

type DialogState = {
  isOpen: boolean;
  contentText: string;
  onConfirm: VoidFunction;
};

type ParentProps = {
  saveMetadata: SaveMetadata;
  services: ServiceItem[];
  setDialog: Dispatch<SetStateAction<DialogState>>;
};

const mapDispatchToProps = {
  updateRepricing,
};

type DispatchProps = {
  updateRepricing: (updateInfo?: SaveMetadata) => void;
};

type Props = ParentProps & DispatchProps;

export function getInitialRepricingMethodology(services: ServiceItem[]): Formula {
  const repricingMethodology = services[0]?.repricing?.repricingMethodology;

  if (
    repricingMethodology &&
    services.every(service => service.repricing?.repricingMethodology === repricingMethodology)
  ) {
    return getSelectedRepricingMethodology(repricingMethodology);
  }

  return getSelectedRepricingMethodology();
}

export function willOverrideRepricingMethodologyValue(
  repricingMethodology: string,
  services: ServiceItem[],
  value?: number
): boolean {
  return services.some(
    service =>
      service.repricing?.repricingMethodology !== repricingMethodology ||
      service.repricing?.[repricingMethodology as keyof Repricing] !== value
  );
}

export function doServicesHaveRepricingMethodologyValues(services: ServiceItem[]): boolean {
  return services.some(
    service =>
      service.repricing?.[service.repricing.repricingMethodology as keyof Repricing] != null
  );
}

export function RepricingMethodologyOverride({
  saveMetadata,
  updateRepricing,
  services,
  setDialog,
}: Props): JSX.Element {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const [selectedFormula, setSelectedFormula] = useState(getInitialRepricingMethodology(services));

  useEffect(() => setSelectedFormula(getInitialRepricingMethodology(services)), [services]);

  const onMenuOpen: MouseEventHandler<HTMLDivElement> = (event): void => {
    setMenuAnchorEl(event.currentTarget);
  };
  const onMenuClose = (): void => setMenuAnchorEl(undefined);
  const onFormulaChange = (formulaKey: Formula['key']): void => {
    setMenuAnchorEl(undefined);
    setSelectedFormula(getSelectedRepricingMethodology(formulaKey));
  };

  const saveRepricingMethodologyOverride = (updateInfo: SaveMetadata): void => {
    if (!doServicesHaveRepricingMethodologyValues(services)) {
      updateRepricing(updateInfo);
      return;
    }

    if (
      willOverrideRepricingMethodologyValue(selectedFormula.key, services, updateInfo.numberVal)
    ) {
      setDialog({
        isOpen: true,
        contentText: `Overwrite this claim's existing repricing methodology with a ${
          selectedFormula.name
        } of ${inflateNumber(updateInfo.numberVal)}?`,
        onConfirm: () => {
          updateRepricing(updateInfo);
          setDialog(dialog => ({ ...dialog, isOpen: false }));
        },
      });
    }
  };

  return (
    <>
      <GridColumn>
        <RepricingMethodologyFormulaButton selectedFormula={selectedFormula} onClick={onMenuOpen} />
        <FormulaMenu
          onFormulaChange={onFormulaChange}
          selectedFormula={selectedFormula}
          onClose={onMenuClose}
          anchorEl={menuAnchorEl}
          isOpen={Boolean(menuAnchorEl)}
          formulaList={repricingMethodologies}
        />
      </GridColumn>
      {selectedFormula.fields().map(field => (
        <GridColumn key={field.fieldName}>
          <TextInput
            id={`${field.fieldName}Override`}
            label={field.label}
            labelHover={field.labelFull}
            isPercent={field.isPercent}
            fullWidth
            saveMetadata={{ ...saveMetadata, fieldName: `override.${field.fieldName}` }}
            save={saveRepricingMethodologyOverride}
          />
        </GridColumn>
      ))}
    </>
  );
}

export default connect(null, mapDispatchToProps)(RepricingMethodologyOverride);
