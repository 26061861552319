import { formatCurrency } from '@apps/shared/src/utilities';

import { capitalizeWords } from '../../shared/globals';
import { pluralize } from '../contractUtilities';
import { ContractTerm } from '../types/contracts';
import { validArray, isArrayFilled } from '../../shared/typeChecks';

function generateSituations(t: ContractTerm): string {
  const situations = [];
  if (t.comparisonType) situations.push(`The ${capitalizeWords(t.comparisonType)} of`);
  if (t.rangeInclusive) {
    if (t.startRange && t.endRange)
      situations.push(`If Between ${t.startRange} - ${t.endRange} ${capitalizeWords(t.rangeType)}`);
    else if (t.startRange)
      situations.push(`If Over ${t.startRange} ${capitalizeWords(t.rangeType)}`);
    else situations.push(`If Under ${t.endRange} ${capitalizeWords(t.rangeType)}`);
  }
  return situations.join(' or ');
}

function generateRules(t: ContractTerm): string {
  const rules = [];
  if (t.percentCMS) rules.push(`${t.percentCMS}% of CMS`);
  if (t.percentBilledCharges) rules.push(`${t.percentBilledCharges}% of Billed Charges`);
  if (t.perDiem) rules.push(`${formatCurrency(t.perDiem)} per Diem`);
  if (t.flatRate) rules.push(`Flat Rate of ${formatCurrency(t.flatRate)}`);
  if (t.caseRate) rules.push(`Case Rate of ${formatCurrency(t.caseRate)}`);
  if (t.maxAmount) rules.push(`Max Amount of ${formatCurrency(t.maxAmount)}`);
  return rules.join(' or ');
}

function generateConditions(t: ContractTerm): string {
  const conditions = [];
  const revCodes = validArray(t.revCode);
  const hcpcsOrCPTs = validArray(t.hcpcsOrCPT);
  if (t.claimType) conditions.push(`${capitalizeWords(t.claimType)} Claims`);
  if (t.limitedServices) conditions.push(`${capitalizeWords(t.limitedServices)} Services`);
  if (t.unlistedCharges) conditions.push('Unlisted Charges');
  if (t.extraStipulations) conditions.push(t.extraStipulations);
  if (isArrayFilled(revCodes) && revCodes[0] !== '')
    conditions.push(`${pluralize('Rev Code', revCodes.length)} ${revCodes.join(', ')}`);
  if (isArrayFilled(hcpcsOrCPTs) && hcpcsOrCPTs[0] !== '')
    conditions.push(`${pluralize('CPT Code', hcpcsOrCPTs.length)} ${hcpcsOrCPTs.join(', ')}`);
  return conditions.join(' or ');
}

function constructTermText(rule: string, situation: string, condition: string): string {
  let term = rule;
  if (situation) term = [situation, term].join(': ');
  if (condition) term = [term, condition].join(' for ');
  return term;
}

export default function getTermText(t: ContractTerm): string {
  const rule = generateRules(t);
  const situation = generateSituations(t);
  const condition = generateConditions(t);
  if (
    rule?.length < 1 &&
    situation?.length < 1 &&
    condition?.length < 1 &&
    t.openFormatTextTerm &&
    t.openFormatTextTerm.length > 0
  )
    return t.openFormatTextTerm;
  return constructTermText(rule, situation, condition);
}
