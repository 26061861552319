import { clearRedirect, verifyPasswordReset } from '@apps/shared/src/auth/userActions';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UserState } from '@apps/shared/src/auth/types';

type StateProps = {
  redirectTo: string;
};

const ResetPassword: React.FC<Props> = (props): JSX.Element => {
  const { redirectTo, verifyPasswordReset } = props;
  useEffect(() => {
    const url = window.location.href;
    const token = url.split('/')[url.split('/').length - 1];
    if (url && token) {
      try {
        verifyPasswordReset(token);
      } finally {
        clearRedirect();
      }
    }
  }, [verifyPasswordReset]);

  return redirectTo === '' ? <div>Verifying Email, please wait</div> : <Redirect to={redirectTo} />;
};

// Container

type DispatchProps = {
  verifyPasswordReset: typeof verifyPasswordReset;
};

const mapStateToProps = (user: UserState): StateProps => ({
  redirectTo: user.redirectTo || '',
});

const mapDispatchToProps = { verifyPasswordReset };

type Props = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
