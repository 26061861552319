export function hexToRgba(hex: string, opacity = 1): string {
  if (typeof hex !== 'string') return '';
  let withoutHashHex = hex.replace('#', '');
  switch (withoutHashHex.length) {
    case 3:
      withoutHashHex =
        withoutHashHex.charAt(0).repeat(2) +
        withoutHashHex.charAt(1).repeat(2) +
        withoutHashHex.charAt(2).repeat(2);
      break;
    case 6:
      break;
    default:
      return '';
  }
  const r = parseInt(withoutHashHex.substring(0, 2), 16);
  const g = parseInt(withoutHashHex.substring(2, 4), 16);
  const b = parseInt(withoutHashHex.substring(4), 16);
  return `rgba(${r},${g},${b},${opacity})`;
}
