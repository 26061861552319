import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import makeStyles from '@material-ui/styles/makeStyles';

import queryString from 'query-string';
import { History } from 'history';

import { colors } from '@apps/shared/src/style';

import {
  SFCase,
  SettlementsClaimSummary,
  SettlementMethodology,
  DisplayReportOptions,
  MediVIChartOptions,
  MediVIProvider,
} from '../types/types';
import { RootState } from '../../shared/types/types';
import {
  getCaseSummary,
  updateAllSettlementAmounts,
  updateSettlementMethodology,
  updateSettlementPercent,
  updateReportOptions,
  updateMediVIChartOptions,
  setSavedManualSettlementAmounts,
  setURLSynced,
  resetSettlements,
} from '../settlementsActions';
import { inflateURL, defaultState as defaultSettlementsReduxState } from '../settlementsReducer';
import { createSearchString } from './createSearchString';

const dialogDescription = [
  'The below URL will return to this report exactly as it currently appears. If changes are made to any element, you should save an updated URL to reflect those changes.',
  'Please select and copy the text in the box in order to share it.',
];

const useStyles = makeStyles({
  share: {
    maxWidth: '200px',
  },
  url: {
    backgroundColor: colors.grey12,
    width: 'calc(100% - 40px)',
    padding: '30px 20px',
  },
  dialogButton: {
    margin: '0 auto',
  },
  description: {
    marginBottom: '10px',
  },
});

type StateProps = {
  sfCases: SFCase[];
  claims: SettlementsClaimSummary[];
  settlementMethodology: SettlementMethodology;
  settlementPercent: number;
  displayReportOptions: DisplayReportOptions;
  mediVIChartOptions: MediVIChartOptions;
  loadingCases: number;
  isInitialURLSynced: boolean;
};

const mapStateToProps = ({ settlements }: RootState): StateProps => {
  return {
    sfCases: settlements.sfCases,
    claims: settlements.claims,
    settlementMethodology: settlements.settlementMethodology,
    settlementPercent: settlements.settlementPercent,
    displayReportOptions: settlements.displayReportOptions,
    mediVIChartOptions: settlements.mediVIChartOptions,
    loadingCases: settlements.loadingCases,
    isInitialURLSynced: settlements.isInitialURLSynced,
  };
};

const mapDispatchToProps = {
  getCaseSummary,
  updateAllSettlementAmounts,
  updateSettlementMethodology,
  updateSettlementPercent,
  updateReportOptions,
  updateMediVIChartOptions,
  setSavedManualSettlementAmounts,
  setURLSynced,
  resetSettlements,
};

type ParentProps = {
  location: { search: string };
  history: History;
};

type Props = ParentProps & StateProps & typeof mapDispatchToProps;

export function URLSyncer({
  location,
  history,
  getCaseSummary,
  updateSettlementMethodology,
  updateSettlementPercent,
  updateReportOptions,
  updateMediVIChartOptions,
  setSavedManualSettlementAmounts,
  setURLSynced,
  sfCases,
  claims,
  settlementMethodology,
  settlementPercent,
  displayReportOptions,
  mediVIChartOptions,
  loadingCases,
  isInitialURLSynced,
  resetSettlements,
}: Props): JSX.Element | null {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const rawQueryValues = queryString.parse(location.search, { parseBooleans: true });
  const queryValues = inflateURL(rawQueryValues);

  useEffect(() => {
    const {
      caseNumber,
      providerLimit,
      selectedCCN,
      selectedProviderName,
      selectedRadius,
      settlementMethodology,
      settlementPercent,
      showChart,
      showCostMultiple,
      showPlanImpact,
      showInpatient,
      showOutpatient,
      savedManualSettlementAmounts,
    } = queryValues;

    if (caseNumber) caseNumber.forEach(getCaseSummary);
    if (savedManualSettlementAmounts) setSavedManualSettlementAmounts(savedManualSettlementAmounts);
    if (settlementMethodology) updateSettlementMethodology(settlementMethodology);
    if (settlementPercent) updateSettlementPercent(settlementPercent);
    if (defaultSettlementsReduxState.displayReportOptions.showCostMultiple !== showCostMultiple)
      updateReportOptions('showCostMultiple');
    if (defaultSettlementsReduxState.displayReportOptions.showChart !== showChart)
      updateReportOptions('showChart');
    if (defaultSettlementsReduxState.displayReportOptions.showPlanImpact !== showPlanImpact)
      updateReportOptions('showPlanImpact');

    if (providerLimit) updateMediVIChartOptions('providerLimit', providerLimit);
    if (selectedRadius) updateMediVIChartOptions('selectedRadius', selectedRadius);
    if (showInpatient !== undefined) updateMediVIChartOptions('showInpatient', showInpatient);
    if (showOutpatient !== undefined) updateMediVIChartOptions('showOutpatient', showOutpatient);

    const newMediviProvider: MediVIProvider = {
      name: selectedProviderName || '',
      ccn: selectedCCN || '',
      city: '',
      state: '',
      zip: '',
    };

    updateMediVIChartOptions('selectedProvider', newMediviProvider);

    setURLSynced(true);
    return (): void => {
      resetSettlements();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newUrl = createSearchString(
    sfCases,
    claims,
    settlementMethodology,
    settlementPercent,
    displayReportOptions,
    mediVIChartOptions
  );

  useEffect(() => {
    if (!isInitialURLSynced) return;

    history.push(newUrl);
  }, [
    sfCases,
    claims,
    settlementMethodology,
    settlementPercent,
    displayReportOptions,
    mediVIChartOptions,
    loadingCases,
    setURLSynced,
    newUrl,
    history,
    isInitialURLSynced,
  ]);

  if (sfCases.length === 0) return null;

  const handleModalToggle = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        className={classes.share}
        variant="contained"
        color="secondary"
        onClick={handleModalToggle}
        disabled={loadingCases > 0}
      >
        Share Report Link
      </Button>
      <Dialog
        maxWidth="sm"
        open={isModalOpen}
        onClose={handleModalToggle}
        aria-describedby="share-url-alert"
      >
        <DialogContent>
          <Typography className={classes.description} variant="h5">
            Link to Current Report
          </Typography>
          {dialogDescription.map(d => (
            <Typography key={d} className={classes.description} variant="body2">
              {d}
            </Typography>
          ))}

          <TextareaAutosize
            readOnly
            value={`${window.location.origin.toString()}${newUrl}`}
            className={classes.url}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={handleModalToggle}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(URLSyncer));
