import React from 'react';
import Button from '@material-ui/core/Button';

interface ChildProps {
  handleModalClose: () => void;
}

const PasswordExpiredForm = (props: ChildProps): JSX.Element => {
  const handleBtnClick = (): void => {
    props.handleModalClose();
  };

  return (
    <>
      <p>Your Password is expired, Please update your password.</p>
      <Button variant="contained" onClick={handleBtnClick}>
        Update Password
      </Button>
    </>
  );
};

export default PasswordExpiredForm;
