import { chartColors, hexToRgba } from '@apps/shared/src/style';
import { pluralize } from '../../contracts/contractUtilities';
import { formatFloat } from '../../shared/globals';
import {
  ChartJSData,
  ChartJSOptions,
  ChartJSLabelItem,
  ChartJSLabelData,
} from '../types/chartTypes';

export function formatLabel(item: ChartJSLabelItem, data: ChartJSLabelData): string {
  const label = data.datasets[item.datasetIndex].label
    ? `${data.datasets[item.datasetIndex].label}: `
    : '';
  const labelValue = formatFloat(item.yLabel, 2);
  return label + labelValue;
}

export function generateChartTitle(labels: string[], selectedProviderName: string): string {
  if (labels.length <= 0) return '';

  const otherProvidersCount = labels.length - 1;
  const providerText = `Billed-to-Medicare Ratio for ${selectedProviderName}`;
  const details =
    otherProvidersCount > 0
      ? ` and ${otherProvidersCount} nearby ${pluralize('Provider', otherProvidersCount)}`
      : '';
  return providerText + details;
}

function preventClick(e: MouseEvent): void {
  e.stopPropagation();
}

function generateChartOptions(
  labels: string[],
  selectedProviderName: string,
  printView?: boolean
): ChartJSOptions {
  return {
    title: { display: true, text: generateChartTitle(labels, selectedProviderName), fontSize: 16 },
    legend: {
      labels: { usePointStyle: true },
      onClick: preventClick,
    },
    tooltips: { callbacks: { label: formatLabel } },
    scales: { xAxes: [{ scaleLabel: { display: true } }] },
    animation: !printView,
    maintainAspectRatio: false,
  };
}

function generateChartData(
  labels: string[],
  inpatientRatios: number[],
  outpatientRatios: number[],
  showInpatient: boolean,
  showOutpatient: boolean
): ChartJSData {
  return {
    labels,
    datasets: [
      {
        label: 'Inpatient Ratio',
        backgroundColor: hexToRgba(chartColors.bar[0], 0.7),
        data: inpatientRatios,
        hidden: !showInpatient,
      },
      {
        label: 'Outpatient Ratio',
        backgroundColor: hexToRgba(chartColors.bar[1], 0.7),
        data: outpatientRatios,
        hidden: !showOutpatient,
      },
    ],
  };
}

export function generateSettlementsChartElements(
  selectedProviderName: string,
  labels: string[],
  inpatientRatios: number[],
  outpatientRatios: number[],
  showInpatient: boolean,
  showOutpatient: boolean,
  printView?: boolean
): { data: ChartJSData; options: ChartJSOptions } {
  return {
    data: generateChartData(
      labels,
      inpatientRatios,
      outpatientRatios,
      showInpatient,
      showOutpatient
    ),
    options: generateChartOptions(labels, selectedProviderName, printView),
  };
}
