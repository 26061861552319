import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { RouteMatchParams } from '../../shared/types/types';

import AdvancedBatchList from './AdvancedBatchList';
import BatchDetail from './BatchDetail';
import AdvancedBatchSearch, { advancedBatchesSearchHeight } from './AdvancedBatchSearch';

const useStyles = makeStyles({
  container: {
    height: `calc(100% - ${advancedBatchesSearchHeight} - 45px)`,
    width: '100%',
    display: 'flex',
    borderTop: `1px solid #E0DEDD`,
  },
  containerWrapper: {
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    gap: '.2rem',
  },
});

function AdvancedBatches(): JSX.Element {
  const { id } = useParams<RouteMatchParams>();
  const batchId = id || '';
  const classes = useStyles();

  return (
    <>
      <AdvancedBatchSearch />
      <div className={classes.container}>
        <AdvancedBatchList
          batchId={batchId}
          baseURL="/reports/advanced-batches/"
          showBatchesOnLoad={false}
          displayNumResults
        />
        <BatchDetail batchId={batchId} />
      </div>
    </>
  );
}

export default AdvancedBatches;
