import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { inflateString } from '@apps/shared/src/inflators';
import TextInput from '../../../shared/components/TextInput';
import { TermInputProps } from '../../types/contracts';

const useStyles = makeStyles({
  termInput: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '7em',
    marginLeft: '1em',
  },
});

export default function MoneyInput({ value, handleChange, id }: TermInputProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.termInput}>
      <TextInput
        id={id}
        placeholder="Amount"
        isMoney
        value={inflateString(value)}
        onChange={handleChange}
      />
    </div>
  );
}
