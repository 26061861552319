import React from 'react';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

import { colors } from '@apps/shared/src/style';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  circularProgress: {
    outline: 'none',
    color: colors.orange,
  },
});

type ParentProps = {
  isLoading?: boolean;
  containerClassName?: string;
  unstyledContainer?: boolean;
};

type Props = ParentProps & CircularProgressProps;

export default function LoadingSpinner({
  isLoading,
  containerClassName,
  unstyledContainer = false,
  ...circularProgressProps
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (typeof isLoading !== 'undefined' && !isLoading) return null;

  const containerClasses = classNames(
    containerClassName,
    unstyledContainer ? '' : classes.container
  );

  return (
    <Fade
      in
      timeout={300}
      style={{
        transitionDelay: '400ms',
      }}
    >
      <div data-testid="loading" className={containerClasses}>
        <CircularProgress classes={{ root: classes.circularProgress }} {...circularProgressProps} />
      </div>
    </Fade>
  );
}
