import React from 'react';
import { inflateNumber } from '@apps/shared/src/inflators';
import TooltippedChip from './TooltippedChip';
import { ContractTerm } from '../types/contracts';
import getTermText from '../terms/getTermText';

type Props = {
  isEdit: boolean;
  handleRemove: (id: number) => void;
  item: ContractTerm;
  onClick: (t: ContractTerm) => void;
  isSelected: boolean;
};

export default function TermChip({
  isEdit,
  handleRemove,
  item: term,
  onClick,
  isSelected,
}: Props): JSX.Element {
  const id = inflateNumber(term.id);
  const handleDelete = isEdit ? (): void => handleRemove(id) : undefined;
  const handleClick = (): void => onClick(term);
  const content = getTermText(term);

  return (
    <TooltippedChip
      chipKey={id}
      tooltip={content}
      chipText={content}
      onDelete={handleDelete}
      onClick={handleClick}
      isSelected={isSelected}
    />
  );
}
