import React from 'react';
import PropTypes from 'prop-types';

import PreviousNavIcon from '@material-ui/icons/SkipPrevious';
import NextNavIcon from '@material-ui/icons/SkipNext';

import IconButtonCompact from './IconButtonCompact';

export default function QueueNavigator(props) {
  const { isFirstClaim, isLastClaim, handleNavigatePrev, handleNavigateNext } = props;

  return (
    <>
      {!isFirstClaim && (
        <IconButtonCompact
          id="previous-claim"
          tooltip="Previous Claim"
          onClick={handleNavigatePrev}
        >
          <PreviousNavIcon />
        </IconButtonCompact>
      )}
      {!isLastClaim && (
        <IconButtonCompact id="next-claim" tooltip="Next Claim" onClick={handleNavigateNext}>
          <NextNavIcon />
        </IconButtonCompact>
      )}
    </>
  );
}

QueueNavigator.propTypes = {
  isFirstClaim: PropTypes.bool.isRequired,
  isLastClaim: PropTypes.bool.isRequired,
  handleNavigatePrev: PropTypes.func.isRequired,
  handleNavigateNext: PropTypes.func.isRequired,
};
