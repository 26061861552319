import { ReportRow } from './types';

export function getClaimNumText(data: ReportRow): string {
  return getClaimNum(data) || getRefNum(data) || data.id;
}

export function getClaimNum(data: ReportRow): string {
  return data?.claimNums?.length > 0 ? data.claimNums[0] : '';
}

export function getRefNum(data: ReportRow): string {
  return data?.refNums?.length > 0 ? data.refNums[0] : '';
}
